import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import baseUrl from "../../../../../Http/backend-base-url";
import EpousesDetailsAssuresData from "./EpousesDetailsAssuresData";
import EpousesDetailsAssuresSkeleton from "./EpousesDetailsAssuresSkeleton";
import DefaultAvatar from "../../../../../images/others/default-profile-avatar.png";
import moment from "moment";
import { DataContext } from "../../../../store/GlobalState";

moment.updateLocale("fr", {
  relativeTime: {
    future: "in %s",
    past: "%s ans",
    s: "a few secondes",
    ss: "%d secondes",
    m: "a minute",
    mm: "%d minutes",
    h: "1 heure",
    hh: "%d heures",
    d: "1 jour",
    dd: "%d jours",
    w: "1 semaine",
    ww: "%d semaines",
    M: "1 mois",
    MM: "%d mois",
    y: "1 an",
    yy: "%d ans",
  },
});

var EpousesDetailsAssures = ({ epouses, isPageLoading }) => {
  const { state, dispatch } = useContext(DataContext);

  useEffect(() => {
    // setTimeout(() => {
    //   setIsPageLoading(false);
    // }, 3000);
  }, []);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <div className="nameFormatter">
        <span className="name"> {`${cell} ${row.nom}`} </span>
      </div>
    );
  };

  const avatarFormatter = (cell, row) => {
    return (
      <img
        src={
          !row?.avatar || row?.avatar?.includes("default.png")
            ? DefaultAvatar
            : baseUrl + row?.avatar
        }
        alt="Profile"
        style={{ width: "50px", height: "50px", borderRadius: "100%" }}
      />
    );
  };

  const ageFormater = (cell, row) => {
    // return moment(row?.date_naissance).format("DD/MM/YYYY");
    return moment(cell).locale("fr").fromNow(true);
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button
          className="action-btn show-btn"
          title={row?.is_active ? "Désactivation" : "Activation"}
        >
          {row.is_active ? (
            <IconlyPack.TickSquare
              set="light"
              primaryColor="rgb(2, 166, 156)"
              className="action-btn-icon action-show-icon"
              data-toggle="modal"
              data-target=".active-family-modal"
              onClick={() => onGetItem(row)}
            />
          ) : (
            <IconlyPack.CloseSquare
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-show-icon"
              data-toggle="modal"
              data-target=".active-family-modal"
              onClick={() => onGetItem(row)}
            />
          )}
        </button>

        <button className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".show-family-modal"
            onClick={() => onGetItem(row)}
          />
        </button>

        <button className="action-btn edit-btn" title="Modification">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".edit-epoux-modal"
            onClick={() => onGetItem(row)}
          />
        </button>
        <button className="action-btn delete-btn" title="Supprimer">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            data-toggle="modal"
            data-target=".archive-family-modal"
            onClick={() => onGetItem(row)}
          />
        </button>
      </div>
    );
  };

  const onGetItem = (item) => {
    dispatch({
      type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE",
      payload: item,
    });
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <EpousesDetailsAssuresSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={epouses}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="75"
                    dataField="avatar"
                    dataFormat={(cell, row) => avatarFormatter(cell, row)}
                  >
                    Photo
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="prenom"
                  >
                    Prénom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="nom"
                  >
                    Nom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="date_naissance"
                    dataFormat={(cell, row) => ageFormater(cell, row)}
                  >
                    Âge
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EpousesDetailsAssures;
