import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
import GestionAssuresTable from "../../../Tables/Assurance/GestionAssuresTable/GestionAssuresTable";
import AjouterAssure from "./AjouterAssure";

var GestionAssures = () => {
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Assurés</h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target="#AjoutAssureModal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">Ajouter un assuré</span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <GestionAssuresTable />
                </div>
                <div
                  class="modal fade"
                  id="AjoutAssureModal"
                  tabindex="-1"
                  data-backdrop="static"
                  aria-labelledby="AjoutAssureModalLabel"
                  aria-hidden="true"
                >
                  <AjouterAssure />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionAssures;
