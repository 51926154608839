const ExceptionData = [
    {
        id: 1,
        type: 'Exception',
        libelle: 'Exception libelle',
        cle: 'beneficiaire',
    },
    {
        id: 2,
        type: 'Exception',
        libelle: 'Exception libelle',
        cle: 'beneficiaire',
    },
    {
        id: 3,
        type: 'Exception',
        libelle: 'Exception libelle',
        cle: 'beneficiaire',
    },
    {
        id: 4,
        type: 'Exception',
        libelle: 'Exception libelle',
        cle: 'beneficiaire',
    },
];

export default ExceptionData;