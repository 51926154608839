import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UseCreateFicheSoins from "./UseCreateFicheSoins";
import { DataContext } from "../../../store/GlobalState";
import { getDataByID } from "../../../utils/fetchData";

const GenererFicheSoinModal = () => {
  const {
    unregister,
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    watch,
    reset,
    setValue,
    isSuccess,
    clearErrors,
    error,
  } = UseCreateFicheSoins();
  const { state, dispatch } = useContext(DataContext);

  const { auth, assureur_prestataires, assureur_beneficiaires } = state;

  var [startDate, setStartDate] = React.useState(new Date());
  var [ayantdroits, setAyantdroits] = React.useState("");
  var [isPharmacie, setIsPharmacie] = React.useState(false);
  var [selected, setSelected] = React.useState({});

  React.useEffect(() => {
    if (formState.errors) {
      console.log("error", formState?.errors);
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState.errors]);

  React.useEffect(() => {
    if (isSuccess) {
      setAyantdroits([]);
      setIsPharmacie(false);
      register("date");
      setValue("date", moment(new Date()).format("YYYY-MM-DD"));
    }
  }, [isSuccess]);

  const handleDate = (date) => {
    setStartDate(date);
    register("date");
    setValue("date", moment(date).format("YYYY-MM-DD"));
  };

  const handleChange = (e) => {
    let id = e.target.value;
    register("beneficiaire");
    setValue("beneficiaire", id);

    let benef = assureur_beneficiaires?.data?.filter(
      (b) => b?.id === parseInt(id)
    );
    setSelected(benef[0]);
    getDataByID("ayantdroitbybeneficiaire", parseInt(id), auth.token)
      .then((res) => {
        // console.log("res", );
        if (res?.length) {
          setAyantdroits([...benef, ...res]);
        } else {
          setAyantdroits(benef);
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  const handleChangeBenef = (e) => {
    let benef = assureur_beneficiaires?.data?.filter(
      (b) => b?.id === parseInt(e.target.value)
    );

    if (benef?.length && benef[0]?.prenom === selected?.prenom) {
      console.log("benef");
    } else {
      register("ayant_droit");
      setValue("ayant_droit", e.target.value);
    }
  };

  const handleChangePresta = (e) => {
    register("prestataire");
    setValue("prestataire", e.target.value);
    let testPharmacie = assureur_prestataires?.data?.filter(
      (p) =>
        p?.id === parseInt(e.target.value) &&
        p?.specialite?.id &&
        (p?.specialite?.nom?.toLowerCase() === "pharmacie" ||
          p?.specialite?.nom?.toLowerCase() === "pharmacien" ||
          p?.specialite?.nom?.toLowerCase() === "pharmacienne")
    );
    if (testPharmacie?.length) {
      setIsPharmacie(true);
      register("ordonnance_file");
      setValue("showOrdonnance", true);
      console.log("testPharmacie", testPharmacie);
    } else {
      unregister("ordonnance_file");
      setIsPharmacie(false);
      setValue("showOrdonnance", false);
      console.log(
        "non testPharmacie",
        testPharmacie,
        assureur_prestataires?.data.filter(
          (p) => p?.id === parseInt(e.target.value)
        )
      );
    }
  };

  const handleReset = () => {
    reset();
    setStartDate(new Date());
    setAyantdroits([]);
    setIsPharmacie(false);
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="GenererFicheSoinModal"
          >
            Demander une fiche de soins
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Assuré</label>
                    <div className="filter-item-container">
                      <select
                        onChange={handleChange}
                        name="beneficiaire"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir
                        </option>
                        {assureur_beneficiaires?.data &&
                          assureur_beneficiaires?.data
                            ?.filter((b) => b?.is_active === true)
                            .map((item, index) => (
                              <option key={index} value={item.id}>
                                {" "}
                                {item.prenom + " " + item.nom}{" "}
                              </option>
                            ))}
                      </select>
                    </div>
                    {formState.errors && formState.errors.beneficiaire && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.beneficiaire?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Prestataires</label>
                    <div className="filter-item-container">
                      <select
                        name="prestataire"
                        onChange={handleChangePresta}
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir un prestataire
                        </option>
                        {assureur_prestataires?.data &&
                          assureur_prestataires?.data?.map((item, index) => (
                            <option key={index} value={item.id}>
                              {" "}
                              {item.raison_sociale
                                ? item.raison_sociale
                                : item.prenom + " " + item.nom}
                            </option>
                          ))}
                      </select>
                    </div>
                    {formState.errors && formState.errors.prestataire && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.prestataire?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group ">
                    <label htmlFor="first_name">Bénéficiaires</label>
                    <div className="filter-item-container">
                      <select
                        onChange={handleChangeBenef}
                        name="ayant_droit"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option value="" hidden>
                          Choisir
                        </option>
                        {ayantdroits?.length &&
                          ayantdroits
                            ?.filter((b) => b?.is_active === true)
                            .map((item, index) => (
                              <option key={index} value={item.id}>
                                {" "}
                                {item.prenom + " " + item.nom}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Date</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {formState.errors && formState.errors.date && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.date?.message}
                      </div>
                    )}
                  </div>
                </div>
                {isPharmacie && (
                  <div
                    className="col-md-6 crud-col-item"
                    style={{ marginBottom: "15px" }}
                  >
                    <div className="form-group crud-form-group custom-file required">
                      <label htmlFor="file">Ordonnance</label>
                      <input
                        type="file"
                        placeholder="Importer l'ordonnance"
                        name="ordonnance_file"
                        className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                        id="file"
                        {...register("ordonnance_file")}
                      />

                      {error && error?.ordonnance_file && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {error?.ordonnance_file}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  <button className="btn crud-submit-btn-assurance">
                    Enregistrer
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenererFicheSoinModal;
