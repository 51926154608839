import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import BeneficiairesSkeleton from "./BeneficiairesSkeleton";
import { useParams } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../store/GlobalState";
import CRUDBeneficiaireModal from "../../Modals/CRUDBeneficiaireModal";
import UseEditBeneficiaireForm from "../../Backend/IPM/GestionBeneficiaires/UseEditBeneficiaireForm";
import ImportCsvBeneficiaire from "../../Modals/ImportCsvBeneficiaireModal";
// import beneficiaireRequest from '../../Services/Beneficiaire'
import CSVModel from "../../../modele-import-files/modele_csv_import_beneficiaires.csv";
import DeleteBeneficiaireModal from "../../Modals/DeleteBeneficiaireModal";
import ValidBeneficiaireModal from "../../Modals/ValidBeneficiaireModal";
import DefaultAvatar from "../../../images/others/default-profile-avatar.png";
import baseUrl from "../../../Http/backend-base-url";
import RegenerePwdModal from "../../Modals/RegenerePwdModal";
import moment from "moment";
import GenereFicheModal from "../../Modals/GenereFicheModal";
import { getDataByID } from "../../utils/fetchData";
import Swal from "sweetalert2";

var Beneficiaires = (props) => {
  const [userType, setUserType] = React.useState(
    sessionStorage.getItem("userType")
  );
  //var [beneficiairesByEntreprise, setBeneficiairesByEntreprise] = React.useState()
  const { state, dispatch } = useContext(DataContext);
  const { auth, beneficiaires, entreprises, beneficiairesByEntreprise, ipms } =
    state;
  var [selected, setSelected] = React.useState({});
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  // const [userActionType, setUserActionType] = React.useState()
  const [id, setId] = React.useState();
  const {
    reset,
    register,
    onSubmit,
    formState,
    setValue,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
  } = UseEditBeneficiaireForm();

  var params = useParams();

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsPageLoading(false);
  //   }, 5000);
  // }, []);

  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (
        auth.user?.user_type === "ipm" ||
        auth?.user?.user_type === "assurance_sante" ||
        auth?.user?.user_type === "admin_ipm"
      ) {
        if (!auth?.user?.admin_type) {
          getDataByID("beneficiairebyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_BENEFICIAIRE",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
              setIsPageLoading(false);
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        } else {
          getDataByID("beneficiairebyassurance", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_BENEFICIAIRE",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
              setIsPageLoading(false);
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyipm",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }

      if (auth.user?.user_type === "entreprise") {
        if (!auth?.user?.admin_type) {
          getDataByID("beneficiairebyemploye", auth?.user?.id, auth.token)
            .then((res) => {
              // console.log('res benef', res)
              dispatch({
                type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
                payload: res.sort((a, b) => b.id - a.id),
              });
              setTimeout(() => {
                setIsPageLoading(false);
              }, 5000);
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyentreprise",
            auth?.user?.id,
            auth?.token
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        } else {
          getDataByID("beneficiairebyemploye", auth?.user?.parent, auth.token)
            .then((res) => {
              // console.log('res benef', res)
              dispatch({
                type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
                payload: res.sort((a, b) => b.id - a.id),
              });
              setIsPageLoading(false);
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyentreprise",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }

      if (auth.user?.user_type === "prestataire") {
        getDataByID(
          "beneficiairebyprestataire",
          auth?.user?.id,
          auth?.token
        ).then((res) => {
          dispatch({
            type: "ADD_BENEFICIAIRE",
            payload: res.sort((a, b) => a?.id - b?.id).reverse(),
          });
          setIsPageLoading(false);
        });

        getDataByID(
          "notificationbyprestataire",
          auth?.user?.id,
          auth?.token
        ).then((res) => {
          // console.log("notif", res);
          dispatch({
            type: "LIST_NOTIFICATION",
            payload: res.sort((a, b) => a?.id - b?.id).reverse(),
          });
        });
      }
    }
  }, [auth]);

  useEffect(() => {
    setId(params?.id);
    sessionStorage.setItem("entrepriseID", id);

    // if(id){
    //   onGetBeneficiaireByEntreprise(id && id)
    // }

    if (isSuccessfullySubmitted) {
      //window.$('.edit-beneficiaire-modal').modal('hide')
      //handleReset()
      setTimeout(() => {
        setIsSuccessfullySubmitted(false);
      }, 5000);
    }

    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }

    setUserType(window.sessionStorage.getItem("userType"));
    // eslint-disable-next-line
  }, [
    formState,
    clearErrors,
    params.id,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    auth,
  ]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    if (
      userType === "ipm" ||
      userType === "assurance_sante" ||
      userType === "admin_ipm"
    ) {
      return (
        <NavLink
          to={`/ipm/profil-beneficiaire/${row.id}`}
          className="nameFormatter"
          onClick={() => onGetBenefInfos(row, "show")}
        >
          <span className="name"> {row?.prenom} </span> <br />
          {/* {!props.isEntrepriseList && (
            <span className="entreprise"> {row.employeur.raison_sociale} </span>
          )} */}
        </NavLink>
      );
    }
    if (userType === "prestataire") {
      return (
        <NavLink
          // to={`/prestataire/profil-beneficiaire/${row.id}`}
          to="#"
          className="nameFormatter"
          onClick={() => onGetBenefInfos(row, "show")}
        >
          <span className="name"> {row?.prenom} </span> <br />
          {/* {!props.isEntrepriseList && (
            <span className="entreprise"> {row.employeur.raison_sociale} </span>
          )} */}
        </NavLink>
      );
    }
    if (userType === "entreprise") {
      return (
        <NavLink
          to={`/entreprise/profil-employes/${row.id}`}
          onClick={() => onGetBenefInfos(row, "show")}
          className="nameFormatter"
        >
          <span className="name"> {cell} </span>
        </NavLink>
      );
    }
  };

  const EntrepriseFormatter = (cell, row) => {
    if (
      userType === "ipm" ||
      userType === "assurance_sante" ||
      userType === "admin_ipm"
    ) {
      return (
        <NavLink
          to={`/ipm/profil-beneficiaire/${row.id}`}
          className="nameFormatter"
          onClick={() => onGetBenefInfos(row, "show")}
        >
          <span className="name"> {row.employeur.raison_sociale} </span>
        </NavLink>
      );
    }
    if (userType === "prestataire") {
      return (
        <NavLink
          // to={`/prestataire/profil-beneficiaire/${row.id}`}
          to="#"
          className="nameFormatter"
          onClick={() => onGetBenefInfos(row, "show")}
        >
          <span className="name"> {row.employeur.raison_sociale} </span>
        </NavLink>
      );
    }
    if (userType === "entreprise") {
      return (
        <NavLink
          to={`/entreprise/profil-employes/${row.id}`}
          onClick={() => onGetBenefInfos(row, "show")}
          className="nameFormatter"
        >
          <span className="name"> {cell?.raison_sociale} </span>
        </NavLink>
      );
    }
  };

  const ActiveFormatter = (cell, row) => {
    if (cell === true) {
      return (
        <span className="badge badge-success easypm-badge-success">Actif</span>
      );
    } else {
      return (
        <span className="badge badge-danger easypm-badge-danger">Inactif</span>
      );
    }
  };

  const DateFormatter = (cell, row) => {
    return moment(new Date(cell)).format("DD/MM/YYYY");
  };

  const nomFormater = (cell, row) => {
    if (
      userType === "ipm" ||
      userType === "assurance_sante" ||
      userType === "admin_ipm"
    ) {
      return (
        <NavLink
          to={`/ipm/profil-beneficiaire/${row.id}`}
          className="nameFormatter"
          onClick={() => onGetBenefInfos(row, "show")}
        >
          <span className="name"> {row?.nom} </span> <br />
        </NavLink>
      );
    }

    if (userType === "prestataire") {
      return (
        <NavLink
          // to={`/prestataire/profil-beneficiaire/${row.id}`}
          to="#"
          className="nameFormatter"
          onClick={() => onGetBenefInfos(row, "show")}
        >
          <span className="name"> {row?.nom} </span> <br />
        </NavLink>
      );
    }

    if (userType === "entreprise") {
      return (
        <NavLink
          to={`/entreprise/profil-employes/${row.id}`}
          onClick={() => onGetBenefInfos(row, "show")}
          className="nameFormatter"
        >
          <span className="name"> {cell} </span>
        </NavLink>
      );
    }
  };

  const nameFilter = (cell, row) => {
    //console.log('row', row)
    return row?.prenom 
  };

  const nomFilter = (cell, row) => {
    //console.log('row', row)
    return cell
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {(userType === "ipm" ||
          userType === "assurance_sante" ||
          userType === "admin_ipm") && (
          <>
            <button
              className="action-btn show-btn"
              title={row?.is_active ? "Désactivation" : "Activation"}
            >
              {row.is_active ? (
                <IconlyPack.TickSquare
                  set="light"
                  primaryColor="rgb(2, 166, 156)"
                  className="action-btn-icon action-show-icon"
                  data-toggle="modal"
                  data-target=".valid-beneficiaire-modal"
                  onClick={() => onGetItem(row)}
                />
              ) : (
                <IconlyPack.CloseSquare
                  set="light"
                  primaryColor="#C8C8C8"
                  className="action-btn-icon action-show-icon"
                  data-toggle="modal"
                  data-target=".valid-beneficiaire-modal"
                  onClick={() => onGetItem(row)}
                />
              )}
            </button>
            <button
              className="action-btn show-btn"
              title="Génèrer une fiche de soins"
            >
              {moment(new Date()).isSameOrBefore(new Date(row?.date_fin)) &&
              row?.is_active ? (
                <i
                  className="fa fa-qrcode fa-lg action-btn-icon action-show-icon"
                  aria-hidden="true"
                  data-toggle="modal"
                  data-target=".add-fiche-modal"
                  onClick={() => onGetItem(row)}
                  style={{ marginTop: "0.3rem", color: "#c8c8c8" }}
                ></i>
              ) : (
                <i
                  className="fa fa-qrcode fa-lg action-btn-icon action-show-icon"
                  aria-hidden="true"
                  onClick={() => getWarning(row)}
                  style={{ marginTop: "0.3rem", color: "#c8c8c8" }}
                ></i>
              )}
            </button>
            {moment(new Date()).isSameOrBefore(new Date(row?.date_fin)) &&
              row?.is_active && (
                <NavLink
                  to={{
                    pathname: "/ipm/gestion-des-remboursements",
                    state: { adherent: row },
                  }}
                  title="Demande de remboursement"
                >
                  <i
                    className="fa fa-credit-card fa-lg action-btn-icon action-show-icon"
                    style={{ color: "#C8C8C8", marginTop: "0.3rem" }}
                    aria-hidden="true"
                  ></i>
                </NavLink>
              )}
          </>
        )}
        {userType === "entreprise" ? (
          <NavLink
            to={`/entreprise/profil-employes/${row.id}`}
            className="action-btn show-btn"
            onClick={() => onGetBenefInfos(row)}
            title="Details"
          >
            <IconlyPack.Show
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-show-icon"
            />
          </NavLink>
        ) : (
          <NavLink
            to={`/ipm/profil-beneficiaire/${row.id}`}
            className="action-btn show-btn"
            onClick={() => onGetBenefInfos(row)}
            title="Details"
          >
            <IconlyPack.Show
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-show-icon"
            />
          </NavLink>
        )}

        {userType !== "prestataire" && (
          <>
            <button
              className="action-btn edit-btn"
              onClick={() => onGetBenefInfos(row, "update")}
              data-toggle="modal"
              data-target=".edit-beneficiaire-modal"
              title="Modification"
            >
              <IconlyPack.Edit
                set="light"
                primaryColor="#C8C8C8"
                className="action-btn-icon action-edit-icon"
              />
            </button>
            <button
              className="action-btn edit-btn"
              onClick={() => onGetItem(row)}
              data-toggle="modal"
              data-target=".regenere-pwd-modal"
              title="Renvoi de mot de passe"
            >
              <IconlyPack.Password
                set="light"
                primaryColor="#C8C8C8"
                className="action-btn-icon action-edit-icon"
              />
            </button>
          </>
        )}

        {userType === "entreprise" && (
          <button className="action-btn delete-btn">
            <IconlyPack.Delete
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-delete-icon"
              data-toggle="modal"
              data-target=".delete-beneficiaire-modal"
              onClick={() => onGetBenefInfos(row)}
            />
          </button>
        )}
      </div>
    );
  };

  const avatarFormatter = (cell, row) => {
    return (
      <img
        src={
          !row?.avatar || row?.avatar.includes("default.png")
            ? DefaultAvatar
            : baseUrl + row?.avatar
        }
        alt="Profile"
        style={{ width: "50px", height: "50px", borderRadius: "100%" }}
      />
    );
  };

  const onGetBenefInfos = async (item, action) => {
    await window.sessionStorage.removeItem("benefInfos");
    await window.sessionStorage.setItem("benefInfos", JSON.stringify(item));
    // setUserActionType(action)
    // console.log('item', item)
    await dispatch({ type: "SHOW_BENEFICIAIRE", payload: item });
  };

  var handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  const onGetItem = (item) => {
    setSelected(item);
    dispatch({
      type: "SHOW_BENEFICIAIRE",
      payload: item,
    });
  };

  const getWarning = (item) => {
    if (item?.is_active === false) {
      Swal.fire({
        title: "Cet adhérent n'est pas actif.",
        text: "Veuillez l'activer avant de génèrer une fiche de soins.",
        icon: "warning",
        // showLoaderOnConfirm: true,
      });
    } else {
      Swal.fire({
        title: "La date de fin de validité de cet adhérent est dépassée.",
        icon: "warning",
        // showLoaderOnConfirm: true,
      });
    }
  };

  const optionFilter = {
    true: "Actif",
    false: "Inactif",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="export-container m-t-50">
          {!props?.isEntrepriseList && userType !== "prestataire" && (
            <div className="export-btn-container">
              <a
                className="btn btn-info modele-import-btn"
                href={CSVModel}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <IconlyPack.PaperUpload set="light" primaryColor="#FFFFFF" />
                &nbsp;
                <span className="file-label">Télécharger le modéle de CSV</span>
              </a>
              <button
                className="btn btn-primary export-btn"
                data-toggle="modal"
                data-target=".csv-beneficiaire-modal"
              >
                <IconlyPack.Upload set="light" primaryColor="#02A69C" />
                &nbsp;
                <span className="export-btn-label">Import CSV</span>
              </button>
            </div>
          )}
        </div>

        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <BeneficiairesSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={
                    ((userType === "ipm" ||
                      userType === "assurance_sante" ||
                      userType === "admin_ipm") &&
                      !id) ||
                    userType === "prestataire"
                      ? beneficiaires
                      : beneficiairesByEntreprise !== null
                      ? beneficiairesByEntreprise
                      : []
                  }
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    width="130"
                    dataAlign="center"
                    isKey={true}
                    filter={{
                      type: "TextFilter",
                      // style: { visibility: "hidden" },
                    }}
                    // hidden={true}
                  >
                    N°.Participant
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="80"
                    dataField="avatar"
                    dataFormat={(cell, row) => avatarFormatter(cell, row)}
                  >
                    Avatar
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="prenom"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Prénom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="100"
                    dataField="nom"
                    filter={{ type: "TextFilter" }}
                    filterValue={nomFilter}
                    dataFormat={(cell, row) => nomFormater(cell, row)}
                  >
                    Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    // width="150"
                    dataField="employeur"
                    dataFormat={(cell, row) => EntrepriseFormatter(cell, row)}
                    hidden={userType !== "entreprise" ? false : true}
                  >
                    Adhérent
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="150"
                    dataField="date_fin"
                    dataFormat={(cell, row) => DateFormatter(cell, row)}
                  >
                    Date de fin
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="110"
                    dataField="is_active"
                    dataFormat={(cell, row) => ActiveFormatter(cell, row)}
                    filter={{ type: "SelectFilter", options: optionFilter }}
                    // hidden={userType !== "entreprise" ? false : true}
                  >
                    Statut
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="200"
                    dataAlign="center"
                    dataField="id"
                    hidden={userType === "prestataire"}
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade easypm-modal edit-beneficiaire-modal"
        id="AddModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
      >
        <CRUDBeneficiaireModal
          entrepriseId={props?.entrepriseId}
          userActionType="edit"
          listEntreprises={entreprises && entreprises}
          register={register}
          reset={reset}
          onSubmit={onSubmit}
          setValue={setValue}
          formState={formState}
          isSuccessfullySubmitted={isSuccessfullySubmitted}
          setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
          handleReset={handleReset}
          assurances={ipms}
        />
      </div>

      {/* Modal import*/}
      <div
        className="modal fade easypm-modal csv-beneficiaire-modal"
        id="CsvModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="CsvModalItemLabel"
        aria-hidden="true"
      >
        <ImportCsvBeneficiaire />
      </div>

      {/* Modal delete*/}
      <div
        className="modal fade easypm-modal delete-beneficiaire-modal"
        id="CsvModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="CsvModalItemLabel"
        aria-hidden="true"
      >
        <DeleteBeneficiaireModal />
      </div>

      <div
        className="modal fade easypm-modal add-fiche-modal"
        id="FicheModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="FicheModalItemLabel"
        aria-hidden="true"
      >
        <GenereFicheModal
          beneficiaire={selected}
          beneficiaireID={selected?.id}
        />
      </div>
      {/* Modal validation*/}
      <div
        className="modal fade easypm-modal valid-beneficiaire-modal"
        id="CsvModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="CsvModalItemLabel"
        aria-hidden="true"
      >
        <ValidBeneficiaireModal />
      </div>

      {/* Modal regeneration de mot de passe*/}
      <div
        className="modal fade easypm-modal regenere-pwd-modal"
        id="CsvModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="PwpModalItemLabel"
        aria-hidden="true"
      >
        <RegenerePwdModal />
      </div>
    </div>
  );
};

export default Beneficiaires;
