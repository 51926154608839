import React, { useMemo, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Entreprise from "../../../Services/Entreprise";
import { DataContext } from "../../../store/GlobalState";
import { toast } from "react-toastify";
// import { updateItem } from '../../../store/Actions'
import { getDataByID } from "../../../utils/fetchData";

function UseCreateEntrepriseForm() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [entrepriseData, setEntrepriseData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };
  const { state, dispatch } = useContext(DataContext);
  const { auth, entreprise } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("L'adresse email n'est pas valide")
          .required("L'email est requis"),
        nom: yup.string().required("Le nom de l'entreprise est requise."),
        raison_sociale: yup.string().required("La raison sociale est requise"),
        // password: yup.number().transform(value => (isNaN(value) ? undefined : value)).required('L\'identifiant est requis'),
        // password: yup.string().required('Le mot de passe est requis'),
        telephone: yup
          .string()
          .required("Le numéro de téléphone est requis")
          .matches(
            /^((\+)221|0)[1-9](\d{2}){4}$/,
            "Veuillez vérifier le format du numéro de téléphone"
          ),
        adresse: yup.string().required("L'adresse est requise"),
        ninea: yup
          .string()
          .matches(
            new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/),
            "Le NINEA doit être de format alpha-numérique."
          )
          .length(12, "Le ninea doit comporter 12 caractères")
          .required("Le NINEA est requis"),
        // website: yup.string().url('Vous devez renseigner une url valide.'),
        website: yup.string(),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    setValue,
    reset,
    unregister,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitEditForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);

    var etsRequest = Entreprise.editEntreprise(
      entreprise?.id,
      formValues,
      requestConfig
    );
    await etsRequest
      .then(async (response) => {
        var entrepriseInfos = response;
        setEntrepriseData(entrepriseInfos);

        if (auth?.user?.user_type === "admin_ipm") {
          getDataByID("entreprisebyassurance", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_ENTREPRISE_BY_IPM",
                payload: res.sort((a, b) => a.id - b.id).reverse(),
              });
              window.$(".edit-entreprise-modal").modal("hide");
              dispatch({ type: "EDIT_ENTREPRISE", payload: {} });
              e.target.reset();
              toast.success("L'entreprise a été modifiée avec succès.");
              setIsSuccessfullySubmitted(false);
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          getDataByID("entreprisebyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_ENTREPRISE_BY_IPM",
                payload: res.sort((a, b) => a.id - b.id).reverse(),
              });
              window.$(".edit-entreprise-modal").modal("hide");
              dispatch({ type: "EDIT_ENTREPRISE", payload: {} });
              e.target.reset();
              toast.success("L'adhérent a été modifié avec succès.");
              setIsSuccessfullySubmitted(false);
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsFormRequestError(true);
        setIsSuccessfullySubmitted(false);
        if (
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          toast.error(
            "Oupsss! L'adresse email est déjà associée à un compte. Veuillez utiliser une autre adresse email.",
            {
              position: "top-right",
              autoClose: 10000,
            }
          );
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (
          error?.response?.status === 400 &&
          !error?.response?.data?.email
        ) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };

  return {
    register,
    errors,
    formState,
    entrepriseData,
    setEntrepriseData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitEditForm),
    setValue,
    reset,
    unregister,
  };
}

export default UseCreateEntrepriseForm;
