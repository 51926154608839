import React from "react";
import Skeleton from "react-loading-skeleton";

const MontantVersesAuxEntreprisesTableSkeleton = () => {
    return (
      <div className="col-md-12 as-col-table-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" style={{ color: "#1F497D" }}>
                N°
              </th>
              <th scope="col" style={{ color: "#1F497D" }}>
                Entreprise
              </th>
              <th scope="col" style={{ color: "#1F497D" }}>
                Montant(FCFA)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
}

export default MontantVersesAuxEntreprisesTableSkeleton;