import React from "react";
import baseUrl from "../../Http/backend-base-url";
import { Message } from "rsuite";

const ShowFicheFileModal = (props) => {
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="SendMessageModal">
            Fiche de soins
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            className="pdf-created-row"
            style={{ margin: "0px", padding: "0px" }}
          >
            {props?.fiche?.id && props?.fiche?.file_pdf ? (
              <>
                <div className="col-md-6 offset-md-3 pdf-created-link-container">
                  {/* <a style={style.download_pdf_} target="_blank" rel="noopener noreferrer" href={Url}> */}
                  <a
                    className="pdf-created-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    href={`${baseUrl}${props?.fiche?.file_pdf}`}
                    // data-dismiss="modal"
                    onClick={() => {
                      setTimeout(() => {
                        window.$(".show-fiche-file-modal").modal("hide");
                      }, 1000);
                    }}
                  >
                    Télécharger la fiche
                  </a>
                </div>
                <div className="col-md-12 pdf-created-viewr-container">
                  {props?.fiche?.id && (
                    // eslint-disable-next-line jsx-a11y/iframe-has-title
                    <iframe
                      className="iframe_pdf"
                      frameBorder="0"
                      src={`${baseUrl}${props?.fiche?.file_pdf}`}
                      style={{ width: "100%", height: "700px" }}
                      height="700"
                      width="100%"
                    />
                  )}
                </div>
              </>
            ) : (
              <Message
                showIcon
                type="warning"
                title="Le fichier n'est pas disponible."
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowFicheFileModal;
