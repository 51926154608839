import React from "react";

export function useMutation(query) {
  const [loading, setLoading] = React.useState(false);

  const exec = React.useCallback(async (data) => {
    try {
      setLoading(true);
      const res = await query(data);
      setLoading(false);
      return res;
    } catch (ex) {
      setLoading(false);
      throw ex;
    }
  }, []);

  return {
    exec,
    loading,
  };
}
