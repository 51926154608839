import React, { Component, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import { getDataByID } from "../../../../utils/fetchData";
import Skeleton from "react-loading-skeleton";

function DonutGraph({ auth }) {
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const [date, setDate] = useState(new Date());
  const [cumul, setCumul] = useState([0, 0]);
  const [cumulTotal, setCumulTotal] = useState(0);
  const [count, setCount] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [countIpm, setCountIpm] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [countAdh, setCountAdh] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [stats, setStats] = useState([]);
  const [labels, setLabels] = useState([]);
  // const [plugins, setPlugins] = useState([]);
  useEffect(() => {
    setLabels(dateRange(moment(date).format("YYYY-MM-DD")));
  }, [date]);

  useEffect(() => {
    if (!auth?.user?.admin_type) {
      getDataByID(
        `dashboardbyipmbydate/${auth?.user?.id}`,
        moment(date).format("DD-MM-YYYY"),
        auth?.token
      ).then((stat) => {
        // setStats(stat?.data);
        getStat(stat?.data);
        setIsPageLoading(false);
        // console.log("testeee", stat?.data);
      });
    } else {
      getDataByID(
        `dashboardbyipmbydate/${auth?.user?.parent}`,
        moment(date).format("DD-MM-YYYY"),
        auth?.token
      ).then((stat) => {
        setStats(stat?.data);
        getStat(stat?.data);
        setIsPageLoading(false);
      });
    }
  }, [auth, date]);

  const getStat = (array) => {
    let part_total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let part_ipm = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let part_adh = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let total_ipm = 0;
    let total_adh = 0;
    let total = 0;
    let cumul = [0, 0];
    if (array?.length) {
      // console.log("cout_total", array);
      array.forEach((element) => {
        total += parseInt(element?.cout_total);
        total_adh += parseInt(element?.part_beneficiaire);
        total_ipm += parseInt(element?.part_ipm);
        part_total[moment(element?.month).format("M") - 1] =
          element?.cout_total;
        part_ipm[moment(element?.month).format("M") - 1] = element?.part_ipm;
        part_adh[moment(element?.month).format("M") - 1] =
          element?.part_beneficiaire;
      });
    }
    cumul[0] = total_ipm;
    cumul[1] = total_adh;
    console.log("test", cumul);
    setCumul(cumul);
    setCumulTotal(total);
    setCount(part_total);
    setCountIpm(part_ipm);
    setCountAdh(part_adh);
  };

  const dateRange = (endDate) => {
    var start = moment().startOf("year").format("YYYY-MM-DD").split("-");
    var end = moment(endDate).format("YYYY-MM-DD").split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];
    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      // console.log("mois", startYear, endYear);
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var mois = moment().month(j).locale("fr").format("MMM");
        dates.push([mois].join("-"));
      }
    }
    return dates;
  };
  const series = cumul;
  const options = {
    labels: ["Prise en charge IPMS", "Retenus sur salaires"],
    chart: {
      width: 480,
      type: "donut",
    },
    colors: ["#01223A", "#02A69C"],
    title: {
      text: "Cumul sur l'année civile des dépenses par poste",
      align: "left",
      offsetX: 1,
    },
    dataLabels: {
      enabled: false,
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "top",
      offsetY: 0,
      height: 30,
    },
  };
  const handleChangeDate = (date) => {
    // setCount([]);
    // setCountAdh([])
    setLabels([]);
    setDate(date);
    var start = moment().startOf("year").format("YYYY-MM-DD").split("-");
    var end = moment(date).format("YYYY-MM-DD").split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];
    console.log("year", startYear, endYear);
    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      // console.log("mois", startYear, endYear);
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var mois = moment().month(j).locale("fr").format("MMM");
        dates.push([mois].join("-"));
      }
    }
    setLabels(dates);
  };
  return (
    <div className="">
      <div className="row mb-2">
        <div className="col-xl-5 col-lg-4 mb-2">
          {/* <h2 className="chart-item-title">Chiffre du jour - <span className="fw-300"> Nombre d'apprenants par jour </span> </h2> */}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6 mb-2 dis-flex">
          <div className="ml-desktop">
            <DatePicker
              dateFormat="MMMM/yyyy"
              className="form-select form-select-line-chart ml-md-auto"
              showMonthYearPicker={true}
              maxDate={new Date()}
              selected={date}
              onChange={(date) => handleChangeDate(date)}
              locale="fr"
            />
          </div>
        </div>
      </div>
      <div class="chart-wrap">
        <div id="chart" className="d-flex justify-content-center">
          {isPageLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Skeleton circle={true} height={300} width={300} />
            </div>
          ) : (
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width={380}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DonutGraph;
