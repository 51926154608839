import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import CompteTableData from "./CompteTableData";
import CompteTableSkeleton from "./CompteTableSkeleton";
// import FactureModalAssureur from './FactureModalAssureur'
// import UseEditEntrepriseForm from '../../Backend/IPM/Comptes/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'
import FeatherIcon from "feather-icons-react";
import { DataContext } from "../../../../store/GlobalState";
import AjoutAdmin from "./AjoutAdmin";
import ModiferAdmin from "./ModiferAdmin";
import RegenerePwdModal from "../../../../Modals/RegenerePwdModal";
// import GestionFicheDeSoin from './GestionFicheDeSoin'

var CompteTable = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { assureur_admins } = state;
  // const [soins, setSoins] = React.useState(CompteTableData);

  // var [isPageLoading, setIsPageLoading] = React.useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsPageLoading(false);
  //   }, 3000);
  // }, [soins]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormatter = (cell, row) => {
    return <span> {row.prenom + " " + cell} </span>;
  };

  const nameFilter = (cell, row) => {
    return row.prenom + " " + cell;
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <button className="action-btn edit-btn" title="Modifier">
          <IconlyPack.Edit
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".edit-admin-modal"
            onClick={() => onGetItem(row)}
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Password
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".regenere-pwd-modal"
            onClick={() => onGetItem(row)}
          />
        </button>
        {/* <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Show
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#factureAssureur"
          />
        </button> */}
      </div>
    );
  };

  const onGetItem = (item) => {
    dispatch({ type: "EDIT_ADMIN_IPM", payload: item });
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {assureur_admins?.isLoading && <CompteTableSkeleton />}
            {!assureur_admins?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={assureur_admins?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="nom"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                    filterValue={nameFilter}
                    dataFormat={nameFormatter}
                  >
                    Prénom & Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="email"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    Email
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="140"
                    dataField="telephone"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    Téléphone
                  </TableHeaderColumn>
                  {/* <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="140"
                    dataField="adresse"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    Adresse
                  </TableHeaderColumn> */}
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="130"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Modals */}
      <div
        className="modal fade easypm-modal add-admin-modal"
        data-backdrop="static"
        id="AddModalItem"
        tabIndex="-1"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
      >
        <AjoutAdmin />
      </div>
      <div
        className="modal fade easypm-modal edit-admin-modal"
        data-backdrop="static"
        id="EditModalItem"
        tabIndex="-1"
        aria-labelledby="EditModalItemLabel"
        aria-hidden="true"
      >
        <ModiferAdmin />
      </div>
      <div
        className="modal fade easypm-modal regenere-pwd-modal"
        id="PwpModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="PwpModalItemLabel"
        aria-hidden="true"
      >
        <RegenerePwdModal />
      </div>
    </div>
  );
};

export default CompteTable;
