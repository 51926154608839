import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import DetailsPrestataireAdminTable from "../../../../Tables/Assurance/GestionPrestataireAdminTable/DetailsPrestataireAdminTable";
import Header from '../../../../Navs/Header';
import NavbarVerticalV2 from "../../../navbarVerticalV2/navbarVerticalV2";
var DetailsPrestataireAdmin = () => {
 

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavbarVerticalV2  />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container align-items-center">
                        <NavLink to="/prestataire-assurance" className="btn  btn-assurance-retour">
                            <IconlyPack.ArrowLeft set="light" primaryColor="#fff"/> 
                            <span className="pl-2">Retour</span>
                        </NavLink>
                        <h1 className="page-title pl-2">
                        Prestations de Abdou Fall
                        </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <DetailsPrestataireAdminTable
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPrestataireAdmin;
