const GestionSoinsAdminTableData = [
    {
        id: 1,
        beneficiaire: 'MAUMY FALL',
        entreprise: 'VOLKENO SARL',
        prestataire: 'HOGGY',
        date: '11/09/2021',
        statut: 'valide'
    },
    {
        id: 2,
        beneficiaire: 'MAUMY FALL',
        entreprise: 'VOLKENO SARL',
        prestataire: 'HOGGY',
        date: '11/09/2021',
        statut: 'valide'
    },
    {
        id: 3,
        beneficiaire: 'MAUMY FALL',
        entreprise: 'VOLKENO SARL',
        prestataire: 'HOGGY',
        date: '11/09/2021',
        statut: 'valide'
    },
    {
        id: 4,
        beneficiaire: 'MAUMY FALL',
        entreprise: 'VOLKENO SARL',
        prestataire: 'HOGGY',
        date: '11/09/2021',
        statut: 'valide'
    },
];

export default GestionSoinsAdminTableData;
