import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { DataContext } from "../../store/GlobalState";
import UseCrudConditionForm from "./UseCrudConditionForm";
import { getData } from "../../utils/fetchData";

function ConditionContent({ type }) {
  return (
    <div className="dashboard-praticien-table-recent-patient-container bg-white py-2 my-4">
      
      <div>
        <FormCondition type={type} />
      </div>
    </div>
  );
}

export default ConditionContent;

const FormCondition = ({ type }) => {
  const { state, dispatch } = useContext(DataContext);
  const { auth, conditions } = state;
  // const [selected, setSelected] = useState();
  let condition = conditions?.data?.find((item) => item?.type === type);
  const { isLoading, onSubmit, errors, contenu, handleChange, isSuccess } =
    UseCrudConditionForm(type, condition);

  const fetchData = () => {
    getData("conditions/", auth?.token)
      .then((res) => {
        console.log("res refresh",res)
        dispatch({
          type: "CONDITIONS",
          payload: {
            data: res,
            isLoading: false,
          },
        });
      })
      .then((err) => {
        dispatch({
          type: "CONDITIONS",
          payload: {
            data: [],
            isLoading: false,
          },
        });
      });
  };

  useEffect(() => {
    if (auth?.token) {
      fetchData();
    }
  }, [auth]);

//   useEffect(() => {
//     console.log(isSuccess, conditions);
//   }, [conditions, isSuccess]);

  return (
    <form onSubmit={onSubmit}>
      <div className="col-12 large-quill mb-4">
        <ReactQuill theme="snow" value={contenu} onChange={handleChange} />
        {!!errors?.text && (
          <div className="alert alert-danger epm-alert-danger closer m-t-10">
            {errors?.text?.message}
          </div>
        )}
      </div>
      <div className="flex-r">
        <button
          className="btn btn-primary crud-submit-btn w-auto"
          disabled={isLoading}
          type="submit"
        >
          {!isLoading && "Enregistrer"}
          {!!isLoading && (
            <>
              En cours &nbsp;
              <i className="fas fa-spin fa-spinner"></i>
            </>
          )}
        </button>
      </div>
    </form>
  );
};
