import React, { useContext, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
// import { DataContext } from "../store/GlobalState";
import UseCreateFicheSoins from "../GestionSoinsAssureur/UseCreateFicheSoins";
import { getDataByID } from "../../../utils/fetchData";
import { DataContext } from "../../../store/GlobalState";

const FicheSoinGenerate = () => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    watch,
    reset,
    setValue,
    clearErrors,
    isSuccess,
  } = UseCreateFicheSoins();

  const { state, dispatch } = useContext(DataContext);

  const { auth, assureur_prestataires, assureur_beneficiaires, beneficiaire } =
    state;

  //   const { auth, prestataires, prestatairebyassurance } = state;
  var [startDate, setStartDate] = React.useState(new Date());
  var [ayantdroits, setAyantdroits] = React.useState([]);

  React.useEffect(() => {
    if (formState.errors) {
      console.log("error", formState?.errors);
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState.errors]);

  useEffect(() => {
    register("date");
    setValue("date", moment(startDate).format("YYYY-MM-DD"));
    if (beneficiaire?.id) {
      register("beneficiaire");
      setValue("beneficiaire", beneficiaire?.id);
      let benef = [];
      benef.push(beneficiaire);
      getDataByID(
        "ayantdroitbybeneficiaire",
        parseInt(beneficiaire?.id),
        auth.token
      )
        .then((res) => {
          setAyantdroits([...res, ...benef]);
        })
        .catch((e) => {
          console.log("e", e);
        });
    }
  }, [beneficiaire]);

  React.useEffect(() => {
    if (isSuccess) {
      setAyantdroits([]);
      dispatch({ type: "SHOW_BENEFICIAIRE", payload: {} });
      register("date");
      setValue("date", moment(new Date()).format("YYYY-MM-DD"));
    }
  }, [isSuccess]);

  const handleDate = (date) => {
    setStartDate(date);
    register("date");
    setValue("date", moment(date).format("YYYY-MM-DD"));
    dispatch({ type: "EDIT_FICHE_DE_SOIN_BY_IPM", payload: {} });
  };

  const onSelectAyant = (e) => {
    let current = e.target.value;
    console.log("current=>", current, beneficiaire?.id);

    if (parseInt(beneficiaire?.id) === parseInt(current)) {
      console.log("benef");
    } else {
      register("ayant_droit");
      setValue("ayant_droit", current);
    }
    // props.setValue("ayant_droit", e.currentTarget.value);
  };

  const handleReset = () => {
    reset();
    setStartDate(new Date());
    setAyantdroits([]);
    dispatch({ type: "EDIT_FICHE_DE_SOIN_BY_IPM", payload: {} });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Demander une fiche de soins
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => handleReset()}
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="edit_employeur">Prestataires</label>
                    <div className="filter-item-container">
                      <select
                        {...register("prestataire")}
                        name="prestataire"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir
                        </option>
                        {assureur_prestataires?.data &&
                          assureur_prestataires?.data.map((item, index) => (
                            <option key={index} value={item.id}>
                              {" "}
                              {item.raison_sociale
                                ? item.raison_sociale
                                : item.prenom + " " + item.nom}
                            </option>
                          ))}
                      </select>

                      {formState.errors && formState.errors.prestataire && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.prestataire?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="edit_employeur">Bénéficiaire</label>
                    <div className="filter-item-container">
                      <select
                        // {...register("ayantdroit")}
                        onChange={onSelectAyant}
                        name="ayantdroit"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir
                        </option>
                        {ayantdroits
                          ?.filter((b) => b?.is_active === true)
                          .map((item, index) => (
                            <option key={index} value={item.id}>
                              {" "}
                              {item.prenom + " " + item.nom}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="date_debut">Date</label>

                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {formState.errors && formState.errors.date && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.date?.message}
                      </div>
                    )}
                  </div>
                </div>

                {/* <input
                  {...register('user_type')}
                  type="hidden"
                  name="user_type"
                  value={`admin_ipm`}
                />
                <input
                  {...register('admin_type')}
                  type="hidden"
                  name="admin_type"
                  value={`ipm`}
                /> */}

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    onClick={() => handleReset()}
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  {isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FicheSoinGenerate;
