import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";
import Remboursement from "../../../Services/Remboursement";
import { getDataByID } from "../../../utils/fetchData";

function UseCreateRemboursement() {
  const { state, dispatch } = useContext(DataContext);
  let { auth } = state;

  // console.log("prestation response=>", prestations);

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [soin, setSoin] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
      "content-type": "multipart/form-data",
    },
  };

  // const ip_id = auth?.user?.id

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        date: yup.string().required("La date est requise"),
        // assurance: yup.string().required("L'assurance est requise"),
        beneficiaire: yup.string().required("L'adhérent est requis"),
        prestataire: yup.string().required("Veuillez choisir le prestataire"),
        montant: yup.string().required("Le Montant est requis"),
        typedesoin: yup.string().required("Le type de soins est requis"),
        familledesoin: yup
          .string()
          .required("La Famille de soins est requise."),
        documents_justif: yup
          .mixed()
          .required("Le document justificatif est requis"),
        show_type: yup.boolean().default(false),
        type_prestation: yup.string().when("show_type", {
          is: true,
          then: yup.string().required("le type de prestation est requis."),
        }),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState,
    clearErrors,
    getValues,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitCreateForm = async (formValues, e) => {
    var formData = new FormData();
    formData.append("document", formValues.documents_justif);
    formData.append("montant", formValues.montant);
    formData.append("date", formValues.date);
    if (auth?.user?.admin_type) {
      formData.append("assurance", auth?.user?.parent);
    } else {
      formData.append("assurance", auth?.user?.id);
    }

    if (
      !formValues?.type_prestation ||
      formValues?.type_prestation === undefined
    ) {
      formValues.type_prestation = null;
    }
    // formData.append('etat', 'new')
    setIsSuccessfullySubmitted(true);
    if (formValues.ayant_droit == formValues.beneficiaire)
      formValues.ayant_droit = null;

    console.log("data send value=>", formValues);
    var remboursementRequest = Remboursement.postCreateRemboursement(
      formValues?.prestataire,
      formValues?.beneficiaire,
      formValues?.ayant_droit ? formValues?.ayant_droit : null,
      formValues?.type_prestation,
      formValues?.typedesoin,
      formValues?.familledesoin,
      formValues?.designation,
      formData,
      requestConfig
    );
    await remboursementRequest
      .then(async (response) => {
        var prestationInfos = response;
        console.log("prestation infos", prestationInfos);
        setSoin(prestationInfos);
        console.log(isSuccessfullySubmitted);
        // prestations = [...prestations, { prestationInfos }]

        // dispatch({ type: 'ADD_PRESTATION_FICHE_DE_SOIN', payload: prestations.sort((a, b) => a.created_at < b.created_at) })

        if (auth?.user?.admin_type === "assureur") {
          await getDataByID(
            "remboursementbyipm",
            auth?.user?.parent,
            auth?.token
          )
            .then((res) => {
              dispatch({
                type: "REMBOURSEMENTS_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res?.sort((a, b) => b.id - a.id),
                },
              });
            })
            .catch((err) => console.log("erreur=>", err));
        } else {
          await getDataByID("remboursementbyipm", auth?.user?.id, auth?.token)
            .then((res) => {
              dispatch({
                type: "REMBOURSEMENTS_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res?.sort((a, b) => b.id - a.id),
                },
              });
            })
            .catch((err) => console.log("erreur=>", err));
        }

        await setIsSuccess(true);
        await e.target.reset();
        await reset();
        await window.$("#DemandeRemboursement").modal("hide");
        await toast.success("Demande de remboursement ajouté avec succès.");
        await setIsSuccessfullySubmitted(false);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setIsFormRequestError(true);
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
        // toast.error("Oupss! Une erreur est survenue lors de l'ajout.")
      });
  };

  return {
    register,
    setValue,
    getValues,
    formState,
    soin,
    setSoin,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    onSubmit: handleSubmit(onSubmitCreateForm),
    reset,
    isSuccess,
  };
}

export default UseCreateRemboursement;
