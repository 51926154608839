import React, { useContext, useState } from "react";
import { DataContext } from "../store/GlobalState";
import Beneficiaire from "../Services/Beneficiaire";
import { toast } from "react-toastify";
import { getDataByID } from "../utils/fetchData";

const ValidBeneficiaireModal = () => {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, beneficiaire } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const handleValid = async (e) => {
    e.preventDefault();
    // beneficiaire['is_active'] = !beneficiaire?.is_active
    // beneficiaire['employeur'] = beneficiaire?.employeur?.id
    // beneficiaire['assurance'] = beneficiaire?.assurance?.id
    // delete beneficiaire?.avatar
    // delete beneficiaire?.cni
    // delete beneficiaire?.certificat_marriage
    setIsLoading(true);
    var beneficiaireRequest = Beneficiaire.editBeneficiaire(
      beneficiaire.id,
      { is_active: !beneficiaire?.is_active },
      requestConfig
    );
    await beneficiaireRequest
      .then(async (res) => {
        if (res.is_active) {
          await toast.success("Participant validé !");
        } else {
          await toast.success("Participant déactivé !");
        }
        
        if (sessionStorage.getItem("userType") === "admin_ipm") {
          getDataByID("beneficiairebyassurance", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_BENEFICIAIRE",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          if (sessionStorage.getItem("userType") === "assureur") {
            getDataByID("beneficiairebyassurance", auth?.user?.id, auth.token)
              .then((res) => {
                dispatch({
                  type: "BENEFICIAIRE_ASSUREUR",
                  payload: {
                    isLoading: false,
                    data: res?.sort((a, b) => b.id - a.id),
                  },
                });
                window.$("#ActiveAssureModal").modal("hide");
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            getDataByID("beneficiairebyassurance", auth?.user?.id, auth.token)
              .then((res) => {
                dispatch({
                  type: "ADD_BENEFICIAIRE",
                  payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          }
        }
        setIsLoading(false);
        dispatch({
          type: "SHOW_BENEFICIAIRE",
          payload: {},
        });
        window.$(".valid-beneficiaire-modal").modal("hide");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la validation.");
      });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="DeletingFamilyModal"
          >
            Validation d'un participant
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "SHOW_BENEFICIAIRE", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="deleting-family-form-container">
            <div className="alert-delete-message">
              <span>
                {beneficiaire &&
                  "Êtes vous sûr de vouloir " +
                    (beneficiaire?.is_active ? "déactiver " : "valider ") +
                    " ce participant"}
                ?
              </span>
            </div>

            <div className="row delete-row">
              <div className="col-md-10 offset-md-2 crud-form-action-btn-container">
                <button
                  onClick={() =>
                    dispatch({
                      type: "SHOW_BENEFICIAIRE",
                      payload: {},
                    })
                  }
                  className="btn btn-default cancelleb-btn delete-actions"
                  data-dismiss="modal"
                >
                  Retour
                </button>
                {!isLoading ? (
                  <button
                    className="btn btn-primary crud-submit-btn delete-actions"
                    onClick={(e) => handleValid(e)}
                  >
                    Confirmer
                  </button>
                ) : (
                  <button className="btn btn-primary crud-submit-btn delete-actions">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidBeneficiaireModal;
