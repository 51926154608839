import React from "react";
import { NavLink } from "react-router-dom";
import EmptyHeader from "../Auth/EmptyHeader";
import EasyPMInMacBook from "../../images/landingpage/easypm-in-macbook.svg";
import * as IconlyPack from "react-iconly";
import Illust1 from "../../images/landingpage/illu1.png";
import Illust2 from "../../images/landingpage/illu2.png";

import EasyPMAppMobile from "../../images/landingpage/app-mobile-easypm.svg";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
import ContactForm from "./ContactForm/ContactForm";
import ReactPlayer from "react-player";
import ImgAbout from "../../images/landingpage/Play.png";
import VideoPm from "../../images/landingpage/Easypm_Couleur-1.m4v";
import EasyPm from "../../images/landingpage/imageVideo.jpeg";
import HeaderEasypm from "./HeaderEasypm";

var LandingPage = () => {
  const [showContactForm, setShowContactForm] = React.useState(false);

  const onShowAndHideContactForm = () => {
    if (showContactForm) {
      setShowContactForm(false);
    } else {
      setShowContactForm(true);
    }
  };
  return (
    <div className="landing-page-component">
      <HeaderEasypm isLandingPage={true} />
      <div className="page-content">
        <section className="page-garde-container">
           <div className="container container-padding-bottom">
           <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-8">
                <h1 className="introduction">
            EasyPM, Votre IPM à portée de main.
            <br />
            <span>Une expérience simple et dématérialisée.</span>
          </h1>
          <div className="introduction-slogan">
            <p>
              Accédez à tous les services de votre IPM sans vous déplacer, à
              tout moment du jour ou de la nuit grâce à une solution sécurisée
              en ligne.
            </p>
          </div>
          {/* <div className="introduction-action-btn-container">
            <NavLink
              to="/inscription"
              className="btn btn-default intro-action-btn"
            >
              rejoindre
            </NavLink>
          </div> */}
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                    <section className="easypm-in-macbook-image-container">
                        <div className="easypm-in-macbook-image-content">
                            <img
                            title="EasyPM"
                            src={EasyPMInMacBook}
                            alt="IPM Easy"
                            className="easypm-in-macbook-image"
                            />
                        </div>
                    </section>
             
                </div>
            </div>
          
           </div>
        </section>
        
        <section className="why-easypm-section-container">
          <div className="container">
            <div className="why-easypm-section-content">
              <h1 className="hp-section-title">Pourquoi EASYPM ?</h1>

              <div className="row why-easypm-row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 why-easypm-col-item">
                  <div className="why-easypm-item-icon-container firsty">
                    <IconlyPack.Activity
                      set="light"
                      primaryColor="white"
                      className="why-easypm-icon"
                    />
                  </div>
                  <div className="why-easypm-item-textual-container">
                    {/* <h2 className="sous-section-title">&nbs;</h2> */}
                    <div className="why-easypm-item-textual">
                      <p>
                        Avec EasyPM, fini les bons de soins, les livrets de
                        soins et les factures en format papier. Nous vous
                        proposons de numériser et de dématérialiser l'ensemble
                        de votre parcours et vous donnons un accès fiable et
                        sécurisé à l'ensemble de ces documents.
                      </p>
                      <p>
                        L'application permet également de bénéficier plus
                        facilement du tiers-payant auprès des prestataires de
                        santé agréées par votre IPM et/ou membre du réseau
                        EasyPM.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 why-easypm-col-item">
                  <div className="why-easypm-item-icon-container secondy">
                    <IconlyPack.Setting
                      set="light"
                      primaryColor="white"
                      className="why-easypm-icon"
                    />
                  </div>
                  <div className="why-easypm-item-textual-container">
                    {/* <h2 className="sous-section-title">Dictumst luctus.</h2> */}
                    <div className="why-easypm-item-textual">
                      <p>
                        L'outil dote les prestataires de moyens
                        d'authentification renforcés permettant d'identifier les
                        Adhérents ainsi que leurs ayant droits. Le prestataire
                        membre du réseau aura à sa disposition l'ensemble des
                        droits ouverts ainsi que les taux de prise en charge
                        applicables à l'IPM d'affiliation.
                      </p>
                      <p>
                        À la délivrance des soins ou des médicaments, le
                        prestataire membre du réseau a la possibilité de
                        télétransmettre la fiche de soins complétée
                        électroniquement.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 why-easypm-col-item">
                  <div className="why-easypm-item-icon-container thirdy">
                    <IconlyPack.Graph
                      set="light"
                      primaryColor="white"
                      className="why-easypm-icon"
                    />
                  </div>
                  <div className="why-easypm-item-textual-container">
                    {/* <h2 className="sous-section-title">Aliquet eu eget.</h2> */}
                    <div className="why-easypm-item-textual">
                      <p>
                        Au gestionnaire d'IPM, EasyPM offre le bénéfice d'un
                        outil transparent et sécurisé qui permet de
                        dématérialiser et télétransmettre l'ensemble des
                        documents relatifs aux salariés assurés au sein de son
                        institution (bons, livrets, factures, fiches de soins).
                      </p>
                      <p>
                        Tous les flux de la relation entre l'IPM, les Employeurs
                        et les prestataires sont numérisés dans un workflow
                        moderne et sécurisé, gage de qualité et d'efficience de
                        la relation entre les organisations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5 mt-5 player-container col-md-10 offset-md-1">
                <ReactPlayer
                  url={VideoPm}
                  width="100%"
                  height="500px"
                  playing
                  playIcon={<img src={ImgAbout} />}
                  light={EasyPm}
                  controls
                  className="easypm-player"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="functionnalities-section-container">
          <div className="container">
            <div className="functionnalities-section-content">
              <h1 className="hp-section-title">Les fonctionnalités EASYPM ?</h1>
              <div className="row functionnalities-row-item firsty d-flex align-items-center">
                <div className="col-md-6 functionnalities-col-item side1">
                  <h2 className="sous-section-title">
                    Votre IPM d'aujourd'hui
                  </h2>
                  <div className="functionnalities-content">
                    <p>
                      Découvrez le tout nouveau produit innovant et
                      technologique qui va révolutionner votre relation à l'IPM
                      : EasyPM.
                    </p>
                    <p>
                      EasyPM transforme vos usages en facilitant l'accès et la
                      gestion de toutes les informations relatives à votre
                      parcours.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 functionnalities-col-item side2">
                  <div className="functionnalities-content-image">
                    <img
                      src={Illust1}
                      alt="Fonctionnalité 1 EasyPM"
                      className="functionnalities-image-item"
                    />
                  </div>
                </div>
              </div>

              <div className="row functionnalities-row-item secondy d-flex justify-content-center">
                <div className="col-md-6 functionnalities-col-item side2">
                  <div className="functionnalities-content-image">
                    <img
                      src={Illust2}
                      alt="Fonctionnalité 1 EasyPM"
                      className="functionnalities-image-item"
                    />
                  </div>
                </div>
                <div className="col-md-6 functionnalities-col-item side1">
                  <h2 className="sous-section-title">Rapidité et autonomie</h2>
                  <div className="functionnalities-content">
                    <p>
                      Au cas où vous n’auriez pas bénéficié du 1/3 payant ou
                      lorsque les soins ont engendré une part-patient, le
                      Adhérent pourra faire des demandes et suivre en ligne
                      l'avancement des ses remboursements depuis son espace
                      (mobile ou web).
                    </p>
                    <p>
                      Par ailleurs Le bénéficiaire n'est plus obligé de passer
                      par son IPM pour retirer une fiche de soins avant une
                      prestation. En effet, l'outil offre la capacité de générer
                      en self-service une fiche de soins électronique fiable et
                      sécurisée par un QR Code. Le bénéficiaire gagne en
                      autonomie avec un accès ètendu à l'EasyPM (fiche de soins
                      24*7 sans déplacement).
                    </p>
                  </div>
                </div>
              </div>

              <div className="row functionnalities-row-item thirdy">
                <div className="col-md-6 functionnalities-col-item side1">
                  <h2 className="sous-section-title">Application Mobile</h2>
                  <div className="functionnalities-content">
                    <div>
                      L’application mobile assure un accès étendu à votre IPM et
                      une autonomie qui procurent une expérience Adhérent unique
                      : <br />
                      <ul>
                        <li>suivi des activités de soins</li>
                        <li>
                          production de fiches électroniques pour le 1/3 payant
                        </li>
                        <li>demandes de remboursements en self-service </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 functionnalities-col-item side2">
                  <div className="functionnalities-content-image easypm-app-mobile-image-container">
                    <img
                      src={EasyPMAppMobile}
                      alt="Fonctionnalité 1 EasyPM"
                      className="functionnalities-image-item easypm-app-mobile-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="testimonial-section-container">
          <div className="container">
            <div className="testimonial-section-content">
              <h1 className="hp-section-title">
                Avis des business développeur
              </h1>
              <div className="row testimonial-row">
                <div className="col-md-12 testimonial-col">
                  <Testimonial />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section-container">
          <div className="contact-light-container">
            <div className="row contact-light-row">
              <div className="col-md-6 contact-light-col textual-contact-col">
                <h3 className="helpy-title">
                  {" "}
                  Nous sommes là pour vous aider{" "}
                </h3>
              </div>
              <div className="col-md-6 contact-light-col btn-contact-col">
                <button
                  onClick={() => onShowAndHideContactForm()}
                  className="btn btn-default contact-light-btn"
                >
                  Nous contacter
                </button>
              </div>
              {showContactForm && (
                <div className="col-md-6 offset-md-6 contact-form-col">
                  <ContactForm />
                </div>
              )}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
