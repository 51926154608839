import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";
import FicheDeSoin from "../../../Services/FicheDeSoin";
import moment from "moment";
import { getDataByID } from "../../../utils/fetchData";

function UseCreateFicheSoins() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [error, setError] = React.useState({});
  const [entrepriseData, setEntrepriseData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;

  const validationSchema = useMemo(() =>
    yup.object().shape(
      {
        beneficiaire: yup.string().required("L'Assuré est requis"),
        prestataire: yup.string().required("Le prestataire est requis"),
        date: yup
          .string()
          .required("La date est requise")
          .default(moment(new Date()).format("YYYY-MM-DD")),
        ayant_droit: yup.string(),
        etat: yup.string(),
        // ordonnance_file: yup.string(),
        ordonnance_file: yup.mixed().when("showOrdonnance", {
          is: true,
          then: yup.mixed().required("L'ordonnance est requise"),
        }),
        showOrdonnance: yup.boolean().default(false),
      },
      ["showOrdonnance", "ordonnance_file"]
    )
  );

  const {
    unregister,
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    let fd = new FormData();

    fd.append("beneficiaire", formValues.beneficiaire);
    fd.append("prestataire", formValues.prestataire);
    if (formValues?.ayant_droit) {
      fd.append("ayant_droit", formValues.ayant_droit);
    }

    if (formValues?.ordonnance_file?.length) {
      fd.append("ordonnance_file", formValues.ordonnance_file[0]);
    }
    fd.append("date", formValues.date);

    if (
      formValues?.showOrdonnance === true &&
      !formValues?.ordonnance_file?.length
    ) {
      setError({ ordonnance_file: "L'ordonnance est requise" });

      setTimeout(() => {
        setError({});
      }, 5000);
    } else {
      console.log("data", formValues);
      var fichedesoinRequest = FicheDeSoin.postCreateFicheDeSoin(
        fd,
        requestConfig
      );
      await fichedesoinRequest
        .then((response) => {
          console.log("response", response);
          if (response?.status === "failure") {
            toast.error(response?.message);
            e.target.reset();
            window.$("#GenererFicheSoinModal").modal("hide");
          } else {
            var fichedesoinInfos = response;
            setEntrepriseData(fichedesoinInfos);
            if (auth?.user?.admin_type) {
              getDataByID("fichedesoinbyipm", auth?.user?.parent, auth.token)
                .then((res) => {
                  dispatch({
                    type: "FICHESOINS_ASSUREUR",
                    payload: {
                      isLoading: false,
                      data: res?.sort((a, b) => b.id - a.id),
                    },
                  });
                })
                .catch((e) => {
                  console.log("e", e);
                });
            } else {
              getDataByID("fichedesoinbyipm", auth?.user?.id, auth.token)
                .then((res) => {
                  dispatch({
                    type: "FICHESOINS_ASSUREUR",
                    payload: {
                      isLoading: false,
                      data: res?.sort((a, b) => b.id - a.id),
                    },
                  });
                })
                .catch((e) => {
                  console.log("e", e);
                });
            }

            e.target.reset();
            window.$("#GenererFicheSoinModal").modal("hide");
            toast.success("Fiche de soins ajoutée avec succès.");
          }
          reset();
          setIsSuccess(true);
          setIsSuccessfullySubmitted(false);
          setTimeout(() => {
            setIsSuccess(false);
          }, 2000);
        })
        .catch((error) => {
          console.log(error?.response);
          setIsFormRequestError(true);
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
          } else {
            if (error?.response?.status === 500) {
              toast.error(
                "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
              );
            } else if (error?.response?.status === 400) {
              toast.error(
                "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
              );
            } else {
              toast.error(
                "Une erreur est survenue, veuillez vérifier votre connexion internet !"
              );
            }
          }

          setIsSuccessfullySubmitted(false);
        });
    }
  };

  return {
    register,
    error,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    setValue,
    onSubmit: handleSubmit(onSubmitCreateForm),
    reset,
    isSuccess,
    unregister,
  };
}

export default UseCreateFicheSoins;
