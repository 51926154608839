import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";

const AjouterQuestion = (props) => {

 
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AjouterQuestion">
          Ajout d’une question
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="adresse">Questions</label>
                    <input
                      type="text"
                      placeholder="Ajouter une question"
                      name="adresse"
                      className="form-control crud-form-control"
                      id="adresse"
                    />
                  </div>
                </div>
               
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="numero_contrat">Réponses</label>
                      <textarea className="form-control crud-form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                  </div>
                </div>
                 <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn-assurance"
                      data-dismiss="modal"
                    >
                      Retour
                    </button>
                    <button className="btn  crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterQuestion;
