import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import { useQuery } from "../../../../Http/http/query";
import { useCurrentUser } from "../../../../utils/utils";
import Header from "../../../Navs/Header";
import GestionPrestataireBeneficiaireTable from "../../../Tables/Beneficiaire/GestionPrestataireBeneficiaire/GestionPrestataireBeneficiaireTable";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import AjouterPrestataireModal from "./AjouterPrestataireModal";
import "./GestionPrestataireBeneficiaire.css";
var GestionPrestataireBeneficiaire = () => {
  const user = useCurrentUser();
  const query = useQuery(null);
  const [data, setData] = React.useState([]);
  const reload = React.useCallback(() => {
    query.getData(`/prestatairebyassurance/${user?.assurance?.id}/`);
  }, [user, query]);
  React.useEffect(() => {
    if (user?.id) {
      reload();
    }
  }, [user?.id]);
  React.useEffect(() => {
    setData(query.data.filter((f) => f.agreement));
  }, [query.data]);

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Prestataires</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <GestionPrestataireBeneficiaireTable
                    user={user}
                    items={data}
                    loading={query.loading && data.length === 0}
                  />
                </div>
                <div
                  class="modal fade"
                  id="AjouterPrestataireModalBeneficiaire"
                  tabindex="-1"
                  aria-labelledby="AjouterPrestataireModalBeneficiaireLabel"
                  aria-hidden="true"
                >
                  <AjouterPrestataireModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionPrestataireBeneficiaire;
