import React from "react";
// import * as IconlyPack from 'react-iconly';
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";

var CRUDAutresAdminModal = (props) => {
  const [phone, setPhone] = React.useState("");
  const handleReset = () => {
    props.reset();
  };

  const handleSetPhone = (val) => {
    // console.log('phone', val)
    setPhone(val);
    props.register("telephone");
    props.setValue("telephone", "+" + val);
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Ajouter un admin
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={props.onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="prenom">Prénom</label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      className="form-control crud-form-control"
                      id="prenom"
                      {...props.register("prenom")}
                    />
                    {props.formState.errors &&
                      props.formState.errors.prenom && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.prenom?.message}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="nom">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      className="form-control crud-form-control"
                      id="nom"
                      {...props.register("nom")}
                    />
                    {props.formState.errors && props.formState.errors.nom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {props.formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="form-control crud-form-control"
                      id="email"
                      {...props.register("email")}
                    />
                    {props.formState.errors && props.formState.errors.email && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {props.formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="telephone">Téléphone</label>
                    {/* <input
                      type="text"
                      placeholder="Téléphone"
                      name="telephone"
                      className="form-control crud-form-control"
                      id="telephone"
                    /> */}
                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      inputClass="form-control crud-form-control"
                      country={"sn"}
                      onlyCountries={["sn"]}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    {props.formState.errors &&
                      props.formState.errors.telephone && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.telephone?.message}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      type="text"
                      placeholder="Adresse"
                      name="adresse"
                      className="form-control crud-form-control"
                      id="adresse"
                      {...props.register("adresse")}
                    />
                    {props.formState.errors &&
                      props.formState.errors.adresse && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.adresse?.message}
                        </div>
                      )}
                  </div>
                </div>
                <input
                  type="hidden"
                  name="user_type"
                  {...props.register("user_type")}
                  value="super_ipm"
                />
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  {props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRUDAutresAdminModal;
