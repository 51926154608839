import React, { useEffect, useContext } from 'react'
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from 'react-bootstrap-table'
import * as IconlyPack from 'react-iconly'
import { NavLink } from 'react-router-dom'
import AdherentAssureurTableData from './AdherentAssureurTableData'
import AdherentAssureurTableSkeleton from './AdherentAssureurTableSkeleton'
// import FactureModalAssureur from './FactureModalAssureur'
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'
import FeatherIcon from "feather-icons-react";
import Photo from '../../../../../../images/others/photo.png'
import FicheSoinAdherent from './FicheSoinAdherent'
var AdherentAssureurTable = (props) => {

 
  const [soins, setSoins] = React.useState(AdherentAssureurTableData)

  var [isPageLoading, setIsPageLoading] = React.useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false)
    }, 3000)
  }, [soins])

  var handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event')
    onClick()
  }

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    )
  }

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: 'Aucune donnée disponible',
  }
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <button className="action-btn edit-btn" title="Traitement">
          <IconlyPack.Paper
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#GenererFactureClientModal"
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.ShieldDone
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#GestionAssures"
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Password
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#factureAssureur"
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Document
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#FicheSoinAdherent"
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Show
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#factureAssureur"
          />
        </button>
      </div>
    );
  };
  
  const detailFormatter = (cell, row, id) => {
      return (
          <div>
              <NavLink to={`/details-adherent-assurance/` + row.id} style={{ color: 'rgba(0, 0, 0, 0.7)'}} className="no-link">
                  {cell}
              </NavLink>
          </div>
      )
  }
  const etatFormatter = (cell, row) => {
    if (cell === "rejete") {
      return (
        <span className="badge badge-danger easypm-badge-danger ">
          Rejeté
        </span>
      );
    }

    if (cell === "envoye") {
      return (
        <span className="badge badge-info easypm-badge-info ">Envoyé</span>
      );
    }

    if (cell === "recu") {
      return (
        <span className="badge badge-warning easypm-badge-warning ">
          Reçu
        </span>
      );
    }

    if (cell === "valide") {
      return (
        <span className="badge badge-default easypm-badge-default ">
          Validé
        </span>
      );
    }

    if (cell === "actif") {
      return (
        <span className="badge badge-success easypm-badge-success ">
          Actif
        </span>
      );
    }
};
const photoFormatter = (cell, row) => {
    //  let beneficiaire = cell.beneficiaire
      return (
        <div className="nameFormatter" >
          <img src={Photo} className="photo-assures" />
          {cell}
          
        </div>
      );
   
  };
  return (
    <div className="child-table-container">
        <div className="row justify-content-end py-4">
        <div className="col-xl-6 col-lg-6 col-md-4"></div>
            <div className="col-xl-3 col-lg-3 col-md-4">
                <button className="btn-assure-telecharger">
                <IconlyPack.Download
                    set="light"
                    primaryColor="#fff"
                    className="action-btn-icon action-edit-icon"
                />
                <span className="pl-2">Télécharger le modèle CSV</span>
                </button>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4">
                <button className="btn-assure-import">
                <IconlyPack.Download
                    set="light"
                    primaryColor="#005D86"
                    className="action-btn-icon action-edit-icon"
                />
                <span className="pl-2">Import CSV</span>
                </button>
            </div>
        </div>
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <AdherentAssureurTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={soins}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="100"
                    dataField="photo"
                    dataFormat={(cell, row) => photoFormatter(cell, row)}
                  >
                    Photo
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="100"
                    dataField="numero_contrat"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    N° contrat
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="nom"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                    dataFormat={(cell, row) => detailFormatter(cell, row)}
                  >
                    Nom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="entreprise"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Entreprise 
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="date"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Date de fin
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="statut"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                    dataFormat={(cell, row) => etatFormatter(cell, row)}
                  >
                    Statut
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="200"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          {/* <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionSoinModal"
            tabIndex="-1"
            aria-labelledby="GestionSoinModalLabel"
            data-backdrop="static"
            aria-hidden="true"
        >
            <GestionSoinAssureurModal />
        </div>*/}
        <div
            className="modal fade easypm-modal add-soin-modal"
            id="FicheSoinAdherent"
            tabIndex="-1"
            aria-labelledby="FicheSoinAdherentLabel"
            data-backdrop="static"
            aria-hidden="true"
        >
            <FicheSoinAdherent />
        </div> 
        </div>
      </div>
    </div>
  )
}

export default AdherentAssureurTable
