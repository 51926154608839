import moment from "moment";
import React, { useEffect, useState } from "react";
import baseUrl from "../../Http/backend-base-url";
import { formatMoney } from "../../utils/utils";
import { DataContext } from "../store/GlobalState";

const DetailFactureModal = (props) => {
  const { state, dispatch } = React.useContext(DataContext);
  const { facture } = state;

  const [partIpmTotal, setPartIpmTotal] = useState(0);
  const [partBenefTotal, setPartBenefTotal] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (facture?.id && facture.prestations?.length) {
      let total_mont = 0;
      let total_ipm = 0;
      let total_benef = 0;
      facture.prestations.forEach((element) => {
        total_mont += parseInt(element.montant);
        total_ipm += parseInt(element.montant_rembourse);
        total_benef += parseInt(element.montant_retenu);
      });
      setPartBenefTotal(total_benef);
      setPartIpmTotal(total_ipm);
      setTotal(total_mont);
    } else {
      setPartBenefTotal(0);
      setPartIpmTotal(0);
      setTotal(0);
    }
  }, [facture]);

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Detail facture
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "EDIT_FACTURE", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="row" style={{ marginBottom: "1.5rem" }}>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group" style={{ marginBottom: ".2rem" }}>
                    <strong>Ipm: </strong>
                    <span className="crud-form-control">
                      {facture?.ipm?.raison_sociale || ""}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group" style={{ marginBottom: ".2rem" }}>
                    <strong>Adresse: </strong>
                    <span className="crud-form-control">
                      {facture?.ipm?.adresse || ""}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group" style={{ marginBottom: ".2rem" }}>
                    <strong>Telephone: </strong>
                    <span className="crud-form-control">
                      {facture.ipm?.telephone || ""}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group" style={{ marginBottom: "1rem" }}>
                    <strong>Email: </strong>
                    <span className="crud-form-control">
                      {facture.ipm?.email || ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="title-facture">
              <span>Facture</span>
            </div>
          </div>
          <div className="col-md-12">
            <table className="table table-striped table-facture">
              <thead>
                <tr className="col-tr">
                  <th scope="col" className="col-th col-fact">
                    {facture?.entreprise
                      ? "Société"
                      : facture?.prestataire
                      ? "Prestataire"
                      : null}
                  </th>
                  <th scope="col" className="col-th col-fact">
                    {facture?.entreprise
                      ? facture?.entreprise?.raison_sociale
                      : facture?.prestataire
                      ? facture?.prestataire?.prenom
                        ? facture?.prestataire?.prenom +
                          " " +
                          facture?.prestataire?.nom
                        : facture?.prestataire?.raison_sociale
                      : null}
                  </th>
                </tr>

                <tr className="col-tr">
                  <th scope="col" className="col-th col-fact">
                    Date la facturation
                  </th>
                  <th scope="col" className="col-th col-fact">
                    {moment(facture?.created_at).format("DD/MM/YYYY")}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="col-md-12">
            <div className="row">
              {/* <div className="col-md-12">
                <div className="form-group">
                  <strong className="label-title">Objet:</strong>
                  &nbsp;
                  <span className="crud-form-control">
                    Reglements des sommes dues:{" "}
                    <b>{moment(facture?.date_debut).format("DD/MM/YYYY")}</b> au{" "}
                    <b>{moment(facture?.date_fin).format("DD/MM/YYYY")}</b>
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <strong className="">Mesdames, Messieurs</strong>
                  <br />
                  <span className="crud-form-control p-0">
                    Veuillez trouver ci-dessous le montant des sommes à régler
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <strong className="label-title">COTISATIONS DU::</strong>
                  &nbsp;
                  <span className="crud-form-control">
                    <b>{moment(facture?.date_debut).format("DD/MM/YYYY")}</b> au{" "}
                    <b>{moment(facture?.date_fin).format("DD/MM/YYYY")}</b>
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <strong className="label-title">FRAIS MEDICAUX DU:</strong>
                  &nbsp;
                  <span className="crud-form-control">
                    <b>{moment(facture?.date_debut).format("DD/MM/YYYY")}</b> au{" "}
                    <b>{moment(facture?.date_fin).format("DD/MM/YYYY")}</b>
                  </span>
                </div>
              </div>
              <div
                className="sub-title sub-title-fact"
                style={{ fontSize: "15px" }}
              >
                <strong className="label-title label-title-fact">
                  Numéro Facture Adhérent:
                </strong>
                &nbsp;
                <strong>{facture?.id}</strong>
              </div>
              <div className="col-md-12">
                <table className="table table-striped table-facture">
                  <thead>
                    <tr className="col-tr">
                      <th scope="col" className="col-th col-fact">
                        Nombre de participants{" "}
                      </th>
                      <th scope="col" className="col-th col-fact">
                        Droits d'adhesion
                      </th>
                      <th scope="col" className="col-th col-fact">
                        Montant de la côtisation
                      </th>
                      <th scope="col" className="col-th col-fact">
                        Frais Medicaux
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    <td className="col-td col-fact">{facture?.participants}</td>
                    <td className="col-td col-fact">{formatMoney(parseInt(facture?.cotisations) / parseInt(facture?.participants))}</td>
                    <td className="col-td col-fact">{formatMoney(facture?.cotisations)}</td>
                    <td className="col-td col-fact">{formatMoney(facture?.frais_medicaux)}</td>
                  </tbody>
                </table>
              </div>
              <div className="w-70" style={{ marginLeft: "15%" }}>
                <table className="table table-striped table-facture1">
                  <thead>
                    <tr className="col-tr">
                      <th scope="col" className="col-th col-fact1">
                        <strong>Total à régler</strong>
                      </th>
                      <td className="col-td col-fact1">{formatMoney(facture?.total)}</td>
                    </tr>
                    <tr>
                      <th scope="col" className="col-th col-fact1">
                        <strong>Réglement effectué</strong>
                      </th>
                      <td className="col-td col-fact1">0</td>
                    </tr>
                    <tr>
                      <th scope="col" className="col-th col-fact1">
                        <strong>Restant dû</strong>
                      </th>
                      <td className="col-td col-fact1">{formatMoney(facture?.total)}</td>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="col-md-12 mb-3">
                <strong>
                  Nous vous remercions de bien vouloir procéder au réglement de
                  cette facture dans les délais indiqués ci-dessous:
                </strong>
              </div>
              <div
                className="w-70 mb-3"
                style={{
                  marginLeft: "15%",
                  border: "1px solid",
                  padding: "10px",
                }}
              >
                <span className="fw-500">
                  Les cotisations et les frais médicaux sont payables au plus
                  tard le{" "}
                  {moment(facture?.created_at)
                    .add(15, "days")
                    .format("DD/MM/YYYY")}
                  .<br /> Les droits d'adhesion sont payables avant toute
                  inscription.{" "}
                </span>
              </div>
              <div className="col-md-12 mb-3">
                <strong>
                  Veuillez agréer, Mesdames,Messieurs, nos plus sincères
                  salutations.
                </strong>
              </div>
              
               */}
              <div className="sub-title sub-title-fact">
                <strong className="label-title label-title-fact">
                  Etat Récapitulatif des Prestations
                </strong>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <strong className="label-title">
                    {facture?.entreprise
                      ? facture?.entreprise?.raison_sociale
                      : facture?.prestataire
                      ? facture?.prestataire?.prenom
                        ? facture?.prestataire?.prenom +
                          " " +
                          facture?.prestataire?.nom
                        : facture?.prestataire?.raison_sociale
                      : null}
                  </strong>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ marginBottom: ".2rem" }}>
                  <strong>
                    {facture?.entreprise
                      ? "Société"
                      : facture?.prestataire
                      ? "Prestataire"
                      : null}
                    :{" "}
                  </strong>
                  <span className="crud-form-control">
                    {facture?.entreprise
                      ? facture?.entreprise?.raison_sociale
                      : facture?.prestataire
                      ? facture?.prestataire?.prenom
                        ? facture?.prestataire?.prenom +
                          " " +
                          facture?.prestataire?.nom
                        : facture?.prestataire?.raison_sociale
                      : null}
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ marginBottom: ".2rem" }}>
                  <strong>Adresse: </strong>
                  <span className="crud-form-control">
                    {facture?.entreprise
                      ? facture?.entreprise?.adresse
                      : facture?.prestataire
                      ? facture?.prestataire?.adresse
                      : null}
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ marginBottom: ".2rem" }}>
                  <strong>Telephone: </strong>
                  <span className="crud-form-control">
                    {facture?.entreprise
                      ? facture?.entreprise?.telephone
                      : facture?.prestataire
                      ? facture?.prestataire?.telephone
                      : null}
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ marginBottom: "1rem" }}>
                  <strong>Email: </strong>
                  <span className="crud-form-control">
                    {facture?.entreprise
                      ? facture?.entreprise?.email
                      : facture?.prestataire
                      ? facture?.prestataire?.email
                      : null}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <strong className="label-title">Facture du:</strong>
                  &nbsp;
                  <span className="crud-form-control">
                    <b>{moment(facture?.date_debut).format("DD/MM/YYYY")}</b> au{" "}
                    <b>{moment(facture?.date_fin).format("DD/MM/YYYY")}</b>
                  </span>
                </div>
              </div>
              <div className="col-md-6" style={{ textAlign: "end" }}>
                <strong className="crud-form-control">
                  {moment(facture?.created_at).format("DD/MM/YYYY")}
                </strong>
              </div>
              <div className="col-md-12">
                <table className="table table-striped ">
                  <thead className="recap-header">
                    <tr>
                      <th scope="col" className="recap-th">
                        Matricule
                      </th>
                      <th scope="col" className="recap-th">
                        Prénom et Nom
                      </th>
                      <th scope="col" className="recap-th">
                        Date
                      </th>
                      <th scope="col" className="recap-th">
                        Total &nbsp;
                      </th>
                      <th scope="col" className="recap-th">
                        Part IPM
                      </th>
                      <th scope="col" className="recap-th">
                        Part Adhérent
                      </th>
                      {/* <th scope="col" className="recap-th">Observations</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {facture?.prestations?.length ? (
                      facture?.prestations
                        .sort((a, b) => new Date(a?.id) - new Date(b?.id))
                        .map((prestation) => (
                          <tr key={prestation?.id}>
                            {/* <td className="recap-th">
                              {prestation?.beneficiaire?.matricule}
                            </td> */}
                            <th scope="row" className="recap-th">
                              {prestation?.beneficiaire?.matricule}
                            </th>
                            <td className="recap-th">
                              <span className="name">
                                {" "}
                                {prestation?.beneficiaire?.prenom +
                                  " " +
                                  prestation?.beneficiaire?.nom}{" "}
                              </span>{" "}
                              <br />
                              <span className="entreprise">
                                {prestation?.prestataire?.raison_sociale ||
                                  prestation?.prestataire?.prenom +
                                    " " +
                                    prestation?.prestataire?.nom}
                              </span>
                            </td>
                            <td className="recap-th">
                              {moment(prestation?.date).format("DD/MM/YYYY")}
                            </td>
                            <td className="recap-th">
                              {formatMoney(prestation?.montant)}
                            </td>
                            <td className="recap-th">
                              {formatMoney(prestation?.montant_rembourse)}
                            </td>
                            <td className="recap-th">
                              {formatMoney(prestation?.montant_retenu)}
                            </td>
                            {/* <td className="recap-th"></td> */}
                          </tr>
                        ))
                    ) : (
                      <tr
                        style={{
                          textAlign: "center",
                          backgroundColor: "transparent",
                          fontSize: "20px",
                        }}
                      >
                        {/* <td className="col-td">Aucune donnée disponible</td> */}
                        <td className="totalText col-td" colSpan="6">
                          <span>Aucune prestation disponible</span>
                        </td>
                      </tr>
                    )}
                    <tr
                      className="fw-400"
                      style={{ color: "black", backgroundColor: "whitesmoke" }}
                    >
                      <td className="totalText col-td" colSpan="3">
                        <strong>Total Général:</strong>
                      </td>
                      <td className="col-td">{formatMoney(parseInt(total))}</td>
                      <td className="col-td">
                        {formatMoney(parseInt(partIpmTotal))}
                      </td>
                      <td className="col-td">
                        {formatMoney(parseInt(partBenefTotal))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-100 mb-4 d-flex" style={{ marginLeft: "75%" }}>
                <div
                  className="form-group visa-col visa-left"
                  style={{ display: "inline" }}
                >
                  <strong className="label-title">LE GERANT</strong>
                  <br />
                  <span
                    className="crud-form-control"
                    style={{ padding: "0px" }}
                  >
                    <img
                      src={
                        facture?.ipm?.signature &&
                        baseUrl + facture?.ipm?.signature
                      }
                      alt={
                        facture?.ipm?.signature &&
                        baseUrl + facture?.ipm?.signature
                      }
                      style={{ marginTop: "10px", width: "180px" }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailFactureModal;
