import React, { useEffect } from "react";
import { DataContext } from "../store/GlobalState";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

registerLocale("fr", fr);

const CrudExceptionModal = (props) => {
  const { state } = React.useContext(DataContext);
  const {
    auth,
    beneficiaires,
    prestatairebyassurance,
    entreprises_by_ipm,
    ipms,
    prestatairebyentreprise,
    beneficiairesByEntreprise,
    prestataires,
  } = state;
  const [cles, setCles] = React.useState([{ value: "", label: "" }]);
  const [val, setVal] = React.useState("");
  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());
  const [isValid, setIsValid] = React.useState(true);

  React.useEffect(() => {
    props.register("date_debut");
    props.setValue("date_debut", moment(new Date()).format("YYYY-MM-DD"));

    props.register("date_fin");
    props.setValue("date_fin", moment(new Date()).format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    props.register("type");
    props.setValue("type", "ipm");
    if (ipms?.length) {
      setCles(
        ipms.map((b) => {
          return {
            label: b.raison_sociale,
            value: b.id,
          };
        })
      );
    }
  }, [ipms]);

  const handleDate = (date) => {
    setStartDate(date);
    props.register("date_debut");
    props.setValue("date_debut", moment(date).format("YYYY-MM-DD"));
    if (endDate && date > endDate) {
      setIsValid(false);
    }
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      props.register("date_fin");
      props.setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };
  const onSelect = (e) => {
    console.log(e.value);
    setVal(e.value);
    props.register("cle");
    props.setValue("cle", e.value);
  };

  useEffect(() => {
    if (props.getValues("type") === undefined) {
      setCles([]);
      setVal("");
      props.register("cle");
      props.setValue("cle", "");
    }
  }, [auth, props.register, props]);

  const handleSelect = (e) => {
    props.register("type");
    props.setValue("type", e.currentTarget.value);
    if (e.target.value === "beneficiaire") {
      if (auth?.user?.user_type !== "entreprise") {
        setCles(
          beneficiaires
            .filter((b) => b?.is_active === true)
            .map((b) => {
              return {
                label: b.prenom + " " + b.nom,
                value: b.id,
              };
            })
        );
      } else {
        setCles(
          beneficiairesByEntreprise
            .filter((b) => b?.is_active === true)
            .map((b) => {
              return {
                label: b.prenom + " " + b.nom,
                value: b.id,
              };
            })
        );
      }
    }

    if (e.target.value === "entreprise") {
      if (auth?.user?.user_type !== "entreprise") {
        setCles(
          entreprises_by_ipm.map((b) => {
            return {
              label: b.raison_sociale,
              value: b.id,
            };
          })
        );
      } else {
        setCles([
          {
            label: auth?.user?.raison_sociale,
            value: auth?.user?.id,
          },
        ]);
      }
    }

    if (e.target.value === "ipm") {
      if (
        auth?.user?.user_type !== "entreprise" &&
        auth?.user?.user_type !== "prestataire"
      ) {
        setCles([
          {
            label: auth?.user?.raison_sociale,
            value: auth?.user?.id,
          },
        ]);
      } else {
        setCles(
          ipms.map((b) => {
            return {
              label: b.raison_sociale,
              value: b.id,
            };
          })
        );
      }
    }

    if (e.target.value === "prestataire") {
      if (auth?.user?.user_type !== "entreprise") {
        setCles(
          prestatairebyassurance.map((b) => {
            return {
              label:
                (b?.raison_sociale
                  ? b?.raison_sociale
                  : b?.prenom + " " + b?.nom) || "",
              value: b.id || "",
            };
          })
        );
      } else {
        setCles(
          prestataires.map((b) => {
            return {
              label:
                (b?.raison_sociale
                  ? b?.raison_sociale
                  : b?.prenom + " " + b?.nom) || "",
              value: b.id || "",
            };
          })
        );
      }
    }
  };

  useEffect(() => {
    if (props.isSuccess) {
      handleReset();
    }
  }, [props.isSuccess]);

  const handleReset = () => {
    setCles([]);
    setVal("");
    setEndDate(new Date());
    setStartDate(new Date());
    props.reset();
    props.register("cle");
    props.setValue("cle", "");
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Ajouter une exception
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => handleReset()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={props?.onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="type">Type d'exception</label>
                    <div className="filter-item-container">
                      <select
                        name="type"
                        id="type"
                        // {...props.register("type")}
                        defaultValue={
                          auth?.user?.user_type === "prestataire" ? "ipm" : ""
                        }
                        onChange={handleSelect}
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir un type
                        </option>
                        {auth?.user?.user_type !== "entreprise" &&
                          auth?.user?.user_type !== "admin_ipm" && (
                            <option value="ipm">Assurance/Ipm</option>
                          )}
                        {auth?.user?.user_type !== "prestataire" && (
                          <>
                            <option value="beneficiaire">
                              {auth?.user?.user_type === "entreprise"
                                ? "Employé"
                                : "Participant"}
                            </option>
                            <option value="entreprise">Adhérent</option>
                            <option value="prestataire">Prestataire</option>
                          </>
                        )}
                      </select>
                      {props.formState.errors &&
                        props.formState.errors.type && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.type?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="libelle">Libellé</label>
                    <input
                      type="text"
                      placeholder="Libellé"
                      name="libelle"
                      className="form-control crud-form-control"
                      id="libelle"
                      {...props.register("libelle")}
                    />
                    {props.formState.errors &&
                      props.formState.errors.libelle && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.libelle?.message}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Objet de l’opposition</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner l'objet de l’opposition"
                        name="cle"
                        defaultValues=""
                        onChange={(e) => onSelect(e)}
                        options={cles}
                      />
                      {props.formState.errors && props.formState.errors.cle && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.cle?.message}
                        </div>
                      )}
                    </div>
                    {auth?.user?.user_type !== "entreprise" && (
                      <input
                        {...props.register("ipm")}
                        type="hidden"
                        name="ipm"
                        value={props?.ipm?.id || ""}
                      />
                    )}

                    {auth?.user?.user_type === "entreprise" && (
                      <input
                        {...props.register("entreprise")}
                        type="hidden"
                        name="entreprise"
                        value={auth?.user?.id || ""}
                      />
                    )}

                    {auth?.user?.user_type === "prestataire" && (
                      <input
                        {...props.register("prestataire")}
                        type="hidden"
                        name="prestataire"
                        value={auth?.user?.id || ""}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required crud-interm prestataire-side">
                    <label htmlFor="date_debut">Date de début</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date_debut"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {props.formState.errors &&
                      props.formState.errors.date_debut && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-3">
                          {props.formState.errors.date_debut?.message}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required crud-interm prestataire-side">
                    <label htmlFor="date_fin">Date de Fin</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={endDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date_fin"
                      locale="fr"
                      onChange={(date) => handleDateFin(date)}
                    />
                    {props.formState.errors &&
                      props.formState.errors.date_fin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-3">
                          {props.formState.errors.date_fin?.message}
                        </div>
                      )}
                    {!isValid && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        La date de fin ne doit pas être inférieure à la date de
                        début.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!props?.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrudExceptionModal;
