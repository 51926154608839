import React, { useContext, useEffect } from "react";
import Header from "../../Navs/Header";
import NavBarVerticalV2 from "../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import { NavLink, Link, useHistory } from "react-router-dom";
import DefaultAvatar from "../../../images/others/default-profile-avatar.png";
import Epouses from "../../Tables/Beneficiaires/Epouses";
import Enfants from "../../Tables/Beneficiaires/Enfants";
import AddFamilyModal from "../../Modals/AddFamilyModal";
import { useParams } from "react-router-dom";
import moment from "moment";
import UseAddAyantDroitForm from "./UseAddAyantDroitForm";
import { DataContext } from "../../store/GlobalState";
import EditFamilyModal from "../../Modals/EditFamilyModal";
import ShowFamilyModal from "../../Modals/ShowFamilyModal";
import beneficiaire from "../../Services/Beneficiaire";
import { toast } from "react-toastify";
import CRUDBeneficiaireModal from "../../Modals/CRUDBeneficiaireModal";
import UseEditBeneficiaireForm from "../IPM/GestionBeneficiaires/UseEditBeneficiaireForm";
import BeneficiaireProfileSkeleton from "./BeneficiaireProfileSkeleton";
import DeleteFamilyModal from "../../Modals/DeleteFamilyModal";
import ValidFamilyModal from "../../Modals/ValidFamilyModal";
import QrCodeModal from "../../Modals/QrCodeModal";
import { postData } from "../../utils/fetchData";
import ShowCarnetModal from "../../Modals/ShowCarnetModal";
import AlimentSoinModal from "../../Modals/AlimentSoinModal";
import ShowFicheFileModal from "../../Modals/ShowFicheFileModal";
import PrestationsByBeneficiaire from "../IPM/PrestationsByBeneficiaire/PrestationsByBeneficiaire";
import baseUrl from "../../../Http/backend-base-url";
import AddFicheModal from "../../Modals/AddFicheModal";
import GenereFicheModal from "../../Modals/GenereFicheModal";
import Swal from "sweetalert2";

const BeneficiaireProfile = (props) => {
  const {
    register,
    onSubmit,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
    setIsFormRequestError,
    isFormRequestError,
    setValue,
    reset,
  } = UseAddAyantDroitForm();
  let userType = window.sessionStorage.getItem("userType");
  let token = window.sessionStorage.getItem("authToken");
  const [showSoin, setShowSoin] = React.useState(false);
  const [carnet, setCarnet] = React.useState("");
  const [avatar, setAvatar] = React.useState(null);
  var [isLoading, setIsLoading] = React.useState(false);
  var [isLoad, setIsLoad] = React.useState(false);
  const [notIn, setNotIn] = React.useState(false);
  const [ayantDroit_type, setAyantDroit_type] = React.useState("");

  const editBenefRequest = UseEditBeneficiaireForm();

  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };
  var [beneficiaireInfos, setBeneficiaireInfos] = React.useState([]);
  const { state, dispatch } = useContext(DataContext);
  const {
    entreprises,
    fichevalidbyprestataire,
    beneficiaires,
    beneficiairesByEntreprise,
    auth,
  } = state;
  const [fiche, setFiche] = React.useState();
  // const { beneficiaire } = state
  const history = useHistory();
  const params = useParams();
  useEffect(() => {
    if (isSuccessfullySubmitted) {
      setTimeout(() => {
        setIsSuccessfullySubmitted(false);
      }, 5000);
    }

    if (isFormRequestError) {
      setIsSuccessfullySubmitted(false);
      //toast.error("Oupss! Une erreur est survenue lors de l'ajout.")
      setIsFormRequestError(true);
      setTimeout(() => {
        setIsFormRequestError(false);
      }, 5000);
    }

    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
    // eslint-disable-next-line
  }, [errors, isSuccessfullySubmitted, formState, isFormRequestError]);

  useEffect(() => {
    // console.log('params', fichevalidbyprestataire)
    if (params?.id && auth?.token) {
      onGetBeneficiaire(params.id);
    }

    setFiche(
      fichevalidbyprestataire.filter(
        (f) => f?.beneficiaire?.id === parseInt(params?.id)
      )[0]
    );
    if (notIn) {
      if (
        userType === "ipm" ||
        userType === "assurance_sante" ||
        userType === "admin_ipm"
      ) {
        history.push("/ipm/gestion-des-beneficiaires");
      }
      if (userType === "entreprise") {
        history.push("/entreprise/gestion-des-employes");
      }
      if (userType === "prestataire") {
        history.push("/prestataire/fiche-de-soin-valide");
      }
      toast.error(" Impossible d'accéder à cette fiche");
    }
    if (editBenefRequest.isSuccessfullySubmitted) {
      onGetBeneficiaire(params.id);
    }
    // eslint-disable-next-line
  }, [
    params.id,
    fichevalidbyprestataire,
    editBenefRequest.isSuccessfullySubmitted,
    clearErrors,
    setIsFormRequestError,
    setIsSuccessfullySubmitted,
    auth,
    beneficiaires,
    beneficiairesByEntreprise,
    notIn,
  ]);

  const onGetBeneficiaire = (id) => {
    var beneficiaireRequest = beneficiaire.getBeneficiaire(id, requestConfig);
    beneficiaireRequest
      .then((response) => {
        if (
          response &&
          auth?.token &&
          (beneficiaires?.length || beneficiairesByEntreprise?.length)
        ) {
          if (
            ((userType === "ipm" ||
              userType === "assurance_sante" ||
              userType === "prestataire" ||
              userType === "admin_ipm") &&
              beneficiaires.filter((ben) => ben?.id === response?.id)
                ?.length) ||
            (userType === "entreprise" &&
              beneficiairesByEntreprise.filter(
                (ben) => ben?.id === response?.id
              )?.length)
          ) {
            setBeneficiaireInfos(response);
            setIsPageLoading(false);

            setTimeout(() => {
              setAvatar(null);
            }, 2000);
          } else {
            setNotIn(true);
          }
        }
        // console.log(
        //   "resp",
        //   beneficiairesByEntreprise,
        //   response,
        //   beneficiairesByEntreprise.filter((ben) => ben?.id === response?.id)
        // );
      })
      .catch((error) => {
        console.log(error);
        setIsPageLoading(false);
      });
  };

  var handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  const onGetBenefInfos = async (item) => {
    await window.sessionStorage.removeItem("benefInfos");
    await window.sessionStorage.setItem("benefInfos", JSON.stringify(item));
    console.log("item", item);
    await dispatch({ type: "SHOW_BENEFICIAIRE", payload: item });
  };

  const onPostCarnet = (e) => {
    e.preventDefault();
    setIsLoading(true);
    window.$(".show-carnet-modal").modal("show");
    postData("carnet/", { beneficiaire: beneficiaireInfos?.id }, token)
      .then((res) => {
        console.log("res carnet", res);
        setCarnet(res);

        // window.$(".show-carnet-modal").modal("show")
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      })
      .catch((e) => {
        console.log("error carnet", e?.response);
        setIsLoading(false);
        toast.error(
          "Oupss! Une erreur est survenue lors de la génération du carnet."
        );
        window.$(".qr-code-modal").modal("hide");
      });
  };

  const handleShowSoin = (e) => {
    e.preventDefault();
    setShowSoin(!showSoin);
  };

  const changeAvatar = (e) => {
    const file = e.target.files[0];

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg"
    ) {
      toast.error("Veuiller uploader une image de type jpg ou jpeg ou png");
    }
    // console.log('file', file)

    // setData({ ...data, avatar: file })
    const fd = new FormData();
    fd.append("avatar", file);
    setAvatar(file);
    setIsLoad(true);
    var benefRequest = beneficiaire.editBeneficiaire(
      beneficiaireInfos?.id,
      fd,
      requestConfig
    );

    benefRequest
      .then(async (response) => {
        // await setIsSuccessfullySubmitted(true)
        var beneficiaireInfos = response;
        // setBeneficiaireData(beneficiaireInfos)
        console.log("response", response);

        onGetBeneficiaire(beneficiaireInfos.id);
        toast.success("Photo de profil modifié avec succès.");
        setIsLoad(false);
      })
      .catch((error) => {
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
        setIsLoad(false);
      });
  };

  const onpenModal = () => {
    if (
      moment(new Date()).isSameOrBefore(
        new Date(beneficiaireInfos?.date_fin)
      ) &&
      beneficiaireInfos?.is_active
    ) {
      window.$(".add-fiche-modal").modal("show");
    } else {
      if (beneficiaireInfos?.is_active === false) {
        Swal.fire({
          title: "Ce participant n'est pas actif.",
          text: "Veuillez l'activer avant de génèrer une fiche de soins.",
          icon: "warning",
          // showLoaderOnConfirm: true,
        });
      } else {
        Swal.fire({
          title: "La date de fin de validité de ce participant est dépassée.",
          icon: "warning",
          // showLoaderOnConfirm: true,
        });
      }
    }
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row breadcrumbs-and-actions-row">
          {userType !== "prestataire" && (
            <>
              <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 breadcrumbs-container">
                <NavLink to="/ipm/dashboard" className="brdc-item">
                  {" "}
                  Dashboard{" "}
                </NavLink>{" "}
                &nbsp;
                <IconlyPack.ChevronRight
                  set="light"
                  primaryColor="rgba(0, 0, 0, 0.7)"
                />
                <NavLink
                  to="/ipm/gestion-des-beneficiaires"
                  className="brdc-item"
                >
                  {" "}
                  Participants{" "}
                </NavLink>{" "}
                &nbsp;
                <IconlyPack.ChevronRight
                  set="light"
                  primaryColor="rgba(0, 0, 0, 0.7)"
                />
                <span
                  className="brdc-item active-brdc-item"
                  style={{ cursor: showSoin ? "pointer" : "normal" }}
                  onClick={() => {
                    if (showSoin) {
                      setShowSoin(false);
                    }
                  }}
                >
                  {" "}
                  {beneficiaireInfos?.prenom + " " + beneficiaireInfos?.nom}
                </span>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12  status-header-actions-container">
                <div className="status-container">
                  <span className="status-label">Statut : </span> &nbsp;
                  <span className="status-value">
                    {beneficiaireInfos?.is_active ? "ACTIF" : "NON ACTIF"}
                  </span>
                </div>
                <div className="header-actions-container">
                  <div
                    className="header-actions-item generate-card-container"
                    style={{ cursor: "pointer" }}
                    onClick={onPostCarnet}
                    // data-toggle="modal"
                    // data-target=".qr-code-modal"
                  >
                    <IconlyPack.Wallet
                      set="bold"
                      primaryColor="#A2A2A2"
                      className="header-actions-btn-icon"
                    />
                    &nbsp;
                    <span className="header-actions-label">
                      Générer un carnet
                    </span>
                  </div>

                  <div
                    className="header-actions-item generate-card-container"
                    style={{ cursor: "pointer" }}
                    onClick={() => onpenModal()}
                    // data-toggle="modal"
                    // data-target=".add-fiche-modal"
                  >
                    <i
                      className="fa fa-qrcode fa-lg header-actions-btn-icon"
                      aria-hidden="true"
                      style={{ color: "#868383", marginTop: "0.3rem" }}
                    ></i>
                    {/* <IconlyPack.Wallet
                      set="bold"
                      primaryColor="#A2A2A2"
                      className="header-actions-btn-icon"
                    /> */}
                    &nbsp;
                    <span className="header-actions-label">
                      Générer une fiche de soins
                    </span>
                  </div>

                  <div className="header-actions-item soin-hd-container">
                    <IconlyPack.Document
                      set="bold"
                      primaryColor="#A2A2A2"
                      className="header-actions-btn-icon"
                    />
                    &nbsp;
                    <Link
                      // to={`/ipm/soins-beneficiaire/${params.id}`}
                      to="#"
                      onClick={(e) => handleShowSoin(e)}
                      className="header-actions-label"
                    >
                      Soins
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 ben-profile-container">
            <div className="page-body-container">
              {!showSoin ? (
                <div className="row beneficiaire-profile-row">
                  <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 profile-infos-perso-container profile-item">
                    <div className="profile-infos-ben">
                      <h2 className="section-light-title">
                        <div className="light-title">Fiche du participant</div>
                        {userType !== "prestataire" && (
                          <div className="bnf-update-infos-btn-container">
                            <button
                              className="bnf-edit-btn"
                              title="Modification"
                            >
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#C8C8C8"
                                className="action-btn-icon action-edit-icon"
                                title="Modifier les infos du bénéciaire"
                                data-toggle="modal"
                                data-target=".edit-beneficiaire-modal"
                                onClick={() =>
                                  onGetBenefInfos(beneficiaireInfos)
                                }
                              />
                            </button>
                          </div>
                        )}

                        {userType === "prestataire" && (
                          <div className="bnf-update-infos-btn-container">
                            {fichevalidbyprestataire.filter(
                              (f) =>
                                f?.beneficiaire?.id === beneficiaireInfos.id
                            )?.length ? (
                              <>
                                <button
                                  className="bnf-edit-btn"
                                  title="Alimenter la fiche"
                                >
                                  <IconlyPack.PaperPlus
                                    set="light"
                                    primaryColor="#C8C8C8"
                                    className="action-btn-icon action-edit-icon"
                                    title="Modifier les infos du bénéciaire"
                                    data-toggle="modal"
                                    data-target=".alim-soin-modal"
                                    onClick={() =>
                                      onGetBenefInfos(beneficiaireInfos)
                                    }
                                  />
                                </button>
                                {fiche && fiche?.file_pdf && (
                                  <button
                                    className="action-btn show-btn"
                                    title="Voir la fiche"
                                  >
                                    <IconlyPack.Paper
                                      set="light"
                                      primaryColor="#C8C8C8"
                                      className="action-btn-icon action-show-icon"
                                      data-toggle="modal"
                                      data-target=".show-fiche-file-modal"
                                      onClick={() =>
                                        onGetBenefInfos(beneficiaireInfos)
                                      }
                                    />
                                  </button>
                                )}
                              </>
                            ) : (
                              <label
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                }}
                              >
                                Pas de fiche de soin.
                              </label>
                            )}
                          </div>
                        )}
                      </h2>
                      {isPageLoading && <BeneficiaireProfileSkeleton />}
                      {!isPageLoading && (
                        <>
                          <div className="profile-avatar-container">
                            <img
                              // src={DefaultAvatar}
                              src={
                                avatar
                                  ? URL.createObjectURL(avatar)
                                  : beneficiaireInfos?.avatar ===
                                    "/mediafiles/avatars/default.png"
                                  ? DefaultAvatar
                                  : baseUrl + beneficiaireInfos?.avatar
                              }
                              className="profile-avatar"
                              alt="Profile"
                            />
                            {isLoad && (
                              <span className="charge">
                                <i
                                  className="fas fa-spin fa-spinner"
                                  style={{ fontSize: "20px" }}
                                ></i>
                              </span>
                            )}

                            <span>
                              <i className="fas fa-camera"></i>
                              <p>Changer</p>
                              <input
                                type="file"
                                name="avatar"
                                id="file_up"
                                accept="image/*"
                                onChange={changeAvatar}
                              />
                            </span>
                            {/* <h3 className="ben-name">
                              {' '}
                              {beneficiaireInfos?.prenom +
                                ' ' +
                                beneficiaireInfos?.nom}{' '}
                            </h3> */}
                          </div>
                          {/* <div className="avatar">
                            <img
                              src={
                                avatar
                                  ? URL.createObjectURL(avatar)
                                  : auth.user.avatar
                              }
                              alt="avatar"
                            />
                            <span>
                              <i className="fas fa-camera"></i>
                              <p>Change</p>
                              <input
                                type="file"
                                name="avatar"
                                id="file_up"
                                accept="image/*"
                                // onChange={changeAvatar}
                              />
                            </span>
                          </div> */}
                          <div className="ben-others-infos-container">
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Genre</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{beneficiaireInfos?.sexe}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Statut</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <strong>
                                  {beneficiaireInfos?.is_active
                                    ? "ACTIF"
                                    : "NON ACTIF"}
                                </strong>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Nom</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{beneficiaireInfos?.nom}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Prénom</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{beneficiaireInfos?.prenom}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Date de naissance</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {moment(
                                    new Date(
                                      beneficiaireInfos?.date_de_naissance
                                    )
                                  ).format("DD/MM/YYYY")}
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Situation Matriomiale</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {beneficiaireInfos?.situation_matrimoniale}
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Groupe Sanguin</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{beneficiaireInfos?.groupe_sanguin}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Nationalité</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {beneficiaireInfos?.nationnalite || ""}
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Téléphone</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{beneficiaireInfos?.telephone}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Email</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{beneficiaireInfos?.email}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Adresse</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{beneficiaireInfos?.adresse}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Entreprise</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {beneficiaireInfos?.employeur?.raison_sociale}
                                </span>
                              </div>
                            </div>
                            {/* <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Département</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>
                                {beneficiaireInfos?.departement !== null &&
                                  beneficiaireInfos?.departement}
                              </span>
                            </div>
                          </div> */}
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Date de création</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {moment(beneficiaireInfos?.created_at).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Date de début</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {beneficiaireInfos?.date_debut !== null &&
                                    moment(
                                      new Date(beneficiaireInfos?.date_debut)
                                    ).format("DD/MM/YYYY")}
                                </span>
                              </div>
                            </div>

                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Date de fin</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {beneficiaireInfos?.date_fin !== null &&
                                    moment(
                                      new Date(beneficiaireInfos?.date_fin)
                                    ).format("DD/MM/YYYY")}
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <div
                                className="accordion w-100"
                                id="accordionExample1"
                              >
                                {beneficiaireInfos?.cni ? (
                                  <>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        id="headingOne"
                                        style={{ textAlign: "left" }}
                                      >
                                        <h5 className="mb-0">
                                          <button
                                            className="bloc-text-profil"
                                            style={{ background: "none" }}
                                            type="button"
                                            data-toggle="collapse"
                                            data-target={`#collapse${1}`}
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                          >
                                            CNI
                                          </button>
                                        </h5>
                                      </div>

                                      <div
                                        id={`collapse${1}`}
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordionExample1"
                                      >
                                        <div
                                          className="card-body"
                                          style={{ height: "700px" }}
                                        >
                                          <iframe
                                            className="responsive-iframe w-100"
                                            src={
                                              baseUrl + beneficiaireInfos?.cni
                                            }
                                            style={{ height: "100%" }}
                                            title="CNI"
                                          ></iframe>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <div
                                className="accordion w-100"
                                id="accordionExample1"
                              >
                                {beneficiaireInfos?.certificat_marriage ? (
                                  <>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        id="headingOne"
                                        style={{ textAlign: "left" }}
                                      >
                                        <h5 className="mb-0">
                                          <button
                                            className="bloc-text-profil"
                                            style={{ background: "none" }}
                                            type="button"
                                            data-toggle="collapse"
                                            data-target={`#collapse${2}`}
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                          >
                                            Certificat de mariage
                                          </button>
                                        </h5>
                                      </div>

                                      <div
                                        id={`collapse${2}`}
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordionExample1"
                                      >
                                        <div
                                          className="card-body"
                                          style={{ height: "700px" }}
                                        >
                                          <iframe
                                            className="responsive-iframe w-100"
                                            src={
                                              baseUrl +
                                              beneficiaireInfos?.certificat_marriage
                                            }
                                            style={{ height: "100%" }}
                                            title="Certificat"
                                          ></iframe>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 profile-infos-family-container profile-item">
                    <div className="wifes-infos-container">
                      <div className="page-first-row-container">
                        <div className="page-title-container">
                          <h1 className="page-title">Conjoint(e)</h1>
                        </div>
                        {userType !== "prestataire" && (
                          <div
                            className="adding-item-container linkable"
                            data-toggle="modal"
                            data-target=".add-family-modal"
                            onClick={() => setAyantDroit_type("epouse")}
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="gray"
                              />
                            </span>
                            <span className="adding-item-label">Ajouter</span>
                          </div>
                        )}
                      </div>
                      <div className="epouses-table-container">
                        <Epouses
                          isSuccessfullySubmitted={isSuccessfullySubmitted}
                          beneficiaireID={beneficiaireInfos?.id}
                        />
                      </div>
                    </div>
                    <div className="childs-infos-container">
                      <div className="page-first-row-container">
                        <div className="page-title-container">
                          <h1 className="page-title">Enfants</h1>
                        </div>
                        {userType !== "prestataire" && (
                          <div
                            className="adding-item-container linkable"
                            data-toggle="modal"
                            data-target=".add-family-modal"
                            onClick={() => setAyantDroit_type("enfant")}
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="gray"
                              />
                            </span>
                            <span className="adding-item-label">Ajouter</span>
                          </div>
                        )}
                      </div>
                      <div className="epouses-table-container">
                        <Enfants
                          isSuccessfullySubmitted={isSuccessfullySubmitted}
                          beneficiaireID={beneficiaireInfos?.id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <PrestationsByBeneficiaire
                  fullName={
                    beneficiaireInfos?.prenom + " " + beneficiaireInfos?.nom
                  }
                  showSoin={showSoin}
                  setShowSoin={setShowSoin}
                />
              )}

              {/* teste */}
            </div>
            {/* Modal */}
            <div
              className="modal fade easypm-modal add-family-modal"
              id="AddModalItem"
              tabIndex="-1"
              aria-labelledby="AddModalItemLabel"
              aria-hidden="true"
              data-backdrop="static"
            >
              <AddFamilyModal
                type={ayantDroit_type}
                beneficiaireID={beneficiaireInfos?.id}
                beneficiaire={beneficiaireInfos}
                register={register}
                reset={reset}
                onSubmit={onSubmit}
                formState={formState}
                setValue={setValue}
                isSuccessfullySubmitted={isSuccessfullySubmitted}
                setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
              />
            </div>

            {/* Modal Edit */}
            <div
              className="modal fade easypm-modal edit-family-modal"
              id="EditModalItem"
              tabIndex="-1"
              aria-labelledby="EditModalItemLabel"
              aria-hidden="true"
              data-backdrop="static"
            >
              <EditFamilyModal beneficiaireID={beneficiaireInfos?.id} />
            </div>

            {/* Modal Show */}
            <div
              className="modal fade easypm-modal show-family-modal"
              id="ShowModalItem"
              tabIndex="-1"
              aria-labelledby="ShowModalItemLabel"
              aria-hidden="true"
              data-backdrop="static"
            >
              <ShowFamilyModal />
            </div>

            {/* Modal Delete */}
            <div
              className="modal fade easypm-modal delete-family-modal"
              id="DeleteModalItem"
              tabIndex="-1"
              aria-labelledby="DeleteModalItemLabel"
              aria-hidden="true"
              data-backdrop="static"
            >
              <DeleteFamilyModal beneficiaireID={beneficiaireInfos?.id} />
            </div>

            {/* Modal Valid */}
            <div
              className="modal fade easypm-modal valid-family-modal"
              id="DeleteModalItem"
              tabIndex="-1"
              aria-labelledby="DeleteModalItemLabel"
              aria-hidden="true"
              data-backdrop="static"
            >
              <ValidFamilyModal beneficiaireID={beneficiaireInfos?.id} />
            </div>

            <div
              className="modal fade easypm-modal edit-beneficiaire-modal"
              id="EditBenefModalItem"
              tabIndex="-1"
              aria-labelledby="EditBenefModalItemLabel"
              aria-hidden="true"
            >
              <CRUDBeneficiaireModal
                userActionType="edit"
                listEntreprises={entreprises && entreprises}
                register={editBenefRequest.register}
                reset={editBenefRequest.reset}
                onSubmit={editBenefRequest.onSubmit}
                setValue={editBenefRequest.setValue}
                formState={editBenefRequest.formState}
                isSuccessfullySubmitted={
                  editBenefRequest.isSuccessfullySubmitted
                }
                setIsSuccessfullySubmitted={
                  editBenefRequest.setIsSuccessfullySubmitted
                }
                handleReset={handleReset}
              />
            </div>

            {/* Modal */}
            <div
              className="modal fade easypm-modal alim-soin-modal"
              id="CsvModalItem"
              tabIndex="-1"
              data-backdrop="static"
              aria-labelledby="CsvModalItemLabel"
              aria-hidden="true"
            >
              <AlimentSoinModal ficheId={fiche?.id} />
            </div>

            {/* Modal */}
            <div
              className="modal fade easypm-modal add-fiche-modal"
              id="FicheModalItem"
              tabIndex="-1"
              data-backdrop="static"
              aria-labelledby="FicheModalItemLabel"
              aria-hidden="true"
            >
              <GenereFicheModal
                beneficiaire={beneficiaireInfos}
                beneficiaireID={beneficiaireInfos?.id}
              />
            </div>

            {/* Modal Carnet */}
            <div
              className="modal fade easypm-modal show-carnet-modal"
              id="DeleteModalItem"
              tabIndex="-1"
              aria-labelledby="DeleteModalItemLabel"
              aria-hidden="true"
              data-backdrop="static"
            >
              <ShowCarnetModal carnet={carnet} isLoading={isLoading} />
            </div>

            {/* Modal show file */}
            <div
              className="modal fade easypm-modal show-fiche-file-modal"
              id="CsvModalItem"
              tabIndex="-1"
              data-backdrop="static"
              aria-labelledby="CsvModalItemLabel"
              aria-hidden="true"
            >
              <ShowFicheFileModal fiche={fiche} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaireProfile;
