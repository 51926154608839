import React from "react";
import Skeleton from "react-loading-skeleton";

function TopTableSkeleton() {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col" style={{ color: "#1F497D" }}>
            #
          </th>
          <th scope="col" style={{ color: "#1F497D" }}>
            Adhérents
          </th>
          <th scope="col" style={{ color: "#1F497D" }}>
            Prestataires
          </th>
          <th scope="col" style={{ color: "#1F497D" }}>
            Entreprises
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            <Skeleton />
          </th>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <Skeleton />
          </th>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <Skeleton />
          </th>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <Skeleton />
          </th>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <Skeleton />
          </th>

          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default TopTableSkeleton;
