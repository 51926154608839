import React, { useContext, useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "jspdf-html2canvas";
import html2PDF from "jspdf-html2canvas";
import $ from "jquery";
import IMGEPM from "../../../../images/others/easypm.png";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import moment from "moment";
import { formatMoney } from "../../../../utils/utils";
import { DataContext } from "../../../store/GlobalState";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./FactureFournisseur.css";

const FactureModalAssureur = () => {
  const { state, dispatch } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(false);
  const [partIpmTotal, setPartIpmTotal] = useState(0);
  const [partBenefTotal, setPartBenefTotal] = useState(0);
  const [benefTotal, setBenefTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const { facture, auth, beneficiairesByEntreprise } = state;
  /* Chart code */

  // Create chart instance
  let chart = null;

  // Create chart instance
  let chart1 = null;

  useEffect(() => {
    if (facture?.id && facture.prestations?.length) {
      chart = am4core.create("chartdiv", am4charts.PieChart);
      chart1 = am4core.create("chartdiv1", am4charts.PieChart);
      // Add data
      chart.data = [
        {
          part: "Part IPM",
          pourcentage: facture?.charge_ipm,
          color: am4core.color("#f2cb67"),
        },
        {
          part: "Part Adhérent",
          pourcentage: facture?.charge_beneficiaire,
          color: am4core.color("#fd8a65"),
        },
      ];

      chart1.data = [
        {
          part: "Cotisations",
          pourcentage: parseInt(
            facture?.cotisations ? facture?.cotisations : 0
          ),
          color: am4core.color("#047eb1"),
        },
        {
          part: "Frais médicaux",
          pourcentage: facture?.frais_medicaux,
          color: am4core.color("rgb(75, 192, 192)"),
        },
      ];

      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.slices.template.propertyFields.fill = "color";
      pieSeries.dataFields.value = "pourcentage";
      pieSeries.dataFields.category = "part";
      pieSeries.innerRadius = am4core.percent(50);
      pieSeries.ticks.template.disabled = true;
      pieSeries.labels.template.disabled = true;

      let rgm = new am4core.RadialGradientModifier();
      rgm.brightnesses.push(0, 0, 0, 0, 0);
      pieSeries.slices.template.fillModifier = rgm;
      pieSeries.slices.template.strokeModifier = rgm;
      pieSeries.slices.template.strokeOpacity = 0.4;
      pieSeries.slices.template.strokeWidth = 0;

      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";

      // var gradient = new am4core.RadialGradient();

      // Add and configure Series
      let pieSeries1 = chart1.series.push(new am4charts.PieSeries());
      pieSeries1.slices.template.propertyFields.fill = "color";
      pieSeries1.dataFields.value = "pourcentage";
      pieSeries1.dataFields.category = "part";
      pieSeries1.innerRadius = am4core.percent(50);
      pieSeries1.ticks.template.disabled = true;
      pieSeries1.labels.template.disabled = true;

      let rgm1 = new am4core.RadialGradientModifier();
      rgm1.brightnesses.push(0, 0, 0, 0, 0);
      pieSeries1.slices.template.fillModifier = rgm1;
      pieSeries1.slices.template.strokeModifier = rgm1;
      pieSeries1.slices.template.strokeOpacity = 0.4;
      pieSeries1.slices.template.strokeWidth = 0;

      chart1.legend = new am4charts.Legend();
      chart1.legend.position = "right";
      let total_mont = 0;
      let total_ipm = 0;
      let total_benef = 0;
      facture.prestations.forEach((element) => {
        total_mont += parseInt(element.montant);
        total_ipm += parseInt(element.montant_rembourse);
        total_benef += parseInt(element.montant_retenu);
      });
      setPartBenefTotal(total_benef);
      setPartIpmTotal(total_ipm);

      setTotal(
        total_mont +
          parseInt(
            facture?.ipm?.montant_cotisation
              ? facture?.ipm?.montant_cotisation
              : 0
          )
      );
    } else {
      setPartBenefTotal(0);
      setPartIpmTotal(0);
      setTotal(0);
      chart = null;
      chart1 = null;
      // return () => {
      //   chart.dispose();
      //   chart1.dispose();
      // };
    }
  }, [facture]);

  //   const  generatePDF = () => {

  //         var doc = new jsPDF('p','pt','a4');
  //        doc.html(document.querySelector("#content"), { height: 1800, width: window.innerWidth * 2, scale: 1 }).then(canvas => {

  //         var dataURL = canvas.toDataURL();
  //         // var pdf = new jsPDF('p','pt','a4');
  //         doc.addImage(dataURL, 'JPEG', 20, 20, 170, 120); //addImage(image, format, x-coordinate, y-coordinate, width, height)
  //         //    callback: function(pdf){

  //                doc.save("facture.pdf");
  //         //    }
  //        });
  //       } ;
  // $("#generatePDF").click(function()
  const generatePDF = () => {
    setIsLoading(true);
    const input = document.getElementById("content");
    html2PDF(input, {
      jsPDF: {
        unit: "px",
        format: "a4",
      },
      html2canvas: {
        imageTimeout: 15000,
        logging: true,
        useCORS: false,
      },
      imageType: "image/png",
      imageQuality: 1,
      margin: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5,
      },
      output: `facture-client-${moment(new Date()).format("DD-MM-YYYY")}.pdf`,
      init: function () {},
      success: function (pdf) {
        pdf.save(this.output);
        window.$("#facturePdfModal").modal("hide");
        setIsLoading(false);
      },
    });
  };

  const handleReset = () => {
    dispatch({ type: "EDIT_FACTURE", payload: {} });
  };
  return (
    <div class="modal-dialog modal-lg" id="modal-facture">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="factureAssureur">
            Facture pdf
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body px-4">
          <div className="row d-flex justify-content-center pb-4">
            <div className="col-md-6">
              {!isLoading && (
                <button
                  className="btn pdf-created-link"
                  //  id="generatePDF"
                  onClick={() => generatePDF()}
                  type="button"
                >
                  Télécharger la facture
                </button>
              )}
              {isLoading && (
                <button
                  className="btn pdf-created-link"
                  //  id="generatePDF"
                  //   onClick={() => generatePDF()}
                  type="button"
                >
                  Téléchargement en cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
          <div id="content">
            <div className="row d-flex align-items-center">
              <div className="col-md-6">
                <div className>
                  <img src={IMGEPM} className="img-facture" />
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <div className="blocright d-flex">
                  <div className="bgblue">
                    <p className="textfacturebloc">Numéro de facture</p>
                    <p className="textlibellebloc">12348585570</p>
                  </div>
                  <div className="bggreen">
                    <p className="textfacturebloc">Référence client</p>
                    <p className="textlibellebloc">010008380</p>
                  </div>
                  <div className="bgblue">
                    <p className="textfacturebloc">Facture du</p>
                    <p className="textlibellebloc">29/01/2021</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row py-3">
              {/* {facture?.ipm && facture?.ipm?.logo && (
                <div className="col-md-12">
                  <img src={baseUrl + facture?.ipm?.logo} width="100" />
                </div>
              )} */}

              <div className="col-md-6">
                <p className="p-text text-dark">ABDOU FALL</p>

                <p className="p-text">Fass - Banque Islamique</p>
                <p className="p-text">77 700 00 00</p>
                <p className="p-text">ab00@gmail.com</p>
              </div>

              <div className="col-md-6">
                <ul className="">
                  <li className="list">
                    Nom du client : <span className="spanli">VOLKENO</span>
                  </li>
                  <li className="list">
                    Adresse du client :{" "}
                    <span className="spanli">Hlm Grand Yoff</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bloctrois">
              <div className="textbloctrois py-2 pl-2">
                Votre facture du 29 janvier 2021
              </div>
            </div>
            <div>
              <div className="pt-3">
                <table class="table" id="page-2">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Désignation </th>
                      <th scope="col">P.Unitaire</th>
                      <th scope="col">Qte</th>
                      <th scope="col">Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fact-cls td-facture-assurance">
                        04/09/2021
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        <p className="fact-cls td-facture-assurance">
                          CONSULTATION GÉNÉRALE:
                        </p>
                        <p className="name td-facture-assurance">
                          Adhérent: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Bénéficiaire: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Entreprise: VOLKENO SARL
                        </p>
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        12 000 CFA
                      </td>
                      <td className="fact-cls td-facture-assurance">1</td>

                      <td className="fact-cls td-facture-assurance">12 000</td>
                    </tr>
                    <tr>
                      <td className="fact-cls td-facture-assurance">
                        04/09/2021
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        <p className="fact-cls td-facture-assurance">
                          CONSULTATION GÉNÉRALE:
                        </p>
                        <p className="name td-facture-assurance">
                          Adhérent: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Bénéficiaire: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Entreprise: VOLKENO SARL
                        </p>
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        12 000 CFA
                      </td>
                      <td className="fact-cls td-facture-assurance">1</td>

                      <td className="fact-cls td-facture-assurance">12 000</td>
                    </tr>

                    <tr>
                      <td className="fact-cls td-facture-assurance">
                        04/09/2021
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        <p className="fact-cls td-facture-assurance">
                          CONSULTATION GÉNÉRALE:
                        </p>
                        <p className="name td-facture-assurance">
                          Adhérent: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Bénéficiaire: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Entreprise: VOLKENO SARL
                        </p>
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        12 000 CFA
                      </td>
                      <td className="fact-cls td-facture-assurance">1</td>

                      <td className="fact-cls td-facture-assurance">12 000</td>
                    </tr>

                    <tr>
                      <td className="fact-cls td-facture-assurance">
                        04/09/2021
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        <p className="fact-cls td-facture-assurance">
                          CONSULTATION GÉNÉRALE:
                        </p>
                        <p className="name td-facture-assurance">
                          Adhérent: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Bénéficiaire: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Entreprise: VOLKENO SARL
                        </p>
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        12 000 CFA
                      </td>
                      <td className="fact-cls td-facture-assurance">1</td>

                      <td className="fact-cls td-facture-assurance">12 000</td>
                    </tr>

                    <tr>
                      <td className="fact-cls td-facture-assurance">
                        04/09/2021
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        <p className="fact-cls td-facture-assurance">
                          CONSULTATION GÉNÉRALE:
                        </p>
                        <p className="name td-facture-assurance">
                          Adhérent: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Bénéficiaire: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Entreprise: VOLKENO SARL
                        </p>
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        12 000 CFA
                      </td>
                      <td className="fact-cls td-facture-assurance">1</td>

                      <td className="fact-cls td-facture-assurance">12 000</td>
                    </tr>

                    <tr>
                      <td className="fact-cls td-facture-assurance">
                        04/09/2021
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        <p className="fact-cls td-facture-assurance">
                          CONSULTATION GÉNÉRALE:
                        </p>
                        <p className="name td-facture-assurance">
                          Adhérent: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Bénéficiaire: Aissatou Badiane
                        </p>
                        <p className="name td-facture-assurance">
                          Entreprise: VOLKENO SARL
                        </p>
                      </td>
                      <td className="fact-cls td-facture-assurance">
                        12 000 CFA
                      </td>
                      <td className="fact-cls td-facture-assurance">1</td>

                      <td className="fact-cls td-facture-assurance">12 000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default FactureModalAssureur;
