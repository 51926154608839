import React, { useContext, useEffect } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import Beneficiaires from "../../../Tables/Beneficiaires/Beneficiaires";
import CRUDBeneficiaireModal from "../../../Modals/CRUDBeneficiaireModal";
import UseCreateBeneficiaireForm from "./UseCreateBeneficiaireForm";
// import { ToastContainer, toast } from 'react-toastify'
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// import entreprise from '../../../Services/Entreprise'
// import beneficiaire from '../../../Services/Beneficiaire'
import { DataContext } from "../../../store/GlobalState";
import { getDataByID } from "../../../utils/fetchData";
import Employes from "../../../Tables/Beneficiaires/Employes";

var GestionBeneficiaires = () => {
  const [userType, setUserType] = React.useState();
  // eslint-disable-next-line
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
    setIsFormRequestError,
    isFormRequestError,
    watch,
    reset,
    setValue,
    isSuccess,
  } = UseCreateBeneficiaireForm();

  const { state, dispatch } = useContext(DataContext);
  const {
    auth,
    beneficiaires,
    entreprises_by_ipm,
    ipms,
    beneficiairesByEntreprise,
  } = state;
  const history = useHistory();

  useEffect(() => {
    setUserType(window.sessionStorage.getItem("userType"));

    if (isFormRequestError) {
      setIsSuccessfullySubmitted(false);

      setIsFormRequestError(true);
      setTimeout(() => {
        setIsFormRequestError(false);
      }, 5000);
    }

    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
    // console.log(formState.errors)
    // console.log(register)
  }, [
    isSuccessfullySubmitted,
    beneficiaires,
    formState,
    clearErrors,
    isFormRequestError,
    setIsFormRequestError,
    setIsSuccessfullySubmitted,
  ]);

  var handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      {(userType === "ipm" ||
                        userType === "assurance_sante" ||
                        userType === "admin_ipm") &&
                        "Participants"}
                      {userType === "entreprise" && "Employés"}
                      {userType === "prestataire" && "Participants"}
                      &nbsp;
                      {(userType === "ipm" ||
                        userType === "assurance_sante" ||
                        userType === "admin_ipm") && (
                        <span className="badge badge-info easypm-badge-info">
                          {" "}
                          {beneficiaires.length}{" "}
                        </span>
                      )}
                      {userType === "entreprise" && (
                        <span className="badge badge-info easypm-badge-info">
                          {" "}
                          {beneficiairesByEntreprise.length}{" "}
                        </span>
                      )}
                    </h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target=".add-beneficiaire-modal"
                  >
                    {userType !== "prestataire" && (
                      <span className="icon-container">
                        <IconlyPack.Plus set="light" primaryColor="gray" />
                      </span>
                    )}

                    <span className="adding-item-label">
                      {(userType === "ipm" ||
                        userType === "assurance_sante" ||
                        userType === "admin_ipm") &&
                        "Ajouter un participant"}
                      {userType === "entreprise" && "Ajouter un employé"}
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  {/* {
                    userType === "entreprise" ? (
                      <Employes />
                    ) : (
                      
                    )
                  } */}
                  <Beneficiaires
                    isPageLoading={isPageLoading}
                    userType={userType}
                  />
                  {/*  */}
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal add-beneficiaire-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <CRUDBeneficiaireModal
                    listEntreprises={entreprises_by_ipm && entreprises_by_ipm}
                    register={register}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    handleReset={handleReset}
                    reset={reset}
                    watch={watch}
                    setValue={setValue}
                    assurances={ipms}
                    isSuccess={isSuccess}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionBeneficiaires;
