import React, { useContext, useMemo }  from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify'
import superAdmin from '../../../../Services/SuperAdmin'
import { DataContext } from '../../../../store/GlobalState';
import { getData } from '../../../../utils/fetchData';

const UseAddSuperAdminForm = () => {
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = React.useState(false);
    const [isFormRequestError, setIsFormRequestError] = React.useState(false);
    const requestConfig = {
      headers: { Authorization: `Bearer ${window.sessionStorage.getItem('authToken')}` }
    }
  
    const { state, dispatch } = useContext(DataContext)
  
    const { admin_ipms, auth } = state
  
    const validationSchema = useMemo(() => (
      yup.object().shape({
          email: yup.string().email("L'adresse email n'est pas valide").required('L\'email est requis'),
          nom: yup.string().required('Le Nom est requis'),
          prenom: yup.string().required('Le prénom est requis'),
          telephone: yup.string().required('Le numéro de téléphone est requis').matches(/^((\+)221|0)[1-9](\d{2}){4}$/ , 'Veuillez vérifier le format du numéro de téléphone'),
          adresse: yup.string().required('L\'adresse est requise'),
      })
    ), [])
  
    const { register, handleSubmit, formState, clearErrors, setValue, reset } = useForm({
      resolver: yupResolver(validationSchema)
    });
   
    const onSubmitCreateForm = async (formValues, e) => {
      setIsSuccessfullySubmitted(true)
      var adminRequest = superAdmin.postCreateSuperAdmin(formValues, requestConfig)
      await adminRequest.then(response => {
        var adminInfos = response
        dispatch({ type: 'LIST_ADMIN_IPM', payload: [...admin_ipms, adminInfos]})
        getData('super_admin', auth.token)
          .then((res) => {
            dispatch({
              type: 'LIST_ADMIN_IPM',
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            })
            
          })
          .catch((e) => {
            console.log('e', e)
          })
        e.target.reset()
        window.$('.add-autres-admin-modal').modal('hide')
        toast.success('Admin ajouté avec succès.')
        console.log(isSuccessfullySubmitted)
      })
      .catch(error => {
        console.log(error)
        setIsFormRequestError(true)
        if(error?.response?.data?.email && error?.response?.data?.email[0] === "user with this email already exists."){
          toast.error('Oupsss! L\'adresse email est déjà associée à un compte. Veuillez utiliser une autre adresse email.',{
            position: "top-right",
            autoClose: 10000,
          })
        }else{
          toast.error('Oupss! Une erreur est survenue lors de l\'ajout.')
        }
      })
  
    };
   
    return {
      register,
      formState,
      isSuccessfullySubmitted,
      setIsSuccessfullySubmitted,
      setIsFormRequestError,
      isFormRequestError,
      clearErrors,
      onSubmit: handleSubmit(onSubmitCreateForm),
      setValue,
      reset 
    }
}

export default UseAddSuperAdminForm
