import React, { useEffect, useContext } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import * as IconlyPack from 'react-iconly';
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getDataByID, putData } from "../../../utils/fetchData";
import DetailPrestataireSkeleton from "./DetailPrestataireSkeleton";
import moment from "moment";

function DetailPrestataire(props) {
  const [userType, setUserType] = React.useState();
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [prestataire, setPrestataire] = React.useState({});

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  // console.log('props', location)
  useEffect(() => {
    setUserType(window.sessionStorage.getItem("userType"));

    if (params?.id && auth?.token) {
      getDataByID("prestataire", params?.id, auth?.token).then((res) => {
        console.log("res", res);
        setPrestataire(res);
        setIsPageLoading(false);
      });
    }
  }, [params?.id, auth?.token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userType !== "super_ipm") {
      setIsSuccessfullySubmitted(true);
      putData(
        "prestataire/" + params?.id + "/",
        { agreement: true },
        auth?.token
      )
        .then((res) => {
          console.log("res", res);

          if (window.sessionStorage.getItem("notifId")) {
            handleValid("valid");
          }
          toast.success("Demande d'agréement acceptée.");
          setIsSuccessfullySubmitted(false);
          getDataByID(
            "prestatairebyassurance",
            auth?.user?.id,
            auth.token
          ).then((res) => {
            dispatch({
              type: "PRESTATAIRE_BY_IPM",
              payload: res.results.reverse(),
            });
            history.push(`/ipm/gestion-des-prestataires`);
          });
        })
        .catch((e) => {
          setIsSuccessfullySubmitted(false);
          toast.error("Oupss! Une erreur est survenue lors de la validation.");
        });
    } else {
      if (location?.state?.notification) {
        handleValid("rejet");
      }
    }
  };

  const handleValid = (item) => {
    let id = parseInt(window.sessionStorage.getItem("notifId"));
    putData("notification/" + id + "/", { valid: true }, auth?.token).then(
      (res) => {
        // console.log('res', res)
        if (userType !== "super_ipm") {
          if (auth?.user?.user_type === "admin_ipm") {
            getDataByID(
              "notificationbyipm",
              auth?.user?.parent,
              auth?.token
            ).then((resp) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
              });
            });
          } else {
            getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
              (resp) => {
                // console.log('notif', resp)
                window.sessionStorage.removeItem("notifId");
                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
                });
              }
            );
          }
          // history.push(`/ipm/profil-beneficiaire/${res.beneficiaire}`);
        } else {
          getDataByID(
            "notificationbysuperadmin",
            auth?.user?.id,
            auth?.token
          ).then((res) => {
            window.sessionStorage.removeItem("notifId");
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
          // history.push(`/entreprise/profil-employes/${res.beneficiaire}`);
        }
        if (item === "rejeter" && userType !== "super_ipm") {
          history.push(`/ipm/gestion-des-prestataires`);
        }

        if (item === "rejeter" && userType === "super_ipm") {
          history.push(`/admin/gestion-des-etablissements-et-pharmacies`);
        }
      }
    );
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div
            className={
              userType === "assureur"
                ? "nav-container nav-assurance-color"
                : "nav-container"
            }
          >
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      {userType === "super_ipm"
                        ? "Détail Prestataire"
                        : "Demande d'agréement"}
                    </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="add-family-form-container">
                    {isPageLoading && <DetailPrestataireSkeleton />}
                    {!isPageLoading && (
                      <div className="crud-form">
                        <div className="row crud-row">
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="numero_contrat">
                                Num. Contrat:{" "}
                              </strong>

                              <strong className="crud-form-control">
                                {prestataire?.num_contrat}
                              </strong>
                            </div>
                          </div>
                          {prestataire?.raison_sociale && (
                            <div className="col-md-6 crud-col-item">
                              <div className="form-group crud-form-group">
                                <strong htmlFor="name">Nom: </strong>
                                <strong className="crud-form-control">
                                  {prestataire?.raison_sociale}
                                </strong>
                              </div>
                            </div>
                          )}

                          {prestataire?.prenom && (
                            <div className="col-md-6 crud-col-item">
                              <div className="form-group crud-form-group">
                                <strong htmlFor="name">Prénom: </strong>
                                <strong className="crud-form-control">
                                  {prestataire?.prenom}
                                </strong>
                              </div>
                            </div>
                          )}

                          {prestataire?.nom && (
                            <div className="col-md-6 crud-col-item">
                              <div className="form-group crud-form-group">
                                <strong htmlFor="name">Nom: </strong>
                                <strong className="crud-form-control">
                                  {prestataire?.nom}
                                </strong>
                              </div>
                            </div>
                          )}

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="specialite">Spécialité: </strong>
                              <strong className="crud-form-control">
                                {prestataire?.specialite?.nom}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="ninea">NINEA: </strong>
                              <strong className="crud-form-control">
                                {prestataire?.ninea}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="email">Email: </strong>
                              <strong className="crud-form-control">
                                {prestataire?.email}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="adresse">Adresse: </strong>
                              <strong className="crud-form-control">
                                {prestataire?.adresse}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="telephone">Téléphone: </strong>
                              <strong className="crud-form-control">
                                {prestataire?.telephone}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="first_name">Agréement: </strong>
                              <strong className="crud-form-control">
                                {prestataire?.agreement ? "Oui" : "Non"}
                              </strong>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="first_name">
                                Date de début:{" "}
                              </strong>
                              <strong className="crud-form-control">
                                {prestataire?.date_debut
                                  ? moment(
                                      new Date(prestataire?.date_debut)
                                    ).format("DD/MM/YYYY")
                                  : ""}
                              </strong>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="first_name">
                                Date de fin:{" "}
                              </strong>
                              <strong className="crud-form-control">
                                {prestataire?.date_fin
                                  ? moment(
                                      new Date(prestataire?.date_fin)
                                    ).format("DD/MM/YYYY")
                                  : ""}
                              </strong>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="website">Site web: </strong>
                              <strong className="crud-form-control">
                                {prestataire?.website}
                              </strong>
                            </div>
                          </div>

                          {userType === "super_ipm" ? (
                            <div
                              className="col-md-6 offset-md-6 crud-form-action-btn-container"
                              style={{ justifyContent: "flex-end" }}
                            >
                              <button
                                className={`btn btn-default ${
                                  userType === "assureur"
                                    ? "cancelleb-btn-assurance"
                                    : "cancelleb-btn"
                                }`}
                                onClick={() => handleValid("rejeter")}
                              >
                                Retour
                              </button>
                            </div>
                          ) : (
                            <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                              <button
                                className={`btn btn-default ${
                                  userType === "assureur"
                                    ? "cancelleb-btn-assurance"
                                    : "cancelleb-btn"
                                }`}
                                onClick={() => handleValid("rejeter")}
                              >
                                {userType === "super_ipm"
                                  ? "Retour"
                                  : "Rejeter"}
                              </button>
                              {isSuccessfullySubmitted && (
                                <button
                                  className={`btn ${
                                    userType === "assureur"
                                      ? "crud-submit-btn-assurance"
                                      : "crud-submit-btn"
                                  }`}
                                >
                                  En cours &nbsp;
                                  <i className="fas fa-spin fa-spinner"></i>
                                </button>
                              )}
                              {!isSuccessfullySubmitted && (
                                <button
                                  className={`btn ${
                                    userType === "assureur"
                                      ? "crud-submit-btn-assurance"
                                      : "crud-submit-btn"
                                  }`}
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  Accepter
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailPrestataire;
