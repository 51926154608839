import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Entreprise from "../../../Services/Entreprise";
import { DataContext } from "../../../store/GlobalState";
import { toast } from "react-toastify";
import { getDataByID } from "../../../utils/fetchData";

function UseEditEntrepriseForm() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [entrepriseData, setEntrepriseData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const { state, dispatch } = useContext(DataContext);

  const { auth, entreprise } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("L'adresse email n'est pas valide")
          .required("L'email est requis"),
        nom: yup.string().required("Le nom de l'entreprise est requis."),
        raison_sociale: yup.string().required("La raison sociale est requise"),
        // password: yup.number().transform(value => (isNaN(value) ? undefined : value)).required('L\'identifiant est requis'),
        // password: yup.string().required('Le mot de passe est requis'),
        telephone: yup
          .string()
          .required("Le numéro de téléphone est requis")
          .matches(
            /^((\+)221|0)[1-9](\d{2}){4}$/,
            "Veuillez vérifier le format du numéro de téléphone"
          ),
        adresse: yup.string().required("L'adresse est requise"),
        ninea: yup
          .string()
          .matches(
            new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/),
            "Le NINEA doit être de format alpha-numérique."
          )
          .length(12, "Le ninea doit comporter 12 caractères")
          .required("Le NINEA est requis"),
        // website: yup.string().url('Vous devez renseigner une url valide.'),
        website: yup
          .string()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "Vous devez renseigner une url valide!"
          ),
      }),
    []
  );

  const validSchema = useMemo(() =>
    yup.object().shape(
      {
        ipm_from: yup.string().required("L'ipm est requise"),
      },
      []
    )
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    setValue,
    reset,
    unregister,
  } = useForm({
    resolver: entreprise?.id
      ? yupResolver(validSchema)
      : yupResolver(validationSchema),
  });

  React.useEffect(() => {
    if (!entreprise?.id && auth?.user?.user_type !== "entreprise") {
      if (auth?.user?.user_type === "admin_ipm") {
        register("ipm_from");
        setValue("ipm_from", auth?.user?.parent);
      } else {
        register("ipm_from");
        setValue("ipm_from", auth?.user?.id);
      }
    }

    if (auth?.user?.user_type === "entreprise") {
      register("enterprise");
      setValue("enterprise", auth?.user?.id);
    }
  }, [register, setValue, auth, entreprise]);

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    if (!entreprise?.id) {
      if (
        formValues?.website?.indexOf("https") === -1 &&
        formValues?.website?.indexOf("http") === -1
      ) {
        //alert('cool')
        if (formValues?.website?.indexOf("www") >= 0) {
          formValues.website = "https://" + formValues?.website;
        } else {
          formValues.website = "https://www." + formValues?.website;
        }
      }
    } else {
      if (auth?.user?.user_type === "admin_ipm") {
        formValues = {
          id: entreprise?.id,
          ipm_from: auth?.user?.parent,
        };
      } else {
        formValues = {
          id: entreprise?.id,
          ipm_from: auth?.user?.id,
        };
      }
    }
    console.log("data", formValues);
    var entrepriseRequest = Entreprise.postCreateEntreprise(
      formValues,
      requestConfig
    );
    await entrepriseRequest
      .then(async (response) => {
        var entrepriseInfos = response;
        setEntrepriseData(entrepriseInfos);
        if (auth?.user?.user_type === "admin_ipm") {
          await getDataByID(
            "entreprisebyassurance",
            auth?.user?.parent,
            auth.token
          )
            .then((res) => {
              dispatch({
                type: "ADD_ENTREPRISE_BY_IPM",
                payload: res.sort((a, b) => a.id - b.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          await getDataByID("entreprisebyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_ENTREPRISE_BY_IPM",
                payload: res.sort((a, b) => a.id - b.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }

        await toast.success("Adhérent ajouté avec succès.");
        await window.$(".add-entreprise-modal").modal("hide");
        await setIsSuccess(true);
        await setIsSuccessfullySubmitted(false);
        await dispatch({ type: "EDIT_ENTREPRISE", payload: {} });
        await e.target.reset();
        console.log(entrepriseInfos);
      })
      .catch((error) => {
        console.log(error?.response);
        setIsFormRequestError(true);
        setIsSuccessfullySubmitted(false);
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else if (
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          toast.error(
            "Oupsss! L'adresse email est déjà associée à un compte. Veuillez utiliser une autre adresse email.",
            {
              position: "top-right",
              autoClose: 10000,
            }
          );
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (
          error?.response?.status === 400 &&
          !error?.response?.data?.email
        ) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };

  return {
    register,
    errors,
    formState,
    entrepriseData,
    setEntrepriseData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    setValue,
    onSubmit: handleSubmit(onSubmitCreateForm),
    reset,
    isSuccess,
    unregister,
  };
}

export default UseEditEntrepriseForm;
