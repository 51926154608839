import React, { useEffect } from "react";
import { Pie } from "react-chartjs-2";
// import "chartjs-plugin-datalabels";
import moment from "moment";
import "moment/locale/fr";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Skeleton from "react-loading-skeleton";
import { GroupSharp } from "@material-ui/icons";
import Header from '../../../../Navs/Header';
import NavbarVerticalV2 from "../../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import './GestionRapportsAdmin.css'
import TopAssuresAdminTable from "./TopAssuresAdminTable";
import TopOppositionAdminTable from "./TopOppositionAdminTable";
// import { forEach } from 'lodash'
// import "react-datepicker/dist/react-datepicker.css";


  
const GestionRapportsAdmin = () => {

  const [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false)
    }, 3000)
  }, [])
  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)"
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)"
        ],
        borderWidth: 1
      }
    ]
  };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row breadcrumbs-rapports d-flex align-items-center">
          <div className="col-xl-8 col-lg-8 col-md-8 mb-2 dis-flex">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#" className="nav-item-breadcrumb">Bénéficiaire</a></li>
                <li class="breadcrumb-item"><a href="#" className="nav-item-breadcrumb">Badou Seck</a></li>
                <li class="breadcrumb-item active" className="nav-item-breadcrumb" aria-current="page">Prestations</li>
            </ol>
            </nav>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 mb-2 dis-flex">
            <div>
            <button
                    className="w-full btn btn-generer-rapport"
                    data-toggle="modal"
                    data-target=".add-soin-modal"
                  >
                    <span className="icon-container pr-2">
                      <IconlyPack.Wallet set="bold" primaryColor="rgba(0, 0, 0, 0.7)" />
                    </span>
                    <span className="adding-item-label text-generer-rapport-assurance">
                        Générer un carnet
                    </span>
                  </button>
            </div>
            </div>
          <div className="col-xl-2 col-lg-2 col-md-2 mb-2 dis-flex">
                <div>
                <button
                    className="w-full btn btn-generer-rapport"
                    data-toggle="modal"
                    data-target=".add-soin-modal"
                  >
                    <span className="icon-container pr-2">
                      <IconlyPack.PaperNegative set="bold" primaryColor="rgba(0, 0, 0, 0.7)" />
                    </span>
                    <span className="adding-item-label text-generer-rapport-assurance">
                        Prestations
                    </span>
                  </button>
                </div>
            </div>
        </div>

        <div className="row breadcrumbs-rapports-filter">
          <div className="col-md-3 mb-2 dis-flex">
            <div class="form-group">
                <select class="form-control select-date-assureur" id="exampleFormControlSelect1">
                    <option>Date</option>
                    <option>Date</option>
                </select>
            </div>
          </div>
        </div>

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 ben-profile-container" id="gestion-rapport-assureur">
            <div className="page-body-container">
              <div className="row beneficiaire-profile-row">
                <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 profile-infos-perso-container profile-item">
                  <div className="profile-infos-ben card-rapports-assureur">
                    <h2 className="section-light-title">
                      <div className="light-title">Rapport Fiches de soins</div>
                    </h2>
                    <div
                      className="chart-item-container"
                      style={{ textAlign: "center", width: 400 }}
                    >
                      {!isPageLoading ? (
                        <Pie data={data} width={250} height={250} style={{ textAlign: 'center'}} />
                      ) : (
                        <Skeleton circle={true} height={250} width={250} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 col-xs-12 profile-infos-family-container profile-item">
                  <div className="wifes-infos-container card-rapports-assureur">
                    <div className="page-first-row-container">
                      <div className="page-title-container">
                        <h1 className="page-title">Top Assurés</h1>
                      </div>
                    </div>
                    <div className="epouses-table-container">
                      <TopAssuresAdminTable />
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 profile-infos-family-container profile-item">
                  <div className="childs-infos-container card-rapports-assureur">
                    <div className="page-first-row-container">
                      <div className="page-title-container">
                        <h1 className="page-title">Top Oppositions</h1>
                      </div>
                    </div>
                    <div className="epouses-table-container">
                      <TopOppositionAdminTable />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionRapportsAdmin;
