import React, { useContext } from "react";
import { DataContext } from "../store/GlobalState";
import * as IconlyPack from "react-iconly";
// import { Input } from '@material-ui/core';
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";

var CRUDEntrepriseModal = (props) => {
  const { state, dispatch } = useContext(DataContext);

  const { entreprise, entreprises } = state;
  var [website, setWebsite] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [name, setName] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  const [phone, setPhone] = React.useState("");

  const handleSetPhone = (val) => {
    // console.log('phone', val)
    setPhone(val);
    props.register("telephone");
    props.setValue("telephone", "+" + val);
  };

  React.useEffect(() => {
    if (props.userActionType === "edit" && entreprise?.id) {
      setPhone(entreprise?.telephone);
      props.register("raison_sociale");
      props.setValue("raison_sociale", entreprise.raison_sociale);
      props.register("telephone");
      props.setValue("telephone", entreprise.telephone);
      props.register("email");
      props.setValue("email", entreprise.email);
      props.register("adresse");
      props.setValue("adresse", entreprise.adresse);
      props.register("ninea");
      props.setValue("ninea", entreprise.ninea);
      props.register("nom");
      props.setValue("nom", entreprise.nom);
      setName(entreprise?.nom);
      props.register("num_contrat");
      props.setValue("num_contrat", entreprise.num_contrat);
      if (entreprise.website) {
        props.register("website");
        props.setValue("website", entreprise.website);
      } else {
        props.unregister("website");
      }
    } else {
      setPhone("+221");
    }
    // console.log(entreprise)
  }, [entreprise?.id, props.userActionType]);

  const handleChangeWebsite = (e) => {
    // eslint-disable-next-line
    if (e.target.value) {
      const urlPattern = new RegExp(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
      );
      let string = e.target.value;

      if (urlPattern.test(string)) {
        ///clear http && https from string
        string = string.replace("https://", "").replace("http://", "");
        //add https to string
        string = `https://${string}`;
      }
      setWebsite(string);
      props.register("website");
      props.setValue("website", string);
    }
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value) {
      props.register("raison_sociale");
      props.setValue("raison_sociale", event.target.value);
      // props.register("nom");
      // props.setValue("nom", event.target.value);
    } else {
      setSearchResults([]);
    }
    if (!name || name === searchTerm) {
      setName(event.target.value);
      props.register("nom");
      props.setValue("nom", event.target.value);
    }

    if (entreprise?.id && entreprise?.raison_sociale === entreprise?.nom) {
      props.register("nom");
      props.setValue("nom", event.target.value);
    }
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
    props.register("nom");
    if (!entreprise?.id && props.userActionType !== "edit") {
      props.setValue("nom", e.target.value);
    }
  };

  React.useEffect(() => {
    //console.log('entreprise', entreprises)
    if (!entreprise?.id && props.userActionType !== "edit" && searchTerm) {
      const results = entreprises?.filter((person) => {
        // console.log('search term', searchTerm, person)
        let name = person?.raison_sociale + " " + person?.nom;
        return name.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setSearchResults(results);
    }
    if (entreprise?.id) {
      if (entreprise?.telephone.includes("+221")) {
        setPhone(entreprise?.telephone);
      } else {
        setPhone("+221" + entreprise?.telephone);
      }
    }
  }, [searchTerm, entreprise?.id]);

  const handleClick = (e, item) => {
    e.preventDefault();
    // console.log('item', item)
    setSearchTerm("");
    setSearchResults([]);

    dispatch({
      type: "EDIT_ENTREPRISE",
      payload: item,
    });
    props.setValue("nom", item?.nom);
  };

  React.useEffect(() => {
    if (props?.isSuccess) {
      handleReset();
    }
  }, [props?.isSuccess]);

  const handleReset = () => {
    dispatch({ type: "EDIT_ENTREPRISE", payload: {} });
    setPhone("");
    setWebsite("");
    setSearchResults([]);

    setName("");
    setSearchTerm("");

    setPhone("+221");
    props.reset();
    // console.log("phone", phone);
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            {props.userActionType === "edit" ? "Modifier " : "Ajouter "} un
            adhérent
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => handleReset()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {props.userActionType !== "edit" && (
              <form className="crud-form" onSubmit={props.onSubmit}>
                <div className="row crud-row">
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="raison_sociale">Raison Sociale</label>
                      <input
                        // {...props.register('raison_sociale')}
                        type="text"
                        autoComplete="off"
                        placeholder="Raison Sociale"
                        name="raison_sociale"
                        className="form-control crud-form-control"
                        id="raison_sociale"
                        value={
                          !entreprise?.id && props.userActionType !== "edit"
                            ? searchTerm
                            : entreprise?.raison_sociale
                        }
                        onChange={handleChange}
                      />
                      {searchResults?.length ? (
                        <ul className="search-result-container">
                          {searchResults.map((item) => (
                            <li
                              className="search-result-item"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleClick(e, item)}
                              key={item?.id}
                            >
                              {item?.raison_sociale}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                      {props.formState.errors &&
                        props.formState.errors.raison_sociale && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.raison_sociale?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="ninea">NINEA</label>
                      <input
                        {...props.register("ninea")}
                        type="text"
                        placeholder="NINEA"
                        maxLength={12}
                        disabled={entreprise?.id}
                        defaultValue={
                          !entreprise?.id && props.userActionType !== "edit"
                            ? ""
                            : entreprise?.ninea
                        }
                        name="ninea"
                        className="form-control crud-form-control"
                        id="ninea"
                      />
                      {props.formState.errors &&
                        props.formState.errors.ninea && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.ninea?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="nom">Nom</label>
                      <input
                        {...props.register("nom")}
                        type="text"
                        placeholder="Nom"
                        name="nom"
                        className="form-control crud-form-control"
                        disabled={entreprise?.id}
                        // onChange={handleChangeName}
                        defaultValue={
                          !entreprise?.id && props.userActionType !== "edit"
                            ? name
                            : entreprise?.raison_sociale === entreprise?.nom
                            ? entreprise?.raison_sociale
                            : entreprise?.nom
                        }
                        // defaultValue={!entreprise?.id && props.userActionType !== "edit" ? '' : entreprise?.nom}
                        id="nom"
                      />
                      {props.formState.errors && props.formState.errors.nom && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.nom?.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="email">Email</label>
                      <input
                        {...props.register("email")}
                        type="email"
                        placeholder="exemple@exemple.com"
                        name="email"
                        className="form-control crud-form-control"
                        disabled={entreprise?.id}
                        defaultValue={
                          !entreprise?.id && props.userActionType !== "edit"
                            ? ""
                            : entreprise?.email
                        }
                        id="email"
                      />
                      {props.formState.errors &&
                        props.formState.errors.email && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.email?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="telephone">Téléphone</label>
                      {/* <input
                        {...props.register('telephone')}
                        type="text"
                        placeholder="+221780158171"
                        name="telephone"
                        className="form-control crud-form-control"
                        disabled={entreprise?.id}
                        defaultValue={
                          !entreprise?.id && props.userActionType !== "edit" ? '' : entreprise?.telephone
                        }
                        id="telephone"
                      />
                      <div className="input-infos-container">
                        <IconlyPack.Danger
                          set="light"
                          primaryColor="gray"
                          className="alerty-icon"
                        />
                        &nbsp;
                        <span className="input-infos">
                          Le numéro de téléphone doit être au format
                          +221780158171
                        </span>
                      </div> */}
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control crud-form-control"
                        country={"sn"}
                        onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      {props.formState.errors &&
                        props.formState.errors.telephone && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.telephone?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="adresse">Adresse</label>
                      <input
                        {...props.register("adresse")}
                        type="text"
                        placeholder="HLM Grand Yoff, Villa 241 - Dakar"
                        name="adresse"
                        className="form-control crud-form-control"
                        disabled={entreprise?.id}
                        defaultValue={
                          !entreprise?.id && props.userActionType !== "edit"
                            ? ""
                            : entreprise?.adresse
                        }
                        id="adresse"
                      />
                      {props.formState.errors &&
                        props.formState.errors.adresse && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.adresse?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="num_contrat">Référence</label>
                      <input
                        {...props.register("num_contrat")}
                        type="text"
                        placeholder="Référence"
                        name="num_contrat"
                        disabled={entreprise?.id}
                        defaultValue={
                          !entreprise?.id && props.userActionType !== "edit"
                            ? ""
                            : entreprise?.num_contrat
                        }
                        className="form-control crud-form-control"
                        id="num_contrat"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="website">Site Web</label>
                      <input
                        type="text"
                        placeholder="www.exemple.com"
                        name="website"
                        className="form-control crud-form-control"
                        id="website"
                        disabled={entreprise?.id}
                        defaultValue={
                          !entreprise?.id && props.userActionType !== "edit"
                            ? website
                            : entreprise?.website
                        }
                        onChange={handleChangeWebsite}
                      />
                      <div className="input-infos-container">
                        <IconlyPack.Danger
                          set="light"
                          primaryColor="gray"
                          className="alerty-icon"
                        />
                        &nbsp;
                        <span className="input-infos">
                          Saisie au format www.exemple.com, l'adresse sera
                          autocomplétée avec le protocole https://
                        </span>
                      </div>
                      {props.formState.errors &&
                        props.formState.errors.website && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.website?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* <input
                    {...props.register('ipm_from')}
                    type="hidden"
                    name="ipm_from"
                    value={props.fromIpmID}
                  /> */}
                  <input
                    {...props.register("user_type")}
                    type="hidden"
                    name="user_type"
                    value={`entreprise`}
                  />

                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    {/* <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                    >
                      Retour
                    </button> */}

                    {!entreprise?.id && props.userActionType !== "edit" && (
                      <button
                        className="btn btn-default cancelleb-btn"
                        onClick={() => handleReset()}
                        data-dismiss="modal"
                      >
                        Retour
                      </button>
                    )}
                    {entreprise?.id && (
                      <button
                        className="btn btn-default cancelleb-btn"
                        onClick={() => handleReset()}
                      >
                        Réinitialiser
                      </button>
                    )}
                    {props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                    {!props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        Enregistrer
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
            {props.userActionType === "edit" && (
              <form className="crud-form" onSubmit={props.onSubmit}>
                <div className="row crud-row">
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="edit_raison_sociale">
                        Raison Sociale
                      </label>
                      <input
                        defaultValue={entreprise?.raison_sociale}
                        {...props.register("raison_sociale")}
                        type="text"
                        onChange={handleChange}
                        placeholder="Raison Sociale"
                        name="raison_sociale"
                        className="form-control crud-form-control"
                        id="edit_raison_sociale"
                      />
                      {props.formState.errors &&
                        props.formState.errors.raison_sociale && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.raison_sociale?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="edit_ninea">NINEA</label>
                      <input
                        defaultValue={entreprise?.ninea}
                        {...props.register("ninea")}
                        type="text"
                        maxLength={12}
                        placeholder="NINEA"
                        name="ninea"
                        className="form-control crud-form-control"
                        id="edit_ninea"
                      />
                      {props.formState.errors &&
                        props.formState.errors.ninea && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.ninea?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="edit_nom">Nom</label>
                      <input
                        defaultValue={entreprise?.nom}
                        {...props.register("nom")}
                        type="text"
                        placeholder="Nom"
                        name="nom"
                        className="form-control crud-form-control"
                        id="edit_nom"
                      />
                      {props.formState.errors && props.formState.errors.nom && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.nom?.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="edit_email">Email</label>
                      <input
                        defaultValue={entreprise?.email}
                        {...props.register("email")}
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="form-control crud-form-control"
                        id="edit_email"
                      />
                      {props.formState.errors &&
                        props.formState.errors.email && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.email?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="edit_adresse">Adresse</label>
                      <input
                        defaultValue={entreprise?.adresse}
                        {...props.register("adresse")}
                        type="text"
                        placeholder="Adresse"
                        name="adresse"
                        className="form-control crud-form-control"
                        id="edit_adresse"
                      />
                      {props.formState.errors &&
                        props.formState.errors.adresse && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.adresse?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="edit_telephone">Téléphone</label>
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control crud-form-control"
                        country={"sn"}
                        onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      {props.formState.errors &&
                        props.formState.errors.telephone && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.telephone?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_num_contrat">Référence</label>
                      <input
                        defaultValue={entreprise?.num_contrat}
                        {...props.register("num_contrat")}
                        type="text"
                        placeholder="Référence"
                        name="num_contrat"
                        className="form-control crud-form-control"
                        id="edit_num_contrat"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_website">Site Web</label>
                      <input
                        defaultValue={entreprise?.website}
                        onChange={(e) => handleChangeWebsite(e)}
                        type="text"
                        placeholder="Site Web"
                        name="website"
                        className="form-control crud-form-control"
                        id="edit_website"
                      />
                      {props.formState.errors &&
                        props.formState.errors.website && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.website?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* <input
                    {...props.register('ipm_from')}
                    type="hidden"
                    name="ipm_from"
                    value={props.fromIpmID}
                  /> */}
                  <input
                    {...props.register("user_type")}
                    type="hidden"
                    name="user_type"
                    value={`entreprise`}
                  />
                  <input
                    {...props.register("id")}
                    type="hidden"
                    name="id"
                    value={entreprise?.id ? entreprise?.id : ""}
                  />

                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                      onClick={() => handleReset()}
                    >
                      Retour
                    </button>
                    {props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                    {!props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        Enregistrer
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRUDEntrepriseModal;
