import React, { useEffect, useContext } from 'react'
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from 'react-bootstrap-table'
import TopOppositionAdminTableSkeleton from './TopOppositionAdminTableSkeleton'
import * as IconlyPack from 'react-iconly'
import { NavLink } from 'react-router-dom'
import TopOppositionAdminTableData from './TopOppositionAdminTableData'
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'


var TopOppositionAdminTable = (props) => {

 
  const [montant, setMontant] = React.useState(TopOppositionAdminTableData)

  var [isPageLoading, setIsPageLoading] = React.useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false)
    }, 3000)
  }, [montant])

  var handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event')
    onClick()
  }

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    )
  }

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: 'Aucune donnée disponible',
  }
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <button className="action-btn edit-btn" title="Traitement">
          <IconlyPack.Edit
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".show-fiche-modal"
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Show
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".show-soin-modal"
          />
        </button>
      </div>
    );
  };
  
  const cleFormatter = (cell, row) => {
    //  let beneficiaire = cell.beneficiaire
      return (
        <div className="nameFormatter" >
          <span className="name">
            {row?.cle}
          </span>{" "}
          <br />
          <strong className="entreprise">
            {" "}
            {row?.sous_cle}{" "}
          </strong>{" "}
          
        </div>
      );
   
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <TopOppositionAdminTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={montant}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={true}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="90"
                    dataField="type"
                  >
                    Type
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="200"
                    dataField="libelle"
                  >
                    Libellé
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="200"
                    dataField="cle"
                    dataFormat={(cell, row) => cleFormatter(cell, row)}

                  >
                    Clé
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="date_validation"
                  >
                     Date de validité
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="date_fin"
                  >
                    Date de fin 
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopOppositionAdminTable
     