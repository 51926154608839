import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import Exception from "../../../../Services/Exception";
import { getDataByID } from "../../../../utils/fetchData";
import { DataContext } from "../../../../store/GlobalState";

registerLocale("fr", fr);

function ModifierOpposition() {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());
  const [isValid, setIsValid] = React.useState(true);
  // const { auth, exception, exceptions } = state;
  const {
    auth,
    exception,
    assureur_entreprises,
    assureur_beneficiaires,
    assureur_prestataires,
  } = state;
  const [cles, setCles] = React.useState([{ value: "", label: "" }]);
  const [cle, setCle] = React.useState({ value: "", label: "" });
  const [val, setVal] = React.useState("");

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  React.useEffect(() => {
    if (exception?.id) {
      register("cle", {
        required: "Ce champ est obligatoire",
      });
      setValue("cle", exception?.cle?.id);
      register("date_debut", {
        required: "Ce champ est obligatoire",
      });
      setValue("date_debut", exception?.date_debut);
      register("date_fin", {
        required: "Ce champ est obligatoire",
      });
      setValue("date_fin", exception?.date_fin);
      register("type", {
        required: "Ce champ est obligatoire",
      });
      setValue("type", exception?.type);
      setStartDate(new Date(exception?.date_debut));
      setEndDate(new Date(exception?.date_fin));
      if (exception?.type === "beneficiaire") {
        setCles(
          assureur_beneficiaires?.data?.map((b) => {
            return {
              label: b.prenom + " " + b.nom,
              value: b.id,
            };
          })
        );
        setCle({
          label: exception?.cle.prenom + " " + exception?.cle.nom,
          value: exception?.cle.id,
        });
      }

      if (exception?.type === "entreprise") {
        setCles(
          assureur_entreprises?.data.map((b) => {
            return {
              label: b.raison_sociale,
              value: b.id,
            };
          })
        );
        setCle({
          label: exception?.cle?.raison_sociale,
          value: exception?.cle.id,
        });
      }

      if (exception?.type === "ipm") {
        if (!auth?.user?.admin_type) {
          setCles([
            {
              label: auth?.user?.raison_sociale,
              value: auth?.user?.id,
            },
          ]);
        } else {
          setCles([
            {
              label: auth?.user?.raison_sociale,
              value: auth?.user?.parent,
            },
          ]);
        }

        setCle({
          label: exception?.cle?.raison_sociale,
          value: exception?.cle.id,
        });
      }

      if (exception?.type === "prestataire") {
        setCles(
          assureur_prestataires?.data?.map((b) => {
            return {
              label:
                (b?.raison_sociale
                  ? b?.raison_sociale
                  : b?.prenom + " " + b?.nom) || "",
              value: b.id || "",
            };
          })
        );
        setCle({
          label:
            exception?.raison_sociale ||
            exception?.cle.prenom + " " + exception?.cle.nom,
          value: exception?.cle.id,
        });
      }
    }
  }, [exception]);

  const handleSelect = (e) => {
    register("type");
    setValue("type", e.currentTarget.value);
    if (e.target.value === "beneficiaire") {
      setCles(
        assureur_beneficiaires?.data?.map((b) => {
          return {
            label: b.prenom + " " + b.nom,
            value: b.id,
          };
        })
      );
    }

    if (e.target.value !== exception?.type) {
      setCle({ label: "", value: "" });
      register("cle", {
        required: "Ce champ est obligatoire",
      });
      setValue("cle", "");
    }

    if (e.target.value === "entreprise") {
      setCles(
        assureur_entreprises?.data.map((b) => {
          return {
            label: b.raison_sociale,
            value: b.id,
          };
        })
      );
    }

    if (e.target.value === "ipm") {
      if (!auth?.user?.admin_type) {
        setCles([
          {
            label: auth?.user?.raison_sociale,
            value: auth?.user?.id,
          },
        ]);
      } else {
        setCles([
          {
            label: auth?.user?.raison_sociale,
            value: auth?.user?.parent,
          },
        ]);
      }
    }

    if (e.target.value === "prestataire") {
      setCles(
        assureur_prestataires?.data?.map((b) => {
          return {
            label:
              (b?.raison_sociale
                ? b?.raison_sociale
                : b?.prenom + " " + b?.nom) || "",
            value: b.id || "",
          };
        })
      );
    }
  };

  const handleDate = (date) => {
    setStartDate(date);
    register("date_debut");
    setValue("date_debut", moment(date).format("YYYY-MM-DD"));
    if (endDate && date > endDate) {
      setIsValid(false);
    }
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      register("date_fin");
      setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };
  const onSelect = (e) => {
    console.log(e.value);
    // setVal(e.value);
    setCle(e);
    register("cle", {
      required: "Ce champ est obligatoire",
    });
    setValue("cle", e.value);
  };

  // const onSelect = (e) => {
  //   register("cle");
  //   setValue("cle", e.currentTarget.value);
  // };

  const onSubmit = async (data, e) => {
    // console.log('data', data)
    setIsLoading(true);

    var exceptionRequest = Exception.putException(
      exception.id,
      data,
      requestConfig
    );

    await exceptionRequest
      .then((response) => {
        var exceptionInfos = response;
        console.log("exception", exceptionInfos);
        if (auth?.user?.admin_type === "assureur") {
          getDataByID("exceptionbyipm", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({
                type: "OPPOSITION_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res.data.sort((a, b) => b?.id - a?.id),
                },
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
          getDataByID("rapportbyipm", auth?.user?.parent, auth.token).then(
            (res) => {
              // console.log('rapport', res)
              dispatch({
                type: "RAPPORT_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res,
                },
              });
            }
          );
        } else {
          getDataByID("exceptionbyipm", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "OPPOSITION_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res.data.sort((a, b) => b?.id - a?.id),
                },
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
          getDataByID("rapportbyipm", auth?.user?.id, auth.token).then(
            (res) => {
              // console.log('rapport', res)
              dispatch({
                type: "RAPPORT_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res,
                },
              });
            }
          );
        }
        setIsLoading(false);

        window.$(".edit-opposition-modal").modal("hide");
        dispatch({ type: "EDIT_EXCEPTION", payload: {} });
        e.target.reset();
        toast.success("Opposition modifiée avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          toast.error(
            "Oupsss! L'adresse email est déjà associée à un compte. Veuillez utiliser une autre adresse email.",
            {
              position: "top-right",
              autoClose: 10000,
            }
          );
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Modifier une opposition
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "EDIT_EXCEPTION", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                {/* <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="type">Type d'exception</label>
                    <input
                      type="text"
                      placeholder="Type"
                      name="type"
                      className="form-control crud-form-control"
                      id="type"
                      {...register("type")}
                      defaultValue={exception?.type}
                    />
                    {errors.type && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {errors.type.message}{" "}
                      </div>
                    )}
                  </div>
                </div> */}
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="type">Type d'exception</label>
                    <div className="filter-item-container">
                      {exception?.id && (
                        <select
                          name="type"
                          id="type"
                          // {...props.register("type")}
                          defaultValue={exception?.type || ""}
                          onChange={handleSelect}
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                        >
                          <option hidden value="">
                            Choisir un type
                          </option>
                          <option value="ipm">Assurance</option>
                          {auth?.user?.user_type !== "prestataire" && (
                            <>
                              <option value="beneficiaire">Adhérent</option>
                              <option value="entreprise">Entreprise</option>
                              <option value="prestataire">Prestataire</option>
                            </>
                          )}
                        </select>
                      )}

                      {errors.type && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {errors.type.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="libelle">Libellé</label>
                    <input
                      type="text"
                      placeholder="Libellé"
                      name="libelle"
                      className="form-control crud-form-control"
                      id="libelle"
                      {...register("libelle")}
                      defaultValue={exception?.libelle}
                    />
                    {errors.libelle && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {errors.libelle.message}{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Objet de l’opposition</label>
                    <div className="filter-item-container">
                      {exception?.cle?.id && (
                        // <select
                        //   name="cle"
                        //   defaultValue={exception?.cle?.id}
                        //   onChange={(e) => onSelect(e)}
                        //   className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        //   aria-label="Default select example"
                        // >
                        //   <option hidden value="">Clé</option>
                        //   {cles &&
                        //     cles?.map((cle) => (
                        //       <option key={cle.value} value={cle.value}>
                        //         {cle?.label}
                        //       </option>
                        //     ))}
                        // </select>
                        <Select
                          placeholder="Selectionner l'objet de l’opposition"
                          name="cle"
                          value={cle}
                          onChange={(e) => onSelect(e)}
                          options={cles}
                        />
                      )}

                      {errors.cle && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {errors.cle.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required crud-interm prestataire-side">
                    <label htmlFor="date_debut">Date de début</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date_debut"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {errors.date_debut && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {errors.date_debut.message}{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required crud-interm prestataire-side">
                    <label htmlFor="date_fin">Date de Fin</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={endDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date_fin"
                      locale="fr"
                      onChange={(date) => handleDateFin(date)}
                    />
                    {errors.date_fin && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {errors.date_fin.message}{" "}
                      </div>
                    )}
                    {!isValid && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        La date de fin ne doit pas être inférieure à la date de
                        début.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    data-dismiss="modal"
                    onClick={() =>
                      dispatch({ type: "EDIT_EXCEPTION", payload: {} })
                    }
                  >
                    Retour
                  </button>

                  {!isLoading ? (
                    <button className="btn crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                  ) : (
                    <button className="btn crud-submit-btn-assurance">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifierOpposition;
