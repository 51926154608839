import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../../../store/GlobalState";
import AyantDroit from "../../../../Services/ayantDroit";

const ArchiveFamily = ({ getAyantDroit, beneficiaire }) => {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, ayantDroit, ayantDroits } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const handleDelete = async () => {
    setIsLoading(true);
    var ayantDroitRequest = AyantDroit.deleteAyantDroit(
      ayantDroit.id,
      requestConfig
    );
    await ayantDroitRequest
      .then(() => {
        setIsLoading(false);
        getAyantDroit(beneficiaire?.id);
        window.$(".archive-family-modal").modal("hide");
        dispatch({ type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE", payload: {} });
        toast.success("Ayant droit supprimé !");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la suppréssion.");
      });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="DeletingFamilyModal"
          >
            Suppression d'un ayant droit
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              dispatch({ type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE", payload: {} })
            }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="deleting-family-form-container">
            <div className="alert-delete-message">
              <span>Êtes vous sûr de vouloir supprimer cet ayant droit ?</span>
            </div>

            <div className="row delete-row">
              <div className="col-md-10 offset-md-2 crud-form-action-btn-container">
                <button
                  onClick={() =>
                    dispatch({
                      type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE",
                      payload: {},
                    })
                  }
                  className="btn btn-default cancelleb-btn delete-actions"
                  data-dismiss="modal"
                >
                  Retour
                </button>
                {!isLoading ? (
                  <button
                    className="btn btn-primary crud-submit-btn delete-actions"
                    onClick={handleDelete}
                  >
                    Valider
                  </button>
                ) : (
                  <button className="btn btn-primary crud-submit-btn delete-actions">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchiveFamily;
