import React, { useContext } from "react";
import { DataContext } from "../store/GlobalState";

const ShowExclusion = () => {
  const { state, dispatch } = useContext(DataContext);

  const { exclusion } = state;
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">Detail Exclusion</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "EDIT_EXCLUSION", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="crud-form">
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Désignation: </strong>

                    <span className="crud-form-control">
                      {exclusion?.designation}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="name">Famille de soins: </strong>
                    <span className="crud-form-control">
                      {exclusion?.familledesoin?.nom}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="name">Type de soins: </strong>
                    <span className="crud-form-control">
                      {exclusion?.typedesoin?.nom}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="specialite">Ipm</strong>
                    <span className="name crud-form-control">
                      {exclusion?.ipm?.raison_sociale}
                    </span>{" "}
                  </div>
                </div>

                <div
                  className="col-md-6 offset-md-6 crud-form-action-btn-container"
                  style={{ justifyContent: "flex-end" }}
                >
                  {/* <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                    >
                      Retour
                    </button> */}

                  <button
                    className="btn btn-danger cancelleb-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      dispatch({ type: "EDIT_EXCLUSION", payload: {} })
                    }
                  >
                    Retour
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowExclusion;
