import { specialite } from "./Endpoints";
import axios from "axios";

export default {
  async getListSpecialites(config) {
    const response = await axios.get(
      specialite.onGetOrPostSpecialite(),
      config
    );
    // console.log(response)
    return response.data;
  },
  async postCreateSpecialite(data, config) {
    const response = await axios.post(
      specialite.onGetOrPostSpecialite(),
      data,
      config
    );
    return response.data;
  },
  async putSpecialite(id, data, config) {
    const response = await axios.put(
      specialite.onEditSpecialite(id),
      data,
      config
    );
    return response.data;
  },
  async deleteSpecialite(id, config) {
    const response = await axios.delete(
      specialite.onDeleteSpecialite(id),
      config
    );
    return response;
  },
};
