import React, { useEffect, useContext } from 'react'
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from 'react-bootstrap-table'
import AutresAdminsTableData from './AutresAdminsTableData'
import AutresAdminsTableSkeleton from './AutresAdminsTableSkeleton'
import * as IconlyPack from 'react-iconly'
import { DataContext } from '../../store/GlobalState'
import ShowSuperAdminModal from '../../Modals/ShowSuperAdminModal'
import EditSuperAdminModal from '../../Modals/EditSuperAdminModal'

var AutresAdminsTable = (props) => {
  const { state, dispatch } = useContext(DataContext)

  const { auth, admin_ipms } = state
  var [isPageLoading, setIsPageLoading] = React.useState(true)
  // eslint-disable-next-line
  var [admins, setAdmins] = React.useState(AutresAdminsTableData)

  // useEffect(() => {
  //     setTimeout(() => {
  //         setIsPageLoading(false)
  //     }, 5000);
  // }, [admins])

  useEffect(() => {
    // setTimeout(() => {
    //   setIsPageLoading(false)
    // }, 5000)
    if (auth?.token && auth?.user?.id) {
      setIsPageLoading(false)
    } else {
      setIsPageLoading(true)
    }
  }, [auth])

  var handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event')
    onClick()
  }

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    )
  }

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: 'Aucune donnée disponible',
  }

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button
          onClick={() => onGetItem(row)}
          className="action-btn show-btn"
          data-toggle="modal"
          data-target=".show-super-admin-modal"
          title="Details"
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
          />
        </button>
        <button
          className="action-btn edit-btn"
          onClick={() => onGetItem(row)}
          data-toggle="modal"
          data-target=".edit-super-admin-modal"
          title="Modification"
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
        {/* <button className="action-btn delete-btn">
                    <IconlyPack.Delete set="light" primaryColor="#C8C8C8" className="action-btn-icon action-delete-icon"/>
                </button> */}
      </div>
    )
  }

  const onGetItem = (item) => {
    dispatch({ type: 'EDIT_ADMIN_IPM', payload: item })
  }

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            <div className="table-container">
              {isPageLoading && <AutresAdminsTableSkeleton />}
              {!isPageLoading && (
                <BootstrapTable
                  data={props?.admin_ipms}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={true}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    dataField="prenom"
                    // width="250"
                  >
                    Prénom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    dataField="nom"
                    // width="150"
                  >
                    Nom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    // width="150"
                    dataField="email"
                  >
                    Email
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    // width="150"
                    dataField="telephone"
                  >
                    Téléphone
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                      padding: 15,
                    }}
                    // width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade easypm-modal show-super-admin-modal"
        id="ShowModalItem"
        tabIndex="-1"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
      >
        <ShowSuperAdminModal />
      </div>

      <div
        className="modal fade easypm-modal edit-super-admin-modal"
        id="ShowModalItem"
        data-backdrop="static"
        tabIndex="-1"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
      >
        <EditSuperAdminModal />
      </div>
    </div>
  )
}

export default AutresAdminsTable
