import React from "react";
import Select from "react-select";
import { DataContext } from "../store/GlobalState";
import { getData } from "../utils/fetchData";

const AddExclusionModal = (props) => {
  const { state } = React.useContext(DataContext);
  const { auth, ipms, list_famille_soins } = state;
  const [types, setTypes] = React.useState([]);
  const [clesFamily, setClesFamily] = React.useState([
    { value: "", label: "" },
  ]);
  const [clesType, setClesType] = React.useState([{ value: "", label: "" }]);
  const [cles, setCles] = React.useState([{ value: "", label: "" }]);
  const [val, setVal] = React.useState("");
  const [valFamily, setValFamily] = React.useState("");
  const [valType, setValType] = React.useState("");

  React.useEffect(() => {
    if (auth?.token) {
      getData("typedesoin/", auth.token).then((response) => {
        setTypes(response.results.sort((a, b) => b?.id - a?.id));
      });
    }
    if (
      ipms?.length &&
      auth?.user?.user_type !== "ipm" &&
      auth?.user?.user_type !== "assurance_dante"
    ) {
      setCles(
        ipms.map((b) => {
          return {
            label: b.raison_sociale,
            value: b.id,
          };
        })
      );
    }

    if (list_famille_soins?.length) {
      setClesFamily(
        list_famille_soins.map((b) => {
          return {
            label: b.nom,
            value: b.id,
          };
        })
      );
    }
  }, [auth, ipms, list_famille_soins]);

  React.useEffect(() => {
    if (props?.isSuccess) {
      setVal("");
      setValFamily("");
      setValType("");
    }
  }, [props]);

  const onSelect = (e) => {
    console.log(e.value);
    setVal(e);
    props.register("ipm");
    props.setValue("ipm", e.value);
  };

  const onSelectType = (e) => {
    console.log(e.value);
    setValType(e);
    props.register("typedesoin");
    props.setValue("typedesoin", e.value);
  };

  const onSelectFamily = (e) => {
    console.log(e.value, types);
    setValFamily(e);
    if (
      types?.length &&
      types.filter((t) => t?.familledesoin?.id === e.value)?.length
    ) {
      setClesType(
        types
          .filter((t) => t?.familledesoin?.id === e.value)
          .map((b) => {
            return {
              label: b.nom,
              value: b.id,
            };
          })
      );
    }
    props.register("familledesoin");
    props.setValue("familledesoin", e.value);
  };
  const handleReset = () => {
    props.reset();
    setVal("");
    setValFamily("");
    setValType("");
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Ajouter une exclusion
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => handleReset()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={props?.onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="libelle">Désignation</label>
                    <input
                      type="text"
                      placeholder="Désignation"
                      name="designation"
                      className="form-control crud-form-control"
                      id="designation"
                      {...props.register("designation")}
                    />
                    {props.formState.errors &&
                      props.formState.errors.designation && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.designation?.message}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Famille de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner la famille de soins"
                        name="familledesoin"
                        value={valFamily}
                        onChange={(e) => onSelectFamily(e)}
                        options={clesFamily}
                      />
                      {props.formState.errors &&
                        props.formState.errors.familledesoin && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.familledesoin?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Type de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner le type de soins"
                        name="typedesoin"
                        value={valType}
                        onChange={(e) => onSelectType(e)}
                        options={clesType}
                      />
                      {props.formState.errors &&
                        props.formState.errors.typedesoin && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.typedesoin?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                {auth.user?.user_type !== "ipm" &&
                auth?.user?.user_type !== "assurance_sante" &&
                auth?.user?.user_type !== "admin_ipm" ? (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="cle">Ipm</label>
                      <div className="filter-item-container">
                        <Select
                          placeholder="Selectionner l'ipm"
                          name="ipm"
                          value={val}
                          onChange={(e) => onSelect(e)}
                          options={cles}
                        />
                        {props?.formState.errors &&
                          props?.formState.errors.ipm && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {props?.formState.errors.ipm?.message}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <input
                    type="hidden"
                    name="ipm"
                    {...props?.register("ipm")}
                    value={
                      auth.user?.user_type === "ipm" ||
                      auth?.user?.user_type === "assurance_sante"
                        ? auth?.user?.id
                        : auth?.user?.parent
                    }
                  />
                )}

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!props?.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExclusionModal;
