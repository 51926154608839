import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import PrestationDeSoin from "../Services/PrestationDeSoin";
import Remboursement from "../Services/Remboursement";
import { updateItem } from "../store/Actions";
import { DataContext } from "../store/GlobalState";
import { getDataByID } from "../utils/fetchData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { formatMontant } from "../../utils/utils";

registerLocale("fr", fr);

const calcul = (cout_montant, taux, montant_plafond) => {
  let a_rembourser = (parseFloat(cout_montant) * taux) / 100;

  if (a_rembourser > montant_plafond) {
    a_rembourser = montant_plafond;
  }
  let a_retenir = parseFloat(cout_montant) - a_rembourser;
  return {
    retenu: a_retenir,
    rembourser: a_rembourser,
  };
};

const EditModalSoin = ({ beneficiaires }) => {
  const [load, setLoad] = React.useState(false);
  const [patients, setPatients] = React.useState([]);
  const { state, dispatch } = React.useContext(DataContext);
  const {
    prestation,
    auth,
    prestation_types,
    prestationbyassurance,
    prestataires,
    prestations,
    prestatairebyassurance,
    list_famille_soins,
  } = state;
  const [types, setTypes] = React.useState([]);
  const [clesFamily, setClesFamily] = React.useState([
    { value: "", label: "" },
  ]);
  const [clesType, setClesType] = React.useState([{ value: "", label: "" }]);
  const [valFamily, setValFamily] = React.useState("");
  const [valType, setValType] = React.useState("");
  const [prestation_typ, setPrestation_type] = React.useState("");
  // eslint-disable-next-line
  const [retenu, setRetenu] = React.useState(0);
  const [type, setType] = React.useState("");
  const [montant, setMontant] = React.useState(0);
  // eslint-disable-next-line
  const [rembourser, setRembouser] = React.useState(0);
  const [item_type, setItem_type] = React.useState({
    taux: 0,
    montant: 0,
  });
  var [startDate, setStartDate] = React.useState(new Date());
  const [item, setItem] = React.useState({});
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  var [selected, setSelected] = React.useState({});
  const [prestation_type, setPrestation_types] = React.useState([
    { value: "", label: "" },
  ]);

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        date: yup.string().required("La date est requise"),
        // assurance: yup.string().required("L'assurance est requise"),
        beneficiaire: yup.string().required("Le participant est requis"),
        prestataire: yup.string().required("Veuillez choisir le prestataire"),
        montant: yup.string().required("Le Montant est requis"),
        typedesoin: yup.string().required("Le type de soins est requis"),
        familledesoin: yup
          .string()
          .required("La Famille de soins est requise."),
        type_prestation: yup
          .string()
          .required("Le type de prestation est requis."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    if (formState?.errors) {
      setTimeout(() => {
        clearErrors();
      }, 5000);
    }
  }, [formState?.errors]);

  React.useEffect(() => {
    if (list_famille_soins?.length) {
      setClesFamily(
        list_famille_soins.map((b) => {
          return {
            label: b.nom,
            value: b.id,
          };
        })
      );
    }
  }, [auth, list_famille_soins]);

  React.useEffect(() => {
    if (prestation?.id) {
      const param = prestation?.prestation?.beneficiaire?.id;
      // setDate(prestation.date)
      setMontant(prestation?.prestation.montant);
      console.log(prestation);
      setItem(prestation?.prestation);

      setType(prestation?.prestation?.type_prestation?.id);
      setRembouser(String(prestation?.prestation?.montant_rembourse));
      setRetenu(prestation?.prestation?.montant_retenu);
      setStartDate(new Date(prestation?.prestation?.date));
      let montant_plafond =
        prestation?.prestation?.type_prestation?.montant_plafond || 0;
      let taux_prise_en_charge =
        prestation?.prestation?.type_prestation?.taux_prise_en_charge;
      setItem_type({
        montant: montant_plafond,
        taux: taux_prise_en_charge,
      });
      register("beneficiaire");
      setValue("beneficiaire", prestation?.prestation?.beneficiaire?.id);
      register("date");
      setValue("date", prestation?.prestation?.date);
      register("libelle");
      setValue("libelle", prestation?.libelle);
      register("assurance");
      setValue("assurance", prestation?.prestation?.assurance?.id);
      register("prestataire");
      setValue("prestataire", prestation?.prestation?.prestataire?.id);
      register("ayant_droit");
      setValue("ayant_droit", prestation?.prestation?.ayant_droit?.id);
      register("type_prestation");
      setValue("type_prestation", prestation?.prestation?.type_prestation?.id);
      if (prestation?.prestation?.type_prestation?.id) {
        setPrestation_type({
          value: prestation?.prestation?.type_prestation?.id,
          label:
            prestation?.prestation?.type_prestation?.libelle +
            " " +
            "(" +
            prestation?.prestation?.type_prestation?.taux_prise_en_charge +
            "%" +
            ")",
        });
      }

      register("montant");
      setValue("montant", prestation?.prestation?.montant);
      register("montant_retenu");
      setValue("montant_retenu", prestation?.prestation?.montant_retenu);
      register("montant_rembourse");
      setValue("montant_rembourse", prestation?.prestation?.montant_rembourse);
      register("typedesoin");
      setValue("typedesoin", prestation?.prestation?.typedesoin?.id);
      if (prestation?.prestation?.typedesoin?.id) {
        setValType({
          label: prestation?.prestation?.typedesoin?.nom,
          value: prestation?.prestation?.typedesoin?.id,
        });
        if (prestation_types?.length) {
          let types = prestation_types?.filter(
            (t) => t?.typedesoin?.id === prestation.prestation.typedesoin.id
          );
          if (types?.length) {
            setPrestation_types(
              types.map((p) => {
                return {
                  label:
                    p?.libelle +
                    " " +
                    "(" +
                    p?.taux_prise_en_charge +
                    "%" +
                    ")",
                  value: p?.id,
                };
              })
            );
          } else {
            setPrestation_types([{ value: "", label: "" }]);
          }
        }
      }

      register("familledesoin", {
        required: "La famille de soins est requise.",
      });
      setValue("familledesoin", prestation?.prestation?.familledesoin?.id);
      if (prestation?.prestation?.familledesoin?.id) {
        setValFamily({
          label: prestation?.prestation?.familledesoin?.nom,
          value: prestation?.prestation?.familledesoin?.id,
        });
        getDataByID(
          "typedesoinbyfamille",
          prestation.prestation.familledesoin.id,
          auth?.token
        ).then((response) => {
          // console.log(response.results, "test");
          if (response.results?.length) {
            setClesType(
              response.results.map((b) => {
                return {
                  label: b.nom,
                  value: b.id,
                };
              })
            );
          }
        });
      }

      //   console.log(prestation);
      if (prestation?.prestation?.beneficiaire?.id) {
        let benef = beneficiaires.filter((b) => b?.id === parseInt(param));
        getDataByID("ayantdroitbybeneficiaire", param, auth.token).then(
          (result) => {
            if (result?.length) {
              setPatients([...benef, ...result]);
            } else {
              setPatients(benef);
            }
          }
        );
      }
    }
  }, [prestation?.id, prestation_types]);

  const onSubmit = async (data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    };
    setLoad(true);
    console.log("data update=>", data);
    // if (prestation?.etat_ipm === "en_attente") {
    // }

    try {
      const request = await PrestationDeSoin.putPrestation(
        prestation?.prestation.id,
        data,
        config
      );
      console.log("request=>", request);
      let prestationInfo = request;
      if (prestationInfo?.id) {
        Remboursement.putRemboursement(
          prestation.id,
          {
            date: data.date,
            montant: data.montant,
            prestation: prestationInfo?.id,
          },
          config
        ).then((res) => {
          console.log("res", res);
          if (auth?.user?.user_type === "admin_ipm") {
            getDataByID("remboursementbyipm", auth?.user?.parent, auth?.token)
              .then((res) => {
                dispatch({
                  type: "GET_FICHES_DE_SOIN_FOR_IPM",
                  payload: res.sort((a, b) => a.id - b.id).reverse(),
                });
              })
              .catch((err) => console.log("erreur=>", err));
          } else {
            getDataByID("remboursementbyipm", auth?.user?.id, auth?.token)
              .then((res) => {
                dispatch({
                  type: "GET_FICHES_DE_SOIN_FOR_IPM",
                  payload: res.sort((a, b) => a.id - b.id).reverse(),
                });
              })
              .catch((err) => console.log("erreur=>", err));
          }
          setLoad(false);
          window.$(".edit-soin-modal").modal("hide");
          dispatch({ type: "EDIT_PRESTATION", payload: {} });
          toast.success("Traitement de remboursement modifié avec succès.");
          setValFamily("");
          setValType("");
          setPrestation_type("");
          setPrestation_types([]);
          setPatients([]);
        });
      }
    } catch (error) {
      console.log("error", error);
      setLoad(false);
      toast.error("Oupss! Une erreur est survenue lors de la modification.");
    }
  };

  const handleDate = (date) => {
    setStartDate(date);
    register("date");
    setValue("date", moment(date).format("YYYY-MM-DD"));
  };

  const onSelect = (e) => {
    let params = e.target.value;
    setValue("beneficiaire", e.target.value);
    setValue("montant", montant);
    let benef = beneficiaires.filter((b) => b?.id === parseInt(params));
    getDataByID("ayantdroitbybeneficiaire", params, auth.token)
      .then((res) => {
        if (res?.length) {
          setPatients([...benef, ...res]);
        } else {
          setPatients(benef);
        }
        setSelected(benef[0]);
      })
      .catch((error) => console.log("error=>", error));
  };

  const onSelectAyant = (e) => {
    let current = e.target.value;
    // console.log('current=>', current)
    let benef = beneficiaires.filter((b) => b?.id === parseInt(current));

    if (benef?.length && benef[0]?.prenom === selected?.prenom) {
      console.log("benef");
    } else {
      register("ayant_droit");
      setValue("ayant_droit", current);
    }
  };

  const onSelectType = (e) => {
    let params = e.value;
    setPrestation_type(e);
    setValue("type_prestation", params);
    // console.log('params', params)
    if (params && params !== undefined && params !== null) {
      setType(params);
      // eslint-disable-next-line
      let montant_plafond = prestation_types.filter((f) => f.id == params)[0]
        ?.montant_plafond;
      // eslint-disable-next-line
      let taux_prise_en_charge = prestation_types.filter(
        (f) => f.id == params
      )[0]?.taux_prise_en_charge;
      setItem_type({
        montant: montant_plafond,
        taux: taux_prise_en_charge,
      });
      if (montant_plafond !== 0 && montant_plafond !== null) {
        if (montant) {
          //let montant = getValues('montant')

          const _getCalcul = calcul(
            montant,
            taux_prise_en_charge,
            montant_plafond
          );
          setRembouser(Math.abs(_getCalcul.rembourser));
          setRetenu(Math.abs(_getCalcul.retenu));

          setValue("montant_rembourse", Math.abs(_getCalcul.rembourser));
          setValue("montant_retenu", Math.abs(_getCalcul.retenu));
        }
      }
    } else {
      setItem_type({ montant: 0, taux: 0 });
      setRembouser(0);
      setRetenu(0);
    }
  };

  const onChangeMontant = (e) => {
    setValue("montant", parseFloat(e.currentTarget.value));
    setMontant(parseFloat(e.currentTarget.value));
    item.montant = parseFloat(e.currentTarget.value);
    // register('montant')
    setItem(item);

    if (type) {
      let montant_plafond = prestation_types.filter((f) => f.id == type)[0]
        ?.montant_plafond;
      // eslint-disable-next-line
      console.log("type", montant_plafond);
      let taux_prise_en_charge = prestation_types.filter((f) => f.id == type)[0]
        ?.taux_prise_en_charge;
      setItem_type({
        montant: montant_plafond,
        taux: taux_prise_en_charge,
      });
      if (montant_plafond !== 0) {
        if (getValues("montant") !== "") {
          let montant = getValues("montant");
          const _getCalcul = calcul(
            montant,
            taux_prise_en_charge,
            montant_plafond
          );
          setRembouser(Math.abs(_getCalcul.rembourser));
          setRetenu(Math.abs(_getCalcul.retenu));
          setValue("montant_rembourse", Math.abs(_getCalcul.rembourser));
          setValue("montant_retenu", Math.abs(_getCalcul.retenu));
        }
      }
    }
  };

  const handleSelectType = (e) => {
    console.log(e.value);
    setValType(e);
    // setPrestation_types(
    //   prestation_types?.filter((t) => t?.typedesoin?.id === e.value)
    // );
    register("typedesoin");
    setValue("typedesoin", e.value);
    let type = prestation_types?.filter((t) => t?.typedesoin?.id === e.value);
    if (type?.length) {
      onSelectType(type[0]?.id);
      // if (type?.length) {
      //   setPrestation_types(
      //     type.map((p) => {
      //       return {
      //         label:
      //           p?.libelle + " " + "(" + p?.taux_prise_en_charge + "%" + ")",
      //         value: p?.id,
      //       };
      //     })
      //   );
      // }
      if (type?.length > 1) {
        setPrestation_types(
          type.map((p) => {
            return {
              label:
                p?.libelle + " " + "(" + p?.taux_prise_en_charge + "%" + ")",
              value: p?.id,
            };
          })
        );
      } else {
        setValue("type_prestation", types[0]?.id);
        handleMont(types[0]?.id);
      }
    } else {
      onSelectType();
    }
  };

  const handleMont = (id) => {
    if (id && id !== undefined && id !== null) {
      setType(id);
      // eslint-disable-next-line
      let montant_plafond = prestation_types.filter((f) => f.id == id)[0]
        ?.montant_plafond;
      // eslint-disable-next-line
      let taux_prise_en_charge = prestation_types.filter((f) => f.id == id)[0]
        ?.taux_prise_en_charge;
      setItem_type({
        montant: montant_plafond,
        taux: taux_prise_en_charge,
      });
      if (montant_plafond !== 0 && montant_plafond !== null) {
        if (montant) {
          //let montant = getValues('montant')

          const _getCalcul = calcul(
            montant,
            taux_prise_en_charge,
            montant_plafond
          );
          setRembouser(Math.abs(_getCalcul.rembourser));
          setRetenu(Math.abs(_getCalcul.retenu));

          setValue("montant_rembourse", Math.abs(_getCalcul.rembourser));
          setValue("montant_retenu", Math.abs(_getCalcul.retenu));
        }
      }
    } else {
      setItem_type({ montant: 0, taux: 0 });
      setRembouser(0);
      setRetenu(0);
    }
  };

  const onSelectFamily = (e) => {
    // console.log(e.value, types);
    setValFamily(e);

    getDataByID("typedesoinbyfamille", e?.value, auth?.token)
      .then((response) => {
        console.log(response.results, "test");
        if (response.results?.length) {
          setClesType(
            response.results.map((b) => {
              return {
                label: b.nom,
                value: b.id,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
    register("familledesoin");
    setValue("familledesoin", e.value);
  };

  const handleReset = () => {
    reset();
    dispatch({ type: "EDIT_PRESTATION", payload: {} });
    setValFamily("");
    setValType("");
    setPrestation_types([]);
    setPatients([]);
    setPrestation_type("");
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Modification Soins
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="first_name">Participant</label>
                    <div className="filter-item-container">
                      <select
                        onChange={(e) => onSelect(e)}
                        name="beneficiaire"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                        // {...register('beneficiaire')}
                      >
                        <option hidden value="">
                          Participant
                        </option>
                        {beneficiaires
                          .filter((b) => b?.is_active === true)
                          .map((benef) => {
                            let selected =
                              benef.id ===
                              prestation?.prestation?.beneficiaire?.id
                                ? benef.nom
                                : null;
                            // console.log("selected=>", selected)
                            return (
                              <option
                                selected={selected}
                                key={benef.id}
                                value={benef.id}
                              >
                                {benef?.prenom} {benef?.nom}
                              </option>
                            );
                          })}
                        {formState.errors && formState.errors.beneficiaire && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.beneficiaire?.message}
                          </div>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="first_name">Bénéficiaire</label>
                    <div className="filter-item-container">
                      {prestation?.id &&
                        prestation?.prestation?.beneficiaire?.id && (
                          <select
                            onChange={(e) => onSelectAyant(e)}
                            name="ayant_droit"
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                            defaultValue={
                              prestation?.prestation?.ayant_droit
                                ? prestation?.prestation?.ayant_droit?.id
                                : prestation?.prestation?.beneficiaire?.id
                            }
                          >
                            <option hidden value="">
                              Adhérent
                            </option>
                            {patients
                              ?.filter((b) => b?.is_active === true)
                              .map((p) => (
                                <option key={p.id} value={p.id}>
                                  {p?.prenom} {p?.nom}
                                </option>
                              ))}
                            {/* <option hidden value="">Choisir un patient.e</option> */}
                          </select>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Famille de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner la famille de soins"
                        name="familledesoin"
                        value={valFamily}
                        onChange={(e) => onSelectFamily(e)}
                        options={clesFamily}
                      />
                      {formState.errors && formState.errors.familledesoin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.familledesoin?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Type de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner le type de soins"
                        name="typedesoin"
                        value={valType}
                        onChange={(e) => handleSelectType(e)}
                        options={clesType}
                      />
                      {formState.errors && formState.errors.typedesoin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.typedesoin?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Type de prestation */}
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Type de prestation</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner le type de prestation"
                        name="type_prestation"
                        value={prestation_typ}
                        onChange={(e) => onSelectType(e)}
                        options={prestation_type}
                      />
                      {formState.errors && formState.errors.type_prestation && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.type_prestation?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* END */}
                <div className="col-md-6 crud-col-item">
                  <div className="crud-form-interm">
                    <div className="form-group crud-form-group crud-interm">
                      <label htmlFor="date">Date</label>
                      <DatePicker
                        className="form-control crud-form-control"
                        selected={startDate}
                        placeholderText="Sélectionner une date"
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="top"
                        peekNextMonth
                        minDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        name="date"
                        locale="fr"
                        onChange={(date) => handleDate(date)}
                      />
                      {formState.errors && formState.errors.date && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.date?.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group crud-form-group crud-interm">
                      <label htmlFor="cout">Montant de la dépense</label>
                      <input
                        type="number"
                        placeholder="Montant de la dépense en FCFA"
                        defaultValue={item?.montant}
                        name="montant"
                        className="form-control crud-form-control"
                        min="1"
                        onChange={(e) => onChangeMontant(e)}
                        // {...register(`montant`)}
                      />
                      {/* <span className="customy-label">FCFA</span> */}
                      {formState.errors && formState.errors.montant && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-3">
                          {formState.errors.montant?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="crud-form-interm">
                    <div className="form-group crud-form-group crud-interm">
                      <label htmlFor="a_rembourser">Part Ipm</label>
                      <input
                        type="text"
                        readOnly
                        placeholder="15000"
                        value={formatMontant(rembourser) || ""}
                        // value={prestation.montant_rembourse}
                        name="a_rembourser"
                        className="form-control crud-form-control"
                        id="a_rembourser"
                      />
                      <span className="customy-label">FCFA</span>
                    </div>
                    <div className="form-group crud-form-group crud-interm">
                      <label htmlFor="cout">Part Participant</label>
                      <input
                        type="text"
                        readOnly
                        value={formatMontant(retenu) || ""}
                        // defaultValue={prestation.montant_retenu}
                        // {...props.register('a_retenir')}
                        placeholder="5000"
                        name="a_retenir"
                        className="form-control crud-form-control"
                        id="a_retenir"
                      />
                      <span className="customy-label">FCFA</span>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="edit_assurance">Type Prestation</label>
                    <div className="filter-item-container">
                      {prestation?.prestation?.type_prestation?.id && (
                        <select
                          defaultValue={
                            prestation?.prestation?.type_prestation?.id &&
                            prestation?.prestation?.type_prestation?.id
                          }
                          onChange={onSelectType}
                          name="type_prestation"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                        >
                          <option hidden value="">Choisir Type</option>
                          {prestation_type?.length &&
                            prestation_type.map((item, index) => (
                              <option key={item?.id} value={item.id}>
                                {" "}
                                {item.libelle}{" "}
                              </option>
                            ))}
                        </select>
                      )}

                      {formState.errors && formState.errors.type_prestation && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.type_prestation?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div> */}

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="edit_assurance">Fournisseur</label>
                    <div className="filter-item-container">
                      {item?.prestataire?.id && (
                        <select
                          {...register("prestataire")}
                          defaultValue={
                            (prestation?.prestation?.prestataire?.id &&
                              prestation?.prestation?.prestataire?.id) ||
                            ""
                          }
                          name="prestataire"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                        >
                          <option hidden value="">
                            Choisir un fournisseur
                          </option>
                          {prestatairebyassurance?.length &&
                            prestatairebyassurance.map((item, index) => (
                              <option key={item.id} value={item.id}>
                                {item.raison_sociale
                                  ? item.raison_sociale
                                  : item.prenom + " " + item.nom}
                              </option>
                            ))}
                        </select>
                      )}

                      {formState.errors && formState.errors.prestataire && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.prestataire?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Libelle */}
                {prestation?.libelle ? (
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="cle">Libellé</label>
                      <div className="filter-item-container">
                        <textarea
                          name="libelle"
                          id="libelle"
                          cols="30"
                          rows="3"
                          readOnly
                          defaultValue={prestation?.libelle || ""}
                          {...register("libelle")}
                          className="form-control crud-form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* End libelle */}
                <div className="col-md-6 crud-col-item pricing-cruded">
                  <div className="taux-ipm-container">
                    <div className="taux-ipm-label">Taux IPM : </div>
                    <div className="taux-ipm-value">{item_type.taux}%</div>
                  </div>
                  <div className="plafond-ipm-container">
                    <div className="plafond-ipm-label">Montant Plafond : </div>
                    <div className="plafond-ipm-value">
                      {formatMontant(item_type.montant)} FCFA
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {load && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!load && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModalSoin;
