import React, { useEffect, useContext, useState } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import MontantVersesAuxAssuresTableSkeleton from "./MontantVersesAuxAssuresTableSkeleton";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../store/GlobalState";
import MontantVersesAuxAssuresTableData from "./MontantVersesAuxAssuresTableData";
import { formatMoney, formatMontant } from "../../../utils/utils";
import { getDataByID } from "../../utils/fetchData";
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'

var MontantVersesAuxAssuresTable = ({ auth }) => {
  const [topAdherents, setTopAdherents] = useState([]);
  var [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    if (!auth?.user?.admin_type) {
      getDataByID(`top10montantbyipm`, auth?.user?.id, auth?.token).then(
        (response) => {
          // console.log("response", response);
          setTopAdherents(response?.top10MontantBeneficiaire);
          setIsPageLoading(false);
        }
      );
    } else {
      getDataByID(`top10montantbyipm`, auth?.user?.id, auth?.token).then(
        (response) => {
          setTopAdherents(response?.top10MontantBeneficiaire);
          setIsPageLoading(false);
        }
      );
    }
  }, [auth]);

  const adhFormat = (cell, row) => {
    return row?.item?.prenom + " " + row?.item?.nom;
  };

  const adhIDFormat = (cell, row) => {
    return row?.item?.id;
  };

  const montantFormat = (cell, row) => {
    return formatMontant(cell);
  };

  const presFormat = (cell, row) => {
    return formatMoney(cell);
  };

  const options = {
    sizePerPage: 5,
    clearSearch: true,
    noDataText: "Aucune donnée disponible",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <MontantVersesAuxAssuresTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={topAdherents}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={false}
                    width="50"
                    dataFormat={adhIDFormat}
                  >
                    N°
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataFormat={adhFormat}
                    dataField="montant_adherent"
                  >
                    Assurés
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    dataAlign="right"
                    dataFormat={montantFormat}
                    dataField="montant"
                  >
                    Montant(FCFA)
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MontantVersesAuxAssuresTable;
