import React from "react";
import { NavLink } from "react-router-dom";

var HeaderEasypm = (props) => {
  return (
    <div className="container-fluid empty-fluidy">
      <nav className="navbar navbar-expand-lg navbar-light">
        <a className="navbar-brand" href="#">
          <div className="logo-item">
            <span className="logo-firsty">EASY</span>&nbsp;
            <span className="logo-secondy">PM</span>
          </div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarNav"
        >
          <div
            className={
              "empty-header-assurance" +
              (props.isLandingPage ? "landing-page-header-assurance" : "")
            }
          >
            <ul className="navbar-nav">
              <li className="nav-item active">
                <NavLink
                  className="nav-link li-navlink-easypm-green"
                  to="/easypm"
                >
                  EASY PM
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link  li-navlink-easypm-green"
                  to="/easypm-assurance"
                >
                  EASY PM-ASSURANCE
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {props.isLandingPage && (
          <div className="auth-btn-container">
            <div className="login-btn-container mx-3">
              <NavLink
                to="/connexion"
                className="btn btn-default ldpg-login-btn auth-btns"
              >
                Connexion
              </NavLink>
            </div>
            <div className="login-btn-container mx-3">
              <NavLink
                to="/inscription"
                className="btn btn-default ldpg-register-btn auth-btns"
              >
                Inscription
              </NavLink>
            </div>
          </div>
        )}
      </nav>
      {/* <div className={"empty-header " + (props.isLandingPage ? 'landing-page-header' : '')}>
                <div className="logo-item">
                    <span className="logo-firsty">EASY</span>&nbsp;
                    <span className="logo-secondy">PM</span>
                </div>
                {props.isLandingPage &&
                    <div className="auth-btn-container">
                        <div className="login-btn-container">
                            <NavLink to="/connexion" className="btn btn-default ldpg-login-btn auth-btns">
                                Connexion
                            </NavLink>
                        </div>
                        <div className="login-btn-container">
                            <NavLink to="/inscription" className="btn btn-default ldpg-register-btn auth-btns">
                                Inscription
                            </NavLink>
                        </div>
                    </div>
                }
            </div>  */}
    </div>
  );
};

export default HeaderEasypm;
