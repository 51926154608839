import React, { useContext } from "react";
import { DataContext } from "../../../store/GlobalState";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AssuranceMaladie from "../../../Services/AssuranceMaladie";

const Facturation = () => {
  const { state, dispatch } = useContext(DataContext);
  const { prestataire, auth } = state;
  const [isLoading, setIsLoading] = React.useState(false);
  const { register, handleSubmit, formState, setValue } = useForm();

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const handleCheck = (e) => {
    console.log(e.target.value, e.target.checked);
  };

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    console.log(data);
    var userRequest = AssuranceMaladie.editAssuranceMaladie(
      auth?.user?.id,
      data,
      requestConfig
    );

    await userRequest
      .then((response) => {
        var userInfos = response;
        console.log("user", userInfos);
        setIsLoading(false);
        dispatch({
          type: "AUTH",
          payload: { token: auth.token, user: userInfos },
        });
        toast.success("Paramétres modifiés avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de l'ajout.");
      });
  };

  return (
    <div className="easypm-tabs-item-content-container">
      <div className="easypm-tabs-item">
        <div className="add-family-form-container">
          {auth?.user?.id && (
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="montant_cotisation">
                      Montant de la côtisation
                    </label>
                    <input
                      {...register("montant_cotisation")}
                      defaultValue={auth?.user?.montant_cotisation || ""}
                      type="number"
                      placeholder="Montant de la côtisation"
                      name="montant_cotisation"
                      className="form-control crud-form-control"
                      id="montant_cotisation"
                    />
                    {formState?.errors &&
                      formState?.errors?.montant_cotisation && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState?.errors?.montant_cotisation?.message}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div
                    className="form-group crud-form-group crud-interm"
                    style={{ width: "100%" }}
                  >
                    <label htmlFor="nbj_avant_echance">
                      Nombre de jours avant échéance
                    </label>
                    <input
                      {...register("nbj_avant_echance", {
                        required: "Ce champ est obligatoire",
                      })}
                      defaultValue={auth?.user?.nbj_avant_echance || ""}
                      type="number"
                      placeholder="Nombre de jours avant échéance"
                      name="nbj_avant_echance"
                      className="form-control crud-form-control"
                      id="nbj_avant_echance"
                    />
                    <span className="customy-label">jours</span>
                    {formState?.errors &&
                      formState?.errors?.nbj_avant_echance && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState?.errors?.nbj_avant_echance?.message}
                        </div>
                      )}
                  </div>
                </div>

                <div
                  className="col-md-12 type-presta-container"
                  style={{
                    textAlign: "start",
                    justifyContent: "normal",
                    background: "none",
                  }}
                >
                  <div className="type-presta-input-radio-container">
                    <input
                      type="checkbox"
                      className="easypm-input-radio input-radio-form-control"
                      name="user_type"
                      onChange={handleCheck}
                      // value={auth?.user?.auto_rappel_facturation}
                      defaultChecked={
                        auth?.user?.auto_rappel_facturation ? true : false
                      }
                      {...register("auto_rappel_facturation", {
                        required: "Ce champ est obligatoire",
                      })}
                    />
                    <label
                      className="input-radio-item-label"
                      style={{ fontWeight: 400 }}
                    >
                      Envoyer automatiquement un rappel de paiement
                    </label>
                  </div>
                </div>
                <div className="col-md-3 offset-md-9 crud-form-action-btn-container">
                  {isLoading && (
                    <button
                      className="btn btn-primary crud-submit-btn"
                      disabled
                    >
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      Modifier
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Facturation;
