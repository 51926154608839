const DetailsFacturationAdminTableData = [
    {
        id: 1,
        soins: 'Achat de Médicaments',
        date: '12/12/2020',
        prestataire: 'Pharmacie Grand Yoff',
        beneficiaire: 'Ibra Mbaye',
        cout: '20 000F',
        part_ipm: '20 000F',
        part_salaire: '20 000F'

    },
    {
        id: 2,
        soins: 'Achat de Médicaments',
        date: '12/12/2020',
        prestataire: 'Pharmacie Grand Yoff',
        beneficiaire: 'Ibra Mbaye',
        cout: '20 000F',
        part_ipm: '20 000F',
        part_salaire: '20 000F'
    },
    {
        id: 3,
        soins: 'Achat de Médicaments',
        date: '',
        prestataire: 'Pharmacie Grand Yoff',
        beneficiaire: 'Ibra Mbaye',
        cout: '20 000F',
        part_ipm: '20 000F',
        part_salaire: '20 000F'
    },
    {
        id: 4,
        soins: 'Achat de Médicaments',
        date: '',
        prestataire: 'Pharmacie Grand Yoff',
        beneficiaire: 'Ibra Mbaye',
        cout: '20 000F',
        part_ipm: '20 000F',
        part_salaire: '20 000F'
    },
    {
        id: 5,
        soins: 'Achat de Médicaments',
        date: '',
        prestataire: 'Pharmacie Grand Yoff',
        beneficiaire: 'Ibra Mbaye',
        cout: '20 000F',
        part_ipm: '20 000F',
        part_salaire: '20 000F'
    }
];

export default DetailsFacturationAdminTableData;