import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Beneficiaire from "../../../Services/Beneficiaire";
// import entreprise from '../../../Services/Entreprise'
import { DataContext } from "../../../store/GlobalState";
import { toast } from "react-toastify";
import { getDataByID } from "../../../utils/fetchData";

function UseCreateBeneficiaireForm() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [beneficiaireData, setBeneficiaireData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  var beneficiaire = JSON.parse(sessionStorage.getItem("benefInfos"));
  var userType = sessionStorage.getItem("userType");

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(),
        nom: yup.string(),
        prenom: yup.string(),
        employeur: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value)),
        // password: yup.string().required('Le mot de passe est requis'),
        telephone: yup
          .string()
          .matches(
            /^((\+)221|0)[1-9](\d{2}){4}$/,
            "Veuillez vérifier le format du numéro de téléphone"
          ),
        adresse: yup.string(),
        sexe: yup.string(),
        situation_matrimoniale: yup.string(),
        nationnalite: yup.string(),
        date_de_naissance: yup.string(),
        date_debut: yup.string(),
        date_fin: yup.string(),
        matricule: yup.string(),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitEditForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    if (!formValues.email) {
      delete formValues.email;
    }
    if (!formValues.assurance) {
      delete formValues.assurance;
    }
    if (!formValues.id) {
      delete formValues.id;
    }

    if (!formValues.nom) {
      delete formValues.nom;
    }

    if (!formValues.prenom) {
      delete formValues.prenom;
    }

    if (!formValues.telephone) {
      delete formValues.telephone;
    }

    if (!formValues.sexe) {
      delete formValues.sexe;
    }

    if (!formValues.date_de_naissance) {
      delete formValues.date_de_naissance;
    }

    if (!formValues.date_debut) {
      delete formValues.date_debut;
    }

    if (!formValues.date_fin) {
      delete formValues.date_fin;
    }

    if (!formValues.adresse) {
      delete formValues.adresse;
    }

    if (!formValues.situation_matrimoniale) {
      delete formValues.situation_matrimoniale;
    }

    if (!formValues.employeur) {
      delete formValues.employeur;
    }

    if (!formValues.nationnalite) {
      delete formValues.nationnalite;
    }

    if (!formValues.num_contrat) {
      delete formValues.num_contrat;
    }

    if (!formValues.groupe_sanguin) {
      delete formValues.groupe_sanguin;
    }

    formValues["user_type"] = "beneficiaire";

    console.log("data", formValues);

    var beneRequest = Beneficiaire.editBeneficiaire(
      beneficiaire.id,
      formValues,
      requestConfig
    );
    await beneRequest
      .then(async (response) => {
        // await setIsSuccessfullySubmitted(true)
        var beneficiaireInfos = response;
        setBeneficiaireData(beneficiaireInfos);
        console.log(beneficiaireInfos);

        //dispatch({ type: 'ADD_BENEFICIAIRE', payload: [...beneficiaires, beneficiaireInfos]})
        if (userType !== "entreprise") {
          if (sessionStorage.getItem("userType") === "admin_ipm") {
            getDataByID(
              "beneficiairebyassurance",
              auth?.user?.parent,
              auth.token
            )
              .then((res) => {
                dispatch({
                  type: "ADD_BENEFICIAIRE",
                  payload: res.sort((a, b) => b?.id - a?.id),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            getDataByID("beneficiairebyassurance", auth?.user?.id, auth.token)
              .then((res) => {
                dispatch({
                  type: "ADD_BENEFICIAIRE",
                  payload: res.sort((a, b) => b?.id - a?.id),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          }
          getDataByID("beneficiairebyemploye", response.employeur, auth.token)
            .then((res) => {
              console.log("res benef", res);
              dispatch({
                type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
                payload: res.sort((a, b) => b?.id - a?.id),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          if (!auth?.user?.admin_type) {
            getDataByID("beneficiairebyemploye", auth?.user?.id, auth.token)
              .then((res) => {
                console.log("res benef", res);
                dispatch({
                  type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
                  payload: res.sort((a, b) => b?.id - a?.id),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            getDataByID("beneficiairebyemploye", auth?.user?.parent, auth.token)
              .then((res) => {
                console.log("res benef", res);
                dispatch({
                  type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
                  payload: res.sort((a, b) => b?.id - a?.id),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          }
        }

        await e.target.reset();
        await window.sessionStorage.removeItem("benefInfos");
        await setIsSuccessfullySubmitted(false);
        window.$(".edit-beneficiaire-modal").modal("hide");
        await toast.success("Ce participant a été modifié avec succès.");
        reset();
      })
      .catch((error) => {
        console.log(error);
        setIsFormRequestError(true);
        setIsSuccessfullySubmitted(false);
        if (
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          toast.error(
            "Oupsss! L'adresse email est déjà associée à un compte. Veuillez utiliser une autre adresse email.",
            {
              position: "top-right",
              autoClose: 10000,
            }
          );
        } else {
          toast.error(
            "Oupss! Une erreur est survenue lors de la modification."
          );
        }
      });
  };

  return {
    reset,
    register,
    errors,
    formState,
    beneficiaireData,
    setBeneficiaireData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    setValue,
    onSubmit: handleSubmit(onSubmitEditForm),
  };
}

export default UseCreateBeneficiaireForm;
