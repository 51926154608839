import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import EntreprisesSkeleton from "./EntreprisesSkeleton";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../store/GlobalState";
import UseEditEntrepriseForm from "../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm";
import CRUDEntrepriseModal from "./../../Modals/CRUDEntrepriseModal";
import ShowEntrepriseModal from "../../Modals/ShowEntrepriseModal";
import RegenerePwdModal from "../../Modals/RegenerePwdModal";
import ValidEntrepriseModal from "../../Modals/ValidEntrepriseModal";
// import AddMessageModal from '../../Modals/AddMessageModal';

var Entreprises = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { auth, entreprises_by_ipm } = state;

  const userID = sessionStorage.getItem("userID");
  const [entrepriseInfos, setEntrepriseInfos] = React.useState();
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
    setValue,
    reset,
    unregister,
  } = UseEditEntrepriseForm();

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 5000);
  }, []);

  useEffect(() => {
    // if (auth?.token && auth?.user?.id) {
    //   setIsPageLoading(false);
    // } else {
    //   setIsPageLoading(true);
    // }

    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [auth, formState]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <NavLink
        to={`/ipm/beneficiaires-entreprise/${row.id}`}
        className="nameFormatter nameFormation-noLink"
        onClick={() => getItem(row)}
      >
        <span className="name"> {cell} </span>
      </NavLink>
    );
  };

  const nameFilter = (cell, row) => {
    //console.log('row', row)
    return cell;
  };

  // const agreementFormatter = (cell) => {
  //     if(cell === true){
  //         return(
  //             <span className="badge badge-success easypm-badge-success"> OUI </span>
  //         )
  //     }else{
  //         return(
  //             <span className="badge badge-danger easypm-badge-danger"> NON </span>
  //         )
  //     }
  // }

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button
          data-toggle="modal"
          data-target=".show-entreprise-modal"
          className="action-btn show-btn"
          onClick={() => onGetEntrepriseInfos(row, "show")}
          title="Details"
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
          />
        </button>
        <button
          onClick={() => getItem(row)}
          className="action-btn edit-btn"
          data-toggle="modal"
          data-target=".edit-entreprise-modal"
          title="Modification"
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
        <button
          className="action-btn show-btn"
          title={row?.is_archived ? "Désarchiver" : "Archiver"}
        >
          {!row.is_archived ? (
            <IconlyPack.Delete
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-show-icon"
              data-toggle="modal"
              data-target=".valid-entreprise-modal"
              onClick={() => getItem(row)}
            />
          ) : (
            <IconlyPack.CloseSquare
              set="light"
              primaryColor="#dc3545"
              className="action-btn-icon action-show-icon"
              data-toggle="modal"
              data-target=".valid-entreprise-modal"
              onClick={() => getItem(row)}
            />
          )}
        </button>
        <button
          className="action-btn edit-btn"
          onClick={() => getItem(row)}
          data-toggle="modal"
          data-target=".regenere-pwd-modal"
          title="Renvoi de mot de passe"
        >
          <IconlyPack.Password
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
        <button
          onClick={() => getItem(row)}
          className="action-btn add-btn"
          data-toggle="modal"
          data-target=".send-message-modal"
          title="Envoi de message"
        >
          <IconlyPack.Message
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-add-icon"
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    console.log("item", item);
    sessionStorage.setItem("entrepriseForEmployees", item);
    dispatch({
      type: "EDIT_ENTREPRISE",
      payload: item,
    });
  };

  // const otherDataFormatter = (cell, row) => {
  //     return(
  //         <NavLink to={`/ipm/profil-beneficiaire/${row.id}`} className="nameFormatter">
  //             <span className="itemy-value"> { cell } </span>
  //         </NavLink>
  //     )
  // }

  const onGetEntrepriseInfos = (item, action) => {
    if (action === "edit") {
      sessionStorage.setItem("etsfInfos", JSON.stringify(item));
    }
    if (action === "show") {
      setEntrepriseInfos(item);
    }
  };

  var handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        {/* <div className="form-filter m-t-50">
          <div className="table-filter-row beneficiaire-filter">
            <div className="filter-label-container">
              <span className="filter-label"> Filtre </span>
            </div>
            <div className="filter-item-container">
              <input
                className="form-control filter-input-item"
                name="fullname"
                placeholder="Entrer un nom"
              />
            </div>
          </div>
        </div> */}

        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <EntreprisesSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={entreprises_by_ipm}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="250"
                    dataField="raison_sociale"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Raison Sociale
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="200"
                    dataField="ninea"
                  >
                    NINEA
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="300"
                    dataField="email"
                  >
                    Email
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="telephone"
                  >
                    Téléphone
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="200"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade easypm-modal edit-entreprise-modal"
        id="AddModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
      >
        <CRUDEntrepriseModal
          userActionType="edit"
          fromIpmID={userID}
          register={register}
          onSubmit={onSubmit}
          formState={formState}
          isSuccessfullySubmitted={isSuccessfullySubmitted}
          setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
          handleReset={handleReset}
          setValue={setValue}
          reset={reset}
          unregister={unregister}
        />
      </div>
      {/* Modal archivage*/}
      <div
        className="modal fade easypm-modal valid-entreprise-modal"
        id="CsvModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="CsvModalItemLabel"
        aria-hidden="true"
      >
        <ValidEntrepriseModal />
      </div>
      <div
        className="modal fade easypm-modal show-entreprise-modal"
        id="ShowModalItem"
        tabIndex="-1"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
      >
        <ShowEntrepriseModal entrepriseInfos={entrepriseInfos} />
      </div>
      {/* Modal regeneration de mot de passe*/}
      <div
        className="modal fade easypm-modal regenere-pwd-modal"
        id="CsvModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="PwpModalItemLabel"
        aria-hidden="true"
      >
        <RegenerePwdModal />
      </div>
    </div>
  );
};

export default Entreprises;
