import React, { useContext } from 'react'
import Header from '../../../Navs/Header'
import NavBarVerticalV2 from '../../navbarVerticalV2/navbarVerticalV2'
import FicheValideTable from './FicheValideTable'
import * as IconlyPack from 'react-iconly'
import { DataContext } from '../../../store/GlobalState'
import { getDataByID } from '../../../utils/fetchData'

const FicheDeSoinValide = () => {
  const { state, dispatch } = useContext(DataContext)

  const { auth } = state
  React.useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (auth.user?.user_type === 'prestataire') {
        if (!auth?.user?.admin_type) {
          getDataByID(
            'fichedesoinvalidatebyprestataire',
            auth?.user?.id,
            auth?.token,
          ).then((res) => {
            dispatch({
              type: 'LISTE_FICHE_VALID_BY_PRESTATAIRE',
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })

          getDataByID(
            'notificationbyprestataire',
            auth?.user?.id,
            auth?.token,
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: 'LIST_NOTIFICATION',
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })
        } else {
          getDataByID(
            'fichedesoinvalidatebyprestataire',
            auth?.user?.parent,
            auth?.token,
          ).then((res) => {
            dispatch({
              type: 'LISTE_FICHE_VALID_BY_PRESTATAIRE',
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })

          getDataByID(
            'notificationbyprestataire',
            auth?.user?.parent,
            auth?.token,
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: 'LIST_NOTIFICATION',
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })
        }
      }
    }
  }, [auth])
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={`prestataire`} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Soins et Médicaments</h1>
                  </div>

                  {/* <div
                                        className="adding-item-container linkable"
                                        data-toggle="modal"
                                        data-target=".alim-soin-modal"
                                    >
                                        <span className="icon-container">
                                            <IconlyPack.Plus set="light" primaryColor="gray" />
                                        </span>
                                        <span className="adding-item-label">
                                            Ajouter une facture
                                        </span>
                                    </div> */}
                </div>
                <div className="parent-table-container">
                  <FicheValideTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FicheDeSoinValide
