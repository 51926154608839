import React, { Component } from "react";
import "./navbarMobileV2.css";
// import { NavLink } from 'react-router-dom';
// import * as IconlyPack from 'react-iconly';
import IpmMobileNavBarContent from "./IpmMobileNavBarContent";
import EntrepriseMobileNavBarContent from "./EntrepriseMobileNavBarContent";
import PrestataireMobileNavBarContent from "./PrestataireMobileNavBarContent";
import AdminMobileNavBarContent from "./AdminMobileNavBarContent";
import NavbarAssureurMobile from "../Assurance/DashboardAssureur/NavbarAssureur/NavbarAssureurMobile";

export default class navbarMobileV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: window.sessionStorage.getItem("userType"),
      isSuperAdmin: window.sessionStorage.getItem("isSuperAdmin"),
    };
  }
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white" id="menu">
        <button
          className="navbar-toggler ml-sm-3"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse bg-theme"
          id="navbarNavDropdown"
        >
          <div className="py-4">
            <div className="mobile-menu-container">
              <div className="mobile-menu-content">
                <ul className="mobile-menu-ul">
                  {(this.state.userType === "ipm" ||
                    this.state.userType === "assurance_sante") && (
                    <IpmMobileNavBarContent />
                  )}
                  {this.state.userType === "entreprise" && (
                    <EntrepriseMobileNavBarContent />
                  )}
                  {this.state.userType === "prestataire" && (
                    <PrestataireMobileNavBarContent />
                  )}

                  {(this.state.userType === "super_ipm" ||
                    this.state.isSuperAdmin) && <AdminMobileNavBarContent />}
                  {this.state.userType === "assureur" && (
                    <NavbarAssureurMobile />
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
