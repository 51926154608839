import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FicheDeSoin from "../../../Services/FicheDeSoin";
import { DataContext } from "../../../store/GlobalState";
import { toast } from "react-toastify";
import { getDataByID } from "../../../utils/fetchData";

function UseFicheCreateForm() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [entrepriseData, setEntrepriseData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        beneficiaire: yup.string().required("Le participant est requis"),
        prestataire: yup.string().required("Le prestataire est requis"),
        date: yup.string().required("La date est requise"),
        ayant_droit: yup.string(),
        etat: yup.string(),
        // ordonnance_file: yup.string(),
        showOrdonnance: yup.boolean(),
        ordonnance_file: yup.mixed().when("showOrdonnance", {
          is: true,
          then: yup.mixed().required("L'ordonnance est requise"),
        }),
      }),
    []
  );

  const {
    unregister,
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    let fd = new FormData();

    fd.append("beneficiaire", formValues.beneficiaire);
    fd.append("prestataire", formValues.prestataire);
    if (formValues?.ayant_droit) {
      fd.append("ayant_droit", formValues.ayant_droit);
    }

    if (formValues?.ordonnance_file) {
      fd.append("ordonnance_file", formValues.ordonnance_file[0]);
    }
    fd.append("date", formValues.date);

    console.log("data", formValues);
    var fichedesoinRequest = FicheDeSoin.postCreateFicheDeSoin(
      fd,
      requestConfig
    );
    await fichedesoinRequest
      .then((response) => {
        console.log("response", response);
        if (response?.status === "failure") {
          toast.error(response?.message);
          e.target.reset();
          window.$(".add-fiche-modal").modal("hide");
        } else {
          var fichedesoinInfos = response;
          setEntrepriseData(fichedesoinInfos);
          if (auth?.user?.user_type === "admin_ipm") {
            getDataByID("fichedesoinbyipm", auth?.user?.parent, auth.token)
              .then((res) => {
                dispatch({
                  type: "ADD_FICHE_DE_SOIN_BY_IPM",
                  payload: res.sort((a, b) => a.id - b.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            getDataByID("fichedesoinbyipm", auth?.user?.id, auth.token)
              .then((res) => {
                dispatch({
                  type: "ADD_FICHE_DE_SOIN_BY_IPM",
                  payload: res.sort((a, b) => a.id - b.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          }

          e.target.reset();
          window.$(".add-fiche-modal").modal("hide");
          toast.success("Fiche de soins ajoutée avec succès.");
        }
        reset();
        setIsSuccess(true);
        setIsSuccessfullySubmitted(false);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error?.response);
        setIsFormRequestError(true);
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          if (error?.response?.status === 500) {
            toast.error(
              "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
            );
          } else if (error?.response?.status === 400) {
            toast.error(
              "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
            );
          } else {
            toast.error(
              "Une erreur est survenue, veuillez vérifier votre connexion internet !"
            );
          }
        }

        setIsSuccessfullySubmitted(false);
      });
  };

  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    setValue,
    onSubmit: handleSubmit(onSubmitCreateForm),
    reset,
    isSuccess,
    unregister,
  };
}

export default UseFicheCreateForm;
