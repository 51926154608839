import React from "react";
import Skeleton from "react-loading-skeleton";

function FicheSkeleton() {
  return (
    <div className="crud-form">
      <div className="row crud-row">
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="name">Prénom Adhérent: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="name">Nom Adhérent: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="specialite">
              Date de naissance de l'adhérent:{" "}
            </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="ninea">
              Situation Matriomiale de l'adhérent:{" "}
            </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="email">Email de l'adhérent: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="adresse">Adresse de l'adhérent: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="telephone">Téléphone de l'adhérent: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="first_name">Date de début de l'adhérent: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="first_name">Date de fin de l'adhérent: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="name">Nom prestataire: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="specialite">Spécialité du prestataire: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="email">Email du prestataire: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="adresse">Adresse du prestataire: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="telephone">Téléphone du prestataire: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="first_name">Agréement du prestataire: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="first_name">Date de début du prestataire: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="first_name">Date de fin du prestataire: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FicheSkeleton;
