import React from "react";
import ImgCheck from "../../../assets/images/icons/check.png";

var RejeterReservationModal = () => {
  return (
    <>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <img src={ImgCheck} alt="Icon check confirmation" />
            </div>
            <div className="pt-4">
              <p className="text-confirm-reservation">Réservation rejetée</p>
            </div>
            <div className="py-4">
              <p className="para-text-confirm-modal">
                Vous avez rejeté une réservation !
              </p>
            </div>
            <div className="row justify-content-center py-4">
              <button
                className="btn btn-confirm-reservation-modal"
                data-dismiss="modal"
              >
                ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RejeterReservationModal;
