import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
import './GestionAide.css'
import AjouterQuestion from "./AjouterQuestion";


var GestionAide = () => {
 

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2  />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                    Aide
                    </h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target="#AjouterQuestion"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                    Ajouter une question
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="py-5">
                      <div className="faq-beneficiaire mb-3">
                          <div>
                              <button className="detail-aide btn">Comment envoyer  un remboursement</button>
                              <div>
                                    <p className="date-publication-aide pl-3">Le 02-03-2020</p>
                              </div>
                          </div>
                          <div className="pl-2">
                              <p className="nombre-vus">254</p>
                              <div>
                                    <p className="text-info-utile">Consultations</p>
                              </div>
                          </div>
                      </div>
                      <div className="faq-beneficiaire mb-3">
                          <div>
                              <button className="detail-aide btn">Comment envoyer  un remboursement</button>
                              <div>
                                    <p className="date-publication-aide pl-3">Le 02-03-2020</p>
                              </div>
                          </div>
                          <div className="pl-2">
                              <p className="nombre-vus">254</p>
                              <div>
                                    <p className="text-info-utile">Consultations</p>
                              </div>
                          </div>
                      </div>
                      <div className="faq-beneficiaire mb-3">
                          <div>
                              <button className="detail-aide btn">Comment envoyer  un remboursement</button>
                              <div>
                                    <p className="date-publication-aide pl-3">Le 02-03-2020</p>
                              </div>
                          </div>
                          <div className="pl-2">
                              <p className="nombre-vus">254</p>
                              <div>
                                    <p className="text-info-utile">Consultations</p>
                              </div>
                          </div>
                      </div>
                      <div className="faq-beneficiaire mb-3">
                          <div>
                              <button className="detail-aide btn">Comment envoyer  un remboursement</button>
                              <div>
                                    <p className="date-publication-aide pl-3">Le 02-03-2020</p>
                              </div>
                          </div>
                          <div className="pl-2">
                              <p className="nombre-vus">254</p>
                              <div>
                                    <p className="text-info-utile">Consultations</p>
                              </div>
                          </div>
                      </div>
                      <div className="faq-beneficiaire mb-3">
                          <div>
                              <button className="detail-aide btn">Comment envoyer  un remboursement</button>
                              <div>
                                    <p className="date-publication-aide pl-3">Le 02-03-2020</p>
                              </div>
                          </div>
                          <div className="pl-2">
                              <p className="nombre-vus">254</p>
                              <div>
                                    <p className="text-info-utile">Consultations</p>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="modal fade" id="AjouterQuestion" tabindex="-1" aria-labelledby="AjouterQuestionLabel" aria-hidden="true">
                    <AjouterQuestion />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionAide;
