import React, { useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import DetailsFactureData from "./DetailsFactureData";
import DetailsFactureSkeleton from "./DetailsFactureSkeleton";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";

var DetailsFactureTable = (props) => {
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  // eslint-disable-next-line
  var [details, setDetails] = React.useState(DetailsFactureData);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 5000);
    // console.log(soins)
  }, [details]);

  // isSoinByBeneficiaire

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const beneficiaireFormatter = (cell, row) => {
    return (
      <NavLink to={`#`} className={"nameFormatter "}>
        <span className="name"> {cell} </span> <br />
        <span className="entreprise"> {row.entreprise} </span>
      </NavLink>
    );
  };

  // const actionFormatter = (cell, row) => {
  //     return(
  //         <div className="table-actions-btn-container">
  //             <button className="action-btn edit-btn">
  //                 <IconlyPack.Edit set="light" primaryColor="#C8C8C8" className="action-btn-icon action-edit-icon"/>
  //             </button>
  //         </div>
  //     )
  // }

  const currencyFormatter = (cell) => {
    const formatConfig = {
      style: "currency",
      currency: "XOF",
      minimumFractionDigits: 2,
      currencyDisplay: "symbol",
      currencySign: "accounting",
    };

    // setup formatters
    const xofNumberFormatter = new Intl.NumberFormat("sn-SN", formatConfig);
    return xofNumberFormatter.format(cell);
  };

  const selectRowProp = {
    mode: "checkbox",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="form-filter m-t-50">
          <div className="table-filter-row">
            <div className="filter-label-container">
              <span className="filter-label"> Filtre </span>
            </div>

            <div className="filter-item-container">
              <select
                name="mois"
                className="form-select form-select-line-chart ml-md-auto filter-select-item"
                aria-label="Default select example"
              >
                <option hidden value="">
                  --Mois--
                </option>
                <option value="Janvier">Janvier</option>
                <option value="Février">Février</option>
                <option value="Mars">Mars</option>
                <option value="Avril">Avril</option>
              </select>
            </div>
            <div className="filter-item-container">
              <select
                name="annee"
                className="form-select form-select-line-chart ml-md-auto filter-select-item"
                aria-label="Default select example"
              >
                <option hidden value="">
                  --Année--
                </option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
              </select>
            </div>
          </div>

          <div className="export-btn-container bill-generation-btn-container">
            <button className="btn btn-primary export-btn bill-generation-btn">
              <IconlyPack.Document set="light" primaryColor="#FFFFFF" />
              &nbsp;
              <span className="bill-generation-btn-label">
                Générer une facture
              </span>
            </button>
          </div>
        </div>

        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <DetailsFactureSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={details}
                  selectRow={selectRowProp}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={true}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="libelle_soin"
                    width="300"
                  >
                    Soins
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="300"
                    dataField="prestataire"
                  >
                    Prestataires
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="300"
                    dataField="beneficiaire"
                    dataFormat={(cell, row) => beneficiaireFormatter(cell, row)}
                  >
                    Adhérents
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    dataField="cout"
                    dataFormat={(cell) => currencyFormatter(cell)}
                  >
                    Coût
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    dataField="montant_rembourse"
                    dataFormat={(cell) => currencyFormatter(cell)}
                  >
                    Montant remboursé
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    dataField="montant_retenu"
                    dataFormat={(cell) => currencyFormatter(cell)}
                  >
                    Montant retenu
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    dataField="date"
                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    dataField="fournisseur"
                  >
                    Fournisseurs
                  </TableHeaderColumn>
                  {/* <TableHeaderColumn 
                                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D'}}
                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', padding: 15, }} width="100" dataField='id' dataFormat={(cell, row) => actionFormatter(cell, row)}>Action</TableHeaderColumn> */}
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsFactureTable;
