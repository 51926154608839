import React, { useEffect, useContext } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import * as IconlyPack from 'react-iconly';
import DefaultAvatar from "../../../../images/others/default-profile-avatar.png";
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getDataByID, putData } from "../../../utils/fetchData";
import moment from "moment";
import DetailAyantDroitSkeleton from "./DetailAyantDroitSkeleton";
import baseUrl from "../../../../Http/backend-base-url";
import Skeleton from "react-loading-skeleton";

function DetailAyantDroit() {
  const [userType, setUserType] = React.useState();
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [ayantdroit, setAyantDroit] = React.useState({});

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  // console.log('props', location)
  useEffect(() => {
    setUserType(window.sessionStorage.getItem("userType"));

    if (params?.id && auth?.token) {
      console.log("params", params);
      getDataByID("ayantdroit", params?.id, auth?.token)
        .then((res) => {
          console.log("res", res);
          setAyantDroit(res);
          setIsPageLoading(false);
        })
        .catch((error) => {
          console.log("error", error, error?.response);
          if (error?.response === undefined) {
            toast.error("Cet ayant droit n'existe plus dans la base");

            setTimeout(() => {
              handleValid("rejeter", "error");
            }, 3000);
          }
        });
    }
  }, [params?.id, auth?.token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userType !== "super_ipm") {
      setIsSuccessfullySubmitted(true);
      putData(
        "ayantdroit/" + params?.id + "/",
        { is_active: true },
        auth?.token
      )
        .then((res) => {
          console.log("resvvv", res);

          if (window.sessionStorage.getItem("notifId")) {
            handleValid("valid");
          }
          toast.success("Ayant droit validé.");
          setIsSuccessfullySubmitted(false);
          
          if (auth?.user?.user_type === "assureur") {
            history.push(
              `/assurance/details-assures/${ayantdroit.beneficiaire}`
            );
          } else {
            history.push(`/ipm/profil-beneficiaire/${ayantdroit.beneficiaire}`);
          }
        })
        .catch((e) => {
          setIsSuccessfullySubmitted(false);
          toast.error("Oupss! Une erreur est survenue lors de la validation.");
        });
    } else {
      if (location?.state?.notification) {
        handleValid("rejet");
      }
    }
  };

  const handleValid = (item, error) => {
    let id = parseInt(window.sessionStorage.getItem("notifId"));
    let data = {};
    if (item == "valid") {
      data = { valid: true };
    } else {
      data = { is_archived: true };
    }
    putData("notification/" + id + "/", data, auth?.token).then((res) => {
      if (auth?.user?.admin_type) {
        getDataByID("notificationbyipm", auth?.user?.parent, auth?.token).then(
          (resp) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );
      } else {
        getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
          (resp) => {
            // console.log('notif', resp)
            window.sessionStorage.removeItem("notifId");
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );
      }

      if (item === "rejeter") {
        console.log("beneficiaire", ayantdroit);
        if (error !== "error") {
          toast.success("Ayant droit non validé.");
          if (auth?.user?.user_type === "assureur") {
            history.push(
              `/assurance/details-assures/${ayantdroit.beneficiaire}`
            );
          } else {
            history.push(`/ipm/profil-beneficiaire/${ayantdroit.beneficiaire}`);
          }
        } else {
          if (auth?.user?.user_type === "assureur") {
            history.push(
              `/assurance/assures`
            );
          } else {
            history.push(`/ipm/gestion-des-beneficiaires`);
          }
        }
        
        
      }
    });
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div
            className={
              userType === "assureur"
                ? "nav-container nav-assurance-color"
                : "nav-container"
            }
          >
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 ben-profile-container">
            <div className="page-body-container mt-5">
              <div className="row beneficiaire-profile-row mb-3">
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 profile-infos-perso-container profile-item">
                  <div className="profile-infos-ben">
                    <h2 className="section-light-title">
                      <div className="light-title">
                        {" "}
                        {userType === "super_ipm"
                          ? "Détail Prestataire"
                          : "Demande de validation d'un ayant droit"}
                      </div>
                    </h2>
                    {isPageLoading && <DetailAyantDroitSkeleton />}
                    {!isPageLoading && (
                      <>
                        <div className="profile-avatar-container">
                          <img
                            // src={DefaultAvatar}
                            src={
                              !ayantdroit?.avatar ||
                              ayantdroit?.avatar?.includes("default.png")
                                ? DefaultAvatar
                                : baseUrl + ayantdroit?.avatar
                            }
                            className="profile-avatar"
                            // alt="Profile"
                          />
                        </div>

                        <div className="ben-others-infos-container">
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Genre</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{ayantdroit?.sexe}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Statut</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <strong>{ayantdroit?.statut}</strong>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Nom</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{ayantdroit?.nom}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Prénom</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{ayantdroit?.prenom}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Date de naissance</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>
                                {moment(ayantdroit?.date_naissance).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </div>
                          </div>

                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Date de création</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>
                                {ayantdroit?.created_at
                                  ? moment(ayantdroit?.created_at).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "Non renseignée"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 profile-infos-family-container profile-item">
                  <div className="wifes-infos-container">
                    <div className="page-first-row-container">
                      <div className="page-title-container">
                        <h1 className="page-title">
                          Certificat de{" "}
                          {ayantdroit?.statut === "enfant"
                            ? "naissance"
                            : "mariage"}
                        </h1>
                      </div>
                    </div>
                    <div
                      className="epouses-table-container"
                      style={{ height: "500px" }}
                    >
                      {!isPageLoading && ayantdroit?.certificat ? (
                        <iframe
                          className="responsive-iframe w-100"
                          src={baseUrl + ayantdroit?.certificat}
                          style={{ height: "100%" }}
                          title="Certificat"
                        ></iframe>
                      ) : !isPageLoading ? (
                        <div className="gfa-rowy" style={{ marginTop: "25%" }}>
                          <div className="alert alert-warning gfa-warning">
                            Certificat de{" "}
                            {ayantdroit?.statut === "enfant"
                              ? "naissance"
                              : "mariage"}{" "}
                            non disponible
                          </div>
                        </div>
                      ) : (
                        <Skeleton width={`100%`} height={`100%`} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className={`btn btn-default ${
                      userType === "assureur"
                        ? "cancelleb-btn-assurance"
                        : "cancelleb-btn"
                    }`}
                    onClick={() => handleValid("rejeter")}
                  >
                    {userType === "super_ipm" ? "Retour" : "Rejeter"}
                  </button>
                  {isSuccessfullySubmitted && (
                    <button
                      className={`btn ${
                        userType === "assureur"
                          ? "crud-submit-btn-assurance"
                          : "crud-submit-btn"
                      }`}
                    >
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button
                      className={`btn ${
                        userType === "assureur"
                          ? "crud-submit-btn-assurance"
                          : "crud-submit-btn"
                      }`}
                      onClick={(e) => handleSubmit(e)}
                    >
                      Accepter
                    </button>
                  )}
                </div>
              </div>

              {/* teste */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailAyantDroit;
