import { service } from './Endpoints'
import axios from 'axios'

export default {
    async getListServices(config) {
        const response = await axios.get(service.onGetOrPostService(), config);
        // console.log(response)
        return response.data;
    },
    async postCreateService(data, config) {
        const response = await axios.post(service.onGetOrPostService(), data, config);
        return response.data;
    },
    async putService(id, data, config) {
        const response = await axios.put(service.onEditService(id), data, config);
        return response.data;
    },
    async deleteService(id, config) {
        const response = await axios.delete(service.onDeleteService(id), config);
        return response;
    },

}