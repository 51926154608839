import React, { useEffect } from "react";
import { Pie } from "react-chartjs-2";
// import "chartjs-plugin-datalabels";
import moment from "moment";
import "moment/locale/fr";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Skeleton from "react-loading-skeleton";
import { GroupSharp } from "@material-ui/icons";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import "./GestionRapportsAssureur.css";
import TopAssuresTable from "./TopAssuresTable";
import TopOppositionTable from "./TopOppositionTable";
import { DataContext } from "../../../store/GlobalState";
// import { forEach } from 'lodash'
// import "react-datepicker/dist/react-datepicker.css";

const GestionRapportsAssureur = () => {
  const [count, setCount] = React.useState([]);
  const [stat, setStat] = React.useState([]);
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const [labels, setLabels] = React.useState([]);
  const [type, setType] = React.useState("week");
  const { state } = React.useContext(DataContext);
  const {
    ficheDeSoinsbyIpm,
    auth,
    rapport,
    assureur_rapports,
    assureur_ficheSoins,
  } = state;
  const [date, setDate] = React.useState(new Date());
  const [month, setMonth] = React.useState(new Date());
  const [year, setYear] = React.useState(new Date());
  let data = {
    labels: labels,
    datasets: [
      {
        label: "Rapports",
        data: count,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 255, 255, 0.1)",
          "rgb(255, 153, 153, 0.1)",
          "rgb(128, 191, 255, 0.1)",
          "rgb(255, 255, 128, 0.1)",
          "rgb(255, 128, 255, 0.1)",
          "rgb(128, 255, 255, 0.1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgb(2, 166, 156, 1)",
          "rgb(255, 153, 153, 1)",
          "rgb(128, 191, 255, 1)",
          "rgb(255, 255, 128, 1)",
          "rgb(255, 128, 255, 1)",
          "rgb(128, 255, 255, 1)",
        ],

        borderWidth: 1,
      },
    ],
  };

  React.useEffect(() => {
    if (auth?.token && auth?.user?.id && assureur_rapports?.data) {
      // setIsPageLoading(false);
    } else {
      setIsPageLoading(true);
    }
  }, [auth, assureur_rapports?.data]);

  const getISOWeekInMonth = (date) => {
    // Copy date so don't affect original
    var d = new Date(+date);
    // console.log('date', d, date)
    if (isNaN(d)) return;
    // Move to previous Monday
    d.setDate(d.getDate() - d.getDay() + 1);
    // Week number is ceil date/7
    return { month: +d.getMonth() + 1, week: Math.ceil(d.getDate() / 7) };
  };

  React.useEffect(() => {
    let test = [];
    if (type === "month" && count.length) {
      for (let i = 0; i < count?.length; i++) {
        test[i] = `Semaine ${i + 1}`;
      }
      // console.log('test',count,  test)
      setLabels(test);
    }
  }, [type, count]);

  React.useEffect(() => {
    if (type === "week") {
      setLabels([
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
      ]);
      //   setLabel("Fiche de Soin");
      setStat(
        assureur_ficheSoins?.data?.filter((st) =>
          moment(st?.date).locale("fr").isSame(date, "week")
        )
      );
      //   console.log(assureur_ficheSoins?.data?.filter((st) => moment(st?.date).locale("fr").isSame(date, "week")))
    } else if (type === "month") {
      // setLabels(['Semaine 1', 'Semaine 2', 'Semaine 3', 'Semaine 4'])
      setStat(
        assureur_ficheSoins?.data?.filter((st) =>
          moment(st?.date).locale("fr").isSame(month, "month")
        )
      );
    } else {
      // setLabels('Cette année', 'autres')
      setLabels([
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ]);
      setStat(
        assureur_ficheSoins?.data?.filter((st) =>
          moment(st?.date).locale("fr").isSame(year, "year")
        )
      );
    }

    // if (window.sessionStorage.getItem("userType") === "entreprise") {

    // }
  }, [assureur_ficheSoins?.data, type, date, month, year]);

  React.useEffect(() => {
    if (type === "week") {
      let days = [0, 0, 0, 0, 0, 0, 0];
      if (stat?.length) {
        // stat.forEach((element) => {
        //   days[moment(element.date).locale("fr").day() - 1] = element.fiche;
        // });
        const groups = stat.reduce((groups, item) => {
          const date = moment(item.date).locale("fr").format("dddd");
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(item);
          return groups;
        }, {});
        // console.log('group', groups)
        const other = Object.keys(groups).map((date) => {
          // tslint:disable-next-line: prefer-for-of

          return {
            date: date,
            data: groups[date].length,
          };
        });
        if (other?.length) {
          for (let i = 0; i < other.length; i++) {
            const element = other[i];
            days[moment(element.date, "dddd").day() - 1] = element.data;
          }
        }
      }
      setCount(days);
      setIsPageLoading(false);
    }

    if (type === "month") {
      let days = [0, 0, 0, 0];
      if (stat?.length) {
        const groups = stat.reduce((groups, item) => {
          // const date = getISOWeekInMonth(new Date(item.date))?.week
          const week = getISOWeekInMonth(new Date(item.date))?.week;
          const month = getISOWeekInMonth(new Date(item.date))?.month;
          const key = [month, week].sort((a, b) => a - b).join(",");
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].push(item);
          return groups;
        }, {});
        const other = Object.keys(groups).map((key) => {
          // tslint:disable-next-line: prefer-for-of

          return {
            date: parseInt(key),
            mois: parseInt(key.split(",")[1]),
            data: groups[key].length,
          };
        });
        if (other?.length) {
          other.forEach((element) => {
            if (parseInt(element.mois) < moment(month).format("M")) {
              days[0] = element.data;
            } else {
              days[parseInt(element.date)] = element.data;
            }
          });
          // for (let i = 0; i < other.length; i++) {
          //   const element = other[i]

          //   console.log(
          //     'test',
          //     other.some((oth) => parseInt(oth.date <= 4)),
          //   )

          //   if (other.some((oth) => parseInt(oth.date <= 4))) {
          //     days[parseInt(element.date - 1)] = element.data
          //   } else {
          //     if (parseInt(element.date) === 5) {
          //       days[0] = element.data
          //     } else {
          //       days[parseInt(element.date)] = element.data
          //     }
          //   }
          // }
        }
      }
      setCount(days);
      setIsPageLoading(false);
    }

    if (type === "year") {
      let days = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (stat?.length) {
        const groups = stat.reduce((groups, item) => {
          const date = moment(item.date).locale("fr").format("YYYY");
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(item);
          return groups;
        }, {});

        let dates = null;

        // Edit: to add it in the array format instead
        const other = Object.keys(groups).map((date) => {
          // tslint:disable-next-line: prefer-for-of
          for (let j = 0; j < groups[date].length; j++) {
            const el = groups[date][j];
            // console.log(el.Date);
            const data = groups[date].reduce((data, val) => {
              const mois = moment(val.Date).locale("fr").format("MMMM");
              if (!data[mois]) {
                data[mois] = [];
              }
              data[mois].push(val);
              return data;
            }, {});
            // console.log('data', data)
            dates = Object.keys(data).map((mois) => {
              // console.log(data[mois]);
              return {
                mois,
                value: data[mois]?.length,
              };
            });
            dates.forEach((element) => {
              days[
                labels.indexOf(
                  element.mois.charAt(0).toUpperCase() + element.mois.slice(1)
                ) - 1
              ] = element.value;
            });
          }
          return {
            date,
            value: dates,
          };
        });
        // console.log('days', other)
      } else {
      }
      setCount(days);
      setIsPageLoading(false);
    }
  }, [stat, date]);

  const handleChangeDate = (date, type) => {
    setCount([]);
    // console.log(type)
    if (type === "week") {
      setDate(date);
      // setMonth(null)
      // setYear(null)
    }

    if (type === "month") {
      setMonth(date);
      // setYear(null)
      // setDate(null)
    }

    if (type === "year") {
      setYear(date);
      // setDate(null)
      // setMonth(null)
    }

    setType(type);
  };
  // const data = {
  //   labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  //   datasets: [
  //     {
  //       label: "# of Votes",
  //       data: [12, 19, 3, 5, 2, 3],
  //       backgroundColor: [
  //         "rgba(255, 99, 132, 0.2)",
  //         "rgba(54, 162, 235, 0.2)",
  //         "rgba(255, 206, 86, 0.2)",
  //         "rgba(75, 192, 192, 0.2)",
  //         "rgba(153, 102, 255, 0.2)",
  //         "rgba(255, 159, 64, 0.2)",
  //       ],
  //       borderColor: [
  //         "rgba(255,99,132,1)",
  //         "rgba(54, 162, 235, 1)",
  //         "rgba(255, 206, 86, 1)",
  //         "rgba(75, 192, 192, 1)",
  //         "rgba(153, 102, 255, 1)",
  //         "rgba(255, 159, 64, 1)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row breadcrumbs-and-actions-row">
          <div className="col-md-4 mb-2 dis-flex">
            <DatePicker
              placeholderText="Par semaine"
              dateFormat="dd/MM/yyyy"
              className="form-select form-select-line-chart ml-md-auto"
              selected={date}
              onChange={(date) => handleChangeDate(date, "week")}
              locale="fr"
              showWeekNumbers
            />
          </div>
          <div className="col-md-4 mb-2 dis-flex">
            <DatePicker
              placeholderText="Par mois"
              dateFormat="MMMM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              maxDate={new Date()}
              className="form-select form-select-line-chart ml-md-auto"
              selected={month}
              onChange={(date) => handleChangeDate(date, "month")}
              locale="fr"
            />
          </div>
          <div className="col-md-4 mb-2 dis-flex">
            <DatePicker
              placeholderText="Par année"
              showYearPicker
              dateFormat="yyyy"
              maxDate={new Date()}
              className="form-select form-select-line-chart ml-md-auto"
              selected={year}
              onChange={(date) => handleChangeDate(date, "year")}
              locale="fr"
            />
          </div>
        </div>

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div
            className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 ben-profile-container"
            id="gestion-rapport-assureur"
          >
            <div className="page-body-container">
              <div className="row beneficiaire-profile-row">
                <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 profile-infos-perso-container profile-item">
                  <div className="profile-infos-ben card-rapports-assureur">
                    <h2 className="section-light-title">
                      <div className="light-title">Rapport Fiches de soins</div>
                    </h2>
                    <div
                      className="chart-item-container"
                      style={{ textAlign: "center", width: 400 }}
                    >
                      {!isPageLoading && !assureur_ficheSoins?.isLoading ? (
                        <Pie
                          data={data}
                          width={250}
                          height={250}
                          style={{ textAlign: "center" }}
                        />
                      ) : (
                        <Skeleton circle={true} height={250} width={250} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 col-xs-12 profile-infos-family-container profile-item">
                  <div className="wifes-infos-container card-rapports-assureur">
                    <div className="page-first-row-container">
                      <div className="page-title-container">
                        <h1 className="page-title">Top Assurés</h1>
                      </div>
                    </div>
                    <div className="epouses-table-container">
                      <TopAssuresTable
                        assures={assureur_rapports?.data?.top_10_beneficiaire}
                        isLoading={assureur_rapports?.isLoading}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 profile-infos-family-container profile-item">
                  <div className="childs-infos-container card-rapports-assureur">
                    <div className="page-first-row-container">
                      <div className="page-title-container">
                        <h1 className="page-title">Top Oppositions</h1>
                      </div>
                    </div>
                    <div className="epouses-table-container">
                      <TopOppositionTable
                        exceptions={assureur_rapports?.data?.top_10_exception}
                        isLoading={assureur_rapports?.isLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionRapportsAssureur;
