import React, { useState, useMemo, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RequestPassword from "../Services/RequestPassword";
import { useHistory } from "react-router";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";

function ResetPassword() {
  const [isInProgress, setIsInProgress] = useState(false);
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);
  const history = useHistory();

  const { register, handleSubmit, formState, clearErrors, setValue, watch } =
    useForm();
  useEffect(() => {
    //register('new_password')
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, formState?.errors, clearErrors, register]);

  const countUppercase = (str) => {
    if (str.replace(/[^A-Z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str) => {
    if (str.replace(/[^a-z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str) => {
    if (str.replace(/[^0-9]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str) => {
    if (str.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str) => {
    const punct = "!,;.-?@";
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e) => {
    var password = e.target.value;
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }
    // console.log("paass", e.target.value);
    register("new_password", {
      required: {
        value: true,
        message: "Ce champ est obligatoire.",
      },
      pattern: {
        value: new RegExp(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
          // "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
        message:
          "Votre mot de passe doit contenir au moins 8 caracteres, un majuscule, un minuscule et un caractère spécial.",
      },
    });
    setValue("new_password", e.target.value);
  };

  const onReset = async (data) => {
    setIsInProgress(true);
    console.log("data", data);
    var passwordReset = RequestPassword.postResetPassword(data);

    await passwordReset
      .then((res) => {
        console.log(res);
        setIsInProgress(false);
        toast.success(
          "Votre mot de passe a été modifié avec succès. Vous serez redirigé vers la page de connexion dans 5 secondes."
        );
        // window.$(".open-reset-password-modal").modal("hide");
        setTimeout(() => {
          history.push("/connexion");
        }, 5000);
      })
      .catch((e) => {
        setIsInProgress(false);
        console.log(e?.response, e);
        if (e?.response?.statut === 400) {
          toast.error(
            "Email ou code incorrecte. Veuillez utiliser un email ou code valide."
          );
        } else {
          toast.error(
            "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard."
          );
        }
      });
  };

  return (
    <div className="reset-password-component">
      <div className="p-t-100">
        <div className="dis-flex">
          <div className="col-xl-5 col-lg-6 col-md-7 col-sm-10 ml-auto mr-auto">
            <div className="card-connexion">
              <p className="titre-connexion">Modifiez votre mot de passe</p>
              <form id="reset-form" onSubmit={handleSubmit(onReset)}>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="hash" />
                    </span>
                  </div>
                  <input
                    autoComplete="off"
                    name="code"
                    id="code"
                    {...register("code", {
                      required: "le code est requis",
                    })}
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Le code reçu par email"
                  />
                  {formState.errors.code && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.code.message}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="mail" />
                    </span>
                  </div>
                  <input
                    autoComplete="off"
                    name="email"
                    id="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Ce champ est obligatoire.",
                      },
                      pattern: {
                        value: new RegExp(
                          // /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                          /^(?<user>[a-zA-Z0-9._%+'-]+)@(?<domain>[a-zA-Z0-9.'-]+\.[a-zA-Z]{2,4})$/
                        ),
                        message: "Votre email n'est pas valide",
                      },
                    })}
                    type="email"
                    className="form-control input-with-icon"
                    placeholder="Adresse mail"
                  />
                  {formState.errors.email && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.email.message}{" "}
                    </div>
                  )}
                </div>

                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="lock" />
                    </span>
                  </div>
                  <input
                    autoComplete="new-password"
                    name="new_password"
                    id="new_password"
                    onChange={(e) => passwordHandleChange(e)}
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Votre nouveau mot de passe"
                  />
                  {formState.errors.new_password && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.new_password.message}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2 password-validation-terms-row">
                  <div className="password-validation-terms-container">
                    <h4 className="password-validation-terms-title">
                      Votre mot de passe doit contenir :{" "}
                    </h4>
                    <ul className="password-validation-terms-ul">
                      <li
                        className={
                          "min-character-count pvt-li " +
                          (haveMinCharacter && "min-character-count-ok")
                        }
                      >
                        Au minimum 8 caractères
                      </li>
                      <li
                        className={
                          "min-character-uppercase pvt-li " +
                          (haveMinUppercase && "min-character-uppercase-ok")
                        }
                      >
                        Au minimum 1 caractère en majuscule
                      </li>
                      <li
                        className={
                          "min-character-lowercase pvt-li " +
                          (haveMinLowercase && "min-character-lowercase-ok")
                        }
                      >
                        Au minimum 1 caractère en minuscule
                      </li>
                      <li
                        className={
                          "min-character-number pvt-li " +
                          (haveMinNumber && "min-character-number-ok")
                        }
                      >
                        Au minimum 1 nombre
                      </li>
                      <li
                        className={
                          "min-character-special pvt-li " +
                          (haveMinSpecialCharacter &&
                            "min-character-special-ok")
                        }
                      >
                        Au minimum 1 caractère spéciale
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="lock" />
                    </span>
                  </div>
                  <input
                    autoComplete="new-password"
                    name="new_password_confirm"
                    id="new_password_confirm"
                    {...register("new_password_confirm", {
                      required: {
                        value: true,
                        message: "Ce champ est obligatoire.",
                      },
                      validate: (value) => {
                        return (
                          value === watch("new_password") ||
                          "Veuillez entrer deux mots de passe identiques."
                        );
                      },
                    })}
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Confirmez votre nouveau mot de passe"
                  />
                  {formState.errors.new_password_confirm && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.new_password_confirm.message}{" "}
                    </div>
                  )}
                </div>

                {isInProgress === false ? (
                  <button type="submit" className="btn-connexion">
                    Envoyer
                  </button>
                ) : (
                  <button className="btn-connexion in-progress-btn">
                    Envoi en cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>

        {/* {this.state.isSuccess || this.state.isError ? <ToastContainer /> : ""}

        <div className="">
          <Footer />
        </div> */}
      </div>
    </div>
  );
}

export default ResetPassword;
