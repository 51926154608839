import React from "react";

import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import DefaultAvatar from "../../../../images/others/default-profile-avatar.png";
import { ApiUrl } from "../../../../Http/http/http";
import { getName } from "../../../../utils/utils";
import moment from "moment";
const RenderView = ({ label, name }) => {
  const labelCmp =
    typeof name === "string" ? (
      <span className="crud-form-control">{name}</span>
    ) : (
      name
    );
  return (
    <div className="col-md-6 crud-col-item">
      <div className="form-group crud-form-group">
        <strong>{label} :</strong>
        {labelCmp}
      </div>
    </div>
  );
};
const LivretInformationPersonnelle = ({ user, carnet }) => {
  const avatar =
    user?.avatar?.includes("default.png") || !user?.avatar
      ? DefaultAvatar
      : ApiUrl + user?.avatar;
  const DISPLAY_VALUES = [
    { name: "matricule", label: "Identifiant" },
    { name: "sexe", label: "Genre" },
    {
      name: "is_active",
      label: "Statut",
      gv: (v) => (v ? "ACTIF" : "INACTIF"),
    },
    { name: "nom", label: "Nom" },
    { name: "prenom", label: "Prenom" },
    { name: "date_de_naissance", label: "Date de naissance" },
    { name: "situation_matrimoniale", label: "Situation matrimoniale" },
    { name: "groupe_sanguin", label: "Groupe sanguin" },
    { name: "nationnalite", label: "Nationnalite" },
    { name: "telephone", label: "Telephone" },
    { name: "email", label: "Email" },
    { name: "employeur", label: "Entreprise", gv: (v) => v.nom },
    { name: "adresse", label: "Departement" },
    { name: "date_joined", label: "Date de creation", format: "DD/MM/YYYY" },
  ];
  return (
    <div className="parent-table-container">
      <div className="px-5">
        <div className="d-flex align-items-center flex-column py-3">
          <div className="py-3">
            <img
              src={avatar}
              alt="Image profil utilisateur"
              className="img-profil-app"
            />
          </div>
          <div>
            <h2 className="nom-profil-user pb-3">{getName(user)}</h2>
            <p className="text-profil-user">Bénéficiaire</p>
          </div>
        </div>
        <div className="py-3">
          <p className="label-profil-user">Informations personnelles</p>
        </div>
        <div className="row d-flex align-items-center">
          {DISPLAY_VALUES.map(({ label, name, format, gv }) => {
            const value = _.get(user, name);
            let displayValue = value;
            if (format) {
              displayValue = moment(value).format(format);
            }
            if (gv) {
              displayValue = gv(value);
            }
            return <RenderView key={name} label={label} name={displayValue} />;
          })}
          {!!carnet && (
            <div className="col-md-12 crud-col-item mt-3 mb-3 justify-content-center d-flex">
              <img
                title="Télécharger la fiche"
                src={ApiUrl + carnet.qrcode}
                style={{ width: 250, height: "auto" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LivretInformationPersonnelle;
