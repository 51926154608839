import { exception } from './Endpoints'
import axios from 'axios'

export default {
    async getListExceptions(config) {
        const response = await axios.get(exception.onGetOrPostException(), config);
        // console.log(response)
        return response.data;
    },
    async postCreateException(data, config) {
        const response = await axios.post(exception.onGetOrPostException(), data, config);
        return response.data;
    },
    async putException(id, data, config) {
        const response = await axios.put(exception.onEditException(id), data, config);
        return response.data;
    },
    async deleteException(id, config) {
        const response = await axios.delete(exception.onDeleteException(id), config);
        return response;
    },

}