import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import GestionSoinAssureurModal from "../../../Backend/Assurance/GestionSoinsAssureur/GestionSoinAssureur";
import PriseEnChargeTableData from "./PriseEnChargeTableData";
import PriseEnChargeTableSkeleton from "./PriseEnChargeTableSkeleton";
import FeatherIcon from "feather-icons-react";
import { DataContext } from "../../../store/GlobalState";
import { formatMoney } from "../../../../utils/utils";

var PriseEnChargeTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const { assureur_prise_en_charges } = state;
  const [soins, setSoins] = React.useState(PriseEnChargeTableData);

  var [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, [soins]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const familleFormat = (cell, row) => {
    return cell?.nom;
  };

  const typeFormat = (cell, row) => {
    return cell?.nom;
  };

  const montantFormat = (cell, row) => {
    return formatMoney(cell);
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".details-prise-en-charge-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn edit-btn" title="Modification">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".edit-prise-en-charge-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn" title="Supprimer">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            data-toggle="modal"
            data-target=".delete-prise-en-charge-modal"
            onClick={() => getItem(row)}
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    // console.log("item", item);
    dispatch({
      type: "EDIT_PRESTATION_TYPE",
      payload: item,
    });
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {assureur_prise_en_charges?.isLoading && (
              <PriseEnChargeTableSkeleton />
            )}
            {!assureur_prise_en_charges?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={assureur_prise_en_charges?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="familledesoin"
                    dataFormat={familleFormat}
                    filterValue={familleFormat}
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                  >
                    Famille de soins
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="typedesoin"
                    dataFormat={typeFormat}
                    filterValue={typeFormat}
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                  >
                    Type de soins
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="140"
                    dataField="libelle"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    Libellé
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="center"
                    width="80"
                    dataField="taux_prise_en_charge"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    Taux
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    dataAlign="center"
                    dataFormat={montantFormat}
                    dataField="montant_plafond"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    Plafond
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataAlign="center"
                    width="120"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionSoinModal"
            tabIndex="-1"
            aria-labelledby="GestionSoinModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <GestionSoinAssureurModal />
          </div>
          {/* <div
            className="modal fade easypm-modal add-soin-modal"
            id="factureAssureur"
            tabIndex="-1"
            aria-labelledby="factureAssureurLabel"
            data-backdrop="static"
            aria-hidden="true"
        >
            <FactureModalAssureur />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default PriseEnChargeTable;
