import React, { useEffect, useContext, useState } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import MontantVersesAuxEntreprisesTableSkeleton from "./MontantVersesAuxEntreprisesTableSkeleton";
import { formatMontant } from "../../../utils/utils";
import { getDataByID } from "../../utils/fetchData";

var MontantVersesAuxEntreprisesTable = ({ auth }) => {
  const [topEntreprises, setTopEntreprises] = useState([]);
  var [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    if (!auth?.user?.admin_type) {
      getDataByID(`top10montantbyipm`, auth?.user?.id, auth?.token).then(
        (response) => {
          // console.log("response", response);
          setTopEntreprises(response?.top10MontantEntreprise);
          setIsPageLoading(false);
        }
      );
    } else {
      getDataByID(`top10montantbyipm`, auth?.user?.id, auth?.token).then(
        (response) => {
          setTopEntreprises(response?.top10MontantEntreprise);
          setIsPageLoading(false);
        }
      );
    }
  }, [auth]);

  const entFormat = (cell, row) => {
    return row?.item?.raison_sociale;
  };

  const entIDFormat = (cell, row) => {
    return row?.item?.id;
  };

  const montantFormat = (cell, row) => {
    return formatMontant(cell);
  };

  const options = {
    sizePerPage: 5,
    clearSearch: true,
    noDataText: "Aucune donnée disponible",
    expandBy: "column",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <MontantVersesAuxEntreprisesTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={topEntreprises}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={false}
                    width="50"
                    dataFormat={entIDFormat}
                  >
                    N°
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataFormat={entFormat}
                    dataField="entreprise"
                  >
                    Entreprise
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    dataAlign="right"
                    dataFormat={montantFormat}
                    dataField="montant"
                  >
                    Montant(FCFA)
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MontantVersesAuxEntreprisesTable;
