import React, { useEffect } from 'react'
import Header from '../../../Navs/Header'
import NavBarVerticalV2 from '../../navbarVerticalV2/navbarVerticalV2'
import * as IconlyPack from 'react-iconly'
import Prestataires from '../../../Tables/Prestataires/Prestataires'
import CRUDPrestataireModal from '../../../Modals/CRUDPrestataireModal'
import { DataContext } from '../../../store/GlobalState'
import { useHistory } from 'react-router-dom'
import EditPrestataireModal from '../../../Modals/EditPrestataireModal'
import UseCreatePrestataireForm from './UseCreatePrestataireForm'
import ShowPrestataireModal from '../../../Modals/ShowPrestataireModal'
import DeleteModalPrestataire from '../../../Modals/DeleteModalPrestataire'
import { getDataByID } from '../../../utils/fetchData'

var GestionPrestataires = () => {
  const { state, dispatch } = React.useContext(DataContext)

  const {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    unregister,
    isSuccess,
  } = UseCreatePrestataireForm();

  const { auth, prestatairebyassurance, prestataires } = state;

  React.useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (
        auth.user?.user_type === "ipm" ||
        auth?.user?.user_type === "assurance_sante" ||
        auth?.user?.user_type === "admin_ipm"
      ) {
        if (!auth?.user?.admin_type) {
          getDataByID("prestatairebyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "PRESTATAIRE_BY_IPM",
                payload: res.results.reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        } else {
          getDataByID("prestatairebyassurance", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({
                type: "PRESTATAIRE_BY_IPM",
                payload: res.results.reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyipm",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }

      if (auth?.user?.user_type === "entreprise") {
        if (!auth?.user?.admin_type) {
          getDataByID("prestatairebyentreprise", auth?.user?.id, auth.token)
            .then((res) => {
              // console.log('res pres', res)
              dispatch({
                type: "ADD_PRESTATAIRE",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyentreprise",
            auth?.user?.id,
            auth?.token
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        } else {
          getDataByID("prestatairebyentreprise", auth?.user?.parent, auth.token)
            .then((res) => {
              // console.log('res pres', res)
              dispatch({
                type: "ADD_PRESTATAIRE",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyentreprise",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    if (isSuccessfullySubmitted) {
      setTimeout(() => {
        // setPrestataireData(prestataireData)
        setIsSuccessfullySubmitted(false);
      }, 3000);
    }

    if (isFormRequestError) {
      setIsSuccessfullySubmitted(false);
      setIsFormRequestError(true);
      setTimeout(() => {
        setIsFormRequestError(false);
      }, 5000);
    }

    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [
    errors,
    isSuccessfullySubmitted,
    formState,
    isFormRequestError,
    clearErrors,
    setIsFormRequestError,
    setIsSuccessfullySubmitted,
  ]);

  var handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={auth?.user?.user_type} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title entreprise-side">Prestataires</h1>
                    &nbsp;
                    <span className="badge badge-info easypm-badge-info">
                      {" "}
                      {auth?.user?.user_type !== "entreprise"
                        ? prestatairebyassurance.length
                        : prestataires?.length}{" "}
                    </span>
                  </div>

                  {(auth?.user?.user_type === "ipm" ||
                    auth?.user?.user_type === "assurance_sante" ||
                    auth?.user?.user_type === "entreprise" ||
                    auth?.user?.user_type === "admin_ipm") && (
                    <div
                      className="adding-item-container linkable"
                      data-toggle="modal"
                      data-target=".add-prestataire-modal"
                    >
                      <span className="icon-container">
                        <IconlyPack.Plus set="light" primaryColor="gray" />
                      </span>
                      <span className="adding-item-label">
                        {auth?.user?.user_type === "entreprise"
                          ? "Demander un agréement pour un prestataire"
                          : "Ajouter un prestataire"}
                      </span>
                    </div>
                  )}
                </div>
                <div className="parent-table-container">
                  <Prestataires
                    prestatairebyassurance={prestatairebyassurance}
                  />
                </div>

                {/* Modal Add */}
                <div
                  className="modal fade easypm-modal add-prestataire-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <CRUDPrestataireModal
                    assurance_maladie={[auth?.user?.id]}
                    register={register}
                    unregister={unregister}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    handleReset={handleReset}
                    setValue={setValue}
                    reset={reset}
                    isSuccess={isSuccess}
                  />
                </div>

                {/* Modal Edit */}
                <div
                  className="modal fade easypm-modal edit-prestataire-modal"
                  id="EditModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="EditModalItemLabel"
                  aria-hidden="true"
                >
                  <EditPrestataireModal />
                </div>

                {/* Modal Show */}
                <div
                  className="modal fade easypm-modal show-prestataire-modal"
                  id="ShowModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="ShowModalItemLabel"
                  aria-hidden="true"
                >
                  <ShowPrestataireModal />
                </div>

                {/* Modal Show */}
                <div
                  className="modal fade easypm-modal delete-prestataire-modal"
                  id="DeleteModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="DeleteModalItemLabel"
                  aria-hidden="true"
                >
                  <DeleteModalPrestataire />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isSuccessfullySubmitted || isFormRequestError  ?
                <ToastContainer /> : ''
            } */}
    </div>
  );
}

export default GestionPrestataires
