import React, { useContext } from "react";
import { DataContext } from "../store/GlobalState";

const ShowFamilleSoinsModal = () => {
  const { state, dispatch } = useContext(DataContext);

  const { famille_soins } = state;
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Detail Famille de soins
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "FAMILLE_SOINS", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {famille_soins?.id && (
              <div className="crud-form">
                <div className="row crud-row">
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group fs-20">
                      <span>Famille de soins &nbsp; </span>
                      <strong>{famille_soins?.nom}</strong>
                    </div>
                  </div>
                </div>
                <div className="subcat-manage-container">
                  <strong className="subcat-manage-title">Type de soins</strong>
                  <div className="row sub-cat-row">
                    {famille_soins?.typedesoin.length ? (
                      famille_soins?.typedesoin.map((sub, i) => (
                        <div
                          className="col-md-6 mb-3 crud-col-item"
                          key={sub?.id}
                        >
                          <div className="add-subcat-item-input-container">
                            <div className="filter-item-container position-relative">
                              <span className="form-control input-add-category">
                                {sub?.nom}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <strong style={{ margin: " 1rem 10rem" }}>
                        Aucun type de soins disponible.
                      </strong>
                    )}
                  </div>
                </div>

                <div className="row submit-btn-row">
                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-danger cancelleb-btn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() =>
                        dispatch({ type: "FAMILLE_SOINS", payload: {} })
                      }
                    >
                      FERMER
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowFamilleSoinsModal;
