import { assuranceMaladie } from './Endpoints'
import axios from 'axios'

export default {
    async editAssuranceMaladie(id, data, config) {
        const response = await axios.put(`${assuranceMaladie.onEditAssuranceMaladie(id)}`, data, config);
        return response.data;
    }, 
    async createAdminAssurance(data, config) {
        const response = await axios.post(`${assuranceMaladie.onCreateAdminAssuranceMaladie()}`, data, config);
        return response.data;
    }, 
    async editAdminAssuranceMaladie(id, data, config) {
        const response = await axios.put(`${assuranceMaladie.onEditAdminAssuranceMaladie(id)}`, data, config);
        return response.data;
    },
    async deleteAdmin(id, config) {
        const response = await axios.delete(assuranceMaladie.onDeleteAdmin(id), config);
        return response;
    },
    async createIpm(data, config) {
        const response = await axios.post(`${assuranceMaladie.onGetOrPostAssuranceMaladie()}`, data, config);
        return response.data;
    },
    async deleteIpm(id, config) {
        const response = await axios.delete(assuranceMaladie.onDeleteAssuranceMaladie(id), config);
        return response;
    } 
    
}