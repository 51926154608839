import axios from "axios";
import { prestation } from "./Endpoints";

export default {
    async createPostFicheDeSoin(data, config) {
        const response = await axios.post(prestation.onCreatePrestation(), data, config);
        return response.data;
    },

    async putPrestation(id, data, config) {
        const response = await axios.put(prestation.onPutPrestation(id), data, config);
        return response.data;
    },
    async deletePrestation(id, config) {
        const response = await axios.delete(prestation.onDeletePrestation(id), config);
        return response;
    }, 

}