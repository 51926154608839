import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { DataContext } from "../store/GlobalState";
import AyantDroit from "../Services/ayantDroit";
import DatePicker from "react-datepicker";
import { updateItem } from "../store/Actions";
import { toast } from "react-toastify";
import baseUrl from "../../Http/backend-base-url";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

const EditFamilyModal = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidDate, setIsValiddate] = React.useState(true);
  const [avatar, setAvatar] = React.useState(null);
  const [certificat, setCertificat] = React.useState(null);
  const { auth, ayantDroit, ayantDroits, beneficiaire } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  var [startDate, setStartDate] = React.useState(new Date());

  React.useEffect(() => {
    if (ayantDroit?.id) {
      register("date_naissance", {
        required: "Ce champ est obligatoire",
      });
      setValue("date_naissance", ayantDroit?.date_naissance);
      var date = new Date(ayantDroit?.date_naissance);
      setStartDate(date);
      register("prenom", {
        required: "Ce champ est obligatoire",
      });
      setValue("prenom", ayantDroit?.prenom);
      register("nom", {
        required: "Ce champ est obligatoire",
      });
      setValue("nom", ayantDroit?.nom);
      register("statut", {
        required: "Ce champ est obligatoire",
      });
      setValue("statut", ayantDroit?.statut);

      register("sexe", {
        required: "Ce champ est obligatoire",
      });
      setValue("sexe", ayantDroit?.sexe);
      console.log("ayant", ayantDroit);

      register("beneficiaire", {
        required: "Ce champ est obligatoire",
      });
      setValue("beneficiaire", ayantDroit?.beneficiaire?.id);
    }

    // props.setValue('beneficiaire', props?.beneficiaireID)
  }, [ayantDroit]);

  const handleDate = (date) => {
    // console.log(moment(date).format('DD-MM-YYYY'))
    if (
      ayantDroit.statut === "enfant" &&
      new Date(ayantDroit.beneficiaire?.date_de_naissance) > date
    ) {
      setIsValiddate(false);
      setTimeout(() => {
        setIsValiddate(true);
      }, 5000);
    } else {
      setStartDate(date);
      register("date_naissance");
      setValue("date_naissance", moment(date).format("YYYY-MM-DD"));
    }
  };

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    console.log("data", data);
    const fd = new FormData();
    if (data?.avatar) {
      fd.append("avatar", data?.avatar);
    }
    if (data?.certificat) {
      fd.append("certificat", data?.certificat);
    }
    fd.append("prenom", data?.prenom);
    fd.append("nom", data?.nom);
    fd.append("beneficiaire", data?.beneficiaire);
    fd.append("statut", data?.statut);
    fd.append("date_naissance", data?.date_naissance);
    fd.append("sexe", data?.sexe);

    var ayantDroitRequest = AyantDroit.putAyantDroit(
      ayantDroit.id,
      fd,
      requestConfig
    );

    await ayantDroitRequest
      .then((response) => {
        var ayantDroitInfos = response;
        console.log("ayantDroit", ayantDroitInfos);
        dispatch(
          updateItem(
            ayantDroits,
            ayantDroit.id,
            ayantDroitInfos,
            "ADD_AYANTDROIT_BY_BENEFICIAIRE"
          )
        );
        setIsLoading(false);
        e.target.reset();
        window.$(".edit-family-modal").modal("hide");
        dispatch({ type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE", payload: {} });
        setCertificat(null);
        toast.success("Ayant droit modifié avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
      });
  };

  const changeAvatar = (e) => {
    const file = e.target.files[0];

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg"
    ) {
      toast.error("Veuiller uploader une image de type jpg ou jpeg ou png");
    }
    // console.log('file', file)

    // setData({ ...data, avatar: file })
    // const fd = new FormData()
    // fd.append('avatar', file)
    setAvatar(file);
    register("avatar");
    setValue("avatar", file);
  };

  const changeCertificat = (e) => {
    const file = e.target.files[0];

    // console.log('file', file)

    // setData({ ...data, avatar: file })
    // const fd = new FormData()
    // fd.append('avatar', file)
    setCertificat(file);
    register("certificat");
    setValue("certificat", file);
  };

  const handleReset = async () => {
    reset();
    setCertificat(null);
    dispatch({ type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE", payload: {} });
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="EditingFamilyModal"
          >
            Modification un ayant droit
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="first_name">Prénom</label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      className="form-control crud-form-control"
                      id="first_name"
                      defaultValue={ayantDroit?.prenom}
                      {...register("prenom")}
                    />

                    {errors.prenom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {errors.prenom.message}{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="last_name">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      className="form-control crud-form-control"
                      id="last_name"
                      defaultValue={ayantDroit?.nom}
                      {...register("nom")}
                    />
                    {errors.nom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {errors.nom.message}{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="date_naissance">Date de naissance</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {errors.date_naissance && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {errors.date_naissance.message}{" "}
                      </div>
                    )}
                    {!isValidDate && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        Attention! Un enfant ne peut être plus agé que son
                        parent.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item input-radio-container">
                  <label htmlFor="genre">Genre</label>
                  <div className="input-radio-content">
                    {ayantDroit?.id && (
                      <>
                        <div className="input-radio-item">
                          <input
                            type="radio"
                            name="sexe"
                            className="crud-form-control input-radio-form-control"
                            id="genre"
                            value="homme"
                            defaultChecked={ayantDroit?.sexe === "homme"}
                            onChange={(e) => setValue("sexe", e.target.value)}
                            // {...register("sexe")}
                          />
                          &nbsp;
                          <span className="input-radio-item-label">
                            Masculin
                          </span>
                        </div>
                        <div className="input-radio-item">
                          <input
                            type="radio"
                            name="sexe"
                            className="crud-form-control input-radio-form-control"
                            id="genre_2"
                            value="femme"
                            defaultChecked={ayantDroit?.sexe === "femme"}
                            onChange={(e) => setValue("sexe", e.target.value)}
                            // {...register("sexe")}
                          />
                          &nbsp;
                          <span className="input-radio-item-label">
                            Féminin
                          </span>
                        </div>
                      </>
                    )}

                    {errors.sexe && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {errors.sexe.message}{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item input-radio-container">
                  <label htmlFor="parente">Lien de parenté</label>
                  {ayantDroit?.statut && (
                    <div className="input-radio-content">
                      <div className="input-radio-item">
                        <input
                          type="radio"
                          name="statut"
                          className="crud-form-control input-radio-form-control"
                          id="parente"
                          value="époux(se)"
                          defaultChecked={
                            ayantDroit?.statut === "époux(se)" ? true : false
                          }
                          {...register("statut")}
                        />
                        &nbsp;
                        <span className="input-radio-item-label">Epouse</span>
                      </div>
                      <div className="input-radio-item">
                        <input
                          type="radio"
                          name="statut"
                          className="crud-form-control input-radio-form-control"
                          id="parente_2"
                          value="enfant"
                          defaultChecked={
                            ayantDroit?.statut === "enfant" ? true : false
                          }
                          {...register("statut")}
                        />
                        &nbsp;
                        <span className="input-radio-item-label">Enfant</span>
                      </div>
                      {errors.statut && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {errors.statut.message}{" "}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="col-md-6 crud-col-item">
                  <div
                    className="form-group crud-form-group custom-file"
                    style={{ marginBottom: "13%" }}
                  >
                    <label htmlFor="file_up">Photo</label>
                    <input
                      type="file"
                      name="avatar"
                      id="file_up"
                      accept="image/*"
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      onChange={changeAvatar}
                    />
                    {errors && errors.avatar && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {errors.avatar?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item mb-5">
                  <div
                    className="form-group crud-form-group custom-file"
                    style={{ marginBottom: "13%" }}
                  >
                    <label htmlFor="certificat_marriage">
                      Certificat de{" "}
                      {ayantDroit?.statut === "enfant"
                        ? "naissance"
                        : "mariage"}
                    </label>
                    <input
                      type="file"
                      placeholder={`Importer le certifcat de ${
                        ayantDroit?.statut === "enfant"
                          ? "naissance"
                          : "mariage"
                      }`}
                      name="certificat"
                      // onChange={handleFile}
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      id="certificat"
                      // {...register("certificat")}
                      onChange={changeCertificat}
                    />
                    {errors && errors.certificat && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {errors.certificat.message}
                      </div>
                    )}
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="row">
                    {ayantDroit?.certificat || certificat ? (
                      <div className="col-md-12 crud-col-item mb-5">
                        <div
                          className="form-group crud-form-group custom-file"
                          style={{ marginBottom: "13%" }}
                        >
                          <label htmlFor="certificat_marriage">
                            Certificat de{" "}
                            {ayantDroit?.statut === "enfant"
                              ? "naissance"
                              : "mariage"}
                          </label>
                          <iframe
                            className="responsive-iframe w-100"
                            src={
                              certificat
                                ? URL.createObjectURL(certificat)
                                : baseUrl + ayantDroit?.certificat
                            }
                            style={{ height: "500px" }}
                            title="Certificat"
                          ></iframe>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <input
                  type="hidden"
                  name="beneficiaire"
                  value={ayantDroit?.beneficiaire || ""}
                  {...register("beneficiaire")}
                />

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isLoading && (
                    <button
                      type="submit"
                      className="btn btn-primary crud-submit-btn"
                    >
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFamilyModal;
