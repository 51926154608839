import React, { useEffect, useContext } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import * as IconlyPack from 'react-iconly';
import FacturationTable from "../../../Tables/Facturation/FacturationTable";
import { DataContext } from "../../../store/GlobalState";
import ShowFactureModal from "../../../Modals/ShowFactureModal";
import DetailFactureModal from "../../../Modals/DetailFactureModal";
import { getDataByID } from "../../../utils/fetchData";
// import CRUDPrestataireModal from '../../../Modals/CRUDPrestataireModal'
import "./FacturePdfModal.css";
import FacturePdfModal from "../../../Modals/FacturePdfModal";

var GestionFacturation = () => {
  const [userType, setUserType] = React.useState();
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;

  React.useEffect(() => {
    if (
      auth?.token &&
      auth?.user?.id &&
      window.sessionStorage.getItem("authToken")
    ) {
      if (
        auth.user?.user_type === "ipm" ||
        auth?.user?.user_type === "assurance_sante" ||
        auth?.user?.user_type === "admin_ipm"
      ) {
        if (!auth?.user?.admin_type) {
          getDataByID("facturebyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_FACTURE_BY_IPM",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );

          getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        } else {
          getDataByID("facturebyipm", auth?.user?.parent, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_FACTURE_BY_IPM",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );

          getDataByID(
            "notificationbyipm",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    setUserType(window.sessionStorage.getItem("userType"));
  }, []);

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Facturation des fournisseurs</h1>
                  </div>

                  {/* {userType === 'ipm' &&
                                        <div className="adding-item-container linkable" data-toggle="modal" data-target=".add-prestataire-modal">
                                            <span className="icon-container">
                                                <IconlyPack.Plus set="light" primaryColor="gray"/>
                                            </span>
                                            <span className="adding-item-label">
                                                Ajouter un prestataire
                                            </span>
                                        </div>
                                    } */}
                </div>
                <div className="parent-table-container">
                  <FacturationTable />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal show-facture-modal"
                  id="AddModalItem"
                  data-backdrop="static"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <ShowFactureModal />
                </div>
                <div
                  class="modal fade"
                  id="facturePdfModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="facturePdfModalLabel"
                  aria-hidden="true"
                >
                  <FacturePdfModal />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal detail-facture-modal"
                  id="AddModalItem"
                  data-backdrop="static"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <DetailFactureModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionFacturation;
