import React, { useEffect, useState } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import { formatMoney, formatMontant } from "../../../utils/utils";
import { getDataByID } from "../../utils/fetchData";
import TopTableSkeleton from "./TopTableSkeleton";

const TopPrestataires = (props) => {
  const [topPrestataires, setTopPrestataires] = useState([]);
  var [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    if (
      props?.auth?.user?.id &&
      (props?.auth?.user?.user_type === "ipm" ||
        props?.auth?.user?.user_type === "assurance_sante" ||
        props?.auth?.user?.user_type === "admin_ipm")
    ) {
      if (!props?.auth?.user?.admin_type) {
        getDataByID(
          `top10montantbyipm`,
          props?.auth?.user?.id,
          props?.auth?.token
        ).then((response) => {
          // console.log("response", response);
          setTopPrestataires(response?.top10MontantPrestataire);
          setIsPageLoading(false);
        });
      } else {
        getDataByID(
          `top10montantbyipm`,
          props?.auth?.user?.id,
          props?.auth?.token
        ).then((response) => {
          setTopPrestataires(response?.top10MontantPrestataire);
          setIsPageLoading(false);
        });
      }
    }
  }, [props?.auth]);

  const presFormat = (cell, row) => {
    return row?.item?.prenom
      ? row?.item?.prenom + " " + row?.item?.nom
      : row?.item?.raison_sociale;
  };

  const presIDFormat = (cell, row) => {
    return row?.item?.id;
  };

  const montantFormat = (cell, row) => {
    return formatMontant(cell);
  };

  const options = {
    sizePerPage: 5,
    clearSearch: true,
    noDataText: "Aucune donnée disponible",
    expandBy: "column",
  };

  return (
    <div className="chart-item-container">
      <div className="row mb-2">
        <div className="col-xl-12 col-lg-12">
          <h2 className="chart-item-title">
            Top 10 des montants facturés par les Prestataires{" "}
          </h2>
        </div>
      </div>
      <div className="table-responsive">
        {isPageLoading && <TopTableSkeleton />}
        {!isPageLoading && (
          <BootstrapTable
            data={topPrestataires}
            striped={true}
            hover={true}
            condensed={true}
            multiColumnSort={2}
            options={options}
            pagination
            search={false}
            version="4"
            bordered={false}
          >
            <TableHeaderColumn
              thStyle={{
                fontWeight: 600,
                fontSize: 16,
                color: "#1F497D",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
              }}
              dataField="item"
              isKey={true}
              hidden={false}
              expandable={false}
              width="50"
              dataFormat={presIDFormat}
            >
              N°
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{
                fontWeight: 600,
                fontSize: 16,
                color: "#1F497D",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
              }}
              dataFormat={presFormat}
              dataField="montant_adherent"
            >
              Prestataire
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{
                fontWeight: 600,
                fontSize: 16,
                color: "#1F497D",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
              }}
              dataFormat={montantFormat}
              dataField="montant"
              width="200"
              dataAlign="right"
            >
              Montant (F CFA)
            </TableHeaderColumn>
            {/* <TableHeaderColumn
              thStyle={{
                fontWeight: 600,
                fontSize: 16,
                color: "#1F497D",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
              }}
              dataFormat={entFormat}
              dataField="montant_entreprise"
            >
              Entreprises
            </TableHeaderColumn> */}
          </BootstrapTable>
        )}
      </div>
    </div>
  );
};

export default TopPrestataires;
