import React from "react";
import moment from "moment";
import { DataContext } from "../../../store/GlobalState";

function DetailsDevis() {
  const { state, dispatch } = React.useContext(DataContext);
  const { devis, auth } = state;
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Detail demande de devis
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "SHOW_DEVIS", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="crud-form">
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Prénom & Nom: </strong>

                    <span className="crud-form-control">
                      {devis?.beneficiaire?.prenom +
                        " " +
                        devis?.beneficiaire?.nom}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="name">Date de la demande: </strong>
                    <span className="crud-form-control">
                      {moment(devis?.created_at).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="specialite">Email: </strong>
                    <span className="crud-form-control">
                      {devis?.beneficiaire?.email}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="specialite">Adresse: </strong>
                    <span className="crud-form-control">
                      {devis?.beneficiaire?.adresse}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="specialite">Téléphone: </strong>
                    <span className="crud-form-control">
                      {devis?.beneficiaire?.telephone}
                    </span>
                  </div>
                </div>

                <div
                  className="col-md-6 offset-md-6 crud-form-action-btn-container"
                  style={{ justifyContent: "flex-end" }}
                >
                  <button
                    className="btn btn-danger cancelleb-btn-assurance"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      dispatch({ type: "SHOW_DEVIS", payload: {} })
                    }
                  >
                    Retour
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsDevis;
