import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import TopAssuresTableSkeleton from "./TopAssuresTableSkeleton";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import TopAssuresTableData from "./TopAssuresTableData";
import { formatMontant } from "../../../../utils/utils";
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'

var TopAssuresTable = ({ assures, isLoading }) => {
  const [montant, setMontant] = React.useState(TopAssuresTableData);

  var [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, [montant]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <NavLink
        to={{
          pathname: `/assurance/details-assures/${row?.item?.id}`,
          state: { assure: row?.item },
        }}
        className="nameFormatter"
      >
        <span className="name"> {row?.item?.prenom} </span> <br />
        <span className="entreprise">
          {" "}
          {row?.item?.employeur.raison_sociale}{" "}
        </span>
      </NavLink>
    );
  };

  const nomFormater = (cell, row) => {
    return (
      <NavLink
        to={{
          pathname: `/assurance/details-assures/${row?.item?.id}`,
          state: { assure: row?.item },
        }}
        className="nameFormatter"
      >
        <span className="name"> {row?.item?.nom} </span> <br />
      </NavLink>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isLoading && <TopAssuresTableSkeleton />}
            {!isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={assures}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => row?.item?.id}
                    isKey={true}
                    hidden={false}
                    dataAlign="right"
                  >
                    Assuré
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="prenom"
                    // filter={{ type: 'TextFilter' }}
                    // filterValue={nameFilter}
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Prénom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="nom"
                    dataFormat={(cell, row) => nomFormater(cell, row)}
                  >
                    Nom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="right"
                    dataField="montant"
                    dataFormat={(cell, row) => formatMontant(cell)}
                  >
                    Montant(FCFA)
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopAssuresTable;
