const EnfantsDetailsAdherentData = [
    {
        id: 1,
        prenom: 'Sophie Ndiaye',
        age: '23 ans',
        genre: 'F'
    },
    {
        id: 2,
        prenom: 'Sophie Ndiaye',
        age: '23 ans',
        genre: 'M'
    },
    {
        id: 3,
        prenom: 'Sophie Ndiaye',
        age: '23 ans',
        genre: 'M'
    },
];

export default EnfantsDetailsAdherentData;
