import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import ayantDroit from "../../../../Services/ayantDroit";
import moment from "moment";

function UseEditAyantDroit() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [ayantDroitData, setAyantDroitData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  // const { state } = useContext(DataContext)
  // const { auth, ayantDroits } = state

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        nom: yup.string().required("Le Nom est requis"),
        prenom: yup.string().required("Le prénom est requis"),
        statut: yup.string().required("Le Lien de parenté est requis"),
        date_naissance: yup
          .string()
          .required("La date de naissance est requise")
          .default(moment(new Date()).format("YYYY-MM-DD")),
        sexe: yup.string("Le genre est requis").required("Le genre est requis"),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    if (formState?.errors?.length) {
      console.log(formState.errors);
    }
  }, [formState]);

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    // formValues['date_naissance'] = moment(formValues?.date_naissance).format(
    //   'YYYY-MM-DD',
    // )
    const fd = new FormData();
    if (formValues?.avatar) {
      fd.append("avatar", formValues?.avatar);
    }

    if (formValues?.certificat?.length) {
      fd.append("certificat", formValues?.certificat[0]);
    }

    fd.append("prenom", formValues?.prenom);
    fd.append("nom", formValues?.nom);
    fd.append("beneficiaire", formValues?.beneficiaire);
    fd.append("statut", formValues?.statut);
    fd.append("date_naissance", formValues?.date_naissance);
    fd.append("sexe", formValues?.sexe);
    console.log(formValues);
    var beneRequest = ayantDroit.putAyantDroit(
      formValues?.id,
      fd,
      requestConfig
    );
    await beneRequest
      .then((response) => {
        var ayantDroitInfos = response;
        setAyantDroitData(ayantDroitInfos);
        setIsSuccess(true);
        window.$(".edit-enfant-modal").modal("hide");
        window.$(".edit-epoux-modal").modal("hide");
        toast.success("Un ayant droit modifié avec succès.");
        e.target.reset();
        reset();
        setIsSuccessfullySubmitted(false);
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        setIsFormRequestError(true);
        setIsSuccessfullySubmitted(false);
        toast.error("Oupss! Une erreur est survenue lors de l'ajout.");
      });
  };

  return {
    register,
    errors,
    formState,
    ayantDroitData,
    setAyantDroitData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitCreateForm),
    isSuccess,
  };
}

export default UseEditAyantDroit;
