import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import GestionReservationTableSkeleton from "./GestionReservationTableSkeleton";
import * as IconlyPack from "react-iconly";
import { NavLink, useLocation } from "react-router-dom";
import GestionReservationTableData from "./GestionReservationTableData";
import ImgPatient from "../../../images/others/photo.png";
import { formatDate, getName, useCurrentUser } from "../../../utils/utils";
import { appendZero } from "../../Backend/PRESTATAIRE/GestionRendezVousPrestataire/CardItemRdv";
import http, { ApiUrl } from "../../../Http/http/http";
import AccepterReservationModal from "./AccepterReservationModal";
import RejeterReservationModal from "./RejeterReservationModal";
import ReaffecterReservationModal from "./ReaffecterReservationModal";
import { usePrestatairebyvisitor } from "../../Backend/Beneficiaire/GestionRendezVous/ListeDesParticipants";
import { toast } from "react-toastify";

var GestionReservationTable = ({ data, loading, getData }) => {
  const user = useCurrentUser();
  const [reafecting, setReafecting] = React.useState({});
  const [accepting, setAccepting] = React.useState({});
  const [rejecting, setRejecting] = React.useState({});

  const [prestataires] = usePrestatairebyvisitor();
  const maj = React.useCallback(
    async (id, status) => {
      try {
        await http.put(`/rdv/${id}/`, { etat: status });
        getData(user.id);
        if (status === "accepte") {
          window.$("#AccepterReservationModal").modal("show");
        } else {
          window.$("#RejeterReservationModal").modal("show");
        }
      } catch (ex) {
        toast.error(ex.message);
      }
      setAccepting({});
      setRejecting({});
    },
    [user]
  );
  React.useEffect(() => {
    if (rejecting.id) {
      maj(rejecting.id, "rejete");
    }
  }, [rejecting.id]);
  React.useEffect(() => {
    if (accepting.id) {
      maj(accepting.id, "accepte");
    }
  }, [accepting.id]);
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
    expandBy: "column",
  };

  const patientFormatter = (cell, row) => {
    return (
      <div>
        <img
          src={ApiUrl + row.beneficiaire?.avatar}
          className="img-patient"
          alt="avatar"
        />
        <br />
        <span className="text-patient-reservation">
          {getName(row.beneficiaire)}{" "}
        </span>
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button
          className="btn-accepter-reservation"
          // data-toggle="modal"
          // data-target="#AccepterReservationModal"
          onClick={() => {
            if (accepting.id === row.id) return;
            setAccepting(row);
          }}
          type="button"
        >
          {accepting.id === row.id ? "Chargement..." : "Accepter"}
        </button>
        <button
          className="btn-reaffecter-reservation mx-2"
          data-toggle="modal"
          onClick={() => {
            setReafecting(row);
          }}
          data-target="#ReaffecterReservationModal"
          type="button"
        >
          Réaffecter
        </button>
        <button
          className="btn-Rejeter-reservation"
          // data-toggle="modal"
          // data-target="#RejeterReservationModal"
          onClick={() => {
            if (rejecting.id === row.id) return;
            setRejecting(row);
          }}
          type="button"
        >
          {rejecting.id === row.id ? "Chargement..." : "Rejeter"}
        </button>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {loading && <GestionReservationTableSkeleton />}
            {!loading && (
              <div className="table-container">
                <BootstrapTable
                  data={data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                    expandable={false}
                    width="100"
                    dataAlign="center"
                  >
                    id
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                    }}
                    filterValue={(_, r) => getName(r.beneficiaire)}
                    width="200"
                    dataField="patient"
                    dataFormat={(cell, row) => patientFormatter(cell, row)}
                  >
                    Patient
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    dataFormat={(_, r) => r.beneficiaire.telephone}
                    dataField="telephone"
                  >
                    Téléphone
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      whiteSpace: "normal",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                    }}
                    width="200"
                    dataField="adresse"
                  >
                    Localisation
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="150"
                    dataFormat={(_, r) => formatDate(r.date)}
                    dataField="date"
                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="120"
                    dataFormat={(_, r) => `${appendZero(r.heure)}:00`}
                    dataAlign="center"
                    dataField="heure"
                  >
                    Heure
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    expandable={false}
                    dataAlign="center"
                    width="200"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="AccepterReservationModal"
        tabindex="-1"
        aria-labelledby="AccepterReservationModalLabel"
        aria-hidden="true"
      >
        <AccepterReservationModal />
      </div>
      <div
        class="modal fade"
        id="ReaffecterReservationModal"
        tabindex="-1"
        aria-labelledby="ReaffecterReservationModalLabel"
        aria-hidden="true"
      >
        <ReaffecterReservationModal
          getData={() => getData(user.id)}
          data={prestataires}
          userId={user?.id}
          item={reafecting}
        />
      </div>
      <div
        class="modal fade"
        id="RejeterReservationModal"
        tabindex="-1"
        aria-labelledby="RejeterReservationModalLabel"
        aria-hidden="true"
      >
        <RejeterReservationModal />
      </div>
    </div>
  );
};

export default GestionReservationTable;
