import React, { useEffect, useContext, useState } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import TypePrestationsData from "./TypePrestationsData";
import TypePrestationsSkeleton from "./TypePrestationsSkeleton";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../store/GlobalState";
import { getDataByID } from "../../utils/fetchData";
import { formatMoney } from "../../../utils/utils";

var TypePrestations = () => {
  const { state, dispatch } = useContext(DataContext);

  const { auth, prestation_types } = state;

  var [isPageLoading, setIsPageLoading] = useState(true);
  // eslint-disable-next-line
  var [types, etTypes] = useState(TypePrestationsData);

  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (auth.user?.user_type !== "admin_ipm") {
        getDataByID(
          "typeprestationbyassurance",
          auth?.user?.id,
          auth.token
        ).then((res) => {
          dispatch({
            type: "ADD_PRESTATION_TYPE",
            payload: res.sort((a, b) => b.id - a.id),
          });
          setIsPageLoading(false);
        });
      } else {
        getDataByID(
          "typeprestationbyassurance",
          auth?.user?.parent,
          auth.token
        ).then((res) => {
          dispatch({
            type: "ADD_PRESTATION_TYPE",
            payload: res.sort((a, b) => b.id - a.id),
          });
          setIsPageLoading(false);
        });
      }
    }
  }, [auth]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const familleFormat = (cell, row) => {
    return cell?.nom;
  };

  const typeFormat = (cell, row) => {
    return cell?.nom;
  };

  const montantFormat = (cell, row) => {
    return formatMoney(cell);
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".show-type-prestation-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn edit-btn" title="Modification">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".edit-type-prestation-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn" title="Archivage">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            data-toggle="modal"
            data-target=".delete-type-prestation-modal"
            onClick={() => getItem(row)}
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    console.log("item", item);
    dispatch({
      type: "EDIT_PRESTATION_TYPE",
      payload: item,
    });
  };

  //   console.log('type prestation', prestation_types)

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <TypePrestationsSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={prestation_types}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="familledesoin"
                    dataFormat={familleFormat}
                  >
                    Famille de soins
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="typedesoin"
                    dataFormat={typeFormat}
                  >
                    Type de soins
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="200"
                    dataField="libelle"
                  >
                    Libellé
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="center"
                    width="80"
                    dataField="taux_prise_en_charge"
                  >
                    Taux
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    dataAlign="center"
                    dataFormat={montantFormat}
                    dataField="montant_plafond"
                  >
                    Plafond
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      padding: 15,
                    }}
                    dataAlign="center"
                    width="120"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypePrestations;
