import React, { useEffect, useState } from "react";

import ImageLabel from "../../../../assets/images/image_label.png";
import DocsLabel from "../../../../assets/images/image_label.png";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import * as IconlyPack from "react-iconly";
import ImgDetail from "../../../../images/others/detail-info.png";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import { NavLink, useLocation, useParams, useHistory } from "react-router-dom";
import EditeurTexte from "./EditeurDeTexte";
import http, { ApiUrl } from "../../../../Http/http/http";
import { formatDate, getDate } from "../../../../utils/utils";

var DetailsInfoUtile = () => {
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const params = useParams();
  const [item, setItem] = React.useState({});
  React.useEffect(() => {
    if (location.state?.item) {
      setItem(location.state.item);
    } else {
      setLoading(true);
      http
        .get(`/article/${params.id}`)
        .then((res) => {
          setLoading(false);
          setItem(res);
        })
        .catch((ex) => {
          history.goBack();
        });
    }
  }, []);
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container pd-page">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      <NavLink to="/info-utile-beneficiaire">
                        <IconlyPack.CaretLeft
                          set="bold"
                          primaryColor="#000"
                          className="pr-2"
                        />
                      </NavLink>
                      {item.titre}
                    </h1>
                  </div>

                  {/* <div
                    className="adding-item-container linkable"
                    style={{ width: "2%" }}
                  >
                    <div>
                      <IconlyPack.Edit
                        set="bold"
                        primaryColor="#005D86"
                        className="pr-2"
                      />
                    </div>
                    <div>
                      <IconlyPack.Delete
                        set="bold"
                        primaryColor="#005D86"
                        className="pr-2"
                      />
                    </div>
                  </div> */}
                </div>
                <div className="parent-table-container">
                  <div className="py-4">
                    <div>
                      <img
                        src={ApiUrl + item.photo}
                        alt="Image detail info utile"
                        className="w-full"
                      />
                    </div>
                    <div className="pt-3">
                      <p className="text-details-info-utile">
                        {item.description}
                      </p>
                      <p className="date-details-info-utile">
                        Publié le {formatDate(item.created_at)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailsInfoUtile;
