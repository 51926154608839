import React, { useState, useEffect, useContext } from "react";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import { NavLink, useLocation, useParams, useHistory } from "react-router-dom";
// import { forEach } from 'lodash'
// import "react-datepicker/dist/react-datepicker.css";
import DefaultAvatar from "../../../../images/others/default-profile-avatar.png";
import EpousesDetailsAssures from "./EpousesDetailsAssures/EpousesDetailsAssures";
import EnfantsDetailsAssures from "./EnfantsDetailsAssures/EnfantsDetailsAssures";
import baseUrl from "../../../../Http/backend-base-url";
import moment from "moment";
import { getDataByID, postData } from "../../../utils/fetchData";
import AjoutEnfant from "./EnfantsDetailsAssures/AjoutEnfant";
import AjoutEpoux from "./EnfantsDetailsAssures/AjoutEpoux";
import EditEnfant from "./EnfantsDetailsAssures/EditEnfant";
import EditEpoux from "./EnfantsDetailsAssures/EditEpoux";
import ActiveFamilyModal from "./EnfantsDetailsAssures/ActiveFamilyModal";
import ArchiveFamily from "./EnfantsDetailsAssures/ArchiveFamily";
import ShowCarnetModal from "../../../Modals/ShowCarnetModal";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { DataContext } from "../../../store/GlobalState";
import FicheSoinGenerate from "./FicheSoinGenerate";
import Beneficiaire from "../../../Services/Beneficiaire";
import PrestationsByBeneficiaire from "../../IPM/PrestationsByBeneficiaire/PrestationsByBeneficiaire";

const DetailsAssures = () => {
  const { dispatch } = useContext(DataContext);
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  let token = window.sessionStorage.getItem("authToken");
  let { id } = params;
  const [assure, setAssure] = useState({});
  let userType = window.sessionStorage.getItem("userType");
  const [showSoin, setShowSoin] = React.useState(false);
  const [carnet, setCarnet] = React.useState("");
  const [avatar, setAvatar] = React.useState(null);
  var [isLoading, setIsLoading] = React.useState(false);
  var [isLoad, setIsLoad] = React.useState(false);
  const [notIn, setNotIn] = React.useState(false);
  const [ayantDroit_type, setAyantDroit_type] = React.useState("");

  // const editBenefRequest = UseEditBeneficiaireForm();

  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };
  const [ayantDroits, setAyantDroits] = useState([]);
  var [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    if (id && token) {
      getAyantDroit(id);
      getDataByID("beneficiaire", id, token)
        .then((response) => {
          // console.log("res benef", response);
          setAssure(response);
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [id, token]);

  const getAyantDroit = (id) => {
    getDataByID("ayantdroitbybeneficiaire", id, token)
      .then((res) => {
        console.log("ayant droit", res);
        setAyantDroits(res.sort((a, b) => b?.id - a?.id));
        setIsPageLoading(false);
      })
      .catch((error) => console.log("error", error?.response));
  };

  useEffect(() => {
    if (location?.state?.assure) {
      setAssure(location.state.assure);
    }
  }, [location]);

  const handleShowSoin = (e) => {
    e.preventDefault();
    setShowSoin(!showSoin);
  };

  const changeAvatar = (e) => {
    const file = e.target.files[0];

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return toast.error(
        "Veuiller uploader une image de type jpg ou jpeg ou png"
      );
    }
    // console.log('file', file)

    // setData({ ...data, avatar: file })
    const fd = new FormData();
    fd.append("avatar", file);
    setAvatar(file);
    setIsLoad(true);
    var benefRequest = Beneficiaire.editBeneficiaire(
      assure?.id,
      fd,
      requestConfig
    );

    benefRequest
      .then(async (response) => {
        // await setIsSuccessfullySubmitted(true)
        var beneficiaireInfos = response;
        // setBeneficiaireData(beneficiaireInfos)
        console.log("response", response);
        toast.success("Photo de profil modifié avec succès.");
        setIsLoad(false);

        history.push({
          pathname: `/assurance/details-assures/${response?.id}`,
          state: { assure: response },
        });
      })
      .catch((error) => {
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
        setIsLoad(false);
      });
  };

  const onPostCarnet = (e) => {
    e.preventDefault();
    setIsLoading(true);
    window.$(".show-carnet-modal").modal("show");
    postData("carnet/", { beneficiaire: assure?.id }, token)
      .then((res) => {
        console.log("res carnet", res);
        setCarnet(res);

        // window.$(".show-carnet-modal").modal("show")
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      })
      .catch((e) => {
        console.log("error carnet", e?.response);
        setIsLoading(false);
        toast.error(
          "Oupss! Une erreur est survenue lors de la génération du carnet."
        );
        window.$(".qr-code-modal").modal("hide");
      });
  };

  const onpenModal = () => {
    if (
      moment(new Date()).isSameOrBefore(new Date(assure?.date_fin)) &&
      assure?.is_active
    ) {
      dispatch({
        type: "SHOW_BENEFICIAIRE",
        payload: assure,
      });
      window.$("#GenererFicheSoinModal").modal("show");
    } else {
      if (assure?.is_active === false) {
        Swal.fire({
          title: "Cet adhérent n'est pas actif.",
          text: "Veuillez l'activer avant de génèrer une fiche de soins.",
          icon: "warning",
          // showLoaderOnConfirm: true,
        });
      } else {
        Swal.fire({
          title: "La date de fin de validité de cet adhérent est dépassée.",
          icon: "warning",
          // showLoaderOnConfirm: true,
        });
      }
    }
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row breadcrumbs-rapports d-flex align-items-center">
          <div className="col-xl-3 col-lg-3 col-md-3 mb-2 dis-flex">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <NavLink
                    to="/assurance/assures"
                    className="nav-item-breadcrumb"
                  >
                    Assurés /
                  </NavLink>
                </li>
                <li
                  class="breadcrumb-item active"
                  className="nav-item-breadcrumb"
                  aria-current="page"
                  style={{ cursor: showSoin ? "pointer" : "normal" }}
                  onClick={() => {
                    if (showSoin) {
                      setShowSoin(false);
                    }
                  }}
                >
                  {assure?.prenom + " " + assure?.nom}
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 mb-2 dis-flex">
            <div>
              <span
                className="icon-container pr-2"
                style={{ color: "rgba(0, 0, 0, 0.7)" }}
              >
                Statut:
              </span>
              <span
                className="adding-item-label text-generer-rapport-assurance"
                style={{ fontWeight: "bold" }}
              >
                {assure?.is_active ? "Actif" : "Inactif"}
              </span>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 mb-2 dis-flex">
            <div>
              <button
                className="w-full btn btn-generer-rapport"
                // data-toggle="modal"
                // data-target=".add-soin-modal"
                // style={{ cursor: "pointer" }}
                onClick={onPostCarnet}
              >
                <span className="icon-container pr-2">
                  <IconlyPack.Wallet
                    set="bold"
                    primaryColor="rgba(0, 0, 0, 0.7)"
                  />
                </span>
                <span className="adding-item-label text-generer-rapport-assurance">
                  Générer un carnet
                </span>
              </button>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 mb-2 dis-flex">
            <div>
              <button
                className="w-full btn btn-generer-rapport"
                onClick={() => onpenModal()}
              >
                <span className="icon-container pr-2">
                  {/* <IconlyPack.Wallet
                    set="bold"
                    primaryColor="rgba(0, 0, 0, 0.7)"
                  /> */}
                  <i
                    className="fa fa-qrcode fa-lg header-actions-btn-icon"
                    aria-hidden="true"
                    style={{
                      color: "rgba(0, 0, 0, 0.7)3",
                      marginTop: "0.3rem",
                    }}
                  ></i>
                </span>
                <span className="adding-item-label text-generer-rapport-assurance">
                  Générer une fiche de soins
                </span>
              </button>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 mb-2 dis-flex">
            <div>
              <button
                className="w-full btn btn-generer-rapport"
                onClick={(e) => handleShowSoin(e)}
              >
                <span className="icon-container pr-2">
                  <IconlyPack.PaperNegative
                    set="bold"
                    primaryColor="rgba(0, 0, 0, 0.7)"
                  />
                </span>
                <span className="adding-item-label text-generer-rapport-assurance">
                  Soins
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div
            className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 ben-profile-container"
            id="gestion-rapport-assureur"
          >
            <div className="page-body-container">
              {!showSoin ? (
                <div className="row beneficiaire-profile-row">
                  <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 profile-infos-perso-container profile-item">
                    <div className="">
                      <div className="profile-infos-ben">
                        <h2 className="section-light-title">
                          <div className="light-title">Fiche de l'assuré</div>
                        </h2>
                        {/* <div className="profile-avatar-container">
                        <img
                          src={
                            assure?.avatar &&
                            !assure?.avatar?.includes("default.png")
                              ? baseUrl + assure?.avatar
                              : DefaultAvatar
                          }
                          className="profile-avatar"
                          alt="Profile"
                        />
                      </div> */}
                        <div className="profile-avatar-container">
                          <img
                            // src={DefaultAvatar}
                            src={
                              avatar
                                ? URL.createObjectURL(avatar)
                                : !assure?.avatar ||
                                  assure?.avatar?.includes("default.png")
                                ? DefaultAvatar
                                : baseUrl + assure?.avatar
                            }
                            className="profile-avatar"
                            alt="Profile"
                          />
                          {isLoad && (
                            <span className="charge">
                              <i
                                className="fas fa-spin fa-spinner"
                                style={{ fontSize: "20px" }}
                              ></i>
                            </span>
                          )}

                          <span>
                            <i className="fas fa-camera"></i>
                            <p>Changer</p>
                            <input
                              type="file"
                              name="avatar"
                              id="file_up"
                              accept="image/*"
                              onChange={changeAvatar}
                            />
                          </span>
                          {/* <h3 className="ben-name">
                              {' '}
                              {beneficiaireInfos?.prenom +
                                ' ' +
                                beneficiaireInfos?.nom}{' '}
                            </h3> */}
                        </div>
                        <h3 className="ben-name text-center mt-4">
                          {assure?.prenom + " " + assure?.nom}
                        </h3>
                        <div className="ben-others-infos-container">
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>N° Assuré:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{assure?.id}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Genre:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{assure?.sexe}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Statut:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <strong>
                                {assure?.is_active ? "Actif" : "Inactif"}
                              </strong>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Nom:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{assure?.nom}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Prénom:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{assure?.prenom}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Date de naissance:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>
                                {moment(assure?.date_de_naissance).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Situation Matriomiale:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{assure?.situation_matrimoniale}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Groupe Sanguin:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{assure?.groupe_sanguin}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Nationalité:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{assure?.nationnalite}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Téléphone:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{assure?.telephone}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Email:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{assure?.email}</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Employeur:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>{assure?.employeur?.raison_sociale}</span>
                            </div>
                          </div>
                          {/* <div className="ben-others-infos-item-container">
                          <div className="ben-others-infos-item-label">
                            <span>Département</span>
                          </div>
                          <div className="ben-others-infos-item-value">
                            <span>Département</span>
                          </div>
                        </div> */}
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Date de création</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>
                                {moment(assure?.created_at).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Date de début:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>
                                {assure?.date_debut !== null &&
                                  moment(new Date(assure?.date_debut)).format(
                                    "DD/MM/YYYY"
                                  )}
                              </span>
                            </div>
                          </div>

                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Date de fin:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>
                                {assure?.date_fin !== null &&
                                  moment(new Date(assure?.date_fin)).format(
                                    "DD/MM/YYYY"
                                  )}
                              </span>
                            </div>
                          </div>

                          <div className="ben-others-infos-item-value">
                            <div
                              className="accordion w-100"
                              id="accordionExample1"
                            >
                              {assure?.cni ? (
                                <>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="headingOne"
                                      style={{ textAlign: "left" }}
                                    >
                                      <h5 className="mb-0">
                                        <button
                                          className="bloc-text-profil"
                                          style={{ background: "none" }}
                                          type="button"
                                          data-toggle="collapse"
                                          data-target={`#collapse${1}`}
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          CNI
                                        </button>
                                      </h5>
                                    </div>

                                    <div
                                      id={`collapse${1}`}
                                      className="collapse show"
                                      aria-labelledby="headingOne"
                                      data-parent="#accordionExample1"
                                    >
                                      <div
                                        className="card-body"
                                        style={{ height: "700px" }}
                                      >
                                        <iframe
                                          className="responsive-iframe w-100"
                                          src={baseUrl + assure?.cni}
                                          style={{ height: "100%" }}
                                          title="CNI"
                                        ></iframe>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className="ben-others-infos-item-value">
                            <div
                              className="accordion w-100"
                              id="accordionExample1"
                            >
                              {assure?.certificat_marriage ? (
                                <>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="headingOne"
                                      style={{ textAlign: "left" }}
                                    >
                                      <h5 className="mb-0">
                                        <button
                                          className="bloc-text-profil"
                                          style={{ background: "none" }}
                                          type="button"
                                          data-toggle="collapse"
                                          data-target={`#collapse${2}`}
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          Certificat de mariage
                                        </button>
                                      </h5>
                                    </div>

                                    <div
                                      id={`collapse${2}`}
                                      className="collapse show"
                                      aria-labelledby="headingOne"
                                      data-parent="#accordionExample1"
                                    >
                                      <div
                                        className="card-body"
                                        style={{ height: "700px" }}
                                      >
                                        <iframe
                                          className="responsive-iframe w-100"
                                          src={
                                            baseUrl +
                                            assure?.certificat_marriage
                                          }
                                          style={{ height: "100%" }}
                                          title="Certificat"
                                        ></iframe>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 profile-infos-family-container profile-item">
                    <div className="wifes-infos-container">
                      <div className="page-first-row-container">
                        <div className="page-title-container">
                          <h1 className="page-title">Épouse (s)</h1>
                        </div>
                        <div
                          className="adding-item-container linkable"
                          data-toggle="modal"
                          data-target=".add-epoux-modal"
                        >
                          <span className="icon-container">
                            <IconlyPack.Plus set="light" primaryColor="gray" />
                          </span>
                          <span className="adding-item-label">Ajouter</span>
                        </div>
                      </div>
                      <div className="epouses-table-container">
                        <EpousesDetailsAssures
                          isPageLoading={isPageLoading}
                          epouses={ayantDroits?.filter(
                            (item) => item?.statut !== "enfant"
                          )}
                        />
                      </div>
                    </div>
                    <div className="childs-infos-container">
                      <div className="page-first-row-container">
                        <div className="page-title-container">
                          <h1 className="page-title">Enfants</h1>
                        </div>
                        <div
                          className="adding-item-container linkable"
                          data-toggle="modal"
                          data-target=".add-enfant-modal"
                        >
                          <span className="icon-container">
                            <IconlyPack.Plus set="light" primaryColor="gray" />
                          </span>
                          <span className="adding-item-label">Ajouter</span>
                        </div>
                      </div>
                      <div className="epouses-table-container">
                        <EnfantsDetailsAssures
                          isPageLoading={isPageLoading}
                          enfants={ayantDroits?.filter(
                            (item) => item?.statut === "enfant"
                          )}
                        />
                      </div>
                    </div>
                    <div
                      class="modal fade add-enfant-modal"
                      id="AjoutEnfantModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="AjoutEnfantModalLabel"
                      aria-hidden="true"
                    >
                      <AjoutEnfant
                        beneficiaire={assure}
                        getAyantDroit={getAyantDroit}
                      />
                    </div>
                    <div
                      class="modal fade add-epoux-modal"
                      id="AjoutEpouxModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="AjoutEpouxModalLabel"
                      aria-hidden="true"
                    >
                      <AjoutEpoux
                        beneficiaire={assure}
                        getAyantDroit={getAyantDroit}
                      />
                    </div>
                    <div
                      class="modal fade edit-enfant-modal"
                      id="EditEnfantModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="EditEnfantModalLabel"
                      aria-hidden="true"
                    >
                      <EditEnfant
                        beneficiaire={assure}
                        getAyantDroit={getAyantDroit}
                      />
                    </div>
                    <div
                      class="modal fade edit-epoux-modal"
                      id="EditEpouxModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="EditEpouxModalLabel"
                      aria-hidden="true"
                    >
                      <EditEpoux
                        beneficiaire={assure}
                        getAyantDroit={getAyantDroit}
                      />
                    </div>
                    <div
                      class="modal fade active-family-modal"
                      id="ActiveEpouxModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="ActiveEpouxModalLabel"
                      aria-hidden="true"
                    >
                      <ActiveFamilyModal getAyantDroit={getAyantDroit} />
                    </div>
                    <div
                      class="modal fade archive-family-modal"
                      id="ArchiveEpouxModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="ArchiveEpouxModalLabel"
                      aria-hidden="true"
                    >
                      <ArchiveFamily
                        beneficiaire={assure}
                        getAyantDroit={getAyantDroit}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <PrestationsByBeneficiaire
                  fullName={assure?.prenom + " " + assure?.nom}
                  showSoin={showSoin}
                  setShowSoin={setShowSoin}
                />
              )}
            </div>
          </div>
          {/* Modal Carnet */}
          <div
            className="modal fade easypm-modal show-carnet-modal"
            id="DeleteModalItem"
            tabIndex="-1"
            aria-labelledby="DeleteModalItemLabel"
            aria-hidden="true"
            data-backdrop="static"
          >
            <ShowCarnetModal carnet={carnet} isLoading={isLoading} />
          </div>

          {/* Modal Fiche*/}
          <div
            className="modal fade easypm-modal add-fiche-modal"
            id="GenererFicheSoinModal"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="GenererFicheSoinModalLabel"
            aria-hidden="true"
          >
            <FicheSoinGenerate />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsAssures;
