import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
// import AjouterUneBaseDeDonnee from "../../../Tables/Assurance/BaseDeDonneeTable/AjouterUneBaseDeDonnee";
import Img1 from "../../../../images/others/doctor.png";
import { NavLink } from "react-router-dom";
import { useQuery } from "../../../../Http/http/query";
import BaseDonneeListeSpecialitesTable from "../../../Tables/Beneficiaire/BaseDonneeListeSpecialites/BaseDonneeListeSpecialitesTable";
import { usePrestatairebyvisitor } from "../GestionRendezVous/ListeDesParticipants";
var ListeDesSpecialite = () => {
  const [data, loading] = usePrestatairebyvisitor();
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}

          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Listes des spécialistes</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <BaseDonneeListeSpecialitesTable
                    loading={loading}
                    data={data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeDesSpecialite;
