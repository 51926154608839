import React from "react";
// import * as IconlyPack from 'react-iconly'
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";

var CRUDIpmModal = (props) => {
  const [phone, setPhone] = React.useState("");

  const handleSetPhone = (val) => {
    // console.log('phone', val)
    setPhone(val);
    props.register("telephone");
    props.setValue("telephone", "+" + val);
  };
  const handleReset = async () => {
    props.reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddIPMModal">
            Ajouter une IPM / ASSUREUR
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-ipm-form-container">
            <form className="crud-form" onSubmit={props.onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="name">Raison Sociale</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="raison_sociale"
                      {...props.register("raison_sociale")}
                      className="form-control crud-form-control"
                      id="name"
                    />
                    {props.formState.errors &&
                      props.formState.errors.raison_sociale && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.raison_sociale?.message}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="specialite">Spécilalité</label>
                    <div className="filter-item-container">
                      <select
                        {...props.register("specialite")}
                        name="specialite"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir une spécialité
                        </option>
                        <option value="ipm">IPM</option>
                        <option value="assureur">ASSURANCE SANTÉ</option>
                      </select>
                      {props.formState.errors &&
                        props.formState.errors.specialite && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.specialite?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="ninea">NINEA</label>
                    <input
                      type="text"
                      placeholder="NINEA"
                      name="ninea"
                      {...props.register("ninea")}
                      className="form-control crud-form-control"
                      id="ninea"
                    />
                    {props.formState.errors && props.formState.errors.ninea && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {props.formState.errors.ninea?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="form-control crud-form-control"
                      id="email"
                      {...props.register("email")}
                    />
                    {props.formState.errors && props.formState.errors.email && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {props.formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      type="text"
                      placeholder="Adresse"
                      name="adresse"
                      {...props.register("adresse")}
                      className="form-control crud-form-control"
                      id="adresse"
                    />
                    {props.formState.errors &&
                      props.formState.errors.adresse && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.adresse?.message}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="telephone">Téléphone</label>
                    {/* <input
                      type="text"
                      placeholder="Téléphone"
                      name="telephone"
                      {...props.register('telephone')}
                      className="form-control crud-form-control"
                      id="telephone"
                    />
                    <div className="input-infos-container">
                      <IconlyPack.Danger
                        set="light"
                        primaryColor="gray"
                        className="alerty-icon"
                      />
                      &nbsp;
                      <span className="input-infos">
                        Le numéro de téléphone doit être au format +221780158171
                      </span>
                    </div> */}
                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      inputClass="form-control crud-form-control"
                      country={"sn"}
                      onlyCountries={["sn"]}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    {props.formState.errors &&
                      props.formState.errors.telephone && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.telephone?.message}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRUDIpmModal;
