import React from "react";
import Skeleton from "react-loading-skeleton";


const BeneficiaireProfileSkeleton = () => {
    return(
        <>
            <div className="profile-avatar-container">
                <Skeleton circle={true} height={100} width={100} />
                <h3 className="ben-name">
                    <Skeleton width={100} />
                </h3>
            </div>
            <div className="ben-others-infos-container">
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Genre</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span><Skeleton width={70} /></span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Statut</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <strong>
                    <Skeleton width={70} />
                    </strong>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Nom</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span><Skeleton width={70} /></span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Prénom</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span><Skeleton width={70} /></span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Date de naissance</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span>
                    <Skeleton width={70} />
                    </span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Situation Matriomiale</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span><Skeleton width={70} /></span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Groupe Sanguin</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span><Skeleton width={70} /></span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Nationalité</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span><Skeleton width={70} /></span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Téléphone</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span><Skeleton width={70} /></span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Email</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span><Skeleton width={70} /></span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Adresse</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span><Skeleton width={70} /></span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Entreprise</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span><Skeleton width={70} /></span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Département</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span>
                    <Skeleton width={70} />
                    </span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Date de création</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span>
                        <Skeleton width={70} />
                    </span>
                </div>
                </div>
                <div className="ben-others-infos-item-container">
                <div className="ben-others-infos-item-label">
                    <span>Date de mise à jour</span>
                </div>
                <div className="ben-others-infos-item-value">
                    <span>
                    <Skeleton width={70} />
                    </span>
                </div>
                </div>
            </div>
        </>
    )
}

export default BeneficiaireProfileSkeleton;