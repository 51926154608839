const TopOppositionAdminTableData = [
    {
        id: 1,
        type: 'Assurance',
        libelle: 'Plafond momentanément atteint',
        cle: 'ABDOU FALL',
        sous_cle: 'Adhérent',
        date_validation: '12/06/2021',
        date_fin: '20/09/2021'
    },
    {
        id: 2,
        type: 'Ipm',
        libelle: 'Plafond momentanément atteint',
        cle: 'ABDOU FALL',
        sous_cle: 'Adhérent',
        date_validation: '12/06/2021',
        date_fin: '20/09/2021'
    },
    {
        id: 3,
        type: 'Ipm',
        libelle: 'Plafond momentanément atteint',
        cle: 'ABDOU FALL',
        sous_cle: 'Adhérent',
        date_validation: '12/06/2021',
        date_fin: '20/09/2021'
    },
    {
        id: 4,
        type: 'Ipm',
        libelle: 'Plafond momentanément atteint',
        cle: 'ABDOU FALL',
        sous_cle: 'Adhérent',
        date_validation: '12/06/2021',
        date_fin: '20/09/2021'
    },
];

export default TopOppositionAdminTableData;
