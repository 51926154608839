import React, { useState, useContext, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { DataContext } from "../store/GlobalState";
import { useForm } from "react-hook-form";
import { deleteData, getData, postData, putData } from "../utils/fetchData";
import FamilleSoins from "../Services/FamilleSoins";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const EditFamilleSoinModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useContext(DataContext);
  const { famille_soins, auth } = state;
  const [inputArray, setInputArray] = useState([]);
  const [typeDeSoin, setTypeDeSoin] = useState([]);
  const [indexSub, setIndexSub] = useState(null);
  const [nameSub, setNameSub] = useState("");
  var [hasFile, setHasFile] = useState(false);
  const { register, handleSubmit, reset, formState, setValue, unregister } =
    useForm();
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const onGetListFamilleSoins = () => {
    getData("familledesoin/", auth.token).then((response) => {
      dispatch({
        type: "LIST_FAMILLE_SOINS",
        payload: response.results.sort((a, b) => b?.id - a?.id),
      });
    });
  };

  useEffect(() => {
    if (famille_soins?.id) {
      register("nom");
      setValue("nom", famille_soins?.nom);

      setTypeDeSoin(famille_soins?.typedesoin);
    }
  }, [famille_soins]);

  const addJugementItem = (e) => {
    e.preventDefault();
    // setInputArray([...inputArray, 'A new Input'])
    setInputArray((prevIndexes) => [...prevIndexes, 1]);
  };
  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...inputArray];
    list.splice(index, 1);
    setInputArray(list);
    unregister(`typedesoin[${index}].nom`);
    unregister(`typedesoin[${index}].familledesoin`);
  };

  const handleDeleteClick = (e, id) => {
    const newList = typeDeSoin.filter((s) => s?.id !== id);
    setTypeDeSoin(newList);
  };

  const handleEditClick = (e, id) => {
    console.log("id", id, nameSub);
    if (nameSub) {
      putData(`typedesoin/${id}/`, { nom: nameSub }, auth?.token)
        .then((response) => {
          setIndexSub(null);
          setNameSub("");
          // setSubcategories(newSubcategories);
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Type de soins modifié avec succés.",
          });
          onGetListFamilleSoins();
        })
        .catch((error) => {
          console.log("error", error?.response);
        });
    }
  };

  const onSubmit = async (data, e) => {
    console.log(data);
    setIsLoading(true);
    if (data?.typedesoin) {
      await data.typedesoin.forEach(async (element) => {
        await postData("typedesoin/", element, auth?.token)
          .then((res) => {
            console.log("res", res);
          })
          .catch((e) => {
            console.log("error", e);
          });
        // ids = [...ids, element?.name]
      });
    }
    setTimeout(() => {
      let fd = new FormData();
      fd.append("nom", data?.nom);
      //   if (data?.picture && hasFile) {
      //     fd.append("picture", data?.picture);
      //   }

      let request = FamilleSoins.putFamilleSoin(
        famille_soins?.id,
        fd,
        requestConfig
      );
      request
        .then((res) => {
          let deleteItems = famille_soins?.typedesoin?.filter(
            (x) => typeDeSoin.indexOf(x) === -1
          );
          //   console.log('supp', deleteItems)
          if (deleteItems?.length) {
            deleteItems.forEach((item) => {
              deleteData(`typedesoin/${item?.id}/`, auth?.token)
                .then((resp) => {
                  //   console.log(resp)
                  onGetListFamilleSoins();
                })
                .catch((err) => {
                  console.error(err);
                });
            });
          } else {
            onGetListFamilleSoins();
          }
          setIsLoading(false);
          e.target.reset();

          window.$(".edit-familleSoins-modal").modal("hide");
          dispatch({ type: "FAMILLE_SOINS", payload: {} });
          reset();
          setInputArray([]);
          setTypeDeSoin([]);
          setHasFile(false);
          toast.success("Famille de soins modifiée avec succès.");
        })
        .catch((e) => {
          console.log("error", e);
          setIsLoading(false);
          toast.error(
            "Oupss! Une erreur est survenue lors de la modification."
          );
        });
    }, 2000);
  };
  const onReset = () => {
    reset();
    dispatch({ type: "FAMILLE_SOINS", payload: {} });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Modifier une famille de soins
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="name">Nom de la famille de soins</label>
                    <div className="filter-item-container">
                      <input
                        name="nom"
                        {...register("nom", {
                          required: "Le nom de la categorie est requis.",
                        })}
                        className="form-control crud-form-control category-name-input"
                        type="text"
                        placeholder="Nom de la famille de soins"
                      />
                    </div>
                  </div>
                  {formState?.errors && formState?.errors?.nom && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.nom?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="subcat-manage-container">
                <h4 className="subcat-manage-title">
                  Ajouter la/les type de soins
                </h4>
                {typeDeSoin.length
                  ? typeDeSoin.map((sub, i) => (
                      <div className="row sub-cat-row" key={sub?.id}>
                        <div className="col-md-8 crud-col-item">
                          <div className="add-subcat-item-input-container">
                            <div className="filter-item-container position-relative">
                              {/* <span className="form-control input-add-category">
                                {sub?.name}
                              </span> */}
                              <input
                                className="form-control input-add-category"
                                name="name"
                                defaultValue={sub?.nom}
                                onChange={(e) => {
                                  setNameSub(e.target.value);
                                  // sub["name"] = e.target.value;
                                  setIndexSub(i);
                                }}
                              />

                              <span className="icon-trash">
                                {i === indexSub && nameSub && (
                                  <FeatherIcon
                                    icon="edit"
                                    onClick={(e) => handleEditClick(e, sub?.id)}
                                  />
                                )}

                                <FeatherIcon
                                  icon="trash-2"
                                  onClick={(e) => handleDeleteClick(e, sub?.id)}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}

                {inputArray.map((item, index) => (
                  <>
                    <div
                      className="row sub-cat-row"
                      key={index}
                      data-value={item}
                    >
                      <div className="col-md-8 crud-col-item">
                        <div className="add-subcat-item-input-container">
                          <div className="filter-item-container position-relative">
                            <input
                              {...register(`typedesoin[${index}].nom`, {
                                required: "Le nom du type de soins est requis.",
                              })}
                              name={`typedesoin[${index}].nom`}
                              className="form-control crud-form-control input-add-category"
                              type="text"
                              placeholder="Type de soins"
                            />
                            <input
                              type="hidden"
                              {...register(
                                `typedesoin[${index}].familledesoin`
                              )}
                              name={`typedesoin[${index}].familledesoin`}
                              value={famille_soins?.id}
                            />
                            <span
                              onClick={(e) => handleRemoveClick(e, index)}
                              className="icon-trash"
                            >
                              <FeatherIcon icon="trash-2" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {formState?.errors && formState?.errors?.typedesoin && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.typedesoin[index]?.nom?.message}
                      </div>
                    )}
                  </>
                ))}
                <div className="row add-dynamically-btn-container">
                  <div
                    className="col-md-4 offset-md-8 crud-col-item add-subcat-item-container linkable"
                    onClick={(e) => addJugementItem(e)}
                  >
                    <div className="add-item-container-sub">
                      <FeatherIcon icon="plus" className="add-item-icon" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row submit-btn-row">
                <div className="col-md-4 offset-md-4 crud-form-action-btn-container">
                  {/* {!props.isSuccessfullySubmitted && 
                                    <button className="btn btn-primary crud-submit-btn">AJOUTER</button>
                                
                                } */}
                  {isLoading && (
                    <div className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </div>
                  )}
                  {/* {props.isSuccessfullySubmitted && (
                                    <button className="btn btn-primary crud-submit-btn">
                                        En cours &nbsp;
                                        <i className="fas fa-spin fa-spinner"></i>
                                    </button>
                                )} */}
                  {!isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      Modifier
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFamilleSoinModal;
