import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import TestimonialAvatar from '../../images/others/Default-profil.jpg'
import QuoteIcon from '../../images/landingpage/quote-icon.svg'


var Testimonial = () => {
    return(
        <div className="testimonial-container">
            <Carousel
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={6100}
            >
                <div className="testimonial-slide-item">
                    <div className="row testimonial-item-row">
                        <div className="col-md-2 testimonial-item-col image-col">
                            <div className="testimonial-image-container">
                                <img src={TestimonialAvatar} alt="Testimonial EasyPM" className="testimonial-image-item" />
                            </div>
                            <div className="testimonial-author-infos-container">
                                <h4 className="testimonial-author-name">Business développeur</h4>
                                {/* <h4 className="testimonial-author-function">ceo de papay</h4> */}
                            </div>
                        </div>
                        <div className="col-md-10 testimonial-item-col textual-col">
                            <img src={QuoteIcon} alt="EasyPM Quote" className="quote-icon" />
                            <p>
                                La solution EasyPM, c’est l’IPM comme vous ne l’avez jamais vu : elle va véritablement transformer et améliorer votre expérience de l’IPM.  

                                Nous avons identifié plusieurs problèmes : la nécessité pour les assurés de se rendre physiquement dans leur IPM pour y retirer des bons et des livrets avant d’aller chez un praticien, la difficulté à bénéficier systématiquement du tiers-payant, les délais d’obtention et de transmission des documents, la gestion des remboursements… 

                                Désormais, les assurés bénéficient d’une IPM dématérialisée, accessible 24/7, depuis leur PC ou leur téléphone mobile pour un accès aux soins plus simple, plus rapide et sécurisé.  

                                En parallèle, les IPM disposent d’un tableau de bord qui leur permet de suivre en toute transparence et en temps réel l’évolution des activités et de transmettre rapidement les informations et documents aux services connexes (paie, comptabilité...).
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonial-slide-item">
                    <div className="row testimonial-item-row">
                        <div className="col-md-2 testimonial-item-col image-col">
                            <div className="testimonial-image-container">
                                <img src={TestimonialAvatar} alt="Testimonial EasyPM" className="testimonial-image-item" />
                            </div>
                            <div className="testimonial-author-infos-container">
                                <h3 className="testimonial-author-name">Amy DIOP</h3>
                                <h4 className="testimonial-author-function">ceo de papay</h4>
                            </div>
                        </div>
                        <div className="col-md-10 testimonial-item-col textual-col">
                            <img src={QuoteIcon} alt="EasyPM Quote" className="quote-icon" />
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim pulvinar viverra lectus adipiscing placerat turpis accumsan porttitor. Viverra gravida feugiat egestas nulla quisque in sed. Proin phasellus tellus non dolor, at viverra hac et. Id cursus aliquam non eu nulla non. Semper viverra nibh varius sit. In eget egestas fringilla eget enim leo ipsum odio non. Eget sodales amet, duis id ac, venenatis molestie aliquam vivamus.
                                Lorem quis ut lacus et dolor, congue. A porta neque nullam eu. Praesent scelerisque egestas at integer. Aliquam cum lacus, nulla ultrices dolor odio. Scelerisque magna a mi neque. Pretium, enim proin amet eget imperdiet in tortor. Ultrices aenean enim ut diam.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="testimonial-slide-item">
                    <div className="row testimonial-item-row">
                        <div className="col-md-2 testimonial-item-col image-col">
                            <div className="testimonial-image-container">
                                <img src={TestimonialAvatar} alt="Testimonial EasyPM" className="testimonial-image-item" />
                            </div>
                            <div className="testimonial-author-infos-container">
                                <h3 className="testimonial-author-name">Amy DIOP</h3>
                                <h4 className="testimonial-author-function">ceo de papay</h4>
                            </div>
                        </div>
                        <div className="col-md-10 testimonial-item-col textual-col">
                            <img src={QuoteIcon} alt="EasyPM Quote" className="quote-icon" />
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim pulvinar viverra lectus adipiscing placerat turpis accumsan porttitor. Viverra gravida feugiat egestas nulla quisque in sed. Proin phasellus tellus non dolor, at viverra hac et. Id cursus aliquam non eu nulla non. Semper viverra nibh varius sit. In eget egestas fringilla eget enim leo ipsum odio non. Eget sodales amet, duis id ac, venenatis molestie aliquam vivamus.
                                Lorem quis ut lacus et dolor, congue. A porta neque nullam eu. Praesent scelerisque egestas at integer. Aliquam cum lacus, nulla ultrices dolor odio. Scelerisque magna a mi neque. Pretium, enim proin amet eget imperdiet in tortor. Ultrices aenean enim ut diam.
                            </p>
                        </div>
                    </div>
                </div> */}

                {/* <div className="testimonial-slide-item">
                    <div className="testimonial-image-container">
                        <img src="assets/1.jpeg" />
                    </div>
                    <div className="testimonial-content-container">
                        <p className="legend">Legend 1</p>
                    </div>
                </div>
                <div className="testimonial-slide-item">
                    <div className="testimonial-image-container">
                        <img src="assets/1.jpeg" />
                    </div>
                    <div className="testimonial-content-container">
                        <p className="legend">Legend 1</p>
                    </div>
                </div> */}
            </Carousel>
        </div>
    )
}

export default Testimonial;