import React, { useContext, useEffect } from "react";
import UseEditSpecialiteForm from "../Backend/SUPERADMIN/GestionDesSpecialites/UseEditSpecialiteForm";
import { DataContext } from "../store/GlobalState";

const EditSpecialiteModal = () => {
  const { state, dispatch } = useContext(DataContext);
  const { specialite } = state;
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    reset,
    setValue,
  } = UseEditSpecialiteForm();

  useEffect(() => {
    if (specialite?.id) {
      register("id");
      setValue("id", specialite.id);
      register("nom");
      setValue("nom", specialite.nom);
    }
  }, [specialite]);

  const handleReset = () => {
    dispatch({
      type: "SPECIALITE",
      payload: {},
    });
    reset();
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Ajouter une spécialité
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="ad_first_name">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      className="form-control crud-form-control"
                      id="ad_first_name"
                      {...register("nom")}
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button
                      type="submit"
                      className="btn btn-primary crud-submit-btn"
                    >
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSpecialiteModal;
