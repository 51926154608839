import React from "react";
import { EditorState, convertToRaw } from "draft-js";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../store/GlobalState";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Devis from "../Services/Devis";
import { getDataByID, putData } from "../utils/fetchData";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
} from "reactstrap";

const DevisValidationModal = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState("");
  const [rowData, setRowData] = React.useState([
    {
      acte: "",
      prix_unit: 0,
      qty: 1,
      part_ipm: 0,
      part_benef: 0,
      montant: 0,
      taux: 0,
    },
  ]);
  const [dataTotal, setData] = React.useState({
    total_montant: 0,
    total_part_ipm: 0,
    total_part_benef: 0,
  });
  const [id, setId] = React.useState(0);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const { state, dispatch } = React.useContext(DataContext);
  const { devis, auth } = state;
  const { register, handleSubmit, reset, formState, unregister, setValue } =
    useForm();
  const [etat, setEtat] = React.useState();
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
      "Content-Type": "application/json",
    },
  };

  React.useEffect(() => {
    register("etat");
    if (devis?.id) {
      if (auth?.user?.user_type === "prestataire") {
        setValue("etat", devis?.etat_prestataire);
        setEtat(devis?.etat_prestataire);
      } else if (auth?.user?.user_type === "entreprise") {
        setValue("etat", devis?.etat_entreprise);
        setEtat(devis?.etat_entreprise);
      } else {
        setValue("etat", devis?.etat_ipm);
        setEtat(devis?.etat_ipm);
      }

      if (devis?.decompte?.data?.length) {
        devis.decompte.data.forEach((element) => {
          if (!element?.qty) {
            element.qty = 1;
          }
        });
        if (
          !devis?.decompte?.total?.total_part_benef ||
          devis?.decompte?.total?.total_part_benef === 0
        ) {
          devis.decompte.total.total_part_benef =
            devis?.decompte?.total?.total_montant -
            devis?.decompte?.total?.total_part_ipm;
        }

        console.log(devis?.decompte?.total);
        setRowData(devis.decompte.data);
        setData(devis?.decompte?.total);
      }
      // console.log("devis", devis?.decompte);
    }
  }, [devis]);

  // React.useEffect(() => {

  // }, [rowData]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleSelect = (e) => {
    register("etat", {
      required: "Ce champ est obligatoire",
    });
    setValue("etat", e.target.value);
    setEtat(e.target.value);
    // if (e.target.value === 'validee') {
    //   register('document', {
    //     required: 'Ce champ est obligatoire',
    //   })
    // }
    if (e.target.value !== "en_cours") {
      unregister("decompte");
    }

    if (e.target.value !== "envoyé") {
      unregister("document");
    }

    if (e.target.value !== "validee") {
      unregister("lettre_engagement");
    }

    if (e.target.value !== "refuse" && auth?.user?.user_type !== "entreprise") {
      unregister("motif");
    }
  };

  // const handleFile = (e) => {
  //   setValue('document', e.target.files[0])
  // }

  const onSubmit = async (data, e) => {
    let isEmpty = Object.values(rowData).every(
      (x) => x?.acte === null || x?.acte === ""
    );

    if (etat === "en_cours" && isEmpty) {
      let error = "Veuillez compléter le décompte avant d'envoyer.";
      setIsError(error);

      setTimeout(() => {
        setIsError("");
      }, 5000);
    } else {
      var formData = new FormData();
      if (auth?.user?.user_type === "prestataire") {
        formData.append("etat_prestataire", data.etat);
        data.etat_prestataire = data.etat;
        delete data.etat;
      } else if (auth?.user?.user_type === "entreprise") {
        formData.append("etat_entreprise", data.etat);
        data.etat_entreprise = data.etat;
        delete data.etat;
      } else {
        formData.append("etat_ipm", data.etat);
        data.etat_ipm = data.etat;
        delete data.etat;
      }

      if (data?.doc) {
        formData.append("doc", data.doc[0]);
      }

      if (data?.lettre_engagement) {
        formData.append("lettre_engagement", data.lettre_engagement[0]);
      }

      if (data?.motif) {
        formData.append("motif", data.motif);
      }

      if (etat === "en_cours") {
        rowData.forEach((element) => {
          delete element?.taux;
        });
        let decompte = {
          total: dataTotal,
          data: rowData,
        };
        data["decompte"] = decompte;
        // formData.append("decompte", JSON.parse(decompte));
      }

      setIsLoading(true);
      console.log("data", data);
      try {
        //
        let request = null;
        if (
          data?.decompte &&
          data?.etat_ipm === "en_cours" &&
          auth?.user?.userId !== "entreprise"
        ) {
          request = Devis.putDevis(devis?.id, data, requestConfig);
        } else {
          request = Devis.putDevis(devis?.id, formData, requestConfig);
        }
        await request
          .then((res) => {
            console.log("res", res);
            if (auth?.user?.user_type === "prestataire") {
              getDataByID("devisbyprestataire", auth?.user?.id, auth.token)
                .then((res) => {
                  dispatch({
                    type: "DEVIS_BY_PRESTATAIRE",
                    payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                  });
                })
                .catch((e) => {
                  console.log("e", e);
                });
            } else if (auth?.user?.user_type === "entreprise") {
              if (auth?.user?.admin_type) {
                getDataByID("devisbyentreprise", auth?.user?.parent, auth.token)
                  .then((res) => {
                    dispatch({
                      type: "DEVIS_BY_ENTREPRISE",
                      payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                    });
                  })
                  .catch((e) => {
                    console.log("e", e);
                  });
              } else {
                getDataByID("devisbyentreprise", auth?.user?.id, auth.token)
                  .then((res) => {
                    dispatch({
                      type: "DEVIS_BY_ENTREPRISE",
                      payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                    });
                  })
                  .catch((e) => {
                    console.log("e", e);
                  });
              }
            } else {
              if (auth?.user?.user_type === "admin_ipm") {
                getDataByID("devisbyipm", auth?.user?.parent, auth.token)
                  .then((res) => {
                    // console.log(res)
                    dispatch({
                      type: "DEVIS_BY_IPM",
                      payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                    });
                  })
                  .catch((e) => {
                    console.log("e", e);
                  });
              } else {
                getDataByID("devisbyipm", auth?.user?.id, auth.token)
                  .then((res) => {
                    // console.log(res)
                    dispatch({
                      type: "DEVIS_BY_IPM",
                      payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                    });
                  })
                  .catch((e) => {
                    console.log("e", e);
                  });
              }
            }

            setIsLoading(false);
            e.target.reset();

            window.$(".devis-validation-modal").modal("hide");
            dispatch({ type: "SHOW_DEVIS", payload: {} });
            setEtat("");
            dispatch({ type: "SHOW_DEVIS", payload: {} });
            setRowData([
              {
                acte: "",
                prix_unit: 0,
                part_ipm: 0,
                qty: 1,
                part_benef: 0,
                montant: 0,
              },
            ]);
            if (res?.message) {
              toast.error(res.message);
            } else {
              if (etat === "supprimer") {
                toast.success("Demande de devis supprimée avec succès.");
              } else {
                toast.success("Statut de la demande modifié avec succès.");
              }
            }
          })
          .catch((e) => {
            console.log(e);
            setIsLoading(false);
            if (e?.response?.status === 500) {
              toast.error(
                "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
              );
            } else if (e?.response?.status === 400) {
              toast.error(
                "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
              );
            } else {
              toast.error(
                "Oupss! Une erreur est survenue lors de la modification."
              );
            }
          });
      } catch (error) {
        console.log("err", error);
      }
    }
  };

  const handleActeChange = (index, value) => {
    const rowDataCopy = rowData.slice(0);
    rowDataCopy[index] = Object.assign({}, rowDataCopy[index], {
      acte: value,
      qty: rowDataCopy[index]?.qty ? rowDataCopy[index]?.qty : 1,
    });
    setRowData(rowDataCopy);
  };

  const handlePrixChange = (index, value) => {
    const rowDataCopy = rowData.slice(0);
    if (rowDataCopy[index]?.montant && rowDataCopy[index]?.part_ipm) {
      rowDataCopy[index].taux =
        (rowDataCopy[index]?.part_ipm * 100) / rowDataCopy[index]?.montant;
    }
    rowDataCopy[index] = Object.assign({}, rowDataCopy[index], {
      prix_unit: parseInt(value, 10),
      montant: rowDataCopy[index]?.qty
        ? rowDataCopy[index]?.qty * parseInt(value, 10)
        : parseInt(value, 10),
      part_ipm: rowDataCopy[index]?.taux
        ? (rowDataCopy[index]?.qty
            ? rowDataCopy[index]?.qty *
              parseInt(value, 10) *
              rowDataCopy[index].taux
            : parseInt(value, 10) * rowDataCopy[index].taux) / 100
        : rowDataCopy[index]?.part_ipm,
    });
    rowDataCopy[index].part_benef = rowDataCopy[index]?.part_ipm
      ? rowDataCopy[index]?.montant - rowDataCopy[index]?.part_ipm
      : rowDataCopy[index]?.part_benef;
    setRowData(rowDataCopy);
    let total = dataTotal;
    let grandTotal = 0;
    let totalIpm = 0;
    let totalBenef = 0;
    const rowTotals = rowDataCopy.map((row) => row.montant || 0);
    if (rowTotals.length > 0) {
      grandTotal = rowTotals.reduce((acc, val) => acc + val);
    }
    total.total_montant = grandTotal;

    const ipmTotals = rowDataCopy.map((row) => row.part_ipm || 0);
    if (ipmTotals.length > 0) {
      totalIpm = ipmTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_ipm = totalIpm;
    // setData(total);
    const benefTotals = rowDataCopy.map((row) => row.part_benef || 0);
    if (benefTotals.length > 0) {
      totalBenef = benefTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_benef = totalBenef;
    // console.log("total ", total);
    setData(total);
  };

  const handleQtyChange = (index, value) => {
    const rowDataCopy = rowData.slice(0);

    if (rowDataCopy[index]?.montant && rowDataCopy[index]?.part_ipm) {
      rowDataCopy[index].taux =
        (rowDataCopy[index]?.part_ipm * 100) / rowDataCopy[index]?.montant;
    }
    rowDataCopy[index] = Object.assign({}, rowDataCopy[index], {
      qty: parseInt(value, 10),
      montant: rowDataCopy[index]?.prix_unit
        ? rowDataCopy[index]?.prix_unit * parseInt(value, 10)
        : 0,
      part_ipm: rowDataCopy[index]?.taux
        ? (rowDataCopy[index]?.prix_unit *
            parseInt(value, 10) *
            rowDataCopy[index].taux) /
          100
        : rowDataCopy[index]?.part_ipm,
    });

    rowDataCopy[index].part_benef = rowDataCopy[index]?.part_ipm
      ? rowDataCopy[index]?.montant - rowDataCopy[index]?.part_ipm
      : rowDataCopy[index]?.part_benef;

    setRowData(rowDataCopy);
    let total = dataTotal;
    let grandTotal = 0;
    let totalIpm = 0;
    let totalBenef = 0;
    const rowTotals = rowDataCopy.map((row) => row.montant || 0);
    if (rowTotals.length > 0) {
      grandTotal = rowTotals.reduce((acc, val) => acc + val);
    }
    total.total_montant = grandTotal;
    setData(total);

    const ipmTotals = rowDataCopy.map((row) => row.part_ipm || 0);
    if (ipmTotals.length > 0) {
      totalIpm = ipmTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_ipm = totalIpm;
    setData(total);
    const benefTotals = rowDataCopy.map((row) => row.part_benef || 0);
    if (benefTotals.length > 0) {
      totalBenef = benefTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_benef = totalBenef;
    // console.log("total ", total);
    setData(total);
  };

  const handlePartIpmChange = (index, value) => {
    const rowDataCopy = rowData.slice(0);
    rowDataCopy[index] = Object.assign({}, rowDataCopy[index], {
      part_ipm: parseInt(value, 10),
      part_benef: rowDataCopy[index]?.montant - parseInt(value, 10),
      taux: (parseInt(value, 10) * 100) / rowDataCopy[index]?.montant,
    });
    setRowData(rowDataCopy);
    let total = dataTotal;
    let totalIpm = 0;

    const ipmTotals = rowDataCopy.map((row) => row.part_ipm || 0);
    if (ipmTotals.length > 0) {
      totalIpm = ipmTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_ipm = totalIpm;
    setData(total);

    let totalBenef = 0;

    const benefTotals = rowDataCopy.map((row) => row.part_benef || 0);
    if (benefTotals.length > 0) {
      totalBenef = benefTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_benef = totalBenef;
    // console.log("total ", total);
    setData(total);
  };

  const handlePartBenefChange = (index, value) => {
    const rowDataCopy = rowData.slice(0);
    rowDataCopy[index] = Object.assign({}, rowDataCopy[index], {
      part_benef: parseInt(value, 10),
      part_ipm: rowDataCopy[index]?.montant - parseInt(value, 10),
    });
    setRowData(rowDataCopy);
    let total = dataTotal;
    let totalBenef = 0;

    const benefTotals = rowDataCopy.map((row) => row.part_benef || 0);
    if (benefTotals.length > 0) {
      totalBenef = benefTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_benef = totalBenef;
    // console.log("total ", total);
    setData(total);

    let totalIpm = 0;

    const ipmTotals = rowDataCopy.map((row) => row.part_ipm || 0);
    if (ipmTotals.length > 0) {
      totalIpm = ipmTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_ipm = totalIpm;
    setData(total);
  };

  const handleMontantChange = (index, value) => {
    const rowDataCopy = rowData.slice(0);
    rowDataCopy[index] = Object.assign({}, rowDataCopy[index], {
      montant: parseInt(value, 10),
      part_ipm: rowDataCopy[index]?.part_benef
        ? parseInt(value, 10) - rowDataCopy[index]?.part_benef
        : rowDataCopy[index]?.part_ipm,
      part_benef: rowDataCopy[index]?.part_ipm
        ? parseInt(value, 10) - rowDataCopy[index]?.part_ipm
        : rowDataCopy[index]?.part_benef,
    });
    setRowData(rowDataCopy);
    let total = dataTotal;
    let grandTotal = 0;
    const rowTotals = rowDataCopy.map((row) => row.montant || 0);
    if (rowTotals.length > 0) {
      grandTotal = rowTotals.reduce((acc, val) => acc + val);
    }
    total.total_montant = grandTotal;
    setData(total);

    let totalBenef = 0;

    const benefTotals = rowDataCopy.map((row) => row.part_benef || 0);
    if (benefTotals.length > 0) {
      totalBenef = benefTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_benef = totalBenef;
    // console.log("total ", total);
    setData(total);

    let totalIpm = 0;

    const ipmTotals = rowDataCopy.map((row) => row.part_ipm || 0);
    if (ipmTotals.length > 0) {
      totalIpm = ipmTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_ipm = totalIpm;
    setData(total);
  };

  const handleRowDelete = (e, row) => {
    e.preventDefault();
    const rowDataCopy = rowData.slice(0);
    rowDataCopy.splice(row, 1);
    // this.setState({
    //   rowData: rowDataCopy,
    // });
    setRowData(rowDataCopy);
    let total = dataTotal;
    let grandTotal = 0;
    let totalIpm = 0;
    let totalBenef = 0;
    const rowTotals = rowDataCopy.map((row) => row.montant || 0);
    if (rowTotals.length > 0) {
      grandTotal = rowTotals.reduce((acc, val) => acc + val);
    }
    total.total_montant = grandTotal;
    // console.log("total", grandTotal);

    const ipmTotals = rowDataCopy.map((row) => row.part_ipm || 0);
    if (ipmTotals.length > 0) {
      totalIpm = ipmTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_ipm = totalIpm;
    // console.log("total ipm", totalIpm);

    const benefTotals = rowDataCopy.map((row) => row.part_benef || 0);
    if (benefTotals.length > 0) {
      totalBenef = benefTotals.reduce((acc, val) => acc + val);
    }
    total.total_part_benef = totalBenef;
    // console.log("total ", total);
    setData(total);
  };
  const handleRowAdd = async (e) => {
    e.preventDefault();
    let idCopy = id;
    idCopy = idCopy + 1;
    const rowDataCopy = rowData.slice(0);
    await rowDataCopy.push({
      acte: "",
      part_ipm: 0,
      part_benef: 0,
      montant: 0,
    });
    await setRowData(rowDataCopy);
    await setId(idCopy);
    console.log(idCopy, rowDataCopy, rowData);
    // this.setState({
    //   rowData: rowDataCopy,
    //   id: id,
    // });
  };

  const onReset = () => {
    // if (devis?.etat !== undefined) {
    reset();
    setEtat("");
    dispatch({ type: "SHOW_DEVIS", payload: {} });
    setRowData([
      {
        acte: "",
        prix_unit: 0,
        qty: 1,
        part_ipm: 0,
        part_benef: 0,
        montant: 0,
      },
    ]);

    setData({
      total_montant: 0,
      total_part_ipm: 0,
      total_part_benef: 0,
    });
    // }
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Gestion des devis
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {(devis?.etat_prestataire !== undefined ||
              devis?.etat_ipm !== undefined) &&
              devis?.id && (
                <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row crud-row">
                    <div className="col-md-12 crud-col-item">
                      <div className="form-group crud-form-group">
                        <label htmlFor="etat">Action</label>
                        <div className="filter-item-container">
                          <select
                            onChange={handleSelect}
                            // defaultValue={
                            //   auth?.user?.user_type === "prestataire"
                            //     ? devis?.etat_prestataire
                            //     : auth?.user?.user_type === "entreprise"
                            //     ? devis?.etat_entreprise
                            //     : devis?.etat_ipm || ""
                            // }
                            name="etat"
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                          >
                            <option hidden value="">
                              Choisir une action
                            </option>
                            <option value="a_traite" hidden>
                              Recevoir
                            </option>

                            {auth?.user?.user_type !== "prestataire" ? (
                              <>
                                {auth?.user?.user_type !== "entreprise" && (
                                  <>
                                    <option value="en_attente">
                                      Mettre en attente
                                    </option>
                                    <option value="en_cours">
                                      Mettre en cours
                                    </option>

                                    <option value="annule">Annuler</option>
                                  </>
                                )}
                                {(auth?.user?.user_type === "entreprise" ||
                                  devis?.etat_entreprise === "validee" ||
                                  devis?.etat_entreprise === "refuse") && (
                                  <>
                                    <option value="refuse">Refuser</option>
                                    <option value="validee">Valider</option>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <option value="envoyé">Envoyer</option>
                                <option value="refuse">Rejeter</option>
                                <option value="supprimer">Supprimer</option>
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    {etat && etat === "envoyé" && (
                      <div className="col-md-12 crud-col-item">
                        <div
                          className="form-group crud-form-group custom-file"
                          style={{ marginBottom: "13%" }}
                        >
                          <label htmlFor="document">Document</label>
                          <input
                            type="file"
                            placeholder="Importer un fichier"
                            name="doc"
                            // onChange={handleFile}
                            className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                            id="document"
                            {...register("doc", {
                              required: "Ce champ est obligatoire",
                              //   validate: (value) => {
                              //     // console.log(value)
                              //     return (
                              //       (value &&
                              //         (value[0].type === 'text/csv' ||
                              //           value[0]?.name?.split('.')?.pop() ===
                              //             'csv')) ||
                              //       'Veuillez importer un fichier .csv !'
                              //     )
                              //   },
                            })}
                          />
                          {formState.errors && formState.errors.doc && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {formState.errors.doc?.message}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {etat &&
                      etat === "refuse" &&
                      auth?.user?.user_type !== "entreprise" && (
                        <div className="col-md-12 crud-col-item">
                          <div className="form-group crud-form-group">
                            <label htmlFor="motif">Motif</label>

                            <textarea
                              className="form-control crud-form-control"
                              name="motif"
                              id="motif"
                              placeholder="Saisissez le motif"
                              {...register("motif", {
                                required:
                                  "Veuiller saisir le motif avant d'envoyer !",
                              })}
                              cols="10"
                              rows="5"
                            ></textarea>

                            {formState.errors && formState.errors?.motif && (
                              <div className="alert alert-danger gfa-alert-danger">
                                {" "}
                                {formState.errors?.motif?.message}{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                    {etat &&
                      auth?.user?.user_type !== "entreprise" &&
                      auth?.user?.user_type !== "prestataire" &&
                      etat === "en_cours" && (
                        <div className="col-md-12 crud-col-item">
                          <div className="form-group crud-form-group">
                            <label htmlFor="motif">Tableu de décompte</label>
                            <fieldset className="table-responsive">
                              <table className="table table-bordered table-striped table-highlight">
                                <thead>
                                  <tr>
                                    <th>Acte</th>
                                    <th>P.U</th>
                                    <th>Qty</th>
                                    <th>Montant</th>
                                    <th>P.Ipm</th>
                                    <th>P.Participant</th>
                                    <th
                                      hidden={rowData.length > 1 ? false : true}
                                    >
                                      #
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rowData.length &&
                                    rowData.map((row, index) => (
                                      <tr key={index}>
                                        <td>
                                          <input
                                            type="text"
                                            value={row.acte || ""}
                                            className="form-control"
                                            onChange={(e) =>
                                              handleActeChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={row.prix_unit || 0}
                                            onChange={(e) =>
                                              handlePrixChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            min={1}
                                            value={row.qty || 1}
                                            onChange={(e) =>
                                              handleQtyChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={row.montant || 0}
                                            onChange={(e) =>
                                              handleMontantChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={row.part_ipm || 0}
                                            onChange={(e) =>
                                              handlePartIpmChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            onChange={(e) =>
                                              handlePartBenefChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                            value={row.part_benef || 0}
                                          />
                                        </td>

                                        {rowData.length > 1 && (
                                          <td>
                                            <button className="action-btn delete-btn">
                                              <IconlyPack.Delete
                                                set="light"
                                                primaryColor="#C8C8C8"
                                                className="action-btn-icon action-delete-icon"
                                                onClick={(e) =>
                                                  handleRowDelete(e, index)
                                                }
                                              />
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  <tr>
                                    <td
                                      colSpan="7"
                                      style={{ border: "none" }}
                                    ></td>
                                  </tr>
                                  <tr
                                    style={{
                                      border: "1px solid #dee2e6",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <td
                                      style={{ border: "1px solid #dee2e6" }}
                                      colSpan="3"
                                    ></td>
                                    <td style={{ border: "1px solid #dee2e6" }}>
                                      <strong>
                                        {dataTotal?.total_montant}
                                      </strong>
                                    </td>
                                    <td style={{ border: "1px solid #dee2e6" }}>
                                      <strong>
                                        {dataTotal?.total_part_ipm}
                                      </strong>
                                    </td>
                                    <td style={{ border: "1px solid #dee2e6" }}>
                                      <strong>
                                        {dataTotal?.total_part_benef}
                                      </strong>
                                    </td>
                                    <td style={{ display: "none" }}></td>
                                  </tr>

                                  <tr>
                                    <td
                                      colSpan="7"
                                      style={{
                                        border: "none",
                                        textAlign: "end",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <button
                                        onClick={handleRowAdd}
                                        style={{
                                          color: "white",
                                          background: "#02a69c",
                                          borderRadius: "10px",
                                          padding: ".5rem",
                                        }}
                                      >
                                        Ajouter une ligne
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </fieldset>
                            {isError && isError && (
                              <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                {isError}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                    {etat &&
                      etat === "validee" &&
                      auth?.user?.user_type !== "entreprise" && (
                        <div className="col-md-12 crud-col-item">
                          <div
                            className="form-group crud-form-group custom-file"
                            style={{ marginBottom: "13%" }}
                          >
                            <label htmlFor="lettre_engagement">
                              lettre d'engagement
                            </label>
                            <input
                              type="file"
                              placeholder="Importer un fichier"
                              name="lettre_engagement"
                              // onChange={handleFile}
                              className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                              id="lettre_engagement"
                              {...register("lettre_engagement", {
                                required: "Ce champ est obligatoire",
                                //   validate: (value) => {
                                //     // console.log(value)
                                //     return (
                                //       (value &&
                                //         (value[0].type === 'text/csv' ||
                                //           value[0]?.name?.split('.')?.pop() ===
                                //             'csv')) ||
                                //       'Veuillez importer un fichier .csv !'
                                //     )
                                //   },
                              })}
                            />
                            {formState.errors &&
                              formState.errors.lettre_engagement && (
                                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                  {formState.errors.lettre_engagement?.message}
                                </div>
                              )}
                          </div>
                        </div>
                      )}

                    <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                      <button
                        className="btn btn-default cancelleb-btn"
                        data-dismiss="modal"
                        onClick={() => onReset()}
                      >
                        Retour
                      </button>

                      {!isLoading && (
                        <button className="btn btn-primary crud-submit-btn">
                          Enregistrer
                        </button>
                      )}

                      {isLoading && (
                        <button
                          disabled
                          className="btn btn-primary crud-submit-btn"
                        >
                          En cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevisValidationModal;
