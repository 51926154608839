import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Remboursement from "../../../Services/Remboursement";
import { DataContext } from "../../../store/GlobalState";
import { getDataByID } from "../../../utils/fetchData";

function ActionRemboursement() {
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useContext(DataContext);
  const { auth, ficheDeSoin, ficheDeSoinsbyIpm, prestation } = state;
  const { register, handleSubmit, reset, setValue, formState, unregister } =
    useForm();
  const [etat, setEtat] = React.useState("");

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  React.useEffect(() => {
    if (prestation?.id) {
      setEtat(prestation?.etat_ipm);
      register("etat_ipm");
      setValue("etat_ipm", prestation?.etat_ipm);

      if (prestation?.etat_ipm !== "rejete") {
        unregister("motif");
      } else {
        register("motif");
        setValue("motif", prestation?.motif);
      }

      if (prestation?.etat_ipm !== "regle") {
        unregister("moyen_paiement");
      } else {
        register("moyen_paiement");
        setValue("moyen_paiement", prestation?.moyen_paiement);
      }
    }
  }, [prestation]);

  const handleSelect = (e) => {
    register("etat_ipm", {
      required: "Ce champ est obligatoire",
    });
    if (e.target.value === "archive") {
      register("etat");
      setValue("etat", e.target.value);
    } else {
      unregister("etat");
    }

    if (e.target.value !== "rejete") {
      unregister("motif");
    } else {
      register("motif");
    }

    if (e.target.value !== "regle") {
      unregister("moyen_paiement");
    } else {
      register("moyen_paiement");
    }
    setEtat(e.target.value);
    setValue("etat_ipm", e.target.value);
  };

  const onSubmit = async (data, e) => {
    console.log("data", data);
    setIsLoading(true);

    var ficheDeSoinRequest = Remboursement.putRemboursement(
      prestation.id,
      data,
      requestConfig
    );

    await ficheDeSoinRequest
      .then(async (response) => {
        var ficheDeSoinInfos = response;
        ficheDeSoinInfos.prestataire = ficheDeSoin.prestataire;
        ficheDeSoinInfos.ayant_droit = ficheDeSoin.ayant_droit;
        ficheDeSoinInfos.beneficiaire = ficheDeSoin.beneficiaire;

        if (auth?.user?.admin_type === "assureur") {
          await getDataByID(
            "remboursementbyipm",
            auth?.user?.parent,
            auth?.token
          )
            .then((res) => {
              dispatch({
                type: "REMBOURSEMENTS_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res?.sort((a, b) => b.id - a.id),
                },
              });
            })
            .catch((err) => console.log("erreur=>", err));
        } else {
          await getDataByID("remboursementbyipm", auth?.user?.id, auth?.token)
            .then((res) => {
              dispatch({
                type: "REMBOURSEMENTS_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res?.sort((a, b) => b.id - a.id),
                },
              });
            })
            .catch((err) => console.log("erreur=>", err));
        }

        await window.$("#ActionRemboursement").modal("hide");
        await setIsLoading(false);
        await dispatch({ type: "EDIT_PRESTATION", payload: {} });
        await e.target.reset();
        await reset();
        await toast.success("Statut de la demande modifié avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        } else {
          toast.error(
            "Oupss! Une erreur est survenue lors de la modification."
          );
        }
      });
  };

  const onReset = () => {
    if (prestation?.etat_ipm !== undefined) {
      reset();
      setEtat("");
      dispatch({ type: "EDIT_PRESTATION", payload: {} });
    }
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Gestion d'une demande de remboursement
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {prestation?.etat !== undefined && prestation?.id && (
              <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row crud-row">
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="etat">Action</label>
                      <div className="filter-item-container">
                        <select
                          // {...register('etat_ipm')}
                          onChange={handleSelect}
                          defaultValue={prestation?.etat_ipm || ""}
                          name="etat_ipm"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                        >
                          <option hidden value="">
                            Choisir une action
                          </option>
                          <option value="en_attente">Mettre en attente</option>
                          <option value="envoye">Envoyer</option>
                          <option value="valide">Valider</option>
                          <option value="regle">Régler</option>
                          <option value="rejete">Rejeter</option>
                          <option value="archive">Archiver</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {etat && etat === "rejete" && (
                    <div className="col-md-12 crud-col-item">
                      <div className="form-group crud-form-group">
                        <label htmlFor="motif">Motif</label>
                        {prestation?.id && (
                          <textarea
                            className="form-control crud-form-control"
                            name="motif"
                            id="motif"
                            placeholder="Saisissez le motif"
                            {...register("motif", {
                              required:
                                "Veuiller saisir le motif avant d'envoyer !",
                            })}
                            defaultValue={prestation?.motif || ""}
                            cols="10"
                            rows="5"
                          ></textarea>
                        )}

                        {formState.errors && formState.errors?.motif && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {" "}
                            {formState.errors?.motif?.message}{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {etat && etat === "regle" && (
                    <div className="col-md-12 crud-col-item">
                      <div className="form-group crud-form-group">
                        <label htmlFor="moyen_paiement">
                          Référence de paiement
                        </label>
                        <input
                          type="text"
                          className="form-control crud-form-control"
                          name="moyen_paiement"
                          style={{
                            width: "100%",
                            border: "1px solid #ced4da",
                            backgroundColor: "#ffffff",
                          }}
                          {...register("moyen_paiement", {
                            required:
                              "Veuiller saisir les références du chèque ou du virement avant d'envoyer !",
                          })}
                          defaultValue={prestation?.moyen_paiement || ""}
                          placeholder="Références du chèque ou du virement"
                          id="moyen_paiement"
                        />

                        {formState.errors &&
                          formState.errors?.moyen_paiement && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {" "}
                              {formState.errors?.moyen_paiement?.message}{" "}
                            </div>
                          )}
                      </div>
                    </div>
                  )}

                  {/* <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group">
                    <label htmlFor="moyen_paiement">
                          Référence de payement
                        </label>

                      <input
                        type="text"
                        className="form-control crud-form-control"
                        name="moyen_paiement"
                        style={{width: "100%", border: '1px solid #ced4da', backgroundColor: "#ffffff",}}
                        {...register("moyen_paiement", {
                          required:
                            "Veuiller saisir les références du chèque ou du virement avant d'envoyer !",
                        })}
                        placeholder="Références du chèque ou du virement"
                        id="moyen_paiement"
                      />

                      {formState.errors.moyen_paiement && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {formState.errors.moyen_paiement.message}{" "}
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn-assurance"
                      data-dismiss="modal"
                      onClick={() => onReset()}
                    >
                      Retour
                    </button>

                    {!isLoading && (
                      <button className="btn crud-submit-btn-assurance">
                        Enregistrer
                      </button>
                    )}

                    {isLoading && (
                      <button
                        disabled
                        className="btn crud-submit-btn-assurance"
                      >
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActionRemboursement;
