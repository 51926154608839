import React from "react";
import * as IconlyPack from "react-iconly";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import { useCurrentUser } from "../../../../utils/utils";
import { useQueries, useQuery } from "../../../../Http/http/query";
import _ from "lodash";
import GestionDevisBeneficiaireModal from "./GestionDevisBeneficiaireModal";
import GestionDevisBeneficiaireTable from "../../../Tables/Beneficiaire/GestionRemboursementBeneficicaire/GestionDevisBeneficiaireTable";
var GestionDevisBeneficiaire = () => {
  const user = useCurrentUser();
  const query = useQuery(null);
  const [data, setData] = React.useState([]);
  const queries = useQueries([]);
  const reload = React.useCallback(() => {
    query.getData(`/devisbybenef/${user?.id}/`);
  }, [user, query]);
  React.useEffect(() => {
    if (user?.id) {
      reload();
      queries.getData(["/prestatairebyassurance/" + user?.assurance?.id + "/"]);
    }
  }, [user?.id]);
  React.useEffect(() => {
    setData(_.orderBy(query.data, ["created_at"], ["desc"]));
  }, [query.data]);

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Devis</h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target="#DemandeRemboursementBeneficiaire"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">Demander un Devis</span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <GestionDevisBeneficiaireTable
                    data={data}
                    reload={reload}
                    loading={query.loading && data.length === 0}
                  />
                </div>
                <div
                  class="modal fade"
                  id="DemandeRemboursementBeneficiaire"
                  tabindex="-1"
                  aria-labelledby="DemandeRemboursementBeneficiaireLabel"
                  aria-hidden="true"
                >
                  <GestionDevisBeneficiaireModal
                    prestataires={queries.data[0] || []}
                    user={user}
                    onDone={(flag) => {
                      if (flag !== false) {
                        reload();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionDevisBeneficiaire;
