const TypePrestationsData = [
    {
        id: 1,
        libelle: 'Médecine Générale',
        taux_prise_en_charge: 20,
        montant_plafond: 38000,
    },
    {
        id: 2,
        libelle: 'Médecine Générale',
        taux_prise_en_charge: 20,
        montant_plafond: 38000,
    },
    {
        id: 3,
        libelle: 'Médecine Générale',
        taux_prise_en_charge: 20,
        montant_plafond: 38000,
    },
    {
        id: 4,
        libelle: 'Médecine Générale',
        taux_prise_en_charge: 20,
        montant_plafond: 38000,
    },
];

export default TypePrestationsData;
