import React from "react";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./LivretBeneficiaire.css";
import ParametreInformation from "./LivretInformationPersonnelle";
import AyantDroitBeneficiaireTable from "./AyantDroitBeneficiaireTable.js/AyantDroitBeneficiaireTable";
import { useQuery } from "../../../../Http/http/query";
import { useCurrentUser } from "../../../../utils/utils";

var LivretBeneficiaire = () => {
  const user = useCurrentUser();
  const query = useQuery(null);
  const carnetQuery = useQuery(null);
  const reload = React.useCallback(() => {
    query.getData("/ayantdroitbybeneficiaire/" + user?.id + "/");
  }, [user, query]);
  React.useEffect(() => {
    if (user?.id) {
      reload();
      carnetQuery.getData(`/carnetbybeneficiaire/${user?.id}/`);
    }
  }, [user?.id]);

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Livret</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active tab-parametre-assurance"
                        id="info-beneficiaire-tab"
                        data-toggle="tab"
                        href="#info-beneficiaire"
                        role="tab"
                        aria-controls="info-beneficiaire"
                        aria-selected="true"
                      >
                        Participant{" "}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link tab-parametre-assurance"
                        id="ayant-droit-tab"
                        data-toggle="tab"
                        href="#ayant-droit"
                        role="tab"
                        aria-controls="ayant-droit"
                        aria-selected="false"
                      >
                        {" "}
                        Ayants droit{" "}
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="info-beneficiaire"
                      role="tabpanel"
                      aria-labelledby="info-beneficiaire-tab"
                    >
                      {!!user && (
                        <ParametreInformation
                          carnet={carnetQuery.data}
                          user={user}
                        />
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="ayant-droit"
                      role="tabpanel"
                      aria-labelledby="ayant-droit-tab"
                    >
                      <AyantDroitBeneficiaireTable
                        items={query.data}
                        user={user}
                        reload={reload}
                        loading={query.loading && query.data.length === 0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivretBeneficiaire;
