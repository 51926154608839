import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AjouterPrestataireModal = (props) => {

 
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AjouterPrestataireModalBeneficiaire">
            Ajouter un prestataire
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
            <span className="type-presta-label">
                      {" "}
                      Le prestataire est une{" "}
                    </span>
              <div className="row crud-row">
                <div className="col-md-12 type-presta-container">
                  <div className="type-presta-label-container col-md-6">
                  <div className="type-presta-input-radio-container">
                      <input
                        type="radio"
                        className="easypm-input-radio input-radio-form-control"
                        name="user_type"
                        value="personne_physique"
                      />
                      <label className="input-radio-item-label">
                        une personne physique
                      </label>
                    </div>
                  </div>
                  <div className="type-presta-label-container col-md-6 type-presta-label-col">
                    <div className="type-presta-input-radio-container">
                      <input
                        type="radio"
                        className="easypm-input-radio input-radio-form-control"
                        name="user_type"
                        value="personne_morale"
                      />
                      <label className="input-radio-item-label">
                        une personne morale
                      </label>
                    </div>
                  </div>
                </div>

                  <>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <label htmlFor="nom">Nom</label>
                        <input
                          type="text"
                          placeholder="Nom"
                          name="nom"
                          className="form-control crud-form-control"
                          id="nom"
                        />
                      </div>
                    </div>
                  </>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="date_debut">Date d'arrivée</label>

                    <DatePicker
                      className="form-control crud-form-control"
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                    />
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="date_fin">Date de fin</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                    />
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Spécialité</label>
                    <div className="filter-item-container">
                      <select
                        name="specialite"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option>
                          Choisir une spécialité
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      type="text"
                      placeholder="HLM Grand Yoff, Villa 241 - Dakar"
                      name="adresse"
                      className="form-control crud-form-control"
                      id="adresse"
                    />
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="telephone">Téléphone</label>
                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      inputClass="form-control crud-form-control"
                      country={"sn"}
                      onlyCountries={["sn"]}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      countryCodeEditable={false}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="numero_contrat">Num. Contrat</label>
                    <input
                      type="text"
                      placeholder="Numéro contrat"
                      name="num_contrat"
                      className="form-control crud-form-control"
                      id="numero_contrat"
                    />
                  </div>
                </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="first_name">Agréement</label>
                      <div className="filter-item-container">
                          <select
                            name="agreement"
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                          >
                            <option>
                              Choisir
                            </option>
                            <option value={true}>OUI</option>
                            <option value={false}>NON</option>
                          </select>
                      </div>
                    </div>
                  </div>


                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="website">Site web</label>
                    <input
                      type="text"
                      placeholder="www.exemple.com"
                      name="website"
                      className="form-control crud-form-control"
                      id="website"
                    />
                    <div className="input-infos-container">
                      <IconlyPack.Danger
                        set="light"
                        primaryColor="gray"
                        className="alerty-icon"
                      />
                      &nbsp;
                      <span className="input-infos">
                        Saisie au format www.exemple.com, l'adresse sera
                        autocomplétée avec le protocole http://
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn-assurance"
                      data-dismiss="modal"
                    >
                      Retour
                    </button>
                    <button className="btn  crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterPrestataireModal;
