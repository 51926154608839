import React, { useContext } from 'react'
import { DataContext } from '../store/GlobalState'

function ShowIpmModal() {
  const { state, dispatch } = useContext(DataContext)

  const { ipm } = state
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Detail ipm
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: 'EDIT_IPM', payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {ipm && (
              <div className="crud-form">
                <div className="row crud-row">
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="name">Nom: </strong>
                      <span className="crud-form-control">
                        {ipm?.raison_sociale}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="specialite">Spécialité: </strong>
                      <span className="crud-form-control">
                        {ipm?.specialite}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="ninea">NINEA: </strong>
                      <span className="crud-form-control">{ipm?.ninea}</span>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="email">Email: </strong>
                      <span className="crud-form-control">{ipm?.email}</span>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="adresse">Adresse: </strong>
                      <span className="crud-form-control">{ipm?.adresse}</span>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="telephone">Téléphone: </strong>
                      <span className="crud-form-control">
                        {ipm?.telephone}
                      </span>
                    </div>
                  </div>

                  <div
                    className="col-md-6 offset-md-6 crud-form-action-btn-container"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    {/* <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button> */}

                    <button
                      className="btn btn-danger cancelleb-btn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() =>
                        dispatch({ type: 'EDIT_IPM', payload: {} })
                      }
                    >
                      Retour
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowIpmModal
