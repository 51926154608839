import moment from "moment";
import React from "react";
import * as IconlyPack from "react-iconly";
import {
  BootstrapTable,
  ClearSearchButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../../store/GlobalState";
// import FactureSkeleton from '../../../Tables/Facture/FactureSkeleton';
import FicheValideSkeleton from "../../../Tables/Facture/FicheValideSkeleton ";
import { getDataByID } from "../../../utils/fetchData";
import AlimentSoinModal from "../../../Modals/AlimentSoinModal";
import ShowFicheFileModal from "../../../Modals/ShowFicheFileModal";
import QrCodeModal from "../../../Modals/QrCodeModal";
import ShowFicheModal from "../../../Modals/ShowFicheModal";
import { formatMoney } from "../../../../utils/utils";
import { Message } from "rsuite";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert2";
import FicheDeSoin from "../../../Services/FicheDeSoin";
import { toast } from "react-toastify";

const FicheValideTable = (props) => {
  const { state, dispatch } = React.useContext(DataContext);
  const {
    auth,
    fichevalidbyprestataire,
    beneficiaires,
    prestataire,
    list_ayantdroit,
  } = state;
  const [isClicked, setIsClicked] = React.useState(false);
  // console.log("auth data=>", auth?.user?.id)
  // const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [fiche, setFiche] = React.useState();
  const location = useLocation();
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  React.useEffect(() => {
    if (auth?.user?.id) {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
    if (
      window.$(".qr-code-modal").data("bs.modal")?._isShown !== undefined &&
      window.$(".qr-code-modal").data("bs.modal")?._isShown === false
    ) {
      setIsClicked(false);
    }
  }, [auth]);

  React.useEffect(() => {
    if (isClicked) {
      window.$(".qr-code-modal").modal("show");
    }
  }, [isClicked]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const getNavLinkClass = (path) => {
    // console.log("test", props, path);
    return props.location === path ? "active" : "";
  };

  const nameFormater = (cell, row) => {
    return (
      <NavLink
        to={`/prestataire/profil-beneficiaire/${row?.beneficiaire?.id}`}
        className={
          "nameFormatter " +
          getNavLinkClass("/prestataire/fiche-de-soin-valide")
        }
      >
        <span className="name">
          {" "}
          {cell.prenom || ""} {cell.nom || ""}{" "}
        </span>{" "}
        <br />
        <strong className="entreprise">
          {" "}
          {cell.assurance?.raison_sociale}{" "}
        </strong>{" "}
      </NavLink>
    );
  };

  const nameFilter = (cell, row) => {
    return cell.prenom + " " + cell.nom;
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
    expandBy: "column",
  };

  const onLock = (item, label) => {
    swal
      .fire({
        title: `Êtes vous sûr de vouloir ${label} cette fiche de soins ?`,
        //   text: 'You will not be able to recover this imaginary file!',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          var deleteRequest = FicheDeSoin.editFicheDeSoin(
            item?.id,
            { used: !item?.used },
            requestConfig
          );
          return deleteRequest
            .then((response) => {
              getDataByID(
                "fichedesoinvalidatebyprestataire",
                auth?.user?.id,
                auth?.token
              ).then((res) => {
                dispatch({
                  type: "LISTE_FICHE_VALID_BY_PRESTATAIRE",
                  payload: res.sort((a, b) => b?.id - a?.id),
                });
              });
            })
            .catch((error) => {
              swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (label === "clôturer") {
            toast.success("Fiche de soins clôturée avec succès.");
          } else {
            toast.success("Fiche de soins réouverte avec succès.");
          }
        }
      });
  };

  const stateFormatter = (cell, row) => {
    return (
      <div>
        {cell === true ? (
          <span className="badge badge-danger easypm-badge-danger">
            Clôturé
          </span>
        ) : (
          <span className="badge badge-success easypm-badge-success">
            Ouvert
          </span>
        )}
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn add-btn" title="Alimenter">
          {!row?.used ? (
            <IconlyPack.PaperPlus
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-add-icon"
              data-toggle="modal"
              data-target=".alim-soin-modal"
              onClick={() => getItem(row)}
            />
          ) : (
            <IconlyPack.PaperFail
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-add-icon"
              // data-toggle="modal"
              // data-target=".alim-soin-modal"
              onClick={() => getWarning()}
            />
          )}
        </button>
        {!row?.used ? (
          <button
            className="action-btn show-btn"
            onClick={() => onLock(row, "clôturer")}
            title="Clôturer"
          >
            <IconlyPack.Lock
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-show-icon"
            />
          </button>
        ) : // <button
        //   className="action-btn show-btn"
        //   onClick={() => onLock(row, "réouvrir")}
        //   title="Réouvrir"
        // >
        //   <IconlyPack.Unlock
        //     set="light"
        //     primaryColor="#C8C8C8"
        //     className="action-btn-icon action-show-icon"
        //   />
        // </button>
        null}

        <button className="action-btn show-btn" title="Visualiser">
          <IconlyPack.Download
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".show-fiche-file-modal"
            onClick={() => getItem(row)}
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    getDataByID(
      "exclusionbyipm",
      item?.beneficiaire?.assurance?.id,
      auth?.token
    ).then((res) => {
      dispatch({
        type: "LIST_EXCLUSION",
        payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
      });
    });
    setFiche(item);
    dispatch({ type: "EDIT_FICHE_DE_SOIN_BY_IPM", payload: item });
  };

  const getWarning = () => {
    swal.fire({
      title: "Cette fiche est clôturée.",
      icon: "warning",
      // showLoaderOnConfirm: true,
    });
  };

  const handleClick = (e) => {
    // e.preventDefault();
    window.$(".qr-code-modal").modal("show");
  };

  const isExpandableRow = (row) => {
    if (row.id) return true;
    else return false;
  };

  const expandComponent = (row) => {
    let totalCout = 0;
    let totalIpm = 0;
    let totalBenef = 0;
    if (row?.prestations && row?.prestations?.length) {
      row.prestations.forEach((element) => {
        totalCout += parseInt(element.montant);
        totalIpm += parseInt(element.montant_rembourse);
        totalBenef += parseInt(element.montant_retenu);
      });
      beneficiaires.forEach((b) => {
        row.prestations.forEach((element) => {
          if (element?.beneficiaire === b?.id) {
            element.beneficiaire = b;
          }
        });
      });

      list_ayantdroit.forEach((b) => {
        row.prestations.forEach((element) => {
          if (element?.ayantdroit === b?.id) {
            element.ayantdroit = b;
          }
        });
      });
    }

    return (
      <div className="easypm-collapse-body">
        <div className="card">
          <div className="card-body">
            {row?.prestations && row?.prestations?.length ? (
              <>
                <div className="row mb-3 px-2">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1 d-block">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Coût total prestations
                        </p>
                        {/* <p className="child-label-card-statistics">En CFA</p> */}
                      </div>
                      <div className="stat-counter text-right">
                        <div
                          className="label-stat-counter"
                          style={{ fontSize: "25px" }}
                        >
                          {/* {totalCout} */}
                          {/* {new Intl.NumberFormat('sn', {
                        currency: 'XOF',
                      }).format(totalCout)} */}
                          {formatMoney(parseInt(totalCout))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2 d-block">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total part Ipm
                        </p>
                        {/* <p className="child-label-card-statistics">En CFA</p> */}
                      </div>
                      <div className="stat-counter text-right">
                        <div
                          className="label-stat-counter"
                          style={{ fontSize: "25px" }}
                        >
                          {/* {new Intl.NumberFormat('sn', {
                        currency: 'XOF',
                      }).format(totalIpm)} */}
                          {formatMoney(parseInt(totalIpm))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3 d-block">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total part salarié
                        </p>
                        {/* <p className="child-label-card-statistics">En CFA</p> */}
                      </div>
                      <div className="stat-counter text-right">
                        <div
                          className="label-stat-counter"
                          style={{ fontSize: "25px" }}
                        >
                          {/* {new Intl.NumberFormat('sn', {
                        currency: 'XOF',
                      }).format(totalBenef)} */}
                          {formatMoney(parseInt(totalBenef))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {row?.prestations.map((prestation) => (
                  <>
                    <div className="crud-form">
                      <div
                        className="row crud-row"
                        style={{ paddingBottom: "10px" }}
                      >
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Adhérent: </strong>

                            <span className="crud-form-control">
                              {`${
                                prestation?.beneficiaire?.prenom +
                                " " +
                                prestation?.beneficiaire?.nom
                              }`}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Bénéficiaire: </strong>

                            <span className="crud-form-control">
                              {row?.ayant_droit?.id
                                ? `${
                                    row?.ayant_droit?.prenom +
                                    " " +
                                    row?.ayant_droit?.nom
                                  }`
                                : `${
                                    prestation?.beneficiaire?.prenom +
                                    " " +
                                    prestation?.beneficiaire?.nom
                                  }`}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Famille de soins: </strong>

                            <span className="crud-form-control">
                              {`${prestation?.familledesoin?.nom}`}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Type de soins: </strong>

                            <span className="crud-form-control">
                              {`${prestation?.typedesoin?.nom}`}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Désignation: </strong>

                            <span className="crud-form-control">
                              {`${prestation?.designation}`}
                            </span>
                          </div>
                        </div>
                        {/* <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Type de prestation: </strong>

                            <span className="crud-form-control">
                              {`${prestation?.type_prestation?.libelle}`}
                            </span>
                          </div>
                        </div> */}
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="name">Coût: </strong>
                            <span className="crud-form-control">
                              {/* {prestation?.montant} */}
                              {formatMoney(parseInt(prestation?.montant))}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="specialite">Part Ipm: </strong>
                            <span className="crud-form-control">
                              {/* {prestation?.montant_rembourse} */}
                              {formatMoney(
                                parseInt(prestation?.montant_rembourse)
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="specialite">
                              Part Participant:{" "}
                            </strong>
                            <span className="crud-form-control">
                              {/* {prestation?.montant_retenu} */}
                              {formatMoney(
                                parseInt(prestation?.montant_retenu)
                              )}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Fournisseurs: </strong>
                            <span className="crud-form-control">
                              {row?.prestataire?.raison_sociale ||
                                row?.prestataire?.prenom +
                                  " " +
                                  row?.prestataire?.nom}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-divider"></div>
                  </>
                ))}
              </>
            ) : (
              <Message
                showIcon
                type="warning"
                title="Aucune prestation disponible."
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  let optionFilter = {
    false: "Ouvert",
    true: "Clôturé",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="export-container m-t-50">
          <div
            className="export-btn-container"
            style={{ justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-primary export-btn"
              onClick={() => {
                setIsClicked(true);
              }}
              // data-toggle="modal"
              // data-target=".csv-beneficiaire-modal"
            >
              <IconlyPack.Scan set="light" primaryColor="#02A69C" />
              &nbsp;
              <span className="export-btn-label">Scan Qrcode</span>
            </button>
          </div>
        </div>
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isLoading && <FicheValideSkeleton />}
            {!isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={fichevalidbyprestataire}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                  expandableRow={isExpandableRow}
                  expandComponent={expandComponent}
                >
                  <TableHeaderColumn
                    dataField="id"
                    width="50px"
                    isKey={true}
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    hidden
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    ID
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // dataAlign="center"
                    filter={{
                      type: "TextFilter",
                      defaultValue: location?.state?.item?.id
                        ? String(
                            location?.state?.item?.beneficiaire?.prenom +
                              " " +
                              location?.state?.item?.beneficiaire?.nom
                          )
                        : "",
                    }}
                    expandable={false}
                    filterValue={nameFilter}
                    width="200"
                    dataField="beneficiaire"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Adhérent
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    // dataAlign="center"
                    width="150"
                    dataField="telephone"
                    dataFormat={(row, cell) => {
                      return cell.beneficiaire.telephone;
                    }}
                  >
                    Téléphone
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    // dataAlign="center"
                    width="150"
                    dataField="created_at"
                    dataFormat={(row, cell) => {
                      return moment(cell.created_at).format("DD/MM/YYYY");
                    }}
                  >
                    Date de création
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "SelectFilter",
                      options: optionFilter,
                      selectText: "Choisir",
                      condition: "eq",
                    }}
                    dataAlign="center"
                    width="150"
                    dataField="used"
                    dataFormat={stateFormatter}
                  >
                    Etat
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    expandable={false}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          {/* Modal */}
          <div
            className="modal fade easypm-modal alim-soin-modal"
            id="CsvModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="CsvModalItemLabel"
            aria-hidden="true"
          >
            <AlimentSoinModal ficheId={fiche?.id} />
          </div>

          {/* Modal show */}
          <div
            className="modal fade easypm-modal show-fiche-file-modal"
            id="CsvModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="CsvModalItemLabel"
            aria-hidden="true"
          >
            <ShowFicheFileModal fiche={fiche} />
          </div>

          <div
            className="modal fade easypm-modal show-fiche-modal"
            id="CsvModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="CsvModalItemLabel"
            aria-hidden="true"
          >
            <ShowFicheModal />
          </div>

          {/* Modal Qr Code */}
          {isClicked && (
            <div
              className="modal fade easypm-modal qr-code-modal"
              id="DeleteModalItem"
              tabIndex="-1"
              aria-labelledby="DeleteModalItemLabel"
              aria-hidden="true"
              data-backdrop="static"
            >
              <QrCodeModal />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FicheValideTable;
