import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
import GestionDevisTable from "../../../Tables/Assurance/GestionDevisTable/GestinDevisTable";
import GestionDevisFicheDeSoin from "../../../Tables/Assurance/GestionDevisTable/GestionDevisFicheDeSoin";
import DetailsDevis from "../../../Tables/Assurance/GestionDevisTable/DetailsDevis";
import DevisFile from "../../../Tables/Assurance/GestionDevisTable/DevisFile";

var GestionDevisAssurance = () => {
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Devis</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <GestionDevisTable />
                </div>
                <div
                  class="modal fade"
                  id="DevisValidation"
                  tabindex="-1"
                  aria-labelledby="DevisValidationLabel"
                  aria-hidden="true"
                >
                  <GestionDevisFicheDeSoin />
                </div>
                <div
                  class="modal fade"
                  id="DetailsDevis"
                  tabindex="-1"
                  aria-labelledby="DetailsDevisLabel"
                  aria-hidden="true"
                >
                  <DetailsDevis />
                </div>
                <div
                  class="modal fade"
                  id="DevisFile"
                  tabindex="-1"
                  aria-labelledby="DevisFileLabel"
                  aria-hidden="true"
                >
                  <DevisFile />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionDevisAssurance;
