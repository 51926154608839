import moment from "moment";
import React from "react";
import * as yup from "yup";
import { useContext } from "react";
import { DataContext } from "../Components/store/GlobalState";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

export const formatMoney = (num) => {
  // console.log(num)
  var p = parseInt(num).toFixed(2).split(".");
  return (
    p[0]
      .split("")
      .reverse()
      .reduce(function (acc, num, i, orig) {
        return num === "-" ? acc : num + (i && !(i % 3) ? " " : "") + acc;
      }, "") + " CFA"
  );
};

export const formatMontant = (num) => {
  // console.log(num)
  var p = parseInt(num).toFixed(2).split(".");
  return p[0]
    .split("")
    .reverse()
    .reduce(function (acc, num, i, orig) {
      return num === "-" ? acc : num + (i && !(i % 3) ? " " : "") + acc;
    }, "");
};

export function useCurrentUser() {
  const { state, dispatch } = useContext(DataContext);
  const { auth, stats } = state;
  return auth.user;
}

export function getName(item) {
  if (!item) return "";
  if (item.raison_sociale) {
    return item.raison_sociale;
  }
  return `${item.prenom || "-"} ${item.nom || ""}`;
}

export function getDate(date) {
  const momentDate = moment(date);
  const now = moment();
  const beforNow = moment().subtract(1, "days");

  if (momentDate.format("L") === now.format("L")) {
    return "Aujourd'hui";
  }
  if (momentDate.format("L") === beforNow.format("L")) {
    return "Hier";
  }
  return momentDate.format("DD/MM/YYYY");
}
export function formatDate(date) {
  const momentDate = moment(date);

  return momentDate.format("DD/MM/YYYY");
}
export function getAge(date) {
  const mDate = moment(date);
  const now = moment();

  return now.diff(mDate, "y") || "0";
}

export const useFormValidation = (initalErrors, cbRules) => {
  const [errors, setErrors] = React.useState(initalErrors);
  const Schema = yup.object().shape(cbRules(yup));
  const validate = React.useCallback((data) => {
    return new Promise((resolve, reject) => {
      setErrors({});
      Schema.validate(data, { abortEarly: false })
        .then(() => {
          resolve(data);
        })
        .catch(function (err) {
          let newErrors = {};
          for (let e of err.inner) {
            newErrors[e.path] = e.errors[0];
          }
          setErrors(newErrors);
          reject(newErrors);
        });
    });
  });
  return [errors, validate, setErrors];
};

export const useFormData = (formData) => {
  const [data, setData] = React.useState(formData);
  const onDataChange = React.useCallback((fieldName, value) => {
    if (typeof fieldName === "object") {
      setData({ ...data, ...fieldName });
      return;
    }
    if (fieldName === null) {
      setData(value);
      return;
    }
    let newdata = { ...data };
    newdata[fieldName] = fieldName === "email" ? value?.trim() : value;
    setData(newdata);
  });
  return [data, onDataChange];
};
export function isPharmacien(item) {
  const { specialite } = item;
  if (specialite) {
    return specialite.nom?.toLowerCase().includes("PHARMACIE".toLowerCase());
  }
  return false;
}

export function Select({
  name,
  value,
  label,
  placeholder,
  onChange,
  error,
  items,
}) {
  return (
    <div className="form-group crud-form-group required">
      {!!label && <label htmlFor={name}>{label}</label>}
      <select
        name={name}
        className="form-control crud-form-control form-select"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        aria-label="Default select example"
      >
        <option value="">{placeholder}</option>
        {items.map((i) => (
          <option key={i.value} selected={i.value == value} value={i.value}>
            {i.label}
          </option>
        ))}
      </select>
      {!!error && (
        <div className="alert alert-danger epm-alert-danger closer m-t-10">
          {error}
        </div>
      )}
    </div>
  );
}
export function MyTextareaInput({
  name,
  value,
  label,
  placeholder,
  onChange,
  error,
}) {
  return (
    <div className="form-group crud-form-group required">
      {!!label && <label htmlFor={name}>{label}</label>}
      <textarea
        name={name}
        className="form-control crud-form-control"
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={value}
        aria-label="Default select example"
      >
        {value}
      </textarea>

      {!!error && (
        <div className="alert alert-danger epm-alert-danger closer m-t-10">
          {error}
        </div>
      )}
    </div>
  );
}
export function MyInput({
  name,
  value,
  label,
  placeholder,
  type = "text",
  className,
  onChange,
  error,
}) {
  return (
    <div className="form-group crud-form-group required">
      {!!label && <label htmlFor={name}>{label}</label>}
      <input
        name={name}
        className={className || "form-control crud-form-control"}
        placeholder={placeholder}
        type={type}
        onChange={(e) => {
          if (type === "file") {
            onChange(e.target.files[0]);
          } else {
            onChange(e.target.value);
          }
        }}
        value={value}
        aria-label="Default select example"
      />
      {!!error && (
        <div className="alert alert-danger epm-alert-danger closer m-t-10">
          {error}
        </div>
      )}
    </div>
  );
}

export function MyPhoneInput({ value, onChange, name, error, label }) {
  return (
    <div className="form-group crud-form-group required">
      {!!label && <label htmlFor={name}>{label}</label>}
      <PhoneInput
        defaultMask={".. ... .. .."}
        inputClass="form-control input-with-icon"
        country={"sn"}
        onlyCountries={["sn"]}
        inputProps={{
          name: "telephone",
          required: true,
          autoFocus: false,
        }}
        disableDropdown={true}
        enableAreaCodes={true}
        prefix="+"
        value={value}
        countryCodeEditable={false}
        onChange={onChange}
        buttonClass="drop-flag"
      />
      {!!error && (
        <div className="alert alert-danger epm-alert-danger closer m-t-10">
          {error}
        </div>
      )}
    </div>
  );
}

export function MyDatePicker({
  name,
  value,
  onChange,
  label,
  placeholder,
  error,
  ...rest
}) {
  return (
    <div className="form-group crud-form-group required">
      {!!label && <label htmlFor={name}>{label}</label>}
      <DatePicker
        className="form-control crud-form-control"
        placeholderText={placeholder}
        dateFormat="dd/MM/yyyy"
        peekNextMonth
        selected={value}
        onChange={(e) => {
          onChange(e);
        }}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        locale="fr"
        {...rest}
      />
      {!!error && (
        <div className="alert alert-danger epm-alert-danger closer m-t-10">
          {error}
        </div>
      )}
    </div>
  );
}

export function MyButton({ title, className, loading, onClick }) {
  const props = {};
  if (onClick) {
    props.onClick = (e) => {
      if (onClick) {
        e.preventDefault();
        onClick();
      }
    };
  }
  if (loading) {
    return (
      <button onClick={() => null} className={className}>
        <i className="fas fa-spin fa-spinner"></i>
      </button>
    );
  }
  return (
    <button {...props} className={className}>
      {title}
    </button>
  );
}

export function Status({ etat, mh = 15, isRem = false }) {
  if (!etat) return null;
  return (
    <span
      style={{
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 15,
        marginLeft: mh,
        marginRight: mh,
        backgroundColor: getBackground(etat, isRem),
      }}
    >
      <strong style={{ color: "#FFF", fontSize: 12 }}>
        {getEtat(etat, isRem)}
      </strong>
    </span>
  );
}
export function StatusActive({ etat, mh = 15 }) {
  return (
    <span
      style={{
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 15,
        marginLeft: mh,
        marginRight: mh,
        backgroundColor: getBackgroundActive(etat),
      }}
    >
      <strong style={{ color: "#FFF", fontSize: 10, textAlign: "center" }}>
        {etat ? "validé(e)" : "non validé(e)"}
      </strong>
    </span>
  );
}
export const ETAT = [
  { value: "new", label: "Nouveau", backgroundColor: "#dfe6e9" },
  { value: "en_cours", label: "En cours", backgroundColor: "#ffbe76" },
  { value: "validee", label: "Validé", backgroundColor: "#6ab04c" },
  { value: "traite", label: "Traité", backgroundColor: "#6ab04c" },
  { value: "envoye", label: "Nouveau", backgroundColor: "#dfe6e9" },
  { value: "valide", label: "Validé", backgroundColor: "#6ab04c" },
  { value: "validee", label: "Validé", backgroundColor: "#6ab04c" },
  { value: "regle", label: "Réglé", backgroundColor: "#6ab04c" },
  { value: "rejete", label: "Rejeté", backgroundColor: "#F00" },
  { value: "refuse", label: "Refusé", backgroundColor: "#F00" },
  { value: "archive", label: "Archivé", backgroundColor: "#E2D0A8" },
  { value: "suspendu", label: "Suspendu", backgroundColor: "yellow" },
];
export const ETAT_RMBOURSEMENT = [
  { value: "envoye", label: "En cours", backgroundColor: "#dfe6e9" },
  { value: "valide", label: "Validé", backgroundColor: "#6ab04c" },
  { value: "regle", label: "Réglé", backgroundColor: "#6ab04c" },
  { value: "rejete", label: "Rejeté", backgroundColor: "#F00" },
  { value: "en_attente", label: "En attente", backgroundColor: "yellow" },
  { value: "traite", label: "Traité", backgroundColor: "#0F0" },
  { value: "archive", label: "Historique", backgroundColor: "#E2D0A8" },
  { value: "new", label: "Nouveau", backgroundColor: "#dfe6e9" },
];

export const etat_remboursement_obj = {
  envoye: "En cours",
  valide: "Validé",
  regle: "Réglé",
  rejete: "Rejeté",
  archive: "Historique",
  en_attente: "En attente",
  new: "Nouveau",
  traite: "Traité",
};
export function getEtat(etat, isRem = false) {
  if (isRem) {
    return ETAT_RMBOURSEMENT?.find((f) => f?.value === etat)?.label;
  }
  return ETAT.find((f) => f?.value === etat).label;
}
export function getBackground(etat, isRem = false) {
  if (isRem) {
    return ETAT_RMBOURSEMENT?.find((f) => f?.value === etat)?.backgroundColor;
  }
  return ETAT?.find((f) => f?.value === etat)?.backgroundColor;
}
export function getBackgroundActive(etat) {
  if (etat) {
    return ETAT[2]?.backgroundColor;
  }
  return ETAT[1]?.backgroundColor;
}

export function createMarkup(text, showDots, numberSlice) {
  return {
    __html: `${numberSlice ? text.slice(0, numberSlice) : text}${
      showDots === true ? "..." : ""
    }`,
  };
}
