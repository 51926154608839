import React, { Component, useContext } from "react";
import "./navbarContent.css";
import IpmNavBarContent from "./IpmNavBarContent";
import EntrepriseNavBarContent from "./EntrepriseNavBarContent";
import PrestataireNavBarContent from "./PrestataireNavBarContent";
import IpmAssuranceNavBar from "./IpmAssuranceNavBar";
import NavbarAssureur from "../Assurance/DashboardAssureur/NavbarAssureur/NavbarAssureur";
import AdminNavBarContent from "./AdminNavBarContent";
import NavbarBeneficiaire from "../Beneficiaire/DashboardBeneficiaire/NavbarBeneficiaire/NavbarBeneficiaire";
import { DataContext } from "../../store/GlobalState";

function NavbarContent() {
  const { state } = useContext(DataContext);
  const { auth } = state;
  const { user } = auth;
  return (
    <div className="hoverable">
      <ul className="nav nav-items-custom flex-column">
        {user?.user_type === "assurance_sante" ||
        user?.user_type === "ipm" ||
        user?.user_type === "admin_ipm" ? (
          <IpmNavBarContent />
        ) : (
          ""
        )}
        {user?.user_type === "entreprise" && <EntrepriseNavBarContent />}
        {user?.user_type === "prestataire" && <PrestataireNavBarContent />}
        {user?.user_type === "assureur" && <NavbarAssureur />}

        {user?.user_type === "beneficiaire" && <NavbarBeneficiaire />}

        {(user?.user_type === "super_ipm" || user?.is_superuser) && (
          <AdminNavBarContent />
        )}
      </ul>
    </div>
  );
}

export default NavbarContent;
