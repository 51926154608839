import { entreprise } from './Endpoints'
import axios from 'axios'

export default {
    async getListEntreprises(config) {
        const response = await axios.get(entreprise.onGetOrPostEntreprise(), config);
        // console.log(response)
        return response.data;
    },
    async postCreateEntreprise(data, config) {
        const response = await axios.post(entreprise.onGetOrPostEntreprise(), data, config);
        return response.data;
    },
    async editEntreprise(id, data, config) {
        const response = await axios.put(entreprise.onEditEntreprise(id), data, config);
        return response.data;
    },
    async deleteEntreprise(id, config) {
        const response = await axios.delete(entreprise.onDeleteEntreprise(id), config);
        return response;
    }, 
}
