import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import ShowAdminIPMModal from "../../Modals/ShowAdminIPMModal";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../store/GlobalState";
import UseEditAssuranceAdminForm from "../../Backend/IPM/Parametres/Requests/UseEditAssuranceAdminForm";
import CRUDAdminIPMModal from "../../Modals/CRUDAdminIPMModal";
import RegenerePwdModal from "../../Modals/RegenerePwdModal";

var AdminsTable = () => {
  var [adminInfos, setAdminInfos] = React.useState();
  const userID = sessionStorage.getItem("userID");
  const { state, dispatch } = useContext(DataContext);

  const { auth, admin_ipms } = state;

  const {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    setValue,
    reset,
  } = UseEditAssuranceAdminForm();

  useEffect(() => {
    if (isSuccessfullySubmitted) {
      window.$(".edit-admin-modal").modal("hide");

      setTimeout(() => {
        setIsSuccessfullySubmitted(false);
      }, 5000);
    }

    if (isFormRequestError) {
      setIsSuccessfullySubmitted(false);

      setIsFormRequestError(true);
      setTimeout(() => {
        setIsFormRequestError(false);
      }, 5000);
    }

    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
    // console.log(register)
    // reset(adminInfos && adminInfos)
  }, [
    admin_ipms,
    isSuccessfullySubmitted,
    isFormRequestError,
    formState,
    clearErrors,
    setIsFormRequestError,
    setIsSuccessfullySubmitted,
  ]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button
          onClick={() => onGetAdminInfos(row)}
          className="action-btn show-btn"
          data-toggle="modal"
          data-target=".show-admin-ipm-modal"
          title="Details"
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
          />
        </button>
        <button
          className="action-btn edit-btn"
          onClick={() => onGetItem(row)}
          data-toggle="modal"
          data-target=".edit-admin-modal"
          title="Modification"
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
        <button
          className="action-btn delete-btn"
          onClick={() => onGetItem(row)}
          data-toggle="modal"
          data-target=".regenere-pwd-modal"
          title="Renvoi de mot de passe"
        >
          <IconlyPack.Password
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
      </div>
    );
  };

  const onGetItem = (item) => {
    dispatch({ type: "EDIT_ADMIN_IPM", payload: item });
  };

  var onGetAdminInfos = (item) => {
    setAdminInfos(item);
    window.sessionStorage.setItem("adminInfos", JSON.stringify(item));
  };

  const nameFormatter = (cell, row) => {
    return (
      <span
        className="linkable"
        onClick={() => onGetAdminInfos(row)}
        data-toggle="modal"
        data-target=".show-admin-ipm-modal"
      >
        {" "}
        {row.prenom + " " + cell}{" "}
      </span>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            <div className="table-container">
              <BootstrapTable
                data={admin_ipms && admin_ipms}
                striped={true}
                hover={true}
                condensed={true}
                multiColumnSort={2}
                options={options}
                pagination
                search={false}
                version="4"
                bordered={true}
              >
                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  dataField="id"
                  isKey={true}
                  hidden={true}
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  dataField="nom"
                  // width="250"
                  dataFormat={(cell, row) => nameFormatter(cell, row)}
                >
                  Prénom & Nom
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  // width="150"
                  dataField="email"
                >
                  Email
                </TableHeaderColumn>

                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  // width="150"
                  dataField="telephone"
                >
                  Téléphone
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  // width="150"
                  dataField="adresse"
                >
                  Adresse
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                    padding: 15,
                  }}
                  //width="100"
                  dataField="id"
                  dataFormat={(cell, row) => actionFormatter(cell, row)}
                >
                  Action
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade easypm-modal show-admin-ipm-modal"
        id="ShowModalItem"
        tabIndex="-1"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
      >
        <ShowAdminIPMModal adminInfos={adminInfos} />
      </div>

      {/* Modal */}
      <div
        className="modal fade easypm-modal edit-admin-modal"
        id="EditModalItem"
        tabIndex="-1"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
      >
        <CRUDAdminIPMModal
          actionType={"edit"}
          type={auth?.user?.user_type}
          adminInfos={adminInfos && adminInfos}
          register={register}
          fromIpmID={userID}
          onSubmit={onSubmit}
          formState={formState}
          isSuccessfullySubmitted={isSuccessfullySubmitted}
          setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
          setValue={setValue}
          reset={reset}
        />
      </div>
      {/* Modal regeneration de mot de passe*/}
      <div
        className="modal fade easypm-modal regenere-pwd-modal"
        id="CsvModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="PwpModalItemLabel"
        aria-hidden="true"
      >
        <RegenerePwdModal />
      </div>
    </div>
  );
};

export default AdminsTable;
