const GestionPrestataireAdminTableData = [
    {
        id: 1,
        nom: 'Hopital Aristide le Dantec',
        specialite: 'Hopital',
        date: '20/10/2022',
        telephone: '33 800 0000',
        agreement: 'OUI'
    },
    {
        id: 2,
        nom: 'Hopital Aristide le Dantec',
        specialite: 'Hopital',
        date: '20/10/2022',
        telephone: '33 800 0000',
        agreement: 'OUI'
    },
    {
        id: 3,
        nom: 'Hopital Aristide le Dantec',
        specialite: 'Hopital',
        date: '20/10/2022',
        telephone: '33 800 0000',
        agreement: 'OUI'
    },
    {
        id: 4,
        nom: 'Hopital Aristide le Dantec',
        specialite: 'Hopital',
        date: '20/10/2022',
        telephone: '33 800 0000',
        agreement: 'OUI'
    },
];

export default GestionPrestataireAdminTableData;
