import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import ShowExclusion from "../../../../Modals/ShowExclusion";
import Exclusion from "../../../../Services/Exclusion";
import { DataContext } from "../../../../store/GlobalState";
import { getDataByID } from "../../../../utils/fetchData";
import AjouterExclusion from "./AjouterExclusion";
import ExclusionTableData from "./ExclusionTableData";
import ExclusionTableSkeleton from "./ExclusionTableSkeleton";
import ModifierExclusion from "./ModifierExclusion";

var ExclusionTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const { assureur_exclusions, auth } = state;
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };
  const onDelete = (id) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cette exclusion ?",
      customClass: {
        confirmButton: "btn-success",
        cancelButton: "btn-danger",
      },
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        var deleteRequest = Exclusion.deleteExclusion(id, requestConfig);
        return deleteRequest
          .then((response) => {
            if (auth.user?.admin_type === "assureur") {
              getDataByID("exclusionbyipm", auth?.user?.parent, auth?.token)
                .then((res) => {
                  // console.log("notif", res);
                  dispatch({
                    type: "EXCLUSION_ASSUREUR",
                    payload: {
                      isLoading: false,
                      data: res.results.sort((a, b) => b?.id - a?.id),
                    },
                  });
                })
                .catch((error) => console.log("error", error?.response));
            } else {
              getDataByID("exclusionbyipm", auth?.user?.id, auth?.token)
                .then((res) => {
                  // console.log("notif", res);
                  dispatch({
                    type: "EXCLUSION_ASSUREUR",
                    payload: {
                      isLoading: false,
                      data: res.results.sort((a, b) => b?.id - a?.id),
                    },
                  });
                })
                .catch((error) => console.log("error", error?.response));
            }
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        toast.success("Exclusion supprimée avec succès.");
      }
    });
  };

  const nameFormater = (cell, row) => {
    return (
      <div className="nameFormatter">
        <span className="name">{cell?.raison_sociale}</span>
      </div>
    );
  };

  const typeFormatter = (cell, row) => {
    return (
      <div className="nameFormatter">
        <span className="name">{cell?.nom}</span> <br />
        <span className="entreprise">{row?.familledesoin?.nom}</span>
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button
          onClick={() => onGetItem(row)}
          className="action-btn show-btn"
          data-toggle="modal"
          data-target=".show-exclusion-modal"
          title="Details"
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
          />
        </button>
        <button
          className="action-btn edit-btn"
          onClick={() => onGetItem(row)}
          data-toggle="modal"
          data-target=".edit-exclusion-modal"
          title="Modification"
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
        <button className="action-btn delete-btn">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            onClick={() => onDelete(row?.id)}
          />
        </button>
      </div>
    );
  };

  const onGetItem = (item) => {
    dispatch({ type: "EDIT_EXCLUSION", payload: item });
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {assureur_exclusions?.isLoading && <ExclusionTableSkeleton />}
            {!assureur_exclusions?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={assureur_exclusions?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="designation"
                  >
                    Désignation
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataFormat={(cell, row) => typeFormatter(cell, row)}
                    dataField="typedesoin"
                  >
                    Type de soins
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="center"
                    // width="140"
                    dataField="ipm"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    IPM
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="center"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            class="modal fade ajout-exclusion-modal"
            id="AjouterExclusion"
            tabindex="-1"
            data-backdrop="static"
            aria-labelledby="AjouterExclusionLabel"
            aria-hidden="true"
          >
            <AjouterExclusion />
          </div>
          <div
            className="modal fade easypm-modal show-exclusion-modal"
            id="ShowExclusionModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="ShowExclusionModalItemLabel"
            aria-hidden="true"
          >
            <ShowExclusion />
          </div>
          <div
            className="modal fade easypm-modal edit-exclusion-modal"
            id="EditExclusionModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="EditExclusionModalItemLabel"
            aria-hidden="true"
          >
            <ModifierExclusion />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExclusionTable;
