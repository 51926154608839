import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getName,
  isPharmacien,
  Select,
  useFormData,
  MyDatePicker,
  useFormValidation,
  MyInput,
  MyButton,
} from "../../../../utils/utils";
import { useMutation } from "../../../../Http/http/mutation";
import { fichedesoin, editfichedesoin } from "../../../../Http/http/api";

const GenererFicheSoinModal = ({
  prestataires,
  ayant_droits,
  user,
  onAdd,
  item,
}) => {
  const mutation = useMutation(fichedesoin);
  const mutation2 = useMutation((data) => editfichedesoin(data.Id, data));
  const [data, onChange] = useFormData({
    prestataire: "",
    beneficiaire: "",
    is: false,
    ordonnance_file: "",
    date: moment().toDate(),
  });
  React.useEffect(() => {
    if (item) {
      const changed = {
        prestataire: String(item.prestataire?.id),
        beneficiaire: String(item?.ayant_droit ? item.ayant_droit?.id : 0),
        is: false,
        ordonnance_file: "",
        date: moment(item.date).toDate(),
      };
      onChange(changed);
    }
  }, [item]);
  const [errors, validate] = useFormValidation({}, (Yup) => ({
    prestataire: Yup.string().required().nullable().label("Prestataire"),
    ordonnance_file: Yup.string()
      .label("Ordonnance")
      .test(
        "ordonnance_file",
        "Ordonnance est un champ obligatoire",
        function (value) {
          if (this.parent.is) return !!value;
          return true;
        }
      ),
    beneficiaire: Yup.string()
      .label("Beneficiaire")
      .test(
        "beneficiaire",
        "Beneficiaire est un champ obligatoire",
        function (value) {
          return !!value;
        }
      ),
    date: Yup.string().required().label("Date"),
  }));
  React.useEffect(() => {
    setTimeout(() => {
      if (data.prestataire) {
        const obj = prestataires.find((f) => f.id == data.prestataire);
        if (obj) {
          onChange({
            is: isPharmacien(obj),
            ordonnance_file: "",
          });
        }
      } else {
        onChange({
          is: false,
          ordonnance_file: "",
        });
      }
    }, 500);
  }, [data.prestataire, prestataires]);
  const onSubmit = React.useCallback(async () => {
    validate(data)
      .then(async () => {
        try {
          const soin = {
            etat: "new",
            beneficiaire: user.id,
            prestataire: data.prestataire,
            date: moment(data.date).format("YYYY-MM-DD"),
          };
          if (data.ordonnance_file) {
            soin.ordonnance_file = data.ordonnance_file;
          }
          if (data.beneficiaire && data.beneficiaire != "0") {
            soin.ayant_droit = data.beneficiaire;
          }
          console.log("data", soin);
          let res;
          if (item) {
            soin.Id = item.id;
            res = await mutation2.exec(soin);
          } else {
            res = await mutation.exec(soin);
          }
          if (res.message) {
            toast.error(res.message);
          } else {
            toast.success(
              `Fiche de soin ${item ? "modifiée" : "ajoutée"} avec succès`
            );
            reset();
            window.$("#DemandeFicheSoinsModal").modal("hide");
            onAdd();
          }
        } catch (err) {
          console.log(err);
          toast.error(`Error: ${err.message}`);
          console.log("error", err.response);
        }
      })
      .catch((ex) => {});
  }, [data, user, item]);
  const reset = () => {
    onChange({
      prestataire: "",
      beneficiaire: "",
      ordonnance_file: "",
    });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="DemandeFicheSoinsModal"
          >
            {item ? "Modifier" : "Demander"} une fiche de soins
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => {
              onAdd(false);
              reset();
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <Select
                    name="beneficiaire"
                    error={errors.beneficiaire}
                    value={data.beneficiaire}
                    onChange={(v) => onChange("beneficiaire", v)}
                    label="Bénéficiaires"
                    placeholder="Choisir"
                    items={[
                      {
                        label: `${getName(user)} (Vous)`,
                        value: "0",
                      },
                      ...ayant_droits
                        .filter((i) => i.is_active === true)
                        .map((i) => ({
                          label: getName(i),
                          value: String(i.id),
                        })),
                    ]}
                  />
                </div>
                <div className="col-md-6 crud-col-item">
                  <Select
                    name="prestataire"
                    value={data.prestataire}
                    error={errors.prestataire}
                    onChange={(v) => onChange("prestataire", v)}
                    label="Prestataires"
                    placeholder="Choisir un prestataire"
                    items={prestataires
                      .filter((i) => i.agreement)
                      .map((i) => ({
                        label: getName(i),
                        value: String(i.id),
                      }))}
                  />
                </div>
                <div className="col-md-6 crud-col-item">
                  <MyDatePicker
                    value={data.date}
                    name="date"
                    onChange={(v) => onChange("date", v)}
                    label="Date"
                    placeholder="Choisir une date"
                    error={errors.date}
                  />
                </div>
                {!!data.is && (
                  <div className="col-md-12 crud-col-item">
                    <MyInput
                      name="ordonnance_file"
                      error={errors.ordonnance_file}
                      type="file"
                      onChange={(v) => onChange("ordonnance_file", v)}
                      label="Ordonnance"
                      placeholder="Importer une ordonnance"
                    />
                  </div>
                )}
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    onClick={() => {
                      onAdd(false);
                      reset();
                    }}
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  <MyButton
                    title="Enregistrer"
                    loading={mutation.loading || mutation2.loading}
                    onClick={onSubmit}
                    className="btn  crud-submit-btn-assurance"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenererFicheSoinModal;
