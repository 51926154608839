import { devis } from './Endpoints'
import axios from 'axios'

export default {
    async getListDevis(config) {
        const response = await axios.get(devis.onGetOrPostDevis(), config);
        // console.log(response)
        return response.data;
    },
    async postCreateDevis(data, config) {
        const response = await axios.post(devis.onGetOrPostDevis(), data, config);
        return response.data;
    },
    async putDevis(id, data, config) {
        const response = await axios.put(devis.onEditDevis(id), data, config);
        return response.data;
    },
    async deleteDevis(id, config) {
        const response = await axios.delete(devis.onDeleteDevis(id), config);
        return response;
    },

}