import React, { useEffect } from "react";
// import * as IconlyPack from 'react-iconly';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

var AddFamilyModal = (props) => {
  var [startDate, setStartDate] = React.useState(new Date());
  const [isValidDate, setIsValiddate] = React.useState(true);
  useEffect(() => {
    props.setValue("beneficiaire", props?.beneficiaireID);
    if (props?.type === "enfant") {
      props.register("statut");
      props.setValue("statut", "enfant");
      props.register("nom");
      props.setValue("nom", props?.beneficiaire?.nom);
    }
    if (props?.type === "epouse") {
      console.log("here");
      props.register("statut");
      props.setValue("statut", "époux(se)");
      if (props?.beneficiaire?.sexe === "femme") {
        props.register("sexe");
        props.setValue("sexe", "homme");
      } else {
        props.register("sexe");
        props.setValue("sexe", "femme");
      }
    }
    props.register("date_naissance");
    props.setValue("date_naissance", moment(startDate).format("YYYY-MM-DD"));
    // console.log('type', props?.beneficiaire)
  }, [props?.type, props?.beneficiaireID]);

  useEffect(() => {
    if (props.formState.isSubmitted && !props.isSuccessfullySubmitted) {
      // setStartDate(new Date());
      // props.reset()

      setTimeout(() => {
        setStartDate(new Date());
      }, 1000);
    }
  }, [props?.isSuccessfullySubmitted, props.formState.isSubmitted]);

  const handleDate = (date) => {
    if (
      props?.type === "enfant" &&
      moment(props?.beneficiaire?.date_de_naissance) >= moment(date)
    ) {
      setIsValiddate(false);
      setTimeout(() => {
        setIsValiddate(true);
      }, 5000);
    } else {
      setStartDate(date);
      props.setValue("date_naissance", moment(date).format("YYYY-MM-DD"));
    }
  };

  const changeAvatar = (e) => {
    const file = e.target.files[0];
    props.register("avatar");
    props.setValue("avatar", file);
  };

  const handleReset = async () => {
    setStartDate(new Date());
    props.reset();
    props.setValue("nom", "");
    if (props?.type === "epouse") {
      console.log("here end");
      props.register("statut");
      props.setValue("statut", "époux(se)");
    }
    props.register("date_naissance");
    props.setValue("date_naissance", moment(new Date()).format("YYYY-MM-DD"));
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Ajouter{" "}
            {props?.type === "enfant" ? "un enfant" : " un/une conjoint(e)"}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => handleReset()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={props.onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="ad_first_name">Prénom</label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      className="form-control crud-form-control"
                      id="ad_first_name"
                      {...props.register("prenom")}
                    />
                    {props.formState.errors &&
                      props.formState.errors.prenom && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.prenom?.message}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="ad_last_name">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      className="form-control crud-form-control"
                      id="ad_last_name"
                      // defaultValue={props?.type === "enfant" ? props?.beneficiaire?.nom : ""}
                      {...props.register("nom")}
                    />
                    {props.formState.errors && props.formState.errors.nom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {props.formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="ad_date_naissance">Date de naissance</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      minDate={
                        props?.type === "enfant" &&
                        new Date(props?.beneficiaire?.date_de_naissance)
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {props.formState.errors &&
                      props.formState.errors.date_naissance && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.date_naissance?.message}
                        </div>
                      )}
                    {!isValidDate && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        Attention! Un enfant ne peut être plus agé que son
                        parent.
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 crud-col-item input-radio-container">
                  <label htmlFor="ad_genre">Genre</label>
                  <div className="input-radio-content">
                    {props?.type !== undefined &&
                    props?.type !== "enfant" &&
                    props?.beneficiaire?.sexe !== undefined &&
                    props?.beneficiaire?.sexe === "homme" ? (
                      <div className="input-radio-item">
                        <input
                          type="radio"
                          name="sexe"
                          className="crud-form-control input-radio-form-control"
                          id="genre_2"
                          value={"femme" || ""}
                          checked={true || ""}
                          {...props.register("sexe")}
                        />
                        &nbsp;
                        <span className="input-radio-item-label">Féminin</span>
                      </div>
                    ) : props?.type !== undefined &&
                      props?.type !== "enfant" &&
                      props?.beneficiaire?.sexe !== undefined &&
                      props?.beneficiaire?.sexe === "femme" ? (
                      <div className="input-radio-item">
                        <input
                          type="radio"
                          name="sexe"
                          className="crud-form-control input-radio-form-control"
                          id="ad_genre"
                          value="homme"
                          checked={true}
                          {...props.register("sexe")}
                        />
                        &nbsp;
                        <span className="input-radio-item-label">Masculin</span>
                      </div>
                    ) : (
                      <>
                        <div className="input-radio-item">
                          <input
                            type="radio"
                            name="sexe"
                            className="crud-form-control input-radio-form-control"
                            id="ad_genre"
                            value="homme"
                            {...props.register("sexe")}
                          />
                          &nbsp;
                          <span className="input-radio-item-label">
                            Masculin
                          </span>
                        </div>
                        <div className="input-radio-item">
                          <input
                            type="radio"
                            name="sexe"
                            className="crud-form-control input-radio-form-control"
                            id="genre_2"
                            value="femme"
                            {...props.register("sexe")}
                          />
                          &nbsp;
                          <span className="input-radio-item-label">
                            Féminin
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  {props.formState.errors && props.formState.errors.sexe && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {props.formState.errors.sexe?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item mb-5">
                  <div
                    className="form-group crud-form-group custom-file"
                    style={{ marginBottom: "13%" }}
                  >
                    <label htmlFor="file_up">Photo</label>
                    <input
                      type="file"
                      name="avatar"
                      id="file_up"
                      accept="image/*"
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      onChange={changeAvatar}
                    />
                  </div>
                </div>
                <div className="col-md-6 crud-col-item mb-5">
                  <div
                    className="form-group crud-form-group custom-file"
                    style={{ marginBottom: "13%" }}
                  >
                    <label htmlFor="certificat_marriage">
                      Certificat de{" "}
                      {props?.type === "epouse" ? "mariage" : "naissance"}
                    </label>
                    <input
                      type="file"
                      placeholder={`Importer le certifcat de ${
                        props?.type === "epouse" ? "mariage" : "naissance"
                      }`}
                      name="certificat"
                      // onChange={handleFile}
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      id="certificat"
                      {...props.register("certificat")}
                    />
                    {props.formState.errors &&
                      props.formState.errors.certificat && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.certificat.message}
                        </div>
                      )}
                  </div>
                </div>
                <input
                  type="hidden"
                  name="beneficiaire"
                  defaultValue={props?.beneficiaireID}
                  {...props.register("beneficiaire")}
                />

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!props.isSuccessfullySubmitted && (
                    <button
                      type="submit"
                      className="btn btn-primary crud-submit-btn"
                    >
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFamilyModal;
