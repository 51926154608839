import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { DataContext } from "../store/GlobalState";
import SuperAdmin from "../Services/SuperAdmin";
// import superAdmin from '../../../../Services/SuperAdmin'
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import { updateItem } from "../store/Actions";
import { toast } from "react-toastify";

function EditSuperAdminModal() {
  const { register, handleSubmit, formState, reset, setValue } = useForm();
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, admin_ipm, admin_ipms } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const [phone, setPhone] = React.useState("");

  const handleSetPhone = (val) => {
    console.log("ee");
    setPhone(val);
    register("telephone", {
      required: {
        value: true,
        message: "Ce champ est obligatoire.",
      },
      pattern: {
        value: new RegExp(/^((\+)221|0)[1-9](\d{2}){4}$/),
        message: "Veuillez vérifier le format du numéro de téléphone",
      },
    });
    setValue("telephone", "+" + val);
  };

  React.useEffect(() => {
    if (admin_ipm?.id) {
      setPhone(
        admin_ipm?.telephone.includes("+221")
          ? admin_ipm?.telephone
          : "+221" + admin_ipm?.telephone
      );
    }
    // console.log(entreprise)
  }, [admin_ipm?.id]);

  const onSubmit = async (data, e) => {
    console.log("data", data);
    setIsLoading(true);
    if (!data?.email) {
      data.email = admin_ipm.email;
    }

    if (!data?.prenom) {
      data.prenom = admin_ipm.prenom;
    }

    if (!data?.nom) {
      data.nom = admin_ipm.nom;
    }

    if (!data?.telephone) {
      data.telephone = admin_ipm.telephone;
    }

    if (!data?.adresse) {
      data.adresse = admin_ipm.adresse;
    }

    var admin_ipmRequest = SuperAdmin.putSuperAdmin(
      admin_ipm.id,
      data,
      requestConfig
    );

    await admin_ipmRequest
      .then((response) => {
        var admin_ipmInfos = response;
        console.log("admin_ipm", admin_ipmInfos);
        dispatch(
          updateItem(admin_ipms, admin_ipm.id, admin_ipmInfos, "LIST_ADMIN_IPM")
        );
        setIsLoading(false);
        e.target.reset();
        window.$(".edit-super-admin-modal").modal("hide");
        dispatch({ type: "EDIT_ADMIN_IPM", payload: {} });
        setPhone("");
        toast.success("admin modifié avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
      });
  };

  const handleReset = async () => {
    reset();
    dispatch({ type: "EDIT_ADMIN_IPM", payload: {} });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddIPMModal">
            Modifier une IPM
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-ipm-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="name">Raison Sociale</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="prenom"
                      defaultValue={admin_ipm?.prenom || ""}
                      {...register("prenom")}
                      className="form-control crud-form-control"
                      id="name"
                    />
                    {formState.errors && formState.errors.prenom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="nom">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      {...register("nom")}
                      defaultValue={admin_ipm?.nom || ""}
                      className="form-control crud-form-control"
                      id="nom"
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="form-control crud-form-control"
                      id="email"
                      {...register("email")}
                      defaultValue={admin_ipm?.email || ""}
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      type="text"
                      placeholder="Adresse"
                      name="adresse"
                      {...register("adresse")}
                      defaultValue={admin_ipm?.adresse || ""}
                      className="form-control crud-form-control"
                      id="adresse"
                    />
                    {formState.errors && formState.errors.adresse && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.adresse?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="telephone">Téléphone</label>
                    {/* <input
                          type="text"
                          placeholder="Téléphone"
                          name="telephone"
                          {...register('telephone')}
                          defaultValue={ipm?.telephone || ''}
                          className="form-control crud-form-control"
                          id="telephone"
                        />
                        <div className="input-infos-container">
                          <IconlyPack.Danger
                            set="light"
                            primaryColor="gray"
                            className="alerty-icon"
                          />
                          &nbsp;
                          <span className="input-infos">
                            Le numéro de téléphone doit être au format +221780158171
                          </span>
                        </div> */}
                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      inputClass="form-control crud-form-control"
                      country={"sn"}
                      onlyCountries={["sn"]}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    {formState.errors && formState.errors.telephone && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.telephone?.message}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                      <button
                        className="btn btn-default cancelleb-btn"
                        data-dismiss="modal"
                        onClick={() => handleReset()}
                      >
                        Retour
                      </button>
                      {isLoading && (
                        <button className="btn btn-primary crud-submit-btn">
                          En cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                      {!isLoading && (
                        <button
                          type="submit"
                          className="btn btn-primary crud-submit-btn"
                        >
                          Modifier
                        </button>
                      )}
                    </div> */}

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  {isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSuperAdminModal;
