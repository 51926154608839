import React, { useEffect, useContext } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import * as IconlyPack from 'react-iconly';
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getDataByID, putData } from "../../../utils/fetchData";
import FicheSkeleton from "./FicheSkeleton";
import moment from "moment";
import baseUrl from "../../../../Http/backend-base-url";

function DetailFichedesoin() {
  const [userType, setUserType] = React.useState();
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);

  const { auth, ficheDeSoinsbyIpm } = state;

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [beneficiaire, setBeneficiaire] = React.useState({});
  const [fiche, setFiche] = React.useState({});

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  // console.log('props', location)
  useEffect(() => {
    setUserType(window.sessionStorage.getItem("userType"));

    if (params?.id && auth?.token && auth?.user?.id) {
      getDataByID("fichedesoinbyipm", auth?.user?.id, auth.token)
        .then((res) => {
          setFiche(res?.filter((f) => f?.id === parseInt(params?.id))[0]);
          dispatch({
            type: "ADD_FICHE_DE_SOIN_BY_IPM",
            payload: res.sort((a, b) => b.id - a.id),
          });
          setIsPageLoading(false);
        })
        .catch((e) => {
          console.log("e", e);
        });
    }
  }, [params?.id, auth?.token]);

  // useEffect(() => {
  //   setUserType(window.sessionStorage.getItem("userType"));

  //   if (params?.id && auth?.token && ficheDeSoinsbyIpm?.length) {

  //     if (
  //       window.sessionStorage.getItem("userType") === "ipm" ||
  //       window.sessionStorage.getItem("userType") === "assurance_sante"
  //     ) {
  //       setFiche(
  //         ficheDeSoinsbyIpm?.filter((f) => f?.id === parseInt(params?.id))[0]
  //       );

  //       setTimeout(() => {
  //         setIsPageLoading(false);
  //       }, 5000);

  //       // if (parseInt(window.sessionStorage.getItem('notifId'))) {
  //       //   handleValid('valid')
  //       // }
  //       console.log(
  //         ficheDeSoinsbyIpm?.filter((f) => f?.id === parseInt(params?.id))
  //       );
  //     }
  //   }
  // }, [auth.token, ficheDeSoinsbyIpm, params.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSuccessfullySubmitted(true);
    putData("fichedesoin/" + params?.id + "/", { etat: "validee" }, auth?.token)
      .then((res) => {
        console.log("res", res);

        if (window.sessionStorage.getItem("notifId")) {
          handleValid("valid");
        }
        toast.success("Fiche de soins validée.");
        setIsSuccessfullySubmitted(false);
        if (auth?.user?.admin_type) {
          getDataByID("fichedesoinbyipm", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_FICHE_DE_SOIN_BY_IPM",
                payload: res.sort((a, b) => a.id - b.id).reverse(),
              });
              dispatch({
                type: "FICHESOINS_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res?.sort((a, b) => b.id - a.id),
                },
              });
              if (auth?.user?.user_type === "assureur") {
                history.push(`/assurance/soins`);
              } else {
                history.push(`/ipm/gestion-des-fiches-de-soin`);
              }
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          getDataByID("fichedesoinbyipm", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_FICHE_DE_SOIN_BY_IPM",
                payload: res.sort((a, b) => a.id - b.id).reverse(),
              });
              dispatch({
                type: "FICHESOINS_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res?.sort((a, b) => b.id - a.id),
                },
              });
              if (auth?.user?.user_type === "assureur") {
                history.push(`/assurance/soins`);
              } else {
                history.push(`/ipm/gestion-des-fiches-de-soin`);
              }
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
      })
      .catch((e) => {
        setIsSuccessfullySubmitted(false);
        toast.error("Oupss! Une erreur est survenue lors de la validation.");
      });
  };

  const handleValid = (item) => {
    putData(
      "notification/" +
        parseInt(window.sessionStorage.getItem("notifId")) +
        "/",
      { valid: true },
      auth?.token
    ).then((res) => {
      console.log("res", res);
      if (userType !== "prestataire") {
        if (auth?.user?.admin_type) {
          getDataByID(
            "notificationbyipm",
            auth?.user?.parent,
            auth?.token
          ).then((resp) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        } else {
          getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
            (resp) => {
              // console.log('notif', resp)
              window.sessionStorage.removeItem("notifId");
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        }
      } else {
        if (auth?.user?.admin_type) {
          getDataByID(
            "notificationbyentreprise",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            window.sessionStorage.removeItem("notifId");
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        } else {
          getDataByID(
            "notificationbyentreprise",
            auth?.user?.id,
            auth?.token
          ).then((res) => {
            window.sessionStorage.removeItem("notifId");
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
        // history.push(`/entreprise/profil-employes/${res.beneficiaire}`);
      }
    });
    if (item === "rejeter") {
      if (auth?.user?.user_type === "assureur") {
        history.push(`/assurance/soins`);
      } else {
        history.push(`/ipm/gestion-des-fiches-de-soin`);
      }
    }
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div
            className={
              userType === "assureur"
                ? "nav-container nav-assurance-color"
                : "nav-container"
            }
          >
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Demande de fiche de soins</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="add-family-form-container">
                    {isPageLoading && <FicheSkeleton />}
                    {!isPageLoading && (
                      <div className="crud-form">
                        <div className="row crud-row">
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="name">Prénom Adhérent: </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.beneficiaire?.prenom}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="name">Nom Adhérent: </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.beneficiaire?.nom}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="specialite">
                                Date de naissance de l'adhérent:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {moment(
                                  new Date(
                                    fiche?.beneficiaire?.date_de_naissance
                                  )
                                ).format("DD/MM/YYYY")}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="ninea">
                                Situation Matriomiale de l'adhérent:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.beneficiaire?.situation_matrimoniale}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="email">
                                Email de l'adhérent:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.beneficiaire?.email}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="adresse">
                                Adresse de l'adhérent:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.beneficiaire?.adresse}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="telephone">
                                Téléphone de l'adhérent:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.beneficiaire?.telephone}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="first_name">
                                Date de début de l'adhérent:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.beneficiaire?.date_debut
                                  ? moment(
                                      new Date(fiche?.beneficiaire?.date_debut)
                                    ).format("DD/MM/YYYY")
                                  : ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="first_name">
                                Date de fin de l'adhérent:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.beneficiaire?.date_fin
                                  ? moment(
                                      new Date(fiche?.beneficiaire?.date_fin)
                                    ).format("DD/MM/YYYY")
                                  : ""}
                              </span>
                            </div>
                          </div>

                          {fiche?.prestataire?.raison_sociale && (
                            <div className="col-md-6 crud-col-item">
                              <div className="form-group crud-form-group">
                                <strong htmlFor="name">
                                  Raison sociale prestataire:{" "}
                                </strong>
                                <span className="crud-form-control fs-15 fw-500">
                                  {fiche?.prestataire?.raison_sociale}
                                </span>
                              </div>
                            </div>
                          )}

                          {fiche?.prestataire?.prenom && (
                            <div className="col-md-6 crud-col-item">
                              <div className="form-group crud-form-group">
                                <strong htmlFor="name">
                                  Prénom prestataire:{" "}
                                </strong>
                                <span className="crud-form-control fs-15 fw-500">
                                  {fiche?.prestataire?.prenom}
                                </span>
                              </div>
                            </div>
                          )}

                          {fiche?.prestataire?.nom && (
                            <div className="col-md-6 crud-col-item">
                              <div className="form-group crud-form-group">
                                <strong htmlFor="name">
                                  Nom prestataire:{" "}
                                </strong>
                                <span className="crud-form-control fs-15 fw-500">
                                  {fiche?.prestataire?.nom}
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="specialite">
                                Spécialité du prestataire:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.prestataire?.specialite}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="email">
                                Email du prestataire:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.prestataire?.email}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="adresse">
                                Adresse du prestataire:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.prestataire?.adresse}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="telephone">
                                Téléphone du prestataire:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.prestataire?.telephone}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="first_name">
                                Agréement du prestataire:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.prestataire?.agreement ? "Oui" : "Non"}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="first_name">
                                Date de début du prestataire:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.prestataire?.date_debut
                                  ? moment(
                                      new Date(fiche?.prestataire?.date_debut)
                                    ).format("DD/MM/YYYY")
                                  : ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="first_name">
                                Date de fin du prestataire:{" "}
                              </strong>
                              <span className="crud-form-control fs-15 fw-500">
                                {fiche?.prestataire?.date_fin
                                  ? moment(
                                      new Date(fiche?.prestataire?.date_fin)
                                    ).format("DD/MM/YYYY")
                                  : ""}
                              </span>
                            </div>
                          </div>

                          {/* <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="specialite">Statut: </strong>
                              {fiche?.etat_ipm === "new" && (
                                <strong className="badge badge-danger easypm-badge-danger linkable">
                                  nouveau
                                </strong>
                              )}
                              {fiche?.etat_ipm === "en_cours" && (
                                <strong className="badge badge-warning easypm-badge-warning linkable">
                                  en cours
                                </strong>
                              )}

                              {fiche?.etat_ipm === "validee" && (
                                <strong className="badge badge-success easypm-badge-success linkable">
                                  Validé
                                </strong>
                              )}
                            </div>
                          </div> */}

                          <div className="col-md-12 crud-col-item">
                            <div className="accordion" id="accordionExample">
                              {fiche?.ordonnance_file ? (
                                <>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="headingOne"
                                      style={{ textAlign: "left" }}
                                    >
                                      <h5 className="mb-0">
                                        <button
                                          className="bloc-text-profil"
                                          style={{ background: "none" }}
                                          type="button"
                                          data-toggle="collapse"
                                          data-target={`#collapse${1}`}
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          Ordonnance
                                        </button>
                                      </h5>
                                    </div>

                                    <div
                                      id={`collapse${1}`}
                                      className="collapse show"
                                      aria-labelledby="headingOne"
                                      data-parent="#accordionExample"
                                    >
                                      <div
                                        className="card-body"
                                        style={{ height: "700px" }}
                                      >
                                        <iframe
                                          className="responsive-iframe w-100"
                                          src={baseUrl + fiche?.ordonnance_file}
                                          style={{ height: "100%" }}
                                          title="Ordonnace"
                                        ></iframe>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                          {/* <div
                            className="col-md-6 offset-md-6 crud-form-action-btn-container"
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <button
                              className="btn btn-danger cancelleb-btn"
                              onClick={() => handleValid('rejeter')}
                            >
                              Retour
                            </button>
                          </div> */}
                          {fiche?.etat === "validee" ? (
                            <div
                              className="col-md-6 offset-md-6 crud-form-action-btn-container"
                              style={{ justifyContent: "flex-end" }}
                            >
                              <button
                                className={`btn btn-default ${
                                  userType === "assureur"
                                    ? "cancelleb-btn-assurance"
                                    : "cancelleb-btn"
                                }`}
                                onClick={() => handleValid("rejeter")}
                              >
                                Retour
                              </button>
                            </div>
                          ) : (
                            <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                              <button
                                className={`btn btn-default ${
                                  userType === "assureur"
                                    ? "cancelleb-btn-assurance"
                                    : "cancelleb-btn"
                                }`}
                                onClick={() => handleValid("rejeter")}
                              >
                                {userType === "super_ipm"
                                  ? "Retour"
                                  : "Rejeter"}
                              </button>
                              {isSuccessfullySubmitted && (
                                <button
                                  className={`btn ${
                                    userType === "assureur"
                                      ? "crud-submit-btn-assurance"
                                      : "crud-submit-btn"
                                  }`}
                                >
                                  En cours &nbsp;
                                  <i className="fas fa-spin fa-spinner"></i>
                                </button>
                              )}
                              {!isSuccessfullySubmitted && (
                                <button
                                  className={`btn ${
                                    userType === "assureur"
                                      ? "crud-submit-btn-assurance"
                                      : "crud-submit-btn"
                                  }`}
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  Accepter
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailFichedesoin;
