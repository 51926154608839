import React from "react";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import FeatherIcon from 'feather-icons-react'

var EntrepriseNavBarContent = () => {
  return (
    <>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/entreprise/dashboard"
          activeClassName="actived"
        >
          <IconlyPack.Chart
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Dashboard</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/entreprise/rapports"
          activeClassName="actived"
        >
          <FeatherIcon
            strokeWidth="3"
            className="icon-navbar-vertical"
            icon="pie-chart"
            style={{ color: "#ffffff" }}
          />
          <span className="hiddenable-navlink-label">Soins</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/entreprise/gestion-des-employes"
          activeClassName="actived"
        >
          <IconlyPack.People
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Employés</span>
        </NavLink>
      </li>
      {/* <li className="nav-item nav-item-vertical-custom">
                <NavLink className="nav-link nav-link-vertival-custom" to="/entreprise/gestion-des-remboursements" activeClassName="actived">
                    <IconlyPack.Document set="bold" primaryColor="white" className="icon-navbar-vertical"/>
                    <span className="hiddenable-navlink-label">Gestion des remboursements</span>
                </NavLink>
            </li> */}
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/entreprise/liste-des-prestataires"
          activeClassName="actived"
        >
          <IconlyPack.AddUser
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Prestataires</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/entreprise/gestion-des-factures"
          activeClassName="actived"
        >
          <IconlyPack.Wallet
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Facturation</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/entreprise/gestion-des-devis"
          activeClassName="actived"
        >
          <IconlyPack.Activity
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Devis</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/entreprise/parametres"
          activeClassName="actived"
        >
          <IconlyPack.Setting
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Paramétres</span>
        </NavLink>
      </li>
    </>
  );
};

export default EntrepriseNavBarContent;
