import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { getData } from "../../../utils/fetchData";
import { DataContext } from "../../../store/GlobalState";
import FamilleSoins from "../../../Services/FamilleSoins";

const UseCreateFamilleSoinForm = () => {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [ipmData, setIpmData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        nom: yup.string().required("Le nom de la famille de soins est requis."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    var dd = [];
    formValues.typedesoin.forEach((item) => {
      if (item?.nom) {
        dd.push(item.nom);
      }
    });
    if (dd?.length) {
      // '"' + JSON.stringify(dd) + '"'
      formValues.typedesoin = dd;
    } else formValues.typedesoin = [];
    console.log("data", formValues);
    var dataRequest = FamilleSoins.postCreateFamilleSoin(
      formValues,
      requestConfig
    );
    await dataRequest
      .then((response) => {
        var ipmInfos = response;
        console.log("ipm", ipmInfos);
        getData("familledesoin/", auth.token).then((response) => {
          dispatch({
            type: "LIST_FAMILLE_SOINS",
            payload: response.results.sort((a, b) => b?.id - a?.id),
          });
        });
        e.target.reset();
        setIsSuccessfullySubmitted(false);
        window.$(".add-familleSoin-modal").modal("hide");
        toast.success("Famille de soins ajoutée avec succès.");
      })
      .catch((error) => {
        console.log(error?.response);
        setIsFormRequestError(true);
        toast.error("Oupss! Une erreur est survenue lors de l'ajout.");
      });
  };
  return {
    register,
    errors,
    formState,
    ipmData,
    setIpmData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitCreateForm),
    reset,
    setValue,
  };
};

export default UseCreateFamilleSoinForm;
