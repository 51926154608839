import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import Select from "react-select";
import UseCreateRemboursement from "../../../Backend/Assurance/GestionRemboursement/UseCreateRemboursement";
import { formatMontant } from "../../../../utils/utils";
import { getData, getDataByID } from "../../../utils/fetchData";
import { DataContext } from "../../../store/GlobalState";

registerLocale("fr", fr);
const calcul = (cout_montant, taux, montant_plafond) => {
  let a_rembourser = (parseFloat(cout_montant) * taux) / 100;

  if (a_rembourser > montant_plafond) {
    a_rembourser = montant_plafond;
  }
  let a_retenir = parseFloat(cout_montant) - a_rembourser;
  return {
    retenu: a_retenir,
    rembourser: a_rembourser,
  };
};

const DemandeRemboursement = () => {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    unregister,
    isSuccess,
    getValues,
  } = UseCreateRemboursement();
  const { state, dispatch } = useContext(DataContext);
  const {
    auth,
    list_famille_soins,
    assureur_beneficiaires,
    assureur_prestataires,
    assureur_prise_en_charges,
  } = state;

  const [types, setTypes] = React.useState([]);
  const [clesFamily, setClesFamily] = React.useState([
    { value: "", label: "" },
  ]);
  const [clesType, setClesType] = React.useState([{ value: "", label: "" }]);
  const [valFamily, setValFamily] = React.useState("");
  const [valType, setValType] = React.useState("");

  const [token, setToken] = React.useState();
  const [patients, setPatients] = React.useState([]);
  var [startDate, setStartDate] = React.useState(new Date());
  const [prestation_types, setPrestation_types] = React.useState([
    { value: "", label: "" },
  ]);
  // eslint-disable-next-line
  const [prestation_type, setPrestation_type] = React.useState("");
  const [montant, setMontant] = React.useState(0);
  const [retenu, setRetenu] = React.useState(0);
  const [rembourser, setRembouser] = React.useState(0);
  const [type, setType] = React.useState("");
  const [prise, setPrise] = React.useState("");
  const [item_type, setItem_type] = React.useState({
    taux: 0,
    montant: 0,
  });
  var [selected, setSelected] = React.useState({});
  let userType = sessionStorage.getItem("userType");
  // const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (auth?.token) {
      getData("typedesoin/", auth.token)
        .then((response) => {
          setTypes(response.results.sort((a, b) => b?.id - a?.id));
        })
        .catch((error) => console.log("error", error?.response));
    }

    if (list_famille_soins?.length) {
      setClesFamily(
        list_famille_soins.map((b) => {
          return {
            label: b.nom,
            value: b.id,
          };
        })
      );
    }
  }, [auth, list_famille_soins]);

  React.useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    setToken(token);
    register("beneficiaire");
    register("date");
    setValue("date", moment(startDate).format("YYYY-MM-DD"));
  }, [token, startDate]);

  React.useEffect(() => {
    if (isSuccess) {
      handleReset()
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  const onSelect = (e) => {
    let params = e.target.value;
    setValue("beneficiaire", params);
    console.log("value", e.target.value);
    let benef = assureur_beneficiaires?.data?.filter(
      (b) => b?.id === parseInt(params)
    );
    if (params !== "null") {
      getDataByID("ayantdroitbybeneficiaire", params, token)
        .then((res) => {
          if (res?.length) {
            setPatients([...benef, ...res]);
          } else {
            setPatients(benef);
          }
          setSelected(benef[0]);
          console.log("patients=>", patients);
        })
        .catch((error) => console.log("error=>", error));
    } else {
      setPatients([]);
    }
  };

  const onChangeMontant = (e) => {
    setValue("montant", e.currentTarget.value);
    setMontant(e.currentTarget.value);
    if (type) {
      let montant_plafond = assureur_prise_en_charges?.data.filter((f) => f.id == type)[0]
        .montant_plafond;
      // eslint-disable-next-line
      let taux_prise_en_charge = assureur_prise_en_charges?.data.filter((f) => f.id == type)[0]
        .taux_prise_en_charge;
      setItem_type({
        montant: montant_plafond,
        taux: taux_prise_en_charge,
      });
      if (montant_plafond !== 0) {
        if (getValues("montant") !== "") {
          let montant = getValues("montant");
          console.log("calcul=>", montant);
          const _getCalcul = calcul(
            montant,
            taux_prise_en_charge,
            montant_plafond
          );
          setRembouser(Math.abs(_getCalcul.rembourser));
          setRetenu(Math.abs(_getCalcul.retenu));
          setValue("montant_rembourse", Math.abs(_getCalcul.rembourser));
          setValue("montant_retenu", Math.abs(_getCalcul.retenu));
        }
      }
    }
  };

  const onSelectAyant = (e) => {
    let current = e.target.value;
    // console.log('current=>', current)
    let benef = assureur_beneficiaires?.data?.filter(
      (b) => b?.id === parseInt(current)
    );

    if (benef?.length && benef[0]?.prenom === selected?.prenom) {
      console.log("benef");
    } else {
      register("ayant_droit");
      setValue("ayant_droit", current);
    }
    // setValue("ayant_droit", current);
  };

  const onSelectType = (e) => {
    let params = e.value;
    setPrestation_type(e);
    setValue("type_prestation", params);
    setValue("show_type", true);
    console.log("params", params, e);
    if (params && params !== undefined && params !== null) {
      setType(params);
      // eslint-disable-next-line
      let montant_plafond = assureur_prise_en_charges?.data?.filter(
        (f) => f.id === params
      )[0]?.montant_plafond;
      // eslint-disable-next-line
      let taux_prise_en_charge = assureur_prise_en_charges?.data?.filter(
        (f) => f.id === params
      )[0]?.taux_prise_en_charge;
      setItem_type({
        montant: montant_plafond,
        taux: taux_prise_en_charge,
      });
      if (montant_plafond !== 0 && montant_plafond !== null) {
        if (getValues("montant") !== "") {
          let montant = getValues("montant");

          if (montant) {
            const _getCalcul = calcul(
              montant,
              taux_prise_en_charge,
              montant_plafond
            );
            setRembouser(Math.abs(_getCalcul.rembourser));
            setRetenu(Math.abs(_getCalcul.retenu));
            setValue("montant_rembourse", Math.abs(_getCalcul.rembourser));
            setValue("montant_retenu", Math.abs(_getCalcul.retenu));
          }
          // console.log("calcul=>", calcul(montant, item_type.taux))
        }
      }
    } else {
      setItem_type({ montant: 0, taux: 0 });
      setRembouser(0);
      setRetenu(0);
    }
  };

  const handleDate = (date) => {
    setStartDate(date);
    register("date");
    setValue("date", moment(date).format("YYYY-MM-DD"));
  };

  const handleFile = (e) => {
    console.log(e.target.files[0]);
    register("documents_justif");
    setValue("documents_justif", e.target.files[0]);
  };

  const handleSelectType = (e) => {
    console.log(e.value);
    setValType(e);
    // setPrestation_types(
    //   prestation_types?.filter((t) => t?.typedesoin?.id === e.value)
    // );
    let types = assureur_prise_en_charges?.data?.filter(
      (t) => t?.typedesoin?.id === e.value
    );

    console.log("types", types);

    if (types?.length) {
      if (types?.length > 1) {
        setPrestation_types(
          types.map((p) => {
            return {
              label:
                p?.libelle + " " + "(" + p?.taux_prise_en_charge + "%" + ")",
              value: p?.id,
            };
          })
        );
        setValue("show_type", true);
      } else {
        setValue("type_prestation", types[0]?.id);
        handleMont(types[0]?.id);
        setValue("show_type", false);
      }
    } else {
      handleMont(types[0]?.id);
      setPrestation_types([]);
      // setValue("type_prestation", "");
      setValue("show_type", false);
    }
    // let type = prestation_types?.filter(
    //   (t) => t?.typedesoin?.id === e.value
    // );
    // if (type?.length) {
    //   onSelectType(type[0]?.id);
    // } else {
    //   onSelectType();
    // }

    register("typedesoin");
    setValue("typedesoin", e.value);
  };

  const handleMont = (id) => {
    if (id && id !== undefined && id !== null) {
      setType(id);
      // eslint-disable-next-line
      let montant_plafond = assureur_prise_en_charges?.data?.filter(
        (f) => f.id === id
      )[0]?.montant_plafond;
      // eslint-disable-next-line
      let taux_prise_en_charge = assureur_prise_en_charges?.data?.filter(
        (f) => f.id === id
      )[0]?.taux_prise_en_charge;
      setItem_type({
        montant: montant_plafond,
        taux: taux_prise_en_charge,
      });
      if (montant_plafond !== 0 && montant_plafond !== null) {
        if (getValues("montant") !== "") {
          let montant = getValues("montant");

          if (montant) {
            const _getCalcul = calcul(
              montant,
              taux_prise_en_charge,
              montant_plafond
            );
            setRembouser(Math.abs(_getCalcul.rembourser));
            setRetenu(Math.abs(_getCalcul.retenu));
            setValue("montant_rembourse", Math.abs(_getCalcul.rembourser));
            setValue("montant_retenu", Math.abs(_getCalcul.retenu));
          }
          // console.log("calcul=>", calcul(montant, item_type.taux))
        }
      }
    } else {
      setItem_type({ montant: 0, taux: 0 });
      setRembouser(0);
      setRetenu(0);
    }
  };

  const onSelectFamily = (e) => {
    // console.log(e.value, types);
    setValFamily(e);

    getDataByID("typedesoinbyfamille", e?.value, auth?.token)
      .then((response) => {
        console.log(response.results, "test");
        if (response.results?.length) {
          setClesType(
            response.results.map((b) => {
              return {
                label: b.nom,
                value: b.id,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
    register("familledesoin");
    setValue("familledesoin", e.value);
  };

  const handleReset = async () => {
    reset();
    setValFamily("");
    setValType("");
    setPrestation_type("");
    setPrestation_types([]);
    setPatients([]);
    setRembouser(0);
    setRetenu(0);
    
    setItem_type({
      taux: 0,
      montant: 0,
    });
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="DemandeRemboursement"
          >
            Remboursement
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Adhérent</label>
                    <div className="filter-item-container">
                      <select
                        onChange={(e) => onSelect(e)}
                        // {...register('beneficiaire')}
                        name="beneficiaire"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Adhérent
                        </option>
                        {assureur_beneficiaires?.data
                          ?.filter((b) => b?.is_active === true)
                          .map((benef) => (
                            <option key={benef.id} value={benef.id}>
                              {`${benef.nom} ${benef.prenom}`}
                            </option>
                          ))}
                      </select>
                      {formState.errors && formState.errors.beneficiaire && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.beneficiaire?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Bénéficiaire</label>
                    <div className="filter-item-container">
                      <select
                        onChange={(e) => {
                          // register('ayant_droit')
                          onSelectAyant(e);
                        }}
                        name="ayant_droit"
                        // name="prestataires"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir un Bénéficiaire
                        </option>
                        {patients
                          .filter((b) => b?.is_active === true)
                          .map((p) => {
                            let name = `${p.nom} ${p.prenom}`;
                            return (
                              <option key={p.id} value={p.id}>
                                {name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Famille de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner la famille de soins"
                        name="familledesoin"
                        value={valFamily}
                        onChange={(e) => onSelectFamily(e)}
                        options={clesFamily}
                      />
                      {formState.errors && formState.errors.familledesoin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.familledesoin?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Type de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner le type de soins"
                        name="typedesoin"
                        value={valType}
                        onChange={(e) => handleSelectType(e)}
                        options={clesType}
                      />
                      {formState.errors && formState.errors.typedesoin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.typedesoin?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Type de prestation */}
                {prestation_types?.length && prestation_types?.length > 1 ? (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="cle">Type de prestation</label>
                      <div className="filter-item-container">
                        <Select
                          placeholder="Selectionner le type de prestation"
                          name="type_prestation"
                          value={prestation_type}
                          onChange={(e) => onSelectType(e)}
                          options={prestation_types}
                        />
                        {formState.errors &&
                          formState.errors.type_prestation && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {formState.errors.type_prestation?.message}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* END */}
                <div className="col-md-6 crud-col-item">
                  <div className="crud-form-interm">
                    <div className="form-group crud-form-group required crud-interm">
                      <label htmlFor="date">Date</label>
                      <DatePicker
                        className="form-control crud-form-control"
                        selected={startDate}
                        placeholderText="Sélectionner une date"
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="top"
                        peekNextMonth
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        name="date"
                        locale="fr"
                        onChange={(date) => handleDate(date)}
                      />
                      {formState.errors && formState.errors.date && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-3">
                          {formState.errors.date?.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group crud-form-group required crud-interm">
                      <label htmlFor="cout">Coût</label>
                      <input
                        type="number"
                        placeholder="Coût en FCFA"
                        min="1"
                        name="montant"
                        className="form-control crud-form-control"
                        id="cout"
                        onChange={(e) => onChangeMontant(e)}
                        // {...register('montant', {
                        //   required: true,
                        //   maxLength: 30,
                        // })}
                      />
                      {/* <span className="customy-label">FCFA</span> */}
                      {formState.errors && formState.errors.montant && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-3">
                          {formState.errors.montant?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="crud-form-interm">
                    <div className="form-group crud-form-group crud-interm">
                      <label htmlFor="a_rembourser">Part ASSURANCE</label>
                      <input
                        type="text"
                        readOnly
                        placeholder="15000"
                        value={formatMontant(rembourser)}
                        name="montant_rembourse"
                        className="form-control crud-form-control"
                        id="a_rembourser"
                      />
                      <span className="customy-label">FCFA</span>
                    </div>
                    <div className="form-group crud-form-group crud-interm">
                      <label htmlFor="cout">Part Participant</label>
                      <input
                        type="text"
                        readOnly
                        value={formatMontant(retenu)}
                        placeholder="5000"
                        name="montant_retenu"
                        className="form-control crud-form-control"
                        id="a_retenir"
                      />
                      <span className="customy-label">FCFA</span>
                      {formState.errors && formState.errors.a_retenir && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-3">
                          {formState.errors.a_retenir?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="fournisseur">Fournisseur</label>
                    <div className="filter-item-container">
                      <select
                        {...register("prestataire")}
                        name="prestataire"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir un fournisseur
                        </option>
                        {assureur_prestataires?.data?.map((prest) => {
                          return (
                            <option key={prest.id} value={prest.id}>
                              {prest.raison_sociale ||
                                prest.prenom + " " + prest.nom}
                            </option>
                          );
                        })}
                      </select>
                      {formState.errors && formState.errors.prestataire && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.prestataire?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-6 crud-col-item"
                  style={{ marginBottom: "15px" }}
                >
                  <div className="form-group crud-form-group custom-file required">
                    <label htmlFor="file">Pièce justificatif</label>
                    <input
                      type="file"
                      placeholder="Importer un document"
                      name="documents_justif"
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      id="file"
                      onChange={handleFile}
                      // {...register("documents_justif")}
                    />

                    {formState.errors && formState.errors.documents_justif && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.documents_justif?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item pricing-cruded">
                  <div className="taux-ipm-container">
                    <div className="taux-ipm-label">Taux IPM : </div>
                    <div className="taux-ipm-value">{item_type.taux}%</div>
                  </div>
                  <div className="plafond-ipm-container">
                    <div className="plafond-ipm-label">Montant Plafond : </div>
                    <div className="plafond-ipm-value">
                      {formatMontant(item_type.montant)} FCFA
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                  )}
                
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandeRemboursement;
