import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import beneficiaire from "../../../Services/Beneficiaire";
import { DataContext } from "../../../store/GlobalState";
import { toast } from "react-toastify";
import { getDataByID } from "../../../utils/fetchData";

function UseCreateBeneficiaireForm() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [beneficiaireData, setBeneficiaireData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };
  const userType = React.useState(sessionStorage.getItem("userType"));

  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(),
        groupe_sanguin: yup.string(),
        nom: yup.string().required("Le Nom est requis"),
        prenom: yup.string().required("Le prénom est requis"),
        num_contrat: yup.string(),
        employeur: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .when("status", (val, schema) => {
            if (val === "false")
              return yup
                .number()
                .transform((value) => (isNaN(value) ? undefined : value))
                .required();
            else
              return yup
                .number()
                .transform((value) => (isNaN(value) ? undefined : value));
          }),
        telephone: yup.string().required("Le numéro de téléphone est requis"),
        adresse: yup.string().required("L'adresse est requise"),
        sexe: yup.string().required("Le sexe est requis"),
        situation_matrimoniale: yup
          .string()
          .required("La situation matriomiale est requise"),
        nationnalite: yup.string().required("La nationalité est requise"),
        date_de_naissance: yup
          .string()
          .required("La date de naissance est requise"),
        date_debut: yup.string().required("La date de début est requise"),
        date_fin: yup.string().required("La date de début est requise"),
        matricule: yup.string(),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitCreateForm = async (formValues, e) => {
    var fd = new FormData();
    if (sessionStorage.getItem("userType") === "entreprise") {
      if (!auth?.user?.admin_type) {
        formValues["employeur"] = auth?.user?.id;
        formValues["is_active"] = false;
        if (!formValues?.assurance) {
          formValues["assurance "] = null;
        }
      } else {
        formValues["employeur"] = auth?.user?.parent;
        formValues["is_active"] = false;
        if (!formValues?.assurance) {
          formValues["assurance "] = null;
        }
      }
    } else {
      if (sessionStorage.getItem("userType") === "admin_ipm") {
        formValues["assurance"] = auth?.user?.parent;
        formValues["is_active"] = true;
      } else {
        formValues["assurance"] = auth?.user?.id;
        formValues["is_active"] = true;
      }
    }
    fd.append("nom", formValues.nom);
    fd.append("prenom", formValues.prenom);
    fd.append("employeur", formValues.employeur);
    fd.append("assurance", formValues.assurance);
    fd.append("is_active", formValues.is_active);
    fd.append("email", formValues.email);
    fd.append("telephone", formValues.telephone);
    fd.append("adresse", formValues.adresse);
    fd.append("groupe_sanguin", formValues.groupe_sanguin);
    fd.append("num_contrat", formValues.num_contrat);
    fd.append("date_de_naissance", formValues.date_de_naissance);
    fd.append("date_debut", formValues.date_debut);
    fd.append("date_fin", formValues.date_fin);
    fd.append("situation_matrimoniale", formValues.situation_matrimoniale);
    fd.append("nationnalite", formValues.nationnalite);
    fd.append("sexe", formValues.sexe);
    fd.append("matricule", formValues.matricule);
    if (formValues?.cni?.length) {
      fd.append("cni", formValues.cni[0]);
    }

    if (formValues?.certificat_marriage?.length) {
      fd.append("certificat_marriage", formValues.certificat_marriage[0]);
    }

    console.log(formValues);

    setIsSuccessfullySubmitted(true);

    var beneRequest = beneficiaire.postCreateBeneficiaire(fd, requestConfig);
    await beneRequest
      .then((response) => {
        var beneficiaireInfos = response;
        console.log(response);
        setBeneficiaireData(beneficiaireInfos);
        e.target.reset();
        if (sessionStorage.getItem("userType") !== "entreprise") {
          if (sessionStorage.getItem("userType") === "admin_ipm") {
            getDataByID(
              "beneficiairebyassurance",
              auth?.user?.parent,
              auth.token
            )
              .then((res) => {
                dispatch({
                  type: "ADD_BENEFICIAIRE",
                  payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            getDataByID("beneficiairebyassurance", auth?.user?.id, auth.token)
              .then((res) => {
                dispatch({
                  type: "ADD_BENEFICIAIRE",
                  payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          }

          getDataByID("beneficiairebyemploye", response.employeur, auth.token)
            .then((res) => {
              console.log("res benef", res);
              dispatch({
                type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          if (!auth?.user?.admin_type) {
            getDataByID("beneficiairebyemploye", auth?.user?.id, auth.token)
              .then((res) => {
                console.log("res benef", res);
                dispatch({
                  type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
                  payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            getDataByID("beneficiairebyemploye", auth?.user?.parent, auth.token)
              .then((res) => {
                console.log("res benef", res);
                dispatch({
                  type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
                  payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          }
        }
        setIsSuccess(true);
        window.$(".add-beneficiaire-modal").modal("hide");
        setIsSuccessfullySubmitted(false);
        toast.success("Participant ajouté avec succès.");
        reset();
      })
      .catch((error) => {
        console.log(error?.response, error);
        setIsFormRequestError(true);
        setIsSuccessfullySubmitted(false);
        if (
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          toast.error(
            "Oupsss! L'adresse email est déjà associée à un compte Adhérent. Veuillez utiliser une autre adresse email.",
            {
              position: "top-right",
              autoClose: 10000,
            }
          );
        } else if (error.response?.data?.status === "failure") {
          toast.error(error.response?.data?.message);
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (
          error?.response?.status === 400 &&
          !error?.response?.data?.email
        ) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };

  return {
    register,
    errors,
    formState,
    beneficiaireData,
    setBeneficiaireData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitCreateForm),
    isSuccess,
  };
}

export default UseCreateBeneficiaireForm;
