const PriseEnChargeAdminTableData = [
    {
        id: 1,
        famille: 'Examens',
        type_soin: 'Radiologie',
        libelle: 'Echographie',
        taux: '60',
        plafond: '35 000'
    },
    {
        id: 2,
        famille: 'Examens',
        type_soin: 'Radiologie',
        libelle: 'Echographie',
        taux: '60',
        plafond: '35 000'
    },
    {
        id: 3,
        famille: 'Examens',
        type_soin: 'Radiologie',
        libelle: 'Echographie',
        taux: '60',
        plafond: '35 000'
    },
    {
        id: 4,
        famille: 'Examens',
        type_soin: 'Radiologie',
        libelle: 'Echographie',
        taux: '60',
        plafond: '35 000'
    },
];

export default PriseEnChargeAdminTableData;
