/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import "moment/locale/fr";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import "./LineChart.css";
// import { forEach } from 'lodash'
// import "react-datepicker/dist/react-datepicker.css";

registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});
const LineChart = (props) => {
  const [date, setDate] = useState(new Date());
  const [count, setCount] = useState([]);
  const [stat, setStat] = useState([]);
  const [labels, setLabels] = useState([]);
  const [label, setLabel] = useState("");

  // let labels = [
  //   "Lundi",
  //   "Mardi",
  //   "Mercredi",
  //   "Jeudi",
  //   "Vendredi",
  //   "Samedi",
  //   "Dimanche",
  // ];

  let data = {
    labels: labels,
    datasets: [
      {
        type: "line",
        label: label,
        data: count,
        fill: false,
        backgroundColor: "#06DED1",
        borderColor: "#06DED1",
        hoverBackgroundColor: "#06DED1",
        hoverBorderColor: "#06DED1",
        yAxisID: "y-axis-1",
      },
    ],
  };

  let options = {
    responsive: true,
    labels: labels,
    tooltips: {
      mode: "label",
    },
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
          },
          labels: labels,
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
        },
        {
          type: "linear",
          display: false,
          position: "right",
          id: "y-axis-2",
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
        },
      ],
    },
  };

  let plugins = [
    {
      afterDraw: (chartInstance, easing) => {
        //const ctx = chartInstance.chart.ctx;
        //ctx.fillText("This text drawn by a plugin", 100, 100);
      },
    },
  ];

  useEffect(() => {
    if (
      window.sessionStorage.getItem("userType") === "ipm" ||
      window.sessionStorage.getItem("userType") === "assurance_sante" ||
      window.sessionStorage.getItem("userType") === "admin_ipm"
    ) {
      setLabels([
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
      ]);
      setLabel("Fiches de Soins");
      setStat(
        props.stat?.filter((st) =>
          moment(st?.day).locale("fr").isSame(date, "week")
        )
      );
    } else {
      setLabels([
        "Janv",
        "Févr",
        "Mars",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Août",
        "Sept",
        "Oct",
        "Nov",
        "Déc",
      ]);
      setLabel("Prestations");
      setStat(
        props.stat?.filter(
          (st) =>
            moment(st?.month).isSame(date, "year") &&
            moment(st?.month).isSame(date, "month")
        )
      );
    }

    // if (window.sessionStorage.getItem("userType") === "entreprise") {

    // }
  }, [props?.stat]);

  useEffect(() => {
    if (
      window.sessionStorage.getItem("userType") === "ipm" ||
      window.sessionStorage.getItem("userType") === "assurance_sante" ||
      window.sessionStorage.getItem("userType") === "admin_ipm"
    ) {
      let days = [0, 0, 0, 0, 0, 0, 0];
      if (stat?.length) {
        stat.forEach((element) => {
          days[moment(element.day).locale("fr").day() - 1] = element.fiche;
        });
      }

      setCount(days);
    } else if (
      window.sessionStorage.getItem("userType") === "super_imp" ||
      sessionStorage.getItem("isSuperAdmin")
    ) {
      setCount([12, 5, 0, 44, 25, 45, 38]);
      console.log(stat);
    } else {
      let days = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (stat?.length) {
        stat.forEach((element) => {
          // console.log()
          days[moment(element.day).locale("fr").month() - 1] =
            element.total_prest_by_month;
        });
      }

      setCount(days);
    }
  }, [stat?.length, stat, date]);

  const handleChangeDate = (date) => {
    setCount([]);
    setDate(date);
    // if (window.sessionStorage.getItem("userType") === "entreprise") {

    // }

    if (
      window.sessionStorage.getItem("userType") === "ipm" ||
      window.sessionStorage.getItem("userType") === "assurance_sante" ||
      window.sessionStorage.getItem("userType") === "admin_ipm"
    ) {
      setStat(
        props.stat?.filter((st) =>
          moment(st?.day).locale("fr").isSame(date, "week")
        )
      );
    } else {
      setStat(
        props.stat?.filter(
          (st) =>
            moment(st?.month).isSame(date, "year") &&
            moment(st?.month).locale("fr").isSame(date, "month")
        )
      );
    }
  };
  return (
    <div className="chart-item-container">
      <div className="row mb-2">
        <div className="col-xl-5 col-lg-4 mb-2">
          {/* <h2 className="chart-item-title">Chiffre du jour - <span className="fw-300"> Nombre d'apprenants par jour </span> </h2> */}
        </div>
        <div
          className={
            window.sessionStorage.getItem("userType") === "ipm" ||
            window.sessionStorage.getItem("userType") === "assurance_sante" ||
            window.sessionStorage.getItem("userType") === "prestataire" ||
            window.sessionStorage.getItem("userType") === "admin_ipm"
              ? "col-xl-6 col-lg-6 col-md-4 col-sm-6 mb-2 dis-flex"
              : "col-xl-4 offset-xl-2 col-md-5 offset-md-1 col-sm-6 mb-2 dis-flex"
          }
        >
          <DatePicker
            dateFormat={
              window.sessionStorage.getItem("userType") !== "ipm" &&
              window.sessionStorage.getItem("userType") !== "assurance_sante" &&
              window.sessionStorage.getItem("userType") !== "admin_ipm"
                ? "MMMM/yyyy"
                : "dd/MM/yyyy"
            }
            showMonthYearPicker={
              window.sessionStorage.getItem("userType") !== "ipm" &&
              window.sessionStorage.getItem("userType") !== "assurance_sante" &&
              window.sessionStorage.getItem("userType") !== "admin_ipm"
                ? true
                : false
            }
            maxDate={new Date()}
            className="form-select form-select-line-chart ml-md-auto"
            selected={date}
            onChange={(date) => handleChangeDate(date)}
            locale="fr"
          />
        </div>
      </div>

      <Bar data={data} options={options} plugins={plugins} />
    </div>
  );
};

export default LineChart;
