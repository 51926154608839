import React, { useContext, useState } from "react";
import { DataContext } from "../store/GlobalState";
import { toast } from "react-toastify";
import { getDataByID } from "../utils/fetchData";
import Entreprise from "../Services/Entreprise";

function ValidEntrepriseModal() {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, entreprise } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const handleValid = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var beneficiaireRequest = Entreprise.editEntreprise(
      entreprise?.id,
      {
        is_archived: !entreprise?.is_archived,
        is_active: entreprise?.is_archived,
      },
      requestConfig
    );
    await beneficiaireRequest
      .then(async (res) => {
        if (auth?.user?.user_type === "admin_ipm") {
          await getDataByID(
            "entreprisebyassurance",
            auth?.user?.parent,
            auth.token
          )
            .then((response) => {
              dispatch({
                type: "ADD_ENTREPRISE_BY_IPM",
                payload: response.sort((a, b) => a.id - b.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          await getDataByID("entreprisebyassurance", auth?.user?.id, auth.token)
            .then((response) => {
              dispatch({
                type: "ADD_ENTREPRISE_BY_IPM",
                payload: response.sort((a, b) => a.id - b.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
        if (res.is_archived) {
          await toast.success("Adhérent archivé !");
        } else {
          await toast.success("Adhérent désarchivé !");
        }
        await setIsLoading(false);
        await dispatch({
          type: "EDIT_ENTREPRISE",
          payload: {},
        });

        await window.$(".valid-entreprise-modal").modal("hide");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de l'action.");
      });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="DeletingFamilyModal"
          >
            Archivage/Désarchivage d'un adhérent
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "EDIT_ENTREPRISE", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="deleting-family-form-container">
            <div className="alert-delete-message">
              <span>
                {entreprise &&
                  "Êtes vous sûr de vouloir " +
                    (entreprise?.is_archived ? "désarchiver " : "archiver ") +
                    " cet adhérent"}
                ?
              </span>
            </div>

            <div className="row delete-row">
              <div className="col-md-10 offset-md-2 crud-form-action-btn-container">
                <button
                  onClick={() =>
                    dispatch({
                      type: "EDIT_ENTREPRISE",
                      payload: {},
                    })
                  }
                  className="btn btn-default cancelleb-btn delete-actions"
                  data-dismiss="modal"
                >
                  Retour
                </button>
                {!isLoading ? (
                  <button
                    className="btn btn-primary crud-submit-btn delete-actions"
                    onClick={(e) => handleValid(e)}
                  >
                    Confirmer
                  </button>
                ) : (
                  <button className="btn btn-primary crud-submit-btn delete-actions">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValidEntrepriseModal;
