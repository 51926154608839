import React, { useEffect, useContext } from 'react'
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from 'react-bootstrap-table'
import * as IconlyPack from 'react-iconly'
import { NavLink } from 'react-router-dom'
import RemboursementAssureurTableData from './RemboursementAssureurTableData'
import RemboursementAssureurTableSkeleton from './RemboursementAssureurTableSkeleton'
// import FactureModalAssureur from './FactureModalAssureur'
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'
import FeatherIcon from "feather-icons-react";
import GestionFicheSoinRemboursement from './GestionFicheSoinRemboursement'

var RemboursementAssureurTable = (props) => {

 
  const [soins, setSoins] = React.useState(RemboursementAssureurTableData)

  var [isPageLoading, setIsPageLoading] = React.useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false)
    }, 3000)
  }, [soins])

  var handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event')
    onClick()
  }

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    )
  }

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: 'Aucune donnée disponible',
  }
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <button className="action-btn edit-btn" title="Traitement">
          <IconlyPack.Show
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#GenererFactureClientModal"
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Paper
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#FicheSoinRemboursement"
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Edit
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#factureAssureur"
          />
        </button>
      </div>
    );
  };
  
  const etatFormatter = (cell, row) => {
    if (cell === "rejete") {
      return (
        <span className="badge badge-danger easypm-badge-danger ">
          Rejeté
        </span>
      );
    }

    if (cell === "envoye") {
      return (
        <span className="badge badge-info easypm-badge-info ">Envoyé</span>
      );
    }

    if (cell === "recu") {
      return (
        <span className="badge badge-warning easypm-badge-warning ">
          Reçu
        </span>
      );
    }

    if (cell === "valide") {
      return (
        <span className="badge badge-default easypm-badge-default ">
          Validé
        </span>
      );
    }

    if (cell === "regle") {
      return (
        <span className="badge badge-success easypm-badge-success ">
          Reglé
        </span>
      );
    }
};
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <RemboursementAssureurTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={soins}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="150"
                    dataField="assures"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Assurés
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="150"
                    dataField="date"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="type_soin"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Type de soins
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="part_assurance"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Part Assurance 
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="montant"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Montant
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="statut"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                    dataFormat={(cell, row) => etatFormatter(cell, row)}
                  >
                    Statut
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          {/* <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionSoinModal"
            tabIndex="-1"
            aria-labelledby="GestionSoinModalLabel"
            data-backdrop="static"
            aria-hidden="true"
        >
            <GestionSoinAssureurModal />
        </div> */}
        <div
            className="modal fade easypm-modal add-soin-modal"
            id="FicheSoinRemboursement"
            tabIndex="-1"
            aria-labelledby="FicheSoinRemboursementLabel"
            data-backdrop="static"
            aria-hidden="true"
        >
            <GestionFicheSoinRemboursement />
        </div>
        </div>
      </div>
    </div>
  )
}

export default RemboursementAssureurTable
