import React, { useContext, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import Header from '../../../../Navs/Header'
import { DataContext } from '../../../../store/GlobalState'
import MontantVersesAuxAssuresTable from '../../../../Tables/MontantVersesAuxAssuresTable/MontantVersesAuxAssuresTable'
import MontantVersesAuxEntreprisesTable from '../../../../Tables/MontantVersesAuxEntreprisesTable/MontantVersesAuxEntreprisesTable'
import MontantVersesAuxPrestatairesTable from '../../../../Tables/MontantVersesAuxPrestatairesTable/MontantVersesAuxPrestatairesTable'
import { getDataByID } from '../../../../utils/fetchData'
import NavbarVerticalV2 from '../../../navbarVerticalV2/navbarVerticalV2'
import BarChart from '../BarChart/BarChart'
import DonutGraph from '../DonutGraph/DonutGraph'
import LineChart from '../LineChart/LineChart'
import './DashboardAssureur.css'
var DashboardAssureur = () => {
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);
  const { auth, stats } = state;
  const [volume, setVolume] = React.useState({});
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  var [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (!auth?.user?.admin_type) {
        getDataByID("statvolumetriquebyipm", auth?.user?.id, auth.token).then(
          (res) => {
            setVolume(res);
            setIsPageLoading(false);
          }
        );

         getDataByID("statbyassurance", auth?.user?.id, auth.token)
           .then((res) => {
             dispatch({ type: "STATS", payload: res.data });
             setIsLoading(false);
           })
           .catch((e) => {
             console.log("e", e);
           });

        getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
          (res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );
      } else {
        getDataByID(
          "statvolumetriquebyipm",
          auth?.user?.parent,
          auth.token
        ).then((res) => {
          setVolume(res);
          setIsPageLoading(false);
        }).catch((error) => console.log("error", error?.response));

         getDataByID("statbyassurance", auth?.user?.parent, auth.token)
           .then((res) => {
             dispatch({ type: "STATS", payload: res.data });
             setIsLoading(false);
           })
           .catch((e) => {
             console.log("e", e);
           });

        getDataByID("notificationbyipm", auth?.user?.parent, auth?.token).then(
          (res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );
      }
    }
  }, [auth]);
  


  const CardSkeleton = () => {
    const renderModules = Array(3)
      .fill()
      .map((item, index) => (
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2" key={index}>
          <div className="card">
            <Skeleton
              variant="rect"
              style={{ borderRadius: '10px' }}
              width={332}
              height={100}
            />
          </div>
        </div>
      ))
    return <React.Fragment>{renderModules}</React.Fragment>
  }

  return (
    <div className="container-fluid body-background-dashboard">
      <Header />

      <div className="row">
        {/* Navbar Desktop  */}
        <div className="nav-container no-view-mobile nav-assurance-color">
          <NavbarVerticalV2 />
        </div>

        <div
          className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0"
          id="assurance"
        >
          <div className="container-fluid">
            <div className="row mb-3 px-2">
              {isPageLoading && <CardSkeleton />}
              {!isPageLoading && (
                <React.Fragment>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total entreprises
                        </p>
                        <br />
                        <p className="child-label-card-statistics"></p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {volume?.nb_entreprise}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total assurés
                        </p>
                        <br />
                        <p className="child-label-card-statistics"></p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {volume?.nb_benef}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total prestataires agréés
                        </p>
                        <br />
                        <p className="child-label-card-statistics"></p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {volume?.nb_prestataire_agree}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total prestataires non agréés
                        </p>
                        <br />
                        <p className="child-label-card-statistics"></p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {volume?.nb_prestataire_not_agree}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total ayants droit
                        </p>
                        <br />
                        <p className="child-label-card-statistics"></p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {volume?.nb_ayantdroit}
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 m-b-5">
                <div className="card-dashboard m-b-15">
                  {isLoading === false ? (
                    <LineChart stat={stats?.stat} />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 m-b-5">
                <div className="card-dashboard m-b-15">
                  {isPageLoading === false ? (
                    <BarChart auth={auth} />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 m-b-5">
                <div className="card-dashboard m-b-15">
                  {isPageLoading === false ? (
                    <DonutGraph auth={auth} />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 m-b-5">
                <div className="card-dashboard m-b-15">
                  {isPageLoading === false ? (
                    <MontantVersesAuxAssuresTable auth={auth} />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 m-b-5">
                <div className="card-dashboard m-b-15">
                  {isPageLoading === false ? (
                    <MontantVersesAuxPrestatairesTable auth={auth} />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 m-b-5">
                <div className="card-dashboard m-b-15">
                  {isPageLoading === false ? (
                    <MontantVersesAuxEntreprisesTable auth={auth} />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardAssureur
