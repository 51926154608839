import React from "react";
import Header from '../../../../Navs/Header';
import NavbarVerticalV2 from "../../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
// import { forEach } from 'lodash'
// import "react-datepicker/dist/react-datepicker.css";
import DefaultAvatar from "../../../../../images/others/default-profile-avatar.png";
import EpousesDetailsAdherent from "./EpousesDetailsAdherent/EpousesDetailsAdherent";
import EnfantsDetailsAdherent from "./EnfantsDetailsAdherent/EnfantsDetailsAdherent";


const DetailsAdherent = () => {
  

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row breadcrumbs-rapports d-flex align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-6 mb-2 dis-flex">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#" className="nav-item-breadcrumb">Bénéficiaire</a></li>
                <li class="breadcrumb-item active" className="nav-item-breadcrumb" aria-current="page">Badou Seck</li>
            </ol>
            </nav>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 mb-2 dis-flex">
            <div>
                    <span className="icon-container pr-2" style={{ color: 'rgba(0, 0, 0, 0.7)' }}>
                      Statut:
                    </span>
                    <span className="adding-item-label text-generer-rapport-assurance" style={{ fontWeight: 'bold' }}>
                        Actif
                    </span>
            </div>
            </div>
          <div className="col-xl-2 col-lg-2 col-md-2 mb-2 dis-flex">
            <div>
            <button
                    className="w-full btn btn-generer-rapport"
                    data-toggle="modal"
                    data-target=".add-soin-modal"
                  >
                    <span className="icon-container pr-2">
                      <IconlyPack.Wallet set="bold" primaryColor="rgba(0, 0, 0, 0.7)" />
                    </span>
                    <span className="adding-item-label text-generer-rapport-assurance">
                        Générer un carnet
                    </span>
                  </button>
            </div>
            </div>
          <div className="col-xl-2 col-lg-2 col-md-2 mb-2 dis-flex">
                <div>
                <button
                    className="w-full btn btn-generer-rapport"
                    data-toggle="modal"
                    data-target=".add-soin-modal"
                  >
                    <span className="icon-container pr-2">
                      <IconlyPack.PaperNegative set="bold" primaryColor="rgba(0, 0, 0, 0.7)" />
                    </span>
                    <span className="adding-item-label text-generer-rapport-assurance">
                        Soins
                    </span>
                  </button>
                </div>
            </div>
        </div>

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 ben-profile-container" id="gestion-rapport-assureur">
            <div className="page-body-container">
              <div className="row beneficiaire-profile-row">
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 profile-infos-perso-container profile-item">
                    <div className="">
                        <div className="profile-infos-ben">
                            <h2 className="section-light-title">
                                <div className="light-title">Fiche du bénéficiare</div>
                            </h2>
                            <div className="profile-avatar-container">
                            <img
                              src={DefaultAvatar}
                              className="profile-avatar"
                              alt="Profile"
                            />
                            
                          </div>
                          <h3 className="ben-name text-center mt-4">
                              Badou Seck
                            </h3>
                        <div className="ben-others-infos-container">
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>id:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>2434</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Genre:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>Homme</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Statut:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <strong>
                                   ACTIF
                                </strong>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Nom:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>Seck</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Prénom:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>Badou</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Date de naissance:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  OO/OO/1985
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Situation Matriomiale:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  C
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Groupe Sanguin:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>A+</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Nationalité:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  Sénégalaise
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Téléphone:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>777777777</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Email:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>email@gmail.com</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Employeur:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  Volkeno
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Département</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>
                                Département
                              </span>
                            </div>
                          </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Date de création</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  17/12/2019
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Mis à jour le:</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  19/12/2019
                                </span>
                              </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 profile-infos-family-container profile-item">
                <div className="wifes-infos-container">
                      <div className="page-first-row-container">
                        <div className="page-title-container">
                          <h1 className="page-title">Épouse (s)</h1>
                        </div>
                          <div
                            className="adding-item-container linkable"
                            data-toggle="modal"
                            data-target=".add-family-modal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="gray"
                              />
                            </span>
                            <span className="adding-item-label">Ajouter</span>
                          </div>
                      </div>
                      <div className="epouses-table-container">
                        <EpousesDetailsAdherent
                        />
                      </div>
                    </div>
                    <div className="childs-infos-container">
                      <div className="page-first-row-container">
                        <div className="page-title-container">
                          <h1 className="page-title">Enfants</h1>
                        </div>
                          <div
                            className="adding-item-container linkable"
                            data-toggle="modal"
                            data-target=".add-family-modal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="gray"
                              />
                            </span>
                            <span className="adding-item-label">Ajouter</span>
                          </div>
                      </div>
                      <div className="epouses-table-container">
                        <EnfantsDetailsAdherent />
                      </div>
                    </div>
                 
                </div>

                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsAdherent;
