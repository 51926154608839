import React from "react";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import FeatherIcon from "feather-icons-react";

var IpmMobileNavBarContent = () => {
  const [active, setActive] = React.useState("");
  React.useEffect(() => {
    if (
      window.location.pathname === "/ipm/gestion-des-factures" ||
      window.location.pathname === "/ipm/gestion-de-la-facturation"
    ) {
      setActive("actived");
    } else {
      setActive("");
    }
  }, []);
  return (
    <>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/ipm/dashboard"
          activeClassName="actived"
        >
          <IconlyPack.Chart
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Tableau de bord</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/ipm/gestion-des-fiches-de-soin"
          activeClassName="actived"
        >
          <i
            className="fa fa-qrcode fa-lg icon-navbar-vertical"
            aria-hidden="true"
            style={{ color: "#ffffff", marginTop: "0.3rem" }}
          ></i>
          <span className="mobile-navlink-label">Soins</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/ipm/rapports"
          activeClassName="actived"
        >
          <FeatherIcon
            strokeWidth="3"
            className="icon-navbar-vertical"
            icon="pie-chart"
            style={{ color: "#ffffff" }}
          />
          <span className="mobile-navlink-label">Rapports</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/ipm/gestion-des-remboursements"
          activeClassName="actived"
        >
          {/* <FeatherIcon
            strokeWidth="3"
            className="icon-navbar-vertical"
            icon="dollar-sign"
            style={{ color: '#ffffff' }}
          /> */}
          <i
            className="fa fa-credit-card fa-lg icon-navbar-vertical"
            style={{ color: "#ffffff", marginTop: "0.3rem" }}
            aria-hidden="true"
          ></i>
          <span className="mobile-navlink-label">Remboursements</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/ipm/gestion-des-beneficiaires"
          activeClassName="actived"
        >
          <IconlyPack.People
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Participants</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/ipm/gestion-des-types-de-prestation"
          activeClassName="actived"
        >
          <IconlyPack.Category
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Prises en charge</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/ipm/gestion-des-prestataires"
          activeClassName="actived"
        >
          <IconlyPack.AddUser
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Prestataires</span>
        </NavLink>
      </li>
      {/* <li>
                <NavLink className="mobile nav-link nav-link-vertival-custom" to="/ipm/gestion-de-la-facturation" activeClassName="actived">
                    <IconlyPack.Wallet set="bold" primaryColor="white" className="icon-navbar-vertical"/>
                    <span className="mobile-navlink-label">Facturation</span>
                </NavLink>
            </li> */}
      <li className="mobile">
        <a
          className={`mobile nav-link nav-link-vertival-custom ${active}`}
          href="#submenu3"
          data-toggle="collapse"
          // activeClassName="actived"
        >
          <IconlyPack.Wallet
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">
            Facturation
            <i className="fas fa-caret-down" style={{ marginLeft: "10px" }}></i>
          </span>
        </a>
        <ul
          className="collapse nav flex-column ms-1"
          id="submenu3"
          data-parent="#menu"
          style={{ marginLeft: "1rem" }}
        >
          <li className="nav-item w-100">
            <NavLink
              to="/ipm/gestion-de-la-facturation"
              className="nav-link"
              activeClassName="actived"
            >
              <IconlyPack.ArrowRightSquare
                set="bold"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="mobile-navlink-label">Prestataire</span>
            </NavLink>
          </li>
          <li className="nav-item w-100">
            <NavLink
              to="/ipm/gestion-des-factures"
              className="nav-link"
              activeClassName="actived"
            >
              <IconlyPack.ArrowLeftSquare
                set="bold"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="mobile-navlink-label">Adhérents</span>
            </NavLink>
          </li>
        </ul>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/ipm/gestion-des-entreprises"
          activeClassName="actived"
        >
          <IconlyPack.Discovery
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Adhérents</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/ipm/gestion-des-devis"
          activeClassName="actived"
        >
          <IconlyPack.Activity
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Devis</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/ipm/parametres"
          activeClassName="actived"
        >
          <IconlyPack.Setting
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Paramétres</span>
        </NavLink>
      </li>
    </>
  );
};

export default IpmMobileNavBarContent;
