import React, {useEffect} from 'react'
import Header from '../../../Navs/Header';
import NavBarVerticalV2 from '../../navbarVerticalV2/navbarVerticalV2';
import * as IconlyPack from 'react-iconly';
import DetailsFactureTable from '../../../Tables/DetailsFacture/DetailsFactureTable'
// import AddSoinModal from '../../../Modals/AddSoinModal'
import { NavLink } from 'react-router-dom'

var DetailsFacture = () => {
    const [userType, setUserType] = React.useState()

    useEffect(() => {
        setUserType(window.sessionStorage.getItem('userType'))
    }, [])

    return(
        <div className="dashboard-item-container">
            <div className="container-fluid body-background-dashboard">

                <Header />

                <div className="row breadcrumbs-and-actions-row">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 breadcrumbs-container">
                        <NavLink to="/ipm/dashboard" className="brdc-item"> Dashboard </NavLink> &nbsp;
                            <IconlyPack.ChevronRight set="light" primaryColor="rgba(0, 0, 0, 0.7)"/>
                        <NavLink to="/ipm/gestion-de-la-facturation" className="brdc-item"> Facturation </NavLink> &nbsp;
                            <IconlyPack.ChevronRight set="light" primaryColor="rgba(0, 0, 0, 0.7)"/>
                        {/* <NavLink to={`/ipm/profil-beneficiaire/${1}`} className="brdc-item"> Sadio Sangharé </NavLink> &nbsp;
                            <IconlyPack.ChevronRight set="light" primaryColor="rgba(0, 0, 0, 0.7)"/> */}
                        <span className="brdc-item active-brdc-item"> Détails Facture de Volkeno SARL </span>
                    </div>
                    {/* <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12  status-header-actions-container">
                        <div className="status-container hiddenable-opacity">
                            <span className="status-label">Statut : </span> &nbsp;
                            <span className="status-value">Actif</span>
                        </div>
                        <div className="header-actions-container">
                            <div className="header-actions-item generate-card-container">
                                <IconlyPack.Wallet set="bold" primaryColor="#A2A2A2" className="header-actions-btn-icon" />&nbsp;
                                <span className="header-actions-label">Générer un carnet</span>
                            </div>
                            <div className="header-actions-item soin-hd-container">
                                <IconlyPack.Document set="bold" primaryColor="#A2A2A2" className="header-actions-btn-icon" />&nbsp;
                                <Link to="#" className="header-actions-label">Soins</Link>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="row">
                    {/* Navbar Desktop  */}
                    <div className="nav-container">
                        <NavBarVerticalV2 userType={userType} />
                    </div>

                    <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 detaily">
                        <div className="page-body-container">
                            <div className="page-content-container">
                                <div className="page-first-row-container">
                                    <div className="page-title-container">
                                        <h1 className="page-title">VOLKENO SARL</h1>
                                    </div>
                                    {/* <div className="adding-item-container linkable" data-toggle="modal" data-target=".add-soin-modal">
                                        <span className="icon-container">
                                            <IconlyPack.Plus set="light" primaryColor="gray"/>
                                        </span>
                                        <span className="adding-item-label">
                                            Ajouter un soin
                                        </span>
                                    </div> */}
                                </div>
                                <div className="parent-table-container">
                                    <DetailsFactureTable />
                                </div>

                                {/* Modal */}
                                {/* <div class="modal fade easypm-modal add-soin-modal" id="AddModalItem" tabIndex="-1" aria-labelledby="AddModalItemLabel" aria-hidden="true">
                                    <AddSoinModal />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailsFacture;