import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import { toast } from "react-toastify";
import baseUrl from "../../../../Http/backend-base-url";
import AssuranceMaladie from "../../../Services/AssuranceMaladie";
import { DataContext } from "../../../store/GlobalState";

const ParametreLogo = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [avatar, setAvatar] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };
  const changeLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file?.type !== "image/jpeg" &&
        file?.type !== "image/png" &&
        file?.type !== "image/jpg" &&
        file?.type !== "image/gif"
      ) {
        return toast.error(
          "Veuiller uploader une image de type jpg ou jpeg ou png ou gif"
        );
      }
      // console.log('file', file)

      //   setData({ ...data, avatar: file });
      setIsLoading(true);
      const fd = new FormData();
      fd.append("logo", file);
      setAvatar(file);
      let userRequest = AssuranceMaladie.editAssuranceMaladie(
        auth?.user?.id,
        fd,
        requestConfig
      );

      userRequest
        .then((response) => {
          var userInfos = response;
          console.log("user", userInfos);
          setIsLoading(false);
          dispatch({
            type: "AUTH",
            payload: { token: auth.token, user: userInfos },
          });
          setAvatar(null);
          toast.success("Logo ajouté avec succès.");
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };
  return (
    <div className="add-family-form-container pt-5">
      <form className="crud-form">
        <div className="row crud-row justify-content-center">
          <div
            className="profile-avatar-container"
            style={{ width: "200px", height: "200px" }}
          >
            <img
              src={
                avatar
                  ? URL.createObjectURL(avatar)
                  : baseUrl + auth?.user?.logo
              }
              className="profile-avatar"
              alt="Logo"
            />
            {isLoading && (
              <span className="charge">
                <i
                  className="fas fa-spin fa-spinner"
                  style={{ fontSize: "20px" }}
                ></i>
              </span>
            )}

            <span>
              <i className="fas fa-camera"></i>
              <b className="m-1">Changer</b>
              <input
                type="file"
                name="avatar"
                id="file_up"
                accept="image/*"
                onChange={changeLogo}
              />
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ParametreLogo;
