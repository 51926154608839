import moment from "moment";
import React, { useContext } from "react";
import { DataContext } from "../store/GlobalState";

const ShowExceptionModal = () => {
  const { state, dispatch } = useContext(DataContext);

  const { exception } = state;
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Detail Exception
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "EDIT_EXCEPTION", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="crud-form">
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Libellé: </strong>

                    <span className="crud-form-control">
                      {exception?.libelle}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="name">Type: </strong>
                    <span className="crud-form-control">
                      {exception?.type === "entreprise"
                        ? "adhérent"
                        : exception?.type === "beneficiaire"
                        ? "participant"
                        : exception?.type}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="name">Date de début: </strong>
                    <span className="crud-form-control">
                      {moment(exception?.date_debut).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="name">Date de fin: </strong>
                    <span className="crud-form-control">
                      {moment(exception?.date_fin).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="specialite">Objet de l’opposition:</strong>
                    {exception?.cle?.user_type === "beneficiaire" && (
                      <span className="crud-form-control">
                        <span className="name ">
                          {" "}
                          {exception?.cle?.prenom} {exception?.cle?.nom}{" "}
                        </span>{" "}
                        <span className="entreprise ">(Participant)</span>
                      </span>
                    )}

                    {exception?.cle?.user_type === "prestataire" && (
                      <>
                        <span className="name crud-form-control">
                          {exception?.cle?.raison_sociale
                            ? exception?.cle?.raison_sociale
                            : exception?.cle?.prenom +
                              " " +
                              exception?.cle?.nom}
                        </span>
                        <span className="entreprise">(Prestataire)</span>
                      </>
                    )}

                    {exception?.cle?.user_type === "entreprise" && (
                      <>
                        <span className="name crud-form-control">
                          {exception?.cle?.raison_sociale}
                        </span>{" "}
                        <br />
                        <span className="entreprise crud-form-control">
                          Adhérent
                        </span>
                        <span className="entreprise">(Entreprise)</span>
                      </>
                    )}

                    {(exception?.cle?.user_type === "ipm" ||
                      exception?.cle?.user_type === "assurance_sante") && (
                      <>
                        <span className="name crud-form-control">
                          {exception?.cle?.raison_sociale}
                        </span>
                        <span className="entreprise">(IPM)</span>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="col-md-6 offset-md-6 crud-form-action-btn-container"
                  style={{ justifyContent: "flex-end" }}
                >
                  {/* <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                    >
                      Retour
                    </button> */}

                  <button
                    className="btn btn-danger cancelleb-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      dispatch({ type: "EDIT_EXCEPTION", payload: {} })
                    }
                  >
                    Retour
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowExceptionModal;
