import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import EditExclusionModal from "../../Modals/EditExclusionModal";
import ShowExclusion from "../../Modals/ShowExclusion";
import { DataContext } from "../../store/GlobalState";
import { getData, getDataByID } from "../../utils/fetchData";
import ExclusionSkeleton from "./ExclusionSkeleton";
import swal from "sweetalert2";
import { toast } from "react-toastify";
import Exclusion from "../../Services/Exclusion";

const Exclusions = () => {
  const { state, dispatch } = useContext(DataContext);

  const { auth, list_exclusions } = state;
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      getData("exclusion/", auth?.token).then((res) => {
        dispatch({
          type: "LIST_EXCLUSION",
          payload: res.results.sort((a, b) => b?.id - a?.id),
        });
        setIsPageLoading(false);
      });
    }
  }, [auth]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const onDelete = (id) => {
    swal
      .fire({
        title: "Êtes vous sûr de vouloir supprimer cette exclusion ?",
        customClass: {
          confirmButton: "btn-success",
          cancelButton: "btn-danger",
        },
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          var deleteRequest = Exclusion.deleteExclusion(id, requestConfig);
          return deleteRequest
            .then((response) => {
              if (
                auth.user?.user_type === "ipm" ||
                auth?.user?.user_type === "assurance_sante"
              ) {
                getDataByID("exclusionbyipm", auth?.user?.id, auth?.token).then(
                  (res) => {
                    dispatch({
                      type: "LIST_EXCLUSION",
                      payload: res.results.sort((a, b) => b?.id - a?.id),
                    });
                  }
                );
              } else {
                getData("exclusion/", auth?.token).then((res) => {
                  dispatch({
                    type: "LIST_EXCLUSION",
                    payload: res.results.sort((a, b) => b?.id - a?.id),
                  });
                });
              }
            })
            .catch((error) => {
              swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          toast.success("Exclusion supprimée avec succès.");
        }
      });
  };

  const nameFormater = (cell, row) => {
    return (
      <div className="nameFormatter">
        <span className="name">{cell?.raison_sociale}</span>
      </div>
    );
  };

  const typeFormatter = (cell, row) => {
    return (
      <div className="nameFormatter">
        <span className="name">{cell?.nom}</span> <br />
        <span className="entreprise">{row?.familledesoin?.nom}</span>
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button
          onClick={() => onGetItem(row)}
          className="action-btn show-btn"
          data-toggle="modal"
          data-target=".show-exclusion-modal"
          title="Details"
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
          />
        </button>
        <button
          className="action-btn edit-btn"
          onClick={() => onGetItem(row)}
          data-toggle="modal"
          data-target=".edit-exclusion-modal"
          title="Modification"
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
        <button className="action-btn delete-btn">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            onClick={() => onDelete(row?.id)}
          />
        </button>
      </div>
    );
  };

  const onGetItem = (item) => {
    dispatch({ type: "EDIT_EXCLUSION", payload: item });
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <ExclusionSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={list_exclusions}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="designation"
                  >
                    designation
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="100"
                    dataFormat={(cell, row) => typeFormatter(cell, row)}
                    dataField="typedesoin"
                  >
                    Type de soins
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="center"
                    // width="140"
                    dataField="ipm"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Ipm
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      padding: 15,
                    }}
                    // width="100"
                    dataAlign="center"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal show-exclusion-modal"
            id="ShowExclusionModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="ShowExclusionModalItemLabel"
            aria-hidden="true"
          >
            <ShowExclusion />
          </div>

          <div
            className="modal fade easypm-modal edit-exclusion-modal"
            id="EditExclusionModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="EditExclusionModalItemLabel"
            aria-hidden="true"
          >
            <EditExclusionModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exclusions;
