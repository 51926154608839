import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import baseUrl from "../../Http/backend-base-url";

const ShowCarnetModal = (props) => {
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="SendMessageModal">
            Carnet
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {props?.isLoading === false ? (
          <div className="modal-body">
            <div
              className="pdf-created-row"
              style={{ margin: "0px", padding: "0px" }}
            >
              {/* <div style={{ marginBottom: '3em', marginTop: '3em' }}> */}
              <div className="col-md-6 offset-md-3 pdf-created-link-container">
                {/* <a style={style.download_pdf_} target="_blank" rel="noopener noreferrer" href={Url}> */}
                <a
                  className="pdf-created-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  href={`${baseUrl}${props?.carnet?.carnet_pdf}`}
                  // data-dismiss="modal"
                  onClick={() => {
                    setTimeout(() => {
                      window.$(".show-carnet-modal").modal("hide");
                    }, 1000);
                  }}
                >
                  Télécharger le carnet
                </a>
              </div>
              <div className="col-md-12 pdf-created-viewr-container">
                {props?.carnet?.id && (
                  <iframe
                    className="iframe_pdf"
                    frameBorder="0"
                    src={`${baseUrl}${props?.carnet?.carnet_pdf}`}
                    style={{ width: "100%", height: "500px" }}
                    height="500"
                    width="100%"
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <Skeleton width={`100%`} height={500} />
        )}
      </div>
    </div>
  );
};

export default ShowCarnetModal;
