import React, { useEffect, useContext, useState } from 'react'
import * as IconlyPack from 'react-iconly'
import { DataContext } from '../store/GlobalState'
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/bootstrap.css'

var CRUDAdminIPMModal = (props) => {
  const { state } = useContext(DataContext)
  const { admin_ipm, auth } = state

  const [phone, setPhone] = useState('')
  let userType = window.sessionStorage.getItem('userType')
  // var [admin_ipm, setadmin_ipm] = React.useState(JSON.parse(sessionStorage.getItem('admin_ipm')))
  React.useEffect(() => {
    if (props.actionType === 'edit' && admin_ipm?.id) {
      if (admin_ipm?.telephone.includes('+221')) {
        setPhone(admin_ipm?.telephone)
      } else {
        setPhone("+221"+admin_ipm?.telephone)
      }
      
    } else {
      if (auth?.user?.user_type === "admin_ipm") {
        props.register('parent')
        props.setValue('parent', auth?.user?.parent)
      } else {
        props.register('parent')
        props.setValue('parent', auth?.user?.id)
      }
    }
  }, [admin_ipm?.id, props.actionType, auth])

  const handleSetPhone = (val) => {
    // console.log('phone', val)
    setPhone(val)
    props.register('telephone')
    props.setValue('telephone', '+' + val)
  }

  const handleReset = () => {
    props.reset();
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Ajouter un admin
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => handleReset()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {props?.actionType === "create" && (
              <form className="crud-form" onSubmit={props.onSubmit}>
                <div className="row crud-row">
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group required crud-form-group">
                      <label htmlFor="prenom">Prénom</label>
                      <input
                        {...props.register("prenom")}
                        type="text"
                        placeholder="Prénom"
                        name="prenom"
                        className="form-control crud-form-control"
                        id="first_name"
                      />
                      {props?.formState?.errors &&
                        props?.formState?.errors?.prenom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props?.formState?.errors?.prenom?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group required crud-form-group">
                      <label htmlFor="nom">Nom</label>
                      <input
                        {...props.register("nom")}
                        type="text"
                        placeholder="Nom"
                        name="nom"
                        className="form-control crud-form-control"
                        id="last_name"
                      />
                      {props?.formState?.errors &&
                        props?.formState?.errors?.nom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props?.formState?.errors?.nom?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group required crud-form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        {...props.register("email")}
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="form-control crud-form-control"
                        id="email"
                      />
                      {props?.formState?.errors &&
                        props?.formState?.errors?.email && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props?.formState?.errors?.email?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group required crud-form-group">
                      <label htmlFor="telephone">Téléphone</label>
                      {/* <input
                        {...props.register('telephone')}
                        type="text"
                        placeholder="+221780158171"
                        name="telephone"
                        className="form-control crud-form-control"
                        id="telephone"
                      /> */}
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control crud-form-control"
                        country={"sn"}
                        onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      {/* <div className="input-infos-container">
                        <IconlyPack.Danger
                          set="light"
                          primaryColor="gray"
                          className="alerty-icon"
                        />
                        &nbsp;
                        <span className="input-infos">
                          Le numéro de téléphone doit être au format
                          +221780158171
                        </span>
                      </div> */}
                      {props?.formState?.errors &&
                        props?.formState?.errors?.telephone && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props?.formState?.errors?.telephone?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* <div className="col-md-6 crud-col-item">
                    <div className="form-group required crud-form-group">
                      <label htmlFor="adresse">Adresse</label>
                      <input
                        {...props.register("adresse")}
                        type="text"
                        placeholder="Adresse"
                        name="adresse"
                        className="form-control crud-form-control"
                        id="adresse"
                      />
                      {props?.formState?.errors &&
                        props?.formState?.errors?.adresse && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props?.formState?.errors?.adresse?.message}
                          </div>
                        )}
                    </div>
                  </div> */}

                  {/* <input
                    {...props.register('parent')}
                    type="hidden"
                    name="parent"
                    value={auth?.user?.user_type === 'admin_ipm' ? auth?.user?.parent : props.fromIpmID}
                  /> */}
                  {/* <input
                    {...props.register('user_type')}
                    type="hidden"
                    name="user_type"
                    value={`admin_ipm`}
                  />
                  <input
                    {...props.register('admin_type')}
                    type="hidden"
                    name="admin_type"
                    value={`ipm`}
                  /> */}

                  {userType === "entreprise" && (
                    <input
                      {...props.register("user_type")}
                      type="hidden"
                      name="user_type"
                      value={`entreprise`}
                    />
                  )}

                  {(userType === "ipm" ||
                    userType === "assurance_sante" ||
                    userType === "admin_ipm") && (
                    <input
                      {...props.register("user_type")}
                      type="hidden"
                      name="user_type"
                      value={`admin_ipm`}
                    />
                  )}

                  {(userType === "ipm" ||
                    userType === "assurance_sante" ||
                    userType === "admin_ipm") && (
                    <input
                      {...props.register("admin_type")}
                      type="hidden"
                      name="admin_type"
                      value={`ipm`}
                    />
                  )}

                  {userType === "entreprise" && (
                    <input
                      {...props.register("admin_type")}
                      type="hidden"
                      name="admin_type"
                      value={`entreprise`}
                    />
                  )}

                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    {/* <button className="btn btn-default cancelleb-btn" data-dismiss="modal"
                     onClick={() => handleReset()}>Retour</button>
                                        <button className="btn btn-primary crud-submit-btn">Enregistrer</button> */}
                    <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                      onClick={() => handleReset()}
                    >
                      Retour
                    </button>
                    {props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                    {!props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        Enregistrer
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}

            {props?.actionType === "edit" && (
              <form className="crud-form" onSubmit={props.onSubmit}>
                <div className="row crud-row">
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="prenom">Prénom</label>
                      <input
                        defaultValue={admin_ipm?.prenom}
                        {...props.register("prenom")}
                        type="text"
                        placeholder="Prénom"
                        name="prenom"
                        className="form-control crud-form-control"
                        id="edit_first_name"
                      />
                      {props?.formState?.errors &&
                        props?.formState?.errors?.prenom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props?.formState?.errors?.prenom?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="nom">Nom</label>
                      <input
                        defaultValue={admin_ipm?.nom}
                        {...props.register("nom")}
                        type="text"
                        placeholder="Nom"
                        name="nom"
                        className="form-control crud-form-control"
                        id="edit_last_name"
                      />
                      {props?.formState?.errors &&
                        props?.formState?.errors?.nom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props?.formState?.errors?.nom?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        defaultValue={admin_ipm?.email}
                        {...props.register("email")}
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="form-control crud-form-control"
                        id="edit_email"
                      />
                      {props?.formState?.errors &&
                        props?.formState?.errors?.email && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props?.formState?.errors?.email?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="telephone">Téléphone</label>
                      {/* <input
                        defaultValue={admin_ipm?.telephone}
                        {...props.register('telephone')}
                        type="text"
                        placeholder="+221780158171"
                        name="telephone"
                        className="form-control crud-form-control"
                        id="edit_telephone"
                      /> */}
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control crud-form-control"
                        country={"sn"}
                        onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      {props?.formState?.errors &&
                        props?.formState?.errors?.telephone && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props?.formState?.errors?.telephone?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  {/* <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="adresse">Adresse</label>
                      <input
                        defaultValue={admin_ipm?.adresse}
                        {...props.register('adresse')}
                        type="text"
                        placeholder="Adresse"
                        name="adresse"
                        className="form-control crud-form-control"
                        id="edit_adresse"
                      />
                      {props?.formState?.errors &&
                        props?.formState?.errors?.adresse && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props?.formState?.errors?.adresse?.message}
                          </div>
                        )}
                    </div>
                  </div> */}

                  <input
                    value={admin_ipm?.parent}
                    {...props.register("parent")}
                    type="hidden"
                    name="parent"
                  />
                  {userType === "entreprise" && (
                    <input
                      {...props.register("user_type")}
                      type="hidden"
                      name="user_type"
                      value={`entreprise`}
                    />
                  )}

                  {(userType === "ipm" || userType === "assurance_sante") && (
                    <input
                      {...props.register("user_type")}
                      type="hidden"
                      name="user_type"
                      value={`admin_ipm`}
                    />
                  )}

                  {(userType === "ipm" || userType === "assurance_sante") && (
                    <input
                      {...props.register("admin_type")}
                      type="hidden"
                      name="admin_type"
                      value={`ipm`}
                    />
                  )}

                  {userType === "entreprise" && (
                    <input
                      {...props.register("admin_type")}
                      type="hidden"
                      name="admin_type"
                      value={`entreprise`}
                    />
                  )}

                  <input
                    value={admin_ipm?.id}
                    {...props.register("id")}
                    type="hidden"
                    name="id"
                  />

                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    {/* <button className="btn btn-default cancelleb-btn" data-dismiss="modal">Retour</button>
                                        <button className="btn btn-primary crud-submit-btn">Enregistrer</button> */}
                    <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                    >
                      Retour
                    </button>
                    {props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                    {!props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        Modifier
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CRUDAdminIPMModal
