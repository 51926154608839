import React, { useEffect, useContext } from 'react'
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from 'react-bootstrap-table'
import * as IconlyPack from 'react-iconly'
import { NavLink } from 'react-router-dom'
import GestionSoinAssureurModal from '../../../Backend/Assurance/GestionSoinsAssureur/GestionSoinAssureur'
import PriseEnChargeAdminTableData from './PriseEnChargeAdminTableData'
import PriseEnChargeAdminTableSkeleton from './PriseEnChargeAdminTableSkeleton'
// import FactureModalAssureur from './FactureModalAssureur'
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'
import FeatherIcon from "feather-icons-react";

var PriseEnChargeAdminTable = (props) => {

 
  const [soins, setSoins] = React.useState(PriseEnChargeAdminTableData)

  var [isPageLoading, setIsPageLoading] = React.useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false)
    }, 3000)
  }, [soins])

  var handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event')
    onClick()
  }

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    )
  }

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: 'Aucune donnée disponible',
  }
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <button className="action-btn edit-btn" title="Traitement">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#GenererFactureClientModal"
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <FeatherIcon
            icon="edit-2"
            style={{ color: "#C8C8C8" }}
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#factureAssureur"
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <FeatherIcon
            icon="trash-2"
            style={{ color: "#C8C8C8" }}
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#factureAssureur"
          />
        </button>
      </div>
    );
  };
  
 
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <PriseEnChargeAdminTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={soins}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="150"
                    dataField="famille"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Famille de soins
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="150"
                    dataField="type_soin"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Type de soins
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="libelle"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Libellé
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="taux"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Taux
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="plafond"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Plafond
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionSoinModal"
            tabIndex="-1"
            aria-labelledby="GestionSoinModalLabel"
            data-backdrop="static"
            aria-hidden="true"
        >
            <GestionSoinAssureurModal />
        </div>
        {/* <div
            className="modal fade easypm-modal add-soin-modal"
            id="factureAssureur"
            tabIndex="-1"
            aria-labelledby="factureAssureurLabel"
            data-backdrop="static"
            aria-hidden="true"
        >
            <FactureModalAssureur />
        </div> */}
        </div>
      </div>
    </div>
  )
}

export default PriseEnChargeAdminTable
