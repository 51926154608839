import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import http from "../../../../Http/http/http";
import { useMutation } from "../../../../Http/http/mutation";
import { useQuery } from "../../../../Http/http/query";
import { toast } from "react-toastify";
import {
  getName,
  MyButton,
  MyInput,
  useCurrentUser,
  useFormData,
  useFormValidation,
} from "../../../../utils/utils";
const isNow = (date) => {
  return date.format("L") === moment().format("L");
};
export function getHours(date, disponibilite) {
  if (!disponibilite) disponibilite = [];
  disponibilite =
    typeof disponibilite === "string"
      ? JSON.parse(disponibilite)
      : disponibilite;
  if (date && isNow(date)) {
    return disponibilite
      .sort((a, b) => a > b)
      .filter((time) => {
        return time > parseInt(moment().format("HH"));
      });
  }
  return disponibilite.sort((a, b) => a > b);
}
const AjouterRdv = ({ prestataire, rv, onDone }) => {
  const user = useCurrentUser();
  const [prestataires, setPrestataires] = React.useState([]);
  const query = useQuery(null);
  const queryHistory = useQuery(null);
  const fetchPrestataires = React.useCallback(() => {
    query.getData("/prestatairebyvisitor/");
  }, []);
  React.useEffect(() => {
    if (prestataire) {
      setPrestataires([
        {
          label: getName(prestataire),
          value: prestataire.id,
          disponibilite: prestataire.disponibilite,
        },
      ]);
    } else {
      setPrestataires(
        query.data
          .filter((f) => !!f.specialite)
          .map((c) => ({
            label: getName(c),
            value: c.id,
            disponibilite: c.disponibilite,
          }))
      );
    }
  }, [query.data, prestataire]);
  React.useEffect(() => {
    if (!prestataire) {
      fetchPrestataires();
    } else {
      onChange("prestataire", prestataire.id);
    }
  }, [prestataire]);

  const [data, onChange] = useFormData({
    motif: rv?.motif || "",
    date: rv?.date || moment().format("YYYY-MM-DD"),
    heure: rv?.heure || "",
    adresse: rv?.adresse || "",
    latitude: rv?.latitude || "",
    longitude: rv?.longitude || "",
    prestataire: prestataire?.id || "",
    beneficiaire: "",
  });
  React.useEffect(() => {
    if (data.prestataire) {
      queryHistory.getData(`/rdvbyprestatairegtedate/${data.prestataire}/`);
    }
  }, [data.prestataire]);
  const [errors, validate] = useFormValidation({}, (Yup) => ({
    motif: Yup.string().required().label("Motif").min(10),
    date: Yup.string().required().label("Date"),
    heure: Yup.string().required("Veuillez sélectionner une heure"),
    prestataire: Yup.string()
      .required("Veuillez sélectionner un prestataire")
      .label("Heure"),
    adresse: Yup.string().required().label("Localisation"),
  }));
  const [takeds, setTakeds] = React.useState([]);
  const [times, setTimes] = React.useState([]);
  React.useEffect(() => {
    const selected = prestataires.find((p) => p.value == data.prestataire);
    setTimes(getHours(moment(data.date), selected?.disponibilite));
  }, [data.date, data.prestataire, prestataires]);
  React.useEffect(() => {
    const filtred = queryHistory.data.filter(
      (f) => moment(f.date).format("L") === moment(data.date).format("L")
    );
    setTakeds(filtred.map((f) => f.heure));
    onChange("heure", "");
  }, [data.date, queryHistory.data]);
  const { loading, exec } = useMutation((data) => http.post("/rdv/", data));
  const { loading: loading1, exec: exec1 } = useMutation((data) =>
    http.put(`/rdv/${rv?.id}/`, data)
  );
  const onSubmit = React.useCallback(() => {
    validate(data)
      .then(async () => {
        try {
          data.beneficiaire = user?.id;
          console.log({ data });
          if (rv) {
            await exec1(data);
            toast.success("Rendez-vous déplacée avec succès");
          } else {
            await exec(data);
            toast.success("Demande de rendez-vous envoyée avec succès");
            onChange({
              motif: "",
              date: moment().format("YYYY-MM-DD"),
              heure: "",
              adresse: "",
              latitude: "",
              longitude: "",
              prestataire: prestataire?.id || "",
            });
            queryHistory.getData(
              `/rdvbyprestatairegtedate/${data.prestataire}/`
            );
          }
          onDone();
        } catch (ex) {
          console.log("response", ex.response);
          toast.error(ex?.message);
        }
      })
      .catch((ex) => null);
  }, [data, user, rv]);

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AjouterRdv">
            {rv ? "Déplacer un rendez-vous" : "Ajouter un rendez-vous"}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item pb-3">
                  <div class="form-group">
                    <select
                      value={data.prestataire}
                      onChange={(e) => {
                        e.preventDefault();
                        onChange("prestataire", e.currentTarget.value);
                      }}
                      class="form-control input-event-agenda"
                      id="exampleFormControlSelect1"
                    >
                      {!prestataire && <option value={""}>Prestataire</option>}
                      {prestataires.map((n) => (
                        <option value={n.value}>{n.label}</option>
                      ))}
                    </select>
                    {!!errors.prestataire && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {errors.prestataire}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item pb-3">
                  <MyInput
                    type="text"
                    placeholder="Motif de consultation"
                    name="motif"
                    value={data.motif}
                    onChange={(v) => onChange("motif", v)}
                    error={errors.motif}
                    className="form-control input-event-agenda"
                  />
                </div>
                <div className="col-md-12 crud-col-item pb-3">
                  <div class="pb-4">
                    <label class="label-form-add-event mb-2" for="date">
                      Date
                    </label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <IconlyPack.Calendar
                            set="bold"
                            primaryColor="#DADADA"
                          />
                        </div>
                      </div>
                      <input
                        type="date"
                        value={data.date}
                        name="date"
                        onChange={(e) => {
                          e.preventDefault();
                          const val = e.currentTarget.value;
                          if (val) {
                            if (
                              moment(moment().format("YYYY-MM-DD")) <=
                              moment(val)
                            ) {
                              onChange("date", val);
                            } else {
                              toast.dismiss("date");
                              toast.error(
                                "Veuillez sélectionner une date valide",
                                { toastId: "date" }
                              );
                            }
                          } else {
                            onChange("date", val);
                          }
                        }}
                        class="form-control input-add-event pl-5"
                        id="date"
                        placeholder="Choisir une date"
                      />
                      {!!errors.date && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {errors.date}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="checkbox-add-event mt-4">
                    <label
                      class="label-form-add-event mb-2"
                      for="inlineFormInputGroup"
                    >
                      Heure
                    </label>

                    <div className="row">
                      {queryHistory.loading && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <i className="fas fa-spin fa-spinner"></i>
                        </div>
                      )}
                      {times.map((time, index) => {
                        return (
                          <TimeItem
                            onPress={() => {
                              if (data.heure === time) {
                                onChange("heure", "");
                              } else {
                                onChange("heure", time);
                              }
                            }}
                            taked={takeds.includes(time)}
                            checked={data.heure === time}
                            time={time}
                            key={index}
                          />
                        );
                      })}
                    </div>
                    {!!errors.heure && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {errors.heure}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item pt-4 pb-3">
                  <div class="pb-4">
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <IconlyPack.Location
                            set="bold"
                            primaryColor="#DADADA"
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        class="form-control input-add-event pl-5"
                        id="inlineFormInputGroup"
                        value={data.adresse}
                        onChange={(e) =>
                          onChange("adresse", e.currentTarget.value)
                        }
                        placeholder="Localisation"
                      />
                    </div>
                    {!!errors.adresse && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {errors.adresse}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  <MyButton
                    loading={loading1 || loading}
                    title="Enregistrer"
                    onClick={onSubmit}
                    className="btn crud-submit-btn-beneficiaire"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterRdv;

export function TimeItem({ time, onPress, checked, taked, col12, colLess }) {
  return (
    <div className={colLess ? "ml-2" : col12 ? "col-12" : "col-md-3 col-sm-6"}>
      <div
        class={col12 ? "container-checkbox text-center" : "container-checkbox"}
      >
        <label className={taked ? "checked" : ""}>
          <input
            onChange={(e) => {
              if (taked) {
                toast.error(
                  "Ce prestataire n'est pas disponible dans ce créneau"
                );
                return;
              }
              onPress();
            }}
            checked={checked}
            type="checkbox"
          />
          <span>{time}h</span>
        </label>
      </div>
    </div>
  );
}
