import { typePrestation } from './Endpoints'
import axios from 'axios'

export default {
    async getListTypePrestation(config) {
        const response = await axios.get(typePrestation.onGetOrPosttypePrestation(), config);
        // console.log(response)
        return response.data;
    },
    async postCreateTypePrestation(data, config) {
        const response = await axios.post(typePrestation.onGetOrPostTypePrestation(), data, config);
        return response.data;
    },
    async putTypePrestation(id, data, config) {
        const response = await axios.put(typePrestation.onPutTypePrestation(id), data, config);
        return response.data;
    },
    async deleteTypePrestation(id, config) {
        const response = await axios.delete(typePrestation.onDeleteTypePrestation(id), config);
        return response;
    }

}