import React, { useContext } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import IpmAdminTable from "../../../Tables/IpmAdminTable/IpmAdminTable";
import CRUDIpmModal from "../../../Modals/CRUDIpmModal";
import { DataContext } from "../../../store/GlobalState";
import UseCreateIpmForm from "./UseCreateIpmForm";
import SendMessageModal from "../../../Modals/SendMessageModal";
import { getData, getDataByID } from "../../../utils/fetchData";

var GestionIPM = () => {
  const {
    register,
    reset,
    errors,
    formState,
    ipmData,
    setIpmData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    setValue,
  } = UseCreateIpmForm();
  const { state, dispatch } = useContext(DataContext);

  const { auth, ipms } = state;

  React.useEffect(() => {
    if (auth?.user?.id && auth?.token) {
      getData("assurance_maladies", auth.token).then((res) => {
        // console.log('data', res.results)
        dispatch({
          type: "ADD_IPM",
          payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
        });
      });
      getDataByID("notificationbysuperadmin", auth?.user?.id, auth?.token).then((res) => {
        // console.log("notif", res);
        dispatch({
          type: "LIST_NOTIFICATION",
          payload: res.sort((a, b) => a?.id - b?.id).reverse(),
        });
      });
    }
  }, [auth, dispatch]);

  React.useEffect(() => {
    // onGetListEntreprises()

    if (isSuccessfullySubmitted) {
      setIpmData(ipmData);
      setTimeout(() => {
        setIsSuccessfullySubmitted(false);
      }, 5000);
    }

    if (isFormRequestError) {
      setIsSuccessfullySubmitted(false);
      setIsFormRequestError(true);
      setTimeout(() => {
        setIsFormRequestError(false);
      }, 5000);
    }

    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [
    errors,
    isSuccessfullySubmitted,
    formState,
    isFormRequestError,
    clearErrors,
    setIsFormRequestError,
    setIsSuccessfullySubmitted,
    setIpmData,
    ipmData,
  ]);

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      IPMS / ASSUREURS &nbsp;
                      <span className="badge badge-info easypm-badge-info">
                        {" "}
                        {ipms?.length}{" "}
                      </span>
                    </h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target=".add-ipm-modal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Ajouter une IPM / ASSUREUR
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <IpmAdminTable />
                </div>
                {/* Modal */}
                <div
                  className="modal fade easypm-modal add-ipm-modal"
                  id="AddIPMModalItem2"
                  tabIndex="-1"
                  aria-labelledby="AddIPMModalItem2Label"
                  aria-hidden="true"
                  data-backdrop="static"
                >
                  <CRUDIpmModal
                    register={register}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    reset={reset}
                    setValue={setValue}
                  />
                </div>

                {/* Modal send message */}
                <div
                  className="modal fade easypm-modal send-message-modal"
                  id="SendMessageModal"
                  tabIndex="-1"
                  aria-labelledby="SendMessageModallabel"
                  aria-hidden="true"
                >
                  <SendMessageModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GestionIPM;
