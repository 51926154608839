/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
// import Header from '../../../Navs/Header'
// import NavBarVerticalV2 from '../../navbarVerticalV2/navbarVerticalV2'
// import * as IconlyPack from 'react-iconly'
import { DataContext } from "../../../store/GlobalState";
import { useHistory, useParams } from "react-router";
import { getDataByID } from "../../../utils/fetchData";
import { formatMoney } from "../../../../utils/utils";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

const PrestationsByBeneficiaire = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [prestataionsbybeneficiaires, setPrestataionsbybeneficiaires] =
    React.useState([]);
  const [limit, setLimit] = React.useState(10);
  const [totalCout, setTotalCout] = React.useState(0);
  const [totalIpm, setTotalIpm] = React.useState(0);
  const [totalBenef, setTotalBenef] = React.useState(0);
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const params = useParams();
  // const history = useHistory()

  useEffect(() => {
    if (auth?.token) {
      getDataByID("prestationbybeneficiaire", params?.id, auth?.token)
        .then((res) => {
          //   console.log('res', res)
          setPrestataionsbybeneficiaires(
            res.sort((a, b) => new Date(b.date) - new Date(a.date))
          );
          let total_cout = 0;
          let total_ipm = 0;
          let total_benef = 0;
          res.forEach((element) => {
            total_cout += parseInt(element.montant);
            total_ipm += parseInt(element.montant_rembourse);
            total_benef += parseInt(element.montant_retenu);
          });
          // console.log('total', total_cout)
          setTotalCout(total_cout);
          setTotalIpm(total_ipm);
          setTotalBenef(total_benef);
          setIsPageLoading(false);
        })
        .catch((e) => {
          console.log("res err", e);
        });
    }
  }, [auth, params]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const goBack = () => {
    // history.goBack()
    props.setShowSoin();
  };
  return (
    // <div className="dashboard-item-container">
    //   <div className="container-fluid body-background-dashboard">
    //     <Header />

    //     <div className="row">
    //       {/* Navbar Desktop  */}
    //       <div className="nav-container">
    //         <NavBarVerticalV2 />
    //       </div>

    //       <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
    //         <div className="page-body-container">
    //           {/** Here */}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="page-content-container">
      <div className="page-first-row-container">
        <div className="page-title-container">
          <div
            aria-hidden="true"
            style={{ marginTop: "13px", marginRight: "33px" }}
          >
            <button
              className="btn btn-success crud-submit-btn"
              onClick={goBack}
            >
              <i className="fas fa-long-arrow-alt-left"></i> Retour
            </button>
          </div>
          <h1 className="page-title">
            Soins {!isPageLoading ? `de ${props?.fullName}` : null}
          </h1>
        </div>
      </div>

      <div className="parent-table-container">
        {/* <Prestation /> */}
        {!isPageLoading ? (
          <>
            <div className="row mb-3 px-2">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                <div className="card-statistics card-statistics-color-1 d-block">
                  <div className="stat-conteny">
                    <p className="parent-label-card-statistics">
                      Coût total prestations
                    </p>
                    {/* <p className="child-label-card-statistics">En CFA</p> */}
                  </div>
                  <div className="stat-counter text-right">
                    <div
                      className="label-stat-counter"
                      style={{ fontSize: "25px" }}
                    >
                      {/* {totalCout} */}
                      {/* {new Intl.NumberFormat('sn', {
                        currency: 'XOF',
                      }).format(totalCout)} */}
                      {formatMoney(parseInt(totalCout))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                <div className="card-statistics card-statistics-color-2 d-block">
                  <div className="stat-conteny">
                    <p className="parent-label-card-statistics">
                      Total part Ipm
                    </p>
                    {/* <p className="child-label-card-statistics">En CFA</p> */}
                  </div>
                  <div className="stat-counter text-right">
                    <div
                      className="label-stat-counter"
                      style={{ fontSize: "25px" }}
                    >
                      {/* {new Intl.NumberFormat('sn', {
                        currency: 'XOF',
                      }).format(totalIpm)} */}
                      {formatMoney(parseInt(totalIpm))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                <div className="card-statistics card-statistics-color-3 d-block">
                  <div className="stat-conteny">
                    <p className="parent-label-card-statistics">
                      Total part salariés
                    </p>
                    {/* <p className="child-label-card-statistics">En CFA</p> */}
                  </div>
                  <div className="stat-counter text-right">
                    <div
                      className="label-stat-counter"
                      style={{ fontSize: "25px" }}
                    >
                      {/* {new Intl.NumberFormat('sn', {
                        currency: 'XOF',
                      }).format(totalBenef)} */}
                      {formatMoney(parseInt(totalBenef))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="accordion">
              <InfiniteScroll
                dataLength={limit}
                next={fetchMoreData}
                hasMore={true}
                loader={
                  prestataionsbybeneficiaires?.length > 0 &&
                  prestataionsbybeneficiaires?.length > limit ? (
                    <h4>Loading...</h4>
                  ) : null
                }
              >
                {prestataionsbybeneficiaires
                  .slice(0, limit)
                  .map((prestation, index) => (
                    <div className="card" key={index}>
                      <div className="card-header" id="headingOne">
                        <div className="row">
                          <div className="col-md-6">
                            <a
                              className="link-collapse-session-item collapsed"
                              data-toggle="collapse"
                              data-target={`#collapseOne-${index}`}
                              aria-expanded="true"
                              aria-controls="collapseOne"
                              role="button"
                            >
                              <i
                                className="fa chevron-collapse-session-item"
                                aria-expanded="false"
                              ></i>
                              {prestation?.prestataire?.raison_sociale ||
                                prestation?.prestataire?.prenom +
                                  " " +
                                  prestation?.prestataire?.nom}
                            </a>
                            <br />
                            <span
                              style={{ marginLeft: "20px", fontWeight: 500 }}
                            >
                              {moment(prestation?.date).format("DD/MM/YYYY")}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <span style={{ fontWeight: 500 }}>
                              Part Adhérent:{" "}
                              <strong>
                                {/* {prestation?.montant_retenu} */}
                                {new Intl.NumberFormat("sn", {
                                  currency: "XOF",
                                  style: "currency",
                                }).format(prestation?.montant_retenu)}
                              </strong>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        id={`collapseOne-${index}`}
                        className="collapse"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div
                          className="card-body"
                          style={{ backgroundColor: "whitesmoke" }}
                        >
                          <div className="crud-form">
                            <div
                              className="row crud-row"
                              style={{ paddingBottom: "10px" }}
                            >
                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <strong>Adhérent: </strong>

                                  <span className="crud-form-control">
                                    {`${
                                      prestation?.beneficiaire?.nom +
                                      " " +
                                      prestation?.beneficiaire?.prenom
                                    }`}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <strong>Ayant droit: </strong>

                                  <span className="crud-form-control">
                                    {prestation?.ayantdroit
                                      ? `${
                                          prestation?.ayantdroit?.nom +
                                          " " +
                                          prestation?.ayantdroit?.prenom
                                        }`
                                      : null}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <strong>Type de prestation: </strong>

                                  <span className="crud-form-control">
                                    {`${prestation?.type_prestation?.libelle}`}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <strong htmlFor="name">Coût: </strong>
                                  <span className="crud-form-control">
                                    {/* {prestation?.montant} */}
                                    {formatMoney(parseInt(prestation?.montant))}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <strong htmlFor="specialite">
                                    Part Ipm:{" "}
                                  </strong>
                                  <span className="crud-form-control">
                                    {/* {prestation?.montant_rembourse} */}
                                    {formatMoney(
                                      parseInt(prestation?.montant_rembourse)
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <strong htmlFor="specialite">
                                    Part Participant:{" "}
                                  </strong>
                                  <span className="crud-form-control">
                                    {/* {prestation?.montant_retenu} */}
                                    {formatMoney(
                                      parseInt(prestation?.montant_retenu)
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <strong htmlFor="specialite">
                                    Fournisseurs:{" "}
                                  </strong>
                                  <span className="crud-form-control">
                                    {prestation?.prestataire?.raison_sociale ||
                                      prestation?.prestataire?.prenom +
                                        " " +
                                        prestation?.prestataire?.nom}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </InfiniteScroll>
            </div>
          </>
        ) : (
          <div>
            <div className="row mb-3 px-2">
              <CardSkeleton />
            </div>
            {Array(6)
              .fill()
              .map((item, index) => (
                <div className="item-session-details-page" key={index}>
                  <h6 className="text-up text-dark f-f-work fw-600">
                    <Skeleton
                      className="link-details-sessions-on-title"
                      variant="text"
                      height={50}
                      width={`100%`}
                    />
                  </h6>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PrestationsByBeneficiaire;

const CardSkeleton = () => {
  const renderModules = Array(3)
    .fill()
    .map((item, index) => (
      <div
        className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2"
        key={index}
      >
        <div className="card">
          <Skeleton
            variant="rect"
            style={{ borderRadius: "10px" }}
            width={332}
            height={100}
          />
        </div>
      </div>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
