import React from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import GestionSoinsBeneficiaireTableSkeleton from "./GestionSoinsBeneficiaireTableSkeleton";
import * as IconlyPack from "react-iconly";
import GestionSoinBeneficiaireModal from "../../../Backend/Beneficiaire/FicheDeSoinsBeneficiaire/GestionSoinBeneficiaire";
import ModifierSoinModal from "../../../Backend/Beneficiaire/FicheDeSoinsBeneficiaire/ModifierSoinModal";
import { getDate, getName, Status } from "../../../../utils/utils";
import http, { ApiUrl } from "../../../../Http/http/http";
import { toast } from "react-toastify";
var GestionSoinsBeneficiaireTable = ({
  data: soins,
  loading,
  onEdit,
  onDeleted,
}) => {
  const [deleting, setDeleting] = React.useState(null);

  React.useEffect(() => {
    if (deleting) {
      http
        .delete("/fichedesoin/" + deleting + "/")
        .then((res) => {
          toast.success("Fiche de soin supprimée avec succès");
          setDeleting(null);
          onDeleted();
        })
        .catch((ex) => {
          setDeleting(null);
        });
    }
  }, [deleting]);
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
    expandBy: "column",
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-Beneficiaire">
        {row.etat === "new" && (
          <>
            <button className="action-btn edit-btn" title="Modifier">
              <IconlyPack.Edit
                set="bold"
                primaryColor="#C8C8C8"
                className="action-btn-icon action-edit-icon"
                data-toggle="modal"
                onClick={() => {
                  onEdit(row);
                }}
                data-target="#DemandeFicheSoinsModal"
              />
            </button>
            <button className="action-btn edit-btn" title="Supprimer">
              {deleting !== row.id && (
                <IconlyPack.Delete
                  set="bold"
                  primaryColor="#f00"
                  onClick={() => {
                    const ok = window.confirm(
                      "êtes-vous sûr de supprimer cette fiche de soin"
                    );
                    if (ok) {
                      setDeleting(row.id);
                    }
                  }}
                  className="action-btn-icon action-edit-icon"
                />
              )}
              {deleting === row.id && (
                <i className="fas fa-spin fa-spinner"></i>
              )}
            </button>
          </>
        )}
      </div>
    );
  };

  const beneficaireFormatter = (cell, row) => {
    const benf = row.ayant_droit || row.beneficiaire;
    const entreprise = row.beneficiaire.employeur;
    return (
      <div className="nameFormatter">
        <span className="name">{getName(benf)}</span> <br />
        <strong className="entreprise"> {entreprise?.nom} </strong>{" "}
      </div>
    );
  };
  const renderRow = React.useCallback((name, label) => {
    const labelCmp =
      typeof label === "string" ? (
        <span className="crud-form-control">{label}</span>
      ) : (
        label
      );
    return (
      <div className="col-md-6 crud-col-item">
        <div
          style={{ whiteSpace: "initial" }}
          className="form-group crud-form-group"
        >
          <strong>{name} :</strong>
          {labelCmp}
        </div>
      </div>
    );
  }, []);
  const expandComponent = React.useCallback((row) => {
    return (
      <div>
        <div className="row crud-row" style={{ paddingBottom: "10px" }}>
          {renderRow("Adhérent", getName(row.beneficiaire || {}))}
          {renderRow(
            "Bénéficiaire",
            getName(row.ayant_droit || row.beneficiaire)
          )}
          {renderRow("Entreprise", row.beneficiaire?.employeur?.nom)}
          {renderRow("Prestataire", getName(row?.prestataire))}
          {renderRow("Date de consultation", getDate(row.date))}
          {!!row.ordonnance_file &&
            renderRow(
              "Ordonnance",
              <span className="crud-form-control">
                <a
                  href={ApiUrl + row.ordonnance_file}
                  target="_blank"
                  title="Télécharger"
                >
                  Télécharger
                </a>
              </span>
            )}
          {row.etat == "validee" && !!row.file_pdf && (
            <div className="col-md-12 crud-col-item">
              <a
                href={ApiUrl + row.file_pdf}
                title="Télécharger la fiche"
                title="Télécharger la fiche"
                className="btn  crud-submit-btn-assurance"
              >
                Télécharger la fiche
              </a>
            </div>
          )}
          {!!row.qrcode && (
            <div className="col-md-12 crud-col-item mt-3 mb-3 justify-content-center d-flex">
              <img
                title="Télécharger la fiche"
                src={ApiUrl + row.qrcode}
                style={{ width: 200, height: "auto" }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }, []);
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {loading && <GestionSoinsBeneficiaireTableSkeleton />}
            {!loading && (
              <div className="table-container">
                <BootstrapTable
                  data={soins}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                  striped={true}
                  hover={true}
                  expandableRow={() => true}
                  expandComponent={expandComponent}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="beneficiaire"
                    dataFormat={(cell, row) => beneficaireFormatter(cell, row)}
                    filter={{
                      defaultValue: "",
                    }}
                  >
                    Bénéficiaires
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="prestataire"
                    dataFormat={(_, row) => {
                      const prestataire =
                        row?.prestataire || row?.prestation?.prestataire;
                      return getName(prestataire);
                    }}
                    filterValue={(_, row) => {
                      const prestataire =
                        row?.prestataire || row?.prestation?.prestataire;
                      return getName(prestataire);
                    }}
                    filter={{ type: "TextFilter" }}
                  >
                    Prestataires
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="date"
                    dataFormat={(_, row) => getDate(row.date)}
                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="etat"
                    dataFormat={(_, row) => <Status etat={row.etat} />}
                  >
                    État
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GenererSoinsBeneficiaire"
            tabIndex="-1"
            aria-labelledby="GenererSoinsBeneficiaireLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <GestionSoinBeneficiaireModal />
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionSoinEditBeneficiaireModal"
            tabIndex="-1"
            aria-labelledby="GestionSoinEditBeneficiaireModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <ModifierSoinModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionSoinsBeneficiaireTable;
