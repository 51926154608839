import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import FacturationSkeleton from "./FacturationSkeleton";
import * as IconlyPack from "react-iconly";
import { NavLink, useLocation } from "react-router-dom";
import moment from "moment";
import { DataContext } from "../../store/GlobalState";
import FactureValidationModal from "../../Modals/FactureValidationModal";
import { formatMoney } from "../../../utils/utils";
import baseUrl from "../../../Http/backend-base-url";
import { getDataByID } from "../../utils/fetchData";

function FactureEntreprise() {
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);
  const { facturebyipm, auth, facturebyentreprise } = state;
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 5000);
  }, []);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
    expandBy: "column",
  };

  const nameFormater = (cell, row) => {
    if (auth?.user?.user_type === "entreprise") {
      return <span className="name"> {row?.ipm?.raison_sociale} </span>;
    } else {
      return <span className="name"> {row?.entreprise?.raison_sociale} </span>;
    }
  };

  const dateFormatter = (cell, row) => {
    return moment(new Date(cell)).format("DD/MM/YYYY");
  };

  const periodeFormatter = (cell, row) => {
    return (
      moment(new Date(row?.date_debut)).format("DD/MM/YYYY") +
      " au " +
      moment(new Date(row?.date_fin)).format("DD/MM/YYYY")
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn edit-btn" title="Traitement">
          <IconlyPack.Paper
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".facture-valid-modal"
            onClick={() => onGetItem(row)}
          />
        </button>
        <button className="action-btn add-btn" title="Voir le fichier">
          <IconlyPack.Download
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-add-icon"
            data-toggle="modal"
            // data-target=".show-facture-modal"
            data-target="#facturePdfModal"
            onClick={() => onGetItem(row)}
          />
        </button>
        {/* <button className="action-btn delete-btn">
                    <IconlyPack.Delete set="light" primaryColor="#C8C8C8" className="action-btn-icon action-delete-icon"/>
                </button> */}
      </div>
    );
  };

  const etatFormatter = (cell, row) => {
    if (auth?.user?.user_type === "entreprise") {
      if (cell === "rejete") {
        return (
          <span
            className="badge badge-danger easypm-badge-danger"
            // onClick={() => onGetItem(row)}
          >
            Rejeté
          </span>
        );
      }
      if (cell === "envoye") {
        return (
          <span
            className="badge badge-info easypm-badge-info"
            // onClick={() => onGetItem(row)}
          >
            Envoyé
          </span>
        );
      }
      if (cell === "regle") {
        return (
          <span
            className="badge badge-success easypm-badge-success"
            // onClick={() => onGetItem(row)}
          >
            Réglé
          </span>
        );
      }
      if (cell === "reçu") {
        return (
          <span
            className="badge badge-warning easypm-badge-warning"
            // onClick={() => onGetItem(row)}
          >
            Reçu
          </span>
        );
      }
    } else {
      if (cell === "rejete") {
        return (
          <span
            // data-toggle="modal"
            // data-target=".facture-valid-modal"
            className="badge badge-danger easypm-badge-danger "
            // onClick={() => onGetItem(row)}
          >
            Rejeté
          </span>
        );
      }
      if (cell === "envoye") {
        return (
          <span
            // data-toggle="modal"
            // data-target=".facture-valid-modal"
            className="badge badge-info easypm-badge-info "
            // onClick={() => onGetItem(row)}
          >
            Envoyé
          </span>
        );
      }
      if (cell === "regle") {
        return (
          <span
            // data-toggle="modal"
            // data-target=".facture-valid-modal"
            className="badge badge-success easypm-badge-success "
            // onClick={() => onGetItem(row)}
          >
            Réglé
          </span>
        );
      }
      if (cell === "reçu") {
        return (
          <span
            // data-toggle="modal"
            // data-target=".facture-valid-modal"
            className="badge badge-warning easypm-badge-warning "
            // onClick={() => onGetItem(row)}
          >
            Reçu
          </span>
        );
      }
    }
  };

  const totalFormatter = (cell, row) => {
    return cell ? formatMoney(cell) : formatMoney(0);
  };

  const refFormatter = (cell, row) => {
    return (
      <span
        className="linkable"
        data-toggle="modal"
        data-target="#facturePdfModal"
        onClick={() => onGetItem(row)}
      >
        {cell}
      </span>
    );
  };

  const onGetItem = (item) => {
    // console.log(item);
    dispatch({ type: "EDIT_FACTURE", payload: item });

    getDataByID(
      "beneficiairebyemploye",
      item?.entreprise?.id,
      auth?.token
    ).then((response) => {
      dispatch({
        type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
        payload: response
          ?.filter((b) => b?.is_active === true)
          .sort((a, b) => b?.id - a?.id),
      });
    });
  };

  const isExpandableRow = (row) => {
    if (row.id) return true;
    else return false;
  };

  const expandComponent = (row) => {
    let total_mont = 0;
    let total_ipm = 0;
    let total_benef = 0;
    if (row?.id && row.prestations?.length) {
      row.prestations.forEach((element) => {
        total_mont += parseInt(element.montant);
        total_ipm += parseInt(element.montant_rembourse);
        total_benef += parseInt(element.montant_retenu);
      });
    }
    return (
      <div class="easypm-collapse-body">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="row">
                    <div className="sub-title sub-title-fact">
                      <strong className="label-title label-title-fact">
                        Etat Récapitulatif des Prestations
                      </strong>
                    </div>

                    <div className="col-md-12">
                      <table className="table table-striped ">
                        <thead className="recap-header">
                          <tr>
                            {/* <th scope="col" className="recap-th">
                              Matricule
                            </th> */}
                            <th scope="col" className="recap-th">
                              Prénom et Nom
                            </th>
                            <th scope="col" className="recap-th">
                              Date
                            </th>
                            <th scope="col" className="recap-th">
                              Total &nbsp;
                            </th>
                            <th scope="col" className="recap-th">
                              Part IPM
                            </th>
                            <th scope="col" className="recap-th">
                              Part Participant
                            </th>
                            {/* <th scope="col" className="recap-th">Observations</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {row?.prestations?.length ? (
                            row?.prestations
                              .sort((a, b) => new Date(a?.id) - new Date(b?.id))
                              .map((prestation) => (
                                <tr key={prestation?.id}>
                                  {/* <td className="recap-th">
                              {prestation?.beneficiaire?.matricule}
                            </td> */}
                                  {/* <th scope="row" className="recap-th">
                                    {prestation?.beneficiaire?.matricule}
                                  </th> */}
                                  <td className="recap-th">
                                    <span className="name">
                                      {" "}
                                      {prestation?.beneficiaire?.prenom +
                                        " " +
                                        prestation?.beneficiaire?.nom}{" "}
                                    </span>{" "}
                                    {/* <br />
                                    <span className="entreprise">
                                      {prestation?.prestataire
                                        ?.raison_sociale ||
                                        prestation?.prestataire?.prenom +
                                          ' ' +
                                          prestation?.prestataire?.nom}
                                    </span> */}
                                    <br />
                                    <span className="entreprise">
                                      {prestation?.familledesoin?.nom}
                                    </span>
                                    <br />
                                    <span className="entreprise">
                                      {prestation?.typedesoin?.nom}
                                    </span>
                                    <br />
                                    <span className="entreprise">
                                      {prestation?.designation}
                                    </span>
                                  </td>
                                  <td className="recap-th">
                                    {moment(prestation?.date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td className="recap-th">
                                    {formatMoney(prestation?.montant)}
                                  </td>
                                  <td className="recap-th">
                                    {formatMoney(prestation?.montant_rembourse)}
                                  </td>
                                  <td className="recap-th">
                                    {formatMoney(prestation?.montant_retenu)}
                                  </td>
                                  {/* <td className="recap-th"></td> */}
                                </tr>
                              ))
                          ) : (
                            <tr
                              style={{
                                textAlign: "center",
                                backgroundColor: "transparent",
                                fontSize: "20px",
                              }}
                            >
                              {/* <td className="col-td">Aucune donnée disponible</td> */}
                              <td className="totalText col-td" colSpan="5">
                                <span>Aucune prestation disponible</span>
                              </td>
                            </tr>
                          )}
                          <tr
                            className="fw-400"
                            style={{
                              color: "black",
                              backgroundColor: "whitesmoke",
                            }}
                          >
                            <td className="totalText col-td" colSpan="2">
                              <strong>Total Général:</strong>
                            </td>
                            <td className="col-td">
                              {formatMoney(parseInt(total_mont))}
                            </td>
                            <td className="col-td">
                              {formatMoney(parseInt(total_ipm))}
                            </td>
                            <td className="col-td">
                              {formatMoney(parseInt(total_benef))}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const filterStatus = {
    reçu: "Reçu",
    regle: "Réglé",
    envoye: "Envoyé",
    rejete: "Rejeté",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        {/* <div className="form-filter m-t-50">
                    <div className="table-filter-row beneficiaire-filter">
                        <div className="filter-label-container">
                            <span className="filter-label"> Filtre </span>
                        </div>
                        <div className="filter-item-container">
                            <input className="form-control filter-input-item" name="fullname" placeholder="Entrer un nom" />
                        </div>
                    </div>
                </div> */}

        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <FacturationSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={
                    auth?.user?.user_type === "entreprise"
                      ? facturebyentreprise?.filter(
                          (f) => f?.prestataire === null
                        )
                      : facturebyipm?.filter((f) => f?.prestataire === null)
                  }
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                  expandableRow={isExpandableRow}
                  expandComponent={expandComponent}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      whiteSpace: "normal",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                    }}
                    dataField="id"
                    filter={{
                      type: "TextFilter",
                      delay: 1000,
                      defaultValue: location?.state?.notification?.data?.id
                        ? String(location?.state?.notification?.data?.id)
                        : "",
                    }}
                    isKey={true}
                    hidden={false}
                    expandable={false}
                    dataAlign="center"
                    dataFormat={refFormatter}
                    width="100"
                  >
                    Référence
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      whiteSpace: "normal",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    // width="150"
                    // dataAlign="center"
                    dataField="entreprise"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Raison sociale
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      whiteSpace: "normal",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="150"
                    dataAlign="center"
                    dataField="total"
                    dataFormat={totalFormatter}
                  >
                    Montant
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      whiteSpace: "normal",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                    }}
                    filter={{
                      type: "DateFilter",
                      defaultValue: { comparator: "=" },
                    }}
                    // width="200"
                    dataAlign="center"
                    dataField="created_at"
                    dataFormat={dateFormatter}
                  >
                    Date
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      whiteSpace: "normal",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      // whiteSpace: 'normal',
                      // overflowWrap: 'break-word',
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="200"
                    dataAlign="center"
                    dataField="periode"
                    dataFormat={periodeFormatter}
                  >
                    Période
                  </TableHeaderColumn>

                  {/* <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                      whiteSpace: 'normal',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                      // whiteSpace: 'normal',
                      // overflowWrap: 'break-word',
                    }}
                    // width="200"
                    dataAlign="center"
                    dataField="date_fin"
                    dataFormat={dateFormatter}
                  >
                    Date de fin
                  </TableHeaderColumn> */}

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      whiteSpace: "normal",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                    }}
                    filter={{
                      type: "SelectFilter",
                      options: filterStatus,
                    }}
                    width="120"
                    dataAlign="center"
                    // dataField="statut"
                    dataField={
                      auth?.user?.user_type !== "entreprise"
                        ? "statut_ipm"
                        : "statut_entreprise"
                    }
                    expandable={false}
                    dataFormat={(cell, row) => etatFormatter(cell, row)}
                  >
                    Statut
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      whiteSpace: "normal",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    expandable={false}
                    width="100"
                    dataAlign="center"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal facture-valid-modal"
            id="ShowModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="AddModalItemLabel"
            aria-hidden="true"
          >
            <FactureValidationModal />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FactureEntreprise;
