import { familleSoin } from "./Endpoints";
import axios from "axios";

export default {
  async getListFamilleSoin(config) {
    const response = await axios.get(
      familleSoin.onGetOrPostFamilleSoin(),
      config
    );
    // console.log(response)
    return response.data;
  },
  async postCreateFamilleSoin(data, config) {
    const response = await axios.post(
      familleSoin.onGetOrPostFamilleSoin(),
      data,
      config
    );
    return response.data;
  },
  async putFamilleSoin(id, data, config) {
    const response = await axios.put(
      familleSoin.onEditFamilleSoin(id),
      data,
      config
    );
    return response.data;
  },
  async deleteFamilleSoin(id, config) {
    const response = await axios.delete(
      familleSoin.onDeleteFamilleSoin(id),
      config
    );
    return response;
  },
};
