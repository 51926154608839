const DetailsPrestataireAdminTableData = [
    {
        id: 1,
        assures: 'KARA LOUIS',
        nom_assures: 'AFAE entreprise',
        patient: 'Louis Kara',
        date: '28/01/2022',
        nature: 'Soins dentaires',
        montant: '17 250,00',
        cout: '25 000',
        fournisseur: 'ABDOU FALL'
    },
    {
        id: 2,
        assures: 'KARA LOUIS',
        nom_assures: 'AFAE entreprise',
        patient: 'Louis Kara',
        date: '28/01/2022',
        nature: 'Soins dentaires',
        montant: '17 250,00',
        cout: '25 000',
        fournisseur: 'ABDOU FALL'
    },
    {
        id: 3,
        assures: 'KARA LOUIS',
        nom_assures: 'AFAE entreprise',
        patient: 'Louis Kara',
        date: '28/01/2022',
        nature: 'Soins dentaires',
        montant: '17 250,00',
        cout: '25 000',
        fournisseur: 'ABDOU FALL'
    },
    {
        id: 4,
        assures: 'KARA LOUIS',
        nom_assures: 'AFAE entreprise',
        patient: 'Louis Kara',
        date: '28/01/2022',
        nature: 'Soins dentaires',
        montant: '17 250,00',
        cout: '25 000',
        fournisseur: 'ABDOU FALL'
    },
];

export default DetailsPrestataireAdminTableData;
