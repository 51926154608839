import React, { useContext, useEffect } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import Soins from "../../../Tables/Soins/Soins";
import AddSoinModal from "../../../Modals/AddSoinModal";
import { DataContext } from "../../../store/GlobalState";
import UseCreateSoinForm from "./UseCreateSoinForm";
import { useHistory, useLocation } from "react-router";
import { getDataByID } from "../../../utils/fetchData";
import ShowModalSoin from "../../../Modals/ShowModalSoin";
import EditModalSoin from "../../../Modals/EditModalSoin";

var GestionSoins = () => {
  // const [userType, setUserType] = React.useState()
  // const [token, setToken] = React.useState();
  const [adherent, setAdherent] = React.useState({});
  const [prestataires, setPrestataires] = React.useState([]);
  const { state, dispatch } = useContext(DataContext);
  const {
    beneficiaires,
    prestation_types,
    entreprises,
    prestationbyassurance,
    beneficiairesByEntreprise,
    prestations,
    auth,
    ipms,
    prestatairebyassurance,
  } = state;

  const {
    register,
    formState,
    soin,
    setSoin,
    setValue,
    getValues,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    reset,
    isFormRequestError,
    clearErrors,
    onSubmit,
    isSuccess,
  } = UseCreateSoinForm();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.adherent) {
      console.log("adherent location");
      setAdherent(location.state.adherent);
      window.$(".add-soin-modal").modal("show");
    }
  }, [location]);
  useEffect(() => {
    // console.log(prestationbyassurance)
    if (!auth.user && !sessionStorage.getItem("authToken")) {
      history.push("/connexion");
    }

    if (auth?.user) {
      const token = sessionStorage.getItem("authToken");
      // setToken(token);
      getDataByID("prestatairebyassurance", auth.user.id, token)
        .then((res) => {
          // console.log("test response=>", res)
          setPrestataires(res.results);
        })
        .catch((err) => console.log(err, "erreur"));
    }
  }, [auth, history, prestationbyassurance, prestations]);

  React.useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (
        auth.user?.user_type === "ipm" ||
        auth?.user?.user_type === "assurance_sante" ||
        auth?.user?.user_type === "admin_ipm"
      ) {
        if (!auth?.user?.admin_type) {
          getDataByID("remboursementbyipm", auth?.user?.id, auth?.token)
            .then((res) => {
              dispatch({
                type: "GET_FICHES_DE_SOIN_FOR_IPM",
                payload: res.sort((a, b) => a.id - b.id).reverse() || [],
              });
            })
            .catch((err) => console.log("erreur=>", err));

          getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        } else {
          getDataByID("remboursementbyipm", auth?.user?.parent, auth?.token)
            .then((res) => {
              dispatch({
                type: "GET_FICHES_DE_SOIN_FOR_IPM",
                payload: res.sort((a, b) => a.id - b.id).reverse() || [],
              });
            })
            .catch((err) => console.log("erreur=>", err));

          getDataByID(
            "notificationbyipm",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    if (isSuccessfullySubmitted) {
      setSoin(soin);
    }

    if (isFormRequestError) {
      setIsSuccessfullySubmitted(false);
      setIsFormRequestError(true);
      setTimeout(() => {
        setIsFormRequestError(false);
      }, 5000);
    }

    if (formState.errors) {
      // console.log("formState=>", formState.errors)
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [
    formState,
    soin,
    setSoin,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
  ]);

  var handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  // useEffect(() => {
  //     setUserType(window.sessionStorage.getItem('userType'))
  // }, [])

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={auth?.user?.user_type} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      Traitement des remboursements
                    </h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target=".add-soin-modal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Demander un remboursement
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <Soins
                    prestations={
                      auth?.user?.user_type !== "entreprise"
                        ? prestationbyassurance
                        : prestations
                    }
                    entreprises={entreprises}
                    assurance_maladie={[auth?.user?.id]}
                    user={auth?.user}
                  />
                </div>

                {/* Modal Add */}
                <div
                  className="modal fade easypm-modal add-soin-modal"
                  id="showModalItem"
                  tabIndex="-1"
                  aria-labelledby="showModalItemLabel"
                  data-backdrop="static"
                  aria-hidden="true"
                >
                  <AddSoinModal
                    reset={reset}
                    assurance_maladie={auth?.user?.id}
                    register={register}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    handleReset={handleReset}
                    beneficiaires={
                      auth?.user?.user_type !== "entreprise"
                        ? beneficiaires
                        : beneficiairesByEntreprise
                    }
                    ipms={ipms}
                    setValue={setValue}
                    getValues={getValues}
                    prestation_types={prestation_types}
                    entreprises={entreprises}
                    prestataires={prestatairebyassurance}
                    isSuccess={isSuccess}
                    adherent={adherent}
                  />
                </div>
                {/* End Modal Add */}

                {/* Modal Show */}
                <div
                  className="modal fade easypm-modal show-soin-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <ShowModalSoin />
                </div>
                {/* end Modal Show */}

                {/* Modal Show */}
                <div
                  className="modal fade easypm-modal edit-soin-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <EditModalSoin
                    beneficiaires={beneficiaires}
                    prestation_types={prestation_types}
                  />
                </div>
                {/* end Modal Show */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionSoins;
