import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation } from "../../../../Http/http/mutation";
import http from "../../../../Http/http/http";
import {
  MyButton,
  MyTextareaInput,
  useFormData,
  useFormValidation,
} from "../../../../utils/utils";
import { toast } from "react-toastify";

const ModifierPrestataireModal = ({ item, onDone, user }) => {
  const mutation = useMutation((data) => http.post("/devis/", data));
  const [data, onChange] = useFormData({ description: "" });
  const [errors, validate] = useFormValidation({}, (Yup) => {
    return {
      description: Yup.string().required().min(10),
    };
  });
  const onDevis = React.useCallback(async () => {
    validate(data)
      .then(async () => {
        try {
          const sendData = {
            etat: "new",
            beneficiaire: user.id,
            description: data.description,
            prestataire: item.id,
          };
          console.log("sendData", sendData);
          await mutation.exec(sendData);
          toast.success("Votre demande à été envoyé");
          window.$("#ModifierPrestataireBeneficiaireModal").modal("hide");
          onDone();
          onChange({
            description: "",
          });
        } catch (ex) {
          toast.error(`Error ${ex.message}`);
        }
      })
      .catch((ex) => null);
  }, [data, user, item]);
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ModifierPrestataireBeneficiaireModal"
          >
            Demander un devis
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => {
              onChange({
                description: "",
              });
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <MyTextareaInput
                    name="description"
                    error={errors.description}
                    label={"Message"}
                    onChange={(v) => onChange("description", v)}
                    placeholder="Message"
                    value={data.description}
                  />
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    onClick={() => {
                      onDone();
                      onChange({
                        description: "",
                      });
                    }}
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  <MyButton
                    loading={mutation.loading}
                    onClick={onDevis}
                    title={"Envoyer"}
                    className="btn  crud-submit-btn-assurance"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifierPrestataireModal;
