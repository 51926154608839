import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import TypePrestation from "../../../Services/TypePrestation";
import { DataContext } from "../../../store/GlobalState";
import { getDataByID } from "../../../utils/fetchData";

function DeletePriseEnCharge() {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, prestation_type } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const handleDelete = async () => {
    setIsLoading(true);
    var prestation_typeRequest = TypePrestation.deleteTypePrestation(
      prestation_type.id,
      requestConfig
    );
    await prestation_typeRequest
      .then((response) => {
        var prestation_typeInfos = response;
        console.log("prestation_type", prestation_typeInfos);

        // dispatch(deleteItem(prestation_types, prestation_type.id, 'ADD_PRESTATION_TYPE'))
        setIsLoading(false);
        if (auth?.user?.user_type === "admin_ipm") {
          getDataByID(
            "typeprestationbyassurance",
            auth?.user?.parent,
            auth.token
          )
            .then((res) => {
              dispatch({
                type: "ADD_PRESTATION_TYPE",
                payload: res.reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          getDataByID("typeprestationbyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "PRISE_EN_CHARGE_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res?.sort((a, b) => b.id - a.id),
                },
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }

        window.$(".delete-prise-en-charge-modal").modal("hide");
        dispatch({ type: "EDIT_PRESTATION_TYPE", payload: {} });
        toast.success("Prise en charge supprimée !");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la suppréssion.");
      });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="DeletingFamilyModal"
          >
            Suppression d'une prise en charge
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              dispatch({ type: "EDIT_PRESTATION_TYPE", payload: {} })
            }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="deleting-family-form-container">
            <div className="alert-delete-message">
              <span>
                Êtes vous sûr de vouloir supprimer cette prise en charge ?
              </span>
            </div>

            <div className="row delete-row">
              <div className="col-md-10 offset-md-2 crud-form-action-btn-container">
                <button
                  onClick={() =>
                    dispatch({ type: "EDIT_PRESTATION_TYPE", payload: {} })
                  }
                  className="btn btn-default cancelleb-btn-assurance delete-actions"
                  data-dismiss="modal"
                >
                  Retour
                </button>
                {!isLoading ? (
                  <button
                    className="btn crud-submit-btn-assurance delete-actions"
                    onClick={handleDelete}
                  >
                    Valider
                  </button>
                ) : (
                  <button className="btn crud-submit-btn-assurance delete-actions">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeletePriseEnCharge;
