import React from 'react'
import { NavLink } from 'react-router-dom'
import EasyPMInMacBook from '../../../../images/landingpage/easypm-in-macbook.svg'
import * as IconlyPack from 'react-iconly';
import ImgIllus from '../../../../images/landingpage/illuipm.png'
import RectangleItem from '../../../../images/landingpage/functionnality-rectangle-item.svg'
import EasyPMAppMobile from '../../../../images/landingpage/app-mobile-easypm.svg'
import ReactPlayer from "react-player";
import ImgAbout from "../../../../images/landingpage/Playassurance.png";
import VideoPm from "../../../../images/landingpage/easypm-assurance.m4v";
import EasyPm from "../../../../images/landingpage/imageVideo.jpeg";
import  './LandingpageAssurance.css'
import HeaderLandingpage from '../HeaderLandingpage/HeaderLandingpage';
import FooterAssurance from '../FooterAssurance/FooterAssurance';
import ContactForm from '../../../LandingPage/ContactForm/ContactForm';
var LandingPageAssurance = () => {
    const [showContactForm, setShowContactForm] = React.useState(false)

    const onShowAndHideContactForm = () => {
        if(showContactForm){
            setShowContactForm(false)
        }else{
            setShowContactForm(true)
        }
    }
    return(
        <div className="landing-page-component">
            <HeaderLandingpage isLandingPage={true} />
            <div className="page-content">
                <section className="page-garde-container-assurance">
                <div className="container container-padding-bottom-assurance">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-8">
                            <h1 className="introduction-assurance">
                            EasyPM-Assurances, l’assurance en ligne d’aujourd’hui
                                <br />
                            </h1>
                            <div className="introduction-slogan-assurance">
                                <p>
                                Améliorez l'expérience utilisateur de vos assurés grâce à une application moderne et intuitive.
                                </p>
                            </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4">
                        <section className="easypm-in-macbook-image-container-assurance">
                            <div className="easypm-in-macbook-image-content-assurance">
                                <img
                                title="EasyPM"
                                src={EasyPMInMacBook}
                                alt="IPM Easy"
                                className="easypm-in-macbook-image-assurance"
                                />
                            </div>
                        </section>
                    </div>
                </div>
          </div>
                </section>
                <section className="why-easypm-section-container-assurance">
                    <div className="container">
                        <div className="why-easypm-section-content-assurance">
                            <h1 className="hp-section-title-assurance"> Pourquoi EasyPM - Assurances ?</h1>
                            <div className="row why-easypm-row-assurance">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 why-easypm-col-item">
                                    <div className="why-easypm-item-icon-container-assurance firsty">
                                        <IconlyPack.Activity set="light" primaryColor="white" className="why-easypm-icon-assurance" />
                                    </div>
                                    <div className="why-easypm-item-textual-container-assurance">
                                        {/* <h2 className="sous-section-title">&nbs;</h2> */}
                                        <div className="why-easypm-item-textual-assurance">
                                            <p>
                                                Avec EasyPM, fini les bons de soins, les livrets de soins et les factures en format papier.
                                                Nous vous proposons de numériser et de dématérialiser l'ensemble de votre parcours et vous donnons
                                                un accès fiable et sécurisé à l'ensemble de ces documents.
                                            </p>
                                            <p>
                                                L'application permet également de bénéficier plus facilement du tiers-payant auprès des prestataires de santé
                                                agréées par votre IPM et/ou membre du réseau EasyPM.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 why-easypm-col-item">
                                    <div className="why-easypm-item-icon-container-assurance secondy">
                                        <IconlyPack.Setting set="light" primaryColor="white" className="why-easypm-icon" />
                                    </div>
                                    <div className="why-easypm-item-textual-container-assurance">
                                        {/* <h2 className="sous-section-title">Dictumst luctus.</h2> */}
                                        <div className="why-easypm-item-textual-assurance">
                                            <p>
                                                L'outil dote les prestataires de moyens d'authentification renforcés permettant d'identifier les bénéficiaires 
                                                ainsi que leurs ayant droits. Le prestataire membre du réseau aura à sa disposition 
                                                l'ensemble des droits ouverts ainsi que les taux de prise en charge applicables à l'IPM d'affiliation.
                                            </p>
                                            <p>
                                                À la délivrance des soins ou des médicaments, le prestataire membre du réseau a la possibilité de 
                                                télétransmettre la fiche de soins complétée électroniquement.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 why-easypm-col-item">
                                    <div className="why-easypm-item-icon-container-assurance thirdy-assurance">
                                        <IconlyPack.Graph set="light" primaryColor="white" className="why-easypm-icon" />
                                    </div>
                                    <div className="why-easypm-item-textual-container-assurance">
                                        {/* <h2 className="sous-section-title">Aliquet eu eget.</h2> */}
                                        <div className="why-easypm-item-textual-assurance">
                                            <p>
                                                Au gestionnaire d'IPM, EasyPM offre le bénéfice d'un outil transparent et sécurisé qui permet de dématérialiser 
                                                et télétransmettre l'ensemble des documents relatifs aux salariés assurés au sein de son institution (bons, livrets, 
                                                factures, fiches de soins).
                                            </p>
                                            <p>
                                                Tous les flux de la relation entre l'IPM, les Employeurs et les prestataires sont numérisés dans un workflow moderne et 
                                                sécurisé, gage de qualité et d'efficience de la relation entres les organisations.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-5 mt-5 player-container-assurance col-md-10 offset-md-1">
                                <ReactPlayer
                                url={VideoPm}
                                width="100%"
                                height="500px"
                                playing
                                playIcon={<img src={ImgAbout} />}
                                light={EasyPm}
                                controls
                                className="easypm-player"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="functionnalities-section-container-assurance">
                    <div className="container">
                        <div className="functionnalities-section-content-assurance">
                            <h1 className="hp-section-title-assurance">Facilitez la transition vers le mobile</h1>
                            <div className="row functionnalities-row-item-assurance firsty">
                                <div className="col-md-6 functionnalities-col-item-assurance side1">
                                    <h2 className="sous-section-title-assurance">L’ASSURANCE SANTÉ EN MIEUX</h2>
                                    <div className="functionnalities-content-assurance">
                                        <p>
                                        Grâce à notre produit innovant et technologique EasyPM - Assurances , modernisez l'usage de l'assurance santé.
                                        </p>
                                        <p>
                                        EasyPM - Assurances transforme les usages en facilitant l'accès et la gestion de toutes les informations relatives au parcours de soins.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 functionnalities-col-item-assurance side2">
                                    <div className="functionnalities-content-image-assurance">
                                        <img src={ImgIllus} alt="Fonctionnalité 1 EasyPM" className="functionnalities-image-item-assurance" />
                                    </div>
                                </div>
                            </div>

                            <div className="row functionnalities-row-item-assurance secondy d-flex align-items-center">
                                <div className="col-md-6 functionnalities-col-item-assurance side2">
                                    <div className="functionnalities-content-image-assurance easypm-app-mobile-image-container-assurance">
                                        <img src={EasyPMAppMobile} alt="Fonctionnalité 1 EasyPM" className="functionnalities-image-item-assurance easypm-app-mobile-image-assurance" />
                                    </div>
                                </div>
                                <div className="col-md-6 functionnalities-col-item-assurance side1">
                                    <h2 className="sous-section-title-assurance">Application Mobile</h2>
                                    <div className="functionnalities-content-assurance">
                                        <p>
                                            L’application mobile assure un accès étendu à votre IPM et une autonomie qui procurent une expérience bénéficiaire unique : <br />
                                            <ul>
                                                <li>suivi des activités de soins</li>
                                                <li>production de fiches électroniques pour le 1/3 payant</li>
                                                <li>demandes de remboursements en self-service </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            
                <section className="contact-section-container-assurance">
                    <div className="contact-light-container-assurance">
                        <div className="row contact-light-row-assurance">
                            <div className="col-md-6 contact-light-col-assurance textual-contact-col-assurance">
                                <h3 className="helpy-title"> Nous sommes là pour vous aider </h3>
                            </div>
                            <div className="col-md-6 contact-light-col-assurance btn-contact-col-assurance">
                                <buttom onClick={() => onShowAndHideContactForm()} className="btn btn-default-assurance contact-light-btn-assurance">Nous contacter</buttom>
                            </div>
                            {showContactForm &&
                                <div className="col-md-6 offset-md-6 contact-form-col">
                                    <ContactForm />
                                </div>
                            }
                        </div>
                    </div>
                </section>

                <FooterAssurance />
            </div>
        </div>
    )
}

export default LandingPageAssurance;