import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
// import IpmAdminTableData from './IpmAdminTableData'
import IpmAdminTableSkeleton from "./IpmAdminTableSkeleton";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../store/GlobalState";
import ShowIpmModal from "../../Modals/ShowIpmModal";
import EditIpmModal from "../../Modals/EditIpmModal";
import DeleteIpmModal from "../../Modals/DeleteIpmModal";
// import { NavLink } from 'react-router-dom'

var IpmAdminTable = () => {
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  // eslint-disable-next-line
  // var [listIpm, setListIpm] = React.useState(IpmAdminTableData)

  const { state, dispatch } = useContext(DataContext);

  const { auth, ipms } = state;

  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      setIsPageLoading(false);
    } else {
      setIsPageLoading(true);
    }
  }, [auth]);
  // console.table(ipms)
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  // const nameFormater = (cell, row) => {
  //     return(
  //         <NavLink to={`/ipm/beneficiaires-entreprise/${row.id}`} className="nameFormatter nameFormation-noLink">
  //             <span className="name"> { cell } </span>
  //         </NavLink>
  //     )
  // }

  const specialiteFormatter = (cell) => {
    if (cell === "ipm" || cell === "assurance_sante") {
      return (
        <span className="badge badge-default easypm-badge-default">IPM</span>
      );
    }
    if (cell === "assureur") {
      return (
        <span className="badge badge-danger assureur-badge"> ASSUREUR </span>
      );
    }
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".show-ipm-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn edit-btn" title="Modification">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".edit-ipm-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn add-btn" title="Envoi de message">
          <IconlyPack.PaperPlus
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-add-icon"
            data-toggle="modal"
            data-target=".send-message-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn" title="Archivage">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            data-toggle="modal"
            data-target=".delete-ipm-modal"
            onClick={() => getItem(row)}
          />
        </button>
      </div>
    );
  };

  // const otherDataFormatter = (cell, row) => {
  //     return(
  //         <NavLink to={`/ipm/profil-beneficiaire/${row.id}`} className="nameFormatter">
  //             <span className="itemy-value"> { cell } </span>
  //         </NavLink>
  //     )
  // }

  const getItem = (item) => {
    console.log("item", item);
    dispatch({
      type: "EDIT_IPM",
      payload: item,
    });
  };

  const optionFilter = {
    ipm: "IPM",
    assureur: "ASSUREUR",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <IpmAdminTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={ipms}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                    }}
                    // width="150"
                    dataField="raison_sociale"
                  >
                    Raison Sociale
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="130"
                    dataField="ninea"
                  >
                    NINEA
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // dataAlign="center"
                    filter={{ type: "SelectFilter", options: optionFilter }}
                    width="160"
                    dataFormat={specialiteFormatter}
                    dataField="specialite"
                  >
                    Spécialité
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                    }}
                    // width="250"
                    dataField="email"
                  >
                    Email
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    // width="250"
                    dataField="adresse"
                  >
                    Adresse
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="150"
                    dataField="telephone"
                  >
                    Téléphone
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="150"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Modal show*/}
      <div
        className="modal fade easypm-modal show-ipm-modal"
        id="AddIPMModalItem2"
        tabIndex="-1"
        aria-labelledby="AddIPMModalItem2Label"
        aria-hidden="true"
        data-backdrop="static"
      >
        <ShowIpmModal />
      </div>

      {/* Modal edit*/}
      <div
        className="modal fade easypm-modal edit-ipm-modal"
        id="AddIPMModalItem2"
        tabIndex="-1"
        aria-labelledby="AddIPMModalItem2Label"
        aria-hidden="true"
        data-backdrop="static"
      >
        <EditIpmModal />
      </div>
      {/* Modal delete*/}
      <div
        className="modal fade easypm-modal delete-ipm-modal"
        id="AddIPMModalItem2"
        tabIndex="-1"
        aria-labelledby="AddIPMModalItem2Label"
        aria-hidden="true"
        data-backdrop="static"
      >
        <DeleteIpmModal />
      </div>
    </div>
  );
};

export default IpmAdminTable;
