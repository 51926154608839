import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AjouterEntrepriseAdmin = (props) => {

 
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AjouterEntrepriseAdmin">
          Ajouter une entreprise
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row crud-row">
                  <>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <label htmlFor="nom">Raison sociale</label>
                        <input
                          type="text"
                          placeholder="Raison sociale"
                          name="nom"
                          className="form-control crud-form-control"
                          id="nom"
                        />
                      </div>
                    </div>
                  </>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="adresse">Ninea</label>
                    <input
                      type="text"
                      placeholder="Ninea"
                      name="adresse"
                      className="form-control crud-form-control"
                      id="adresse"
                    />
                  </div>
                </div>
                 <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="numero_contrat">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="num_contrat"
                      className="form-control crud-form-control"
                      id="numero_contrat"
                    />
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="numero_contrat">Adresse mail*</label>
                    <input
                      type="mail"
                      placeholder="Adresse mail*"
                      name="num_contrat"
                      className="form-control crud-form-control"
                      id="numero_contrat"
                    />
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="numero_contrat">Téléphone</label>
                    <input
                      type="number"
                      placeholder="Téléphone"
                      name="num_contrat"
                      className="form-control crud-form-control"
                      id="numero_contrat"
                    />
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="numero_contrat">Adresse</label>
                    <input
                      type="text"
                      placeholder="Adresse"
                      name="num_contrat"
                      className="form-control crud-form-control"
                      id="numero_contrat"
                    />
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="numero_contrat">Référence</label>
                    <input
                      type="text"
                      placeholder="Référence"
                      name="num_contrat"
                      className="form-control crud-form-control"
                      id="numero_contrat"
                    />
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="website">Site web</label>
                    <input
                      type="text"
                      placeholder="www.exemple.com"
                      name="website"
                      className="form-control crud-form-control"
                      id="website"
                    />
                    <div className="input-infos-container">
                      <IconlyPack.Danger
                        set="light"
                        primaryColor="gray"
                        className="alerty-icon"
                      />
                      &nbsp;
                      <span className="input-infos">
                        Saisie au format www.exemple.com, l'adresse sera
                        autocomplétée avec le protocole http://
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Tiers Payant Intégral</label>
                    <div className="filter-item-container">
                      <select
                        name="specialite"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option>
                          OUI
                        </option>
                        <option>
                          NON
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 crud-col-item">
                    <label for="inlineFormInputGroupUsername">Pourcentage</label>
                    <div class="input-group position-relative">
                        <input type="number" class="form-control crud-form-control" id="inlineFormInputGroupUsername" />
                        <div class="input-group-percent">
                        <span class="">%</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="numero_contrat">Logo *</label>
                    <input
                      type="file"
                      name="num_contrat"
                      className="form-control crud-form-control"
                      id="numero_contrat"
                    />
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn-assurance"
                      data-dismiss="modal"
                    >
                      Retour
                    </button>
                    <button className="btn  crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterEntrepriseAdmin;
