import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import GestionSoinAssureurModal from "../../../Backend/Assurance/GestionSoinsAssureur/GestionSoinAssureur";
import GestionRemboursementBeneficiaireTableSkeleton from "./GestionRemboursementBeneficiaireTableSkeleton";

import GestionFicheDeSoin from "./GestionFicheDeSoinRemboursementBeneficiaire";
import {
  formatMoney,
  getName,
  Status,
  getDate,
  etat_remboursement_obj,
} from "../../../../utils/utils";

var GestionRemboursementBeneficiaireTable = ({ data: soins, loading }) => {
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
    expandBy: "column",
  };

  const RenderView = ({ label, name }) => {
    const labelCmp =
      typeof name === "string" ? (
        <span className="crud-form-control">{name}</span>
      ) : (
        name
      );
    return (
      <div className="col-md-6 crud-col-item">
        <div className="form-group crud-form-group">
          <strong>{label} :</strong>
          {labelCmp}
        </div>
      </div>
    );
  };
  const expandComponent = React.useCallback((item) => {
    const bef = item.prestation?.ayant_droit || item.prestation?.beneficiaire;
    return (
      <div>
        <div className="row crud-row" style={{ paddingBottom: "10px" }}>
          <RenderView label={"Beneficiare"} name={`${getName(bef)}`} />
          <RenderView
            label={"Prestataire"}
            name={`${getName(item?.prestation?.prestataire)}`}
          />
          <RenderView
            label={"Famille de soins"}
            name={`${item?.prestation?.familledesoin?.nom || "-"}`}
          />
          <RenderView
            label={"Type de soins"}
            name={`${item?.prestation?.typedesoin?.nom || "-"}`}
          />
          <RenderView label={"Date"} name={getDate(item.date)} />
          <RenderView
            label={"Montant de la dépense"}
            name={`${formatMoney(item.prestation?.montant)}`}
          />
          {item.prestation.type_prestation && (
            <>
              <RenderView
                label={"Part Participant"}
                name={`${formatMoney(item.prestation?.montant_retenu)}`}
              />
              <RenderView
                label={"Part IPM"}
                name={`${formatMoney(item.prestation?.montant_rembourse)}`}
              />
            </>
          )}
          <RenderView label={"Libellé"} name={`${item?.libelle || "-"}`} />
          {!!item.moyen_paiement && (
            <RenderView
              label={"Payé par"}
              name={`${item?.moyen_paiement || "-"}`}
            />
          )}
          {!!item.motif && (
            <RenderView label={"Motif"} name={`${item?.motif || "-"}`} />
          )}
        </div>
      </div>
    );
  }, []);

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {loading && <GestionRemboursementBeneficiaireTableSkeleton />}
            {!loading && (
              <div className="table-container">
                <BootstrapTable
                  data={soins}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                  striped={true}
                  hover={true}
                  expandableRow={() => true}
                  expandComponent={expandComponent}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="beneficiaire"
                    dataFormat={(_, item) =>
                      getName(
                        item.prestation?.ayant_droit ||
                          item.prestation?.beneficiaire
                      )
                    }
                  >
                    Bénéficiaires
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="type_soin"
                    dataFormat={(_, item) =>
                      getName(item?.prestation?.prestataire)
                    }
                  >
                    Prestataire
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="date"
                    dataFormat={(_, item) => getDate(item.date)}
                  >
                    Date
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="part_assurance"
                    dataFormat={(_, item) =>
                      `${formatMoney(item.prestation?.montant)}`
                    }
                  >
                    Montant demandé
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="etat"
                    filter={{
                      type: "SelectFilter",
                      options: etat_remboursement_obj,
                    }}
                    dataFormat={(cell, item) => (
                      <Status etat={item?.etat} isRem={true} />
                    )}
                  >
                    Statut
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionSoinModal"
            tabIndex="-1"
            aria-labelledby="GestionSoinModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <GestionSoinAssureurModal />
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionRemboursementBeneficiaire"
            tabIndex="-1"
            aria-labelledby="GestionRemboursementBeneficiaireLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <GestionFicheDeSoin />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionRemboursementBeneficiaireTable;
