import React, { Component, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import { getDataByID } from "../../../../utils/fetchData";
import { formatMontant } from "../../../../../utils/utils";
import Skeleton from "react-loading-skeleton";

function DonutGraphCumul({ auth }) {
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const [date, setDate] = useState(new Date());
  const [cumul, setCumul] = useState([0, 0]);
  const [cumulTotal, setCumulTotal] = useState(0);
  const [count, setCount] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [countIpm, setCountIpm] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [countAdh, setCountAdh] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [stats, setStats] = useState([]);
  const [labels, setLabels] = useState([]);
  // const [plugins, setPlugins] = useState([]);
  useEffect(() => {
    // setLabels(dateRange(moment(date).format("YYYY-MM-DD")));
  }, [date]);

  const formatTotal = (w) => {
    console.log("form", w);
    return 1000;
  };

  useEffect(() => {
    if (auth?.user?.id) {
      getDataByID(
        `culmulmontantbybeneficiaire/${auth?.user?.id}`,
        moment(date).format("DD-MM-YYYY"),
        auth?.token
      ).then((stat) => {
        setStats(stat?.data);
        getStat(stat?.data);
        setIsPageLoading(false);
      });
    }
  }, [auth, date]);

  // useEffect(() => {

  // }, [stats]);

  const getStat = (array) => {
    let total_ipm = 0;
    let total_adh = 0;
    let total = 0;
    let cumul = [0, 0];
    if (array?.length) {
      // console.log("cout_total", array);
      array.forEach((element) => {
        total += parseInt(element?.cout_total);
        total_adh += parseInt(element?.part_beneficiaire);
        total_ipm += parseInt(element?.part_ipm);
      });
    }
    cumul[0] = total_ipm;
    cumul[1] = total_adh;
    setCumul(cumul);
    setCumulTotal(total);
  };
  let series = cumul;
  let options = {
    chart: {
      width: 480,
      type: "donut",
    },
    colors: ["#01223A", "#02A69C"],
    title: {
      text: "Cumul sur lannée civile des dépenses par poste",
      align: "left",
      offsetX: 5,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "top",
      offsetY: 0,
      height: 30,
    },
    labels: ["Prise en charge IPMS", "Retenus sur salaires"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "15px",
              offsetY: -10,
            },
            value: {
              show: true,
              formatter: function (value) {
                // console.log("value", w);
                return formatMontant(value);
              },
            },
            total: {
              label: "Total",
              show: true,
              // formatter: formatTotal(),
              formatter: function (w) {
                let total = 0;
                w.globals.seriesTotals.forEach((element) => {
                  total += parseInt(element);
                });
                return formatMontant(total);
                // return w.globals.seriesTotals.reduce((a, b) => {
                //   console.log(w.globals.seriesTotals, b);
                //   let total = parseInt(a) + parseInt(b);
                //   return formatMontant(total);
                // }, 0);
              },
            },
          },
        },
      },
    },
  };

  const handleChangeDate = (date) => {
    setDate(date);
  };

  return (
    <div className="">
      <div className="row mb-2">
        <div className="col-xl-5 col-lg-4 mb-2">
          {/* <h2 className="chart-item-title">Chiffre du jour - <span className="fw-300"> Nombre d'apprenants par jour </span> </h2> */}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6 mb-2 dis-flex">
          <div className="ml-desktop">
            <DatePicker
              dateFormat="MMMM/yyyy"
              showMonthYearPicker={true}
              className="form-select form-select-line-chart ml-md-auto"
              selected={date}
              onChange={(date) => handleChangeDate(date)}
              locale="fr"
            />
          </div>
        </div>
      </div>
      <div class="chart-wrap">
        <div id="chart">
          {isPageLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Skeleton circle={true} height={250} width={250} />
            </div>
          ) : (
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width={380}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DonutGraphCumul;
