import React from "react";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import FeatherIcon from "feather-icons-react";
import BusinessIcon from "@material-ui/icons/Business";
import "./NavbarBeneficiaire.css";
import StyleIcon from "@mui/icons-material/Style";
import PanToolIcon from "@mui/icons-material/PanTool";

var NavbarBeneficiaire = () => {
  const [active, setActive] = React.useState("");

  return (
    <>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/dashboard-beneficiaire"
          activeClassName="actived"
        >
          <IconlyPack.Chart
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Dashboard</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/fiche-soins-beneficiaire"
          activeClassName="actived"
        >
          <i
            className="fa fa-qrcode fa-lg icon-navbar-vertical"
            aria-hidden="true"
            style={{ color: "#ffffff", marginTop: "0.3rem" }}
          ></i>
          <span className="hiddenable-navlink-label">Fiche de soins</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/devis-beneficiaire"
          activeClassName="actived"
        >
          <IconlyPack.Paper
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Devis</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/remboursement-beneficiaire"
          activeClassName="actived"
        >
          <FeatherIcon
            strokeWidth="3"
            className="icon-navbar-vertical"
            icon="credit-card"
            style={{ color: "#ffffff" }}
          />
          <span className="hiddenable-navlink-label">Remboursements</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/livret-beneficiaire"
          activeClassName="actived"
        >
          <StyleIcon
            style={{ color: "white" }}
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Livret</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/gestion-de-rendez-vous-beneficiaire"
          activeClassName="actived"
        >
           <IconlyPack.PaperPlus
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Mes rendez-vous</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/compte-beneficiaire"
          activeClassName="actived"
        >
          <IconlyPack.User
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Mon compte</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/prestataire-agree-beneficiaire"
          activeClassName="actived"
        >
          <IconlyPack.AddUser
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Prestataires agréés</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/base-donnee-beneficiaire"
          activeClassName="actived"
        >
          <FeatherIcon
            strokeWidth="3"
            className="icon-navbar-vertical"
            icon="database"
            style={{ color: "#ffffff" }}
          />
          <span className="hiddenable-navlink-label">Base de données</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/info-utile-beneficiaire"
          activeClassName="actived"
        >
          <IconlyPack.InfoSquare
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Infos utiles</span>
        </NavLink>
      </li>

      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/contacter-beneficiaire"
          activeClassName="actived"
        >
          <IconlyPack.People
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Nous contacter</span>
        </NavLink>
      </li>
    </>
  );
};

export default NavbarBeneficiaire;
