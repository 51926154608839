import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
// import Header from "../../../Navs/Header";
import Header from '../../../../Navs/Header';
import DetailsFacturationAdminTable from "../../../../Tables/Assurance/GestionFacturationAdmin/DetailsFacturationAdminTable";
import NavbarVerticalV2 from "../../../navbarVerticalV2/navbarVerticalV2";


var DetailsFacturationAdmin = () => {


    return (
        <div className="dashboard-item-container">
            <div className="container-fluid body-background-dashboard">
                <Header />
               

                <div className="row">
                    {/* Navbar Desktop  */}
                    <div className="nav-container">
                        <NavbarVerticalV2 />
                    </div>

                    <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 px-md-3 px-0 soinsy">
                        <div className="page-body-container">
                            <div className="page-content-container">
                                <div className="row facturation d-flex align-items-center">
                                    <div className="col-xl-8 col-lg-8 col-md-8 mb-2 dis-flex">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="/facturation-assurance" className="nav-item-breadcrumb">Bénéficiaire</a></li>
                                            <li class="breadcrumb-item"><a href="/facturation-assurance" className="nav-item-breadcrumb">Badou Seck</a></li>
                                            <li class="breadcrumb-item active" className="nav-item-breadcrumb" aria-current="page">Prestations</li>
                                        </ol>
                                        </nav>
                                    </div>
                                    </div>
                                <div className="page-first-row-container">
                                    <div className="page-title-container">
                                        <h1 className="page-title">
                                        VOLKENO SARL
                                        </h1>
                                    </div>
                                </div>
                                <div className="parent-table-container">
                                    <DetailsFacturationAdminTable />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsFacturationAdmin;
