import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import Header from "../../../Navs/Header";
import GestionSoinsBeneficiaireTable from "../../../Tables/Beneficiaire/GestionSoinsBeneficiaire/GestionSoinsBeneficiaireTable";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import GenererFicheSoinModal from "./GenererFicheSoinBeneficiaire";
import { useQuery } from "../../../../Http/http/query";
import { useCurrentUser } from "../../../../utils/utils";
import _ from "lodash";
var FicheDeSoinsBeneficiaire = () => {
  const user = useCurrentUser();
  const query = useQuery(null);
  const [data, setData] = React.useState([]);
  const queryPrestataires = useQuery(null);
  const queryAyantdDroit = useQuery(null);
  const [item, setItem] = React.useState(null);
  const reload = React.useCallback(() => {
    query.getData(`/fichedesoinbybeneficiaire/${user?.id}/`);
  }, [user, query]);
  React.useEffect(() => {
    if (user?.id) {
      reload();
      queryPrestataires.getData(
        `/prestatairebyassurance/${user?.assurance?.id}/`
      );
      queryAyantdDroit.getData(`/ayantdroitbybeneficiaire/${user?.id}/`);
    }
  }, [user?.id]);
  React.useEffect(() => {
    setData(_.orderBy(query.data, ["date"], ["desc"]));
  }, [query.data]);
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Activités de soins</h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target="#DemandeFicheSoinsModal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Générer une fiche de soins
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <GestionSoinsBeneficiaireTable
                    onEdit={setItem}
                    onDeleted={() => {
                      reload();
                    }}
                    loading={query.loading && query.data.length === 0}
                    data={data}
                  />
                </div>
                <div
                  className="modal fade easypm-modal add-soin-modal"
                  id="DemandeFicheSoinsModal"
                  tabIndex="-1"
                  aria-labelledby="DemandeFicheSoinsModalLabel"
                  data-backdrop="static"
                  aria-hidden="true"
                >
                  <GenererFicheSoinModal
                    item={item}
                    onAdd={(flag) => {
                      setItem(null);
                      if (flag !== false) {
                        reload();
                      }
                    }}
                    prestataires={queryPrestataires.data}
                    user={user || {}}
                    ayant_droits={queryAyantdDroit.data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FicheDeSoinsBeneficiaire;
