import React, { useEffect, useContext, useState } from "react";
import { useQuery } from "../../../../Http/http/query";
import { useCurrentUser } from "../../../../utils/utils";
import Header from "../../../Navs/Header";
import GestionReservationTable from "../../../Tables/GestionReservationTable/GestionReservationTable";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./GestionReservation.css";
import { useRdvPrestataire } from "../../../../Components/Backend/navbarContent/PrestataireNavBarContent";
var GestionReservation = () => {
  const [data, loading, reload] = useRdvPrestataire();

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={`prestataire`} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Liste des réservations</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <GestionReservationTable
                    data={data}
                    getData={reload}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionReservation;
