import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import entreprise from "../../../Services/Entreprise";
import assuranceMaladie from "../../../Services/AssuranceMaladie";
import { DataContext } from "../../../store/GlobalState";
import { toast } from "react-toastify";

var UseUpdateProfileInformationForm = () => {
  const userType = window.sessionStorage.getItem("userType");
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email("L'adresse email n'est pas valide"),
        raison_sociale: yup.string(),
        //prenom: yup.string(),
        //   employeur: yup.number().transform(value => (isNaN(value) ? undefined : value)),
        //   // password: yup.string().required('Le mot de passe est requis'),
        telephone: yup.string(),
        adresse: yup.string(),
        ninea: yup.string(),
        website: yup.string().url("Cette url n'est pas valide."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitEditForm = async (formValues, e) => {
    if (e !== "valid") {
      setIsSuccessfullySubmitted(true);
    }

    if (!formValues?.email) {
      formValues.email = auth?.user.email;
    }

    if (!formValues?.raison_sociale) {
      formValues.raison_sociale = auth?.user.raison_sociale;
    }

    if (!formValues?.ninea) {
      formValues.ninea = auth?.user.ninea;
    }

    if (!formValues?.telephone) {
      formValues.telephone = auth?.user.telephone;
    }

    if (!formValues?.adresse) {
      formValues.adresse = auth?.user.adresse;
    }

    var userRequest = {};
    if (userType === "ipm" || userType === "assurance_sante") {
      userRequest = assuranceMaladie.editAssuranceMaladie(
        auth?.user?.id,
        formValues,
        requestConfig
      );
    }

    if (userType === "admin_ipm" || auth?.user?.admin_type) {
      userRequest = assuranceMaladie.editAdminAssuranceMaladie(
        auth?.user?.id,
        formValues,
        requestConfig
      );
    }

    if (userType === "entreprise" && !auth?.user?.admin_type) {
      userRequest = entreprise.editEntreprise(
        auth?.user?.id,
        formValues,
        requestConfig
      );
    }
    // console.log("data", formValues, e);
    await userRequest
      .then((response) => {
        var profileInfos = response;
        console.log(profileInfos);

        dispatch({
          type: "AUTH",
          payload: { token: auth.token, user: profileInfos },
        });
        if (e !== "valid") {
          toast.success("Vos informations ont été modifiées avec succès.");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsFormRequestError(true);
        if (e !== "valid") {
          if (error?.response?.status === 500){
          toast.error("Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard.")
        } else if (error?.response?.status === 400){
          toast.error("Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur.")
        }
        }
      });
  };

  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitEditForm),
    setValue,
    getValues,
    reset,
  };
};

export default UseUpdateProfileInformationForm;
