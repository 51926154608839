import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";

import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
import Swal from "sweetalert2";
import DefaultAvatar from "../../../../images/others/default-profile-avatar.png";
import "./MonCompte.css";
import {
  getName,
  MyButton,
  MyDatePicker,
  MyInput,
  MyPhoneInput,
  Select,
  useCurrentUser,
  useFormData,
  useFormValidation,
} from "../../../../utils/utils";
import { read_user, update_user } from "../../../../Http/http/api";
import { toast } from "react-toastify";
import { getAvatarOrDefault } from "../LivretBeneficiaire/AyantDroitBeneficiaireTable.js/AyantDroitBeneficiaireTable";
import moment from "moment";
import { DataContext } from "../../../store/GlobalState";
const config = [
  {
    name: "email",
    label: "Email",
    type: "email",
    keyboardType: "email-address",
    autoCapitalize: "none",
  },
  {
    name: "prenom",
    label: "Prénom",
  },
  {
    name: "nom",
    label: "Nom",
  },
  {
    name: "avatar",
    label: "Avatar",
    type: "file",
  },
  {
    name: "sexe",
    label: "Genre",
    type: "picker",
    items: [
      { value: "homme", label: "Homme" },
      { value: "femme", label: "Femme" },
    ],
  },
  {
    name: "date_de_naissance",
    label: "Date de naissance",
    type: "date",
    format: "DD/MM/YYYY",
  },
  {
    name: "situation_matrimoniale",
    label: "Situation matrimoniale",
    type: "picker",
    items: [
      { value: "célibataire", label: "Célibataire" },
      { value: "marié(e)", label: "Marié(e)" },
      { value: "veuf(ve)", label: "Veuf(ve)" },
    ],
  },
  {
    name: "groupe_sanguin",
    label: "Groupe sanguin",
    type: "picker",
    items: [
      { value: "O +", label: "O +" },
      { value: "A +", label: "A +" },
      { value: "B +", label: "B +" },
      { value: "O -", label: "O -" },
      { value: "A -", label: "A -" },
      { value: "AB +", label: "AB +" },
      { value: "B -", label: "B -" },
      { value: "AB -", label: "AB -" },
    ],
  },

  {
    name: "nationnalite",
    label: "Nationnalité",
  },
  {
    name: "telephone",
    label: "Téléphone",
    type: "phone",
  },
  {
    name: "adresse",
    label: "Adresse",
  },
];
const keys = config.map((c) => c.name);
var MonCompte = () => {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  let { user, token } = auth;
  if (!user) {
    user = {};
  }
  const obj = {};
  for (let k of keys) {
    obj[k] = "";
  }
  const [data, onChange] = useFormData(obj);
  React.useEffect(() => {
    if (user?.id) {
      onChange({
        email: user.email,
        prenom: user.prenom,
        nom: user.nom,
        sexe: user.sexe,
        date_de_naissance: moment(user.date_de_naissance).toDate(),
        situation_matrimoniale: user.situation_matrimoniale,
        groupe_sanguin: user.groupe_sanguin,
        nationnalite: user.nationnalite,
        telephone: user.telephone,
        adresse: user.adresse,
      });
    }
  }, [user]);
  const [loading, setLoading] = React.useState(false);
  const [errors, validate] = useFormValidation({}, (Yup) => ({
    email: Yup.string().email().required().label("Email"),
    nom: Yup.string().required().label("Nom"),
    prenom: Yup.string().required().label("Prénom"),
    telephone: Yup.string()
      .required()
      .test("Veuillez vérifier le format du numéro de téléphone", (v) => {
        return /^((\+)221|0)[1-9](\d{2}){4}$/.test(v);
      }),
  }));

  const onSubmit = React.useCallback(async () => {
    if (data.telephone !== user?.telephone) {
      if (!data.telephone?.includes("+")) {
        data.telephone = "+" + data.telephone.split(" ").join("");
      }
    }
    validate(data)
      .then(async () => {
        setLoading(true);
        const { date_de_naissance, avatar, ...sendData } = data;
        if (date_de_naissance && date_de_naissance !== user.date_de_naissance) {
          sendData.date_de_naissance =
            moment(date_de_naissance).format("YYYY-MM-DD");
        }
        if (avatar) {
          sendData.avatar = avatar;
        }
        try {
          console.log(sendData);
          await update_user(user.id, sendData);
          const newUser = await read_user(user.id);
          dispatch({
            type: "AUTH",
            payload: {
              token: token,
              user: newUser,
            },
          });
          toast.success(
            "La modifications de vos données est en cours de Validation. \n Vous serez notifié lorsque la Validation sera un succes. \n Une fois notifié les données se Mettront Automatiquement à jour"
          );
        } catch (err) {
          toast.error(`Error: ${err?.message}`);
          console.log("error", JSON.stringify(err.response));
        }
        setLoading(false);
      })
      .catch((ex) => {});
  }, [data, user, token]);

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Mon compte</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="px-5">
                    <form>
                      <div className="d-flex align-items-center flex-column py-3">
                        <div className="py-3">
                          <img
                            src={getAvatarOrDefault(user?.avatar)}
                            alt="Image profil utilisateur"
                            className="img-profil-app"
                          />
                        </div>
                        <div>
                          <h2 className="nom-profil-user pb-3">
                            {getName(user || {})}
                          </h2>
                          <p className="text-profil-user">Bénéficiaire</p>
                        </div>
                      </div>
                      <div className="py-3">
                        <p className="label-profil-user">
                          Informations personnelles
                        </p>
                      </div>
                      <div className="row d-flex align-items-center">
                        {config.map(
                          ({ type, name, items, label, ...rest }, i) => {
                            if (type === "picker") {
                              return (
                                <div className="col-md-6" key={i}>
                                  <Select
                                    name={name}
                                    error={errors[name]}
                                    value={data[name]}
                                    onChange={(value) => {
                                      onChange(name, value);
                                    }}
                                    label={label}
                                    placeholder={label}
                                    items={items}
                                    {...rest}
                                  />
                                </div>
                              );
                            }
                            if (type === "date") {
                              return (
                                <div className="col-md-6" key={i}>
                                  <MyDatePicker
                                    name={name}
                                    error={errors[name]}
                                    value={data[name]}
                                    onChange={(v) => onChange(name, v)}
                                    label={label}
                                    placeholder={label}
                                    maxDate={new Date()}
                                    {...rest}
                                  />
                                </div>
                              );
                            }
                            if (type === "phone") {
                              return (
                                <div className="col-md-6" key={i}>
                                  <MyPhoneInput
                                    name={name}
                                    error={errors[name]}
                                    value={data[name]}
                                    onChange={(v) => onChange(name, v)}
                                    label={label}
                                    placeholder={label}
                                  />
                                </div>
                              );
                            }
                            return (
                              <div className="col-md-6" key={i}>
                                <MyInput
                                  error={errors[name]}
                                  {...(type !== "file"
                                    ? { value: data[name] }
                                    : {})}
                                  onChange={(v) => onChange(name, v)}
                                  label={label}
                                  placeholder={label}
                                  name={name}
                                  {...rest}
                                  type={type || "text"}
                                />
                              </div>
                            );
                          }
                        )}
                        <div className="col-md-6">
                          <MyButton
                            title="Enregistrer"
                            loading={loading}
                            onClick={onSubmit}
                            className="btn  crud-submit-btn-assurance"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonCompte;
