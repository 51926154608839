import React from "react";
import * as IconlyPack from "react-iconly";
import PpAvatar from "../../../../images/others/pp.png";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { ApiUrl } from "../../../../Http/http/http";
import { getName } from "../../../../utils/utils";
export function appendZero(n) {
  if (n < 10) return `0${n}`;
  return n;
}
var CardItemRdv = ({ item }) => {
  return (
    <div>
      <div className="card-item-rdv">
        <div className="bloc-entete-card-item-rdv-prestataire">
          <div className="">
            <IconlyPack.Calendar set="bold" primaryColor="#fff" />
            <span className="text-card-item-rdv pl-2">
              {moment(item.date).format("dddd DD MMMM")}
            </span>
          </div>
          <div>
            <IconlyPack.TimeCircle set="bold" primaryColor="#fff" />
            <span className="text-card-item-rdv pl-2">
              {appendZero(item.heure)}:00
            </span>
          </div>
        </div>
        <div className="p-3">
          <div className="d-flex align-items-center py-3">
            <div>
              <img
                src={ApiUrl + item.beneficiaire?.avatar}
                alt="Image"
                className="image-pp-card-item-rdv"
              />
            </div>
            <div className="pl-3">
              <span className="titre-auteur-rdv no-link">
                {getName(item.beneficiaire)}
              </span>
            </div>
          </div>
          <div>
            <span className="text-localisation-rdv pl-2">Motif</span>
            <p className="pl-2 text-motif-consultation-prestataire">
              {item.motif}
            </p>
          </div>
          <div className="mt-2">
            <span className="text-localisation-rdv pl-2">Localisation</span>
            <p className="pl-2 text-motif-consultation-prestataire">
              {item.adresse}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardItemRdv;
