import React, { useEffect, useState, useRef } from "react";
import FeatherIcon from "feather-icons-react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { getData, getDataByID, postData } from "../utils/fetchData";
import { DataContext } from "../store/GlobalState";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import swal from "sweetalert2";

registerLocale("fr", fr);

const schema = yup.object().shape({
  fields: yup.array().of(
    yup.object().shape({
      date: yup
        .string()
        .required("La date est requise")
        .default(moment(new Date()).format("YYYY-MM-DD")),
      familledesoin: yup.string().required("La famille de soins est requise"),
      designation: yup.string().required("La designation est requise"),
      quantite: yup.string().required("La quantité est requise"),
      fichedesoin: yup.string(),
      total: yup.string(),
      montant: yup.string().required("Le Montant est requis"),
      typedesoin: yup.string().required("Le type de soins est requis"),
    })
  ),
});

const AlimentSoinModal = (props) => {
  const { state, dispatch } = React.useContext(DataContext);
  const { auth, list_famille_soins, prestation_types, ficheDeSoin } = state;
  const ref = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [types, setTypes] = React.useState([]);
  const [clesFamily, setClesFamily] = React.useState([
    { value: "", label: "" },
  ]);
  const [clesType, setClesType] = React.useState([{ value: "", label: "" }]);
  const [exclusions, setExclusions] = React.useState([]);
  const [selectIndex, setSelectIndex] = React.useState(0);
  var [startDate, setStartDate] = React.useState(new Date());
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const [prestation_type, setPrestation_type] = React.useState("");
  let token = window.sessionStorage.getItem("authToken");
  const [indexes, setIndexes] = React.useState([0]);
  const [indexesDefault, setIndexesDefault] = React.useState([]);
  const [typeDefault, setTypeDefault] = React.useState([]);
  const [typeNumber, setTypeNumber] = React.useState([]);
  const [counter, setCounter] = React.useState(1);
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
    reset,
    clearErrors,
    getValues,
  } = useForm({
    validationSchema: schema,
  });

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        // console.log("error", errors);
        clearErrors();
      }, 7000);
    }
  }, [errors, indexes]);

  React.useEffect(() => {
    if (auth?.token) {
      getData("typedesoin/", auth.token).then((response) => {
        setTypes(response.results.sort((a, b) => b?.id - a?.id));
      });
    }

    if (list_famille_soins?.length) {
      setClesFamily(
        list_famille_soins.map((b) => {
          return {
            label: b.nom,
            value: b.id,
          };
        })
      );
    }

    if (ficheDeSoin?.id) {
      getDataByID(
        "exclusionbyipm",
        ficheDeSoin?.beneficiaire?.assurance?.id,
        auth?.token
      ).then((res) => {
        // console.log("exclu", res);
        dispatch({
          type: "LIST_EXCLUSION",
          payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
        });
      });

      getDataByID(
        "typeprestationbyassurance",
        ficheDeSoin?.beneficiaire?.assurance?.id,
        auth.token
      )
        .then((res) => {
          // console.log("res type", res);
          dispatch({
            type: "ADD_PRESTATION_TYPE",
            payload: res?.sort((a, b) => b.id - a.id),
          });
        })
        .catch((e) => {
          // console.log("e", e);
        });
    }
  }, [auth, list_famille_soins, ficheDeSoin]);

  useEffect(() => {
    // datePickerId.max = new Date().toISOString().split("T")[0];

    const fieldName = `fields[${indexes[indexes.length - 1]}]`;
    register(`${fieldName}.date`);
    setValue(`${fieldName}.date`, moment(startDate).format("YYYY-MM-DD"));
    register(`${fieldName}.familledesoin`, {
      required: "La famille de soins de requise.",
    });
    register(`${fieldName}.typedesoin`, {
      required: "Le type de soins de requis.",
    });
    // console.log("indexes", indexes[indexes.length - 1]);
  }, [indexes]);

  useEffect(() => {
    if (searchTerm && exclusions?.length) {
      const results = exclusions.filter((exclu) => {
        //console.log('search term', searchTerm)
        if (exclu.designation) {
          return exclu.designation
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        }
      });
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, exclusions]);

  const handleChange = (e, index) => {
    setSelectIndex(index);
    setSearchTerm(e.target.value);
    const fieldName = `fields[${index}]`;
    setValue(`${fieldName}.designation`, e.target.value);
    setValue(`${fieldName}.fichedesoin`, ficheDeSoin?.id);
  };

  const handleClick = async (e, item, index) => {
    e.preventDefault();
    // console.log("item", item, index);
    const fieldName = `fields[${index}]`;

    await setSearchTerm("");
    await setSearchResults([]);
    swal
      .fire({
        title:
          "Ce médicament n'est pas pris en charge par votre IPM, Voulez-vous le prendre quand même ?",
        customClass: {
          confirmButton: "btn-success",
          cancelButton: "btn-danger",
        },
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        // showLoaderOnConfirm: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setValue(`${fieldName}.designation`, item.designation);
        } else {
          setValue(`${fieldName}.designation`, "");
        }
      });
  };

  const onSelectType = (e, index) => {
    indexesDefault[index] = e;
    console.log(e.value, indexesDefault);

    const fieldName = `fields[${index}]`;
    // setValType(e);
    register(`${fieldName}.typedesoin`);
    setValue(`${fieldName}.typedesoin`, e.value);
    setValue(`${fieldName}.fichedesoin`, ficheDeSoin?.id);
    setIndexesDefault(indexesDefault);
    getDataByID("exclusionbytypedesoin", e.value, auth?.token)
      .then((res) => {
        // console.log("res", res);
        setExclusions(res?.results);
      })
      .catch((error) => console.log("error", error?.response));
    let types = prestation_types?.filter(
      (t) => t?.typedesoin?.id === parseInt(e.value)
    );

    if (types?.length) {
      if (types?.length > 1) {
        // setPrestation_type(types);
        register(`${fieldName}.type_prestation`, {
          required: "le type de prestation est requis.",
        });
        typeNumber[index] = {
          number: types?.length,
          values: types.map((p) => {
            return {
              label:
                p?.libelle + " " + "(" + p?.taux_prise_en_charge + "%" + ")",
              value: p?.id,
            };
          }),
        };
        setPrestation_type(
          types.map((p) => {
            return {
              label:
                p?.libelle + " " + "(" + p?.taux_prise_en_charge + "%" + ")",
              value: p?.id,
            };
          })
        );
        
      } else {
        typeNumber[index] = {
          number: types?.length,
        };
        console.log("resss", getValues(`${fieldName}.type_prestation`));
        register(`${fieldName}.type_prestation`, {
          required: {
            value: false,
          },
        });
        setValue(`${fieldName}.type_prestation`, types[0]?.id);
      }
    } else {
      unregister(`fields[${index}].type_prestation`);
       console.log("sss", getValues(`${fieldName}.type_prestation`));
      typeNumber[index] = {
        number: 0,
      };
      setPrestation_type([]);
      
    }
  
    setTypeNumber(typeNumber);
  };

  const onSelectTypeP = (e, index) => {
    typeDefault[index] = e;
    let params = e.value;
    const fieldName = `fields[${index}]`;
    setTypeDefault(typeDefault);
    register(`${fieldName}.type_prestation`);
    setValue(`${fieldName}.type_prestation`, params);
    // getValues(`${fieldName}.type_prestation`);
    // console.log("e", params);
  };

  const onSelectFamily = (e, index) => {
    // setIndexesDefault([]);
    // setTypeDefault([]);
    // console.log(parseInt(e.target.value));
    const fieldName = `fields[${index}]`;
    // setValFamily(e);
    if (
      types?.length &&
      types.filter((t) => t?.familledesoin?.id === parseInt(e.target.value))
        ?.length
    ) {
      setClesType(
        types
          .filter((t) => t?.familledesoin?.id === parseInt(e.target.value))
          .map((b) => {
            return {
              label: b.nom,
              value: b.id,
            };
          })
      );
    }
    register(`${fieldName}.familledesoin`);
    setValue(`${fieldName}.familledesoin`, parseInt(e.target.value));
    setValue(`${fieldName}.fichedesoin`, ficheDeSoin?.id);
    setValue(`${fieldName}.typedesoin`, "");
  };

  const onSubmit = (data, e) => {
    setIsSuccessfullySubmitted(true);
    // data.ficheDeSoin
    // console.log(data);
    // reset();
    // ref.current.select.props.value = {}
    let resp = null;
    let errCount = 0;
    data.fields.forEach((element) => {
      postData("relevesoin/", element, token)
        .then((res) => {
          setIsFormRequestError(false);
          resp = res;
          if (
            res?.message &&
            res?.message === "L'ipm ne prend pas en compte cette designation"
          ) {
            errCount += 1;
          }
        })
        .catch((error) => {
          console.log(error);
          setIsFormRequestError(true);
        });
    });

    setTimeout(async () => {
      if (!isFormRequestError) {
        getDataByID(
          "fichedesoinvalidatebyprestataire",
          auth?.user?.id,
          auth?.token
        ).then((res) => {
          dispatch({
            type: "LISTE_FICHE_VALID_BY_PRESTATAIRE",
            payload: res.sort((a, b) => b?.id - a?.id),
          });
        });

        if (errCount > 0 && errCount <= data.fields?.length) {
          if (errCount < 1) {
            toast.warning(
              `${errCount} de vos prestations sont soumises à votre ipm pour validation`
            );
          } else {
            toast.warning(
              `${errCount} de vos prestations est soumise à votre ipm pour validation`
            );
          }
        } else {
          if (resp?.message) {
            // console.log("ee", resp, errCount);
            toast.warning(resp?.message);
          } else {
            toast.success("Fiche de soins alimentée avec succès.");
          }
        }

        setIsSuccessfullySubmitted(false);

        dispatch({ type: "EDIT_FICHE_DE_SOIN_BY_IPM", payload: {} });

        window.$(".alim-soin-modal").modal("hide");
        setIndexesDefault("");
        setTypeDefault("");
        setIndexes([0]);
        setCounter(1);
        e.target.reset();
        reset();
        handleReset();
        setIndexesDefault([]);
        setTypeDefault([]);
      } else {
        setIsSuccessfullySubmitted(false);
        toast.error(
          "Oupss! Une erreur est survenue lors de l'alimentation de la fiche de soin."
        );
      }
    }, "3000");
  };

  const addFriend = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
    document
      .getElementById("datePickerId")
      .setAttribute("max", new Date().toISOString().split("T")[0]);
  };

  const removeFriend = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
    unregister(`fields[${index}].designation`);
    unregister(`fields[${index}].montant`);
    unregister(`fields[${index}].date`);
    unregister(`fields[${index}].quantite`);
    unregister(`fields[${index}].fichedesoin`);
    unregister(`fields[${index}].total`);
    unregister(`fields[${index}].typedesoin`);
    unregister(`fields[${index}].familledesoin`);
    unregister(`fields[${index}].type_prestation`);
  };

  const handleReset = async () => {
    setStartDate(new Date());
    reset();
    setPrestation_type([]);
    setTypeDefault([]);
    setIndexesDefault([]);
    setIndexes([0]);
    setCounter(1);
    setClesType([]);
    dispatch({ type: "EDIT_FICHE_DE_SOIN_BY_IPM", payload: {} });
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Alimenter la fiche de soins de{" "}
            <b>
              {ficheDeSoin?.id
                ? ficheDeSoin?.ayant_droit?.id
                  ? ficheDeSoin?.ayant_droit?.prenom +
                    " " +
                    ficheDeSoin?.ayant_droit?.nom
                  : ficheDeSoin?.beneficiaire?.prenom +
                    " " +
                    ficheDeSoin?.beneficiaire?.nom
                : null}
            </b>
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => handleReset()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="crud-row">
                {indexes?.map((index) => {
                  const fieldName = `fields[${index}]`;
                  return (
                    <fieldset className="row" key={index}>
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group crud-form-group required">
                          <label htmlFor="cle">Famille de soins</label>
                          <div className="filter-item-container">
                            {/* <Select
                              placeholder="Selectionner la famille de soins"
                              // name="familledesoin"
                              name={`${fieldName}.familledesoin`}
                              // value={`${fieldName}.familledesoin` || ""}
                              onChange={(e) => onSelectFamily(e, index)}
                              options={clesFamily}
                            /> */}
                            <select
                              // {...register(`${fieldName}.familledesoin`, {
                              //   required: "La famille de soins de requise."
                              // })}
                              onChange={(e) => onSelectFamily(e, index)}
                              name={`${fieldName}.familledesoin`}
                              className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                              aria-label="Default select example"
                            >
                              <option hidden value="">
                                Famille de soins
                              </option>
                              {clesFamily.map((family) => (
                                <option key={family.value} value={family.value}>
                                  {family.label}
                                </option>
                              ))}
                            </select>
                            {errors &&
                              errors?.fields !== undefined &&
                              (errors.fields[index]?.familledesoin ? (
                                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                  {errors.fields[index]?.familledesoin?.message}
                                </div>
                              ) : null)}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 crud-col-item">
                        <div className="form-group crud-form-group required">
                          <label htmlFor={index}>Type de soins</label>
                          <div className="filter-item-container">
                            <Select
                              ref={ref}
                              placeholder="Selectionner le type de soins"
                              // name="typedesoin"
                              name={`${fieldName}.typedesoin`}
                              value={indexesDefault[index] || ""}
                              onChange={(e) => onSelectType(e, index)}
                              options={clesType}
                            />
                            {/* <select
                              id={index}
                              onChange={(e) => onSelectType(e, index)}
                              name={`${fieldName}.typedesoin`}
                              className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                              aria-label="Default select example"
                            >
                              <option hidden value="">
                                Type de soins
                              </option>
                              {clesType.map((type) => (
                                <option key={type.value} value={type.value}>
                                  {type.label}
                                </option>
                              ))}
                            </select> */}

                            {errors &&
                              errors?.fields !== undefined &&
                              (errors.fields[index]?.typedesoin ? (
                                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                  {errors.fields[index]?.typedesoin?.message}
                                </div>
                              ) : null)}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group required crud-form-group">
                          <label htmlFor="designation">Désignation</label>
                          <input
                            {...register(`${fieldName}.designation`, {
                              required: "La designation est requise.",
                            })}
                            onChange={(e) => handleChange(e, index)}
                            type="text"
                            placeholder="Acte ou médicament"
                            name={`${fieldName}.designation`}
                            className="form-control crud-form-control"
                            id="designation"
                          />
                          {searchResults?.length && selectIndex === index ? (
                            <ul className="search-result-container" key={index}>
                              {searchResults.map((item) => (
                                <li
                                  className="search-result-item"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => handleClick(e, item, index)}
                                  key={item?.id}
                                >
                                  {item?.designation}
                                </li>
                              ))}
                            </ul>
                          ) : null}
                          {errors &&
                            errors?.fields !== undefined &&
                            (errors.fields[index]?.designation ? (
                              <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                {errors.fields[index]?.designation?.message}
                              </div>
                            ) : null)}
                        </div>
                      </div>
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group required crud-form-group">
                          <label htmlFor="montant">Prix Unitaire</label>
                          <input
                            {...register(`${fieldName}.montant`, {
                              required: "Le montant est requis.",
                            })}
                            min={1}
                            onChange={(e) =>
                              setValue(`${fieldName}.montant`, e.target.value)
                            }
                            type="number"
                            placeholder="Prix Unitaire"
                            name={`${fieldName}.montant`}
                            className="form-control crud-form-control"
                            id="montant"
                          />
                          {errors &&
                            errors?.fields !== undefined &&
                            (errors.fields[index]?.montant ? (
                              <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                {errors.fields[index]?.montant?.message}
                              </div>
                            ) : null)}
                        </div>
                      </div>
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group required crud-form-group">
                          <label htmlFor="quantite">Quantite</label>
                          <input
                            {...register(`${fieldName}.quantite`, {
                              required: "La quantité est requise.",
                            })}
                            min={1}
                            onChange={(e) =>
                              setValue(`${fieldName}.quantite`, e.target.value)
                            }
                            type="number"
                            placeholder="Quantite"
                            name={`${fieldName}.quantite`}
                            className="form-control crud-form-control"
                            id="quantite"
                          />
                          {errors &&
                            errors?.fields !== undefined &&
                            (errors.fields[index]?.quantite ? (
                              <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                {errors.fields[index]?.quantite?.message}
                              </div>
                            ) : null)}
                        </div>
                      </div>
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group crud-form-group required">
                          <label htmlFor="datePickerId">Date</label>
                          <input
                            type="date"
                            {...register(`${fieldName}.date`, {
                              required: "La date est requise.",
                            })}
                            defaultValue={startDate}
                            onChange={(e) => {
                              setValue(`${fieldName}.date`, e.target.value);
                            }}
                            max={new Date().toISOString().split("T")[0]}
                            // disabled
                            className="form-control crud-form-control"
                            placeholder="Sélectionner une date"
                            name={`${fieldName}.date`}
                            id="datePickerId"
                          />
                          {/* <DatePicker
                            className="form-control crud-form-control"
                            selected={startDate}
                            placeholderText="Sélectionner une date"
                            dateFormat="dd/MM/yyyy"
                            disabled
                            popperPlacement="top"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            name={`${fieldName}.date`}
                            locale="fr"
                            value={startDate}
                            onChange={(date) => handleDate(date)}
                          /> */}
                          {errors &&
                            errors?.fields !== undefined &&
                            (errors.fields[index]?.date ? (
                              <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                {errors.fields[index]?.date?.message}
                              </div>
                            ) : null)}
                        </div>

                        {ficheDeSoin?.id && (
                          <input
                            type="hidden"
                            {...register(`${fieldName}.fichedesoin`)}
                            value={ficheDeSoin?.id || ""}
                            name={`${fieldName}.fichedesoin`}
                          />
                        )}
                      </div>

                      {typeNumber[index]?.number > 1 ? (
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group required">
                            <label htmlFor="first_name">Type Prestation</label>
                            <div className="filter-item-container">
                              <Select
                                placeholder="Selectionner le type de prestation"
                                name={`${fieldName}.type_prestation`}
                                value={typeDefault[index]}
                                onChange={(e) => onSelectTypeP(e, index)}
                                options={typeNumber[index]?.values}
                              />
                              {errors &&
                                errors?.fields !== undefined &&
                                (errors.fields[index]?.type_prestation ? (
                                  <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                    {
                                      errors.fields[index]?.type_prestation
                                        ?.message
                                    }
                                  </div>
                                ) : null)}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-md-6 link-add row">
                        {indexes.length !== 1 && index > 0 && (
                          <button
                            className="link-add-dashboard"
                            onClick={removeFriend(index)}
                          >
                            <FeatherIcon
                              className="icon-theme"
                              style={{ width: "20px", color: "#FF0000" }}
                              icon="x-circle"
                            />
                          </button>
                        )}
                        {indexes.length - 1 === index && (
                          // <button onClick={(e) => this.handleAddClick(e)}>Add</button>
                          <button
                            type="button"
                            onClick={addFriend}
                            className="link-add-dashboard"
                          >
                            <FeatherIcon
                              style={{ width: "20px", color: "#02A69C" }}
                              icon="plus-circle"
                            />
                          </button>
                        )}
                      </div>
                    </fieldset>
                  );
                })}

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlimentSoinModal;
