import React from "react";
import Skeleton from "react-loading-skeleton";
import { createMarkup } from "../../utils/utils";
import { FiAlertCircle } from "react-icons/fi";

function ContentPageCondition({ content }) {
  const { isLoading, data } = content;
  React.useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <div>
      {!!isLoading &&
        [...Array(5)]?.map((el, i) => (
          <Skeleton count={10} width={`100%`} key={i} />
        ))}
      {!isLoading &&
        (data?.length ? (
          <div
            className="text-mention-legales text-conditions"
            dangerouslySetInnerHTML={createMarkup(data[0]?.text || "")}
          />
        ) : (
          <div className="easypm-alert easypm-alert-info p-3" role="alert">
            <FiAlertCircle style={{ fontSize: 24 }} />
            <h4>{"Ce contenu n'est pas encore disponible."}</h4>
          </div>
        ))}
    </div>
  );
}

export default ContentPageCondition;
