/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from "react";
import "./cssFiche/DetailFiche.css";
import { DataContext } from "../store/GlobalState";
import { getDataByID } from "../utils/fetchData";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import DefaultAvatar from "../../images/others/default-profile-avatar.png";
import baseUrl from "../../Http/backend-base-url";
import { formatMoney } from "../../utils/utils";
import "./cssFiche/DetailFacture.css";

function ShowFicheModal() {
  const { state, dispatch } = useContext(DataContext);
  const { auth, ficheDeSoin } = state;
  const [releves, setReleves] = useState([]);
  const [relevesTotal, setRelevesTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (ficheDeSoin?.id) {
      getDataByID("relevesoinbyfiche", ficheDeSoin?.id, auth?.token)
        .then((res) => {
          console.log("res", res, ficheDeSoin);

          setReleves(res.results);
          let total = 0;
          res.results.forEach((element) => {
            total += parseInt(element.montant);
          });
          setRelevesTotal(total);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("err", err);
        });
    }
  }, [auth.token, ficheDeSoin]);

  const handleReset = async () => {
    dispatch({ type: "EDIT_FICHE_DE_SOIN_BY_IPM", payload: {} });
    setIsLoading(true);
    setReleves([]);
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Detail Fiche de soins
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="row" style={{ marginBottom: "1.5rem" }}>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <strong>Ipm: </strong>
                    <span className="crud-form-control">
                      {ficheDeSoin?.beneficiaire?.assurance?.raison_sociale ||
                        ""}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <strong>Adresse: </strong>
                    <span className="crud-form-control">
                      {ficheDeSoin?.beneficiaire?.assurance?.adresse || ""}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <strong>Telephone: </strong>
                    <span className="crud-form-control">
                      {ficheDeSoin?.beneficiaire?.assurance?.telephone || ""}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <strong>Email: </strong>
                    <span className="crud-form-control">
                      {ficheDeSoin?.beneficiaire?.assurance?.email || ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6" style={{ textAlign: "center" }}>
              <div
                className="profile-avatar-container"
                style={{
                  textAlign: "center",
                  marginLeft: "50%",
                  marginTop: "0%",
                }}
              >
                <img
                  // src={DefaultAvatar}
                  src={
                    ficheDeSoin?.ayant_droit
                      ? ficheDeSoin?.ayant_droit?.avatar ===
                        "/mediafiles/avatars/default.png"
                        ? DefaultAvatar
                        : baseUrl + ficheDeSoin?.ayant_droit?.avatar
                      : ficheDeSoin?.beneficiaire?.avatar ===
                        "/mediafiles/avatars/default.png"
                      ? DefaultAvatar
                      : baseUrl + ficheDeSoin?.beneficiaire?.avatar
                  }
                  className="profile-avatar"
                  alt="Profile"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="title-fiche">
              <span>Feuille de malade</span>
            </div>
            <div className="sub-title">
              <strong className="label-title">Société:</strong>&nbsp;
              <strong>
                {ficheDeSoin?.beneficiaire?.employeur?.raison_social ||
                  ficheDeSoin?.beneficiaire?.employeur?.nom ||
                  ""}
              </strong>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <strong className="label-title">No Feuille de Maladie:</strong>
                &nbsp;
                <span className="crud-form-control">
                  {ficheDeSoin?.id || ""}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <strong className="label-title">Matricule:</strong>&nbsp;
                <span className="crud-form-control">
                  {ficheDeSoin?.beneficiaire?.matricule || ""}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <strong className="label-title">Nom du participant:</strong>
                &nbsp;
                <span className="crud-form-control">
                  {ficheDeSoin?.beneficiaire?.prenom +
                    " " +
                    ficheDeSoin?.beneficiaire?.nom || ""}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <strong className="label-title">Date de prestation:</strong>
                &nbsp;
                <span className="crud-form-control">
                  {moment(ficheDeSoin?.date).format("DD/MM/YYYY")}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <strong className="label-title">Nom du praticien:</strong>
                &nbsp;
                <span className="crud-form-control">
                  {ficheDeSoin?.prestataire?.raison_sociale
                    ? ficheDeSoin?.prestataire?.raison_sociale
                    : ficheDeSoin?.prestataire?.prenom +
                        " " +
                        ficheDeSoin?.prestataire?.nom || ""}
                </span>
              </div>
            </div>
            {ficheDeSoin?.ayant_droit ? (
              <div className="col-md-6">
                <div className="form-group">
                  <strong className="label-title">Lien parenté:</strong>&nbsp;
                  <span className="crud-form-control">
                    {ficheDeSoin?.ayant_droit?.statut === "époux(se)"
                      ? "époux(se)"
                      : "enfant"}
                  </span>
                </div>
              </div>
            ) : null}

            <div className="col-md-6">
              <div className="form-group">
                <strong className="label-title">Nom(s) Malade(s):</strong>&nbsp;
                <span className="crud-form-control">
                  {ficheDeSoin?.ayant_droit
                    ? ficheDeSoin?.ayant_droit?.prenom +
                      " " +
                      ficheDeSoin?.ayant_droit?.nom
                    : ficheDeSoin?.beneficiaire?.prenom +
                        " " +
                        ficheDeSoin?.beneficiaire?.nom || ""}
                </span>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="form-group">
                <strong className="label-title">Taux:</strong>&nbsp;
                <span className="crud-form-control">50%</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <strong className="label-title">Montant plafond:</strong>&nbsp;
                <span className="crud-form-control">10 000</span>
              </div>
            </div> */}
            <div className="col-md-12 sub-title">
              <strong className="label-title">RELEVE DES ACTES ET FRAIS</strong>
            </div>
            <div className="col-md-12">
              <table className="table table-striped">
                <thead>
                  <tr className="col-tr">
                    <th scope="col" className="col-th">
                      Date{" "}
                    </th>
                    <th scope="col" className="col-th">
                      Type prestation
                    </th>
                    <th scope="col" className="col-th">
                      Quantité
                    </th>
                    <th scope="col" className="col-th">
                      Montant
                    </th>
                  </tr>
                </thead>
                {!isLoading ? (
                  <tbody>
                    {releves?.length ? (
                      releves.map((releve) => (
                        <tr key={releve?.id}>
                          <td className="col-td">
                            {moment(releve?.date).format("DD/MM/YYYY")}
                          </td>
                          <td className="col-td">
                            {releve?.type_prestation?.libelle}
                          </td>
                          <td className="col-td">{releve?.quantite}</td>
                          <td className="col-td">
                            {/* {new Intl.NumberFormat("sn", {
                              currency: "XOF",
                              style: "currency",
                            }).format(parseInt(releve?.montant))} */}
                            {formatMoney(parseInt(releve?.montant))}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr
                        style={{
                          textAlign: "center",
                          backgroundColor: "transparent",
                          fontSize: "20px",
                        }}
                      >
                        {/* <td className="col-td">Aucune donnée disponible</td> */}
                        <td className="totalText col-td" colSpan="4">
                          <span>Aucune donnée disponible</span>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td className="totalText col-td" colSpan="3">
                        <strong>Montant Total des Actes:</strong>
                      </td>
                      <td className="col-td">
                        {formatMoney(parseInt(relevesTotal))}
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <th scope="row">
                        <Skeleton />
                      </th>
                      {/* <td><Skeleton/></td> */}
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Skeleton />
                      </th>
                      {/* <td><Skeleton/></td> */}
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Skeleton />
                      </th>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Skeleton />
                      </th>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Skeleton />
                      </th>
                      {/* <td><Skeleton/></td> */}
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
            <div className="col-md-12 d-flex">
              <div className="col-md-6">
                <div
                  className="form-group visa-col visa-left"
                  style={{ display: "inline" }}
                >
                  <strong className="label-title">LE GERANT</strong>
                  <br />
                  <span
                    className="crud-form-control"
                    style={{ padding: "0px" }}
                  >
                    <img
                      src={
                        ficheDeSoin?.beneficiaire?.assurance?.signature &&
                        baseUrl +
                          ficheDeSoin?.beneficiaire?.assurance?.signature
                      }
                      style={{ marginTop: "10px", width: "180px" }}
                    />
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group visa-col visa-right">
                  <strong className="label-title">LE PRATICIEN</strong>
                  <br />
                  <span
                    className="crud-form-control"
                    style={{ padding: "0px" }}
                  >
                    <img
                      src={
                        ficheDeSoin?.prestataire?.url &&
                        baseUrl + ficheDeSoin?.prestataire?.url
                      }
                      style={{ marginTop: "10px", width: "180px" }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowFicheModal;
