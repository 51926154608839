import React, { useEffect, useContext, useState } from 'react'
import Header from '../../../Navs/Header'
import NavBarVerticalV2 from '../../navbarVerticalV2/navbarVerticalV2'
import * as IconlyPack from 'react-iconly'
import DevisTable from '../../../Tables/Devis/DevisTable'
import ShowDevisModal from '../../../Modals/ShowDevisModal'
import DevisValidationModal from '../../../Modals/DevisValidationModal'
import ShowDevisFileModal from '../../../Modals/ShowDevisFileModal'
import { DataContext } from '../../../store/GlobalState'
import { getDataByID } from '../../../utils/fetchData'

const GestionDevis = () => {
  const { state, dispatch } = useContext(DataContext)
  const { auth } = state
  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (
        auth.user?.user_type === 'ipm' ||
        auth?.user?.user_type === 'assurance_sante' ||
        auth?.user?.user_type === 'admin_ipm'
      ) {
        if (!auth?.user?.admin_type) {
          getDataByID('devisbyipm', auth?.user?.id, auth.token)
            .then((res) => {
              // console.log(res)
              dispatch({
                type: 'DEVIS_BY_IPM',
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              })
            })
            .catch((e) => {
              console.log('e', e)
            })

          getDataByID('notificationbyipm', auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: 'LIST_NOTIFICATION',
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              })
            },
          )
        } else {
          getDataByID('devisbyipm', auth?.user?.parent, auth.token)
            .then((res) => {
              // console.log(res)
              dispatch({
                type: 'DEVIS_BY_IPM',
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              })
            })
            .catch((e) => {
              console.log('e', e)
            })

          getDataByID(
            'notificationbyipm',
            auth?.user?.parent,
            auth?.token,
          ).then((res) => {
            dispatch({
              type: 'LIST_NOTIFICATION',
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })
        }
      }

      if (auth.user?.user_type === 'entreprise') {
        if (!auth?.user?.admin_type) {
          getDataByID('devisbyentreprise', auth?.user?.id, auth.token)
            .then((res) => {
              // console.log(res)
              dispatch({
                type: 'DEVIS_BY_ENTREPRISE',
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              })
            })
            .catch((e) => {
              console.log('e', e)
            })

          getDataByID(
            'notificationbyentreprise',
            auth?.user?.id,
            auth?.token,
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: 'LIST_NOTIFICATION',
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })
        } else {
          getDataByID('devisbyentreprise', auth?.user?.parent, auth.token)
            .then((res) => {
              // console.log(res)
              dispatch({
                type: 'DEVIS_BY_ENTREPRISE',
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              })
            })
            .catch((e) => {
              console.log('e', e)
            })

          getDataByID(
            'notificationbyentreprise',
            auth?.user?.parent,
            auth?.token,
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: 'LIST_NOTIFICATION',
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })
        }
      }

      if (auth.user?.user_type === 'prestataire') {
        getDataByID('devisbyprestataire', auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: 'DEVIS_BY_PRESTATAIRE',
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })
          .catch((e) => {
            console.log('e', e)
          })

        getDataByID(
          'notificationbyprestataire',
          auth?.user?.id,
          auth?.token,
        ).then((res) => {
          // console.log("notif", res);
          dispatch({
            type: 'LIST_NOTIFICATION',
            payload: res.sort((a, b) => a?.id - b?.id).reverse(),
          })
        })
      }
    }
  }, [auth])
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={`prestataire`} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Devis</h1>
                  </div>

                  {/* <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target=".add-facture-modal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Ajouter une facture
                    </span>
                  </div> */}
                </div>
                <div className="parent-table-container">
                  <DevisTable />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal show-devis-modal"
                  id="AddModalItem"
                  data-backdrop="static"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <ShowDevisModal />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal show-devis-file-modal"
                  id="AddModalItem"
                  data-backdrop="static"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <ShowDevisFileModal />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal devis-validation-modal"
                  id="AddModalItem"
                  data-backdrop="static"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <DevisValidationModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GestionDevis
