import React, { useContext, useEffect } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import "react-toastify/dist/ReactToastify.css";
import Entreprises from "../../../Tables/Entreprises/Entreprises";
import CRUDEntrepriseModal from "../../../Modals/CRUDEntrepriseModal";
import UseCreateEntrepriseForm from "./UseCreateEntrepriseForm";
// import $ from 'jquery'
// import entreprise from '../../../Services/Entreprise'
import { useHistory } from "react-router-dom";
import { DataContext } from "../../../store/GlobalState";
import SendMessageModal from "../../../Modals/SendMessageModal";
import { getDataByID } from "../../../utils/fetchData";

var GestionEntreprises = () => {
  const {
    register,
    onSubmit,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
    setIsFormRequestError,
    isFormRequestError,
    setValue,
    reset,
    unregister,
    isSuccess,
  } = UseCreateEntrepriseForm();
  const userID = sessionStorage.getItem("userID");
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);

  const { auth, entreprises_by_ipm } = state;

  const history = useHistory();

  React.useEffect(() => {
    if (auth?.user?.id) {
      if (
        auth.user?.user_type === "ipm" ||
        auth?.user?.user_type === "assurance_sante" ||
        auth?.user?.user_type === "admin_ipm"
      ) {
        if (!auth?.user?.admin_type) {
          getDataByID("entreprisebyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_ENTREPRISE_BY_IPM",
                payload: res.sort((a, b) => b.id - a.id),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        } else {
          getDataByID("entreprisebyassurance", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_ENTREPRISE_BY_IPM",
                payload: res.sort((a, b) => b.id - a.id),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyipm",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }
    }
  }, [auth?.user?.id]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  var handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType="ipm" />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 entry">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      Adhérents &nbsp;
                      <span className="badge badge-info easypm-badge-info">
                        {" "}
                        {entreprises_by_ipm.length}{" "}
                      </span>
                    </h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target=".add-entreprise-modal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Ajouter un adhérent
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <Entreprises />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal add-entreprise-modal"
                  id="AddModalItem"
                  data-backdrop="static"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <CRUDEntrepriseModal
                    register={register}
                    fromIpmID={userID}
                    onSubmit={onSubmit}
                    setValue={setValue}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    handleReset={handleReset}
                    reset={reset}
                    isSuccess={isSuccess}
                    unregister
                  />
                </div>

                {/* Modal send message */}
                <div
                  className="modal fade easypm-modal send-message-modal"
                  id="SendMessageModal"
                  tabIndex="-1"
                  aria-labelledby="SendMessageModallabel"
                  aria-hidden="true"
                >
                  <SendMessageModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Notification Toast */}
      {/* {isSuccessfullySubmitted || isFormRequestError  ?
                <ToastContainer /> : ''
            } */}
    </div>
  );
};

export default GestionEntreprises;
