/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import FeatherIcon from 'feather-icons-react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { userLogin } from '../../../Services/Endpoints'
import { DataContext } from '../../../store/GlobalState'
import { NavLink, useHistory } from 'react-router-dom'
import './ConnexionBeneficiaire.css'
import EmptyHeader from '../../../Auth/EmptyHeader'
import ResetPasswordModal from '../../../Auth/ResetPasswordModal'

export default function ConnexionBeneficiaire() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm()
  const [isLoginInProgress, setIsLoginInProgress] = useState(false)

  const history = useHistory()

  const { state, dispatch } = useContext(DataContext)
  const { auth } = state

  // useEffect(() => {
  //   if ((auth.token || window.sessionStorage.getItem('authToken')) && (window.sessionStorage.getItem('userType') === 'assurance_sante' || window.sessionStorage.getItem('userType') === 'ipm' )) {
      
  //     history.push('/ipm/dashboard')
  //     sessionStorage.removeItem('user')
  //     // console.log(window.sessionStorage.getItem('authToken'))
  //   }

  //   if ((auth.token || window.sessionStorage.getItem('authToken')) && (window.sessionStorage.getItem('userType') === 'entreprise')) {
      
  //     history.push('/entreprise/dashboard')
  //     sessionStorage.removeItem('user')
  //     // console.log(auth.user.user_type)
  //   }

  //   if ((auth.token || window.sessionStorage.getItem('authToken')) && (window.sessionStorage.getItem('userType') === 'prestataire')) {
      
  //     history.push('/prestataire/dashboard')
  //     sessionStorage.removeItem('user')
  //     // console.log(auth.user.user_type)
  //   }
  // }, [auth])

  const onSubmit = async (data) => {
    setIsLoginInProgress(true)
    await userLogin(data).then(async () => {
      let token = sessionStorage.getItem('authToken')
      let user = JSON.parse(sessionStorage.getItem('user'))
      //sessionStorage.setItem('userType', user?.id)
      let userType = window.sessionStorage.getItem('userType') ;
      
      await dispatch({
        type: 'AUTH',
        payload: {
          token: token,
          user: user,
        },
      })
      
      
      setIsLoginInProgress(false)
      if (token  && (userType === 'assurance_sante' || userType === 'ipm' )) {
       
        window.location = '/ipm/dashboard'
        sessionStorage.removeItem('user')
      // console.log(window.sessionStorage.getItem('authToken'))
    }

    if ((token) && (userType === 'entreprise')) {
      window.location = '/entreprise/dashboard'
      sessionStorage.removeItem('user')
      // console.log(auth.user.user_type)
    }

    if ((token) && (userType === 'prestataire')) {
      window.location = '/prestataire/dashboard'
      sessionStorage.removeItem('user')
      console.log(auth?.user?.user_type)
    }
    
    })
  }
  //console.log('aurh', auth?.user)
  return (
    <>
      <EmptyHeader />
      <div className="container login-page-container">
        <div className="row login-page-row">
          <div className="col-md-6 left-side-col"></div>

          <div className="col-md-6 right-side-col">
            <div className="login-form-container">
              <div className="login-form-introduction">
                <h1 className="login-title-label">Connectez-vous</h1>
              </div>
              <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="user" />
                    </span>
                  </div>
                  <input
                    name="email"
                    id="email"
                    type="text"
                    className="form-control input-with-icon"
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'Ce champ est obligatoire.',
                      },
                      pattern: {
                        value: new RegExp(
                         /^(?<user>[a-zA-Z0-9._%+'-]+)@(?<domain>[a-zA-Z0-9.'-]+\.[a-zA-Z]{2,4})$/,
                        ),
                        message: "Votre email n'est pas valide",
                      },
                    })}
                    placeholder="Adresse mail"
                  />
                  {errors.email && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {' '}
                      {errors.email.message}{' '}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="lock" />
                    </span>
                  </div>
                  <input
                    name="password"
                    id="password"
                    {...register('password', {
                      required: {
                        value: true,
                        message: 'Ce champ est obligatoire.',
                      },
                    })}
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Mot de passe"
                  />
                  {errors.password && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {' '}
                      {errors.password}{' '}
                    </div>
                  )}
                </div>

                <div className="dis-flex justify-content-end m-b-40">
                  <button
                    onClick={(e) => e.preventDefault()}
                    type="button"
                    className="mdpOublie-assurance"
                    data-toggle="modal"
                    data-target=".open-reset-password-modal"
                  >
                    Mot de passe oublié ?
                  </button>
                </div>

                <ResetPasswordModal />

                <div className="row submit-btn-row pt-3">
                  <div className="col-md-8 offset-md-4 submit-btn-col--assurance">
                    {isLoginInProgress === false ? (
                      <button type="submit" className="btn-connexion-assurance">
                        Connexion
                      </button>
                    ) : (
                      <button className="btn-connexion-assurance in-progress-btn">
                        Connexion en cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
                {/* <div lassName="py-3">
                    <p className="inscrivez-vous mt-3">
                      <font className="color-part1">
                        Vous n’avez pas de compte? Inscrivez-vous
                      </font>
                      <font className="color-part2 pl-2">
                        <NavLink className="color-part2" to="/inscription">
                          ici
                        </NavLink>{" "}
                      </font>
                    </p>
                  </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
