import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Facture from "../Services/Facture";
import { updateItem } from "../store/Actions";
import { DataContext } from "../store/GlobalState";
import { getDataByID } from "../utils/fetchData";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

registerLocale("fr", fr);

const EditFactureModal = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { facture, auth, ipms } = state;
  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());
  const [load, setLoad] = React.useState(false);
  const [isValid, setIsValid] = React.useState(true);
  // eslint-disable-next-line
  const { register, handleSubmit, formState, setValue, getValues } = useForm();

  useEffect(() => {
    if (facture?.id) {
      // console.log("facture", facture);
      setStartDate(new Date(facture?.date_debut));
      setEndDate(new Date(facture?.date_fin));
      register("date_debut");
      setValue("date_debut", facture?.date_debut);
      register("date_fin");
      setValue("date_fin", facture?.date_fin);
      if (auth?.user?.user_type === "prestataire") {
        register("ipm");
        setValue("ipm", facture?.ipm?.id);
      } else {
        register("entreprise");
        setValue("entreprise", facture?.entreprise?.id);
      }
    }
  }, [facture?.id, auth]);

  const onSubmit = async (data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    };
    setLoad(true);
    console.log("data update=>", data);
    if (!data.ipm) {
      data.ipm = facture.ipm?.id;
    }
    if (!data.prestataire) {
      data.prestataire = facture.prestataire?.id;
    }

    if (!data.date_debut) {
      data.date_debut = facture.date_debut;
    }

    if (!data.date_fin) {
      data.date_fin = facture.date_fin;
    }

    try {
      const request = await Facture.putFacture(facture.id, data, config);
      // let factureInfo = request;
      // dispatch(
      //   updateItem(
      //     prestations,
      //     prestation.id,
      //     prestationInfo,
      //     "ADD_FACTURE_FICHE_DE_SOIN"
      //   )
      // );
      if (auth?.user?.user_type === "prestataire") {
        getDataByID("facturebyprestataire", auth?.user?.id, auth?.token).then(
          (res) => {
            dispatch({
              type: "LIST_FACTURE_BY_PRESTATAIRE",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );
      } else {
        if (auth?.user?.user_type === "admin_ipm") {
          getDataByID("facturebyipm", auth?.user?.parent, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_FACTURE_BY_IPM",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        } else {
          getDataByID("facturebyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_FACTURE_BY_IPM",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        }
      }

      setLoad(false);
      window.$(".edit-facture-modal").modal("hide");
      dispatch({ type: "EDIT_FACTURE", payload: {} });
      toast.success("Facture modifiée avec succès.");
    } catch (error) {
      console.log("error", error);
      setLoad(false);
      toast.error("Oupss! Une erreur est survenue lors de la modification.");
    }
  };

  const onSelect = (e) => {
    if (auth?.user?.user_type === "prestataire") {
      register("ipm");
      setValue("ipm", e.currentTarget.value);
    } else {
      register("entreprise");
      setValue("entreprise", e.currentTarget.value);
    }
  };

  const handleDate = (date) => {
    setStartDate(date);
    register("date_debut");
    setValue("date_debut", moment(date).format("YYYY-MM-DD"));
    if (endDate && date > endDate) {
      setIsValid(false);
    }
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      register("date_fin");
      setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Modification de la facture
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "EDIT_FACTURE", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                {auth?.user?.user_type !== "prestataire" ? (
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="first_name">Entreprise</label>
                      <div className="filter-item-container">
                        {facture?.id && (
                          <select
                            name="entreprise"
                            onChange={(e) => onSelect(e)}
                            defaultValue={facture?.entreprise?.id || ""}
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                          >
                            <option hidden value="">
                              entreprise
                            </option>
                            {props.entreprises.map((ent) => (
                              <option key={ent?.id} value={ent?.id}>
                                {ent.raison_sociale}
                              </option>
                            ))}
                          </select>
                        )}

                        {formState.errors && formState.errors.entreprise && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.entreprise?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="first_name">Ipm</label>
                      <div className="filter-item-container">
                        {facture?.id && (
                          <select
                            name="ipm"
                            defaultValue={facture?.ipm?.id || ""}
                            onChange={(e) => onSelect(e)}
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                          >
                            <option hidden value="">
                              Ipm
                            </option>
                            {ipms.map((ipm) => (
                              <option key={ipm.id} value={ipm.id}>
                                {ipm.raison_sociale}
                              </option>
                            ))}
                          </select>
                        )}

                        {formState.errors && formState.errors.ipm && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.ipm?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required crud-interm prestataire-side">
                    <label htmlFor="date_debut">Date de début</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      popperPlacement="top"
                      peekNextMonth
                      maxDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date_debut"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {formState.errors && formState.errors.date_debut && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-3">
                        {formState.errors.date_debut?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required crud-interm prestataire-side">
                    <label htmlFor="date_fin">Date de Fin</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={endDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      maxDate={new Date()}
                      showYearDropdown
                      dropdownMode="select"
                      name="date_fin"
                      locale="fr"
                      onChange={(date) => handleDateFin(date)}
                    />
                    {formState.errors && formState.errors.date_fin && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-3">
                        {formState.errors.date_fin?.message}
                      </div>
                    )}
                    {!isValid && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        La date de fin ne doit pas être inférieure à la date de
                        début.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() =>
                      dispatch({ type: "EDIT_FACTURE", payload: {} })
                    }
                  >
                    Retour
                  </button>

                  {load && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!load && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFactureModal;
