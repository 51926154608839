import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";
import AssuranceMaladie from "../../../Services/AssuranceMaladie";

function UseEditAssureurForm() {
  const userType = window.sessionStorage.getItem("userType");
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email("L'adresse email n'est pas valide"),
        raison_sociale: yup.string(),
        telephone: yup.string(),
        adresse: yup.string(),
        ninea: yup
          .string()
          .matches(
            new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/),
            "Le NINEA doit être de format alpha-numérique."
          )
          .length(12, "Le ninea doit comporter 12 caractères")
          .required("Le NINEA est requis"),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitEditForm = async (formValues, e) => {
    if (e !== "valid") {
      setIsSuccessfullySubmitted(true);
    }

    var userRequest = AssuranceMaladie.editAssuranceMaladie(
      auth?.user?.id,
      formValues,
      requestConfig
    );
    console.log("data", formValues, e);
    await userRequest
      .then((response) => {
        var profileInfos = response;
        console.log(profileInfos);

        dispatch({
          type: "AUTH",
          payload: { token: auth.token, user: profileInfos },
        });
        setIsSuccessfullySubmitted(false);
        if (e !== "valid") {
          toast.success("Vos informations ont été modifiées avec succès.");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsFormRequestError(true);
        setIsSuccessfullySubmitted(false);

        if (
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          toast.error(
            "Oupsss! L'adresse email est déjà associée à un compte. Veuillez utiliser une autre adresse email.",
            {
              position: "top-right",
              autoClose: 10000,
            }
          );
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (
          error?.response?.status === 400 &&
          !error?.response?.data?.email
        ) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };

  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitEditForm),
    setValue,
    getValues,
    reset,
  };
}

export default UseEditAssureurForm;
