import React, { useContext, useEffect } from 'react'
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from 'react-bootstrap-table'
// import AdminPrestatairesTableData from './AdminPrestatairesTableData'
import AdminPrestatairesTableSkeleton from './AdminPrestatairesTableSkeleton'
import * as IconlyPack from 'react-iconly'
import { DataContext } from '../../store/GlobalState'
import moment from "moment";
// import { NavLink } from 'react-router-dom'

var AdminPrestatairesTable = (props) => {
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;

  useEffect(() => {
    // setTimeout(() => {
    //   setIsPageLoading(false)
    // }, 5000)
    if (auth?.token && auth?.user?.id) {
      setIsPageLoading(false);
    } else {
      setIsPageLoading(true);
    }
  }, [auth]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    if (row?.raison_sociale) {
      return row?.raison_sociale;
    } else {
      return row?.prenom + " " + row?.nom;
    }
  };

  // const agreementFormatter = (cell) => {
  //   if (cell === true) {
  //     return (
  //       <span className="badge badge-success easypm-badge-success"> OUI </span>
  //     )
  //   } else {
  //     return (
  //       <span className="badge badge-danger easypm-badge-danger"> NON </span>
  //     )
  //   }
  // }

  //   const actionFormatter = (cell, row) => {
  //     return (
  //       <div className="table-actions-btn-container">
  //         <button className="action-btn show-btn">
  //           <IconlyPack.Show
  //             set="light"
  //             primaryColor="#C8C8C8"
  //             className="action-btn-icon action-show-icon"
  //           />
  //         </button>
  //         <button className="action-btn edit-btn">
  //           <IconlyPack.Edit
  //             set="light"
  //             primaryColor="#C8C8C8"
  //             className="action-btn-icon action-edit-icon"
  //           />
  //         </button>
  //         <button className="action-btn delete-btn">
  //           <IconlyPack.Delete
  //             set="light"
  //             primaryColor="#C8C8C8"
  //             className="action-btn-icon action-delete-icon"
  //           />
  //         </button>
  //       </div>
  //     )
  //   }

  const specialiteFormat = (cell, row) => {
    return row?.specialite?.nom;
  };

  const dateFormater = (cell, row) => {
    return moment(new Date(cell)).format("DD/MM/YYYY");
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".show-prestataire-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn edit-btn" title="Modification">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".edit-prestataire-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn" title="Archivage">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            data-toggle="modal"
            data-target=".delete-prestataire-modal"
            onClick={() => getItem(row)}
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    console.log("item", item);
    dispatch({
      type: "EDIT_PRESTATAIRE",
      payload: item,
    });
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <AdminPrestatairesTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={props?.prestataires}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="nom"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Nom
                  </TableHeaderColumn>

                  {/* <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="120"
                    dataField="ninea"
                  >
                    NINEA
                  </TableHeaderColumn> */}
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // filter={{
                    //   type: "TextFilter",
                    //   style: { borderRadius: "55px", visibility: "hidden" },
                    // }}
                    width="150"
                    dataFormat={dateFormater}
                    dataField="date_fin"
                  >
                    Date de fin
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="telephone"
                  >
                    Téléphone
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataFormat={(cell, row) => specialiteFormat(cell, row)}
                    // width="300"
                    dataField="specialite"
                  >
                    Spécialité
                  </TableHeaderColumn>
                  {/* <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    // width="120"
                    dataField="agreement"
                    dataFormat={(cell) => agreementFormatter(cell)}
                  >
                    Agréement
                  </TableHeaderColumn> */}

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="120"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPrestatairesTable
