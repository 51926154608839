import React, { useEffect, useContext } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import * as IconlyPack from 'react-iconly';
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getDataByID, putData } from "../../../utils/fetchData";
import DetailPrestataireSkeleton from "./DetailPrestataireSkeleton";
import BeneficiaireSkeleton from "./BeneficiaireSkeleton";
import moment from "moment";

function DetailBeneficiaireNotification() {
  const [userType, setUserType] = React.useState();
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [beneficiaire, setBeneficiaire] = React.useState({});

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  // console.log('props', location)
  useEffect(() => {
    setUserType(window.sessionStorage.getItem("userType"));

    if (params?.id && auth?.token) {
      getDataByID("beneficiaire", params?.id, auth?.token).then((res) => {
        console.log("res", res);
        setBeneficiaire(res);
        setIsPageLoading(false);
      });
    }
  }, [params?.id, auth?.token]);

  const handleValid = (item) => {
    let id = parseInt(window.sessionStorage.getItem("notifId"));
    putData("notification/" + id + "/", { valid: true }, auth?.token).then(
      (res) => {
        // console.log('res', res)
        if (userType !== "super_ipm" && userType !== "entreprise") {
          if (auth?.user?.admin_type) {
            getDataByID(
              "notificationbyipm",
              auth?.user?.parent,
              auth?.token
            ).then((resp) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
              });
            });
          } else {
            getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
              (resp) => {
                // console.log('notif', resp)
                window.sessionStorage.removeItem("notifId");
                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
                });
              }
            );
          }
          // history.push(`/ipm/profil-beneficiaire/${res.beneficiaire}`);
        } else if (userType === "super_ipm") {
          getDataByID(
            "notificationbysuperadmin",
            auth?.user?.id,
            auth?.token
          ).then((res) => {
            window.sessionStorage.removeItem("notifId");
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
          // history.push(`/entreprise/profil-employes/${res.beneficiaire}`);
        } else {
          if (auth?.user?.admin_type) {
            getDataByID(
              "notificationbyentreprise",
              auth?.user?.parent,
              auth?.token
            ).then((res) => {
              window.sessionStorage.removeItem("notifId");
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            });
          } else {
            getDataByID(
              "notificationbyentreprise",
              auth?.user?.id,
              auth?.token
            ).then((res) => {
              window.sessionStorage.removeItem("notifId");
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            });
          }
        }
        if (
          item === "rejeter" &&
          userType !== "super_ipm" &&
          userType !== "entreprise"
        ) {
          history.push(`/ipm/gestion-des-beneficiaires`);
          // history.goBack()
        }

        if (item === "rejeter" && userType === "entreprise") {
          history.push(`/entreprise/gestion-des-employes`);
          // history.goBack()
        }

        if (item === "rejeter" && userType === "super_ipm") {
          history.push(`/admin/gestion-des-etablissements-et-pharmacies`);
        }
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userType !== "super_ipm" && userType !== "entreprise") {
      setIsSuccessfullySubmitted(true);
      putData(
        "beneficiaire/" + params?.id + "/",
        { is_active: true },
        auth?.token
      )
        .then((res) => {
          console.log("res", res);

          toast.success("Demande d'adhésion acceptée.");
          setIsSuccessfullySubmitted(false);
          if (auth?.user?.user_type === "assureur") {
            if (auth?.user?.admin_type) {
              getDataByID(
                "beneficiairebyassurance",
                auth?.user?.parent,
                auth.token
              )
                .then((res) => {
                  dispatch({
                    type: "BENEFICIAIRE_ASSUREUR",
                    payload: {
                      isLoading: false,
                      data: res?.sort((a, b) => b.id - a.id),
                    },
                  });
                  history.push(`/assurance/assures`);
                })
                .catch((e) => {
                  console.log("e", e);
                });
            } else {
              getDataByID("beneficiairebyassurance", auth?.user?.id, auth.token)
                .then((res) => {
                  dispatch({
                    type: "BENEFICIAIRE_ASSUREUR",
                    payload: {
                      isLoading: false,
                      data: res?.sort((a, b) => b.id - a.id),
                    },
                  });
                  history.push(`/assurance/assures`);
                })
                .catch((e) => {
                  console.log("e", e);
                });
            }
          } else {
            if (auth?.user?.admin_type) {
              getDataByID(
                "beneficiairebyassurance",
                auth?.user?.parent,
                auth.token
              )
                .then((res) => {
                  dispatch({
                    type: "ADD_BENEFICIAIRE",
                    payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                  });
                  history.push(`/ipm/gestion-des-beneficiaires`);
                })
                .catch((e) => {
                  console.log("e", e);
                });
            } else {
              getDataByID("beneficiairebyassurance", auth?.user?.id, auth.token)
                .then((res) => {
                  dispatch({
                    type: "ADD_BENEFICIAIRE",
                    payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                  });
                  history.push(`/ipm/gestion-des-beneficiaires`);
                })
                .catch((e) => {
                  console.log("e", e);
                });
            }
          }

          if (window.sessionStorage.getItem("notifId")) {
            handleValid("accept");
          }
        })
        .catch((e) => {
          setIsSuccessfullySubmitted(false);
          toast.error("Oupss! Une erreur est survenue lors de la validation.");
        });
    } else {
      if (location?.state?.notification) {
        handleValid("rejet");
      }
    }
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div
            className={
              userType === "assureur"
                ? "nav-container nav-assurance-color"
                : "nav-container"
            }
          >
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      {userType === "super_ipm"
                        ? "Détail Adhérent"
                        : "Demande d'adhésion"}
                    </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="add-family-form-container">
                    {isPageLoading && <BeneficiaireSkeleton />}
                    {!isPageLoading && (
                      <div className="crud-form">
                        <div className="row crud-row">
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="numero_contrat">Genre: </strong>

                              <strong className="crud-form-control">
                                {beneficiaire?.sexe}
                              </strong>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="name">Prénom: </strong>
                              <strong className="crud-form-control">
                                {beneficiaire?.prenom}
                              </strong>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="name">Nom: </strong>
                              <strong className="crud-form-control">
                                {beneficiaire?.nom}
                              </strong>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="specialite">
                                Date de naissance:{" "}
                              </strong>
                              <strong className="crud-form-control">
                                {moment(
                                  new Date(beneficiaire?.date_de_naissance)
                                ).format("DD/MM/YYYY")}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="ninea">
                                Situation Matriomiale:{" "}
                              </strong>
                              <strong className="crud-form-control">
                                {beneficiaire?.situation_matrimoniale}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="ninea">Groupe sanguin: </strong>
                              <strong className="crud-form-control">
                                {beneficiaire?.groupe_sanguin}
                              </strong>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="ninea">Nationalité: </strong>
                              <strong className="crud-form-control">
                                {beneficiaire?.nationnalite}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="email">Email: </strong>
                              <strong className="crud-form-control">
                                {beneficiaire?.email}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="adresse">Adresse: </strong>
                              <strong className="crud-form-control">
                                {beneficiaire?.adresse}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="telephone">Téléphone: </strong>
                              <strong className="crud-form-control">
                                {beneficiaire?.telephone}
                              </strong>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="first_name">
                                Date de début:{" "}
                              </strong>
                              <strong className="crud-form-control">
                                {beneficiaire?.date_debut
                                  ? moment(
                                      new Date(beneficiaire?.date_debut)
                                    ).format("DD/MM/YYYY")
                                  : ""}
                              </strong>
                            </div>
                          </div>

                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="first_name">
                                Date de fin:{" "}
                              </strong>
                              <strong className="crud-form-control">
                                {beneficiaire?.date_fin
                                  ? moment(
                                      new Date(beneficiaire?.date_fin)
                                    ).format("DD/MM/YYYY")
                                  : ""}
                              </strong>
                            </div>
                          </div>

                          {beneficiaire?.is_active &&
                          userType === "super_ipm" &&
                          userType !== "entreprise" &&
                          userType !== "prestataire" ? (
                            <div
                              className="col-md-6 offset-md-6 crud-form-action-btn-container"
                              style={{ justifyContent: "flex-end" }}
                            >
                              <button
                                className={`btn btn-default ${
                                  userType === "assureur"
                                    ? "cancelleb-btn-assurance"
                                    : "cancelleb-btn"
                                }`}
                                onClick={() => handleValid("rejeter")}
                              >
                                Retour
                              </button>
                            </div>
                          ) : (
                            <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                              <button
                                className={`btn btn-default ${
                                  userType === "assureur"
                                    ? "cancelleb-btn-assurance"
                                    : "cancelleb-btn"
                                }`}
                                onClick={() => handleValid("rejeter")}
                              >
                                {userType === "super_ipm"
                                  ? "Retour"
                                  : "Rejeter"}
                              </button>
                              {isSuccessfullySubmitted && (
                                <button
                                  className={`btn ${
                                    userType === "assureur"
                                      ? "crud-submit-btn-assurance"
                                      : "crud-submit-btn"
                                  }`}
                                >
                                  En cours &nbsp;
                                  <i className="fas fa-spin fa-spinner"></i>
                                </button>
                              )}
                              {!isSuccessfullySubmitted && (
                                <button
                                  className={`btn ${
                                    userType === "assureur"
                                      ? "crud-submit-btn-assurance"
                                      : "crud-submit-btn"
                                  }`}
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  Accepter
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailBeneficiaireNotification;
