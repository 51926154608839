import React from 'react'
import { NavLink } from 'react-router-dom'
import * as IconlyPack from 'react-iconly'

var PrestataireMobileNavBarContent = () => {
  return (
    <>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/prestataire/dashboard"
          activeClassName="actived"
        >
          <IconlyPack.Chart
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Tableau de bord</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/prestataire/gestion-des-factures"
          activeClassName="actived"
        >
          <IconlyPack.Wallet
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Facturation</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/prestataire/fiche-de-soin-valide"
          activeClassName="actived"
        >
          <i
            className="fa fa-qrcode fa-lg icon-navbar-vertical"
            aria-hidden="true"
            style={{ color: '#ffffff' }}
          ></i>
          <span className="mobile-navlink-label">Soins et Médicaments</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/prestataire/gestion-des-devis"
          activeClassName="actived"
        >
          <IconlyPack.Activity
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Devis</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/prestataire/parametres"
          activeClassName="actived"
        >
          <IconlyPack.Setting
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Paramétres</span>
        </NavLink>
      </li>
    </>
  )
}

export default PrestataireMobileNavBarContent
