import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ayantDroit from "../../Services/ayantDroit";
// import entreprise from '../../../Services/Entreprise'
// import { DataContext } from '../../store/GlobalState'
import { toast } from "react-toastify";
import moment from "moment";
// import { getDataByID } from '../../utils/fetchData'

var UseAddAyantDroitForm = () => {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [ayantDroitData, setAyantDroitData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  // const { state } = useContext(DataContext)
  // const { auth, ayantDroits } = state

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        nom: yup.string().required("Le Nom est requis"),
        prenom: yup.string().required("Le prénom est requis"),
        statut: yup.string().required("Le Lien de parenté est requis"),
        date_naissance: yup
          .string()
          .required("La date de naissance est requise")
          .default(moment(new Date()).format("YYYY-MM-DD")),
        sexe: yup.string().required("Le sexe est requis"),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    if (formState?.errors?.length) {
      console.log(formState.errors);
    }
  }, [formState]);

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    // formValues['date_naissance'] = moment(formValues?.date_naissance).format(
    //   'YYYY-MM-DD',
    // )
    const fd = new FormData();
    if (formValues?.avatar) {
      fd.append("avatar", formValues?.avatar);
    }

    if (formValues?.certificat?.length) {
      fd.append("certificat", formValues?.certificat[0]);
    }

    fd.append("prenom", formValues?.prenom);
    fd.append("nom", formValues?.nom);
    fd.append("beneficiaire", formValues?.beneficiaire);
    fd.append("statut", formValues?.statut);
    fd.append("date_naissance", formValues?.date_naissance);
    fd.append("sexe", formValues?.sexe);
    console.log(formValues);
    let statut = formValues.statut;
    var beneRequest = ayantDroit.onCreateAyantDroit(fd, requestConfig);
    await beneRequest
      .then(async (response) => {
        var ayantDroitInfos = response;
        await setAyantDroitData(ayantDroitInfos);

        await toast.success("Un ayant droit ajouté avec succès.");
        await window.$(".add-family-modal").modal("hide");
        await e.target.reset();
        await reset();
        // register("date_naissance");
        // setValue("date_naissance", moment(new Date()).format("YYYY-MM-DD"));
        register("statut");
        setValue("statut", statut);
      })
      .catch((error) => {
        console.log(error);
        setIsFormRequestError(true);
        setIsSuccessfullySubmitted(false);
        if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };

  return {
    register,
    errors,
    formState,
    ayantDroitData,
    setAyantDroitData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitCreateForm),
  };
};

export default UseAddAyantDroitForm;
