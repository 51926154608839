import React, { useContext, useEffect, useState } from "react";
import Header from "../../../Navs/Header";
import * as IconlyPack from "react-iconly";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import { DataContext } from "../../../store/GlobalState";
import Beneficiaires from "../../../Tables/Beneficiaires/Beneficiaires";
import UseCreateBeneficiaireForm from "../GestionBeneficiaires/UseCreateBeneficiaireForm";
import CRUDBeneficiaireModal from "../../../Modals/CRUDBeneficiaireModal";
import { useParams } from "react-router-dom";
import { getDataByID } from "../../../utils/fetchData";

var BeneficiairesByEntreprise = () => {
  const [beneficiaires, setBeneficiaires] = useState([]);
  const params = useParams();
  const { state, dispatch } = useContext(DataContext);
  const { auth, entreprise } = state;
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
    setIsFormRequestError,
    isFormRequestError,
    watch,
    reset,
    setValue,
  } = UseCreateBeneficiaireForm();

  useEffect(() => {
    if (auth.token) {
      getDataByID("entreprise", params?.id, auth.token)
        .then((res) => {
          console.log(res);
          dispatch({
            type: "EDIT_ENTREPRISE",
            payload: res,
          });
        })
        .catch((e) => {
          console.log("e", e);
        });
      getDataByID("beneficiairebyemploye", params?.id, auth.token)
        .then((res) => {
          dispatch({
            type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
            payload: res.reverse(),
          });
        })
        .catch((e) => {
          console.log("e", e);
        });
    }
  }, [params?.id, auth?.token]);

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType="ipm" />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      Adhérents de {entreprise?.raison_sociale}
                    </h1>
                  </div>
                  <div className="adding-item-container">
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span
                      className="adding-item-label"
                      data-toggle="modal"
                      data-target=".add-beneficiaire-modal"
                    >
                      Ajouter un Adhérent
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <Beneficiaires
                    isEntrepriseList={true}
                    entrepriseId={entreprise?.id && entreprise?.id}
                  />
                </div>
                {/* Modal */}
                <div
                  className="modal fade easypm-modal add-beneficiaire-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <CRUDBeneficiaireModal
                    entrepriseId={entreprise?.id && entreprise?.id}
                    register={register}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    reset={reset}
                    watch={watch}
                    setValue={setValue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiairesByEntreprise;
