import React, { useEffect, useContext, useState } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import EditSpecialiteModal from "../../Modals/EditSpecialiteModal";
import { DataContext } from "../../store/GlobalState";
import swal from "sweetalert2";
import { getData } from "../../utils/fetchData";
import Specialite from "../../Services/Specialite";
import { toast } from "react-toastify";
import SpecialiteSkeleton from "./SpecialiteSkeleton";

const SpecialiteTable = () => {
  const { state, dispatch } = useContext(DataContext);

  const { auth, list_specialites } = state;

  var [isPageLoading, setIsPageLoading] = useState(true);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 5000);
  }, []);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const onDelete = (id) => {
    swal
      .fire({
        title: "Êtes vous sûr de vouloir supprimer cette spécialité?",
        customClass: {
          confirmButton: "btn-success",
          cancelButton: "btn-danger",
        },
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          var deleteRequest = Specialite.deleteSpecialite(id, requestConfig);
          return deleteRequest
            .then((response) => {
              getData("specialite/", auth.token).then((response) => {
                dispatch({
                  type: "LIST_SPECIALITES",
                  payload: response.results.sort((a, b) => b?.id - a?.id),
                });
                setIsPageLoading(false);
              });
            })
            .catch((error) => {
              swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          toast.success("Specialité supprimée avec succès.");
        }
      });
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {/* <button className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".show-type-prestation-modal"
            onClick={() => getItem(row)}
          />
        </button> */}
        <button className="action-btn edit-btn" title="Modification">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".edit-specialite-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn" title="Archivage">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            onClick={() => onDelete(row?.id)}
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    dispatch({
      type: "SPECIALITE",
      payload: item,
    });
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <SpecialiteSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={list_specialites}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="100"
                    dataField="id"
                    isKey={true}
                    hidden={false}
                  >
                    N°
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="center"
                    dataField="nom"
                  >
                    Nom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      padding: 15,
                    }}
                    // width="90"
                    dataAlign="center"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          {/* Modal */}
          <div
            className="modal fade easypm-modal edit-specialite-modal"
            id="EditSpecialiteModalItem2"
            tabIndex="-1"
            aria-labelledby="EditSpecialiteModalItem2Label"
            aria-hidden="true"
            data-backdrop="static"
          >
            <EditSpecialiteModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialiteTable;
