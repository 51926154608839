import { ficheDeSoin } from './Endpoints'
import axios from 'axios'

export default {
    async postCreateFicheDeSoin(data, config) {
        const response = await axios.post(ficheDeSoin.OnPostFicheDeSoin(), data, config);
        return response.data;
    },
    async editFicheDeSoin(id, data, config) {
        const response = await axios.put(ficheDeSoin.onPutFicheDeSoin(id), data, config);
        return response.data;
    },
    async deleteFicheDeSoin(id, config) {
        const response = await axios.delete(ficheDeSoin.onDeleteFicheDeSoin(id), config);
        return response;
    }, 
}