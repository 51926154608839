import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
// import "react-datepicker/dist/react-datepicker.css";

registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});
const LineChart = (props) => {
  const [date, setDate] = useState(new Date());
  const [count, setCount] = useState([]);
  const [stat, setStat] = useState([]);
  const [labels, setLabels] = useState([]);
  const [label, setLabel] = useState("");

  // let labels = [
  //   "Lundi",
  //   "Mardi",
  //   "Mercredi",
  //   "Jeudi",
  //   "Vendredi",
  //   "Samedi",
  //   "Dimanche",
  // ];

  let data = {
    labels: labels,
    datasets: [
      {
        type: "line",
        label: label,
        data: count,
        fill: false,
        backgroundColor: "#06DED1",
        borderColor: "#06DED1",
        hoverBackgroundColor: "#06DED1",
        hoverBorderColor: "#06DED1",
        yAxisID: "y-axis-1",
      },
    ],
  };

  let options = {
    responsive: true,
    labels: labels,
    tooltips: {
      mode: "label",
    },
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
          },
          labels: labels,
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
        },
        {
          type: "linear",
          display: false,
          position: "right",
          id: "y-axis-2",
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
        },
      ],
    },
  };

  let plugins = [
    {
      afterDraw: (chartInstance, easing) => {
        //const ctx = chartInstance.chart.ctx;
        //ctx.fillText("This text drawn by a plugin", 100, 100);
      },
    },
  ];

  useEffect(() => {
    setLabels([
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche",
    ]);
    setLabel("Fiches de Soins");
    setStat(
      props.stat?.filter((st) =>
        moment(st?.day).locale("fr").isSame(date, "week")
      )
    );

    // if (window.sessionStorage.getItem("userType") === "entreprise") {

    // }
  }, [props?.stat]);

  useEffect(() => {
    let days = [0, 0, 0, 0, 0, 0, 0];
    if (stat?.length) {
      stat.forEach((element) => {
        days[moment(element.day).locale("fr").day() - 1] = element.fiche;
      });
    }

    setCount(days);
  }, [stat?.length, stat, date]);

  const handleChangeDate = (date) => {
    setCount([]);
    setDate(date);
    // if (window.sessionStorage.getItem("userType") === "entreprise") {

    // }

    setStat(
      props.stat?.filter((st) =>
        moment(st?.day).locale("fr").isSame(date, "week")
      )
    );
  };
  return (
    <div className="chart-item-container">
      <div className="row mb-2">
        <div className="col-xl-5 col-lg-4 mb-2"></div>
        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6 mb-2 dis-flex">
          <div className="ml-desktop">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="form-select form-select-line-chart ml-md-auto"
              selected={date}
              onChange={(date) => handleChangeDate(date)}
              locale="fr"
            />
          </div>
        </div>
      </div>
      <Bar data={data} options={options} plugins={plugins} />
    </div>
  );
};

export default LineChart;
