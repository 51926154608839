import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../store/GlobalState";
import { getDataByID } from "../utils/fetchData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import Facture from "../Services/Facture";
import { toast } from "react-toastify";
import { formatMoney, formatMontant } from "../../utils/utils";

registerLocale("fr", fr);

var CRUDFactureModal = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { prestations_facture, facture, auth } = state;
  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());
  // var [start, setStart] = React.useState(new Date());
  // var [end, setEnd] = React.useState(new Date());
  const [prestations, setPrestations] = useState([]);
  const [prestationsList, setPrestationsList] = useState([]);
  const [isValid, setIsValid] = React.useState(true);
  const [token, setToken] = useState();
  const userType = window.sessionStorage.getItem("userType");
  const [isLoading, setIsLoading] = useState(false);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  useEffect(() => {
    if (prestations_facture?.length) {
      setPrestations(prestations_facture);
      setPrestationsList(prestations_facture);
    } else {
      setPrestations([]);
      setPrestationsList([]);
    }
  }, [prestations_facture]);

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    setToken(token);
    // if (userType === 'prestataire') {
    props.register("date_debut");
    props.setValue("date_debut", moment(startDate).format("YYYY-MM-DD"));

    props.register("date_fin");
    props.setValue("date_fin", moment(endDate).format("YYYY-MM-DD"));
    // }
    if (endDate && startDate && startDate > endDate) {
      setIsValid(false);
    }

    if (auth?.user?.user_type === "prestataire") {
      props.register("prestataire");
      props.setValue("prestataire", auth?.user?.id);
    }
  }, [auth]);

  React.useEffect(() => {
    if (props.isSuccess) {
      props.register("date_debut");
      props.setValue("date_debut", moment(new Date()).format("YYYY-MM-DD"));

      props.register("date_fin");
      props.setValue("date_fin", moment(new Date()).format("YYYY-MM-DD"));
      // setStartDate(new Date());
      // setEndDate(new Date());

      if (auth?.user?.user_type === "prestataire") {
        props.register("prestataire");
        props.setValue("prestataire", auth?.user?.id);
      }

      if (auth?.user?.user_type !== "prestataire") {
        props.register("ipm");
        props.setValue("ipm", auth?.user?.id);
      }
    }

    if (props?.isFormRequestError) {
      setEndDate(new Date());
      setStartDate(new Date());
    }
  }, [props?.isFormRequestError, props.isSuccess]);

  const onSelect = (e) => {
    if (e.target.value) {
      if (userType === "prestataire") {
        props.register("ipm");
        props.setValue("ipm", e.target.value);
      } else {
        props.register("entreprise");
        props.setValue("entreprise", e.target.value);
      }
    }
  };

  const handleDate = (date) => {
    setStartDate(date);
    props.register("date_debut");
    props.setValue("date_debut", moment(date).format("YYYY-MM-DD"));
    if (endDate && date > endDate) {
      setIsValid(false);
    }
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      props.register("date_fin");
      props.setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };

  const handleReset = () => {
    props.reset();
    setEndDate(new Date());
    setStartDate(new Date());
    props.register("date_debut");
    props.setValue("date_debut", moment(new Date()).format("YYYY-MM-DD"));

    props.register("date_fin");
    props.setValue("date_fin", moment(new Date()).format("YYYY-MM-DD"));
    dispatch({
      type: "LIST_PRESTATION_BY_FACTURE",
      payload: [],
    });
    setIsValid(true);

    dispatch({ type: "EDIT_FACTURE", payload: {} });
    // console.log(prestations);
    setPrestations([]);
    setPrestationsList([]);
    if (auth?.user?.user_type === "prestataire") {
      props.register("prestataire");
      props.setValue("prestataire", auth?.user?.id);
    }
  };

  const onRemoveItem = (e, item) => {
    // console.log("even", e.target.checked, e.target);
    if (e.target.checked) {
      setPrestations([...prestations, item]);
      console.log([...prestations, item]);
    } else {
      setPrestations(
        prestations
          .filter((prestation) => prestation?.id !== item?.id)
          .sort((a, b) => b?.id - a?.id)
      );
    }
    // dispatch({
    //   type: "LIST_PRESTATION_BY_FACTURE",
    //   payload: prestations_facture
    //     .filter((prestation) => prestation?.id !== item?.id)
    //     .sort((a, b) => b?.id - a?.id),
    // });

    // if (
    //   !prestations_facture.filter((prestation) => prestation?.id !== item?.id)
    //     ?.length
    // ) {
    //   handleReset();
    //   window.$(".genere-facture-modal").modal("hide");
    // }
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setPrestationsList(
      prestations_facture?.filter((d) =>
        moment(new Date(d?.date)).isSameOrAfter(date, "date")
      )
    );
    setPrestations(
      prestations_facture?.filter((d) =>
        moment(new Date(d?.date)).isSameOrAfter(date, "date")
      )
    );
  };

  const handleDateFinChange = (date) => {
    setEndDate(date);
    setPrestationsList(
      prestations_facture?.filter((d) =>
        moment(new Date(d?.date)).isSameOrBefore(date, "date")
      )
    );
    setPrestations(
      prestations_facture?.filter((d) =>
        moment(new Date(d?.date)).isSameOrBefore(date, "date")
      )
    );
  };

  const handleEditFacture = async (e) => {
    e.preventDefault();
    if (prestations?.length) {
      setIsLoading(true);
      let data = prestations.map((p) => parseInt(p?.id));
      console.log(data);
      let request = null;
      if (auth?.user?.user_type !== "prestataire") {
        request = Facture.onPutFacture(
          facture?.id,
          { prestations: data },
          requestConfig
        );
      } else {
        request = Facture.onEditFacture(
          facture?.id,
          { prestations: data },
          requestConfig
        );
      }

      await request
        .then((res) => {
          console.log(res);

          if (auth?.user?.user_type !== "prestataire") {
            getDataByID("facturebyipm", auth?.user?.id, auth?.token).then(
              (res) => {
                dispatch({
                  type: "LIST_FACTURE_BY_IPM",
                  payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                });
              }
            );
          }

          if (auth?.user?.user_type === "prestataire") {
            getDataByID(
              "facturebyprestataire",
              auth?.user?.id,
              auth?.token
            ).then((res) => {
              dispatch({
                type: "LIST_FACTURE_BY_PRESTATAIRE",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            });
          }
          dispatch({
            type: "LIST_PRESTATION_BY_FACTURE",
            payload: [],
          });
          dispatch({ type: "EDIT_FACTURE", payload: {} });
          toast.success("Facture validée avec succès.");
          setIsLoading(false);
          window.$(".genere-facture-modal").modal("hide");
          window.$(".add-facture-modal").modal("hide");
          handleReset();
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
          toast.error("Oupss! Une erreur est survenue lors de la validation.");
        });
    } else {
      await toast.error(
        "Aucune prestation n'a été sélectionnée pour facturation."
      );
      await window.$(".genere-facture-modal").modal("hide");
      await window.$(".add-facture-modal").modal("hide");
      await handleReset();

      await setIsLoading(false);
      props.register("date_debut");
      props.setValue("date_debut", moment(new Date()).format("YYYY-MM-DD"));

      props.register("date_fin");
      props.setValue("date_fin", moment(new Date()).format("YYYY-MM-DD"));
    }
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div
        className="modal-content easypm-modal-content"
        style={{ width: "60rem" }}
      >
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Ajouter une facture
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={handleReset}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {!prestations_facture?.length ? (
              <form className="crud-form" onSubmit={(e) => props.onSubmit(e)}>
                {userType === "prestataire" ? (
                  <div className="row crud-row">
                    <div className="col-md-12 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <label htmlFor="first_name">Ipm</label>
                        <div className="filter-item-container">
                          <select
                            name="ipm"
                            onChange={(e) => onSelect(e)}
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                          >
                            <option hidden value="">
                              Sélectionner une ipm
                            </option>
                            {props.ipms.length &&
                              props.ipms.map((ipm) => (
                                <option key={ipm.id} value={ipm.id}>
                                  {ipm.raison_sociale}
                                </option>
                              ))}
                          </select>
                          {props.formState.errors &&
                            props.formState.errors.ipm && (
                              <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                {props.formState.errors.ipm?.message}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required crud-interm prestataire-side">
                        <label htmlFor="date_debut">Date de début</label>
                        <DatePicker
                          className="form-control crud-form-control"
                          selected={startDate}
                          placeholderText="Sélectionner une date"
                          dateFormat="dd/MM/yyyy"
                          popperPlacement="top"
                          peekNextMonth
                          maxDate={new Date()}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          name="date_debut"
                          locale="fr"
                          onChange={(date) => handleDate(date)}
                        />
                        {props.formState.errors &&
                          props.formState.errors.date_debut && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-3">
                              {props.formState.errors.date_debut?.message}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required crud-interm prestataire-side">
                        <label htmlFor="date_fin">Date de Fin</label>
                        <DatePicker
                          className="form-control crud-form-control"
                          selected={endDate}
                          placeholderText="Sélectionner une date"
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          popperPlacement="top"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          name="date_fin"
                          locale="fr"
                          onChange={(date) => handleDateFin(date)}
                        />
                        {props.formState.errors &&
                          props.formState.errors.date_fin && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-3">
                              {props.formState.errors.date_fin?.message}
                            </div>
                          )}
                        {!isValid && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            La date de fin ne doit pas être inférieure à la date
                            de début.
                          </div>
                        )}
                      </div>
                    </div>
                    <input
                      type="hidden"
                      name="statut"
                      value="nouveau"
                      {...props.register("statut")}
                    />
                    <input
                      type="hidden"
                      name="entreprise"
                      value={null}
                      {...props.register("entreprise")}
                    />
                    <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                      <button
                        className="btn btn-default cancelleb-btn"
                        data-dismiss="modal"
                        onClick={() => handleReset()}
                      >
                        Retour
                      </button>
                      {/* <button className="btn btn-primary crud-submit-btn">
                    Enregistrer
                  </button> */}
                      {props.isSuccessfullySubmitted && (
                        <button className="btn btn-primary crud-submit-btn">
                          En cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                      {!props.isSuccessfullySubmitted && (
                        <button className="btn btn-primary crud-submit-btn">
                          Enregistrer
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="row crud-row">
                    <div className="col-md-12 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <label htmlFor="first_name">Adhérent</label>
                        <div className="filter-item-container">
                          <select
                            name="entreprise"
                            onChange={(e) => onSelect(e)}
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                          >
                            <option hidden value="">
                              Sélectionner un adhérent
                            </option>
                            {props.entreprises?.length &&
                              props.entreprises.map((ent) => (
                                <option key={ent?.id} value={ent?.id}>
                                  {ent.raison_sociale}
                                </option>
                              ))}
                          </select>

                          {props.formState.errors &&
                            props.formState.errors.entreprise && (
                              <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                {props.formState.errors.entreprise?.message}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required crud-interm prestataire-side">
                        <label htmlFor="date_debut">Date de début</label>
                        <DatePicker
                          className="form-control crud-form-control"
                          selected={startDate}
                          placeholderText="Sélectionner une date"
                          dateFormat="dd/MM/yyyy"
                          popperPlacement="top"
                          peekNextMonth
                          maxDate={new Date()}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          name="date_debut"
                          locale="fr"
                          onChange={(date) => handleDate(date)}
                        />
                        {props.formState.errors &&
                          props.formState.errors.date_debut && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-3">
                              {props.formState.errors.date_debut?.message}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required crud-interm prestataire-side">
                        <label htmlFor="date_fin">Date de Fin</label>
                        <DatePicker
                          className="form-control crud-form-control"
                          selected={endDate}
                          placeholderText="Sélectionner une date"
                          dateFormat="dd/MM/yyyy"
                          popperPlacement="top"
                          maxDate={new Date()}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          name="date_fin"
                          locale="fr"
                          onChange={(date) => handleDateFin(date)}
                        />
                        {props.formState.errors &&
                          props.formState.errors.date_fin && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-3">
                              {props.formState.errors.date_fin?.message}
                            </div>
                          )}
                        {!isValid && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            La date de fin ne doit pas être inférieure à la date
                            de début.
                          </div>
                        )}
                      </div>
                    </div>

                    <input
                      type="hidden"
                      name="statut"
                      value="envoyé"
                      {...props.register("statut")}
                    />
                    <input
                      type="hidden"
                      name="ipm"
                      value={props?.ipm?.id}
                      {...props.register("ipm")}
                    />
                    <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                      <button
                        className="btn btn-default cancelleb-btn"
                        data-dismiss="modal"
                        onClick={() => handleReset()}
                      >
                        Retour
                      </button>
                      {/* <button className="btn btn-primary crud-submit-btn">
                    Enregistrer
                  </button> */}
                      {props.isSuccessfullySubmitted && (
                        <button className="btn btn-primary crud-submit-btn">
                          En cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                      {!props.isSuccessfullySubmitted && (
                        <button className="btn btn-primary crud-submit-btn">
                          Enregistrer
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </form>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="form-filter m-t-50">
                    <div className="table-filter-row beneficiaire-filter w-100">
                      <div className="filter-label-container">
                        <span className="filter-label"> Date de début </span>
                      </div>
                      <div className="filter-item-container">
                        <DatePicker
                          className="form-control filter-input-item"
                          selected={startDate}
                          dateFormat="dd/MM/yyyy"
                          maxDate={endDate}
                          // disabled={true}
                          dropdownMode="select"
                          name="date_debut"
                          locale="fr"
                          onChange={handleDateChange}
                        />
                      </div>
                      <div className="filter-label-container">
                        <span className="filter-label"> Date de fin </span>
                      </div>
                      <div className="filter-item-container">
                        <DatePicker
                          className="form-control filter-input-item"
                          selected={endDate}
                          dateFormat="dd/MM/yyyy"
                          minDate={startDate}
                          // disabled={true}
                          dropdownMode="select"
                          name="date_debut"
                          locale="fr"
                          onChange={handleDateFinChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <table className="table table-striped">
                    <thead>
                      <tr className="col-fc-tr">
                        <th scope="col">Soins</th>
                        <th scope="col">Date</th>
                        <th scope="col">Adhérent </th>
                        <th scope="col">Patient</th>

                        {/* <th scope="col" className="col-fc-th">
                          Nature prestation
                        </th> */}
                        <th scope="col">Part Ipm</th>
                        <th scope="col">Part Adhérent</th>
                        <th scope="col">Coût</th>
                        {/* <th scope="col" >
                          Prestataire
                        </th> */}
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prestationsList?.map((prestation) => (
                        <tr key={prestation?.id}>
                          <td className="fs-12">{prestation?.designation}</td>
                          <td className="fs-12">
                            {moment(prestation?.date).format("DD/MM/YYYY")}
                          </td>
                          <td className="fs-12">
                            {prestation?.beneficiaire?.prenom +
                              " " +
                              prestation?.beneficiaire?.nom}
                          </td>
                          <td className="fs-12">
                            {prestation?.ayant_droit
                              ? prestation?.ayant_droit?.prenom +
                                " " +
                                prestation?.ayant_droit?.nom
                              : prestation?.beneficiaire?.prenom +
                                " " +
                                prestation?.beneficiaire?.nom}
                          </td>

                          {/* <td className="fs-12">
                            {prestation?.type_prestation?.libelle}
                          </td> */}
                          <td className="fs-12 text-right">
                            {formatMontant(prestation?.montant_rembourse)}
                          </td>
                          <td className="fs-12 text-right">
                            {formatMontant(prestation?.montant_retenu)}
                          </td>
                          <td className="fs-12 text-right">
                            {formatMontant(prestation?.montant)}
                          </td>
                          {/* <td className="fs-12">
                            {prestation?.prestataire?.raison_sociale ||
                              prestation?.prestataire?.prenom +
                                " " +
                                prestation?.prestataire?.nom}
                          </td> */}
                          <td className="fs-12">
                            <button className="action-btn delete-btn">
                              {/* <IconlyPack.Delete
                                set="light"
                                primaryColor="#C8C8C8"
                                className="action-btn-icon action-delete-icon"
                                onClick={() => onRemoveItem(prestation)}
                              /> */}
                              <input
                                type="checkbox"
                                defaultChecked={true}
                                className="action-btn-icon action-delete-icon"
                                onChange={(e) => onRemoveItem(e, prestation)}
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                      {/* <tr>
                        <td className="totalText fs-12" colspan="3">
                          <strong>Montant Total des Actes:</strong>
                        </td>
                        <td className="fs-12">15 000 F CFA</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {/* <button className="btn btn-primary crud-submit-btn">
                    Générer la facture
                  </button> */}
                  {isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isLoading && (
                    <button
                      className="btn btn-primary crud-submit-btn"
                      onClick={(e) => handleEditFacture(e)}
                    >
                      Valider la facture
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRUDFactureModal;
