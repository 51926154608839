import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import GestionSoinAssureurModal from "../../../Backend/Assurance/GestionSoinsAssureur/GestionSoinAssureur";
import GestionDevisBeneficiaireTableSkeleton from "./GestionDevisBeneficiaireTableSkeleton";

import GestionFicheDeSoin from "./GestionFicheDeSoinRemboursementBeneficiaire";
import {
  formatMoney,
  getName,
  Status,
  getDate,
  ETAT,
} from "../../../../utils/utils";
import http, { ApiUrl } from "../../../../Http/http/http";
import { toast } from "react-toastify";

var GestionDevisBeneficiaireTable = ({ data: soins, loading, reload }) => {
  const [deleting, setDeleting] = React.useState(null);
  React.useEffect(() => {
    if (deleting) {
      http
        .delete(`/devis/${deleting}`)
        .then(() => {
          reload();
          toast.success("Données supprimées avec succès");
          setDeleting(null);
        })
        .catch((ex) => {
          setDeleting(null);
        });
    }
  }, [deleting]);
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
    expandBy: "column",
  };

  const RenderView = ({ label, name }) => {
    const labelCmp =
      typeof name === "string" ? (
        <span className="crud-form-control">{name}</span>
      ) : (
        name
      );
    return (
      <div className="col-md-6 crud-col-item flex-wrap">
        <span
          className="form-group crud-form-group"
          style={{ whiteSpace: "initial" }}
        >
          <strong>{label} :</strong>
          {labelCmp}
        </span>
      </div>
    );
  };
  const renderRow = React.useCallback((label, name) => {
    return <RenderView label={label} name={name} />;
  }, []);
  const expandComponent = React.useCallback((item) => {
    return (
      <div className="row" style={{ paddingBottom: "10px" }}>
        <RenderView
          label={"Prestataire"}
          name={`${getName(item?.prestataire)}`}
        />

        <RenderView label={"Date"} name={getDate(item.created_at)} />

        <RenderView label={"Description"} name={item.description} />

        {!!item.motif && <RenderView label={"Motif"} name={item.motif} />}

        {!!item.document &&
          renderRow(
            "Document",
            <span className="crud-form-control">
              <a
                href={ApiUrl + item.document.doc}
                target="_blank"
                title="Télécharger"
              >
                Télécharger
              </a>
            </span>
          )}
        {!!item.decompte_file &&
          renderRow(
            "Fichier de décompte",
            <span className="crud-form-control">
              <a
                href={ApiUrl + item.decompte_file}
                target="_blank"
                title="Télécharger"
              >
                Télécharger
              </a>
            </span>
          )}
      </div>
    );
  }, []);
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-Beneficiaire">
        <button className="action-btn edit-btn" title="Supprimer">
          {deleting !== row.id && (
            <IconlyPack.Delete
              set="bold"
              primaryColor="#f00"
              onClick={(e) => {
                e.preventDefault();
                const ok = window.confirm(
                  "êtes-vous sûr de supprimer ce devis"
                );
                if (ok) {
                  setDeleting(row.id);
                }
              }}
              className="action-btn-icon action-edit-icon"
            />
          )}
          {deleting === row.id && <i className="fas fa-spin fa-spinner"></i>}
        </button>
      </div>
    );
  };
  const opt = {};
  const opt1 = {};
  for (let i of soins) {
    if (i.prestataire) {
      const name = getName(i.prestataire);
      opt[name] = name;
    }
    if (i.etat_beneficiaire) {
      opt1[i.etat_beneficiaire] = ETAT.find(
        (i2) => i2.value === i.etat_beneficiaire
      )?.label;
    }
  }
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {loading && <GestionDevisBeneficiaireTableSkeleton />}
            {!loading && (
              <div className="table-container">
                <BootstrapTable
                  data={soins}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                  striped={true}
                  hover={true}
                  expandableRow={() => true}
                  expandComponent={expandComponent}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="prestataire"
                    filterValue={(_, i) => getName(i.prestataire)}
                    filter={{ type: "SelectFilter", options: opt }}
                    dataFormat={(_, item) => getName(item.prestataire)}
                  >
                    Prestataire
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="date"
                    dataFormat={(_, item) => getDate(item.created_at)}
                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="etat_beneficiaire"
                    filter={{ type: "SelectFilter", options: opt1 }}
                    dataFormat={(_, item) => (
                      <Status etat={item.etat_beneficiaire} />
                    )}
                  >
                    Statut
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="action"
                    dataFormat={actionFormatter}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionDevisBeneficiaireTable;
