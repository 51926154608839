import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import LineChart from "../../LineChart/LineChart";
import Header from "../../../Navs/Header";
import { DataContext } from "../../../store/GlobalState";
import { getDataByID } from "../../../utils/fetchData";
import LineChartDeux from "../../LineChartDeux/LineChartDeux";
import LineChartTrois from "../../LineChartTrois/LineChartTrois";
import LineChartQuatre from "../../LineChartQuatre/LineChartQuatre";
import TopPrestataires from "../../LineChartQuatre/TopPrestataires";
import TopEntreprise from "../../LineChartQuatre/TopEntreprise";
import { formatMontant } from "../../../../utils/utils";
import CumulChart from "../../LineChartsPrestataire/CumulChart";
import TopIpmsTable from "../../LineChartsPrestataire/TopIpmsTable";

var Dashboard = () => {
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);
  const { stats, auth, ipms } = state;
  const [ipm, setIpm] = React.useState({});
  const [byIpm, setbyIpm] = React.useState({});
  const [volume, setVolume] = React.useState({});
  var [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    if (ipms?.length) {
      setIpm(ipms[0]);
    }
  }, [ipms]);

  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (
        auth.user?.user_type === "ipm" ||
        auth?.user?.user_type === "assurance_sante" ||
        auth?.user?.user_type === "admin_ipm"
      ) {
        if (!auth?.user?.admin_type) {
          getDataByID("statvolumetriquebyipm", auth?.user?.id, auth.token).then(
            (res) => {
              setVolume(res);
              setIsPageLoading(false);
            }
          );

          getDataByID("statbyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({ type: "STATS", payload: res.data });
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        } else {
          getDataByID(
            "statvolumetriquebyipm",
            auth?.user?.parent,
            auth.token
          ).then((res) => {
            setVolume(res);
            setIsPageLoading(false);
          });
          getDataByID("statbyassurance", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({ type: "STATS", payload: res.data });
              // setIsPageLoading(false);
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyipm",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }

      if (auth.user?.user_type === "entreprise") {
        if (!auth?.user?.admin_type) {
          getDataByID("statbyentreprise", auth?.user?.id, auth.token)
            .then((res) => {
              // console.log('stat', res)
              dispatch({ type: "STATS", payload: res.data });
              setTimeout(() => {
                // setIsPageLoading(false);
              }, 2500);
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyentreprise",
            auth?.user?.id,
            auth?.token
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        } else {
          getDataByID("statbyentreprise", auth?.user?.parent, auth.token)
            .then((res) => {
              // console.log('stat', res)
              dispatch({ type: "STATS", payload: res.data });
              setTimeout(() => {
                // setIsPageLoading(false);
              }, 2500);
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyentreprise",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }

      if (auth.user?.user_type === "prestataire") {
        getDataByID("statbyprestataire", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({ type: "STATS", payload: res.data });
            setTimeout(() => {
              // setIsPageLoading(false);
            }, 2500);
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID(
          "notificationbyprestataire",
          auth?.user?.id,
          auth?.token
        ).then((res) => {
          // console.log("notif", res);
          dispatch({
            type: "LIST_NOTIFICATION",
            payload: res.sort((a, b) => a?.id - b?.id).reverse(),
          });
        });
      }
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth.user?.user_type === "entreprise" && ipm?.id) {
      if (!auth?.user?.admin_type) {
        getDataByID(
          `statbyentreprisebyipmbycurrentmonth/${auth?.user?.id}`,
          ipm?.id,
          auth?.token
        ).then((response) => {
          // console.log("new stat", response);
          setbyIpm(response?.data);
          setIsPageLoading(false);
        });
      } else {
        getDataByID(
          `statbyentreprisebyipmbycurrentmonth/${auth?.user?.parent}`,
          ipm?.id,
          auth?.token
        ).then((response) => {
          // console.log("new stat", response);
          setbyIpm(response?.data);
          setIsPageLoading(false);
        });
      }
    }
    if (auth?.token && auth.user?.user_type === "prestataire" && ipm?.id) {
      getDataByID(
        `statbyprestatairebyipmbycurrentmonth/${auth?.user?.id}`,
        ipm?.id,
        auth?.token
      ).then((response) => {
        console.log("new stat", response);
        setbyIpm(response?.data);
        setIsPageLoading(false);
      });
    }
  }, [auth, ipm]);

  const handleIpmChange = (e) => {
    if (e.target.value) {
      setIpm(ipms?.filter((i) => i?.id === parseInt(e.target.value))[0]);
    }
  };

  const CardSkeleton = () => {
    const renderModules = Array(3)
      .fill()
      .map((item, index) => (
        <div
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2"
          key={index}
        >
          <div className="card">
            <Skeleton
              variant="rect"
              style={{ borderRadius: "10px" }}
              width={332}
              height={100}
            />
          </div>
        </div>
      ));
    return <React.Fragment>{renderModules}</React.Fragment>;
  };

  return (
    <div className="container-fluid body-background-dashboard">
      <Header />

      <div className="row">
        {/* Navbar Desktop  */}
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>

        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-6 mb-2">
                <h6 className="title-view-dashboard">Tableau de Bord</h6>
              </div>
              <div
                className="col-xl-4 offset-xl-2 col-md-5 offset-md-1 col-sm-6 mb-2 dis-flex"
                style={{ justifyContent: "flex-end" }}
              >
                {!isPageLoading &&
                  (auth?.user?.user_type !== "ipm" &&
                  auth?.user?.user_type !== "assurance_sante" &&
                  auth?.user?.user_type !== "admin_ipm" ? (
                    <select
                      className="form-select form-select-line-chart ml-md-auto"
                      value={ipm?.id}
                      onChange={handleIpmChange}
                    >
                      <option hidden value="">
                        Sélectionner une ipm
                      </option>
                      {ipms?.length &&
                        ipms.map((item) => (
                          <option key={item?.id} value={item?.id}>
                            {" "}
                            {item?.raison_sociale}{" "}
                          </option>
                        ))}
                    </select>
                  ) : null)}
              </div>
            </div>
            <div className="row mb-3 px-2">
              {isPageLoading && <CardSkeleton />}
              {!isPageLoading &&
                (auth?.user?.user_type === "ipm" ||
                  auth?.user?.user_type === "assurance_sante" ||
                  auth?.user?.user_type === "admin_ipm") && (
                  <>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                      <div className="card-statistics card-statistics-color-1">
                        <div className="stat-conteny">
                          <p className="parent-label-card-statistics">
                            Total adhérants
                          </p>
                          <br />
                          <p></p>
                        </div>
                        <div className="stat-counter">
                          <div className="label-stat-counter">
                            {volume?.nb_entreprise}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                      <div className="card-statistics card-statistics-color-2">
                        <div className="stat-conteny">
                          <p className="parent-label-card-statistics">
                            Total participants
                          </p>
                          <br />
                          <p></p>
                        </div>
                        <div className="stat-counter">
                          <div className="label-stat-counter">
                            {volume?.nb_benef}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                      <div className="card-statistics card-statistics-color-3">
                        <div className="stat-conteny">
                          <p className="parent-label-card-statistics">
                            Total prestataires agréés
                          </p>
                          <br />
                          <p></p>
                        </div>
                        <div className="stat-counter">
                          <div className="label-stat-counter">
                            {volume?.nb_prestataire_agree}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                      <div className="card-statistics card-statistics-color-3">
                        <div className="stat-conteny">
                          <p className="parent-label-card-statistics">
                            Total prestataires non agréés
                          </p>
                          <br />
                          <p></p>
                        </div>
                        <div className="stat-counter">
                          <div className="label-stat-counter">
                            {volume?.nb_prestataire_not_agree}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                      <div className="card-statistics card-statistics-color-1">
                        <div className="stat-conteny">
                          <p className="parent-label-card-statistics">
                            Total ayants droit
                          </p>
                          <br />
                          <p></p>
                        </div>
                        <div className="stat-counter">
                          <div className="label-stat-counter">
                            {volume?.nb_ayantdroit}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {!isPageLoading && auth?.user?.user_type === "entreprise" && (
                <>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre de participants
                        </p>
                        <p className="child-label-card-statistics">
                          {/* Pour l'ipm {ipm?.raison_sociale} */}
                          Ce mois
                        </p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {byIpm?.total_beneficiaire}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre d'ayants droit
                        </p>
                        <p className="child-label-card-statistics">
                          {/* Pour l'ipm {ipm?.raison_sociale} */}
                          Ce mois
                        </p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {byIpm?.total_ayantdroit}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre de prestations
                        </p>
                        <p className="child-label-card-statistics">
                          {/* Pour l'ipm {ipm?.raison_sociale} */}
                          Ce mois
                        </p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {byIpm?.total_prestation}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Montant retenus (en F CFA)
                        </p>
                        <p className="child-label-card-statistics">
                          {/* Pour l'ipm {ipm?.raison_sociale} */}
                          Ce mois
                        </p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {new Intl.NumberFormat("sn", {
                            currency: "XOF",
                            // style: "currency",
                          }).format(parseInt(byIpm?.total_retenu))}
                          {/* {stats?.total_retenu} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {!isPageLoading && auth?.user?.user_type === "prestataire" && (
                <>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre d'ipms
                        </p>
                        <p className="child-label-card-statistics">Ce mois</p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {stats?.total_ipm}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Montants de prestations par IPM (en F CFA)
                        </p>
                        <p className="child-label-card-statistics">
                          {/* Pour l'ipm {ipm?.raison_sociale} */}
                          Ce mois
                        </p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {/* {stats?.total_montant_prestation_by_ipm} */}
                          {new Intl.NumberFormat("sn", {
                            currency: "XOF",
                            // style: "currency",
                          }).format(
                            parseInt(byIpm?.total_montant_prestation_by_ipm)
                          )}
                          {/* {formatMontant(
                            byIpm?.total_montant_prestation_by_ipm
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre de factures émises
                        </p>
                        <p className="child-label-card-statistics">
                          {/* Pour l'ipm {ipm?.raison_sociale} */}
                          Ce mois
                        </p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {byIpm?.total_facture_emise}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre de participants reçus par IPM
                        </p>
                        <p className="child-label-card-statistics">
                          {/* Pour l'ipm {ipm?.raison_sociale} */}
                          Ce mois
                        </p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {byIpm?.total_benef_recu_by_ipm}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* {!isPageLoading &&
                (window.sessionStorage.getItem("userType") === "super_imp" ||
                sessionStorage.getItem("isSuperAdmin")) && (
                  <>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                      <div className="card-statistics card-statistics-color-1">
                        <div className="stat-conteny">
                          <p className="parent-label-card-statistics">
                            Total entreprises
                          </p>
                          <p className="child-label-card-statistics">
                            Cette semaine
                          </p>
                        </div>
                        <div className="stat-counter">
                          <div className="label-stat-counter">
                            {stats?.total_entreprise}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                      <div className="card-statistics card-statistics-color-2">
                        <div className="stat-conteny">
                          <p className="parent-label-card-statistics">
                            Total Adhérents
                          </p>
                          <p className="child-label-card-statistics">
                            Cette semaine
                          </p>
                        </div>
                        <div className="stat-counter">
                          <div className="label-stat-counter">
                            {stats?.total_beneficiaire}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                      <div className="card-statistics card-statistics-color-3">
                        <div className="stat-conteny">
                          <p className="parent-label-card-statistics">
                            Total prestataires
                          </p>
                          <p className="child-label-card-statistics">
                            Cette semaine
                          </p>
                        </div>
                        <div className="stat-counter">
                          <div className="label-stat-counter">
                            {stats?.total_prestataire}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )} */}
            </div>
            <div className="row">
              <div
                className={
                  auth?.user?.user_type === "ipm" ||
                  auth?.user?.user_type === "assurance_sante" ||
                  auth?.user?.user_type === "admin_ipm" ||
                  auth?.user?.user_type === "prestataire"
                    ? "col-xl-6 col-lg-6 col-md-6 m-b-5 pb-3"
                    : "col-xl-12 m-b-5"
                }
              >
                {/* <div className="col-xl-12 m-b-5"> */}
                <div className="card-dashboard m-b-15">
                  {isPageLoading === false ? (
                    <LineChart stat={stats?.stat} />
                  ) : (
                    <Skeleton
                      width={`100%`}
                      height={
                        auth?.user?.user_type === "ipm" ||
                        auth?.user?.user_type === "assurance_sante" ||
                        auth?.user?.user_type === "admin_ipm" ||
                        auth?.user?.user_type === "prestataire"
                          ? 300
                          : 500
                      }
                    />
                  )}
                </div>
              </div>
              {auth?.user?.user_type === "ipm" ||
              auth?.user?.user_type === "assurance_sante" ||
              auth?.user?.user_type === "admin_ipm" ? (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-6 m-b-5 pb-3">
                    <div className="card-dashboard m-b-15">
                      {isPageLoading === false ? (
                        <LineChartTrois stat={stats?.stat} auth={auth} />
                      ) : (
                        <Skeleton width={`100%`} height={300} />
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 m-b-5 pb-3">
                    <div className="card-dashboard m-b-15">
                      {isPageLoading === false ? (
                        <LineChartDeux stat={stats?.stat} auth={auth} />
                      ) : (
                        <Skeleton width={`100%`} height={300} />
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 m-b-5 pb-3">
                    <div className="card-dashboard m-b-15">
                      {isPageLoading === false ? (
                        <LineChartQuatre auth={auth} />
                      ) : (
                        <Skeleton width={`100%`} height={300} />
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 m-b-5 pb-3">
                    <div className="card-dashboard m-b-15">
                      {isPageLoading === false ? (
                        <TopPrestataires auth={auth} />
                      ) : (
                        <Skeleton width={`100%`} height={300} />
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 m-b-5 pb-3">
                    <div className="card-dashboard m-b-15">
                      {isPageLoading === false ? (
                        <TopEntreprise auth={auth} />
                      ) : (
                        <Skeleton width={`100%`} height={300} />
                      )}
                    </div>
                  </div>
                </>
              ) : null}

              {auth?.user?.user_type === "prestataire" ? (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-6 m-b-5 pb-3">
                    <div className="card-dashboard m-b-15">
                      {isPageLoading === false ? (
                        <CumulChart auth={auth} />
                      ) : (
                        <Skeleton width={`100%`} height={300} />
                      )}
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 m-b-5 pb-3">
                    <div className="card-dashboard m-b-15">
                      {isPageLoading === false ? (
                        <TopIpmsTable auth={auth} />
                      ) : (
                        <Skeleton width={`100%`} height={300} />
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
