import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RenvoiPasswordPrestataireAdminModal = (props) => {

 
  return (
    <div className="modal-dialog easypm-modal-dialog modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="RenvoiPasswordPrestataireAdminModal">
            Renvoi de mot de passe
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row crud-row">
                  <>
                    <div className="col-md-12 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <p className="text-revoi-password">Veuillez confirmer le renvoi de mot de passe à
                            ABDOU FALL</p>
                      </div>
                    </div>
                  </>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn-assurance"
                      data-dismiss="modal"
                    >
                      Retour
                    </button>
                    <button className="btn  crud-submit-btn-assurance">
                      Valider
                    </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenvoiPasswordPrestataireAdminModal;
