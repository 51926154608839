import React from 'react'
import { NavLink } from 'react-router-dom'
import GooglePlay from '../../../../images/landingpage/google-play-rectangle.svg'
import AppStore from '../../../../images/landingpage/app-store-rectangle.svg'
import FacebookIcon from '../../../../images/landingpage/Facebook.png'
import TwitterIcon from '../../../../images/landingpage/Twitter.png'
import LinkedinIcon from '../../../../images/landingpage/LinkedIN.png'
import InstagramIcon from '../../../../images/landingpage/Instagram.png'
import './FooterAssurance.css'
var FooterAssurance = () => {
    return(
        <footer className="ldp-footer">
            <div className="container">
                <div className="ldp-footer-body">
                    <div className="row ldp-footer-row">
                        <div className="col-md-4 ldp-footer-col footer-firsty">
                            <div className="ldp-footer-content-item">
                                <h1 className="ldp-footer-item-title">EASYPM</h1>
                                <div className="ldp-footer-item textual">
                                    <p>
                                    EasyPM-Assurances est une plateforme numérique, basée sur la technologie cloud, qui permet aux assurances santé, aux différents prestataires de santé et aux salariés un accès rapide et transparent à l’ensemble des données relatives au parcours de soin.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 ldp-footer-col footer-secondy">
                            <h1 className="ldp-footer-item-title">EN PLUS</h1>
                            <div className="ldp-footer-item links">
                                <ul className="ldp-footer-link-list">
                                    <li>
                                        <NavLink to="#" className="footer-link-item">Conditions générales d’utilisation</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#" className="footer-link-item">Politique de Protection des donneées personnelles</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 ldp-footer-col footer-thirdy">
                            <div className="app-mobile-container">
                                <h1 className="ldp-footer-item-title-assurance">TEÉLÉCHARGER NOS APPS</h1>
                                <div className="app-mobile-row">
                                    <div className="app-mobile-item-container">
                                        <img src={GooglePlay} alt="Google Play App" className="app-mobile-store" />
                                    </div>
                                    <div className="app-mobile-item-container">
                                        <img src={AppStore} alt="App Store App" className="app-mobile-store" />
                                    </div>
                                </div>
                            </div>
                            <div className="social-container">
                                <h1 className="ldp-footer-item-title-assurance">NOUS SUIVRE</h1>
                                <div className="social-row">
                                    <div className="social-item-container">
                                        <img src={FacebookIcon} alt="Facebook" className="social-icon" />
                                    </div>
                                    <div className="social-item-container">
                                        <img src={LinkedinIcon} alt="Linkedin" className="app-mobile-store" />
                                    </div>
                                    <div className="social-item-container">
                                        <img src={InstagramIcon} alt="Instagram" className="app-mobile-store" />
                                    </div>
                                    <div className="social-item-container">
                                        <img src={TwitterIcon} alt="Twitter" className="app-mobile-store" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterAssurance;