import React from "react";
import * as IconlyPack from "react-iconly";
import PpAvatar from "../../../../images/others/pp.png";
import AddRdv from "../../../../images/icons/add.png";
import RemoveRdv from "../../../../images/icons/remove.png";
import { NavLink } from "react-router-dom";
import AjouterRdv from "./AjouterRdv";
import DeplacerRdv from "./DeplacerRdv";
import moment from "moment";
import { ApiUrl } from "../../../../Http/http/http";
import { getName } from "../../../../utils/utils";

var CardItemRdv = ({ item, reload }) => {
  const passed = moment(item.date).set("hours", item.heure) < moment();
  return (
    <div>
      <div className="card-item-rdv">
        <div className="bloc-entete-card-item-rdv">
          <div className="">
            <IconlyPack.Calendar set="bold" primaryColor="#fff" />
            <span className="text-card-item-rdv pl-2">
              {moment(item.date).format("dddd DD MMMM")}
            </span>
          </div>
          <div>
            <IconlyPack.TimeCircle set="bold" primaryColor="#fff" />
            <span className="text-card-item-rdv pl-2">{`${item.heure}:00`}</span>
          </div>
        </div>
        <div className="p-3">
          <div className="d-flex align-items-center py-3">
            <div>
              <img
                src={ApiUrl + item.prestataire?.avatar}
                alt="Image"
                className="image-pp-card-item-rdv"
              />
            </div>
            <div className="pl-3">
              <NavLink
                to={{
                  pathname: `/details-praticiens/${item.prestataire.id}/`,
                  state: item.prestataire,
                }}
                className="titre-auteur-rdv no-link"
              >
                {getName(item.prestataire)}
              </NavLink>
              <p className="text-auteur-rdv">
                {item.prestataire?.specialite?.nom || ""}
              </p>
            </div>
          </div>
          <div>
            <IconlyPack.Location
              set="bold"
              primaryColor="rgba(204, 204, 204, 0.5)"
            />
            <span className="text-localisation-rdv pl-2">{item.adresse}</span>
          </div>
          <div className="d-flex justify-content-end py-3 align-items-center">
            <RdvStatus etat={item.etat} />
            <div className="">
              <button
                className="btn "
                type="button"
                data-toggle="modal"
                data-target={`#AjouterRdv${item.id}`}
              >
                <img src={AddRdv} alt="Image ajouter rdv" />{" "}
              </button>{" "}
            </div>
            {!passed && (
              <div>
                <button
                  className="btn"
                  type="button"
                  data-toggle="modal"
                  data-target={`#DeplacerRdv${item.id}`}
                >
                  <img src={RemoveRdv} alt="Image ajouter rdv" />
                </button>{" "}
              </div>
            )}
          </div>
        </div>
        <div
          class="modal fade"
          id={`AjouterRdv${item.id}`}
          tabindex="-1"
          aria-labelledby="AjouterRdvLabel"
          aria-hidden="true"
        >
          <AjouterRdv
            onDone={() => {
              window.$(`#AjouterRdv${item.id}`).modal("hide");
              reload();
            }}
            prestataire={item.prestataire}
          />
        </div>
        <div
          class="modal fade"
          id={`DeplacerRdv${item.id}`}
          tabindex="-1"
          aria-labelledby="DeplacerRdvLabel"
          aria-hidden="true"
        >
          <AjouterRdv
            prestataire={item.prestataire}
            rv={item}
            onDone={() => {
              window.$(`#DeplacerRdv${item.id}`).modal("hide");
              reload();
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default CardItemRdv;

export function RdvStatus({ etat }) {
  const i = {
    new: { text: "Nouveau", bg: "#f1c40f" },
    accepte: { text: "Accepté", bg: "#16a085" },
    rejete: { text: "Accepté", bg: "#c0392b" },
  }[etat];

  return (
    <div style={{ padding: ".375rem" }}>
      <div style={{ backgroundColor: i.bg, padding: 5, borderRadius: 5 }}>
        <span style={{ fontWeight: "bold", color: "#FFF" }}>{i.text}</span>
      </div>
    </div>
  );
}
