import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import ListeDesParticipantsTableSkeleton from "./ListeDesParticipantsTableSkeleton";
import * as IconlyPack from "react-iconly";
import { Link, NavLink } from "react-router-dom";
import ListeDesParticipantsTableData from "./ListeDesParticipantsTableData";
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'
import Profil from "../../../../images/others/photo.png";
import { ApiUrl } from "../../../../Http/http/http";
import { getName } from "../../../../utils/utils";

var ListeDesParticipantsTable = ({ loading, data }) => {
  var handleClearButtonClick = (onClick) => {
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-Beneficiaire">
        <Link
          to={{ pathname: `/details-praticiens/${row.id}/`, state: row }}
          className="action-btn edit-btn"
          title="Détail"
        >
          <IconlyPack.Show
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#DemandeFicheSoinsModal"
          />
        </Link>
      </div>
    );
  };

  const photoFormatter = (cell, row) => {
    return (
      <div>
        <img
          style={{ width: "70px", height: "62px", borderRadius: "5px" }}
          src={ApiUrl + row.avatar}
          alt="Image"
        />
        <span
          className="pl-3"
          style={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
        >
          {getName(row)}
        </span>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {loading && <ListeDesParticipantsTableSkeleton />}
            {!loading && (
              <div className="table-container">
                <BootstrapTable
                  data={data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="praticien"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                    filterValue={(_, row) => getName(row)}
                    dataFormat={(cell, row) => photoFormatter(cell, row)}
                  >
                    Praticiens
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                    filterValue={(_, row) => row?.specialite?.nom}
                    dataFormat={(cell, row) => row?.specialite?.nom}
                    dataField="specialite"
                  >
                    Spécialités
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="adresse"
                  >
                    Lieu
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeDesParticipantsTable;
