import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import { Toggle } from "rsuite";
import { DataContext } from "../../../store/GlobalState";
import UseEditAssureurForm from "./UseEditAssureurForm";
import ValideGenerationFiche from "./ValideGenerationFiche";

const ParametreInformation = () => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setValue,
  } = UseEditAssureurForm();
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;

  const [phone, setPhone] = React.useState("");

  useEffect(() => {
    if (auth?.user?.id) {
      register("raison_sociale");
      setValue("raison_sociale", auth.user.raison_sociale);
      register("email");
      setValue("email", auth.user.email);
      register("telephone");
      setValue("telephone", auth.user.telephone);
      setPhone(auth?.user?.telephone);
      register("adresse");
      setValue("adresse", auth.user.adresse);
      register("ninea");
      setValue("ninea", auth.user.ninea);
    }
  }, [auth]);

  const handleSetPhone = (val) => {
    setPhone(val);
    register("telephone");
    setValue("telephone", "+" + val);
  };
  const handleChange = (e) => {
    console.log("val", e);
    setValue("auto_generate_fiche", e);

    onSubmit("valid");

    if (e === true) {
      window.$(".accept-generation-modal").modal("show");
    }
  };
  return (
    <div className="add-family-form-container pt-5">
      <form className="crud-form" onSubmit={onSubmit}>
        <div className="row crud-row">
          <>
            <div className="col-md-6 crud-col-item">
              <div className="form-group crud-form-group">
                <label htmlFor="nom">Raison sociale</label>
                <input
                  type="text"
                  placeholder="Raison sociale"
                  name="raison_sociale"
                  {...register("raison_sociale")}
                  className="form-control crud-form-control"
                  id="nom"
                />
                {formState?.errors && formState?.errors?.raison_sociale && (
                  <div className="alert alert-danger epm-alert-danger closer m-t-10">
                    {formState?.errors?.raison_sociale?.message}
                  </div>
                )}
              </div>
            </div>
          </>
          <div className="col-md-6 crud-col-item">
            <div className="form-group crud-form-group">
              <label htmlFor="ninea">Ninea</label>
              <input
                type="text"
                placeholder="Ninea"
                name="ninea"
                {...register("ninea")}
                className="form-control crud-form-control"
                id="ninea"
              />
              {formState?.errors && formState?.errors?.ninea && (
                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                  {formState?.errors?.ninea?.message}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 crud-col-item">
            <div className="form-group crud-form-group">
              <label htmlFor="email">Adresse email</label>
              <input
                type="mail"
                placeholder="Adresse email"
                name="email"
                {...register("email")}
                className="form-control crud-form-control"
                id="email"
              />
              {formState?.errors && formState?.errors?.email && (
                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                  {formState?.errors?.email?.message}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 crud-col-item">
            <div className="form-group crud-form-group">
              <label htmlFor="telephone">Téléphone</label>
              {/* <input
                type="number"
                placeholder="Téléphone"
                name="telephone"
                {...register("telephone")}
                className="form-control crud-form-control"
                id="telephone"
              /> */}
              <PhoneInput
                defaultMask={".. ... .. .."}
                inputClass="form-control crud-form-control"
                country={"sn"}
                onlyCountries={["sn"]}
                inputProps={{
                  name: "telephone",
                  required: true,
                  autoFocus: true,
                }}
                disableDropdown={true}
                enableAreaCodes={true}
                prefix="+"
                value={phone}
                countryCodeEditable={false}
                onChange={(phone) => handleSetPhone(phone)}
                inputStyle={{
                  width: "100%",
                  paddingBottom: "22px",
                  borderRadius: "10px",
                }}
              />
              {formState?.errors && formState?.errors?.telephone && (
                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                  {formState?.errors?.telephone?.message}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 crud-col-item">
            <div className="form-group crud-form-group">
              <label htmlFor="adresse">Adresse</label>
              <input
                type="text"
                placeholder="Adresse"
                name="adresse"
                {...register("adresse")}
                className="form-control crud-form-control"
                id="adresse"
              />
              {formState?.errors && formState?.errors?.adresse && (
                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                  {formState?.errors?.adresse?.message}
                </div>
              )}
            </div>
          </div>
          {auth?.user?.admin_type !== "assureur" && (
            <div className="col-md-6 crud-col-item">
              <div className="form-group crud-form-group">
                <label htmlFor="cle">
                  Génération automatique de fiche de soins
                </label>
                <div className="filter-item-container">
                  {auth?.user?.id && (
                    <Toggle
                      size="lg"
                      defaultChecked={auth?.user?.auto_generate_fiche}
                      onChange={handleChange}
                      checkedChildren="Accepter"
                      unCheckedChildren="Refuser"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="col-md-3 offset-md-9 crud-form-action-btn-container">
            {isSuccessfullySubmitted && (
              <button className="btn crud-submit-btn-assurance" disabled>
                En cours &nbsp;
                <i className="fas fa-spin fa-spinner"></i>
              </button>
            )}
            {!isSuccessfullySubmitted && (
              <button className="btn crud-submit-btn-assurance">
                Enregistrer
              </button>
            )}
          </div>
        </div>
      </form>
      {/* Modal */}
      <div
        className="modal fade easypm-modal accept-generation-modal"
        data-backdrop="static"
        id="AddModalItem"
        tabIndex="-1"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
      >
        <ValideGenerationFiche />
      </div>
    </div>
  );
};

export default ParametreInformation;
