import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import { getDataByID } from "../../../../utils/fetchData";
import { formatMontant } from "../../../../../utils/utils";
import Skeleton from "react-loading-skeleton";

function DonutGraphPriseEnCharge({ auth }) {
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const [date, setDate] = useState(new Date());
  const [labels, setLabels] = useState([]);
  const [cumul, setCumul] = useState([0, 0]);
  const [cumulTotal, setCumulTotal] = useState(0);
  useEffect(() => {
    if (auth?.user?.id && date) {
      getDataByID(
        `statbetweenbenefbydate/${auth?.user?.id}`,
        moment(date).format("DD-MM-YYYY"),
        auth?.token
      ).then((stat) => {
        // getStat(stat);
        getStat(stat?.filter((item) => item?.item?.is_active === true));
        setIsPageLoading(false);
      });
    }
  }, [auth, date]);

  const getStat = (array) => {
    console.log("data", array);
    let names = [];
    let data = [];
    array.forEach((element) => {
      names.push(element?.item?.prenom + " " + element?.item?.nom);
      data.push(element?.cout);
    });
    setLabels(names);
    setCumul(data);
    console.log("label", names, data);
  };
  let series = cumul;
  let options = {
    chart: {
      width: 480,
      type: "donut",
    },
    colors: [
      "#01223A",
      "#02A69C",
      "#005D86",
      "#FF9800",
      "#2E93fA",
      "#66DA26",
      "#546E7A",
      "#E91E63",
    ],
    title: {
      text: "Prise en charge entre les différents bénéficiaires",
      align: "left",
      offsetX: 5,
    },
    labels: labels,
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "top",
      offsetY: 0,
      height: 50,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "15px",
              offsetY: -10,
            },
            value: {
              show: true,
              formatter: function (value) {
                // console.log("value", w);
                return formatMontant(value);
              },
            },
            total: {
              label: "Total",
              show: true,
              // formatter: formatTotal(),
              formatter: function (w) {
                let total = 0;
                w.globals.seriesTotals.forEach((element) => {
                  total += parseInt(element);
                });
                return formatMontant(total);
                // return w.globals.seriesTotals.reduce((a, b) => {
                //   console.log(w.globals.seriesTotals, b);
                //   let total = parseInt(a) + parseInt(b);
                //   return formatMontant(total);
                // }, 0);
              },
            },
          },
        },
      },
    },
  };

  const handleChangeDate = (date) => {
    setDate(date);
  };

  return (
    <div className="">
      <div className="row mb-2">
        <div className="col-xl-5 col-lg-4 mb-2">
          {/* <h2 className="chart-item-title">Chiffre du jour - <span className="fw-300"> Nombre d'apprenants par jour </span> </h2> */}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6 mb-2 dis-flex">
          <div className="ml-desktop">
            <DatePicker
              dateFormat="MMMM/yyyy"
              showMonthYearPicker={true}
              className="form-select form-select-line-chart ml-md-auto"
              maxDate={new Date()}
              selected={date}
              onChange={(date) => handleChangeDate(date)}
              locale="fr"
            />
          </div>
        </div>
      </div>
      <div class="chart-wrap">
        <div id="chart">
          {isPageLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Skeleton circle={true} height={250} width={250} />
            </div>
          ) : (
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width={380}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DonutGraphPriseEnCharge;
