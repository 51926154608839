/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import "moment/locale/fr";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { getDataByID } from "../../utils/fetchData";
import Skeleton from "react-loading-skeleton";
// import { forEach } from 'lodash'
// import "react-datepicker/dist/react-datepicker.css";

registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});
const LineChartTrois = (props) => {
  const [date, setDate] = useState(new Date());
  const [count, setCount] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [countIpm, setCountIpm] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [countAdh, setCountAdh] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const [stats, setStats] = useState([]);
  const [labels, setLabels] = useState([]);
  const [label, setLabel] = useState("");
  useEffect(() => {
    setLabels(dateRange(moment(new Date()).format("YYYY-MM-DD")));
  }, [date]);

  useEffect(() => {
    if (
      props?.auth?.user?.id &&
      (props?.auth?.user?.user_type === "ipm" ||
        props?.auth?.user?.user_type === "assurance_sante" ||
        props?.auth?.user?.user_type === "admin_ipm")
    ) {
      if (!props?.auth?.user?.admin_type) {
        getDataByID(
          `dashboardbyipm`,
          props?.auth?.user?.id,
          props?.auth?.token
        ).then((stat) => {
          setStats(stat?.data);
          setIsPageLoading(false);
        });
      } else {
        getDataByID(
          `dashboardbyipm`,
          props?.auth?.user?.id,
          props?.auth?.token
        ).then((stat) => {
          setStats(stat?.data);
          setIsPageLoading(false);
        });
      }
    }
  }, [props?.auth, date]);

  useEffect(() => {
    let part_total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let part_ipm = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let part_adh = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    if (date && stats?.length) {
      stats.forEach((element) => {
        part_total[moment(element?.month).format("M") - 1] =
          element?.cout_total;
        part_ipm[moment(element?.month).format("M") - 1] = element?.part_ipm;
        part_adh[moment(element?.month).format("M") - 1] =
          element?.part_beneficiaire;
      });
    }
    setCount(part_total);
    setCountIpm(part_ipm);
    setCountAdh(part_adh);
  }, [date, stats]);

  const dateRange = (endDate) => {
    var start = moment().startOf("year").format("YYYY-MM-DD").split("-");
    var end = moment(endDate).format("YYYY-MM-DD").split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];
    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      // console.log("mois", startYear, endYear);
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var mois = moment().month(j).locale("fr").format("MMM");
        dates.push([mois].join("-"));
      }
    }
    return dates;
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Coût total",
        data: count,
        backgroundColor: "#002060",
      },
      {
        label: "Prise en charge IPMS",
        data: countIpm,
        backgroundColor: "#BF9000",
      },
      {
        label: "Retenus sur salaires",
        data: countAdh,
        backgroundColor: "rgb(75, 192, 192)",
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const handleChangeDate = (date) => {
    // setCount([]);
    // setCountAdh([])
    setLabels([]);
    setDate(date);
    var start = moment().startOf("year").format("YYYY-MM-DD").split("-");
    var end = moment(date).format("YYYY-MM-DD").split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];
    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      // console.log("mois", startYear, endYear);
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var mois = moment().month(j).locale("fr").format("MMM");
        dates.push([mois].join("-"));
      }
    }
    setLabels(dates);
  };
  return (
    <div className="chart-item-container">
      <div className="row mb-2">
        <div className="col-xl-12 col-lg-12 mb-2">
          <h2 className="chart-item-title">
            Répartition mensuelle des montants des dépenses par poste
          </h2>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6 mb-2 dis-flex">
          {/* <DatePicker
            dateFormat={
              window.sessionStorage.getItem("userType") !== "ipm" &&
              window.sessionStorage.getItem("userType") !== "assurance_sante" && window.sessionStorage.getItem("userType") !== "admin_ipm"
                ? "MMMM/yyyy"
                : "dd/MM/yyyy"
            }
            showMonthYearPicker={
              window.sessionStorage.getItem("userType") !== "ipm" &&
              window.sessionStorage.getItem("userType") !== "assurance_sante"  && window.sessionStorage.getItem("userType") !== "admin_ipm"
                ? true
                : false
            }
            className="form-select form-select-line-chart ml-md-auto"
            selected={date}
            onChange={(date) => handleChangeDate(date)}
            locale="fr"
          /> */}
        </div>
      </div>
      {isPageLoading ? (
        <div>
          <Skeleton width={`100%`} height={300} />
        </div>
      ) : (
        <Bar data={data} options={options} />
      )}
    </div>
  );
};

export default LineChartTrois;
