import React, { useContext, useEffect } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../../store/GlobalState";
import { useHistory, useParams } from "react-router";
import { getDataByID } from "../../../utils/fetchData";
import Prestation from "../../../Tables/Prestation/Prestation";
import ShowModalSoin from "../../../Modals/ShowModalSoin";

function PrestationByPrestataire() {
  const { state, dispatch } = useContext(DataContext);
  const { prestataire, auth } = state;
  const params = useParams();
  const history = useHistory();
  useEffect(() => {
    if (auth?.token) {
      getDataByID("prestataire", params?.id, auth?.token).then((res) => {
        dispatch({
          type: "EDIT_PRESTATAIRE",
          payload: res,
        });
      });
    }
  }, [auth?.token, params?.id]);
  const goBack = () => {
    history.goBack();
  };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={auth?.user?.user_type} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <div
                      aria-hidden="true"
                      style={{ marginTop: "13px", marginRight: "33px" }}
                    >
                      <button
                        className="btn btn-success crud-submit-btn"
                        onClick={goBack}
                      >
                        <i className="fas fa-long-arrow-alt-left"></i> Retour
                      </button>
                    </div>
                    <h1 className="page-title">
                      Prestations de{" "}
                      {prestataire?.raison_sociale ||
                        prestataire?.prenom + " " + prestataire?.nom}
                    </h1>
                  </div>
                  {/* <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target=".add-soin-modal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Demander un remboursement
                    </span>
                  </div> */}
                </div>
                <div className="parent-table-container">
                  <Prestation />
                </div>
                {/* Modal Show */}
                <div
                  className="modal fade easypm-modal show-prestation-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <ShowModalSoin from="prestation_by_prestataire" />
                </div>
                {/* end Modal Show */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrestationByPrestataire;
