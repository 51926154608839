import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import * as Yup from "yup";
import { fr } from "yup-locales";
// import isAuthenticate from './modules/Secure/Secure'
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "react-toastify/dist/ReactToastify.css";

// import Home from './Components/Backend/Home/Home'
import Dashboard from "./Components/Backend/IPM/Dashboard/Dashboard";
import Inscription from "./Components/Auth/Inscription";
import Connexion from "./Components/Auth/Connexion";
import ResetPassword from "./Components/Auth/ResetPassword";

import GestionSoins from "./Components/Backend/IPM/GestionSoins/GestionSoins";
import GestionBeneficiaires from "./Components/Backend/IPM/GestionBeneficiaires/GestionBeneficiaires";
import BeneficiaireProfile from "./Components/Backend/BeneficiaireProfile/BeneficiaireProfile";
import GestionTypePrestations from "./Components/Backend/IPM/GestionTypePrestations/GestionTypePrestations";
import GestionPrestataires from "./Components/Backend/IPM/GestionPrestataires/GestionPrestataires";
import GestionEntreprises from "./Components/Backend/IPM/GestionEntreprises/GestionEntreprises";
import BeneficiairesByEntreprise from "./Components/Backend/IPM/BeneficiairesByEntreprise/BeneficiairesByEntreprise";
import Parametres from "./Components/Backend/IPM/Parametres/Parametres";
import LandingPage from "./Components/LandingPage/LandingPage";
import SoinsByBeneficiaire from "./Components/Backend/IPM/SoinsByBeneficiaire/SoinsByBeneficiaire";
import GestionFacturation from "./Components/Backend/IPM/GestionFacturation/GestionFacturation";
import GestionFactures from "./Components/Backend/IPM/GestionFacturation/GestionFactures";
import DetailsFacture from "./Components/Backend/IPM/DetailsFacture/DetailsFacture";
import { DataProvider } from "./Components/store/GlobalState";
import { ToastContainer } from "react-toastify";
import GestionDemandeFicheDeSoin from "./Components/Backend/IPM/GestionDemandeFicheDeSoin/GestionDemandeFicheDeSoin";
import isAuthenticate from "./Components/Modules/Secure/Secure";

import GestionFacture from "./Components/Backend/PRESTATAIRE/GestionFacture/GestionFacture";
import ParametresPrestataire from "./Components/Backend/PRESTATAIRE/Parametres/ParametresPrestataire";
import FicheDeSoinValide from "./Components/Backend/PRESTATAIRE/FicheDeSoin/FicheDeSoinValide";

import GestionIPM from "./Components/Backend/SUPERADMIN/GestionIPM/GestionIPM";
import AdminGestionPrestataires from "./Components/Backend/SUPERADMIN/GestionPrestataires/AdminGestionPrestataires";
import AdminParametres from "./Components/Backend/SUPERADMIN/Parametres/AdminParametres";
import { getBuildDate } from "./utils/utils";
import withClearCache from "./ClearCache";
import GestionException from "./Components/Backend/IPM/GestionException/GestionException";
import Notification from "./Components/Backend/IPM/GestionNotifications/Notification";
import DetailPrestataire from "./Components/Backend/IPM/GestionNotifications/DetailPrestataire";
import DetailDevis from "./Components/Backend/IPM/GestionNotifications/DetailDevis";
import DetailFactureNotification from "./Components/Backend/IPM/GestionNotifications/DetailFacture";
import PrestationByPrestataire from "./Components/Backend/IPM/PrestationByPrestataire/PrestationByPrestataire";
import GestionDevis from "./Components/Backend/PRESTATAIRE/GestionDevis/GestionDevis";
import DetailEntrepriseNotification from "./Components/Backend/IPM/GestionNotifications/DetailEntrepriseNotification";
import DetailBeneficiaireNotification from "./Components/Backend/IPM/GestionNotifications/DetailBeneficiaireNotification";
import DetailAyantDroit from "./Components/Backend/IPM/GestionNotifications/DetailAyantDroit";
import Rapports from "./Components/Backend/IPM/Rapports/Rapports";
import DetailFichedesoin from "./Components/Backend/IPM/GestionNotifications/DetailFichedesoin";
import PrestationsByBeneficiaire from "./Components/Backend/IPM/PrestationsByBeneficiaire/PrestationsByBeneficiaire";
import ChangePassword from "./Components/Backend/IPM/Parametres/ChangePassword";
import packageJson from "../package.json";
import GestionFamilleSoin from "./Components/Backend/SUPERADMIN/GestionFamilleSoin/GestionFamilleSoin";
import GestionDesExclusions from "./Components/Backend/SUPERADMIN/GestionDesExclusions/GestionDesExclusions";
import DetailsValidationExclusion from "./Components/Backend/IPM/GestionNotifications/DetailsValidationExclusion";
import GestionDesSpecialites from "./Components/Backend/SUPERADMIN/GestionDesSpecialites/GestionDesSpecialites";
import LandingPageAssurance from "./Components/Backend/Assurance/LandingpageAssurance/LandingpageAssurance";
import FirstLandingPage from "./Components/Backend/FirstLandingPage/FirstLandingPage";
import ConnexionAssureur from "./Components/Backend/Assurance/ConnexionAssureur/ConnexionAssureur";
import GestionSoinsAssureur from "./Components/Backend/Assurance/GestionSoinsAssureur/GestionSoinsAssureur";
import GestionRapportsAssureur from "./Components/Backend/Assurance/GestionRapportsAssureur/GestionRapportsAssureur";
import GestionPrestataireAssureur from "./Components/Backend/Assurance/GestionPrestataireAssureur/GestionPrestataireAssureur";
import DevisAssureur from "./Components/Backend/Assurance/AdminAssureur/DevisAssureur/DevisAssureur";
import EntrepriseAssureur from "./Components/Backend/Assurance/AdminAssureur/EntrepriseAdmin/EntrepriseAdmin";
import FacturationAssureur from "./Components/Backend/Assurance/AdminAssureur/FacturationAdmin/FacturationAdmin";
import DetailsPrestataireAssureur from "./Components/Backend/Assurance/GestionPrestataireAssureur/DetailsPrestataireAssureur";
import FacturationClient from "./Components/Backend/Assurance/GestionFacturation/FacturationDesClients/FacturationClients";
import FacturationFournisseur from "./Components/Backend/Assurance/GestionFacturation/FacturationDesFournisseurs/FacturationFournisseur";
import PriseEnCharge from "./Components/Backend/Assurance/PriseEnCharge/PriseEnCharge";
import GestionRemboursement from "./Components/Backend/Assurance/GestionRemboursement/GestionRemboursement";
import GestionEntreprise from "./Components/Backend/Assurance/GestionEntreprise/GestionEntreprise";
import GestionAssures from "./Components/Backend/Assurance/GestionAssures/GestionAssures";
import GestionDevisAssurance from "./Components/Backend/Assurance/GestionDevis/GestionDevis";
import ParametreAssurance from "./Components/Backend/Assurance/ParametreAssurance/ParametreAssurance";
import DetailsAssures from "./Components/Backend/Assurance/GestionAssures/DetailsAssures";
import ProfilUtilisateurAssureur from "./Components/Backend/Assurance/ProfilUtilisateur/ProfilUtilisateur";
import RemboursementAssureur from "./Components/Backend/Assurance/AdminAssureur/RemboursementAssureur/RemboursementAssureur";
import AdherentAssureur from "./Components/Backend/Assurance/AdminAssureur/AdherentsAssureur/AdherentsAssureur";
import DetailsAdherent from "./Components/Backend/Assurance/AdminAssureur/AdherentsAssureur/DetailsAdherent";
import DashboardAssureur from "./Components/Backend/Assurance/DashboardAssureur/DashboardAssureur/DashboardAssureur";
import GestionPrestataireAdmin from "./Components/Backend/Assurance/AdminAssureur/GestionPrestataireAssureur/GestionPrestataireAdmin";
import DetailsPrestataireAdmin from "./Components/Backend/Assurance/AdminAssureur/GestionPrestataireAssureur/DetailsPrestataireAdmin";
import FacturationAdmin from "./Components/Backend/Assurance/AdminAssureur/FacturationAdmin/FacturationAdmin";
import DetailsFacturationAdmin from "./Components/Backend/Assurance/AdminAssureur/FacturationAdmin/DetailsFacturation";
import GestionRapportsAdmin from "./Components/Backend/Assurance/AdminAssureur/GestionRapportsAdmin/GestionRapportsAdmin";
import PriseEnChargeAdmin from "./Components/Backend/Assurance/AdminAssureur/PriseEnChargeAdmin/PriseEnChargeAdmin";
import ActiviteSoinAssureur from "./Components/Backend/Assurance/AdminAssureur/ActiviteSoinAssureur/ActiviteSoinAssureur";
import ConnexionBeneficiaire from "./Components/Backend/Beneficiaire/ConnexionBenefiaire/ConnexionBeneficiare";
import DashboardBeneficiaire from "./Components/Backend/Beneficiaire/DashboardBeneficiaire/DashboardBeneficiaire/DashboardBeneficiaire";
import FicheDeSoinsBeneficiaire from "./Components/Backend/Beneficiaire/FicheDeSoinsBeneficiaire/FicheDeSoinsBeneficiaire";
import GestionPrestataireBeneficiaire from "./Components/Backend/Beneficiaire/GestionPrestataireBeneficiaire/GestionPrestataireBeneficiaire";
import DetailsPrestataireBeneficiaire from "./Components/Backend/Beneficiaire/GestionPrestataireBeneficiaire/DetailsPrestataireBeneficiaire";
import GestionRemboursementBeneficiaire from "./Components/Backend/Beneficiaire/GestionRemboursementBeneficiaire/GestionRemboursementBeneficiaire";
import LivretBeneficiaire from "./Components/Backend/Beneficiaire/LivretBeneficiaire/LivretBeneficiaire";
import BaseDeDonneeBeneficiaire from "./Components/Backend/Beneficiaire/BaseDeDonnee/BaseDeDonnee";
import ListeDesSpecialite from "./Components/Backend/Beneficiaire/BaseDeDonnee/ListeDesSpecialite";
import Pharmacies from "./Components/Backend/Beneficiaire/BaseDeDonnee/Pharmacies";
import EtablissementSante from "./Components/Backend/Beneficiaire/BaseDeDonnee/EtablissementSante";
import InfoUtiles from "./Components/Backend/Beneficiaire/BaseDeDonnee/InfoUtiles";
import AjouterInfoUtile from "./Components/Backend/Beneficiaire/BaseDeDonnee/AjouterInfoUtile";
import DetailsInfoUtile from "./Components/Backend/Beneficiaire/BaseDeDonnee/DetailsInfoUtile";
import GestionAide from "./Components/Backend/Beneficiaire/GestionAide/GestionAide";
import NousContacter from "./Components/Backend/Beneficiaire/NousContacter/NousContacter";
import NouveauMessage from "./Components/Backend/Beneficiaire/NousContacter/NouveauMessage";
import MonCompte from "./Components/Backend/Beneficiaire/MonCompte/MonCompte";
import GestionDevisBeneficiaire from "./Components/Backend/Beneficiaire/GestionDevisBeneficiaire/GestionDevisBeneficiaire";
import GestionRendezVous from "./Components/Backend/Beneficiaire/GestionRendezVous/GestionRendezVous";
import ListeDesParticipants from "./Components/Backend/Beneficiaire/GestionRendezVous/ListeDesParticipants";
import DetailsPraticien from "./Components/Backend/Beneficiaire/GestionRendezVous/DetailsPraticien";
import GestionRendezVousPrestataire from "./Components/Backend/PRESTATAIRE/GestionRendezVousPrestataire/GestionRendezVousPrestataire";
import GestionReservation from "./Components/Backend/PRESTATAIRE/GestionReservation/GestionReservation";
import DetailsDuPrestataire from "./Components/Backend/PRESTATAIRE/GestionRendezVousPrestataire/DetailPrestataire";
import Conditions from "./Components/Backend/Condition/Conditions";
import CGU from "./Components/LandingPage/CGU";
import Policy from "./Components/LandingPage/Policy";
Yup.setLocale(fr);
// const ClearCacheComponent = withClearCache(MainApp);

// function App() {
//   return <ClearCacheComponent />;
// }

function App() {
  var isLogInExpired = isAuthenticate();
  const token = window.sessionStorage.getItem("authToken");
  var userType = window.sessionStorage.getItem("userType");
  // const version = packageJson.version
  // console.log('expired', isLogInExpired)
  console.log("Build date :", getBuildDate(packageJson.buildDate));
  return (
    <DataProvider>
      <Router>
        <div className="router-container">
          {/* <div className="version-container">
            <span> { `V${version}` } </span>
          </div> */}
          <ToastContainer />
          <Switch>
            <Route
              exact
              path="/"
              // render={() => {
              //   if (isLogInExpired || !token) {
              //     return <FirstLandingPage />;
              //   } else {
              //     return (
              //       <Redirect
              //         to={
              //           userType === "ipm" ||
              //           userType === "assurance_sante" ||
              //           userType === "admin_ipm"
              //             ? "/ipm/dashboard"
              //             : userType === "entreprise"
              //             ? "/entreprise/dashboard"
              //             : userType === "prestataire"
              //             ? "/prestataire/dashboard"
              //             : userType === "assureur"
              //             ? "/assurance/dashboard"
              //             : ""
              //         }
              //       />
              //     );
              //   }
              // }}
              component={FirstLandingPage}
            />
            <Route
              exact
              path="/easypm"
              render={() => {
                if (isLogInExpired || !token) {
                  return <LandingPage />;
                } else {
                  return (
                    <Redirect
                      to={
                        userType === "ipm" ||
                        userType === "assurance_sante" ||
                        userType === "admin_ipm"
                          ? "/ipm/dashboard"
                          : userType === "entreprise"
                          ? "/entreprise/dashboard"
                          : userType === "prestataire"
                          ? "/prestataire/dashboard"
                          : userType === "assureur"
                          ? "/assurance/dashboard"
                          : ""
                      }
                    />
                  );
                }
              }}
              // component={LandingPage}
            />
            {/* <Route
              exact
              path="/easypm-assurance"
              component={LandingPageAssurance}
            /> */}
            <Route
              exact
              path="/admin-assurance"
              component={DashboardAssureur}
            />
            <Route
              exact
              path="/activite-soin-assurance"
              component={ActiviteSoinAssureur}
            />
            <Route exact path="/devis-assurance" component={DevisAssureur} />
            <Route
              exact
              path="/entreprise-assurance"
              component={EntrepriseAssureur}
            />
            <Route
              exact
              path="/prestataire-assurance"
              component={GestionPrestataireAdmin}
            />
            <Route
              exact
              path="/details-prestataire-assurance/:id"
              component={DetailsPrestataireAdmin}
            />
            <Route
              exact
              path="/details-facturation-assurance/:id"
              component={DetailsFacturationAdmin}
            />
            <Route
              exact
              path="/rapports-assurance"
              component={GestionRapportsAdmin}
            />
            <Route
              exact
              path="/facturation-assurance"
              component={FacturationAdmin}
            />
            <Route
              exact
              path="/remboursement-assurance"
              component={RemboursementAssureur}
            />
            <Route
              exact
              path="/adherent-assurance"
              component={AdherentAssureur}
            />
            <Route
              exact
              path="/details-adherent-assurance/:id"
              component={DetailsAdherent}
            />
            <Route
              exact
              path="/prise-en-charge-assurance"
              component={PriseEnChargeAdmin}
            />
            <Route
              exact
              path="/conditions-generales-utilisation"
              component={CGU}
            />
            <Route
              exact
              path="/protection-des-donnees"
              component={Policy}
            />

            <Route
              exact
              path="/easypm-assurance"
              render={() => {
                if (isLogInExpired || !token) {
                  return <LandingPageAssurance />;
                } else {
                  return (
                    <Redirect
                      to={
                        userType === "ipm" ||
                        userType === "assurance_sante" ||
                        userType === "admin_ipm"
                          ? "/ipm/dashboard"
                          : userType === "entreprise"
                          ? "/entreprise/dashboard"
                          : userType === "prestataire"
                          ? "/prestataire/dashboard"
                          : userType === "assureur"
                          ? "/assurance/dashboard"
                          : ""
                      }
                    />
                  );
                }
              }}
              // component={LandingPageAssurance}
            />

            <Route
              path="/inscription"
              render={() => {
                if (isLogInExpired || !token) {
                  return <Inscription />;
                } else {
                  return (
                    <Redirect
                      to={
                        userType === "ipm" ||
                        userType === "assurance_sante" ||
                        userType === "admin_ipm"
                          ? "/ipm/dashboard"
                          : userType === "entreprise"
                          ? "/entreprise/dashboard"
                          : userType === "prestataire"
                          ? "/prestataire/dashboard"
                          : userType === "assureur"
                          ? "/assurance/dashboard"
                          : userType === "beneficiaire"
                          ? "/dashboard-beneficiaire"
                          : ""
                      }
                    />
                  );
                }
              }}
            />

            {/* BÉNÉFICIAIRE */}
            <Route
              path="/connexion-beneficiaire"
              component={ConnexionBeneficiaire}
            />
            <Route path="/beneficiaire/change-password">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <ChangePassword />
              )}
            </Route>
            <Route path="/dashboard-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <DashboardBeneficiaire />
              )}
            </Route>
            <Route path="/fiche-soins-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <FicheDeSoinsBeneficiaire />
              )}
            </Route>
            <Route path="/prestataire-agree-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionPrestataireBeneficiaire />
              )}
            </Route>
            <Route path="/detail-prestataire-beneficiaire/:id">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailsPrestataireBeneficiaire />
              )}
            </Route>

            <Route path="/remboursement-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionRemboursementBeneficiaire />
              )}
            </Route>
            <Route path="/devis-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionDevisBeneficiaire />
              )}
            </Route>
            <Route path="/livret-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <LivretBeneficiaire />
              )}
            </Route>
            <Route path="/base-donnee-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <BaseDeDonneeBeneficiaire />
              )}
            </Route>
            <Route path="/liste-des-specialites-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <ListeDesSpecialite />
              )}
            </Route>
            <Route path="/liste-des-pharmacies-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <Pharmacies />
              )}
            </Route>
            <Route path="/liste-des-etablissement-sante-beneficiaire">
              {/* {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : ( */}
              <EtablissementSante />
              {/* )} */}
            </Route>
            <Route path="/info-utile-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <InfoUtiles />
              )}
            </Route>
            <Route path="/ajouter-info-utile-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <AjouterInfoUtile />
              )}
            </Route>
            <Route path="/details-info-utile-beneficiaire/:id">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailsInfoUtile />
              )}
            </Route>
            <Route path="/aide-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionAide />
              )}
            </Route>
            <Route path="/contacter-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <NousContacter />
              )}
            </Route>
            <Route path="/nouveau-message-beneficiaire/:id">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <NouveauMessage />
              )}
            </Route>
            <Route path="/compte-beneficiaire">
              {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : (
                <MonCompte />
              )}
            </Route>
            <Route path="/gestion-de-rendez-vous-beneficiaire">
              {/* {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : ( */}
              <GestionRendezVous />
              {/* )} */}
            </Route>
            <Route path="/liste-des-participants-beneficiaire">
              {/* {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : ( */}
              <ListeDesParticipants />
              {/* )} */}
            </Route>
            <Route path="/details-praticiens/:id">
              {/* {isLogInExpired || !token || userType !== "beneficiaire" ? (
                <Redirect to="/connexion" />
              ) : ( */}
              <DetailsPraticien />
              {/* )} */}
            </Route>

            {/* BÉNÉFICIAIRE */}
            <Route path="/connexion" component={Connexion} />
            <Route path="/connexion-assureur" component={ConnexionAssureur} />
            {/* ASSURANCE */}
            <Route path="/assurance/change-password">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <ChangePassword />
              )}
            </Route>
            <Route path="/assurance/dashboard">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <DashboardAssureur />
              )}
            </Route>
            <Route path="/assurance/soins">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <GestionSoinsAssureur />
              )}
            </Route>
            <Route path="/assurance/rapports">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <GestionRapportsAssureur />
              )}
            </Route>
            <Route exact path="/assurance/prestataire">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <GestionPrestataireAssureur />
              )}
            </Route>
            <Route path="/assurance/prestation-prestataire/:id">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <DetailsPrestataireAssureur />
              )}
            </Route>
            <Route path="/assurance/facturation-client">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <FacturationClient />
              )}
            </Route>
            <Route path="/assurance/facturation-fournisseur">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <FacturationFournisseur />
              )}
            </Route>
            <Route path="/assurance/prise-en-charge">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <PriseEnCharge />
              )}
            </Route>
            <Route path="/assurance/remboursement">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <GestionRemboursement />
              )}
            </Route>
            <Route path="/assurance/entreprise">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <GestionEntreprise />
              )}
            </Route>
            <Route path="/assurance/assures">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <GestionAssures />
              )}
            </Route>
            <Route path="/assurance/devis">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <GestionDevisAssurance />
              )}
            </Route>
            <Route path="/assurance/parametre">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <ParametreAssurance />
              )}
            </Route>
            <Route path="/assurance/details-assures/:id">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <DetailsAssures />
              )}
            </Route>
            <Route path="/assurance/profil">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion-assureur" />
              ) : (
                <ProfilUtilisateurAssureur />
              )}
            </Route>
            <Route path="/assurance/notification/:id">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion" />
              ) : (
                <Notification />
              )}
            </Route>

            <Route path="/assurance/prestation-validation">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailsValidationExclusion />
              )}
            </Route>

            <Route path="/assurance/beneficiaire/:id">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailBeneficiaireNotification />
              )}
            </Route>

            <Route path="/assurance/ayantdroit/:id">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailAyantDroit />
              )}
            </Route>

            <Route path="/assurance/prestataire/:id">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailPrestataire />
              )}
            </Route>

            <Route path="/assurance/facture/:id">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailFactureNotification />
              )}
            </Route>

            <Route path="/assurance/devis/:id">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailDevis />
              )}
            </Route>

            <Route path="/assurance/fichedesoin/:id">
              {isLogInExpired || !token || userType !== "assureur" ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailFichedesoin />
              )}
            </Route>
            {/* IPM */}

            <Route path="/ipm/dashboard">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <Dashboard />
              )}
            </Route>
            <Route
              path="/modifier-mon-mot-de-passe"
              component={ResetPassword}
            />
            {/* <Route path="/listing" component={Listing} /> */}
            <Route path="/ipm/gestion-des-remboursements">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionSoins />
              )}
            </Route>
            <Route path="/ipm/gestion-des-beneficiaires">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionBeneficiaires />
              )}
            </Route>
            <Route path="/ipm/profil-beneficiaire/:id">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <BeneficiaireProfile />
              )}
            </Route>
            <Route path="/ipm/gestion-des-types-de-prestation">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionTypePrestations />
              )}
            </Route>
            <Route path="/ipm/gestion-des-prestataires">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionPrestataires />
              )}
            </Route>
            <Route path="/ipm/gestion-des-entreprises">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionEntreprises />
              )}
            </Route>
            <Route path="/ipm/beneficiaires-entreprise/:id">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <BeneficiairesByEntreprise />
              )}
            </Route>
            <Route path="/ipm/prestation-prestataire/:id">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <PrestationByPrestataire />
              )}
            </Route>
            {/* <Route path="/ipm/soins-beneficiaire/:id" >
              {(isLogInExpired || !token) || (userType !== "ipm" ||
                        userType === "assurance_sante" ||
                        userType === "admin_ipm") ? <Redirect to='/connexion' /> : <PrestationsByBeneficiaire/>}
            </Route> */}
            <Route path="/ipm/parametres">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <Parametres />
              )}
            </Route>
            <Route path="/ipm/change-password">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <ChangePassword />
              )}
            </Route>
            {/* <Route path="/ipm/soins-beneficiaire/:beneficiaireID" >
              {(isLogInExpired || !token) || (userType !== "ipm" ||
                        userType === "assurance_sante" ||
                        userType === "admin_ipm") ? <Redirect to='/connexion' /> : <SoinsByBeneficiaire />}
            </Route> */}
            <Route path="/ipm/gestion-de-la-facturation">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionFacturation />
              )}
            </Route>
            <Route path="/ipm/gestion-des-factures">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionFactures />
              )}
            </Route>

            {/* <Route path="/ipm/gestion-des-exceptions" >
              {(isLogInExpired || !token) || (userType !== "ipm" ||
                        userType === "assurance_sante" ||
                        userType === "admin_ipm") ? <Redirect to='/connexion' /> : <GestionException />}
            </Route> */}
            <Route path="/ipm/gestion-des-devis">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionDevis />
              )}
            </Route>
            <Route path="/ipm/details-facture/:entrepriseID">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailsFacture />
              )}
            </Route>
            <Route path="/ipm/gestion-des-fiches-de-soin">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionDemandeFicheDeSoin />
              )}
            </Route>

            <Route path="/ipm/rapports">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <Rapports />
              )}
            </Route>

            <Route path="/ipm/notification/:id">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <Notification />
              )}
            </Route>

            <Route path="/ipm/prestation-validation">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailsValidationExclusion />
              )}
            </Route>

            <Route path="/ipm/beneficiaire/:id">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailBeneficiaireNotification />
              )}
            </Route>

            <Route path="/ipm/ayantdroit/:id">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailAyantDroit />
              )}
            </Route>

            <Route path="/ipm/prestataire/:id">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailPrestataire />
              )}
            </Route>

            <Route path="/ipm/facture/:id">
              {isLogInExpired ||
              !token ||
              (userType !== "ipm" &&
                userType !== "assurance_sante" &&
                userType !== "admin_ipm") ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailFactureNotification />
              )}
            </Route>

            <Route path="/ipm/devis/:id">
              {isLogInExpired ||
              !token ||
              userType !== "ipm" ||
              userType === "assurance_sante" ||
              userType === "admin_ipm" ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailDevis />
              )}
            </Route>

            <Route path="/ipm/fichedesoin/:id">
              {isLogInExpired ||
              !token ||
              userType !== "ipm" ||
              userType === "assurance_sante" ||
              userType === "admin_ipm" ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailFichedesoin />
              )}
            </Route>

            {/* ENTREPRISES */}
            <Route path="/entreprise/dashboard">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <Dashboard />
              )}
            </Route>
            <Route path="/entreprise/gestion-des-employes">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionBeneficiaires />
              )}
            </Route>
            <Route path="/entreprise/profil-employes/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <BeneficiaireProfile />
              )}
            </Route>
            <Route path="/entreprise/liste-des-prestataires">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionPrestataires />
              )}
            </Route>
            <Route path="/entreprise/notification/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <Notification />
              )}
            </Route>
            <Route path="/entreprise/gestion-des-factures">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionFactures />
              )}
            </Route>
            <Route path="/entreprise/gestion-des-remboursements">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionSoins />
              )}
            </Route>
            <Route path="/entreprise/gestion-des-devis">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionDevis />
              )}
            </Route>
            <Route path="/entreprise/prestation-prestataire/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <PrestationByPrestataire />
              )}
            </Route>
            <Route path="/entreprise/facture/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailFactureNotification />
              )}
            </Route>
            <Route path="/entreprise/beneficiaire/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailBeneficiaireNotification />
              )}
            </Route>
            <Route path="/entreprise/devis/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailDevis />
              )}
            </Route>
            <Route path="/entreprise/parametres">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <Parametres />
              )}
            </Route>
            <Route path="/entreprise/change-password">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <ChangePassword />
              )}
            </Route>
            <Route path="/entreprise/rapports">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <Rapports />
              )}
            </Route>

            {/* PRESTATAIRE */}
            <Route path="/prestataire/gestion-des-factures">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionFacture />
              )}
            </Route>
            <Route path="/prestataire/dashboard">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <Dashboard />
              )}
            </Route>
            <Route path="/prestataire/fiche-de-soin-valide">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <FicheDeSoinValide />
              )}
            </Route>
            <Route path="/prestataire/profil-beneficiaire/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <BeneficiaireProfile />
              )}
            </Route>
            {/* <Route path="/prestataire/adherents" >
              {(isLogInExpired || !token) ? <Redirect to='/connexion' /> : <GestionBeneficiaires />}
            </Route> */}
            <Route path="/prestataire/gestion-des-devis">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionDevis />
              )}
            </Route>
            <Route path="/prestataire/devis/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailDevis />
              )}
            </Route>
            <Route path="/prestataire/parametres">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <ParametresPrestataire />
              )}
            </Route>
            <Route path="/prestataire/gestion-des-rdv">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionRendezVousPrestataire />
              )}
            </Route>
            <Route path="/prestataire/details-prestataire/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailsDuPrestataire />
              )}
            </Route>
            <Route path="/prestataire/gestion-des-reservations">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionReservation />
              )}
            </Route>
            <Route path="/prestataire/change-password">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <ChangePassword />
              )}
            </Route>

            <Route path="/prestataire/facture/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailFactureNotification />
              )}
            </Route>

            {/* SUPER ADMIN */}
            {/* <Route path="/admin/dashboard" >
              {(isLogInExpired || !token) ? <Redirect to='/connexion' /> : <Dashboard />}
            </Route> */}
            <Route path="/admin/gestion-des-ipm">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionIPM />
              )}
            </Route>
            <Route path="/admin/gestion-des-etablissements-et-pharmacies">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <AdminGestionPrestataires />
              )}
            </Route>
            <Route path="/admin/gestion-des-familles-soins">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionFamilleSoin />
              )}
            </Route>
            <Route path="/admin/gestion-des-exclusions">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionDesExclusions />
              )}
            </Route>
            <Route path="/admin/conditions">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <Conditions />
              )}
            </Route>
            <Route path="/admin/parametres">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <AdminParametres />
              )}
            </Route>
            <Route path="/admin/prestataire/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailPrestataire />
              )}
            </Route>

            <Route path="/admin/entreprise/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailEntrepriseNotification />
              )}
            </Route>

            <Route path="/admin/beneficiaire/:id">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <DetailBeneficiaireNotification />
              )}
            </Route>
            <Route path="/admin/change-password">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <ChangePassword />
              )}
            </Route>
            <Route path="/admin/gestion-des-specialites">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <GestionDesSpecialites />
              )}
            </Route>
          </Switch>
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;
