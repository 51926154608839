import React, { useEffect, useContext } from 'react'
import AdminsTable from '../../../Tables/Admins/AdminsTable'
import * as IconlyPack from 'react-iconly';
import CRUDAdminIPMModal from '../../../Modals/CRUDAdminIPMModal'
import UseCreateAssuranceAdminForm from './Requests/UseCreateAssuranceAdminForm'
import { DataContext } from '../../../store/GlobalState'

var Admins = () => {
    const {
        register,
        formState,
        isSuccessfullySubmitted,
        setIsSuccessfullySubmitted,
        setIsFormRequestError,
        isFormRequestError,
        clearErrors,
        onSubmit,
        setValue,
        reset
    } = UseCreateAssuranceAdminForm();

    
    const userID = sessionStorage.getItem('userID')
    const { state } = useContext(DataContext)
    const { auth } = state

    useEffect(() => {
        if (isSuccessfullySubmitted) {

          setTimeout(() => {
            setIsSuccessfullySubmitted(false)
          }, 5000)
        }
    
        if (isFormRequestError) {
          setIsSuccessfullySubmitted(false)
    
          setIsFormRequestError(true)
          setTimeout(() => {
            setIsFormRequestError(false)
          }, 5000)
        }
    
        if (formState.errors) {
          setTimeout(() => {
            Object.entries(formState.errors).map(([key]) => {
              return clearErrors(key)
            })
          }, 3000)
        }
        // console.log(formState)
    }, [isSuccessfullySubmitted, isFormRequestError, clearErrors, formState, setIsFormRequestError, setIsSuccessfullySubmitted])

    var handleReset = () => {
        Array.from(document.querySelectorAll('input')).forEach(
          (input) => (input.value = ''),
        )
    }


    return(
        <div className="easypm-tabs-item-content-container">
            <div className="easypm-tabs-item">
                <div className="row adding-row">
                    <div className="col-md-2 offset-md-10 adding-col-offseting">
                        <div className="adding-item-container linkable" data-toggle="modal" data-target=".add-admin-modal">
                            <span className="icon-container">
                                <IconlyPack.Plus set="light" primaryColor="gray"/>
                            </span>
                            <span className="adding-item-label">
                                Ajouter un Admin
                            </span>
                        </div>
                    </div>
                </div>
                <AdminsTable />
            </div>

            {/* Modal */}
            <div className="modal fade easypm-modal add-admin-modal" data-backdrop="static" id="AddModalItem" tabIndex="-1" aria-labelledby="AddModalItemLabel" aria-hidden="true">
                <CRUDAdminIPMModal
                    actionType={'create'}
                    type={auth?.user && auth?.user?.user_type}
                    register={register}
                    fromIpmID={auth?.user?.user_type === 'admin_ipm' ? auth?.user?.parent : userID}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    handleReset={handleReset}
                    setValue={setValue}
                    reset={reset}
                />
            </div>
        </div>
    )
}

export default Admins;