import React, { useContext, useEffect } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import * as IconlyPack from 'react-iconly';
import ProfileInformation from "./ProfileInformation";
import Admins from "./Admins";
import GestionException from "../GestionException/GestionException";
import AcceptGénérationModal from "../../../Modals/AcceptGénérationModal";
import UseCreateExceptionForm from "../GestionException/UseCreateExceptionForm";
import { DataContext } from "../../../store/GlobalState";
import Visa from "./Visa";
import Facturation from "./Facturation";
import ChangePassword from "./ChangePassword";
import Exclusions from "../../../Tables/Exclusions/Exclusions";
import ExclusionsIpm from "./ExclusionsIpm";
import Logo from "./Logo";

var Parametres = () => {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    onSubmit,
    setValue,
    reset,
    getValues,
  } = UseCreateExceptionForm();

  const { state } = useContext(DataContext);
  const { auth } = state;
  // var [tab, setTab] = React.useState('informations')
  const [userType, setUserType] = React.useState();

  useEffect(() => {
    setUserType(window.sessionStorage.getItem("userType"));
  }, []);

  // const handleCheck = (e, value) => {
  //   e.preventDefault()
  //   console.log('check', value)
  //   setTab(value)
  // }

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Paramétres</h1>
                  </div>
                </div>

                <div className="parent-table-container parent-tabs-container">
                  <div className="easypm-tabs-content">
                    <ul
                      className="nav nav-pills mb-3 easypm-tabs-nav-pills"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item easypm-tabs-nav-pills-item">
                        <a
                          className="nav-link active"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#pills-informations"
                          role="tab"
                          // onClick={(e) => handleCheck(e, 'informations')}
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Informations
                        </a>
                      </li>
                      {/* <li className="nav-item easypm-tabs-nav-pills-item">
                                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-sites" role="tab"
                                                // onClick={(e) => handleCheck(e, 'site')} aria-controls="pills-profile" aria-selected="false">Sites</a>
                                            </li> */}
                      {auth?.user?.user_type !== "admin_ipm" &&
                        !auth?.user?.admin_type && (
                          <li className="nav-item easypm-tabs-nav-pills-item">
                            <a
                              className="nav-link"
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-comptes"
                              role="tab"
                              // onClick={(e) => handleCheck(e, 'comptes')}
                              aria-controls="pills-contact"
                              aria-selected="false"
                            >
                              Comptes
                            </a>
                          </li>
                        )}

                      <li className="nav-item easypm-tabs-nav-pills-item">
                        <a
                          className="nav-link"
                          id="pills-exception-tab"
                          data-toggle="pill"
                          href="#pills-exceptions"
                          role="tab"
                          // onClick={(e) => handleCheck(e, 'exceptions')}
                          aria-controls="pills-exception"
                          aria-selected="false"
                        >
                          Oppositions
                        </a>
                      </li>
                      {auth?.user?.user_type !== "admin_ipm" && (
                        <li className="nav-item easypm-tabs-nav-pills-item">
                          <a
                            className="nav-link"
                            id="pills-visa-tab"
                            data-toggle="pill"
                            href="#pills-visa"
                            role="tab"
                            // onClick={(e) => handleCheck(e, 'visa')}
                            aria-controls="pills-visa"
                            aria-selected="false"
                          >
                            {auth?.user?.user_type !== "entreprise"
                              ? "Visa"
                              : "Signature"}
                          </a>
                        </li>
                      )}
                      {auth?.user?.user_type !== "entreprise" &&
                        auth?.user?.user_type !== "admin_ipm" && (
                          <>
                            <li className="nav-item easypm-tabs-nav-pills-item">
                              <a
                                className="nav-link"
                                id="pills-logo-tab"
                                data-toggle="pill"
                                href="#pills-logo"
                                role="tab"
                                aria-controls="pills-logo"
                                aria-selected="false"
                              >
                                Logo
                              </a>
                            </li>
                            <li className="nav-item easypm-tabs-nav-pills-item">
                              <a
                                className="nav-link"
                                id="pills-facture-tab"
                                data-toggle="pill"
                                href="#pills-factures"
                                role="tab"
                                // onClick={(e) => handleCheck(e, 'facture')}
                                aria-controls="pills-facture"
                                aria-selected="false"
                              >
                                Facturation
                              </a>
                            </li>
                            <li className="nav-item easypm-tabs-nav-pills-item">
                              <a
                                className="nav-link"
                                id="pills-exclusion-tab"
                                data-toggle="pill"
                                href="#pills-exclusions"
                                role="tab"
                                aria-controls="pills-exclusion"
                                aria-selected="false"
                              >
                                Exclusions
                              </a>
                            </li>
                          </>
                        )}
                    </ul>
                    <div
                      className="tab-content easypm-tabs-content-body"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane easypm-tabs-pane fade show active"
                        id="pills-informations"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <ProfileInformation />
                      </div>
                      <div
                        className="tab-pane easypm-tabs-pane fade"
                        id="pills-logo"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <Logo />
                      </div>
                      <div
                        className="tab-pane easypm-tabs-pane fade"
                        id="pills-comptes"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <Admins />
                      </div>

                      <div
                        className="tab-pane easypm-tabs-pane fade"
                        id="pills-exceptions"
                        role="tabpanel"
                        aria-labelledby="pills-exception-tab"
                      >
                        <GestionException />
                      </div>

                      <div
                        className="tab-pane easypm-tabs-pane fade"
                        id="pills-visa"
                        role="tabpanel"
                        aria-labelledby="pills-visa-tab"
                      >
                        <Visa />
                      </div>

                      <div
                        className="tab-pane easypm-tabs-pane fade"
                        id="pills-factures"
                        role="tabpanel"
                        aria-labelledby="pills-facture-tab"
                      >
                        <Facturation />
                      </div>

                      <div
                        className="tab-pane easypm-tabs-pane fade"
                        id="pills-exclusions"
                        role="tabpanel"
                        aria-labelledby="pills-exclusion-tab"
                      >
                        <ExclusionsIpm />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal add-entreprise-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  {/* <CRUDEntrepriseModal /> */}
                </div>
                {/* Modal */}
                <div
                  className="modal fade easypm-modal accept-generation-modal"
                  data-backdrop="static"
                  id="AddModalItem"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <AcceptGénérationModal
                    ipm={auth?.user}
                    register={register}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    setValue={setValue}
                    reset={reset}
                    getValues={getValues}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* {tab === 'informations' && (
            <div
              className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny"
              style={{ marginTop: '35px' }}
            >
              <div className="page-body-container">
                <div className="page-content-container">
                  <div className="page-first-row-container">
                    <div className="page-title-container">
                      <h1 className="page-title">
                        Changer votre mot de passe
                      </h1>
                    </div>
                  </div>

                  <div className="parent-table-container parent-tabs-container">
                    <div className="easypm-tabs-content">
                      <div
                        className="tab-content easypm-tabs-content-body"
                        id="pills-tabContent"
                      >
                        <div
                          className="tab-pane easypm-tabs-pane fade show active"
                          id="pills-informations"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <ChangePassword />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Parametres;
