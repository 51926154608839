import React, { useContext, useState } from 'react'
// import { useForm } from 'react-hook-form'
import { DataContext } from '../store/GlobalState'
import AssuranceMaladie from '../Services/AssuranceMaladie'
import { toast } from 'react-toastify'
import { deleteItem } from '../store/Actions'

function DeleteIpmModal() {
  const { state, dispatch } = useContext(DataContext)
  const [isLoading, setIsLoading] = useState(false)

  const { auth, ipm, ipms } = state
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  }
  const handleDelete = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    var ipmRequest = AssuranceMaladie.deleteIpm(
      ipm.id,
      requestConfig,
    )
    await ipmRequest
      .then(() => {
        setIsLoading(false)
        dispatch(deleteItem(ipms, ipm.id, 'ADD_IPM'))
        window.$('.delete-ipm-modal').modal('hide')
        dispatch({ type: 'EDIT_IPM', payload: {} })
        toast.success('Ipm supprimé !')
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
        toast.error('Oupss! Une erreur est survenue lors de la suppréssion.')
      })
  }
  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="DeletingFamilyModal"
          >
            Suppression d'un Ipm
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              dispatch({ type: 'EDIT_IPM', payload: {} })
            }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="deleting-family-form-container">
            <div className="alert-delete-message">
              <span>Êtes vous sûr de vouloir supprimer cet IPM ?</span>
            </div>

            <div className="row delete-row">
              <div className="col-md-10 offset-md-2 crud-form-action-btn-container">
                <button
                  onClick={() =>
                    dispatch({
                      type: 'EDIT_IPM',
                      payload: {},
                    })
                  }
                  className="btn btn-default cancelleb-btn delete-actions"
                  data-dismiss="modal"
                >
                  Retour
                </button>
                {!isLoading ? (
                  <button
                    className="btn btn-primary crud-submit-btn delete-actions"
                    onClick={handleDelete}
                  >
                    Valider
                  </button>
                ) : (
                  <button className="btn btn-primary crud-submit-btn delete-actions">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteIpmModal
