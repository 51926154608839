import React, { useContext, useState } from "react";
import { DataContext } from "../store/GlobalState";
import Remboursement from "../Services/Remboursement";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updateItem } from "../store/Actions";
import { getDataByID } from "../utils/fetchData";
import FicheDeSoin from "../Services/FicheDeSoin";
import Swal from "sweetalert2";

function ValidFicheDeSoinModal() {
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useContext(DataContext);
  const { auth, ficheDeSoin, ficheDeSoinsbyIpm, prestation } = state;
  const { register, handleSubmit, reset } = useForm();

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const onSubmit = async (data, e) => {
    console.log("data", data);
    setIsLoading(true);

    var ficheDeSoinRequest = FicheDeSoin.editFicheDeSoin(
      ficheDeSoin.id,
      data,
      requestConfig
    );

    await ficheDeSoinRequest
      .then(async (response) => {
        // var ficheDeSoinInfos = response;

        // console.log("response", response);

        if (
          response?.message &&
          (response?.exception_libelle || response?.exception_type)
        ) {
          await Swal.fire({
            icon: "error",
            title: response?.exception_libelle,
            text: response?.message,
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          if (auth?.user?.user_type === "admin_ipm") {
            await getDataByID(
              "fichedesoinbyipm",
              auth?.user?.parent,
              auth.token
            )
              .then((res) => {
                dispatch({
                  type: "ADD_FICHE_DE_SOIN_BY_IPM",
                  payload: res.sort((a, b) => a.id - b.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            if (auth?.user?.user_type === "assureur") {
              if (auth?.user?.admin_type) {
                await getDataByID(
                  "fichedesoinbyipm",
                  auth?.user?.parent,
                  auth.token
                )
                  .then((res) => {
                    dispatch({
                      type: "FICHESOINS_ASSUREUR",
                      payload: {
                        isLoading: false,
                        data: res?.sort((a, b) => b.id - a.id),
                      },
                    });
                  })
                  .catch((e) => {
                    console.log("e", e);
                  });
              } else {
                await getDataByID(
                  "fichedesoinbyipm",
                  auth?.user?.id,
                  auth.token
                )
                  .then((res) => {
                    dispatch({
                      type: "FICHESOINS_ASSUREUR",
                      payload: {
                        isLoading: false,
                        data: res?.sort((a, b) => b.id - a.id),
                      },
                    });
                  })
                  .catch((e) => {
                    console.log("e", e);
                  });
              }
            } else {
              await getDataByID("fichedesoinbyipm", auth?.user?.id, auth.token)
                .then((res) => {
                  dispatch({
                    type: "ADD_FICHE_DE_SOIN_BY_IPM",
                    payload: res.sort((a, b) => a.id - b.id).reverse(),
                  });
                })
                .catch((e) => {
                  console.log("e", e);
                });
            }
          }
          await toast.success("Statut de la demande modifié avec succès.");
        }

        await setIsLoading(false);
        await e.target.reset();
        await reset();
        await window.$(".show-valid-modal").modal("hide");
        await dispatch({
          type: "EDIT_FICHE_DE_SOIN_BY_IPM",
          payload: {},
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
      });
  };

  const onReset = () => {
    if (ficheDeSoin?.etat !== undefined) {
      reset();
      dispatch({
        type: "EDIT_FICHE_DE_SOIN_BY_IPM",
        payload: {},
      });
    }
  };

  //   console.log('fiche', ficheDeSoin)
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Gestion fiche de soins{" "}
            {ficheDeSoin?.id ? (
              <b>
                {ficheDeSoin?.beneficiaire?.prenom +
                  " " +
                  ficheDeSoin?.beneficiaire?.nom}
              </b>
            ) : null}
            {/* {ficheDeSoin?.id
              ? `de ${
                  ficheDeSoin?.ayant_droit
                    ? ficheDeSoin?.ayant_droit?.prenom +
                      " " +
                      ficheDeSoin?.ayant_droit?.nom
                    : ficheDeSoin?.beneficiaire?.prenom +
                      " " +
                      ficheDeSoin?.beneficiaire?.nom
                }`
              : null} */}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {ficheDeSoin?.etat !== undefined && ficheDeSoin?.id && (
              <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row crud-row">
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="etat">Action</label>
                      <div className="filter-item-container">
                        <select
                          {...register("etat")}
                          defaultValue={ficheDeSoin?.etat}
                          name="etat"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                        >
                          <option hidden value="">
                            Choisir une action
                          </option>
                          <option value="new" disabled>
                            Nouveau
                          </option>
                          <option value="validee">Valider</option>
                          <option value="suspendu">Suspendre</option>

                          <option value="rejete">Rejeter</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                      onClick={() => onReset()}
                    >
                      Retour
                    </button>

                    {!isLoading && (
                      <button className="btn btn-primary crud-submit-btn">
                        Enregistrer
                      </button>
                    )}

                    {isLoading && (
                      <button
                        disabled
                        className="btn btn-primary crud-submit-btn"
                      >
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValidFicheDeSoinModal;
