import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Header from "../../../../Navs/Header";
import LineChart from "../LineChart/LineChart";
import "./DashboardBeneficiaire.css";
import NavbarVerticalV2 from "../../../navbarVerticalV2/navbarVerticalV2";
import { DataContext } from "../../../../store/GlobalState";
import DonutGraphCumul from "../DonutGraphCumul/DonutGraphCumul";
import DonutGraphPriseEnCharge from "../DonutGraphPriseEnCharge/DonutGraphPriseEnCharge";
import BarChartRepartitionMensuel from "../BarChartRepartitionMensuel/BarChartRepartitionMensuel";
import BarChartRepartitionDepenses from "../BarChartRepartitionDepenses/BarChartRepartitionDepenses";
import { useQuery } from "../../../../../Http/http/query";

var DashboardBeneficiaire = () => {
  const { state } = useContext(DataContext);
  const { auth } = state;
  const query = useQuery("/dashboardbybenef/");
  const {
    nb_pharmacies = 0,
    nb_specialites = 0,
    nb_etablissements = 0,
    nb_articles = 0,
  } = query.data;

  const CardSkeleton = () => {
    const renderModules = Array(3)
      .fill()
      .map((item, index) => (
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex px-2">
          <div className="card">
            <Skeleton
              variant="rect"
              style={{ borderRadius: "10px" }}
              width={332}
              height={100}
            />
          </div>
        </div>
      ));
    return <React.Fragment>{renderModules}</React.Fragment>;
  };

  return (
    <div className="container-fluid body-background-dashboard">
      <Header />

      <div className="row">
        {/* Navbar Desktop  */}
        <div className="nav-container no-view-mobile nav-assurance-color">
          <NavbarVerticalV2 />
        </div>

        <div
          className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0"
          id="assurance"
        >
          <div className="container-fluid">
            <div className="row mb-3 px-2">
              {query.loading && <CardSkeleton />}
              {!query.loading && (
                <React.Fragment>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Pharmacies
                        </p>
                        <br />
                        <p></p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {nb_pharmacies}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Spécialistes
                        </p>
                        <br />
                        <p></p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {nb_specialites}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Etablissements de santé
                        </p>
                        <br />
                        <p></p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">
                          {nb_etablissements}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Infos santé
                        </p>
                        <br />
                        <p></p>
                      </div>
                      <div className="stat-counter">
                        <div className="label-stat-counter">{nb_articles}</div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 m-b-5">
                <div className="card-dashboard m-b-15">
                  {query.loading === false ? (
                    <LineChart auth={auth} />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 m-b-5">
                <div className="card-dashboard m-b-15">
                  {query.loading === false ? (
                    <BarChartRepartitionMensuel auth={auth} />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 m-b-5">
                <div className="card-dashboard m-b-15">
                  {query.loading === false ? (
                    <DonutGraphCumul auth={auth} />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 m-b-5">
                <div className="card-dashboard m-b-15">
                  {query.loading === false ? (
                    <BarChartRepartitionDepenses auth={auth} />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 m-b-5">
                <div className="card-dashboard m-b-15">
                  {query.loading === false ? (
                    <DonutGraphPriseEnCharge auth={auth} />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBeneficiaire;
