import React, { useEffect } from "react";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { DataContext } from "../../../../store/GlobalState";
import UseCreateOpposition from "../UseCreateOpposition";

registerLocale("fr", fr);

function AjouterOpposition() {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    unregister,
    isSuccess,
    getValues,
  } = UseCreateOpposition();
  const { state } = React.useContext(DataContext);
  const {
    auth,
    assureur_entreprises,
    assureur_beneficiaires,
    assureur_prestataires,
  } = state;
  const [cles, setCles] = React.useState([{ value: "", label: "" }]);
  const [val, setVal] = React.useState("");
  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());
  const [isValid, setIsValid] = React.useState(true);

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  useEffect(() => {
    if (isSuccess) {
      handleReset();
    }
  }, [isSuccess]);

  React.useEffect(() => {
    register("date_debut");
    setValue("date_debut", moment(new Date()).format("YYYY-MM-DD"));

    register("date_fin");
    setValue("date_fin", moment(new Date()).format("YYYY-MM-DD"));
  }, []);

  const handleDate = (date) => {
    setStartDate(date);
    register("date_debut");
    setValue("date_debut", moment(date).format("YYYY-MM-DD"));
    if (endDate && date > endDate) {
      setIsValid(false);
    }
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      register("date_fin");
      setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };
  const onSelect = (e) => {
    console.log(e.value);
    setVal(e.value);
    register("cle");
    setValue("cle", e.value);
  };

  useEffect(() => {
    if (getValues("type") === undefined) {
      setCles([]);
      setVal("");
      register("cle");
      setValue("cle", "");
    }
  }, [auth, register]);

  const handleSelect = (e) => {
    register("type");
    setValue("type", e.currentTarget.value);
    if (e.target.value === "beneficiaire") {
      setCles(
        assureur_beneficiaires?.data
          ?.filter((b) => b?.is_active === true)
          .map((b) => {
            return {
              label: b.prenom + " " + b.nom,
              value: b.id,
            };
          })
      );
    }

    if (e.target.value === "entreprise") {
      setCles(
        assureur_entreprises?.data?.map((b) => {
          return {
            label: b.raison_sociale,
            value: b.id,
          };
        })
      );
    }

    if (e.target.value === "ipm") {
      if (!auth?.user?.admin_type) {
        setCles([
          {
            label: auth?.user?.raison_sociale,
            value: auth?.user?.id,
          },
        ]);
      } else {
        setCles([
          {
            label: auth?.user?.raison_sociale,
            value: auth?.user?.parent,
          },
        ]);
      }
    }

    if (e.target.value === "prestataire") {
      setCles(
        assureur_prestataires?.data?.map((b) => {
          return {
            label:
              (b?.raison_sociale
                ? b?.raison_sociale
                : b?.prenom + " " + b?.nom) || "",
            value: b.id || "",
          };
        })
      );
    }
  };

  const handleReset = () => {
    setCles([]);
    setVal("");
    reset();
    register("cle");
    setValue("cle", "");
    setEndDate(new Date());
    setStartDate(new Date());
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Ajouter une opposition
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => handleReset()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="type">Type d'exception</label>
                    <div className="filter-item-container">
                      <select
                        name="type"
                        id="type"
                        // {...register("type")}
                        defaultValue={
                          auth?.user?.user_type === "prestataire" ? "ipm" : ""
                        }
                        onChange={handleSelect}
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir un type
                        </option>

                        <option value="ipm">Assurance</option>

                        <option value="beneficiaire">Assuré</option>
                        <option value="entreprise">Entreprise</option>
                        <option value="prestataire">Prestataire</option>
                      </select>
                      {formState.errors && formState.errors.type && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.type?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="libelle">Libellé</label>
                    <input
                      type="text"
                      placeholder="Libellé"
                      name="libelle"
                      className="form-control crud-form-control"
                      id="libelle"
                      {...register("libelle")}
                    />
                    {formState.errors && formState.errors.libelle && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.libelle?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Objet de l’opposition</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner l'objet de l’opposition"
                        name="cle"
                        defaultValues=""
                        onChange={(e) => onSelect(e)}
                        options={cles}
                      />
                      {formState.errors && formState.errors.cle && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.cle?.message}
                        </div>
                      )}
                    </div>
                    <input
                      {...register("ipm")}
                      type="hidden"
                      name="ipm"
                      value={
                        (auth?.user?.id && auth?.user?.admin_type
                          ? auth?.user?.parent
                          : auth?.user?.id) || ""
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required crud-interm prestataire-side">
                    <label htmlFor="date_debut">Date de début</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date_debut"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {formState.errors && formState.errors.date_debut && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-3">
                        {formState.errors.date_debut?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required crud-interm prestataire-side">
                    <label htmlFor="date_fin">Date de Fin</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={endDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date_fin"
                      locale="fr"
                      onChange={(date) => handleDateFin(date)}
                    />
                    {formState.errors && formState.errors.date_fin && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-3">
                        {formState.errors.date_fin?.message}
                      </div>
                    )}
                    {!isValid && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        La date de fin ne doit pas être inférieure à la date de
                        début.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn-assurance">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterOpposition;
