import React from "react";
import * as IconlyPack from "react-iconly";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import AjouterRdv, { getHours, TimeItem } from "./AjouterRdv";
import { useLocation, useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { ApiUrl } from "../../../../Http/http/http";
import { getName } from "../../../../utils/utils";
import { useQuery } from "../../../../Http/http/query";
import moment from "moment";
import { getAllHours } from "../../PRESTATAIRE/Parametres/DisponibilitePrestataire";

var DetailsPraticien = ({}) => {
  let bgColor = "";
  const location = useLocation();
  const history = useHistory();
  const item = location.state;
  const query = useQuery(`/rdvbyprestataire/${item?.id}/`);
  const [date, setDate] = React.useState(moment().startOf("week"));
  React.useEffect(() => {
    if (!item) {
      history.replace("/liste-des-participants-beneficiaire");
    }
  }, [item]);
  if (!item) return null;
  const myHours = getHours(undefined, item.disponibilite);
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-first-row-container pb-4">
                <div className="page-title-container">
                  <h1 className="page-title">Détails du praticien</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 pb-3">
                  <div className="page-content-container">
                    <div className="d-flex flex-column align-items-center py-3">
                      <div>
                        <img
                          src={ApiUrl + item.avatar}
                          alt="Image"
                          className="image-pp-card-detail-rdv"
                        />
                      </div>
                      <div className="pl-3 pt-3">
                        <p
                          className="titre-auteur-rdv no-link"
                          style={{ textAlign: "center" }}
                        >
                          {getName(item)}
                        </p>
                        <p
                          className="text-auteur-rdv"
                          style={{ textAlign: "center" }}
                        >
                          {item.specialite?.nom}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-8 pb-3">
                  <div className="page-content-container">
                    <div className="bloc-info-detail-praticien">
                      <div>
                        {/* <p className="texte-info-normal">Genre</p> */}
                        <p className="texte-info-normal">Adresse</p>
                        <p className="texte-info-normal">Téléphone</p>
                        <p className="texte-info-normal">Spécialité</p>
                        {/* <p className="texte-info-normal">Hopital</p> */}
                      </div>
                      <div>
                        {/* <p className="texte-info-bold">
                          {item.sexe === "homme" ? "H" : "F"}
                        </p> */}
                        <p className="texte-info-bold">{item.adresse}</p>
                        <p className="texte-info-bold">{item.telephone}</p>
                        <p className="texte-info-bold">
                          {item.specialite?.nom}
                        </p>
                        {/* <p className="texte-info-bold">Dalal Diam</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-end align-items-center">
                <div className="col-md-1">
                  <div className=" bg-white text-center">
                    <button
                      className="btn btn-fav trans-0-2 p-3"
                      style={{ backgroundColor: bgColor }}
                    >
                      <IconlyPack.Heart2 set="light" primaryColor="#005D86" />
                    </button>
                  </div>
                </div>
                <div className="col-md-3">
                  <button
                    className="btn crud-submit-btn-beneficiaire w-full p-3"
                    type="button"
                    data-toggle="modal"
                    data-target="#AjouterRdv"
                  >
                    <IconlyPack.Calendar set="light" />
                    <span className="pl-2">Programmer un rendez-vous</span>
                  </button>
                </div>
              </div>
              <div className="page-content-container mt-4">
                <div className="py-4">
                  <p className="titre-auteur-rdv no-link">Disponibilité</p>
                </div>
                <div className="checkbox-add-event">
                  <div className="row align-items-end mb-4">
                    <div className="col-md-4">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setDate((old) => moment(old).add(-7, "days"));
                        }}
                        className="btn crud-submit-btn-beneficiaire p-3"
                      >
                        <IconlyPack.ArrowLeft set="light" />
                      </button>
                    </div>
                    <div className="col-md-4">
                      <p className="legend text-center">
                        Semaine du{" "}
                        <span className="text-legend-bold">
                          {date.format("DD MMMM")}
                        </span>{" "}
                        au{" "}
                        <span className="text-legend-bold">
                          {moment(date).endOf("week").format("DD MMMM")}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-4 text-md-right">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setDate((old) => moment(old).add(7, "days"));
                        }}
                        className="btn crud-submit-btn-beneficiaire p-3"
                      >
                        <IconlyPack.ArrowRight set="light" />
                      </button>
                    </div>
                  </div>
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        {["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"].map(
                          (d) => (
                            <th key={d}>
                              <p className="libelle-calendar-carousel">{d}</p>
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {getAllHours().map((h) => {
                        const taked = !myHours.includes(h);
                        return (
                          <tr key={h}>
                            {[
                              "Lun",
                              "Mar",
                              "Mer",
                              "Jeu",
                              "Ven",
                              "Sam",
                              "Dim",
                            ].map((d, i) => {
                              const cdate = moment(date).add(i, "days");
                              const checked = !!query.data.find(
                                (f) =>
                                  f.heure == h &&
                                  moment(f.date).format("L") ===
                                    cdate.format("L")
                              );
                              return (
                                <td style={{ padding: 0 }}>
                                  <TimeItem
                                    taked={taked}
                                    checked={checked}
                                    col12
                                    onPress={() => null}
                                    time={h}
                                    key={d + h}
                                  />
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="AjouterRdv"
          tabindex="-1"
          aria-labelledby="AjouterRdvLabel"
          aria-hidden="true"
        >
          <AjouterRdv
            prestataire={item}
            onDone={() => {
              window.$(`#AjouterRdv`).modal("hide");
              query.getData();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsPraticien;
