import React, { useState, useMemo, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RequestPassword from "../Services/RequestPassword";
import { useHistory } from "react-router";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";

function ResetPasswordModal() {
  const [isInProgress, setIsInProgress] = useState(false);
  const history = useHistory();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("L'adresse email n'est pas valide")
          .required("L'email est requis"),
      }),
    []
  );

  const { register, handleSubmit, formState, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onRequest = async (data) => {
    setIsInProgress(true);
    var passwordRequest = RequestPassword.postRequestPassword(data);

    await passwordRequest
      .then((res) => {
        console.log(res);
        setIsInProgress(false);
        toast.success(
          "Envoyée avec succès. Veuillez vérifier votre courrier électronique, nous venons de vous envoyer un message pour finaliser la modification de votre mot de passe."
        );
        window.$(".open-reset-password-modal").modal("hide");
        history.push("modifier-mon-mot-de-passe");
      })
      .catch((e) => {
        setIsInProgress(false);
        console.log(e);
        toast.error(
          "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard."
        );
      });
  };
  return (
    <div
      className="modal fade open-reset-password-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content modal-card">
          <div className="row m-b-35">
            <div className="col-10 itm-center">
              <p className="title-modal">Modifier votre mot de passe</p>
            </div>
            <div className="col-2">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <FeatherIcon icon="x" />
                </span>
              </button>
            </div>
          </div>

          <form onSubmit={handleSubmit(onRequest)}>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="mail" />
                    </span>
                  </div>
                  <input
                    name="email"
                    id="email"
                    type="email"
                    className="form-control input-with-icon"
                    placeholder="Adresse mail"
                    {...register("email")}
                  />
                  {formState.errors?.email && (
                    <div className="alert alert-danger easypm-alert-danger">
                      {" "}
                      {formState.errors?.email?.message}{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="dis-flex justify-content-end containt-mobile-center m-t-30">
              {isInProgress === false ? (
                <button type="submit" className="btn-theme p-l-90 p-r-90">
                  ENVOYER
                </button>
              ) : (
                <button type="submit" className="btn-theme p-l-90 p-r-90">
                  Envoi en cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordModal;
