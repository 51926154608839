import React, { useState, useEffect, useContext } from "react";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import { NavLink, useLocation, useParams, useHistory } from "react-router-dom";
// import { forEach } from 'lodash'
// import "react-datepicker/dist/react-datepicker.css";
import DefaultAvatar from "../../../../images/others/default-profile-avatar.png";
import EnfantsDetailsPrestataire from "../../../Tables/DetailPrestataire/EnfantsDetailsPrestataire/EnfantsDetailsPrestataire";
import EpousesDetailsPrestataire from "../../../Tables/DetailPrestataire/EpousesDetailsPrestataire/EpousesDetailsPrestataire";

const DetailsDuPrestataire = () => {
 
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row breadcrumbs-rapports d-flex align-items-center">
          <div className="col-xl-3 col-lg-3 col-md-3 mb-2 dis-flex">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <NavLink
                    to="/assurance/assures"
                    className="nav-item-breadcrumb"
                  >
                    Assurés /
                  </NavLink>
                </li>
                <li
                  class="breadcrumb-item active"
                  className="nav-item-breadcrumb"
                  aria-current="page"
                >
                  Badou Seck
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 mb-2 dis-flex">
            <div>
              <span
                className="icon-container pr-2"
                style={{ color: "rgba(0, 0, 0, 0.7)" }}
              >
                Statut:
              </span>
              <span
                className="adding-item-label text-generer-rapport-assurance"
                style={{ fontWeight: "bold" }}
              >
                Actif
              </span>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 mb-2 dis-flex">
            <div>
              <button
                className="w-full btn btn-generer-rapport"
                // data-toggle="modal"
                // data-target=".add-soin-modal"
                // style={{ cursor: "pointer" }}
              >
                <span className="icon-container pr-2">
                  <IconlyPack.Wallet
                    set="bold"
                    primaryColor="rgba(0, 0, 0, 0.7)"
                  />
                </span>
                <span className="adding-item-label text-generer-rapport-assurance">
                  Générer un carnet
                </span>
              </button>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 mb-2 dis-flex">
            <div>
              <button
                className="w-full btn btn-generer-rapport"
              >
                <span className="icon-container pr-2">
                  {/* <IconlyPack.Wallet
                    set="bold"
                    primaryColor="rgba(0, 0, 0, 0.7)"
                  /> */}
                  <i
                    className="fa fa-qrcode fa-lg header-actions-btn-icon"
                    aria-hidden="true"
                    style={{
                      color: "rgba(0, 0, 0, 0.7)3",
                      marginTop: "0.3rem",
                    }}
                  ></i>
                </span>
                <span className="adding-item-label text-generer-rapport-assurance">
                  Générer une fiche de soins
                </span>
              </button>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 mb-2 dis-flex">
            <div>
              <button
                className="w-full btn btn-generer-rapport"
              >
                <span className="icon-container pr-2">
                  <IconlyPack.PaperNegative
                    set="bold"
                    primaryColor="rgba(0, 0, 0, 0.7)"
                  />
                </span>
                <span className="adding-item-label text-generer-rapport-assurance">
                  Soins
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 userType={`prestataire`} />
          </div>

          <div
            className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 ben-profile-container"
            id="gestion-rapport-assureur"
          >
            <div className="page-body-container">
                <div className="row beneficiaire-profile-row">
                  <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 profile-infos-perso-container profile-item">
                    <div className="">
                      <div className="profile-infos-ben">
                        <h2 className="section-light-title">
                          <div className="light-title">Fiche de l'assuré</div>
                        </h2>
                        {/* <div className="profile-avatar-container">
                        <img
                          src={
                            assure?.avatar &&
                            !assure?.avatar?.includes("default.png")
                              ? baseUrl + assure?.avatar
                              : DefaultAvatar
                          }
                          className="profile-avatar"
                          alt="Profile"
                        />
                      </div> */}
                        <div className="profile-avatar-container">
                          <img
                            src={DefaultAvatar}
                            className="profile-avatar"
                            alt="Profile"
                          />

                          <span>
                            <i className="fas fa-camera"></i>
                            <p>Changer</p>
                            <input
                              type="file"
                              name="avatar"
                              id="file_up"
                              accept="image/*"
                            />
                          </span>
                          {/* <h3 className="ben-name">
                              {' '}
                              {beneficiaireInfos?.prenom +
                                ' ' +
                                beneficiaireInfos?.nom}{' '}
                            </h3> */}
                        </div>
                        <h3 className="ben-name text-center mt-4">
                        Badou Seck
                        </h3>
                        <div className="ben-others-infos-container">
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>N° Assuré:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>2434</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Genre:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>Homme</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Statut:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <strong>
                                Actif
                              </strong>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Nom:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>Seck</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Prénom:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>Abdou</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Date de naissance:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>
                                00/00/1985
                              </span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Situation Matriomiale:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>C</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Groupe Sanguin:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>A+</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Nationalité:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>Sénégalaise</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Téléphone:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>777777777</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Email:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>email@gmail.com</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Employeur:</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>Volkeno</span>
                            </div>
                          </div>
                          <div className="ben-others-infos-item-container">
                          <div className="ben-others-infos-item-label">
                            <span>Département</span>
                          </div>
                          <div className="ben-others-infos-item-value">
                            <span>Département</span>
                          </div>
                        </div>
                          <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Date de création</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <span>
                                17/12/2019
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 profile-infos-family-container profile-item">
                    <div className="wifes-infos-container">
                      <div className="page-first-row-container">
                        <div className="page-title-container">
                          <h1 className="page-title">Épouse (s)</h1>
                        </div>
                        <div
                          className="adding-item-container linkable"
                          data-toggle="modal"
                          data-target=".add-epoux-modal"
                        >
                          <span className="icon-container">
                            <IconlyPack.Plus set="light" primaryColor="gray" />
                          </span>
                          <span className="adding-item-label">Ajouter</span>
                        </div>
                      </div>
                      <div className="epouses-table-container">
                        <EpousesDetailsPrestataire />
                      </div>
                    </div>
                    <div className="childs-infos-container">
                      <div className="page-first-row-container">
                        <div className="page-title-container">
                          <h1 className="page-title">Enfants</h1>
                        </div>
                        <div
                          className="adding-item-container linkable"
                          data-toggle="modal"
                          data-target=".add-enfant-modal"
                        >
                          <span className="icon-container">
                            <IconlyPack.Plus set="light" primaryColor="gray" />
                          </span>
                          <span className="adding-item-label">Ajouter</span>
                        </div>
                      </div>
                      <div className="epouses-table-container">
                        <EnfantsDetailsPrestataire />
                      </div>
                    </div>
                    {/* <div
                      class="modal fade add-enfant-modal"
                      id="AjoutEnfantModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="AjoutEnfantModalLabel"
                      aria-hidden="true"
                    >
                      <AjoutEnfant />
                    </div>
                    <div
                      class="modal fade add-epoux-modal"
                      id="AjoutEpouxModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="AjoutEpouxModalLabel"
                      aria-hidden="true"
                    >
                      <AjoutEpoux />
                    </div>
                    <div
                      class="modal fade edit-enfant-modal"
                      id="EditEnfantModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="EditEnfantModalLabel"
                      aria-hidden="true"
                    >
                      <EditEnfant />
                    </div>
                    <div
                      class="modal fade edit-epoux-modal"
                      id="EditEpouxModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="EditEpouxModalLabel"
                      aria-hidden="true"
                    >
                      <EditEpoux />
                    </div>
                    <div
                      class="modal fade active-family-modal"
                      id="ActiveEpouxModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="ActiveEpouxModalLabel"
                      aria-hidden="true"
                    >
                      <ActiveFamilyModal />
                    </div>
                    <div
                      class="modal fade archive-family-modal"
                      id="ArchiveEpouxModal"
                      tabindex="-1"
                      data-backdrop="static"
                      aria-labelledby="ArchiveEpouxModalLabel"
                      aria-hidden="true"
                    >
                      <ArchiveFamily />
                    </div>
                  */}
                  </div>
                </div>
            </div>
          </div>
          {/* <div
            className="modal fade easypm-modal show-carnet-modal"
            id="DeleteModalItem"
            tabIndex="-1"
            aria-labelledby="DeleteModalItemLabel"
            aria-hidden="true"
            data-backdrop="static"
          >
            <ShowCarnetModal />
          </div>
          <div
            className="modal fade easypm-modal add-fiche-modal"
            id="GenererFicheSoinModal"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="GenererFicheSoinModalLabel"
            aria-hidden="true"
          >
            <FicheSoinGenerate />
          </div>
        */}
        </div>
      </div>
    </div>
  );
};

export default DetailsDuPrestataire;
