import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import typePrestation from "../../../Services/TypePrestation";
import { DataContext } from "../../../store/GlobalState";
import { getDataByID } from "../../../utils/fetchData";

export default function UseCreateTypePrestationForm() {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [typePrestationData, setTypePrestationData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        libelle: yup.string(),
        // num_contrat: yup.string().required('Le numéro du contrat est requis'),
        taux_prise_en_charge: yup
          .string()
          .required("Le taux pris en charge est requis"),
        montant_plafond: yup.string().required("Le montant plafond est requis"),
        familledesoin: yup
          .number()
          .required("La famille de soins est requise."),
        typedesoin: yup.number(),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    if (auth?.user?.user_type === "admin_ipm") {
      formValues["ipm"] = auth?.user?.parent;
    } else {
      formValues["ipm"] = auth?.user?.id;
    }
    // console.log('data', formValues)
    var typePrestationRequest = typePrestation.postCreateTypePrestation(
      formValues,
      requestConfig
    );
    await typePrestationRequest
      .then((response) => {
        if (response?.message) {
          toast.error(response.message);
        } else {
          var typePrestationInfos = response;
          console.log("type prestation", typePrestationInfos);
          setTypePrestationData(typePrestationInfos);

          if (auth?.user?.user_type === "admin_ipm") {
            getDataByID(
              "typeprestationbyassurance",
              auth?.user?.parent,
              auth.token
            )
              .then((res) => {
                dispatch({
                  type: "ADD_PRESTATION_TYPE",
                  payload: res.reverse(),
                });
                toast.success("Type de prestation ajouté avec succès.");
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            if (auth?.user?.user_type === "assureur") {
              getDataByID(
                "typeprestationbyassurance",
                auth?.user?.id,
                auth.token
              )
                .then((res) => {
                  dispatch({
                    type: "PRISE_EN_CHARGE_ASSUREUR",
                    payload: {
                      isLoading: false,
                      data: res?.sort((a, b) => b.id - a.id),
                    },
                  });
                  toast.success("Prise en charge ajoutée avec succès.");
                })
                .catch((e) => {
                  console.log("e", e);
                });
            } else {
              getDataByID(
                "typeprestationbyassurance",
                auth?.user?.id,
                auth.token
              )
                .then((res) => {
                  dispatch({
                    type: "ADD_PRESTATION_TYPE",
                    payload: res.reverse(),
                  });
                  toast.success("Type de prestation ajouté avec succès.");
                })
                .catch((e) => {
                  console.log("e", e);
                });
            }
          }
          setIsSuccess(true);
          window.$(".add-type-prestation-modal").modal("hide");
          window.$("#AjouterPriseEnChargeModal").modal("hide");
          e.target.reset();
          reset();
        }
        setIsSuccessfullySubmitted(false);
      })
      .catch((error) => {
        console.log(error?.response);
        setIsSuccessfullySubmitted(false);
        setIsFormRequestError(true);
        if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        } else if (
          error?.response?.status === 401 &&
          error?.response?.data?.message
        ) {
          toast.error(error.response.data.message);
        }
      });
  };

  return {
    register,
    errors,
    formState,
    typePrestationData,
    setTypePrestationData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitCreateForm),
    reset,
    isSuccess,
    setValue,
  };
}
