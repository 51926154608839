import React, { useContext, useEffect } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import TypePrestations from "../../../Tables/TypePrestations/TypePrestations";
import CRUDTypePrestationModal from "../../../Modals/CRUDTypePrestationModal";
import ShowTypePrestationModal from "../../../Modals/ShowTypePrestationModal";
import UseCreateTypePrestationForm from "./UseCreateTypePrestationForm";
import EditTypePrestationModal from "../../../Modals/EditTypePrestationModal";
import { useHistory } from "react-router-dom";
import { DataContext } from "../../../store/GlobalState";
import DeleteTypePrestationModal from "../../../Modals/DeleteTypePrestationModal";

var GestionTypePrestations = () => {
  const {
    register,
    errors,
    formState,
    typePrestationData,
    setTypePrestationData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    reset,
    isSuccess,
    setValue,
  } = UseCreateTypePrestationForm();

  const { state } = useContext(DataContext);
  const { auth } = state;
  const userType = window.sessionStorage.getItem("userType");
  const history = useHistory();

  useEffect(() => {
    if (
      (!auth?.user ||
        auth?.user?.user_type !== "ipm" ||
        auth?.user?.user_type !== "assurance_sante") &&
      !window.sessionStorage.getItem("authToken")
    ) {
      history.push("/connexion");
    }
  }, [auth, history]);

  // useEffect(() => {
  //   setUserType(window.sessionStorage.getItem('userType'))
  // })

  useEffect(() => {
    // onGetListEntreprises()

    if (isSuccessfullySubmitted) {
      setTypePrestationData(typePrestationData);
      setTimeout(() => {
        setIsSuccessfullySubmitted(false);
      }, 5000);
    }

    if (isFormRequestError) {
      setIsSuccessfullySubmitted(false);
      setIsFormRequestError(true);
      setTimeout(() => {
        setIsFormRequestError(false);
      }, 5000);
    }

    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [
    errors,
    isSuccessfullySubmitted,
    formState,
    isFormRequestError,
    clearErrors,
    setIsFormRequestError,
    setIsSuccessfullySubmitted,
    setTypePrestationData,
    typePrestationData,
  ]);

  var handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Familles de soins</h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target=".add-type-prestation-modal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Ajouter un type de prestation
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <TypePrestations />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal add-type-prestation-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <CRUDTypePrestationModal
                    register={register}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    handleReset={handleReset}
                    reset={reset}
                    setValue={setValue}
                    isSuccess={isSuccess}
                  />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal show-type-prestation-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <ShowTypePrestationModal />
                </div>

                {/* Modal Edit */}
                <div
                  className="modal fade easypm-modal edit-type-prestation-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <EditTypePrestationModal />
                </div>

                {/* Modal Delete */}
                <div
                  className="modal fade easypm-modal delete-type-prestation-modal"
                  id="DeleteModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="DeleteModalItemLabel"
                  aria-hidden="true"
                >
                  <DeleteTypePrestationModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionTypePrestations;
