import React, { useEffect, useContext } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import FacturationTable from "../../../Tables/Facturation/FacturationTable";
import { DataContext } from "../../../store/GlobalState";
import { useHistory } from "react-router-dom";
import FactureEntreprise from "../../../Tables/Facturation/FactureEntreprise";
import CRUDFactureModal from "../../../Modals/CRUDFactureModal";
import UseCreateFacturation from "./UseCreateFacturation";
import ShowFactureModal from "../../../Modals/ShowFactureModal";
import DetailFactureModal from "../../../Modals/DetailFactureModal";
import EditFactureModal from "../../../Modals/EditFactureModal";
import { getDataByID } from "../../../utils/fetchData";
import FacturePdfDataModal from "../../../Modals/FacturePdfDataModal";

const GestionFactures = () => {
  const {
    register,
    formState,
    setValue,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    onSubmit,
    reset,
    isSuccess,
    isFormRequestError,
    clearErrors,
  } = UseCreateFacturation();
  const [userType, setUserType] = React.useState();
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);

  const {
    auth,
    entreprises_by_ipm,
    beneficiaires,
    type_prestations,
    entreprises,
    prestations_facture,
  } = state;

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        clearErrors();
      }, 5000);
    }
  }, [formState]);

  React.useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (
        auth.user?.user_type === "ipm" ||
        auth?.user?.user_type === "assurance_sante" ||
        auth?.user?.user_type === "admin_ipm"
      ) {
        if (!auth?.user?.admin_type) {
          getDataByID("facturebyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_FACTURE_BY_IPM",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );

          getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        } else {
          getDataByID("facturebyipm", auth?.user?.parent, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_FACTURE_BY_IPM",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );

          getDataByID(
            "notificationbyipm",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }

      if (auth?.user?.user_type === "entreprise") {
        if (!auth?.user?.admin_type) {
          getDataByID("facturebyentreprise", auth?.user?.id, auth?.token).then(
            (res) => {
              // console.log(res)
              dispatch({
                type: "LIST_FACTURE_BY_ENTREPRISE",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );

          getDataByID(
            "notificationbyentreprise",
            auth?.user?.id,
            auth?.token
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        } else {
          getDataByID(
            "facturebyentreprise",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            // console.log(res)
            dispatch({
              type: "LIST_FACTURE_BY_ENTREPRISE",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });

          getDataByID(
            "notificationbyentreprise",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    setUserType(window.sessionStorage.getItem("userType"));
  }, []);
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      {userType === "entreprise"
                        ? "Facturation des fournisseurs"
                        : "Facturation des clients"}
                    </h1>
                  </div>

                  {(userType === "ipm" ||
                    userType === "assurance_sante" ||
                    userType === "admin_ipm") && (
                    <div
                      className="adding-item-container linkable"
                      data-toggle="modal"
                      data-target=".genere-facture-modal"
                    >
                      <span className="icon-container">
                        <IconlyPack.Plus set="light" primaryColor="gray" />
                      </span>
                      <span className="adding-item-label">
                        Générer une facture
                      </span>
                    </div>
                  )}
                </div>
                <div className="parent-table-container">
                  <FactureEntreprise />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal genere-facture-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <CRUDFactureModal
                    register={register}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    setValue={setValue}
                    entreprises={entreprises_by_ipm}
                    ipm={auth?.user?.id}
                    reset={reset}
                    isSuccess={isSuccess}
                    isFormRequestError={isFormRequestError}
                  />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal show-facture-modal"
                  id="AddModalItem"
                  data-backdrop="static"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <ShowFactureModal />
                </div>

                <div
                  class="modal fade"
                  id="facturePdfModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="facturePdfModalLabel"
                  aria-hidden="true"
                >
                  <FacturePdfDataModal />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal detail-facture-modal"
                  id="AddModalItem"
                  data-backdrop="static"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <DetailFactureModal for="entreprise" />
                </div>
                {/* Modal */}
                <div
                  className="modal fade easypm-modal edit-facture-modal"
                  id="AddModalItem"
                  data-backdrop="static"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <EditFactureModal
                    beneficiaires={beneficiaires}
                    prestation_types={type_prestations}
                    entreprises={entreprises_by_ipm}
                    prestataires={auth?.user?.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionFactures;
