import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DataContext } from "../../../store/GlobalState";
import UseEditPrestataire from "./UseEditPrestataire";

const ModifierPrestataireModal = (props) => {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    unregister,
    isSuccess,
  } = UseEditPrestataire();
  const { state, dispatch } = useContext(DataContext);
  const { prestataire, list_specialites } = state;

  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());
  const [isValid, setIsValid] = React.useState(true);
  const [phone, setPhone] = React.useState("");
  var [website, setWebsite] = useState("");

  const handleSetPhone = (val) => {
    setPhone(val);
    register("telephone");
    setValue("telephone", "+" + val);
  };
  const handleChangeWebsite = (e) => {
    // eslint-disable-next-line
    const urlPattern = new RegExp(
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    );
    let string = e.target.value;

    if (urlPattern.test(string)) {
      ///clear http && https from string
      string = string.replace("https://", "").replace("http://", "");
      //add https to string
      string = `https://${string}`;
    }
    setWebsite(string);
    register("website");
    setValue("website", string);
  };

  const handleDateDebut = (date) => {
    setStartDate(date);
    register("date_debut");
    setValue("date_debut", moment(date).format("YYYY-MM-DD"));
    if (endDate && date > endDate) {
      setIsValid(false);
    }
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      register("date_fin");
      setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    if (prestataire?.id) {
      if (prestataire?.prenom) {
        register("prenom");
        setValue("prenom", prestataire?.prenom);
        register("nom");
        setValue("nom", prestataire?.nom);
      } else {
        register("raison_sociale");
        setValue("raison_sociale", prestataire?.raison_sociale);
        register("ninea");
        setValue("ninea", prestataire?.ninea);
      }
      register("telephone");
      setValue("telephone", prestataire?.telephone);
      setPhone(prestataire?.telephone);
      register("email");
      setValue("email", prestataire?.email);
      register("adresse");
      setValue("adresse", prestataire?.adresse);
      register("specialite");
      setValue("specialite", prestataire?.specialite?.id);
      register("agreement");
      setValue("agreement", prestataire?.agreement);
      register("date_debut");
      setValue("date_debut", prestataire?.date_debut);
      setStartDate(new Date(prestataire?.date_debut));
      register("date_fin");
      setValue("date_fin", prestataire?.date_fin);
      setEndDate(new Date(prestataire?.date_fin));
      console.log(
        "date fin",
        new Date(prestataire?.date_fin),
        prestataire?.date_fin
      );
      register("num_contrat");
      setValue("num_contrat", prestataire?.num_contrat);
      register("website");
      setValue("website", prestataire?.website);
      register("id");
      setValue("id", prestataire?.id);
    }
  }, [prestataire]);

   React.useEffect(() => {
     if (formState.errors) {
       setTimeout(() => {
         Object.entries(formState.errors).map(([key]) => {
           return clearErrors(key);
         });
       }, 5000);
      //  console.log("error", formState.errors);
     }
   }, [formState]);

  const handleReset = () => {
    // await reset()
    setWebsite("");
    setPhone("+221");

    dispatch({ type: "EDIT_PRESTATAIRE", payload: {} });
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ModifierPrestataireModal"
          >
            Modifier un prestataire rrr
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                {prestataire?.raison_sociale && (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="name">Nom</label>
                      <input
                        type="text"
                        placeholder="Nom"
                        name="raison_sociale"
                        className="form-control crud-form-control"
                        id="name"
                        {...register("raison_sociale")}
                      />
                      {formState.errors.raison_sociale && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {formState.errors.raison_sociale.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!prestataire?.raison_sociale && prestataire?.prenom && (
                  <>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <label htmlFor="prenom">Prénom</label>
                        <input
                          type="text"
                          placeholder="Prénom"
                          name="prenom"
                          className="form-control crud-form-control"
                          id="prenom"
                          {...register("prenom")}
                        />
                        {formState.errors.prenom && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {" "}
                            {formState.errors.prenom.message}{" "}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <label htmlFor="name">Nom</label>
                        <input
                          type="text"
                          placeholder="Nom"
                          name="nom"
                          className="form-control crud-form-control"
                          id="name"
                          {...register("nom")}
                        />
                        {formState.errors.nom && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {" "}
                            {formState.errors.nom.message}{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="date_debut">Date d'arrivée</label>

                    <DatePicker
                      className="form-control crud-form-control"
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      onChange={handleDateDebut}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                    />
                    {formState.errors.date_debut && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {formState.errors.date_debut.message}{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="date_fin">Date de fin</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      selected={endDate}
                      onChange={handleDateFin}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                    />
                    {formState.errors.date_fin && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {formState.errors.date_fin.message}{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Spécialité</label>
                    <div className="filter-item-container">
                      <select
                        name="specialite"
                        {...register("specialite")}
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir une spécialité
                        </option>
                        {list_specialites?.length &&
                          list_specialites?.map((sp) => (
                            <option key={sp?.id} value={sp?.id}>
                              {sp?.nom}
                            </option>
                          ))}
                      </select>
                      {formState.errors.specialite && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {formState.errors.specialite.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {prestataire?.ninea && !prestataire?.prenom && (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="ninea">NINEA</label>
                      <input
                        type="text"
                        placeholder="NINEA"
                        name="ninea"
                        className="form-control crud-form-control"
                        id="ninea"
                        maxlength={12}
                        {...register("ninea")}
                      />
                      {formState.errors && formState.errors.ninea && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.ninea?.message}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      type="text"
                      placeholder="HLM Grand Yoff, Villa 241 - Dakar"
                      name="adresse"
                      className="form-control crud-form-control"
                      id="adresse"
                      {...register("adresse")}
                    />
                    {formState.errors.adresse && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {formState.errors.adresse.message}{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="telephone">Téléphone</label>
                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      inputClass="form-control crud-form-control"
                      country={"sn"}
                      onlyCountries={["sn"]}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      countryCodeEditable={false}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    {formState.errors.telephone && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {formState.errors.telephone.message}{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Agréement</label>
                    <div className="filter-item-container">
                      <select
                        name="agreement"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                        {...register("agreement")}
                      >
                        <option>Choisir</option>
                        <option value={true}>OUI</option>
                        <option value={false}>NON</option>
                      </select>
                      {formState.errors.agreement && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {formState.errors.agreement.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="numero_contrat">Num. Contrat</label>
                    <input
                      type="text"
                      placeholder="Numéro contrat"
                      name="num_contrat"
                      className="form-control crud-form-control"
                      id="numero_contrat"
                      {...register("num_contrat")}
                    />
                    {formState.errors.num_contrat && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {formState.errors.num_contrat.message}{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="website">Site web</label>
                    <input
                      type="text"
                      placeholder="www.exemple.com"
                      name="website"
                      className="form-control crud-form-control"
                      id="website"
                      onChange={handleChangeWebsite}
                    />
                    <div className="input-infos-container">
                      <IconlyPack.Danger
                        set="light"
                        primaryColor="gray"
                        className="alerty-icon"
                      />
                      &nbsp;
                      <span className="input-infos">
                        Saisie au format www.exemple.com, l'adresse sera
                        autocomplétée avec le protocole http://
                      </span>
                    </div>
                    {formState.errors.website && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {formState.errors.website.message}{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifierPrestataireModal;
