import React from 'react'
import Header from '../../../Navs/Header';
import NavBarVerticalV2 from '../../navbarVerticalV2/navbarVerticalV2';
// import * as IconlyPack from 'react-iconly';
import ProfileInformation from './ProfileInformation'
import AutresAdmins from './AutresAdmins'

var AdminParametres = () => {
    // var [isPageLoading, setIsPageLoading] = React.useState(true)

    return(
        <div className="dashboard-item-container">
            <div className="container-fluid body-background-dashboard">

                <Header />

                <div className="row">
                    {/* Navbar Desktop  */}
                    <div className="nav-container">
                        <NavBarVerticalV2 />
                    </div>

                    <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
                        <div className="page-body-container">
                            <div className="page-content-container">
                                <div className="page-first-row-container">
                                    <div className="page-title-container">
                                        <h1 className="page-title">Paramétres</h1>
                                    </div>
                                </div>

                                <div className="parent-table-container">
                                    <ProfileInformation />
                                </div> 
                                
                            </div>
                        </div>
                    </div>

                    <div className="m-t-20 offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
                        <div className="page-body-container">
                            <div className="page-content-container">
                                <div className="page-first-row-container">
                                    <div className="page-title-container">
                                        <h1 className="page-title">Paramétres</h1>
                                    </div>
                                </div>

                                <div className="second-parent-table-container">
                                    <AutresAdmins />
                                </div>

                                {/* Modal */}
                                <div className="modal fade easypm-modal add-entreprise-modal" id="AddModalItem" tabIndex="-1" aria-labelledby="AddModalItemLabel" aria-hidden="true">
                                    {/* <CRUDEntrepriseModal /> */}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default AdminParametres;
