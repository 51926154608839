import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
import DemandeRemboursement from "../../../Tables/Assurance/GestionRemboursement/DemandeRemboursement";
import GestionRemboursementBeneficiaireTable from "../../../Tables/Beneficiaire/GestionRemboursementBeneficicaire/GestionRemboursementBeneficiaireTable";
import { useCurrentUser } from "../../../../utils/utils";
import { useQueries, useQuery } from "../../../../Http/http/query";
import _ from "lodash";
import AddRemboursementModal from "./AddRemboursementModal";
// import AjouterUneGestionRemboursement from "../../../Tables/Assurance/GestionRemboursementTable/AjouterUneGestionRemboursement";

var GestionRemboursementBeneficiaire = () => {
  const user = useCurrentUser();
  const query = useQuery(null);
  const [data, setData] = React.useState([]);
  const queries = useQueries([]);
  const reload = React.useCallback(() => {
    query.getData(`/remboursementbybeneficiaire/${user?.id}/`);
  }, [user, query]);
  React.useEffect(() => {
    if (user?.id) {
      reload();
      queries.getData([
        "/prestatairebyassurance/" + user?.assurance?.id + "/",
        "/ayantdroitbybeneficiaire/" + user?.id + "/",
        "/familledesoin/",
      ]);
    }
  }, [user?.id]);
  React.useEffect(() => {
    setData(_.orderBy(query.data, ["date"], ["desc"]));
  }, [query.data]);

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Remboursements</h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target="#DemandeRemboursementBeneficiaire"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Demander un remboursement
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <GestionRemboursementBeneficiaireTable
                    data={data}
                    loading={query.loading && data.length === 0}
                  />
                </div>
                <div
                  class="modal fade"
                  id="DemandeRemboursementBeneficiaire"
                  tabindex="-1"
                  aria-labelledby="DemandeRemboursementBeneficiaireLabel"
                  aria-hidden="true"
                >
                  <AddRemboursementModal
                    prestataires={queries.data[0] || []}
                    ayant_droits={queries.data[1] || []}
                    familledesoins={queries.data[2] || []}
                    user={user}
                    onAdd={(flag) => {
                      if (flag !== false) {
                        reload();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionRemboursementBeneficiaire;
