import React from 'react'
import Skeleton from "react-loading-skeleton";

function EntrepriseSkeleton() {
    return (
        <div className="crud-form">
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Num. Contrat: </strong>

                    <span className="crud-form-control">
                      <Skeleton width={70} />
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="name">Raison sociale: </strong>
                    <span className="crud-form-control">
                      <Skeleton width={70} />
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="ninea">NINEA: </strong>
                    <span className="crud-form-control">
                      <Skeleton width={70} />
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="email">Email: </strong>
                    <span className="crud-form-control">
                      <Skeleton width={70} />
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="adresse">Adresse: </strong>
                    <span className="crud-form-control">
                      <Skeleton width={70} />
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="telephone">Téléphone: </strong>
                    <span className="crud-form-control">
                      <Skeleton width={70} />
                    </span>
                  </div>
                </div>

                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="website">Site web: </strong>
                    <span className="crud-form-control">
                      <Skeleton width={70} />
                    </span>
                  </div>
                </div>

              </div>
            </div>
    )
}

export default EntrepriseSkeleton
