import http from "./http";

export async function create_prestationBeneficiaire(url, data) {
  let formdata = getFormData(data);
  const option = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return http.post(url, formdata, option);
}

export function editfichedesoin(id, data) {
  const formData = getFormData(data);
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return http.put("/fichedesoin/" + id + "/", formData, options);
}

export function fichedesoin(data) {
  const formData = getFormData(data);
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return http.post("/fichedesoin/", formData, options);
}

export function edit_ayant_droit(id, data) {
  const formData = getFormData(data);
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return http.put("/ayantdroit/" + id + "/", formData, options);
}
export function update_user(id, data) {
  const formData = getFormData(data);
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return http.put("/beneficiaire/" + id + "/", formData, options);
}
export function read_user(id) {
  return http.get("/beneficiaire/" + id + "/");
}

export function add_ayant_droit(data) {
  const formData = getFormData(data);
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return http.post("/ayantdroit/", formData, options);
}

export function makeid(length) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
function getFormData(data, files = []) {
  const formData = new FormData();
  Object.keys(data).map((k) => {
    const value = data[k];
    if (files.includes(k)) {
      if (value && value.includes("file:")) {
        let parts = value.split("/");
        let filename = parts[parts.length - 1];
        parts = filename.split(".");
        formData.append(k, {
          uri: value,
          name: `${makeid(60)}.${parts[parts.length - 1]}`,
          type: "multipart/form-data",
        });
      }
    } else {
      formData.append(k, value);
    }
  });
  return formData;
}
