const GestionAssuresTableData = [
    {
        id: 1,
        numero_contrat: '025',
        nom: 'ABDOU FALL',
        entreprise: 'VOLKENO SARL',
        date: '11/09/2021',
        statut: 'actif'
    },
    {
        id: 2,
        numero_contrat: '025',
        nom: 'ABDOU FALL',
        entreprise: 'VOLKENO SARL',
        date: '11/09/2021',
        statut: 'actif'
    },
    {
        id: 3,
        numero_contrat: '025',
        nom: 'ABDOU FALL',
        entreprise: 'VOLKENO SARL',
        date: '11/09/2021',
        statut: 'actif'
    },
    {
        id: 4,
        numero_contrat: '025',
        nom: 'ABDOU FALL',
        entreprise: 'VOLKENO SARL',
        date: '11/09/2021',
        statut: 'actif'
    },
];

export default GestionAssuresTableData;
