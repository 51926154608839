import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import DetailsPrestataireTableSkeleton from "./DetailsPrestataireTableSkeleton";
import * as IconlyPack from "react-iconly";
import { NavLink, useParams } from "react-router-dom";
import DetailsPrestataireTableData from "./DetailsPrestataireTableData";
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'
import { formatMoney } from "../../../../utils/utils";
import { getDataByID } from "../../../utils/fetchData";
import moment from "moment";

var DetailsPrestataireTable = () => {
  let params = useParams();
  let { id } = params;

  let token = window.sessionStorage.getItem("authToken");
  const [prestations, setPrestations] = React.useState([]);

  var [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    if (id) {
      getDataByID("prestationbyprestataire", id, token)
        .then((response) => {
          console.log("prestations", response);
          setPrestations(response.sort((a, b) => b?.id - a?.id));
          setIsPageLoading(false);
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [id]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Show
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".show-soin-modal"
          />
        </button>
      </div>
    );
  };

  // const assuresFormatter = (cell, row) => {
  //   //  let beneficiaire = cell.beneficiaire
  //   return (
  //     <div className="nameFormatter">
  //       <span className="name">{row?.assures}</span> <br />
  //       <strong className="entreprise"> {row?.nom_assures} </strong>{" "}
  //     </div>
  //   );
  // };

  const assuresFormatter = (cell, row) => {
    // let =cell.nom

    return (
      <NavLink
        to={`/assurance/details-assures/${row?.beneficiaire?.id}`}
        className="nameFormatter "
      >
        <span className="name">
          {" "}
          {row?.beneficiaire?.nom || ""} {row?.beneficiaire?.prenom || ""}{" "}
        </span>{" "}
        <br />
        <strong className="entreprise">
          {" "}
          {row?.beneficiaire?.employeur.raison_sociale}{" "}
        </strong>{" "}
        {/* <br />
          <span className="entreprise">
            {" "}
            {row?.beneficiaire?.employeur.num_contrat}{" "}
          </span> */}
      </NavLink>
    );
  };

  const patientFormatter = (cell, row) => {
    return row?.ayant_droit
      ? row?.ayant_droit?.prenom + " " + row?.ayant_droit?.nom
      : row?.beneficiaire?.prenom + " " + row?.beneficiaire?.nom;
  };

  const typeFormatter = (cell, row) => {
    return row?.type_prestation?.libelle;
  };

  const montantFormatter = (cell, row) => {
    return <div>{formatMoney(cell)}</div>;
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <DetailsPrestataireTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={prestations}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={true}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="beneficiaire"
                    dataFormat={(cell, row) => assuresFormatter(cell, row)}
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                  >
                    Assurés
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="ayant_droit"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                    dataFormat={patientFormatter}
                  >
                    Patient(e)
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="date"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                    dataFormat={(cell) => {
                      return moment(cell).format("DD/MM/YYYY");
                    }}
                  >
                    Date de prest..
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="type_prestation"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                    dataFormat={typeFormatter}
                  >
                    Nature
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="right"
                    width="140"
                    dataField="montant_rembourse"
                    dataFormat={(cell, row) => montantFormatter(cell, row)}
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                  >
                    Montant remboursé
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="right"
                    width="140"
                    dataField="cout"
                    dataFormat={(cell, row) => montantFormatter(cell, row)}
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                  >
                    Coût
                  </TableHeaderColumn>
                  {/* <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="fournisseur"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                  >
                    Fournisseurs
                  </TableHeaderColumn> */}
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="90"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPrestataireTable;
