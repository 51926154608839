import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import GestionPrestataireBeneficiaireTableSkeleton from "./GestionPrestataireBeneficiaireTableSkeleton";
import * as IconlyPack from "react-iconly";
import { Link, NavLink } from "react-router-dom";
import GestionPrestataireBeneficiaireTableData from "./GestionPrestataireBeneficiaireTableData";
import ModifierPrestataireModal from "../../../Backend/Beneficiaire/GestionPrestataireBeneficiaire/ModifierPrestataireModal";
import RenvoiPasswordPrestataireModal from "./RenvoiPasswordPrestataireModal";
import { getName, MyButton } from "../../../../utils/utils";
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'

var GestionPrestataireBeneficiaireTable = ({ items, loading, user }) => {
  const [item, setItem] = React.useState(null);
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <span
          data-target="#ModifierPrestataireBeneficiaireModal"
          data-toggle="modal"
        >
          <MyButton
            title="Demander un devis"
            onClick={() => setItem(row)}
            className="btn  crud-submit-btn-assurance"
          />
        </span>
      </div>
    );
  };
  const opt = {};
  for (let i of items) {
    if (i.specialite) {
      opt[i.specialite.nom] = i.specialite.nom;
    }
  }
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {loading && <GestionPrestataireBeneficiaireTableSkeleton />}
            {!loading && (
              <div className="table-container">
                <BootstrapTable
                  data={items}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="nom"
                    filterValue={(_, item) => getName(item)}
                    dataFormat={(_, item) => getName(item)}
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                  >
                    Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataFormat={(_, r) => r.specialite?.nom}
                    dataField="specialite"
                    filterValue={(_, r) => r.specialite?.nom}
                    filter={{
                      type: "SelectFilter",
                      defaultValue: "",
                      options: opt,
                    }}
                  >
                    Spécialité
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="telephone"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                  >
                    Téléphone
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            class="modal fade"
            id="ModifierPrestataireBeneficiaireModal"
            tabindex="-1"
            aria-labelledby="ModifierPrestataireBeneficiaireModalLabel"
            aria-hidden="true"
          >
            <ModifierPrestataireModal
              item={item}
              user={user}
              onDone={() => setItem(null)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionPrestataireBeneficiaireTable;
