const GestionEntrepriseTableData = [
    {
        id: 1,
        raison_sociale: 'VOLKENO SARL',
        ninea: '005836880 2v2',
        email: 'tlo@volkeno.sn',
        telephone: '20 000',
        payant: 'OUI'
    },
    {
        id: 2,
        raison_sociale: 'VOLKENO SARL',
        ninea: '005836880 2v2',
        email: 'tlo@volkeno.sn',
        telephone: '33 000 0000',
        payant: 'NON'
    },
];

export default GestionEntrepriseTableData;
