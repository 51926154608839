import React, { useContext, useState } from 'react'
import { DataContext } from '../../../store/GlobalState'
import AutresAdminsTable from '../../../Tables/AutresAdmins/AutresAdminsTable'
import * as IconlyPack from 'react-iconly'
import CRUDAutresAdminModal from '../../../Modals/CRUDAutresAdminModal'
import UseAddSuperAdminForm from './Requests/UseAddSuperAdminForm'

var AutresAdmins = () => {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    setValue,
    reset
  } = UseAddSuperAdminForm()
  const { state, dispatch } = useContext(DataContext)
  const { admin_ipms } = state

  React.useEffect(() => {
    if (isSuccessfullySubmitted) {
      setTimeout(() => {
        setIsSuccessfullySubmitted(false)
      }, 5000)
    }

    if (isFormRequestError) {
      setIsSuccessfullySubmitted(false)

      setIsFormRequestError(true)
      setTimeout(() => {
        setIsFormRequestError(false)
      }, 5000)
    }

    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key)
        })
      }, 3000)
    }
    // console.log(formState)
  }, [
    isSuccessfullySubmitted,
    isFormRequestError,
    clearErrors,
    formState,
    setIsFormRequestError,
    setIsSuccessfullySubmitted,
  ])

  return (
    <div className="easypm-tabs-item-content-container">
      <div className="easypm-tabs-item">
        <div className="row adding-row">
          <div className="col-md-2 offset-md-10 adding-col-offseting">
            <div
              className="adding-item-container linkable"
              data-toggle="modal"
              data-target=".add-autres-admin-modal"
            >
              <span className="icon-container">
                <IconlyPack.Plus set="light" primaryColor="gray" />
              </span>
              <span className="adding-item-label">
                Ajouter un Admininistrateur
              </span>
            </div>
          </div>
        </div>
        <AutresAdminsTable admin_ipms={admin_ipms} />
      </div>

      {/* Modal */}
      <div
        className="modal fade easypm-modal add-autres-admin-modal"
        id="AddModalItem"
        tabIndex="-1"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
        aria-hidden="true"
      >
        <CRUDAutresAdminModal
          actionType={'create'}
          register={register}
          onSubmit={onSubmit}
          formState={formState}
          isSuccessfullySubmitted={isSuccessfullySubmitted}
          setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
          setValue={setValue}
          reset={reset}
        />
      </div>
    </div>
  )
}

export default AutresAdmins
