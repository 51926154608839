import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import GestionSoinAssureurModal from "../../../Backend/Assurance/GestionSoinsAssureur/GestionSoinAssureur";
import GestionAssuresTableData from "./GestionAssuresTableData";
import GestionAssuresTableSkeleton from "./GestionAssuresTableSkeleton";
import FeatherIcon from "feather-icons-react";
// import Photo from "../../../../images/others/photo.png";
import DefaultAvatar from "../../../../images/others/default-profile-avatar.png";
import GestionAssureSoin from "./GestionAssureSoin";
import { DataContext } from "../../../store/GlobalState";
import baseUrl from "../../../../Http/backend-base-url";
import moment from "moment";
import Swal from "sweetalert2";
import ModifierAssure from "../../../Backend/Assurance/GestionAssures/ModifierAssure";
import ValidBeneficiaireModal from "../../../Modals/ValidBeneficiaireModal";
import RegenerePwdModal from "../../../Modals/RegenerePwdModal";
import ImportCsvBeneficiaire from "../../../Modals/ImportCsvBeneficiaireModal";
import CSVModel from "../../../../modele-import-files/modele_csv_import_beneficiaires.csv";
import GenereFicheModal from "../../../Modals/GenereFicheModal";
import FicheSoinGenerate from "../../../Backend/Assurance/GestionAssures/FicheSoinGenerate";

var GestionAssuresTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const { assureur_beneficiaires } = state;
  const [soins, setSoins] = React.useState(GestionAssuresTableData);
  var [selected, setSelected] = React.useState({});
  var [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, [soins]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button
          className="action-btn show-btn"
          title={row?.is_active ? "Désactivation" : "Activation"}
        >
          {row.is_active ? (
            <IconlyPack.TickSquare
              set="light"
              primaryColor="rgb(2, 166, 156)"
              className="action-btn-icon action-show-icon"
              data-toggle="modal"
              data-target="#ActiveAssureModal"
              onClick={() => onGetItem(row)}
            />
          ) : (
            <IconlyPack.CloseSquare
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-show-icon"
              data-toggle="modal"
              data-target="#ActiveAssureModal"
              onClick={() => onGetItem(row)}
            />
          )}
        </button>
        <button
          className="action-btn show-btn"
          title="Génèrer une fiche de soins"
        >
          {moment(new Date()).isSameOrBefore(new Date(row?.date_fin)) &&
          row?.is_active ? (
            <i
              className="fa fa-qrcode fa-lg action-btn-icon action-show-icon"
              aria-hidden="true"
              data-toggle="modal"
              data-target="#GenererFicheSoinModal"
              onClick={() => onGetItem(row)}
              style={{ marginTop: "0.3rem", color: "#c8c8c8" }}
            ></i>
          ) : (
            <i
              className="fa fa-qrcode fa-lg action-btn-icon action-show-icon"
              aria-hidden="true"
              onClick={() => getWarning(row)}
              style={{ marginTop: "0.3rem", color: "#c8c8c8" }}
            ></i>
          )}
        </button>

        <NavLink
          to={{
            pathname: `/assurance/details-assures/${row.id}`,
            state: { assure: row },
          }}
          className="action-btn show-btn"
          title="Details"
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
          />
        </NavLink>

        <button
          className="action-btn edit-btn"
          onClick={() => onGetItem(row)}
          data-toggle="modal"
          data-target="#ModifierAssureModal"
          title="Modification"
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
        <button
          className="action-btn edit-btn"
          onClick={() => onGetItem(row)}
          data-toggle="modal"
          data-target="#RegenerePwdModal"
          title="Renvoi de mot de passe"
        >
          <IconlyPack.Password
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
      </div>
    );
  };

  const detailFormatter = (cell, row, id) => {
    return (
      <div>
        <NavLink
          to={{
            pathname: `/assurance/details-assures/${row.id}`,
            state: { assure: row },
          }}
          style={{ color: "rgba(0, 0, 0, 0.7)" }}
          className="no-link"
        >
          {cell}
        </NavLink>
      </div>
    );
  };
  const etatFormatter = (cell, row) => {
    if (cell === true) {
      return (
        <span className="badge badge-success easypm-badge-success">Actif</span>
      );
    } else {
      return (
        <span className="badge badge-danger easypm-badge-danger">Inactif</span>
      );
    }
  };

  const nameFormater = (cell, row) => {
    return (
      <NavLink
        to={{
          pathname: `/assurance/details-assures/${row.id}`,
          state: { assure: row },
        }}
        className="nameFormatter"
      >
        <span className="name"> {row?.prenom} </span> <br />
      </NavLink>
    );
  };

  const nameFilter = (cell, row) => {
    //console.log('row', row)
    return row?.prenom;
  };

  const nomFilter = (cell, row) => {
    //console.log('row', row)
    return cell;
  };
  // const photoFormatter = (cell, row) => {
  //   //  let beneficiaire = cell.beneficiaire
  //   return (
  //     <div className="nameFormatter">
  //       <img src={Photo} className="photo-assures" />
  //       {cell}
  //     </div>
  //   );
  // };

  const EntrepriseFormatter = (cell, row) => {
    return (
      <NavLink
        to={{
          pathname: `/assurance/details-assures/${row.id}`,
          state: { assure: row },
        }}
        className="nameFormatter"
      >
        <span className="name"> {row?.employeur?.raison_sociale} </span>
      </NavLink>
    );
  };

  const DateFormatter = (cell, row) => {
    return moment(new Date(cell)).format("DD/MM/YYYY");
  };

  const photoFormatter = (cell, row) => {
    return (
      <img
        src={
          !row?.avatar || row?.avatar.includes("default.png")
            ? DefaultAvatar
            : baseUrl + row?.avatar
        }
        alt="Profile"
        style={{ width: "50px", height: "50px", borderRadius: "100%" }}
      />
    );
  };

  const onGetItem = (item) => {
    setSelected(item);
    dispatch({
      type: "SHOW_BENEFICIAIRE",
      payload: item,
    });
  };

  const getWarning = (item) => {
    if (item?.is_active === false) {
      Swal.fire({
        title: "Cet assuré n'est pas actif.",
        text: "Veuillez l'activer avant de génèrer une fiche de soins.",
        icon: "warning",
        // showLoaderOnConfirm: true,
      });
    } else {
      Swal.fire({
        title: "La date de fin de validité de cet assuré est dépassée.",
        icon: "warning",
        // showLoaderOnConfirm: true,
      });
    }
  };

  const optionFilter = {
    true: "Actif",
    false: "Inactif",
  };
  return (
    <div className="child-table-container">
      <div className="row justify-content-end py-4">
        <div className="col-xl-6 col-lg-6 col-md-4"></div>
        <div className="col-xl-3 col-lg-3 col-md-4 mt-3">
          <a
            href={CSVModel}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-assure-telecharger"
          >
            <IconlyPack.Download
              set="light"
              primaryColor="#fff"
              className="action-btn-icon action-edit-icon"
            />
            <span className="pl-2">Télécharger le modèle CSV</span>
          </a>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-4">
          <button
            className="btn-assure-import"
            data-toggle="modal"
            data-target="#CsvModalItem"
          >
            <IconlyPack.Upload
              set="light"
              primaryColor="#005D86"
              className="action-btn-icon action-edit-icon"
            />
            <span className="pl-2">Import CSV</span>
          </button>
        </div>
      </div>
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {assureur_beneficiaires?.isLoading && (
              <GestionAssuresTableSkeleton />
            )}
            {!assureur_beneficiaires?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={assureur_beneficiaires?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={false}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="100"
                  >
                    N°.Assuré
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="80"
                    dataField="photo"
                    dataFormat={(cell, row) => photoFormatter(cell, row)}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                  >
                    Photo
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="prenom"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Prénom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="140"
                    dataField="nom"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                    filterValue={nomFilter}
                    dataFormat={(cell, row) => detailFormatter(cell, row)}
                  >
                    Nom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    // width="150"
                    dataField="employeur"
                    dataFormat={(cell, row) => EntrepriseFormatter(cell, row)}
                  >
                    Entreprise
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="date_fin"
                    dataFormat={(cell, row) => DateFormatter(cell, row)}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                  >
                    Date de fin
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                    dataField="is_active"
                    filter={{ type: "SelectFilter", options: optionFilter }}
                    dataFormat={(cell, row) => etatFormatter(cell, row)}
                  >
                    Statut
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal"
            id="ModifierAssureModal"
            tabIndex="-1"
            aria-labelledby="ModifierAssureModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <ModifierAssure />
          </div>
          {/* Modal validation*/}
          <div
            className="modal fade easypm-modal"
            id="ActiveAssureModal"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="ActiveAssureModalLabel"
            aria-hidden="true"
          >
            <ValidBeneficiaireModal />
          </div>
          {/* Modal regeneration de mot de passe*/}
          <div
            className="modal fade easypm-modal"
            id="RegenerePwdModal"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="RegenerePwdModalItemLabel"
            aria-hidden="true"
          >
            <RegenerePwdModal />
          </div>

          {/* Modal import*/}
          <div
            className="modal fade easypm-modal"
            id="CsvModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="CsvModalItemLabel"
            aria-hidden="true"
          >
            <ImportCsvBeneficiaire />
          </div>
          <div
            className="modal fade easypm-modal add-fiche-modal"
            id="GenererFicheSoinModal"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="GenererFicheSoinModalLabel"
            aria-hidden="true"
          >
            <FicheSoinGenerate />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionAssuresTable;
