import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import FacturePdfModal from "../../../../Modals/FacturePdfModal";
import Header from "../../../../Navs/Header";
import FacturationFounisseurTable from "../../../../Tables/Assurance/FacturationFournisseur/FacturationFournisseurTable";
import NavbarVerticalV2 from "../../../navbarVerticalV2/navbarVerticalV2";
import GenererFactureFournisseurModal from "./GenererFactureFournisseurModal";
var FacturationFournisseur = () => {
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Facturation des fournisseurs</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <FacturationFounisseurTable />
                </div>
                <div
                  class="modal fade"
                  id="GenererFactureFournisseurModal"
                  tabindex="-1"
                  data-backdrop="static"
                  aria-labelledby="GenererFactureFournisseurModalLabel"
                  aria-hidden="true"
                >
                  <GenererFactureFournisseurModal />
                </div>
                <div
                  class="modal fade"
                  id="FacturePdfModal"
                  tabindex="-1"
                  data-backdrop="static"
                  aria-labelledby="FacturePdfModalLabel"
                  aria-hidden="true"
                >
                  <FacturePdfModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacturationFournisseur;
