import React, { useEffect, useState } from "react";

import * as IconlyPack from "react-iconly";

import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import { NavLink } from "react-router-dom";
import ImgProfilRecu from '../../../../images/others/message-contact.png'
import ImgProfilEnvoyer from '../../../../images/others/photo.png'

import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import AttachFileIcon from '@material-ui/icons/AttachFile';

var NouveauMessage = () => {


  return (
    <div className="dashboard-item-container">
    <div className="container-fluid body-background-dashboard">
      <Header />

      <div className="row">
        {/* Navbar Desktop  */}
        <div className="nav-container nav-assurance-color">
          <NavbarVerticalV2  />
        </div>


          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container pd-page">
                  <div className="page-title-container">
                    <h1 className="page-title"> 
                    <NavLink to="/contacter-beneficiaire">
                    <IconlyPack.CaretLeft set="bold" primaryColor="#000" className="pr-2"/>
                    </NavLink>
                    Contacts</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="pt-4">
                    <div className="text-center pb-5">
                        <h4 className="title-nom-destinateaire">Alioune Diallo</h4>
                    </div>
                    <div className="row pb-3 align-items-center">
                        <div className="col-xl-1 col-lg-1 col-md-2">
                        <div className="d-flex">
                                    <img src={ImgProfilRecu} className="img-message-contact" alt="Profil contact" />
                              </div>
                        </div>
                        <div className="col-xl-11 col-lg-11 col-md-10">
                            <div className="bloc-message-recu">
                                <p className="text-message-contact-recu">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate posuere urna, nisl integer lectus vitae. Vitae ut amet nunc maecenas proin. Vel ac nisi, urna velit neque, nunc suspendisse. Faucibus proin pellentesque penatibus purus. Sit ut sem scelerisque aenean. Dui ridiculus egestas laoreet erat vivamus in a faucibus. Hendrerit ipsum massa cras turpis. Urna praesent nisi aenean nulla nunc donec nisl aliquet lacus. Facilisi eleifend adipiscing ultrices nam. Vehicula commodo cursus luctus praesent commodo.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-3 align-items-center">
                        <div className="col-xl-11 col-lg-11 col-md-10">
                            <div className="bloc-message-envoyer">
                                <p className="text-message-contact-envoyer">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate posuere urna, nisl integer lectus vitae. Vitae ut amet nunc maecenas proin. Vel ac nisi, urna velit neque, nunc suspendisse. Faucibus proin pellentesque penatibus purus. Sit ut sem scelerisque aenean. Dui ridiculus egestas laoreet erat vivamus in a faucibus. Hendrerit ipsum massa cras turpis. Urna praesent nisi aenean nulla nunc donec nisl aliquet lacus. Facilisi eleifend adipiscing ultrices nam. Vehicula commodo cursus luctus praesent commodo.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-2">
                        <div className="d-flex">
                                    <img src={ImgProfilEnvoyer} className="img-message-contact" alt="Profil contact" />
                              </div>
                        </div>
                    </div>
                    <div className="row pb-3 align-items-center">
                        <div className="col-xl-1 col-lg-1 col-md-2">
                        <div className="d-flex">
                                    <img src={ImgProfilRecu} className="img-message-contact" alt="Profil contact" />
                              </div>
                        </div>
                        <div className="col-xl-11 col-lg-11 col-md-10">
                            <div className="bloc-message-recu">
                                <p className="text-message-contact-recu">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate posuere urna, nisl integer lectus vitae. Vitae ut amet nunc maecenas proin. Vel ac nisi, urna velit neque, nunc suspendisse. Faucibus proin pellentesque penatibus purus. Sit ut sem scelerisque aenean. Dui ridiculus egestas laoreet erat vivamus in a faucibus. Hendrerit ipsum massa cras turpis. Urna praesent nisi aenean nulla nunc donec nisl aliquet lacus. Facilisi eleifend adipiscing ultrices nam. Vehicula commodo cursus luctus praesent commodo.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-3 align-items-center">
                        <div className="col-xl-11 col-lg-11 col-md-10">
                            <div className="bloc-message-envoyer">
                                <p className="text-message-contact-envoyer">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate posuere urna, nisl integer lectus vitae. Vitae ut amet nunc maecenas proin. Vel ac nisi, urna velit neque, nunc suspendisse. Faucibus proin pellentesque penatibus purus. Sit ut sem scelerisque aenean. Dui ridiculus egestas laoreet erat vivamus in a faucibus. Hendrerit ipsum massa cras turpis. Urna praesent nisi aenean nulla nunc donec nisl aliquet lacus. Facilisi eleifend adipiscing ultrices nam. Vehicula commodo cursus luctus praesent commodo.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-2">
                        <div className="d-flex">
                                    <img src={ImgProfilEnvoyer} className="img-message-contact" alt="Profil contact" />
                              </div>
                        </div>
                    </div>
                    
                    <div className="py-5 mt-5">
                        <form>
                            <div className="input-chat position-relative">
                                <span className="icon-emoticon"><InsertEmoticonIcon /></span>
                                <span className="icon-attach-file"><AttachFileIcon /></span>
                                <input className="input-message form-control padd-input-message" type="text" name="commentaire" placeholder="Votre message" id="commentaire" />
                                    <button className="icon-send-form " style={{ backgroundColor: 'transparent' }}><IconlyPack.Send set="bold" primaryColor="#005D86" /></button>
                            </div>
                        </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NouveauMessage;