import { beneficiaire } from './Endpoints'
import axios from 'axios'

export default {
    async getListBeneficiaires(config) {
        const response = await axios.get(beneficiaire.onGetOrPostBeneficiaire(), config);
        // console.log(response)
        return response.data;
    },
    async postCreateBeneficiaire(data, config) {
        const response = await axios.post(beneficiaire.onGetOrPostBeneficiaire(), data, config);
        return response.data;
    },
    async getBeneficiaire(id, config) {
        const response = await axios.get(`${beneficiaire.onGetBeneficiaire(id)}`, config);
        // console.log(response)
        return response.data;
    },
    async editBeneficiaire(id, data, config) {
        const response = await axios.put(`${beneficiaire.onEditBeneficiaire(id)}`, data, config);
        // console.log(response)
        return response.data;
    }, 
    async getBeneficiairesByEntreprise(entrepriseID, config) {
        const response = await axios.get(`${beneficiaire.onGetBeneficiaireByEntreprise(entrepriseID)}`, config);
        // console.log(response)
        return response.data;
    }, 
    async postImportCsv(data, config) {
        const response = await axios.post(beneficiaire.onImportCsv(), data, config);
        console.log('post csv', response)
        return response.data;
    },
    async deleteBeneficiaire(id, config) {
        const response = await axios.delete(beneficiaire.onDeleteBeneficiaire(id), config);
        return response;
    },
    async archiveBeneficiaire(id, config) {
        const response = await axios.get(beneficiaire.onArchiveBeneficiaire(id), config);
        return response;
    },  

}
