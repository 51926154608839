import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const SyRendreModal = (props) => {

  return (
    <div className="modal-dialog easypm-modal-dialog modal-xl">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="syRendreModal">
            S'y rendre
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
                <div style={{ height: "100vh", width: "96%" }} className="div-map-container">
                    <Map
                        google={props.google}
                        zoom={8}
                        initialCenter={{ lat: 15.7410093, lng: -18.0 }}
                        >
                        <Marker position={{ lat: 14.7410093, lng: -17.444037 }} />
                    </Map>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyAecIAfR8y0X-PtKvBUbK54vlhnRN3qK4E",
  })(SyRendreModal);
