import React, { useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
import { DataContext } from "../store/GlobalState";
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

var CRUDAdminPrestataireModal = (props) => {
  const { state } = useContext(DataContext);
  const { list_specialites } = state;
  const [personne, setPersonne] = React.useState("personne_morale");
  const [phone, setPhone] = React.useState("");
  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());
  const [isValid, setIsValid] = React.useState(true);

  const handleReset = () => {
    props.reset();
  };

  React.useEffect(() => {
    props.register("date_debut");
    props.setValue("date_debut", moment(startDate).format("YYYY-MM-DD"));

    props.register("date_fin");
    props.setValue("date_fin", moment(endDate).format("YYYY-MM-DD"));
  }, [startDate, endDate]);

  const handleDateDebut = (date) => {
    setStartDate(date);
    props.register("date_debut");
    props.setValue("date_debut", moment(date).format("YYYY-MM-DD"));
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      props.register("date_fin");
      props.setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };

  const handleSetPhone = (val) => {
    // console.log('phone', val)
    setPhone(val);
    props.register("telephone");
    props.setValue("telephone", "+" + val);
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Établissements et pharmacies
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={props.onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 type-presta-container">
                  <div className="type-presta-label-container col-md-4">
                    <span className="type-presta-label">
                      {" "}
                      Le prestataire est une{" "}
                    </span>
                  </div>
                  <div className="type-presta-label-container col-md-8 type-presta-label-col">
                    <div className="type-presta-input-radio-container">
                      <input
                        type="radio"
                        onChange={(e) => setPersonne(e.target.value)}
                        className="easypm-input-radio input-radio-form-control"
                        name="user_type"
                        value="personne_physique"
                      />
                      <label className="input-radio-item-label">
                        une personne physique
                      </label>
                    </div>
                    <div className="type-presta-input-radio-container">
                      <input
                        type="radio"
                        onChange={(e) => setPersonne(e.target.value)}
                        className="easypm-input-radio input-radio-form-control"
                        name="user_type"
                        value="personne_morale"
                      />
                      <label className="input-radio-item-label">
                        une personne morale
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="numero_contrat">Num. Contrat</label>
                    <input
                      type="text"
                      placeholder="Numéro contrat"
                      name="num_contrat"
                      className="form-control crud-form-control"
                      id="numero_contrat"
                      {...props.register("num_contrat")}
                    />
                  </div>
                </div>
                {personne === "personne_morale" && (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group required crud-form-group">
                      <label htmlFor="name">Nom</label>
                      <input
                        type="text"
                        placeholder="Nom"
                        name="raison_sociale"
                        className="form-control crud-form-control"
                        id="name"
                        {...props.register("raison_sociale")}
                      />

                      {props.formState.errors &&
                        props.formState.errors.raison_sociale && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.raison_sociale?.message}
                          </div>
                        )}
                    </div>
                  </div>
                )}

                {personne === "personne_physique" && (
                  <>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group required crud-form-group">
                        <label htmlFor="prenom">Prénom</label>
                        <input
                          type="text"
                          placeholder="Prénom"
                          name="prenom"
                          className="form-control crud-form-control"
                          id="prenom"
                          {...props.register("prenom")}
                        />
                        {props.formState.errors &&
                          props.formState.errors.prenom && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {props.formState.errors.prenom?.message}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group required crud-form-group">
                        <label htmlFor="nom">Nom</label>
                        <input
                          type="text"
                          placeholder="Nom"
                          name="nom"
                          className="form-control crud-form-control"
                          id="nom"
                          {...props.register("nom")}
                        />
                        {props.formState.errors &&
                          props.formState.errors.nom && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {props.formState.errors.nom?.message}
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                )}
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Spécialité</label>
                    <div className="filter-item-container">
                      <select
                        name="specialite"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                        {...props.register("specialite")}
                      >
                        <option hidden value="">
                          Choisir une spécialité
                        </option>
                        {list_specialites?.length &&
                          list_specialites?.map((sp) => (
                            <option key={sp?.id} value={sp?.id}>
                              {sp?.nom}
                            </option>
                          ))}
                      </select>

                      {props.formState.errors &&
                        props.formState.errors.specialite && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.specialite?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="ninea">NINEA</label>
                    <input
                      type="text"
                      placeholder="NINEA"
                      name="ninea"
                      maxlength={12}
                      className="form-control crud-form-control"
                      id="ninea"
                      {...props.register("ninea")}
                    />
                    {props.formState.errors && props.formState.errors.ninea && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {props.formState.errors.ninea?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      placeholder="exemple@exemple.com"
                      name="email"
                      className="form-control crud-form-control"
                      id="email"
                      {...props.register("email")}
                    />
                    {props.formState.errors && props.formState.errors.email && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {props.formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      type="text"
                      placeholder="HLM Grand Yoff, Villa 241 - Dakar"
                      name="adresse"
                      className="form-control crud-form-control"
                      id="adresse"
                      {...props.register("adresse")}
                    />
                    {props.formState.errors &&
                      props.formState.errors.adresse && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.adresse?.message}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="telephone">Téléphone</label>
                    {/* <input
                      type="text"
                      placeholder="+221780158171"
                      name="telephone"
                      className="form-control crud-form-control"
                      id="telephone"
                      {...props.register('telephone')}
                    />
                    <div className="input-infos-container">
                      <IconlyPack.Danger
                        set="light"
                        primaryColor="gray"
                        className="alerty-icon"
                      />
                      &nbsp;
                      <span className="input-infos">
                        Le numéro de téléphone doit être au format +221780158171
                      </span>
                    </div> */}
                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      inputClass="form-control crud-form-control"
                      country={"sn"}
                      onlyCountries={["sn"]}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    {props.formState.errors &&
                      props.formState.errors.telephone && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.telephone?.message}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="first_name">Agréement</label>
                    <div className="filter-item-container">
                      <select
                        name="agreement"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                        {...props.register("agreement")}
                      >
                        <option hidden value="">
                          Choisir
                        </option>
                        <option value={true}>OUI</option>
                        <option value={false}>NON</option>
                      </select>

                      {props.formState.errors &&
                        props.formState.errors.agreement && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.agreement?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="date_debut">Date de début</label>

                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                      onChange={(date) => handleDateDebut(date)}
                    />
                    {props.formState.errors &&
                      props.formState.errors.date_debut && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.date_debut?.message}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="date_fin">Date de fin</label>
                    {/* <input
                        {...props.register('date_fin')}
                        type="date"
                        name="date_fin"
                        className="form-control crud-form-control"
                        id="date_fin"
                      /> */}
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={endDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                      onChange={(date) => handleDateFin(date)}
                    />
                    {props.formState.errors &&
                      props.formState.errors.date_fin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.date_fin?.message}
                        </div>
                      )}

                    {!isValid && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        La date de fin ne peut pas être avant la date de début.
                      </div>
                    )}
                  </div>
                </div>

                <input
                  {...props.register("user_type")}
                  type="hidden"
                  name="user_type"
                  value="prestataire"
                />

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    onClick={() => handleReset()}
                    data-dismiss="modal"
                  >
                    Retour
                  </button>

                  {props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRUDAdminPrestataireModal;
