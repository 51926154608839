import React, { useState } from "react";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";

var CalendrierRdvPrestataire = ({ value, onChange }) => {
  return (
    <div>
      <p className="courant-date-rdv">
        {value ? format(value, "dd MMM yyyy", { locale: enGB }) : value}.
      </p>
      <DatePickerCalendar
        date={value}
        onDateChange={(d) => onChange(d)}
        locale={enGB}
      />
    </div>
  );
};
export default CalendrierRdvPrestataire;
