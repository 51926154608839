import React, { useContext, useState } from "react";
import { DataContext } from "../store/GlobalState";
import Beneficiaire from "../Services/Beneficiaire";
import { toast } from "react-toastify";
import { getDataByID, getData } from "../utils/fetchData";

const RegenerePwdModal = () => {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, beneficiaire, entreprise, prestataire, admin_ipm } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const handleValid = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let id = null;
    if (beneficiaire?.id) {
      id = beneficiaire?.id;
    }

    if (entreprise?.id) {
      id = entreprise?.id;
    }

    if (prestataire?.id) {
      id = prestataire?.id;
    }

    if (admin_ipm?.id) {
      id = admin_ipm?.id;
    }

    getDataByID("regeneratepwduser", id, auth?.token)
      .then((res) => {
        console.log("res", res);
        toast.success("Nouveau mot de passe envoyé avec succés.");
        window.$(".regenere-pwd-modal").modal("hide");
        window.$("#RegenerePwdModal").modal("hide");
        handleReset();
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setIsLoading(false);
      });
  };

  const handleReset = () => {
    dispatch({ type: "SHOW_BENEFICIAIRE", payload: {} });
    dispatch({ type: "EDIT_ENTREPRISE", payload: {} });
    dispatch({ type: "EDIT_PRESTATAIRE", payload: {} });
    dispatch({ type: "EDIT_ADMIN_IPM", payload: {} });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="DeletingFamilyModal"
          >
            Renvoi de mot de passe
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="deleting-family-form-container">
            <div className="alert-delete-message">
              <span>
                Veuillez confirmer le renvoi de mot de passe à{" "}
                {beneficiaire?.id
                  ? beneficiaire?.prenom + " " + beneficiaire?.nom
                  : entreprise?.id
                  ? entreprise?.raison_sociale
                  : prestataire?.id
                  ? prestataire?.raison_sociale ||
                    prestataire?.prenom + " " + prestataire?.nom
                  : admin_ipm?.id
                  ? admin_ipm?.prenom + " " + admin_ipm?.nom
                  : null}
              </span>
            </div>

            <div className="row delete-row">
              <div className="col-md-10 offset-md-2 crud-form-action-btn-container">
                <button
                  onClick={() => handleReset()}
                  className="btn btn-default cancelleb-btn delete-actions"
                  data-dismiss="modal"
                >
                  Retour
                </button>
                {!isLoading ? (
                  <button
                    className="btn btn-primary crud-submit-btn delete-actions"
                    onClick={handleValid}
                  >
                    Valider
                  </button>
                ) : (
                  <button className="btn btn-primary crud-submit-btn delete-actions">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegenerePwdModal;
