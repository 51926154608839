import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import { NavLink, useParams } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import moment from "moment";
import { DataContext } from "../../store/GlobalState";
import PrestationSkeleton from "./PrestationSkeleton";
import { getDataByID } from "../../utils/fetchData";

const Prestation = (props) => {
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const { state, dispatch } = useContext(DataContext);
  const { auth, prestationbyprestataire } = state;
  const params = useParams();
  useEffect(() => {
    if (params?.id && auth.token && auth?.user?.id) {
      setIsPageLoading(false);
      getDataByID("prestationbyprestataire", params?.id, auth.token)
        .then((res) => {
          dispatch({
            type: "LISTE_PRESTATATION_BY_PRESTATAIRE",
            payload: res.sort((a, b) => a?.id - b?.id).reverse(),
          });
        })
        .catch((e) => {
          console.log("e", e);
        });
    }
  }, [params?.id, auth]);
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const getNavLinkClass = (path) => {
    return props.location === path ? "active" : "";
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".show-prestation-modal"
            onClick={() => getItem(row)}
          />
        </button>
        {/* {props?.user?.user_type !== "entreprise" && (
          <button className="action-btn edit-btn">
            <IconlyPack.Edit
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-edit-icon"
              data-toggle="modal"
              data-target=".edit-soin-modal"
              onClick={() => getItem(row)}
            />
          </button>
        )} */}
      </div>
    );
  };

  const getItem = (item) => {
    // console.log("item==>", item);
    dispatch({
      type: "EDIT_PRESTATION",
      payload: item,
    });
  };

  const currencyFormatter = (cell) => {
    // const num = 1234567890.1234;
    const formatConfig = {
      style: "currency",
      currency: "XOF",
      minimumFractionDigits: 2,
      currencyDisplay: "symbol",
      currencySign: "accounting",
    };

    // setup formatters
    const xofNumberFormatter = new Intl.NumberFormat("sn-SN", formatConfig);
    return xofNumberFormatter.format(cell);
  };

  // const getEmployeurNamebyId = (id) => {
  //     let name = ""
  //     for (let i = 0; i < props.entreprises.length; i++) {
  //         const element = props.entreprises[i];

  //         if (element.id === id) {
  //             name = element.nom
  //         }
  //     }
  //     return name;
  // }

  const beneficaireFormatter = (cell, row) => {
    // let =cell.nom
    if (props?.user?.user_type !== "entreprise") {
      return (
        <NavLink
          to={`/ipm/profil-beneficiaire/${row?.beneficiaire?.id}`}
          className={
            "nameFormatter " + getNavLinkClass("/ipm/gestion-des-beneficiaires")
          }
        >
          <span className="name">
            {" "}
            {row?.beneficiaire?.nom || ""} {row?.beneficiaire?.prenom || ""}{" "}
          </span>{" "}
          <br />
          <strong className="entreprise">
            {" "}
            {row?.beneficiaire?.employeur.raison_sociale}{" "}
          </strong>{" "}
          <br />
          <span className="entreprise">
            {" "}
            {row?.beneficiaire?.employeur.num_contrat}{" "}
          </span>
        </NavLink>
      );
    } else {
      return (
        <NavLink
          to={`/entreprise/profil-employes/${row?.beneficiaire?.id}`}
          className={
            "nameFormatter " +
            getNavLinkClass("/entreprise/gestion-des-employes")
          }
        >
          <span className="name">
            {" "}
            {row?.beneficiaire?.nom || ""} {row?.beneficiaire?.prenom || ""}{" "}
          </span>{" "}
          <br />
          <strong className="entreprise">
            {" "}
            {row?.beneficiaire?.employeur.raison_sociale}{" "}
          </strong>{" "}
          <br />
          <span className="entreprise">
            {" "}
            {row?.beneficiaire?.employeur.num_contrat}{" "}
          </span>
        </NavLink>
      );
    }
  };

  const cellFournisseurFormatter = (cell, row) => {
    let raison_sociale = row?.prestataire?.raison_sociale
      ? row?.prestataire?.raison_sociale
      : row?.prestataire?.prenom + " " + row?.prestataire?.nom || " ";
    return raison_sociale;
  };

  const montantFormatter = (cell, row) => {
    const formatConfig = {
      style: "currency",
      currency: "XOF",
      minimumFractionDigits: 2,
      currencyDisplay: "symbol",
      currencySign: "accounting",
    };

    // setup formatters
    const xofNumberFormatter = new Intl.NumberFormat("sn-SN", formatConfig);
    return xofNumberFormatter.format(row?.montant_rembourse);
  };

  const nameFilter = (cell, row) => {
    return (
      cell?.prenom +
      " " +
      row?.beneficiaire?.nom +
      " " +
      row?.beneficiaire?.employeur?.raison_sociale
    );
  };

  const typeFormatter = (cell, row) => {
    return row?.type_prestation?.libelle;
  };

  // const etatFormatter = (cell, row) => {
  //   if (props?.user?.user_type !== "entreprise") {
  //     if (cell === "new") {
  //       return (
  //         <span
  //           data-toggle="modal"
  //           data-target=".show-fiche-modal"
  //           className="badge badge-danger easypm-badge-danger linkable"
  //           onClick={() => getItem(row)}
  //         >
  //           nouveau
  //         </span>
  //       );
  //     }
  //     if (cell === "en_cours") {
  //       return (
  //         <span
  //           data-toggle="modal"
  //           data-target=".show-fiche-modal"
  //           className="badge badge-warning easypm-badge-warning linkable"
  //           onClick={() => getItem(row)}
  //         >
  //           en cours
  //         </span>
  //       );
  //     }
  //     if (cell === "traite") {
  //       return (
  //         <span
  //           data-toggle="modal"
  //           data-target=".show-fiche-modal"
  //           className="badge badge-success easypm-badge-success linkable"
  //           onClick={() => getItem(row)}
  //         >
  //           Traité
  //         </span>
  //       );
  //     }
  //   } else {
  //     if (cell === "new") {
  //       return (
  //         <span className="badge badge-danger easypm-badge-danger linkable">
  //           nouveau
  //         </span>
  //       );
  //     }
  //     if (cell === "en_cours") {
  //       return (
  //         <span className="badge badge-warning easypm-badge-warning linkable">
  //           en cours
  //         </span>
  //       );
  //     }
  //     if (cell === "traite") {
  //       return (
  //         <span className="badge badge-success easypm-badge-success linkable">
  //           Traité
  //         </span>
  //       );
  //     }
  //   }
  // };

  const patientFormatter = (cell, row) => {
    return row?.ayant_droit
      ? row?.ayant_droit?.prenom + " " + row?.ayant_droit?.nom
      : row?.beneficiaire?.prenom + " " + row?.beneficiaire?.nom;
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <PrestationSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={prestationbyprestataire}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={true}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="300"
                    dataField="beneficiaire"
                    filter={{
                      type: "TextFilter",
                      placeholder: "Chercher un Adhérent ou une entreprise",
                    }}
                    filterValue={nameFilter}
                    dataFormat={(cell, row) => beneficaireFormatter(cell, row)}
                  >
                    {props?.user?.user_type !== "entreprise"
                      ? "Adhérents"
                      : "Employés(es)"}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="250"
                    dataField="ayant_droit"
                    dataFormat={(cell, row) => patientFormatter(cell, row)}
                  >
                    Patient(e)
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="300"
                    dataField="date"
                    dataFormat={(cell) => {
                      return moment(cell).format("DD/MM/YYYY");
                    }}
                  >
                    Date de prestation
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="300"
                    dataField="type_prestation"
                    dataFormat={(row, cell) => typeFormatter(row, cell)}
                  >
                    Nature de la prestation
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="300"
                    dataField="montant_rembourse"
                    dataFormat={(cell, row) => montantFormatter(cell, row)}
                  >
                    Montant remboursé
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="300"
                    dataField="montant"
                    dataFormat={(cell) => currencyFormatter(cell)}
                  >
                    Coût de la prestation
                  </TableHeaderColumn>

                  {/* <TableHeaderColumn
                    thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D' }}
                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)' }}
                    // width="300" dataField='montant_retenu' dataFormat={(cell) => currencyFormatter(cell)}>
                        Montant retenu
                </TableHeaderColumn> */}

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="300"
                    dataField="prestataire"
                    dataFormat={(cell, row) =>
                      cellFournisseurFormatter(cell, row)
                    }
                  >
                    Fournisseurs
                  </TableHeaderColumn>
                  {/* <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="200"
                    dataField="etat"
                    dataFormat={(cell, row) => etatFormatter(cell, row)}
                  >
                    Statut
                  </TableHeaderColumn> */}
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      padding: 15,
                    }}
                    // width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prestation;
