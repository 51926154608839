import React, { useContext, useEffect } from "react";
import UseCreateAyantDroit from "./UseCreateAyantDroit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import UseEditAyantDroit from "./UseEditAyantDroit";
import { DataContext } from "../../../../store/GlobalState";
registerLocale("fr", fr);

const EditEnfant = ({ beneficiaire, getAyantDroit }) => {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    unregister,
    isSuccess,
  } = UseEditAyantDroit();
  const { state, dispatch } = useContext(DataContext);
  const { ayantDroit } = state;
  var [startDate, setStartDate] = React.useState(new Date());
  const [isValidDate, setIsValiddate] = React.useState(true);

  useEffect(() => {
    if (isSuccess && beneficiaire?.id) {
      getAyantDroit(beneficiaire?.id);
      dispatch({
        type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE",
        payload: {},
      });
    }
  }, [isSuccess, beneficiaire]);

  React.useEffect(() => {
    if (ayantDroit?.id) {
      register("date_naissance");
      setValue("date_naissance", ayantDroit?.date_naissance);
      var date = new Date(ayantDroit?.date_naissance);
      setStartDate(date);
      register("prenom");
      setValue("prenom", ayantDroit?.prenom);
      register("nom");
      setValue("nom", ayantDroit?.nom);
      register("statut");
      setValue("statut", ayantDroit?.statut);

      register("sexe");
      setValue("sexe", ayantDroit?.sexe);

      register("id");
      setValue("id", ayantDroit?.id);

      register("beneficiaire");
      setValue("beneficiaire", ayantDroit?.beneficiaire?.id);
    }

    // props.setValue('beneficiaire', props?.beneficiaireID)
  }, [ayantDroit]);

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  const handleDate = (date) => {
    if (moment(beneficiaire?.date_de_naissance) >= moment(date)) {
      setIsValiddate(false);
      setTimeout(() => {
        setIsValiddate(true);
      }, 5000);
    } else {
      setStartDate(date);
      setValue("date_naissance", moment(date).format("YYYY-MM-DD"));
    }
  };

  const changeAvatar = (e) => {
    const file = e.target.files[0];
    register("avatar");
    setValue("avatar", file);
  };

  const handleReset = async () => {
    setStartDate(new Date());
    dispatch({
      type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE",
      payload: {},
    });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Modifier un enfant
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => handleReset()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="ad_first_name">Prénom</label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      className="form-control crud-form-control"
                      id="ad_first_name"
                      {...register("prenom")}
                    />
                    {formState.errors && formState.errors.prenom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="ad_last_name">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      className="form-control crud-form-control"
                      id="ad_last_name"
                      // defaultValue={type === "enfant" ? beneficiaire?.nom : ""}
                      {...register("nom")}
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="ad_date_naissance">Date de naissance</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      minDate={new Date(beneficiaire?.date_de_naissance)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {formState.errors && formState.errors.date_naissance && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.date_naissance?.message}
                      </div>
                    )}
                    {!isValidDate && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        Attention! Un enfant ne peut être plus agé que son
                        parent.
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 crud-col-item input-radio-container required">
                  <label htmlFor="ad_genre">Genre</label>
                  <div className="input-radio-content">
                    <div className="input-radio-item">
                      <input
                        type="radio"
                        name="sexe"
                        className="crud-form-control input-radio-form-control"
                        id="ad_genre"
                        value="homme"
                        {...register("sexe")}
                      />
                      &nbsp;
                      <span className="input-radio-item-label">Masculin</span>
                    </div>
                    <div className="input-radio-item">
                      <input
                        type="radio"
                        name="sexe"
                        className="crud-form-control input-radio-form-control"
                        id="genre_2"
                        value="femme"
                        {...register("sexe")}
                      />
                      &nbsp;
                      <span className="input-radio-item-label">Féminin</span>
                    </div>
                  </div>
                  {formState.errors && formState.errors.sexe && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState.errors.sexe?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item mb-5">
                  <div
                    className="form-group crud-form-group custom-file"
                    style={{ marginBottom: "13%" }}
                  >
                    <label htmlFor="file_up">Photo</label>
                    <input
                      type="file"
                      name="avatar"
                      id="file_up"
                      accept="image/*"
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      onChange={changeAvatar}
                    />
                  </div>
                </div>
                <div className="col-md-6 crud-col-item mb-5">
                  <div
                    className="form-group crud-form-group custom-file"
                    style={{ marginBottom: "13%" }}
                  >
                    <label htmlFor="certificat_marriage">
                      Certificat de naissance
                    </label>
                    <input
                      type="file"
                      placeholder={`Importer le certifcat de naissance`}
                      name="certificat"
                      // onChange={handleFile}
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      id="certificat"
                      {...register("certificat")}
                    />
                    {formState.errors && formState.errors.certificat && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.certificat.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button
                      type="submit"
                      className="btn btn-primary crud-submit-btn"
                    >
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEnfant;
