import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import PriseEnChargeTable from "../../../Tables/Assurance/PriseEnChargeTable/PriseEnChargeTable";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
import AjouterUnePriseEnCharge from "../../../Tables/Assurance/PriseEnChargeTable/AjouterUnePriseEnCharge";
import "./PriseEnCharge.css";
import ModifierPriseEnCharge from "../../../Tables/Assurance/PriseEnChargeTable/ModifierPriseEnCharge";
import DetailPriseEnCharge from "../../../Tables/Assurance/PriseEnChargeTable/DetailPriseEnCharge";
import DeletePriseEnCharge from "../../../Tables/Assurance/PriseEnChargeTable/DeletePriseEnCharge";
var PriseEnCharge = () => {
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Prise en charge</h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target="#AjouterPriseEnChargeModal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Ajouter une prise en charge
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <PriseEnChargeTable />
                </div>
                <div
                  class="modal fade"
                  id="AjouterPriseEnChargeModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AjouterPriseEnChargeModalLabel"
                  aria-hidden="true"
                >
                  <AjouterUnePriseEnCharge />
                </div>

                <div
                  class="modal fade edit-prise-en-charge-modal"
                  id="ModifierPriseEnChargeModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="ModifierPriseEnChargeModalLabel"
                  aria-hidden="true"
                >
                  <ModifierPriseEnCharge />
                </div>
                <div
                  class="modal fade details-prise-en-charge-modal"
                  id="DetailPriseEnChargeModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="DetailPriseEnChargeModalLabel"
                  aria-hidden="true"
                >
                  <DetailPriseEnCharge />
                </div>
                <div
                  class="modal fade delete-prise-en-charge-modal"
                  id="DeletePriseEnChargeModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="DeletePriseEnChargeModalLabel"
                  aria-hidden="true"
                >
                  <DeletePriseEnCharge />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriseEnCharge;
