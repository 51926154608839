import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DataContext } from "../../../store/GlobalState";
import UseCreateEntreprise from "./UseCreateEntreprise";

const AjouterEntreprise = (props) => {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    unregister,
    isSuccess,
  } = UseCreateEntreprise();
  const { state, dispatch } = useContext(DataContext);

  const { entreprise, entreprises } = state;
  var [website, setWebsite] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [name, setName] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  const [phone, setPhone] = React.useState("");

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  const handleSetPhone = (val) => {
    // console.log('phone', val)
    setPhone(val);
    register("telephone");
    setValue("telephone", "+" + val);
  };

  const handleChangeWebsite = (e) => {
    // eslint-disable-next-line
    if (e.target.value) {
      const urlPattern = new RegExp(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
      );
      let string = e.target.value;

      if (urlPattern.test(string)) {
        ///clear http && https from string
        string = string.replace("https://", "").replace("http://", "");
        //add https to string
        string = `https://${string}`;
      }
      setWebsite(string);
      register("website");
      setValue("website", string);
    }
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value) {
      register("raison_sociale");
      setValue("raison_sociale", event.target.value);
      // register("nom");
      // setValue("nom", event.target.value);
    } else {
      setSearchResults([]);
    }
    if (!name || name === searchTerm) {
      setName(event.target.value);
      register("nom");
      setValue("nom", event.target.value);
    }

    if (entreprise?.id && entreprise?.raison_sociale === entreprise?.nom) {
      register("nom");
      setValue("nom", event.target.value);
    }
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
    register("nom");
    if (!entreprise?.id) {
      setValue("nom", e.target.value);
    }
  };

  React.useEffect(() => {
    //console.log('entreprise', entreprises)
    if (!entreprise?.id && searchTerm) {
      const results = entreprises?.filter((person) => {
        // console.log('search term', searchTerm, person)
        let name = person?.raison_sociale + " " + person?.nom;
        return name.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setSearchResults(results);
    }
    if (entreprise?.id) {
      if (entreprise?.telephone.includes("+221")) {
        setPhone(entreprise?.telephone);
      } else {
        setPhone("+221" + entreprise?.telephone);
      }
    }
  }, [searchTerm, entreprise?.id]);

  const handleClick = (e, item) => {
    e.preventDefault();
    // console.log('item', item)
    setSearchTerm("");
    setSearchResults([]);

    dispatch({
      type: "EDIT_ENTREPRISE",
      payload: item,
    });
    setValue("nom", item?.nom);
  };

  React.useEffect(() => {
    if (isSuccess) {
      handleReset();
    }
  }, [isSuccess]);

  const handleReset = () => {
    dispatch({ type: "EDIT_ENTREPRISE", payload: {} });
    setPhone("");
    setWebsite("");
    setSearchResults([]);

    setName("");
    setSearchTerm("");

    setPhone("+221");
    reset();
    console.log("phone", phone);
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AjouterEntreprise">
            Ajouter une entreprise
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="nom">Raison sociale</label>
                      <input
                        type="text"
                        placeholder="Raison sociale"
                        name="raison_sociale"
                        // {...register("raison_sociale")}
                        className="form-control crud-form-control"
                        id="nom"
                        disabled={entreprise?.id}
                        value={
                          !entreprise?.id
                            ? searchTerm
                            : entreprise?.raison_sociale
                        }
                        onChange={handleChange}
                      />
                      {searchResults?.length ? (
                        <ul className="search-result-container">
                          {searchResults.map((item) => (
                            <li
                              className="search-result-item"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleClick(e, item)}
                              key={item?.id}
                            >
                              {item?.raison_sociale}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                      {formState.errors && formState.errors.raison_sociale && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.raison_sociale?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="ninea">Ninea</label>
                    <input
                      type="text"
                      placeholder="Ninea"
                      name="ninea"
                      {...register("ninea")}
                      maxlength={12}
                      className="form-control crud-form-control"
                      id="ninea"
                      disabled={entreprise?.id}
                      defaultValue={!entreprise?.id ? "" : entreprise?.ninea}
                    />
                    {formState.errors && formState.errors.ninea && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.ninea?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="nom">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      {...register("nom")}
                      className="form-control crud-form-control"
                      id="nom"
                      disabled={entreprise?.id}
                      defaultValue={!entreprise?.id ? name : entreprise?.nom}
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="email">Adresse email</label>
                    <input
                      type="mail"
                      placeholder="Adresse email"
                      name="email"
                      {...register("email")}
                      className="form-control crud-form-control"
                      id="email"
                      disabled={entreprise?.id}
                      defaultValue={!entreprise?.id ? "" : entreprise?.email}
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="telephone">Téléphone</label>
                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      inputClass="form-control crud-form-control"
                      country={"sn"}
                      onlyCountries={["sn"]}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      disabled={entreprise?.id}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    {formState.errors && formState.errors.telephone && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.telephone?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      type="text"
                      placeholder="Adresse"
                      name="adresse"
                      {...register("adresse")}
                      className="form-control crud-form-control"
                      id="adresse"
                      disabled={entreprise?.id}
                      defaultValue={!entreprise?.id ? "" : entreprise?.adresse}
                    />
                    {formState.errors && formState.errors.adresse && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.adresse?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="reference">Référence</label>
                    <input
                      type="text"
                      placeholder="Référence"
                      name="num_contrat"
                      {...register("num_contrat")}
                      className="form-control crud-form-control"
                      id="reference"
                      disabled={entreprise?.id}
                      defaultValue={
                        !entreprise?.id ? "" : entreprise?.num_contrat
                      }
                    />
                    {formState.errors && formState.errors.num_contrat && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.num_contrat?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="website">Site web</label>
                    <input
                      type="text"
                      placeholder="www.exemple.com"
                      name="website"
                      // {...register("website")}
                      className="form-control crud-form-control"
                      id="website"
                      disabled={entreprise?.id}
                      defaultValue={
                        !entreprise?.id ? website : entreprise?.website
                      }
                      onChange={handleChangeWebsite}
                    />
                    <div className="input-infos-container">
                      <IconlyPack.Danger
                        set="light"
                        primaryColor="gray"
                        className="alerty-icon"
                      />
                      &nbsp;
                      <span className="input-infos">
                        Saisie au format www.exemple.com, l'adresse sera
                        autocomplétée avec le protocole http://
                      </span>
                    </div>
                    {formState.errors && formState.errors.website && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.website?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="tiers_payant">Tiers Payant Intégral</label>
                    <div className="filter-item-container">
                      <select
                        name="tiers_payant"
                        {...register("tiers_payant")}
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                        disabled={entreprise?.id}
                        defaultValue={
                          !entreprise?.id ? "" : entreprise?.tiers_payant
                        }
                      >
                        <option selected value="">
                          Choisir
                        </option>
                        <option value={true}>OUI</option>
                        <option value={false}>NON</option>
                      </select>
                      {formState.errors && formState.errors.tiers_payant && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.tiers_payant?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 crud-col-item required">
                  <label for="inlineFormInputGroupUsername">
                    Pourcentage (%)
                  </label>
                  <div class="input-group position-relative">
                    <input
                      type="number"
                      name="pourcentage"
                      {...register("pourcentage")}
                      max={100}
                      class="form-control crud-form-control"
                      id="inlineFormInputGroupUsername"
                      disabled={entreprise?.id}
                      defaultValue={
                        !entreprise?.id ? "" : entreprise?.pourcentage
                      }
                    />
                    <div class="input-group-percent">
                      <span class="">%</span>
                    </div>
                  </div>
                  {formState.errors && formState.errors.pourcentage && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState.errors.pourcentage?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="logo">Logo </label>
                    <input
                      type="file"
                      name="logo"
                      disabled={entreprise?.id}
                      {...register("logo")}
                      accept="image/*"
                      className="form-control crud-form-control"
                      id="logo"
                    />
                    {formState.errors && formState.errors.logo && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.logo?.message}
                      </div>
                    )}
                  </div>
                </div>
                <input
                  {...register("user_type")}
                  type="hidden"
                  name="user_type"
                  value={`entreprise`}
                />
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  {!entreprise?.id && (
                    <button
                      className="btn btn-default cancelleb-btn-assurance"
                      onClick={() => handleReset()}
                      data-dismiss="modal"
                    >
                      Retour
                    </button>
                  )}
                  {entreprise?.id && (
                    <button
                      className="btn btn-default cancelleb-btn-assurance"
                      onClick={() => handleReset()}
                    >
                      Réinitialiser
                    </button>
                  )}
                  {isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                  )}
                  {/* <button className="btn  crud-submit-btn-assurance">
                    Enregistrer
                  </button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterEntreprise;
