import moment from "moment";
import React, { useContext, useState } from "react";
// import * as IconlyPack from 'react-iconly'
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import { CountryDropdown } from "react-country-region-selector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import useCreateAssureForm from "./useCreateAssureForm";
import { DataContext } from "../../../store/GlobalState";

registerLocale("fr", fr);

function AjouterAssure() {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    unregister,
    isSuccess,
  } = useCreateAssureForm();
  const { state } = useContext(DataContext);
  const { assureur_entreprises } = state;
  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());
  var [date, setDate] = React.useState(new Date());
  const [isValid, setIsValid] = React.useState(true);
  var [country, setCountry] = useState();
  var [choice, setChoice] = useState("");
  var userType = sessionStorage.getItem("userType");
  var userID = sessionStorage.getItem("userID");
  const [phone, setPhone] = React.useState("");

  const handleSetPhone = (val) => {
    // console.log('phone', val)
    setPhone(val);
    register("telephone");
    setValue("telephone", "+" + val);
  };

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  React.useEffect(() => {
    // register("date_debut");
    // setValue("date_debut", moment(startDate).format("YYYY-MM-DD"));
    // register("date_fin");
    // setValue("date_fin", moment(endDate).format("YYYY-MM-DD"));
  }, []);

  React.useEffect(() => {
    if (isSuccess) {
      handleReset();
    }
  }, [isSuccess]);

  const handleDate = (date) => {
    setDate(date);
    setValue("date_de_naissance", moment(date).format("YYYY-MM-DD"));
  };

  const handleDateDebut = (date) => {
    setStartDate(date);
    register("date_debut");
    setValue("date_debut", moment(date).format("YYYY-MM-DD"));
    if (endDate && date > endDate) {
      setIsValid(false);
    }
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      register("date_fin");
      setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };

  const handleChoice = (e) => {
    register("situation_matrimoniale");
    setValue("situation_matrimoniale", e.target.value);
    setChoice(e.target.value);
    console.log(e.target.value);
  };

  const handleReset = async () => {
    reset();
    setPhone("+221");
    register("telephone");
    setValue("telephone", "");
    register("nationnalite");
    setValue("nationnalite", "");
    setCountry("");
    setDate(new Date());
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const selectCountry = async (val) => {
    // await setCountry()
    await setValue("nationnalite", val);
    await setCountry(val);
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Ajouter un Assuré
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="prenom">Prénom</label>
                    <input
                      {...register("prenom")}
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      className="form-control crud-form-control"
                      id="prenom"
                    />
                    {formState.errors && formState.errors.prenom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="nom">Nom</label>
                    <input
                      {...register("nom")}
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      className="form-control crud-form-control"
                      id="nom"
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="sexe">Genre</label>
                    <div className="filter-item-container">
                      <select
                        {...register("sexe")}
                        name="sexe"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir un genre
                        </option>
                        <option value="femme">FEMME</option>
                        <option value="homme">HOMME</option>
                      </select>
                      {formState.errors && formState.errors.sexe && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.sexe?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="situation_matrimoniale">
                      Situation matrimoniale
                    </label>
                    <div className="filter-item-container">
                      <select
                        onChange={(e) => handleChoice(e)}
                        name="situation_matrimoniale"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form select-country-input"
                        aria-label="Default select example"
                      >
                        <option selected value="">
                          Choisir
                        </option>
                        <option value="marié(e)">MARIÉ(E)</option>
                        <option value="veuf(ve)">VEUF(VE)</option>
                        <option value="célibataire">CÉLIBATAIRE</option>
                        {/* <option value="divorcé">DIVORCÉ</option> */}
                      </select>
                      {formState.errors &&
                        formState.errors.situation_matrimoniale && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.situation_matrimoniale?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="groupe_sanguin">Groupe sanguin</label>
                    <div className="filter-item-container">
                      <select
                        {...register("groupe_sanguin")}
                        name="groupe_sanguin"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option selected value="">
                          Choisir un groupe sanguin
                        </option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                      </select>
                      {formState.errors && formState.errors.groupe_sanguin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.groupe_sanguin?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="nationnalite">Nationalité</label>
                    <CountryDropdown
                      className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form select-country-input"
                      aria-label="Default select example"
                      defaultOptionLabel="Choisir une nationalité"
                      value={country}
                      priorityOptions={["SN"]}
                      name="nationnalite"
                      onChange={(val) => selectCountry(val)}
                    />

                    {formState.errors && formState.errors.nationnalite && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.nationnalite?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="num_contrat">Num. Contrat</label>
                    <input
                      {...register("num_contrat")}
                      type="text"
                      placeholder="Numéro contrat"
                      name="num_contrat"
                      className="form-control crud-form-control"
                      id="numero_contrat"
                    />
                    {formState.errors && formState.errors.num_contrat && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.num_contrat?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="employeur">Entreprise</label>
                    <div className="filter-item-container">
                      <select
                        {...register("employeur")}
                        name="employeur"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option selected value="">
                          Choisir
                        </option>
                        {assureur_entreprises?.data &&
                          assureur_entreprises?.data.map((item, index) => (
                            <option key={index} value={item.id}>
                              {" "}
                              {item.raison_sociale}{" "}
                            </option>
                          ))}
                      </select>
                      {formState.errors && formState.errors.employeur && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.employeur?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="date_de_naissance">Date de naissance</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={date}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {formState.errors && formState.errors.date_de_naissance && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.date_de_naissance?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      {...register("email")}
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="form-control crud-form-control"
                      id="email"
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      {...register("adresse")}
                      type="text"
                      placeholder="Adresse"
                      name="adresse"
                      className="form-control crud-form-control"
                      id="adresse"
                    />
                    {formState.errors && formState.errors.adresse && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.adresse?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="telephone">Téléphone</label>

                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      inputClass="form-control crud-form-control"
                      country={"sn"}
                      onlyCountries={["sn"]}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    {formState.errors && formState.errors.telephone && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.telephone?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="date_debut">Date de début</label>

                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                      onChange={(date) => handleDateDebut(date)}
                    />
                    {formState.errors && formState.errors.date_debut && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.date_debut?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="date_fin">Date de fin</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={endDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                      onChange={(date) => handleDateFin(date)}
                    />
                    {formState.errors && formState.errors.date_fin && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.date_fin?.message}
                      </div>
                    )}

                    {!isValid && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        La date de fin ne doit pas être inférieure à la date de
                        début.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="matricule">Matricule</label>
                    <input
                      {...register("matricule")}
                      type="text"
                      placeholder="Matricule"
                      name="matricule"
                      className="form-control crud-form-control"
                      id="matricule"
                    />
                    {formState.errors && formState.errors.matricule && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.matricule?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div
                    className="form-group crud-form-group custom-file"
                    style={{ marginBottom: "13%" }}
                  >
                    <label htmlFor="document">Carte nationale d'identité</label>
                    <input
                      type="file"
                      placeholder="Importer votre CNI"
                      name="cni"
                      // onChange={handleFile}
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      id="document"
                      {...register("cni")}
                    />
                    {formState.errors && formState.errors.cni && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.cni.message}
                      </div>
                    )}
                  </div>
                </div>
                {choice === "marié(e)" ? (
                  <div className="col-md-6 crud-col-item">
                    <div
                      className="form-group crud-form-group custom-file"
                      style={{ marginBottom: "13%" }}
                    >
                      <label htmlFor="certificat_marriage">
                        Certificat de mariage
                      </label>
                      <input
                        type="file"
                        placeholder="Importer votre certifcat de mariage"
                        name="certificat_marriage"
                        // onChange={handleFile}
                        className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                        id="certificat_marriage"
                        {...register("certificat_marriage")}
                      />
                      {formState.errors &&
                        formState.errors.certificat_marriage && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.certificat_marriage.message}
                          </div>
                        )}
                    </div>
                  </div>
                ) : null}
                {/* <input {...register('ipm_from')} type="hidden" name="ipm_from" value={2} /> */}
                <input
                  {...register("user_type")}
                  type="hidden"
                  name="user_type"
                  value={`beneficiaire`}
                />
                <input
                  {...register("status")}
                  type="hidden"
                  name="status"
                  value={true}
                  // className="display-none"
                />
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterAssure;
