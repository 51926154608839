import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";

const AddFamilleSoinModal = (props) => {
  const [inputArray, setInputArray] = useState([]);

  const addJugementItem = (e) => {
    e.preventDefault();
    setInputArray([...inputArray, "A new Input"]);
  };
  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...inputArray];
    list.splice(index, 1);
    setInputArray(list);
  };
  const handleReset = () => {
    props.reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Ajouter une famille de soins
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={props?.onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="name">Nom de la famille de soins</label>
                    <div className="filter-item-container">
                      <input
                        name="nom"
                        {...props?.register("nom")}
                        className="form-control crud-form-control category-name-input"
                        type="text"
                        placeholder="Nom de la famille de soins"
                      />
                    </div>
                  </div>
                  {props?.formState?.errors &&
                    props?.formState?.errors?.nom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {props?.formState?.errors?.nom?.message}
                      </div>
                    )}
                </div>
              </div>
              <div className="subcat-manage-container">
                <h4 className="subcat-manage-title">
                  Ajouter le/les types de soin(s)
                </h4>
                <div className="row sub-cat-row">
                  <div className="col-md-8 crud-col-item">
                    <div className="add-subcat-item-input-container">
                      <div className="filter-item-container position-relative">
                        <input
                          {...props?.register(`typedesoin[0].nom`)}
                          name={`typedesoin[0].nom`}
                          className="form-control crud-form-control input-add-category"
                          type="text"
                          placeholder="Type de soin"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* {props?.formState?.errors &&
                  props?.formState?.errors?.typedesoin && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {props?.formState?.errors?.typedesoin?.message}
                    </div>
                  )} */}
                {inputArray.map((item, index) => (
                  <div
                    className="row sub-cat-row"
                    key={index}
                    data-value={item}
                  >
                    <div className="col-md-8 crud-col-item">
                      <div className="add-subcat-item-input-container">
                        <div className="filter-item-container position-relative">
                          <input
                            {...props?.register(`typedesoin[${index + 1}].nom`)}
                            name={`typedesoin[${index + 1}].nom`}
                            className="form-control crud-form-control input-add-category"
                            type="text"
                            placeholder="Sous catégorie"
                          />
                          <span
                            onClick={(e) => handleRemoveClick(e, index)}
                            className="icon-trash"
                          >
                            <FeatherIcon icon="trash-2" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="row add-dynamically-btn-container">
                  <div
                    className="col-md-4 offset-md-8 crud-col-item add-subcat-item-container linkable"
                    onClick={(e) => addJugementItem(e)}
                  >
                    <div className="add-item-container-sub">
                      <FeatherIcon icon="plus" className="add-item-icon" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row submit-btn-row">
                <div className="col-md-4 offset-md-4 crud-form-action-btn-container">
                  {/* {!props.isSuccessfullySubmitted && 
                                    <button className="btn btn-primary crud-submit-btn">AJOUTER</button>
                                
                                } */}
                  {props?.isSuccessfullySubmitted && (
                    <div className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </div>
                  )}
                  {!props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      AJOUTER
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFamilleSoinModal;
