import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import GestionSoinsAssuranceTableSkeleton from "./GestionSoinsAssuranceTableSkeleton";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import GestionSoinsAssuranceTableData from "./GestionSoinsAssuranceTableData";
import GestionSoinAssureurModal from "../../../Backend/Assurance/GestionSoinsAssureur/GestionSoinAssureur";
import ModifierSoinModal from "../../../Backend/Assurance/GestionSoinsAssureur/ModifierSoinModal";
import { DataContext } from "../../../store/GlobalState";
import { formatMoney } from "../../../../utils/utils";
import { Message } from "rsuite";
import moment from "moment"
import ValidFicheDeSoinModal from "../../../Modals/ValidFicheDeSoinModal";
import ShowFicheFileModal from "../../../Modals/ShowFicheFileModal";

var GestionSoinsAssuranceTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const {
    assureur_ficheSoins,
    ficheDeSoinsbyIpm,
    beneficiaires,
    prestataires,
    list_ayantdroit,
  } = state;
  const [fiche, setFiche] = React.useState();

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsPageLoading(false);
  //   }, 3000);
  // }, [soins]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
    expandBy: "column",
  };
  const beneficiaireFormatter = (cell, row) => {
    return (
      <NavLink
        to={{
          pathname: `/assurance/details-assures/${row?.beneficiaire?.id}`,
          state: { assure: row?.beneficiaire },
        }}
        className="nameFormatter"
      >
        <span className="name">
          {" "}
          {row?.beneficiaire?.prenom} {row?.beneficiaire?.nom}{" "}
        </span>{" "}
        <br />
        <span className="entreprise">
          {" "}
          {row?.beneficiaire?.employeur?.nom}{" "}
        </span>
      </NavLink>
    );
  };

  const beneficiaireFilter = (cell, row) => {
    //console.log('row', row)
    return (
      row?.beneficiaire?.prenom +
      " " +
      row?.beneficiaire?.nom +
      " " +
      row?.beneficiaire?.employeur?.raison_sociale
    );
  };

  const prestataireFilter = (cell, row) => {
    //console.log('row', row)
    // let filter =
    return (
      row?.prestataire?.prenom +
      " " +
      row?.prestataire?.nom +
      " " +
      row?.prestataire?.raison_sociale
    );
  };

  const etatFormatter = (cell, row) => {
    if (cell === "new") {
      return (
        <span className="badge badge-info easypm-badge-info ">Nouveau</span>
      );
    }
    if (cell === "en_cours") {
      return (
        <span className="badge badge-warning easypm-badge-warning ">
          En cours
        </span>
      );
    }
    if (cell === "suspendu") {
      return (
        <span className="badge badge-warning easypm-badge-warning ">
          Suspendu
        </span>
      );
    }
    if (cell === "rejete") {
      return (
        <span className="badge badge-danger easypm-badge-danger ">Rejeté</span>
      );
    }
    if (cell === "validee") {
      return (
        <span className="badge badge-success easypm-badge-success ">
          Validé
        </span>
      );
    }
  };

  const prestataireFormatter = (cell, row) => {
    return row?.prestataire?.raison_sociale
      ? row?.prestataire?.raison_sociale
      : row?.prestataire?.prenom + " " + row?.prestataire?.nom;
  };

  const DateFormatter = (cell, row) => {
    return moment(row?.date).format("DD/MM/YYYY");
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn edit-btn" title="Traitement">
          <IconlyPack.Paper
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".show-valid-modal"
            onClick={() => getItem(row)}
          />
        </button>

        {row?.file_pdf && row?.etat === "validee" && (
          <>
            <button className="action-btn show-btn" title="Voir le fichier">
              <IconlyPack.Download
                set="light"
                primaryColor="#C8C8C8"
                className="action-btn-icon action-show-icon"
                data-toggle="modal"
                data-target=".show-fiche-file-modal"
                onClick={() => getItem(row)}
              />
            </button>
            {/* <button
              data-toggle="modal"
              data-target=".show-fiche-modal"
              className="action-btn show-btn"
              onClick={() => getItem(row)}
              title="Details"
            >
              <IconlyPack.Show
                set="light"
                primaryColor="#C8C8C8"
                className="action-btn-icon action-show-icon"
              />
            </button> */}
          </>
        )}
      </div>
    );
  };

  const getItem = (item) => {
    setFiche(item);
    dispatch({
      type: "EDIT_FICHE_DE_SOIN_BY_IPM",
      payload: item,
    });
  };

  const isExpandableRow = (row) => {
    if (row.id) return true;
    else return false;
  };

  const expandComponent = (row) => {
    let totalCout = 0;
    let totalIpm = 0;
    let totalBenef = 0;
    if (row?.prestations && row?.prestations?.length) {
      row.prestations.forEach((element) => {
        totalCout += parseInt(element.montant);
        totalIpm += parseInt(element.montant_rembourse);
        totalBenef += parseInt(element.montant_retenu);
      });
      beneficiaires.forEach((b) => {
        row.prestations.forEach((element) => {
          if (element?.beneficiaire === b?.id) {
            element.beneficiaire = b;
          }
        });
      });

      prestataires.forEach((b) => {
        row.prestations.forEach((element) => {
          if (element?.prestataire === b?.id) {
            element.prestataire = b;
          }
        });
      });

      list_ayantdroit.forEach((b) => {
        row.prestations.forEach((element) => {
          if (element?.ayantdroit === b?.id) {
            element.ayantdroit = b;
          }
        });
      });
    }

    return (
      <div className="easypm-collapse-body">
        <div className="card">
          <div className="card-body">
            {row?.prestations && row?.prestations?.length ? (
              <>
                <div className="row mb-3 px-2">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1 d-block">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Coût total prestations
                        </p>
                      </div>
                      <div className="stat-counter text-right">
                        <div
                          className="label-stat-counter"
                          style={{ fontSize: "25px" }}
                        >
                          {/* {totalCout} */}
                          {/* {new Intl.NumberFormat('sn', {
                        currency: 'XOF',
                      }).format(totalCout)} */}
                          {formatMoney(parseInt(totalCout))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2 d-block">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total part Ipm
                        </p>
                        {/* <p className="child-label-card-statistics">En CFA</p> */}
                      </div>
                      <div className="stat-counter text-right">
                        <div
                          className="label-stat-counter"
                          style={{ fontSize: "25px" }}
                        >
                          {/* {new Intl.NumberFormat('sn', {
                        currency: 'XOF',
                      }).format(totalIpm)} */}
                          {formatMoney(parseInt(totalIpm))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3 d-block">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total part salariés
                        </p>
                        {/* <p className="child-label-card-statistics">En CFA</p> */}
                      </div>
                      <div className="stat-counter text-right">
                        <div
                          className="label-stat-counter"
                          style={{ fontSize: "25px" }}
                        >
                          {/* {new Intl.NumberFormat('sn', {
                        currency: 'XOF',
                      }).format(totalBenef)} */}
                          {formatMoney(parseInt(totalBenef))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {row?.prestations.map((prestation) => (
                  <>
                    <div className="crud-form">
                      <div
                        className="row crud-row"
                        style={{ paddingBottom: "10px" }}
                      >
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Adhérent: </strong>

                            <span className="crud-form-control">
                              {`${
                                prestation?.beneficiaire?.prenom +
                                " " +
                                prestation?.beneficiaire?.nom
                              }`}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Bénéficiaire: </strong>

                            <span className="crud-form-control">
                              {prestation?.ayantdroit
                                ? `${
                                    prestation?.ayantdroit?.prenom +
                                    " " +
                                    prestation?.ayantdroit?.nom
                                  }`
                                : `${
                                    prestation?.beneficiaire?.prenom +
                                    " " +
                                    prestation?.beneficiaire?.nom
                                  }`}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Famille de soins: </strong>

                            <span className="crud-form-control">
                              {`${prestation?.familledesoin?.nom}`}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Type de soins: </strong>

                            <span className="crud-form-control">
                              {`${prestation?.typedesoin?.nom}`}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Designation: </strong>

                            <span className="crud-form-control">
                              {`${prestation?.designation}`}
                            </span>
                          </div>
                        </div>
                        {/* <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong>Type de prestation: </strong>

                            <span className="crud-form-control">
                              {`${prestation?.type_prestation?.libelle}`}
                            </span>
                          </div>
                        </div> */}
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="name">Coût: </strong>
                            <span className="crud-form-control">
                              {/* {prestation?.montant} */}
                              {formatMoney(parseInt(prestation?.montant))}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="specialite">Part Ipm: </strong>
                            <span className="crud-form-control">
                              {/* {prestation?.montant_rembourse} */}
                              {formatMoney(
                                parseInt(prestation?.montant_rembourse)
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="specialite">
                              Part Participant:{" "}
                            </strong>
                            <span className="crud-form-control">
                              {/* {prestation?.montant_retenu} */}
                              {formatMoney(
                                parseInt(prestation?.montant_retenu)
                              )}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="specialite">Fournisseurs: </strong>
                            <span className="crud-form-control">
                              {prestation?.prestataire?.raison_sociale ||
                                prestation?.prestataire?.prenom +
                                  " " +
                                  prestation?.prestataire?.nom}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-divider"></div>
                  </>
                ))}
              </>
            ) : (
              <Message
                showIcon
                type="warning"
                title="Aucune prestation disponible."
              />
            )}
          </div>
        </div>
      </div>
    );
  };
  const statutType = {
    new: "Nouveau",
    // en_cours: "En cours",
    suspendu: "Suspendu",
    rejete: "Rejeté",
    validee: "Validé",
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {assureur_ficheSoins?.isLoading && (
              <GestionSoinsAssuranceTableSkeleton />
            )}
            {!assureur_ficheSoins?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={assureur_ficheSoins?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                  expandableRow={isExpandableRow}
                  expandComponent={expandComponent}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    filter={{ type: "TextFilter" }}
                    filterValue={beneficiaireFilter}
                    dataField="beneficiaire"
                    expandable={false}
                    dataFormat={(cell, row) => beneficiaireFormatter(cell, row)}
                  >
                    Assuré
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{ type: "TextFilter" }}
                    filterValue={prestataireFilter}
                    dataField="prestataire"
                    dataFormat={prestataireFormatter}
                  >
                    Prestataires
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="300"
                    dataAlign="center"
                    filter={{
                      type: "DateFilter",
                      defaultValue: { comparator: "=" },
                    }}
                    dataField="date"
                    dataFormat={DateFormatter}
                    dataSort
                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "SelectFilter",
                      options: statutType,
                      selectText: "",
                    }}
                    dataAlign="center"
                    dataField="etat"
                    width="150"
                    dataFormat={(cell, row) => etatFormatter(cell, row)}
                  >
                    Statut
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    expandable={false}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionSoinModal"
            tabIndex="-1"
            aria-labelledby="GestionSoinModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <GestionSoinAssureurModal />
          </div>
          <div
            className="modal fade easypm-modal show-valid-modal"
            id="ShowModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="AddModalItemLabel"
            aria-hidden="true"
          >
            <ValidFicheDeSoinModal />
          </div>
          {/* Modal show */}
          <div
            className="modal fade easypm-modal show-fiche-file-modal"
            id="CsvModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="CsvModalItemLabel"
            aria-hidden="true"
          >
            <ShowFicheFileModal fiche={fiche} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionSoinsAssuranceTable;
