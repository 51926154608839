import React, { useContext, useState } from "react";
import { DataContext } from "../store/GlobalState";
import AyantDroit from "../Services/ayantDroit";
import { toast } from "react-toastify";
import { getDataByID } from "../utils/fetchData";

const ValidFamilyModal = () => {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, ayantDroit, ayantDroits } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const handleValid = async (e) => {
    e.preventDefault();
    let val = null
    if (ayantDroit?.is_active === true) {
      val = false
    } else (
      val = true
    )
    setIsLoading(true);
    var ayantDroitRequest = AyantDroit.putAyantDroit(
      ayantDroit.id,
      {is_active: val},
      requestConfig
    );
    await ayantDroitRequest
      .then(async (res) => {

        if (res.is_active) {
          await toast.success(
            ayantDroit?.statut === "enfant"
              ? "Enfant validé !"
              : "Conjoint(e) validé(e) !"
          );
        } else {
          await toast.success(
            ayantDroit?.statut === "enfant"
              ? "Enfant déactivé !"
              : "Conjoint(e) déactivé (e) !"
          );
        }
        await setIsLoading(false);
        await dispatch({
          type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE",
          payload: {},
        });
        await window.$(".valid-family-modal").modal("hide");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la validation.");
      });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="DeletingFamilyModal"
          >
            Validation{" "}
            {ayantDroit &&
              (ayantDroit?.statut === "enfant"
                ? "d'un enfant"
                : "d'un/une conjoint(e) ")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              dispatch({ type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE", payload: {} })
            }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="deleting-family-form-container">
            <div className="alert-delete-message">
              <span>
                {ayantDroit &&
                  "Êtes vous sûr de vouloir " + 
                    ( ayantDroit?.is_active ? "déactiver " : "valider ") + 
                    ( ayantDroit?.statut === "enfant"
                      ? "cet enfant"
                      : "ce/cette conjoint(e) ")}{" "}
                ?
              </span>
            </div>

            <div className="row delete-row">
              <div className="col-md-10 offset-md-2 crud-form-action-btn-container">
                <button
                  onClick={() =>
                    dispatch({
                      type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE",
                      payload: {},
                    })
                  }
                  className="btn btn-default cancelleb-btn delete-actions"
                  data-dismiss="modal"
                >
                  Retour
                </button>
                {!isLoading ? (
                  <button
                    className="btn btn-primary crud-submit-btn delete-actions"
                    onClick={handleValid}
                  >
                    Confirmer
                  </button>
                ) : (
                  <button className="btn btn-primary crud-submit-btn delete-actions">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidFamilyModal;
