import { prestataire } from './Endpoints'
import axios from 'axios'

export default {
    async getListPrestataires(config) {
        const response = await axios.get(prestataire.onGetOrPostPrestataire(), config);
        // console.log(response)
        return response.data;
    },
    async postCreatePrestataire(data, config) {
        const response = await axios.post(prestataire.onGetOrPostPrestataire(), data, config);
        return response.data;
    },
    async putPrestataire(id, data, config) {
        const response = await axios.put(prestataire.onPutPrestataire(id), data, config);
        return response.data;
    },
    async deletePrestataire(id, config) {
        const response = await axios.delete(prestataire.onDeletePrestataire(id), config);
        return response;
    }, 
    async getPrestationByPrestataire(id, config) {
        const response = await axios.get(prestataire.onGetPrestationByPrestataire(id), config);
        return response.data;
    },
    async postDemandeAgreement(data, config) {
        const response = await axios.post(prestataire.demandeAgreement(), data, config);
        return response.data
    }

}
