import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// import './index.scss';
import "./index.css";
import "react-quill/dist/quill.snow.css";
import * as serviceWorker from "./serviceWorker";

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-184919919-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;

  Sentry.init({
    dsn: "https://4896bdbb477e4413bf706a3e9ce23158@app-sentry.withvolkeno.com/33",
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
