import React, { useEffect, useState } from 'react'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import * as IconlyPack from "react-iconly";
import DetailsFacturationAdminTableData from './DetailsFacturationAdminTableData';
import DetailsFacturationAdminTableSkeleton from './DetailsFacturationAdminTableSkeleton';

var DetailsFacturationAdminTable = (props) => {
    var [facturation, setFacturation] = useState(DetailsFacturationAdminTableData)
    var [isPageLoading, setIsPageLoading] = React.useState(true)

    useEffect(() => {
        setTimeout(() => {
            setIsPageLoading(false)
        }, 3000)
    }, [facturation])

    var handleClearButtonClick = onClick => {
        console.log(
            "This is my custom function for ClearSearchButton click event"
        );
        onClick();
    };

    var createCustomClearButton = onClick => {
        return (
            <ClearSearchButton
                btnText="Effacer"
                btnContextual="btn-warning"
                className="my-custom-class"
                onClick={e => handleClearButtonClick(onClick)}
            />
        );
    };

    const options = {
        clearSearch: true,
        clearSearchBtn: createCustomClearButton,
        noDataText: "Aucune donnée disponible"
    };
  

    const selectRowProp = {
        mode: "checkbox"
    };

  


    return (
        <div className="child-table-container">
            <div className="table-body-container">
                <div className="row easypm-table-row m-t-50 m-b-50">
                    <div className="col-md-12 easypm-table-col">
                        {props.isPageLoading &&
                            <DetailsFacturationAdminTableSkeleton />
                        }
                        {!props.isPageLoading &&
                            <div className="table-container">
                                <BootstrapTable
                                    data={facturation}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    pagination
                                    selectRow={selectRowProp}
                                    trClassName="table-listing-tr"
                                    search={false}
                                    version="4"
                                    bordered={false}
                                >
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: "#000000b8"
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: "rgba(0, 0, 0, 0.7)"
                                        }}
                                        dataField="id"
                                        isKey={true}
                                        hidden={true}
                                    >
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#000000b8' }}
                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)' }} 
                                        width="200" 
                                        dataField='soins' 
                                        >
                                        Soins
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#000000b8' }}
                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)' }}
                                        width="100" dataField='date' 
                                        >
                                        Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: "#000000b8"
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: "rgba(0, 0, 0, 0.7)"
                                        }}
                                        dataField="prestataire"
                                        width="100"
                                    >
                                        Prestataires
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: "#000000b8"
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: "rgba(0, 0, 0, 0.7)"
                                        }}
                                        width="200"
                                        dataField="beneficiaire"
                                    >
                                        Bénéficiaires
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: "#000000b8"
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: "rgba(0, 0, 0, 0.7)"
                                        }}
                                        width="150"
                                        dataField="cout"
                                    >
                                        Coût
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#000000b8' }}
                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)' }}
                                        width="150" dataField='part_ipm'
                                         >
                                        Montant part IPM
                                        </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#000000b8' }}
                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)' }}
                                        width="200" 
                                        dataField='part_salaire' 
                                        >
                                        Montant à retenir sur salaire
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsFacturationAdminTable;