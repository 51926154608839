const GestionDevisAssureurTableData = [
    {
        id: 1,
        prestataire: 'HOGGY',
        adhérent: 'ABDOU FALL',
        date: '20/10/2022',
        statut: 'actif',
    },
    {
        id: 2,
        prestataire: 'HOGGY',
        adhérent: 'ABDOU FALL',
        date: '20/10/2022',
        statut: 'actif',
    },

];

export default GestionDevisAssureurTableData;
