import React from "react";
import { formatMoney } from "../../utils/utils";
import { DataContext } from "../store/GlobalState";
import baseUrl from "../../Http/backend-base-url";

const ShowModalSoin = (props) => {
  const { state, dispatch } = React.useContext(DataContext);
  const { prestation } = state;

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Detail{!props?.from ? " Demande de remboursement" : "Prestation"}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "EDIT_PRESTATION", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="crud-form">
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Participant: </strong>

                    <span className="crud-form-control">
                      {`${
                        !props?.from
                          ? prestation?.prestation?.beneficiaire?.prenom +
                            " " +
                            prestation?.prestation?.beneficiaire?.nom
                          : prestation?.beneficiaire?.prenom +
                            " " +
                            prestation?.beneficiaire?.nom
                      }`}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Patient: </strong>

                    <span className="crud-form-control">
                      {`${
                        !props?.from
                          ? prestation?.prestation?.ayant_droit?.id
                            ? prestation?.prestation?.ayant_droit?.prenom +
                              " " +
                              prestation?.prestation?.ayant_droit?.nom
                            : prestation?.prestation?.beneficiaire?.prenom +
                              " " +
                              prestation?.prestation?.beneficiaire?.nom
                          : prestation?.ayant_droit?.id
                          ? prestation?.ayant_droit?.prenom +
                            " " +
                            prestation?.ayant_droit?.nom
                          : prestation?.beneficiaire?.prenom +
                            " " +
                            prestation?.beneficiaire?.nom
                      }`}
                    </span>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Famille de soins: </strong>

                    <span className="crud-form-control">
                      {`${
                        !props?.from
                          ? prestation?.prestation?.familledesoin?.nom || ""
                          : prestation?.familledesoin?.nom || ""
                      }`}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Type de soins: </strong>

                    <span className="crud-form-control">
                      {`${
                        !props?.from
                          ? prestation?.prestation?.typedesoin?.nom || ""
                          : prestation?.typedesoin?.nom || ""
                      }`}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">
                      Type de prestation:{" "}
                    </strong>

                    <span className="crud-form-control">
                      {`${
                        !props?.from
                          ? prestation?.prestation?.type_prestation?.id
                            ? prestation?.prestation?.type_prestation?.libelle +
                                " " +
                                "(" +
                                prestation?.prestation?.type_prestation
                                  ?.taux_prise_en_charge +
                                "%" +
                                ")" || ""
                            : "Non renseigné"
                          : prestation?.type_prestation?.id
                          ? prestation?.type_prestation?.libelle +
                              " " +
                              "(" +
                              prestation?.type_prestation
                                ?.taux_prise_en_charge +
                              "%" +
                              ")" || ""
                          : "Non renseigné"
                      }`}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="name">Coût: </strong>
                    <span className="crud-form-control">
                      {!props?.from
                        ? formatMoney(prestation?.prestation?.montant)
                        : formatMoney(prestation?.montant)}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="specialite">Part Ipm: </strong>
                    <span className="crud-form-control">
                      {!props?.from
                        ? formatMoney(prestation?.prestation?.montant_rembourse)
                        : formatMoney(prestation?.montant_rembourse)}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="specialite">Part Participant: </strong>
                    <span className="crud-form-control">
                      {!props?.from
                        ? formatMoney(prestation?.prestation?.montant_retenu)
                        : formatMoney(prestation?.montant_retenu)}
                    </span>
                  </div>
                </div>
                {/* {!props?.from ? (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="specialite">Statut: </strong>
                      {prestation?.etat === "new" && (
                        <span className="badge badge-danger easypm-badge-danger linkable">
                          nouveau
                        </span>
                      )}
                      {prestation?.etat === "en_cours" && (
                        <span className="badge badge-warning easypm-badge-warning linkable">
                          en cours
                        </span>
                      )}

                      {prestation?.etat === "validee" && (
                        <span className="badge badge-success easypm-badge-success linkable">
                          Validé
                        </span>
                      )}
                    </div>
                  </div>
                ) : null} */}

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="specialite">Prestataire: </strong>
                    <span className="crud-form-control">
                      {!props?.from
                        ? prestation?.prestation?.prestataire?.raison_sociale
                          ? prestation?.prestation?.prestataire?.raison_sociale
                          : prestation?.prestataire?.prenom +
                            " " +
                            prestation?.prestataire?.nom
                        : prestation?.prestataire?.raison_sociale
                        ? prestation?.prestataire?.raison_sociale
                        : prestation?.prestataire?.prenom +
                          " " +
                          prestation?.prestataire?.nom}
                    </span>
                  </div>
                </div>

                {prestation?.libelle && (
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="specialite">Libellé: </strong>
                      <span className="crud-form-control">
                        {prestation?.libelle}
                      </span>
                    </div>
                  </div>
                )}

                {prestation?.documents_justif?.length ? (
                  <div className="col-md-12 crud-col-item">
                    <div className="accordion w-100" id="accordionExample1">
                      {prestation?.documents_justif.map((item, index) => (
                        <div className="card" key={item?.id}>
                          <div
                            className="card-header"
                            id="headingOne"
                            style={{ textAlign: "left" }}
                          >
                            <h5 className="mb-0">
                              <button
                                className="bloc-text-profil"
                                style={{ background: "none" }}
                                type="button"
                                data-toggle="collapse"
                                data-target={`#collapse${index}`}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                Document justificatif
                              </button>
                            </h5>
                          </div>

                          <div
                            id={`collapse${index}`}
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample1"
                          >
                            <div
                              className="card-body"
                              style={{ height: "700px" }}
                            >
                              <iframe
                                className="responsive-iframe w-100"
                                src={baseUrl + item?.doc}
                                style={{ height: "100%" }}
                                title="Certificat"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}

                <div
                  className="col-md-6 offset-md-6 crud-form-action-btn-container"
                  style={{ justifyContent: "flex-end" }}
                >
                  <button
                    className="btn btn-danger cancelleb-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      dispatch({ type: "EDIT_PRESTATION", payload: {} })
                    }
                  >
                    Retour
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowModalSoin;
