import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DataContext } from "../../../store/GlobalState";
import { getDataByID } from "../../../utils/fetchData";
import { toast } from "react-toastify";

const RenvoiPasswordEntreprise = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, entreprise } = state;

  const handleValid = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let id = null;

    if (entreprise?.id) {
      id = entreprise?.id;
    }

    getDataByID("regeneratepwduser", id, auth?.token)
      .then((res) => {
        // console.log("res", res);
        toast.success("Nouveau mot de passe envoyé avec succés.");
        window.$("#RenvoiPasswordEntreprise").modal("hide");
        handleReset();
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setIsLoading(false);
      });
  };

  const handleReset = () => {
    dispatch({ type: "EDIT_ENTREPRISE", payload: {} });
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="RenvoiPasswordEntreprise"
          >
            Renvoi de mot de passe
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row crud-row">
                <>
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <p className="text-revoi-password">
                        Veuillez confirmer le renvoi de mot de passe à{" "}
                        {entreprise?.id && entreprise?.raison_sociale}
                      </p>
                    </div>
                  </div>
                </>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {!isLoading ? (
                    <button
                      className="btn crud-submit-btn-assurance"
                      onClick={handleValid}
                    >
                      Valider
                    </button>
                  ) : (
                    <button className="btn crud-submit-btn-assurance">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenvoiPasswordEntreprise;
