import React, {useEffect} from 'react'
// import './ContactForm.css'
import UseSendContactForm from './UseSendContactForm'

const ContactForm = () => {

    const { register, onSubmit, formState, clearErrors, isSuccessfullySubmitted,
        setIsSuccessfullySubmitted,
        setIsFormRequestError,
        isFormRequestError, } = UseSendContactForm()
    
    useEffect(() => {
        if(isSuccessfullySubmitted){
            setTimeout(() => {
                setIsSuccessfullySubmitted(false)
            }, 5000);
        }
        if(isFormRequestError){
            setTimeout(() => {
                setIsFormRequestError(false)
            }, 5000);
        }
        if (formState.errors) {
            setTimeout(() => {
              Object.entries(formState.errors).map(([key]) => {
                return clearErrors(key)
              })
            }, 3000)
        }
    }, [isSuccessfullySubmitted, isFormRequestError, formState, clearErrors, setIsFormRequestError, setIsSuccessfullySubmitted])

    return(
        <div className="contact-form-body hiddenable-form">
            <h2 className="contact-form-title">Ecrivez-nous!</h2>
            <form id="login-form"
                className="register-real-form" 
                onSubmit={onSubmit} >
                <div className="row contact-form-row">
                    <div className="col-md-12 contact-form-item-col">
                        <div className="input-group my-2">
                            <label htmlFor="email">Votre email</label>
                            <input
                                {...register('email')}
                                name="email"
                                id="email"
                                type="text"
                                className="form-control input-with-icon"
                                placeholder="Votre email"
                            />
                            {formState?.errors &&
                                formState?.errors?.email && (
                                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                    {formState?.errors?.email?.message}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 contact-form-item-col">
                        <div className="input-group my-2">
                            <label htmlFor="objet">Objet</label>
                            <input
                                {...register('objet')}
                                name="objet"
                                id="objet"
                                type="text"
                                className="form-control input-with-icon"
                                placeholder="Objet de votre message"
                            />
                            {formState?.errors &&
                                formState?.errors?.objet && (
                                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                    {formState?.errors?.objet?.message}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 contact-form-item-col">
                        <div className="input-group my-2">
                            <label htmlFor="message">Votre message</label>
                            <textarea
                                {...register('message')}
                                name="message"
                                id="message"
                                className="form-control input-with-icon"
                                placeholder="Votre message"
                            />
                            {formState?.errors &&
                                formState?.errors?.message && (
                                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                    {formState?.errors?.message?.message}
                                </div>
                            )}
                        </div>
                    </div>
                    {isSuccessfullySubmitted &&
                        <div className="col-md-12 alert-colify">
                            <div className="alert alert-success easypm-alert-success">
                                Votre message a été envoyé avec succès.
                            </div>
                        </div>
                    }
                    {isFormRequestError &&
                        <div className="col-md-12 alert-colify">
                            <div className="alert alert-danger easypm-alert-danger">
                                Oupss! Une erreur est survenue lors de l'envoi de votre message. Veuillez réessayer plus tard.
                            </div>
                        </div>
                    }
                    <div className="col-md-8 offset-md-4 submit-btn-col">
                        {formState.isSubmitted &&
                            isSuccessfullySubmitted && (
                            <button className="btn-connexion m-t-40">
                                En cours &nbsp;
                                <i className="fas fa-spin fa-spinner"></i>
                            </button>
                        )}
                        {!isSuccessfullySubmitted && (
                            <button type="submit" className="btn-connexion m-t-40">
                                Envoyer
                            </button>
                        )}
                    </div>
                </div>   
            </form>
        </div>
    )
}

export default ContactForm;