import React, { useEffect, useContext } from 'react'
import {
    BootstrapTable,
    TableHeaderColumn,
    ClearSearchButton,
} from 'react-bootstrap-table'
import GestionFacturationAdminTableSkeleton from './GestionFacturationAdminTableSkeleton'
import * as IconlyPack from 'react-iconly'
import { Link, NavLink } from 'react-router-dom'
import GestionFacturationAdminTableData from './GestionFacturationAdminTableData'
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'

var GestionFacturationAdminTable = (props) => {


    const [soins, setSoins] = React.useState(GestionFacturationAdminTableData)

    var [isPageLoading, setIsPageLoading] = React.useState(true)

    useEffect(() => {
        setTimeout(() => {
            setIsPageLoading(false)
        }, 3000)
    }, [soins])

    var handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event')
        onClick()
    }

    var createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText="Effacer"
                btnContextual="btn-warning"
                className="my-custom-class"
                onClick={(e) => handleClearButtonClick(onClick)}
            />
        )
    }

    const options = {
        clearSearch: true,
        clearSearchBtn: createCustomClearButton,
        noDataText: 'Aucune donnée disponible',
    }
    const actionFormatter = (cell, row) => {
        return (
            <div className="table-actions-btn-container-assurance">
                <Link className="action-btn edit-btn"  to={{pathname:`/details-facturation-assurance/${cell}`,state:{id:row.id}} } title="Traitement">
                    <IconlyPack.PaperPlus
                        set="light"
                        primaryColor="#C8C8C8"
                        className="action-btn-icon action-edit-icon"
                        data-toggle="modal"
                        data-target=".show-fiche-modal"
                    />
                </Link>
            </div>
        );
    };



    return (
        <div className="child-table-container">
            <div className="table-body-container">
                <div className="row easypm-table-row m-t-50 m-b-50">
                    <div className="col-md-12 easypm-table-col">
                        {isPageLoading && <GestionFacturationAdminTableSkeleton />}
                        {!isPageLoading && (
                            <div className="table-container">
                                <BootstrapTable
                                    data={soins}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    pagination
                                    search={false}
                                    version="4"
                                    bordered={false}
                                >
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        dataField="id"
                                        isKey={true}
                                        hidden={true}
                                    >
                                        ID
                  </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="150"
                                        dataField="raisonsocial"
                                    >
                                        Raison Sociale
                  </TableHeaderColumn>

                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="150"
                                        dataField="ninea"
                                    >
                                        Ninea
                  </TableHeaderColumn>

                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="150"
                                        dataField="ncontrat"
                                    >
                                        N° contrat 
                  </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="140"
                                        dataField="adresse"
                                    >
                                        Adresse
                  </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="140"
                                        dataField="telephone"
                                    >
                                        Téléphone
                  </TableHeaderColumn>

                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="100"
                                        dataField="id"
                                        dataFormat={(cell, row) => actionFormatter(cell, row)}
                                    >
                                        Action
                  </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GestionFacturationAdminTable
