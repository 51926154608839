import React from 'react'
import Skeleton from 'react-loading-skeleton'

function BeneficiaireSkeleton() {
  return (
    <div className="crud-form">
      <div className="row crud-row">
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="numero_contrat">Genre: </strong>

            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="name">Prénom: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="name">Nom: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="specialite">Date de naissance: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="ninea">Situation Matriomiale: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="ninea">Groupe sanguin: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="ninea">Nationalité: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="email">Email: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="adresse">Adresse: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="telephone">Téléphone: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="first_name">Date de début: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>

        <div className="col-md-6 crud-col-item">
          <div className="form-group crud-form-group">
            <strong htmlFor="first_name">Date de fin: </strong>
            <span className="crud-form-control">
              <Skeleton width={70} />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BeneficiaireSkeleton
