import React, { useContext } from "react";
import { DataContext } from "../../../store/GlobalState";

function EntrepriseDetails() {
  const { state, dispatch } = useContext(DataContext);

  const { entreprise } = state;

  const handleReset = () => {
    dispatch({ type: "EDIT_ENTREPRISE", payload: {} });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Detail entreprise
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="crud-form">
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Num. Contrat: </strong>

                    <span className="crud-form-control">
                      {entreprise?.num_contrat}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="name">Raison sociale: </strong>
                    <span className="crud-form-control">
                      {entreprise?.raison_sociale}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="ninea">NINEA: </strong>
                    <span className="crud-form-control">
                      {entreprise?.ninea}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="email">Email: </strong>
                    <span className="crud-form-control">
                      {entreprise?.email}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="adresse">Adresse: </strong>
                    <span className="crud-form-control">
                      {entreprise?.adresse}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="telephone">Téléphone: </strong>
                    <span className="crud-form-control">
                      {entreprise?.telephone}
                    </span>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="telephone">Tiers Payant Intégral: </strong>
                    <span className="crud-form-control">
                      {entreprise?.tiers_payant ? "Oui" : "Non"}
                    </span>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="telephone">Pourcentage: </strong>
                    <span className="crud-form-control">
                      {entreprise?.pourcentage}%
                    </span>
                  </div>
                </div>

                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="website">Site web: </strong>
                    <span className="crud-form-control">
                      {entreprise?.website}
                    </span>
                  </div>
                </div>

                <div
                  className="col-md-6 offset-md-6 crud-form-action-btn-container"
                  style={{ justifyContent: "flex-end" }}
                >
                  {/* <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button> */}

                  <button
                    className="btn btn-danger cancelleb-btn-assurance"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntrepriseDetails;
