import React, { useContext, useEffect, useState } from "react";
import * as IconlyPack from "react-iconly";
// import { useHistory, useLocation } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
// import { NavLink } from "react-router-dom";
import Header from "../../../Navs/Header";
import DetailsPrestataireTable from "../../../Tables/Assurance/GestionPrestataireAssureur/DetailsPrestataireTable";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
var DetailsPrestataireAssureur = () => {
  const [prestataire, setPrestataire] = useState({});
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.prestataire) {
      setPrestataire(location.state.prestataire);
    }
  }, [location]);
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container align-items-center">
                    <NavLink
                      to="/assurance/prestataire"
                      className="btn  btn-assurance-retour"
                    >
                      <IconlyPack.ArrowLeft set="light" primaryColor="#fff" />
                      <span className="pl-2">Retour</span>
                    </NavLink>
                    <h1 className="page-title pl-2">
                      Prestations de{" "}
                      {prestataire?.raison_sociale ||
                        prestataire?.prenom + " " + prestataire?.nom}
                    </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <DetailsPrestataireTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPrestataireAssureur;
