import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import SoinsData from "./SoinsData";
import SoinsByBeneficiaireData from "./../SoinsByBeneficiaire/SoinsByBeneficiaireData";
import SoinsSkeleton from "./SoinsSkeleton";
import { NavLink, useLocation } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import FicheDeSoinValidationModal from "../../Modals/FicheDeSoinValidationModal";
import moment from "moment";
import { DataContext } from "../../store/GlobalState";
import { formatMoney } from "../../../utils/utils";

const Soins = (props) => {
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const location = useLocation();
  // eslint-disable-next-line
  const [soins, setSoins] = React.useState(SoinsData);
  // eslint-disable-next-line
  const [soinsByBeneficiaire, setSoinsByBeneficiaire] = React.useState(
    SoinsByBeneficiaireData
  );

  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      setIsPageLoading(false);
    } else {
      setIsPageLoading(true);
    }
    // console.log(props)
  }, [auth]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const getNavLinkClass = (path) => {
    return props.location === path ? "active" : "";
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn edit-btn" title="Traitement">
          <IconlyPack.Paper
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".show-fiche-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".show-soin-modal"
            onClick={() => getItem(row)}
          />
        </button>
        {props?.user?.user_type !== "entreprise" && (
          <button className="action-btn edit-btn" title="Modification">
            <IconlyPack.Edit
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-edit-icon"
              data-toggle="modal"
              data-target=".edit-soin-modal"
              onClick={() => getItem(row)}
            />
          </button>
        )}
      </div>
    );
  };

  const getItem = (item) => {
    // console.log("item==>", item);
    dispatch({
      type: "EDIT_PRESTATION",
      payload: item,
    });
  };

  const currencyFormatter = (cell, row) => {
    // const num = 1234567890.1234;
    return formatMoney(row?.prestation?.montant);
  };

  // const getEmployeurNamebyId = (id) => {
  //     let name = ""
  //     for (let i = 0; i < props.entreprises.length; i++) {
  //         const element = props.entreprises[i];

  //         if (element.id === id) {
  //             name = element.nom
  //         }
  //     }
  //     return name;
  // }

  const beneficaireFormatter = (cell, row) => {
    // let =cell.nom
    if (props?.user?.user_type !== "entreprise") {
      return (
        <NavLink
          to={`/ipm/profil-beneficiaire/${row?.prestation?.beneficiaire?.id}`}
          className={
            "nameFormatter " + getNavLinkClass("/ipm/gestion-des-beneficiaires")
          }
        >
          <span className="name">
            {row?.prestation?.beneficiaire?.prenom + " " + row?.prestation?.beneficiaire?.nom}
          </span>{" "}
          <br />
          <strong className="entreprise">
            {" "}
            {row?.prestation?.beneficiaire?.employeur.raison_sociale}{" "}
          </strong>{" "}
          <br />
          <span className="entreprise">
            {" "}
            {row?.prestation?.beneficiaire?.employeur.num_contrat}{" "}
          </span>
        </NavLink>
      );
    } else {
      return (
        <NavLink
          to={`/entreprise/profil-employes/${row?.prestation?.beneficiaire?.id}`}
          className={
            "nameFormatter " +
            getNavLinkClass("/entreprise/gestion-des-employes")
          }
        >
          <span className="name">
            {row?.prestation?.beneficiaire?.prenom || ""}&nbsp;
            {row?.prestation?.beneficiaire?.nom || ""}
          </span>{" "}
          <br />
          <strong className="entreprise">
            {" "}
            {row?.prestation?.beneficiaire?.employeur.raison_sociale}{" "}
          </strong>{" "}
          <br />
          <span className="entreprise">
            {" "}
            {row?.prestation?.beneficiaire?.employeur.num_contrat}{" "}
          </span>
        </NavLink>
      );
    }
  };

  const cellFournisseurFormatter = (cell, row) => {
    let raison_sociale = row?.prestation?.prestataire?.raison_sociale
      ? row?.prestation?.prestataire?.raison_sociale
      : row?.prestation?.prestataire?.prenom +
          " " +
          row?.prestation?.prestataire?.nom || " ";
    return raison_sociale;
  };

  const montantFormatter = (cell, row) => {
    // return xofNumberFormatter.format(row?.prestation?.montant_rembourse);
    return formatMoney(row?.prestation?.montant_rembourse);
  };

  const nameFilter = (cell, row) => {
    return (
      row?.prestation?.beneficiaire?.prenom +
      " " +
      row?.prestation?.beneficiaire?.nom +
      " " +
      row?.prestation?.beneficiaire?.employeur?.raison_sociale
    );
  };

  const typeFormatter = (cell, row) => {
    return row?.prestation?.typedesoin?.nom;
  };

  const etatFormatter = (cell, row) => {
    if (props?.user?.user_type !== "entreprise") {
      if (cell === "rejete") {
        return (
          <span className="badge badge-danger easypm-badge-danger ">
            Rejeté
          </span>
        );
      }

      if (cell === "archive") {
        return (
          <span className="badge badge-primary easypm-badge-primary ">
            Archivé
          </span>
        );
      }

      if (cell === "envoye") {
        return (
          <span className="badge badge-info easypm-badge-info ">Envoyé</span>
        );
      }

      if (cell === "en_attente") {
        return (
          <span className="badge badge-warning easypm-badge-warning ">
            En attente
          </span>
        );
      }

      if (cell === "valide") {
        return (
          <span className="badge badge-default easypm-badge-default ">
            Validé
          </span>
        );
      }

      if (cell === "regle") {
        return (
          <span className="badge badge-success easypm-badge-success ">
            Reglé
          </span>
        );
      }
    } else {
      if (cell === "new") {
        return (
          <span className="badge badge-danger easypm-badge-danger ">
            Nouveau
          </span>
        );
      }
      if (cell === "en_cours") {
        return (
          <span className="badge badge-warning easypm-badge-warning ">
            En cours
          </span>
        );
      }
      if (cell === "traite") {
        return (
          <span className="badge badge-success easypm-badge-success ">
            Traité
          </span>
        );
      }
    }
  };

  const patientFormatter = (cell, row) => {
    return row?.prestation?.ayant_droit
      ? row?.prestation?.ayant_droit?.prenom +
          " " +
          row?.prestation?.ayant_droit?.nom
      : row?.prestation?.beneficiaire?.prenom +
          " " +
          row?.prestation?.beneficiaire?.nom;
  };

  const renderTable = (table) => {
    return (
      <BootstrapTable
        data={table}
        striped={true}
        hover={true}
        condensed={true}
        multiColumnSort={2}
        options={options}
        pagination
        search={false}
        version="4"
        bordered={false}
      >
        <TableHeaderColumn
          thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          dataField="id"
          isKey={true}
          hidden={true}
          filter={{
            type: "TextFilter",
            defaultValue: location?.state?.notification?.data?.id
              ? String(location?.state?.notification?.data?.id)
              : "",
          }}
        >
          ID
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          width="250"
          dataField="beneficiaire"
          filter={{
            type: "TextFilter",
            placeholder: "Chercher un Adhérent ou une entreprise",
          }}
          // dataAlign="center"
          filterValue={nameFilter}
          dataFormat={(cell, row) => beneficaireFormatter(cell, row)}
        >
          {props?.user?.user_type !== "entreprise"
            ? "Participant"
            : "Employés(es)"}
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          dataAlign="center"
          // width="300"
          filter={{
            type: "TextFilter",
            style: { borderRadius: "55px", visibility: "hidden" },
          }}
          dataField="date"
          dataFormat={(cell) => {
            return moment(cell).format("DD/MM/YYYY");
          }}
        >
          Date
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{
            fontWeight: 600,
            fontSize: 16,
            color: "#1F497D",
            whiteSpace: "normal",
            overflowWrap: "break-word",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
            whiteSpace: "normal",
            overflowWrap: "break-word",
          }}
          filter={{
            type: "TextFilter",
            style: { borderRadius: "55px", visibility: "hidden" },
          }}
          // dataAlign="center"
          // width="300"
          dataField="type_prestation"
          dataFormat={(row, cell) => typeFormatter(row, cell)}
        >
          Type de soins
        </TableHeaderColumn>

        <TableHeaderColumn
          thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          filter={{
            type: "TextFilter",
            style: { borderRadius: "55px", visibility: "hidden" },
          }}
          dataAlign="center"
          // width="300"
          dataField="montant_rembourse"
          dataFormat={(cell, row) => montantFormatter(cell, row)}
        >
          Part Ipm
        </TableHeaderColumn>

        <TableHeaderColumn
          thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          filter={{
            type: "TextFilter",
            style: { borderRadius: "55px", visibility: "hidden" },
          }}
          dataAlign="center"
          // width="300"
          dataField="montant"
          dataFormat={(cell, row) => currencyFormatter(cell, row)}
        >
          Montant
        </TableHeaderColumn>

        {/* <TableHeaderColumn
                    thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D' }}
                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)' }}
                    // width="300" dataField='montant_retenu' dataFormat={(cell) => currencyFormatter(cell)}>
                        Montant retenu
                </TableHeaderColumn> */}

        {/* <TableHeaderColumn
          thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          // width="300"
          filter={{
            type: "TextFilter",
            style: { borderRadius: "55px", visibility: "hidden" },
          }}
          dataField="prestataire"
          dataFormat={(cell, row) => cellFournisseurFormatter(cell, row)}
        >
          Fournisseurs
        </TableHeaderColumn> */}
        <TableHeaderColumn
          thStyle={{
            fontWeight: 600,
            fontSize: 16,
            color: "#1F497D",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
          filter={{
            type: "TextFilter",
            style: { borderRadius: "55px", visibility: "hidden" },
          }}
          dataAlign="center"
          // width="200"
          dataField="etat_ipm"
          dataFormat={(cell, row) => etatFormatter(cell, row)}
        >
          Statut
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ fontWeight: 600, fontSize: 16, color: "#1F497D" }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
            padding: 15,
          }}
          filter={{
            type: "TextFilter",
            style: { borderRadius: "55px", visibility: "hidden" },
          }}
          // width="100"
          dataField="id"
          dataFormat={(cell, row) => actionFormatter(cell, row)}
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <SoinsSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                {renderTable(props.prestations)}
                <div
                  className="modal fade easypm-modal show-fiche-modal"
                  id="ShowModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <FicheDeSoinValidationModal />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Soins;
