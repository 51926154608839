import React, { useContext, useEffect } from 'react'
import Header from '../../../Navs/Header'
import NavBarVerticalV2 from '../../navbarVerticalV2/navbarVerticalV2'
import * as IconlyPack from 'react-iconly'
import 'react-toastify/dist/ReactToastify.css'
// import $ from 'jquery'
// import entreprise from '../../../Services/Entreprise'
import { useHistory } from 'react-router-dom'
import { DataContext } from '../../../store/GlobalState'
import CrudExceptionModal from '../../../Modals/CrudExceptionModal'
import UseCreateExceptionForm from './UseCreateExceptionForm'
import ExceptionsTable from '../../../Tables/Exceptions/Exceptions'
import { getDataByID } from '../../../utils/fetchData'

function GestionException() {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    getValues,
    isSuccess,
  } = UseCreateExceptionForm();

  const { state, dispatch } = useContext(DataContext);
  const { auth, beneficiaires, exceptions_by_ipm } = state;

  React.useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (
        auth.user?.user_type === "ipm" ||
        auth?.user?.user_type === "assurance_sante" ||
        auth?.user?.user_type === "admin_ipm"
      ) {
        if (!auth?.user?.admin_type) {
          getDataByID("exceptionbyipm", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "LIST_EXCEPTION_IPM",
                payload: res.data.sort((a, b) => a?.id - b?.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        } else {
          getDataByID("exceptionbyipm", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({
                type: "LIST_EXCEPTION_IPM",
                payload: res.data.sort((a, b) => a?.id - b?.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyipm",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    if (isFormRequestError) {
      setIsSuccessfullySubmitted(false);

      setIsFormRequestError(true);
      setTimeout(() => {
        setIsFormRequestError(false);
      }, 5000);
    }

    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
    // console.log(formState)
  }, [
    isSuccessfullySubmitted,
    isFormRequestError,
    clearErrors,
    formState,
    setIsFormRequestError,
    setIsSuccessfullySubmitted,
  ]);

  return (
    <div className="easypm-tabs-item-content-container">
      <div className="easypm-tabs-item">
        <div className="row adding-row">
          <div className="col-md-2 offset-md-10 adding-col-offseting">
            <div
              className="adding-item-container linkable"
              data-toggle="modal"
              data-target=".add-exception-modal"
            >
              <span className="icon-container">
                <IconlyPack.Plus set="light" primaryColor="gray" />
              </span>
              <span className="adding-item-label">Ajouter une exception</span>
            </div>
          </div>
        </div>
        <ExceptionsTable exceptions={exceptions_by_ipm} />
      </div>
      {/* Modal */}
      <div
        className="modal fade easypm-modal add-exception-modal"
        data-backdrop="static"
        id="AddModalItem"
        tabIndex="-1"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
      >
        <CrudExceptionModal
          ipm={auth?.user}
          register={register}
          onSubmit={onSubmit}
          formState={formState}
          isSuccessfullySubmitted={isSuccessfullySubmitted}
          setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
          setValue={setValue}
          reset={reset}
          getValues={getValues}
          isSuccess={isSuccess}
        />
      </div>
    </div>
  );
}

export default GestionException
