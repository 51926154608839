import { superAdmin } from './Endpoints'
import axios from 'axios'

export default {
    async getListSuperAdmins(config) {
        const response = await axios.get(superAdmin.onGetOrPostSuperAdmin(), config);
        // console.log(response)
        return response.data;
    },
    async postCreateSuperAdmin(data, config) {
        const response = await axios.post(superAdmin.onGetOrPostSuperAdmin(), data, config);
        return response.data;
    },
    async putSuperAdmin(id, data, config) {
        const response = await axios.put(superAdmin.onEditSuperAdmin(id), data, config);
        return response.data;
    },
    async deleteSuperAdmin(id, config) {
        const response = await axios.delete(superAdmin.onDeleteSuperAdmin(id), config);
        return response;
    },

}