import React, { useEffect } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'

registerLocale('fr', fr)

const AcceptGénérationModal = (props) => {
  var [startDate, setStartDate] = React.useState(new Date())
  var [endDate, setEndDate] = React.useState(new Date())
  const [isValid, setIsValid] = React.useState(true)

  useEffect(() => {
    props.register('date_debut')
    props.setValue('date_debut', moment(startDate).format('YYYY-MM-DD'))
    if (endDate && startDate > endDate) {
      setIsValid(false)
    } else {
      props.register('date_fin')
      props.setValue('date_fin', moment(endDate).format('YYYY-MM-DD'))
    }
  }, [endDate, props, startDate])
  
  const handleDate = (date) => {
    setStartDate(date)
    props.register('date_debut')
    props.setValue('date_debut', moment(date).format('YYYY-MM-DD'))
    if (endDate && date > endDate) {
      setIsValid(false)
    }
  }

  const handleDateFin = (date) => {
    setEndDate(date)
    if (date < startDate) {
      setIsValid(false)
    } else {
      setIsValid(true)
      props.register('date_fin')
      props.setValue('date_fin', moment(date).format('YYYY-MM-DD'))
    }
  }

  const handleReset = () => {
    props.reset()
  }
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Gestion d'Oppositions
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            // onClick={() => handleReset()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={props?.onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="libelle">Libellé</label>
                    <input
                      type="text"
                      placeholder="Libellé"
                      name="libelle"
                      className="form-control crud-form-control"
                      id="libelle"
                      {...props.register('libelle')}
                    />
                    {props.formState.errors &&
                      props.formState.errors.libelle && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.libelle?.message}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required crud-interm prestataire-side">
                    <label htmlFor="date_debut">Date de début</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date_debut"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {props.formState.errors &&
                      props.formState.errors.date_debut && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-3">
                          {props.formState.errors.date_debut?.message}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required crud-interm prestataire-side">
                    <label htmlFor="date_fin">Date de Fin</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={endDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date_fin"
                      locale="fr"
                      onChange={(date) => handleDateFin(date)}
                    />
                    {props.formState.errors &&
                      props.formState.errors.date_fin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-3">
                          {props.formState.errors.date_fin?.message}
                        </div>
                      )}
                    {!isValid && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        La date de fin ne doit pas être inférieure à la date de
                        début.
                      </div>
                    )}
                  </div>
                  <input
                    type="hidden"
                    name="type"
                    value={`ipm`}
                    {...props.register('type')}
                  />
                  {props?.ipm?.id && (
                    <input
                      type="hidden"
                      name="cle"
                      value={`${props?.ipm?.id}`}
                      {...props.register('cle')}
                    />
                  )}
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!props?.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AcceptGénérationModal
