import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AssuranceMaladie from "../../../Services/AssuranceMaladie";
import { DataContext } from "../../../store/GlobalState";

const ParametreFacturation = () => {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [isLoading, setIsLoading] = React.useState(false);
  const { register, handleSubmit, formState, clearErrors } = useForm();

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    console.log(data);
    var userRequest = AssuranceMaladie.editAssuranceMaladie(
      auth?.user?.id,
      data,
      requestConfig
    );

    await userRequest
      .then((response) => {
        var userInfos = response;
        console.log("user", userInfos);
        setIsLoading(false);
        dispatch({
          type: "AUTH",
          payload: { token: auth.token, user: userInfos },
        });
        toast.success("Paramétres modifiés avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de l'ajout.");
      });
  };
  return (
    <div className="add-family-form-container pt-5">
      {auth?.user?.id && (
        <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row crud-row">
            <div className="col-md-6 crud-col-item">
              <div className="form-group crud-form-group">
                <label htmlFor="adresse">Montant de la cotisation</label>
                <input
                  {...register("montant_cotisation")}
                  defaultValue={auth?.user?.montant_cotisation || ""}
                  type="number"
                  placeholder="Montant de la côtisation"
                  name="montant_cotisation"
                  className="form-control crud-form-control"
                  id="adresse"
                />
                {formState?.errors && formState?.errors?.montant_cotisation && (
                  <div className="alert alert-danger epm-alert-danger closer m-t-10">
                    {formState?.errors?.montant_cotisation?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 crud-col-item">
              <div className="form-group crud-form-group">
                <label htmlFor="numero_contrat">
                  Nombre de jours avant échéance
                </label>
                <input
                  {...register("nbj_avant_echance", {
                    required: "Ce champ est obligatoire",
                  })}
                  defaultValue={auth?.user?.nbj_avant_echance || ""}
                  type="number"
                  placeholder="Nombre de jours avant échéance"
                  name="nbj_avant_echance"
                  className="form-control crud-form-control"
                  id="numero_contrat"
                />
                {formState?.errors && formState?.errors?.nbj_avant_echance && (
                  <div className="alert alert-danger epm-alert-danger closer m-t-10">
                    {formState?.errors?.nbj_avant_echance?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 crud-col-item">
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  name="auto_rappel_facturation"
                  defaultChecked={
                    auth?.user?.auto_rappel_facturation ? true : false
                  }
                  {...register("auto_rappel_facturation", {
                    required: "Ce champ est obligatoire",
                  })}
                />
                <label class="form-check-label" for="exampleCheck1">
                  Envoyer automaquement un rappel de paiement
                </label>
              </div>
            </div>
            <div className="col-md-3 offset-md-9 crud-form-action-btn-container">
              {isLoading && (
                <button
                  className="btn btn-primary crud-submit-btn-assurance"
                  disabled
                >
                  En cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
              {!isLoading && (
                <button className="btn btn-primary crud-submit-btn-assurance">
                  Modifier
                </button>
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default ParametreFacturation;
