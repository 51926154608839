import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
import "./GestionRendezVous.css";
import AjouterRdv from "./AjouterRdv";
import { NavLink } from "react-router-dom";
import CardItemRdv from "./CardItemRdv";
import { getName, useCurrentUser, useFormData } from "../../../../utils/utils";
import { useQuery } from "../../../../Http/http/query";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
var GestionRendezVous = () => {
  const user = useCurrentUser();
  const [names, setNames] = React.useState([]);
  const query = useQuery(null);
  const reload = React.useCallback(() => {
    query.getData(`/rdvbybeneficiaire/${user.id}/`);
  }, [user, query]);
  React.useEffect(() => {
    if (user?.id) {
      reload();
    }
  }, [user?.id]);
  React.useEffect(() => {
    const data = {};
    for (let i of query.data) {
      if (i.prestataire) {
        const n = getName(i.prestataire);
        if (!data[n]) {
          data[n] = {
            label: n,
            value: i.prestataire.id,
          };
        }
      }
    }
    setNames(Object.keys(data).map((d) => data[d]));
  }, [query.data]);
  const [data, onChange] = useFormData({
    prestataire: "",
    date: "",
  });
  const filter = React.useCallback(() => {
    const filtred = [...query.data].reverse();
    if (data.date || data.prestataire) {
      return filtred.filter((f) => {
        let ok1 = true;
        let ok2 = true;
        if (data.prestataire) {
          ok1 = f.prestataire?.id == data.prestataire;
        }
        if (data.date) {
          ok2 = moment(f.date).format("L") === moment(data.date).format("L");
        }
        return ok1 && ok2;
      });
    }
    return filtred;
  }, [data.date, data.prestataire, query.data]);
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Mes rendez-vous</h1>
                  </div>
                  <div className="row w-full align-items-center">
                    <div className="col-md-6">
                      <NavLink
                        className="linkable btn-rdv-beneficiaire"
                        to="/liste-des-participants-beneficiaire"
                      >
                        <span className="icon-container">
                          <IconlyPack.People
                            set="light"
                            primaryColor="#005D86"
                          />
                        </span>
                        <span className="adding-item-label pl-2">
                          Listes des praticiens
                        </span>
                      </NavLink>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="linkable btn-rdv-beneficiaire"
                        data-toggle="modal"
                        data-target="#AjouterRdvParent"
                      >
                        <span className="icon-container">
                          <IconlyPack.PaperPlus
                            set="light"
                            primaryColor="#005D86"
                          />
                        </span>
                        <span className="adding-item-label pl-2">
                          Nouveau rendez-vous
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-content-container mt-4">
                <form>
                  <div className="row align-items-center">
                    <div className="col-xl-1 col-lg-1 col-md-2">
                      <label className="label-filter-rdv">Filter</label>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 py-4">
                      <div class="form-group">
                        <select
                          value={data.prestataire}
                          onChange={(e) =>
                            onChange("prestataire", e.currentTarget.value)
                          }
                          class="form-control select-filere-filter-module"
                          id="exampleFormControlSelect1"
                        >
                          <option value={""}>Nom</option>
                          {names.map((n) => (
                            <option value={n.value}>{n.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 py-4">
                      <div class="form-group">
                        <div
                          class="input-group mb-2"
                          style={{ background: "#F7F7F7" }}
                        >
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <IconlyPack.Calendar
                                set="bold"
                                primaryColor="#DADADA"
                              />
                            </div>
                          </div>
                          <input
                            type="date"
                            value={data.date}
                            onChange={(e) =>
                              onChange("date", e.currentTarget.value)
                            }
                            class="form-control input-add-event pl-5"
                            id="inlineFormInputGroup"
                            placeholder="Choisir une date"
                            style={{ background: "#F7F7F7" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="py-5">
                  {query.loading && query.data.length === 0 && (
                    <div className="row">
                      {[1, 2, 3, 4, 5, 6].map((i) => {
                        return (
                          <div key={i} className="col-md-4 pb-3">
                            <Skeleton height={250} />
                          </div>
                        );
                      })}
                    </div>
                  )}

                  <div className="row">
                    {filter().map((f) => (
                      <div key={f.id} className="col-md-4 pb-3">
                        <CardItemRdv reload={reload} item={f} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="AjouterRdvParent"
          tabindex="-1"
          aria-labelledby="AjouterRdvParentLabel"
          aria-hidden="true"
        >
          <AjouterRdv
            onDone={() => {
              window.$("#AjouterRdvParent").modal("hide");
              reload();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GestionRendezVous;
