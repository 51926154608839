import React, { useEffect, useContext } from 'react'
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from 'react-bootstrap-table'
import GestionPrestataireAdminTableSkeleton from './GestionPrestataireAdminTableSkeleton'
import * as IconlyPack from 'react-iconly'
import { Link, NavLink } from 'react-router-dom'
import GestionPrestataireAdminTableData from './GestionPrestataireAdminTableData'
import RenvoiPasswordPrestataireAdminModal from './RenvoiPasswordPrestataireAdminModal'
import ModifierPrestataireAdminModal from '../../../Backend/Assurance/AdminAssureur/GestionPrestataireAssureur/ModifierPrestataireAdminModal'
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'

var GestionPrestataireAdminTable = (props) => {

 
  const [soins, setSoins] = React.useState(GestionPrestataireAdminTableData)

  var [isPageLoading, setIsPageLoading] = React.useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false)
    }, 3000)
  }, [soins])

  var handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event')
    onClick()
  }

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    )
  }

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: 'Aucune donnée disponible',
  }
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <Link className="action-btn show-btn mr-2" to={{pathname:`/details-prestataire-assurance/${cell}`,state:{id:row.id}} }>
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".show-fiche-modal"
          />
        </Link>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#ModifierPrestataireAdminModal"
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Password
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#RenvoiPasswordPrestataireAdminModal"
          />
        </button>
      </div>
    );
  };
  


  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <GestionPrestataireAdminTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={soins}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="150"
                    dataField="nom"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="150"
                    dataField="specialite"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Spécialité
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="date"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Date de fin
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="telephone"
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Téléphone
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="agreement"
                  >
                    Agréement
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div class="modal fade" id="ModifierPrestataireAdminModal" tabindex="-1" aria-labelledby="ModifierPrestataireAdminModalLabel" aria-hidden="true">
                <ModifierPrestataireAdminModal />
          </div>
          <div class="modal fade" id="RenvoiPasswordPrestataireAdminModal" tabindex="-1" aria-labelledby="RenvoiPasswordPrestataireAdminModalLabel" aria-hidden="true">
                <RenvoiPasswordPrestataireAdminModal />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GestionPrestataireAdminTable
