import React, { useContext, useEffect, useRef } from "react";
import FeatherIcon from "feather-icons-react";
import * as IconlyPack from "react-iconly";
import NavBarMobileV2 from "../Backend/navbarMobileV2/navbarMobileV2";
import { NavLink, useHistory } from "react-router-dom";
import isAuthenticate from "../Modules/Secure/Secure";
import { DataContext } from "../store/GlobalState";
import QrCodeModal from "../Modals/QrCodeModal";
import { putData, getDataByID, postData } from "../utils/fetchData";
import TimeOutLogin from "../Modules/TimeOutLogin/TimeOutLogin";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";
import moment from "moment";

var Header = () => {
  const { state, dispatch } = useContext(DataContext);
  const timer = TimeOutLogin(60 * 10);
  const { auth, prestataire, notifications, fichevalidbyprestataire } = state;
  const [searchTerm, setSearchTerm] = React.useState("");
  const [results, setResults] = React.useState([]);
  const [searchResults, setSearchResults] = React.useState([]);
  const [isClicked, setIsClicked] = React.useState(false);
  var [showActions, setShowActions] = React.useState(false);
  var [showWarning, setShowWarning] = React.useState(false);
  var [showNotifications, setShowNotifications] = React.useState(false);
  const [userType, setUserType] = React.useState();
  var isExpired = isAuthenticate();
  const token = window.sessionStorage.getItem("authToken");
  let history = useHistory();
  const wrapperRef = useRef(null);
  const notifRef = useRef(null);

  useEffect(() => {
    setUserType(window.sessionStorage.getItem("userType"));
    if (
      window.$(".qr-code-modal").data("bs.modal")?._isShown !== undefined &&
      window.$(".qr-code-modal").data("bs.modal")?._isShown === false
    ) {
      setIsClicked(false);
    }

    if (
      auth?.token &&
      auth?.user?.id &&
      auth?.user?.user_type === "prestataire"
    ) {
      setResults(fichevalidbyprestataire);
      getDataByID(
        "fichedesoinvalidatebyprestataire",
        auth?.user?.id,
        auth?.token
      ).then((res) => {
        if (res?.length) {
          setResults(res.sort((a, b) => b?.id - a?.id));
        }
      });
    }
  }, [auth]);

  React.useEffect(() => {
    if (searchTerm) {
      let resultats = null;
      if (auth?.user?.user_type === "prestataire" && results?.length) {
        resultats = results?.filter((fiche) => {
          let prenom = fiche?.beneficiaire?.prenom;
          let nom = fiche?.beneficiaire?.nom;
          return (
            prenom.toLowerCase().includes(searchTerm.toLowerCase()) ||
            nom.toLowerCase().includes(searchTerm.toLowerCase())
          );
        });
      }
      setSearchResults(resultats);
    } else {
      setSearchResults([]);
      // if (results?.length) {
      //   dispatch({
      //     type: 'LISTE_FICHE_VALID_BY_PRESTATAIRE',
      //     payload: results,
      //   })
      // }
    }
  }, [searchTerm]);

  useEffect(() => {
    if (token && auth?.token && auth?.user?.id) {
      let { exp } = jwtDecode(auth?.token);
      if (exp < moment().unix()) {
        console.log("expired");
      } else {
        const expireDate = moment.unix(exp);
        const rest = expireDate.diff(moment(), "minutes");

        if (rest <= 5 && !showWarning) {
          // console.log('refresh to', rest, 'minutes')
          try {
            // call refresh token to renew the token
            postData(
              "auth/refresh-token/",
              { token: auth?.token },
              auth?.token
            ).then((response) => {
              // console.log('new token', response)
              window.sessionStorage.setItem("authToken", response?.token);
              dispatch({
                type: "AUTH",
                payload: { token: response?.token, user: auth?.user },
              });
            });
          } catch (ex) {
            console.log("error", ex?.response);
            // return false;
          }
        }
      }
    }
  });

  const handleClickOutside = (event) => {
    if (notifRef.current && !notifRef.current.contains(event.target)) {
      setShowNotifications(false);
    } else {
      setShowNotifications(!showNotifications);
    }
  };

  const handleOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowActions(false);
    } else {
      setShowActions(!showActions);
    }
  };

  useEffect(() => {
    if (timer === 0 && auth?.token) {
      // return onLogout()
    }

    if (timer && timer <= 60) {
      setShowWarning(true);
    }
  }, [auth, timer]);

  useEffect(() => {
    if (showWarning) {
      let timerInterval;
      Swal.fire({
        title: "Session expirée !",
        customClass: {
          confirmButton: "crud-submit-btn",
        },
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Renouveler la session!",
        html: "Vous serez déconnecté dans <b></b> secondes.",
        timer: timer * 1000,
        didOpen: () => {
          // Swal.showLoading()
          timerInterval = setInterval(() => {
            const content = Swal.getHtmlContainer();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
              }
            }
          }, 1000);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return postData(
            "auth/refresh-token/",
            { token: auth?.token },
            auth?.token
          )
            .then((response) => {
              // console.log('expire', response)
              window.sessionStorage.setItem("authToken", response?.token);
              return dispatch({
                type: "AUTH",
                payload: { token: response?.token, user: auth?.user },
              });
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        // allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Succés!", "Votre session est renouvelée.", "success").then(
            (res) => {
              if (res.isConfirmed) {
                setShowWarning(false);
              }
            }
          );
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
        }
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log("I was closed by the timer");
          Swal.close();
          return onLogout();
        }
      });
    }
  }, [showWarning]);

  React.useEffect(() => {
    if (isClicked) {
      window.$(".qr-code-modal").modal("show");
    }
  }, [isClicked]);

  const onRedirectToHomepage = () => {
    history.push("/");
  };

  var onLogout = () => {
    // e.preventDefault()
    // sessionStorage.removeItem("userType");
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("etsfInfos");
    sessionStorage.removeItem("userID");

    sessionStorage.removeItem("isSuperAdmin");
    dispatch({ type: "AUTH", payload: {} });

    if (sessionStorage.getItem("userType") === "assureur") {
      // history.push("/connexion-assureur");
      window.location = "/connexion-assureur";
      sessionStorage.removeItem("userType");
    } else {
      // history.push("/connexion");
      window.location = "/connexion";
      sessionStorage.removeItem("userType");
    }
  };

  const onShowAndHideNotification = () => {
    setShowNotifications(!showNotifications);
  };

  const onShowAndHideHeaderAction = (e) => {
    setShowActions(!showActions);
  };

  const handleType = async (e, item) => {
    window.sessionStorage.setItem("notifId", item?.id);
    setShowNotifications(!showNotifications);
    if (
      item?.message !== "Demande d'agréement" &&
      item?.message !== "Notification de modification pour un beneficiaire" &&
      item?.message !== "Notification de modification pour un ayant droit" &&
      item?.message !== "Ajout d'un ayant droit pour validation" &&
      item?.message !== "Validation de l'adhésion de votre employé" &&
      !item?.message.includes("Demande d'avis et de validation du prestataire")
    ) {
      await putData(
        `notification/${item?.id}/`,
        { valid: true },
        auth?.token
      ).then((res) => {
        console.log("res", res);
        if (
          auth?.user?.user_type === "ipm" ||
          auth?.user?.user_type === "assurance_sante"
        ) {
          if (auth?.user?.user_type === "admin_ipm") {
            getDataByID(
              "notificationbyipm",
              auth?.user?.parent,
              auth?.token
            ).then((resp) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
              });
            });
          } else {
            getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
              (resp) => {
                // console.log('notif', resp)

                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
                });
              }
            );
          }
        }

        if (auth?.user?.user_type === "assureur") {
          if (auth?.user?.admin_type === "assureur") {
            getDataByID(
              "notificationbyipm",
              auth?.user?.parent,
              auth?.token
            ).then((resp) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
              });
            });
          } else {
            getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
              (resp) => {
                // console.log('notif', resp)

                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
                });
              }
            );
          }
        }

        if (auth?.user?.user_type === "entreprise") {
          if (auth?.user?.admin_type) {
            getDataByID(
              "notificationbyentreprise",
              auth?.user?.parent,
              auth?.token
            ).then((res) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            });
          } else {
            getDataByID(
              "notificationbyentreprise",
              auth?.user?.id,
              auth?.token
            ).then((res) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            });
          }
        }

        if (auth?.user?.user_type === "prestataire") {
          getDataByID("facturebyprestataire", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_FACTURE_BY_PRESTATAIRE",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        }

        if (auth?.user?.is_superuser || auth.user?.user_type === "super_ipm") {
          getDataByID(
            "notificationbysuperadmin",
            auth?.user?.id,
            auth?.token
          ).then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      });
    }
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClick = async (e, item) => {
    // e.preventDefault();
    // console.log("item", item);
    setSearchTerm("");
  };

  return (
    <>
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering">
          <div className="logo-item linkable" onClick={onRedirectToHomepage}>
            <span className="logo-firsty">EASY</span>&nbsp;
            <span className="logo-secondy">PM</span>
          </div>

          <div className="header-others-items">
            <div className="search-form-container">
              <div className="search-form-content">
                <input
                  name="search"
                  value={searchTerm}
                  className="form-control search-input"
                  onChange={handleChange}
                />
                <label>
                  <IconlyPack.Search
                    set="light"
                    primaryColor="black"
                    className="search-icony"
                  />
                </label>
              </div>
              {searchResults?.length ? (
                <div className="search-result-container">
                  {searchResults.map((item) => (
                    <NavLink
                      to=""
                      className="search-result-item"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleClick(e, item)}
                      key={item?.id}
                      to={{
                        pathname: "/prestataire/fiche-de-soin-valide",
                        state: { item },
                      }}
                    >
                      {item?.beneficiaire?.prenom +
                        " " +
                        item?.beneficiaire?.nom}
                    </NavLink>
                  ))}
                </div>
              ) : null}
            </div>
            <div
              className="add-item-container linkable"
              onClick={(e) => onShowAndHideHeaderAction(e)}
            >
              <div className="add-item-content" ref={wrapperRef}>
                <IconlyPack.Plus set="bold" primaryColor="#02A69C" />
              </div>
            </div>
            {userType === "prestataire" && (
              <div
                className="notification-item-container linkable"
                onClick={() => {
                  setIsClicked(true);
                }}
              >
                <div className="notification-item-content">
                  <IconlyPack.Scan set="light" primaryColor="black" />
                </div>
              </div>
            )}

            <div
              className="notification-item-container linkable"
              onClick={() => onShowAndHideNotification()}
            >
              <div className="notification-item-content" ref={notifRef}>
                <IconlyPack.Notification set="light" primaryColor="black" />
                <span className="notification-counter">
                  {notifications?.filter((n) => n.valid === false).length}
                </span>
              </div>
            </div>
            <div className="connected-user-container">
              <div className="connected-user-content ml-auto">
                <div className="dropdown dis-flex">
                  <button
                    className="user-acount-dashboard-header"
                    type="button"
                    id="dropdownMenuButtons"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display">
                      <img
                        src={
                          "https://ui-avatars.com/api/?name=" +
                          (auth?.user?.user_type !== "prestataire"
                            ? auth?.user?.raison_sociale
                              ? auth?.user?.raison_sociale.replace(/ /g, "+")
                              : (
                                  auth?.user?.prenom +
                                  " " +
                                  auth?.user?.nom
                                ).replace(/ /g, "+")
                            : prestataire?.raison_sociale
                            ? prestataire?.raison_sociale.replace(/ /g, "+")
                            : (
                                prestataire?.prenom +
                                " " +
                                prestataire?.nom
                              ).replace(/ /g, "+"))
                        }
                        className="user-avatar"
                        alt="User Avatar"
                      />
                      <FeatherIcon
                        className="icon-user-acount-dashboard"
                        icon="chevron-down"
                      />
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom"
                    aria-labelledby="dropdownMenuButtons"
                  >
                    {!isExpired && auth.user && (
                      <h3 className="userName">
                        {/* {" "} */}
                        {auth?.user?.user_type !== "prestataire"
                          ? auth?.user?.raison_sociale
                            ? auth?.user?.raison_sociale
                            : auth?.user?.prenom + " " + auth?.user?.nom
                          : prestataire?.raison_sociale
                          ? prestataire?.raison_sociale
                          : prestataire?.prenom + " " + prestataire?.nom}{" "}
                      </h3>
                    )}
                    <div className="dropdown-divider"></div>
                    <NavLink
                      to={
                        auth?.user?.user_type === "ipm" ||
                        auth?.user?.user_type === "assurance_sante" ||
                        auth?.user?.user_type === "admin_ipm"
                          ? "/ipm/change-password"
                          : auth?.user?.user_type === "entreprise"
                          ? "/entreprise/change-password"
                          : auth?.user?.user_type === "prestataire"
                          ? "/prestataire/change-password"
                          : auth?.user?.user_type === "assureur"
                          ? "/assurance/change-password"
                          : "/admin/change-password"
                      }
                      className="dropdown-item item-dropdown-custom"
                    >
                      Changer votre mot de passe
                    </NavLink>
                    <div className="dropdown-divider"></div>
                    <button
                      onClick={(e) => onLogout()}
                      className="dropdown-item item-dropdown-custom"
                    >
                      {" "}
                      Déconnexion
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hiddenable-nav-mobile-container no-view-desktop">
          {/* Navbar Mobile */}
          <div className="hiddenable-nav-mobile-content">
            <NavBarMobileV2 />
          </div>
        </div>

        {showActions && (
          <div
            className={
              "header-hiddenable-section " + (showActions ? "animated" : "")
            }
          >
            <div className="heder-hiddenable-title">Créer</div>
            <ul className="header-hiddenable-ul">
              <li>
                {(userType === "ipm" ||
                  userType === "admin_ipm" ||
                  userType === "assurance_sante") && (
                  <NavLink
                    to="/ipm/gestion-des-beneficiaires"
                    className="header-hiddenable-link"
                  >
                    Adhérent
                  </NavLink>
                )}
                {userType === "assureur" && (
                  <NavLink
                    to="/assurance/assures"
                    className="header-hiddenable-link"
                  >
                    Assurés
                  </NavLink>
                )}
                {auth?.user?.user_type === "entreprise" && (
                  <NavLink
                    to="/entreprise/gestion-des-employes"
                    className="header-hiddenable-link"
                  >
                    Employés
                  </NavLink>
                )}

                {auth?.user?.user_type === "prestataire" && (
                  <NavLink
                    to="/prestataire/gestion-des-factures"
                    className="header-hiddenable-link"
                  >
                    Facturation
                  </NavLink>
                )}
              </li>
              {(userType === "ipm" ||
                userType === "admin_ipm" ||
                userType === "assurance_sante") && (
                <li>
                  <NavLink
                    to="/ipm/gestion-des-prestataires"
                    className="header-hiddenable-link"
                  >
                    Prestataire
                  </NavLink>
                </li>
              )}

              {userType === "assureur" && (
                <li>
                  <NavLink
                    to="/assurance/prestataire"
                    className="header-hiddenable-link"
                  >
                    Prestataire
                  </NavLink>
                </li>
              )}

              <li>
                {(userType === "ipm" ||
                  userType === "admin_ipm" ||
                  userType === "assurance_sante") && (
                  <NavLink
                    to="/ipm/parametres"
                    className="header-hiddenable-link"
                  >
                    Admin IPM
                  </NavLink>
                )}
                {userType === "assureur" && (
                  <NavLink
                    to="/assurance/parametre"
                    className="header-hiddenable-link"
                  >
                    Admin Assurance
                  </NavLink>
                )}
                {auth?.user?.user_type === "entreprise" && (
                  <NavLink
                    to="/entreprise/parametres"
                    className="header-hiddenable-link"
                  >
                    Admin Entreprise
                  </NavLink>
                )}

                {auth?.user?.user_type === "prestataire" && (
                  <NavLink
                    to="/prestataire/gestion-des-devis"
                    className="header-hiddenable-link"
                  >
                    Devis
                  </NavLink>
                )}
              </li>
            </ul>
          </div>
        )}

        {showNotifications && (
          <div className={"header-hiddenable-notification-section"}>
            <div className="header-hiddenable-notification-title">
              Notifications
            </div>

            <ul className="header-hiddenable-notification-ul">
              {notifications?.filter((n) => n.valid === false)?.length ? (
                notifications
                  ?.filter((n) => n.valid === false)
                  ?.map((notif) => {
                    return (
                      <li
                        className="header-hiddenable-notification-li"
                        key={notif?.id}
                      >
                        <NavLink
                          to={{
                            pathname: `${`${
                              notif?.message === "Demande d'agréement"
                                ? auth?.user?.user_type === "assureur"
                                  ? `/assurance/prestataire/${notif?.data?.id}`
                                  : `/ipm/prestataire/${notif?.data?.id}`
                                : notif?.message ===
                                    "Notification de modification pour un beneficiaire" ||
                                  notif?.message ===
                                    "Notification de modification pour un ayant droit"
                                ? auth?.user?.user_type !== "entreprise"
                                  ? auth?.user?.user_type === "assureur"
                                    ? `/assurance/notification/${notif?.id}`
                                    : `/ipm/notification/${notif?.id}`
                                  : `/entreprise/notification/${notif?.id}`
                                : notif?.data?.facture
                                ? auth?.user?.user_type === "entreprise"
                                  ? `/entreprise/gestion-des-factures`
                                  : auth?.user?.user_type === "prestataire"
                                  ? `/prestataire/gestion-des-factures`
                                  : auth?.user?.user_type === "assureur"
                                  ? `/assurance/facturation-fournisseur`
                                  : `/ipm/gestion-de-la-facturation`
                                : notif?.data?.document &&
                                  notif?.message !== "Demande de devis" &&
                                  notif?.message.includes(
                                    "Demande de decompte du beneficiaire"
                                  ) === false &&
                                  notif?.message.includes(
                                    "Le decompte concernant le devis du beneficiaire"
                                  ) === false &&
                                  notif?.message.includes(
                                    "Vous venez de reçevoir un decompte de"
                                  ) === false &&
                                  notif?.message.includes(
                                    "vient de reçevoir un devis du prestataire"
                                  ) === false
                                ? auth?.user?.user_type === "prestataire"
                                  ? `/prestataire/devis/${notif?.data?.id}`
                                  : auth?.user?.user_type === "entreprise"
                                  ? `/entreprise/devis/${notif?.data?.id}`
                                  : auth?.user?.user_type === "assureur"
                                  ? `/assurance/devis/${notif?.data?.id}`
                                  : `/ipm/devis/${notif?.data?.id}`
                                : notif?.message ===
                                  "Notification de modification prestataire"
                                ? `/admin/prestataire/${notif?.data?.id}`
                                : notif?.message.includes(
                                    "Notification d'incription d'une entreprise"
                                  ) ||
                                  notif?.message ===
                                    "Notification de modification entreprise"
                                ? `/admin/entreprise/${notif?.data?.id}`
                                : notif?.message ===
                                  "Notification de modification beneficiaire"
                                ? `/admin/beneficiaire/${notif?.data?.id}`
                                : notif?.message === "Ouverture des droits" ||
                                  notif?.message === "Demande d'adhesion" ||
                                  notif?.message ===
                                    "Désactivation d'un compte Adhérent"
                                ? auth?.user?.user_type !== "entreprise"
                                  ? auth?.user?.user_type === "assureur"
                                    ? `/assurance/beneficiaire/${
                                        notif?.data?.id !== undefined
                                          ? notif?.data?.id
                                          : notif?.data?.i?.id
                                      }`
                                    : `/ipm/beneficiaire/${
                                        notif?.data?.id !== undefined
                                          ? notif?.data?.id
                                          : notif?.data?.i?.id
                                      }`
                                  : `/entreprise/beneficiaire/${
                                      notif?.data?.id !== undefined
                                        ? notif?.data?.id
                                        : notif?.data?.i?.id
                                    }`
                                : notif?.message ===
                                  "Ajout d'un ayant droit pour validation"
                                ? auth?.user?.user_type === "assureur"
                                  ? `/assurance/ayantdroit/${notif?.data?.id}`
                                  : `/ipm/ayantdroit/${notif?.data?.id}`
                                : notif?.message === "Demande de fiche de soin"
                                ? auth?.user?.user_type === "assureur"
                                  ? `/assurance/fichedesoin/${notif?.data?.id}`
                                  : `/ipm/fichedesoin/${notif?.data?.id}`
                                : notif?.message ===
                                    "Ajout de beneficiaire dans votre entreprise" ||
                                  notif?.message ===
                                    "Validation de l'adhésion de votre employé"
                                ? `/entreprise/beneficiaire/${notif?.data?.id}`
                                : notif?.message === "Demande de devis"
                                ? `/prestataire/gestion-des-devis`
                                : notif?.message.includes(
                                    "Demande de decompte du beneficiaire"
                                  ) ||
                                  notif?.message.includes(
                                    "Le decompte concernant le devis du beneficiaire"
                                  )
                                ? auth?.user?.user_type === "assureur"
                                  ? `/assurance/devis`
                                  : `/ipm/gestion-des-devis`
                                : notif?.message ===
                                  "Vous avez reçu une demande de remboursement"
                                ? auth?.user?.user_type === "assureur"
                                  ? `/assurance/remboursement`
                                  : `/ipm/gestion-des-remboursements`
                                : notif?.message.includes(
                                    "Vous venez de reçevoir un decompte de"
                                  ) ||
                                  notif?.message.includes(
                                    "vient de reçevoir un devis du prestataire"
                                  )
                                ? auth?.user?.user_type === "assureur"
                                  ? `/assurance/devis`
                                  : `/ipm/gestion-des-devis`
                                : notif?.message.includes(
                                    "Demande d'avis et de validation du prestataire"
                                  )
                                ? auth?.user?.user_type === "assureur"
                                  ? `/assurance/prestation-validation`
                                  : `/ipm/prestation-validation`
                                : ""
                            }`}`,
                            state: {
                              notification: notif,
                            },
                          }}
                          onClick={(e) => handleType(e, notif)}
                          className="notification-item-link"
                        >
                          <p>{notif?.message}</p>
                          <span>19/04/2021</span>
                        </NavLink>
                      </li>
                    );
                  })
              ) : (
                <label className="header-hiddenable-notification-li">
                  Aucune notification
                </label>
              )}
            </ul>
          </div>
        )}
      </nav>
      // Modal Qr Code
      {isClicked && (
        <div
          className="modal fade easypm-modal qr-code-modal"
          id="DeleteModalItem"
          tabIndex="-1"
          aria-labelledby="DeleteModalItemLabel"
          aria-hidden="true"
          data-backdrop="static"
        >
          <QrCodeModal />
        </div>
      )}
    </>
  );
};

export default Header;
