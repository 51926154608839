import React, { useContext } from "react";
import { toast } from "react-toastify";
import baseUrl from "../../../../Http/backend-base-url";
import Prestataire from "../../../Services/Prestataire";
import { DataContext } from "../../../store/GlobalState";

const LogoPrestataire = () => {
  const { state, dispatch } = useContext(DataContext);
  const { auth, prestataire } = state;
  const [avatar, setAvatar] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };
  const changeLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file?.type !== "image/jpeg" &&
        file?.type !== "image/png" &&
        file?.type !== "image/jpg" &&
        file?.type !== "image/gif"
      ) {
        return toast.error(
          "Veuiller uploader une image de type jpg ou jpeg ou png ou gif"
        );
      }
      // console.log('file', file)

      //   setData({ ...data, avatar: file });
      setIsLoading(true);
      const fd = new FormData();
      fd.append("logo", file);
      setAvatar(file);
      let userRequest = Prestataire.putPrestataire(
        prestataire.id,
        fd,
        requestConfig
      );

      userRequest
        .then((response) => {
          var userInfos = response;
          console.log("user", userInfos);
          setIsLoading(false);
          dispatch({ type: "EDIT_PRESTATAIRE", payload: userInfos });
          dispatch({
            type: "AUTH",
            payload: { token: auth.token, user: userInfos },
          });

          toast.success("Logo ajouté avec succès.");
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };
  return (
    <div className="easypm-tabs-item-content-container">
      <div className="easypm-tabs-item">
        <div className="add-family-form-container">
          <form className="crud-form">
            <div className="row crud-row" style={{ justifyContent: "center" }}>
              <div
                className="profile-avatar-container"
                style={{ width: "200px", height: "200px" }}
              >
                {/* <img
                  className="profile-avatar"
                  src={avatar ? URL.createObjectURL(avatar) : imgUrl}
                /> */}
                <img
                  src={
                    avatar
                      ? URL.createObjectURL(avatar)
                      : baseUrl + prestataire?.logo
                  }
                  className="profile-avatar"
                  alt="Logo"
                />
                {isLoading && (
                  <span className="charge">
                    <i
                      className="fas fa-spin fa-spinner"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </span>
                )}

                <span>
                  <i className="fas fa-camera"></i>
                  <b className="m-1">Changer</b>
                  <input
                    type="file"
                    name="avatar"
                    id="file_up"
                    accept="image/*"
                    onChange={changeLogo}
                  />
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LogoPrestataire;
