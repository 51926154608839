import React, { useEffect, useState } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import { toast } from "react-toastify";
import { NavLink, Link, useParams, useHistory } from "react-router-dom";
import DefaultAvatar from "../../../../images/others/default-profile-avatar.png";
import Header from "../../../Navs/Header";
import BeneficiaireProfileSkeleton from "../../BeneficiaireProfile/BeneficiaireProfileSkeleton";
import { getDataByID, putData } from "../../../utils/fetchData";
import moment from "moment";
import { DataContext } from "../../../store/GlobalState";
import baseUrl from "../../../../Http/backend-base-url";

function Notification() {
  const [notification, setNotification] = useState({});
  var [isPageLoading, setIsPageLoading] = React.useState(false);
  const { state, dispatch } = React.useContext(DataContext);

  const { auth } = state;
  const [isloading, setIsloading] = useState(false);
  const [valid, setValid] = useState(false);
  const history = useHistory();
  let userType = window.sessionStorage.getItem("userType");
  let token = window.sessionStorage.getItem("authToken");

  const params = useParams();

  useEffect(() => {
    console.log("params", params);
    getDataByID("notification", params?.id, token)
      .then((res) => {
        console.log("res", res);
        setValid(res.valid);
        setNotification(res);
        setIsPageLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setIsPageLoading(false);
      });
  }, [params]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('valid')
    if (!valid) {
      setIsloading(true);
      putData("notification/" + notification?.id + "/", { valid: true }, token)
        .then((res) => {
          console.log("res", res);
          setIsloading(false);
          toast.success("Modification validée avec succès.");
          if (userType !== "entreprise") {
            if (auth?.user?.admin_type) {
              getDataByID(
                "notificationbyipm",
                auth?.user?.parent,
                auth?.token
              ).then((resp) => {
                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
                });
              });
            } else {
              getDataByID(
                "notificationbyipm",
                auth?.user?.id,
                auth?.token
              ).then((resp) => {
                // console.log('notif', resp)
                window.sessionStorage.removeItem("notifId");
                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
                });
              });
            }
            if (auth?.user?.user_type === "assureur") {
              history.push(`/assurance/details-assures/${res.beneficiaire}`);
            } else {
              history.push(`/ipm/profil-beneficiaire/${res.beneficiaire}`);
            }
          } else {
            getDataByID(
              "notificationbyentreprise",
              auth?.user?.id,
              auth?.token
            ).then((res) => {
              console.log("notif", res);
              window.sessionStorage.removeItem("notifId");
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            });
            history.push(`/entreprise/profil-employes/${res.beneficiaire}`);
          }
        })
        .catch((e) => {
          console.log("error", e);
          setIsloading(false);
          setIsloading(false);
          toast.error("Oupss! Une erreur est survenue lors de la validation.");
        });
    } else {
      toast.error("La modification est déja validée.");
    }
  };

  const handleRejet = (e) => {
    e.preventDefault();
    // console.log('valid')
    if (!notification?.is_archived) {
      setIsloading(true);
      putData(
        "notification/" + notification?.id + "/",
        { is_archived: true },
        token
      )
        .then((res) => {
          console.log("res", res);
          setIsloading(false);
          toast.success("Modification rejetée.");
          if (userType !== "entreprise") {
            if (auth?.user?.admin_type) {
              getDataByID(
                "notificationbyipm",
                auth?.user?.parent,
                auth?.token
              ).then((resp) => {
                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
                });
              });
            } else {
              getDataByID(
                "notificationbyipm",
                auth?.user?.id,
                auth?.token
              ).then((resp) => {
                // console.log('notif', resp)
                window.sessionStorage.removeItem("notifId");
                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
                });
              });
            }
            if (auth?.user?.user_type === "assureur") {
              history.push(`/assurance/details-assures/${res.beneficiaire}`);
            } else {
              history.push(`/ipm/profil-beneficiaire/${res.beneficiaire}`);
            }
          } else {
            if (auth?.user?.admin_type) {
              getDataByID(
                "notificationbyentreprise",
                auth?.user?.parent,
                auth?.token
              ).then((res) => {
                window.sessionStorage.removeItem("notifId");
                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                });
              });
            } else {
              getDataByID(
                "notificationbyentreprise",
                auth?.user?.id,
                auth?.token
              ).then((res) => {
                window.sessionStorage.removeItem("notifId");
                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                });
              });
            }
            history.push(`/entreprise/profil-employes/${res.beneficiaire}`);
          }
        })
        .catch((e) => {
          console.log("error", e);
          setIsloading(false);
          setIsloading(false);
          toast.error("Oupss! Une erreur est survenue lors du rejet.");
        });
    } else {
      toast.error("La modification est déja rejetée.");
    }
  };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row breadcrumbs-and-actions-row"></div>

        <div className="row">
          {/* Navbar Desktop  */}
          <div
            className={
              userType === "assureur"
                ? "nav-container nav-assurance-color"
                : "nav-container"
            }
          >
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 ben-profile-container">
            <div className="page-body-container">
              <div className="row beneficiaire-profile-row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 profile-infos-perso-container profile-item">
                  <div className="profile-infos-ben">
                    <h2 className="section-light-title">
                      <div className="light-title">
                        {userType !== "entreprise"
                          ? !notification?.ayant_droit
                            ? "Données de l'adhérent initiales"
                            : "Données du ayant droit initiales"
                          : !notification?.ayant_droit
                          ? "Données de l'employé(e) initiales"
                          : "Données du ayant droit initiales"}
                      </div>
                    </h2>
                    {isPageLoading && <BeneficiaireProfileSkeleton />}
                    {!isPageLoading &&
                      (!notification?.ayant_droit ? (
                        <>
                          <div className="profile-avatar-container">
                            <img
                              // src={DefaultAvatar}
                              src={
                                !notification?.beneficiaire?.avatar ||
                                notification?.beneficiaire?.avatar?.includes(
                                  "default.png"
                                )
                                  ? DefaultAvatar
                                  : baseUrl + notification?.beneficiaire?.avatar
                              }
                              className="profile-avatar"
                              alt="Profile"
                            />
                            <h3 className="ben-name">
                              {" "}
                              {notification?.beneficiaire?.prenom +
                                " " +
                                notification?.beneficiaire?.nom}{" "}
                            </h3>
                          </div>
                          <div className="ben-others-infos-container">
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Genre</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{notification?.beneficiaire?.sexe}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Nom</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{notification?.beneficiaire?.nom}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Prénom</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {notification?.beneficiaire?.prenom}
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Date de naissance</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {moment(
                                    notification?.beneficiaire
                                      ?.date_de_naissance
                                  ).format("DD/MM/YYYY")}
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Situation Matriomiale</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {
                                    notification?.beneficiaire
                                      ?.situation_matrimoniale
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Groupe Sanguin</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {notification?.beneficiaire?.groupe_sanguin}
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Nationalité</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {notification?.beneficiaire?.nationnalite ||
                                    ""}
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Téléphone</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {notification?.beneficiaire?.telephone}
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Email</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{notification?.beneficiaire?.email}</span>
                              </div>
                            </div>
                            {/* <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Département</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {notification?.beneficiaire?.departement !== null &&
                                    notification?.beneficiaire?.departement}
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="profile-avatar-container">
                            <img
                              // src={DefaultAvatar}
                              src={
                                notification?.ayant_droit?.avatar ===
                                "/mediafiles/avatars/default.png"
                                  ? DefaultAvatar
                                  : baseUrl + notification?.ayant_droit?.avatar
                              }
                              className="profile-avatar"
                              alt="Profile"
                            />
                            <h3 className="ben-name">
                              {" "}
                              {notification?.ayant_droit?.prenom +
                                " " +
                                notification?.ayant_droit?.nom}{" "}
                            </h3>
                          </div>
                          <div className="ben-others-infos-container">
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Genre</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{notification?.ayant_droit?.sexe}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Nom</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{notification?.ayant_droit?.nom}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Prénom</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{notification?.ayant_droit?.prenom}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Date de naissance</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {moment(
                                    notification?.ayant_droit?.date_naissance
                                  ).format("DD/MM/YYYY")}
                                </span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Staut</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>{notification?.ayant_droit?.statut}</span>
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div
                                className="accordion w-100"
                                id="accordionExample"
                              >
                                {notification?.ayant_droit?.certificat ? (
                                  <>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        id="headingOne"
                                        style={{ textAlign: "left" }}
                                      >
                                        <h5 className="mb-0">
                                          <button
                                            className="bloc-text-profil"
                                            style={{ background: "none" }}
                                            type="button"
                                            data-toggle="collapse"
                                            data-target={`#collapse${1}`}
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                          >
                                            Certificat de{" "}
                                            {notification?.ayant_droit
                                              ?.statut === "enfant"
                                              ? "naissance"
                                              : "mariage"}
                                          </button>
                                        </h5>
                                      </div>

                                      <div
                                        id={`collapse${1}`}
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordionExample"
                                      >
                                        <div
                                          className="card-body"
                                          style={{ height: "700px" }}
                                        >
                                          <iframe
                                            className="responsive-iframe w-100"
                                            src={
                                              baseUrl +
                                              notification?.ayant_droit
                                                ?.certificat
                                            }
                                            style={{ height: "100%" }}
                                            title="Certificat"
                                          ></iframe>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 profile-infos-family-container profile-item">
                  <div className="profile-infos-ben">
                    <h2 className="section-light-title">
                      <div className="light-title">
                        {userType !== "entreprise"
                          ? !notification?.ayant_droit
                            ? "Données modifiées"
                            : "Données modifiées"
                          : !notification?.ayant_droit
                          ? "Données  modifiées"
                          : "Données modifiées"}
                      </div>
                      {userType !== "prestataire" && (
                        <div className="bnf-update-infos-btn-container">
                          {!valid ? (
                            isloading ? (
                              <i className="fas fa-spin fa-spinner"></i>
                            ) : (
                              <>
                                <button
                                  className="bnf-edit-btn"
                                  onClick={handleSubmit}
                                >
                                  <IconlyPack.TickSquare
                                    set="light"
                                    primaryColor="#C8C8C8"
                                    className="action-btn-icon action-edit-icon"
                                    title="Modifier les infos du bénéciaire"
                                  />
                                  Valider
                                </button>
                                <button
                                  className="bnf-edit-btn"
                                  onClick={handleRejet}
                                >
                                  <IconlyPack.CloseSquare
                                    set="light"
                                    primaryColor="#C8C8C8"
                                    className="action-btn-icon action-edit-icon"
                                    title="Modifier les infos du bénéciaire"
                                  />
                                  Rejeter
                                </button>
                              </>
                            )
                          ) : isloading ? (
                            <i className="fas fa-spin fa-spinner"></i>
                          ) : (
                            <button className="bnf-edit-btn">
                              <IconlyPack.TickSquare
                                set="light"
                                primaryColor="#02A69C"
                                className="action-btn-icon action-edit-icon"
                                title="Modifier les infos du bénéciaire"
                              />
                            </button>
                          )}
                        </div>
                      )}
                    </h2>
                    {isPageLoading && <BeneficiaireProfileSkeleton />}
                    {!isPageLoading &&
                      (!notification?.ayant_droit ? (
                        <>
                          <div className="profile-avatar-container">
                            <img
                              src={
                                (!notification?.data?.avatar ||
                                  notification?.data?.avatar?.includes(
                                    "default.png"
                                  )) &&
                                (!notification?.beneficiaire?.avatar ||
                                  notification?.beneficiaire?.avatar?.includes(
                                    "default.png"
                                  ))
                                  ? DefaultAvatar
                                  : notification?.data?.avatar &&
                                    !notification?.data?.avatar?.includes(
                                      "default.png"
                                    )
                                  ? baseUrl +
                                    "/mediafiles/" +
                                    notification?.data?.avatar
                                  : baseUrl + notification?.beneficiaire?.avatar
                              }
                              className="profile-avatar"
                            />
                            <h3 className="ben-name">
                              {" "}
                              {notification?.data?.prenom +
                                " " +
                                notification?.data?.nom}{" "}
                            </h3>
                          </div>
                          <div className="ben-others-infos-container">
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Genre</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.beneficiaire?.sexe ===
                                notification?.data?.sexe ? (
                                  <span> {notification?.data?.sexe} </span>
                                ) : (
                                  <strong>{notification?.data?.sexe}</strong>
                                )}
                              </div>
                            </div>
                            {/* <div className="ben-others-infos-item-container">
                            <div className="ben-others-infos-item-label">
                              <span>Statut</span>
                            </div>
                            <div className="ben-others-infos-item-value">
                              <strong>
                                {notification?.data?.is_active
                                  ? "ACTIF"
                                  : "NON ACTIF"}
                              </strong>
                            </div>
                          </div> */}
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Nom</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.beneficiaire?.nom ===
                                notification?.data?.nom ? (
                                  <span> {notification?.data?.nom} </span>
                                ) : (
                                  <strong>{notification?.data?.nom}</strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Prénom</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.beneficiaire?.prenom ===
                                notification?.data?.prenom ? (
                                  <span> {notification?.data?.prenom} </span>
                                ) : (
                                  <strong>{notification?.data?.prenom}</strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Date de naissance</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {/* <strong>
                                  {moment(
                                    notification?.data?.date_de_naissance
                                  ).format("DD/MM/YYYY")}
                                </strong> */}
                                {notification?.beneficiaire
                                  ?.date_de_naissance ===
                                notification?.data?.date_de_naissance ? (
                                  <span>
                                    {" "}
                                    {moment(
                                      notification?.data?.date_de_naissance
                                    ).format("DD/MM/YYYY")}{" "}
                                  </span>
                                ) : (
                                  <strong>
                                    {moment(
                                      notification?.data?.date_de_naissance
                                    ).format("DD/MM/YYYY")}
                                  </strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Situation Matriomiale</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.beneficiaire
                                  ?.situation_matrimoniale ===
                                notification?.data?.situation_matrimoniale ? (
                                  <span>
                                    {" "}
                                    {
                                      notification?.data?.situation_matrimoniale
                                    }{" "}
                                  </span>
                                ) : (
                                  <strong>
                                    {notification?.data?.situation_matrimoniale}
                                  </strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Groupe Sanguin</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.beneficiaire?.groupe_sanguin ===
                                notification?.data?.groupe_sanguin ? (
                                  <span>
                                    {" "}
                                    {notification?.data?.groupe_sanguin}{" "}
                                  </span>
                                ) : (
                                  <strong>
                                    {notification?.data?.groupe_sanguin}
                                  </strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Nationalité</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.beneficiaire?.nationnalite ===
                                notification?.data?.nationnalite ? (
                                  <span>
                                    {" "}
                                    {notification?.data?.nationnalite}{" "}
                                  </span>
                                ) : (
                                  <strong>
                                    {notification?.data?.nationnalite}
                                  </strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Téléphone</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.beneficiaire?.telephone ===
                                notification?.data?.telephone ? (
                                  <span> {notification?.data?.telephone} </span>
                                ) : (
                                  <strong>
                                    {notification?.data?.telephone}
                                  </strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Email</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.beneficiaire?.email ===
                                notification?.data?.email ? (
                                  <span> {notification?.data?.email} </span>
                                ) : (
                                  <strong>{notification?.data?.email}</strong>
                                )}
                              </div>
                            </div>
                            {/* <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Département</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                <span>
                                  {notification?.data?.departement !== null &&
                                    notification?.data?.departement}
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="profile-avatar-container">
                            <img
                              src={
                                (!notification?.data?.avatar ||
                                  notification?.data?.avatar?.includes(
                                    "default.png"
                                  )) &&
                                (!notification?.ayant_droit?.avatar ||
                                  notification?.ayant_droit?.avatar?.includes(
                                    "default.png"
                                  ))
                                  ? DefaultAvatar
                                  : notification?.data?.avatar &&
                                    !notification?.data?.avatar?.includes(
                                      "default.png"
                                    )
                                  ? baseUrl +
                                    "/mediafiles/" +
                                    notification?.data?.avatar
                                  : baseUrl + notification?.ayant_droit?.avatar
                              }
                              className="profile-avatar"
                            />
                            <h3 className="ben-name">
                              {" "}
                              {notification?.data?.prenom +
                                " " +
                                notification?.data?.nom}{" "}
                            </h3>
                          </div>
                          <div className="ben-others-infos-container">
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Genre</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.ayant_droit?.sexe ===
                                notification?.data?.sexe ? (
                                  <span> {notification?.data?.sexe} </span>
                                ) : (
                                  <strong>{notification?.data?.sexe}</strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Nom</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.ayant_droit?.nom ===
                                notification?.data?.nom ? (
                                  <span> {notification?.data?.nom} </span>
                                ) : (
                                  <strong>{notification?.data?.nom}</strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Prénom</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.ayant_droit?.prenom ===
                                notification?.data?.prenom ? (
                                  <span> {notification?.data?.prenom} </span>
                                ) : (
                                  <strong>{notification?.data?.prenom}</strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Date de naissance</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.ayant_droit?.date_naissance ===
                                notification?.data?.date_naissance ? (
                                  <span>
                                    {" "}
                                    {moment(
                                      notification?.data?.date_naissance
                                    ).format("DD/MM/YYYY")}{" "}
                                  </span>
                                ) : (
                                  <strong>
                                    {moment(
                                      notification?.data?.date_naissance
                                    ).format("DD/MM/YYYY")}
                                  </strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div className="ben-others-infos-item-label">
                                <span>Staut</span>
                              </div>
                              <div className="ben-others-infos-item-value">
                                {notification?.ayant_droit?.statut ===
                                notification?.data?.statut ? (
                                  <span> {notification?.data?.statut} </span>
                                ) : (
                                  <strong>{notification?.data?.statut}</strong>
                                )}
                              </div>
                            </div>
                            <div className="ben-others-infos-item-container">
                              <div
                                className="accordion w-100"
                                id="accordionExample1"
                              >
                                {notification?.data?.certificat ? (
                                  <>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        id="headingOne"
                                        style={{ textAlign: "left" }}
                                      >
                                        <h5 className="mb-0">
                                          <button
                                            className="bloc-text-profil"
                                            style={{ background: "none" }}
                                            type="button"
                                            data-toggle="collapse"
                                            data-target={`#collapse${2}`}
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                          >
                                            Certificat de{" "}
                                            {notification?.data?.statut ===
                                            "enfant"
                                              ? "naissance"
                                              : "mariage"}
                                          </button>
                                        </h5>
                                      </div>

                                      <div
                                        id={`collapse${2}`}
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordionExample1"
                                      >
                                        <div
                                          className="card-body"
                                          style={{ height: "700px" }}
                                        >
                                          <iframe
                                            className="responsive-iframe w-100"
                                            src={
                                              baseUrl +
                                              "/mediafiles/" +
                                              notification?.data?.certificat
                                            }
                                            style={{ height: "100%" }}
                                            title="Certificat"
                                          ></iframe>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>

                {/* Modal Valid */}
                {/* <div
                    className="modal fade easypm-modal valid-family-modal"
                    id="DeleteModalItem"
                    tabIndex="-1"
                    aria-labelledby="DeleteModalItemLabel"
                    aria-hidden="true"
                    data-backdrop="static"
                  >
                    <ValidFamilyModal beneficiaireID={notification?.beneficiaire?.id} />
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
