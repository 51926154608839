import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import EpousesDetailsAdherentData from "./EpousesDetailsAdherentData";
import EpousesDetailsAdherentSkeleton from "./EpousesDetailsAdherentSkeleton";


var EpousesDetailsAdherent = (props) => {
var [isPageLoading, setIsPageLoading] = React.useState(true);
  var [epouses, setEpouses] = React.useState(EpousesDetailsAdherentData);
  
  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false)
    }, 3000)
  }, [])

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <div className="nameFormatter">
        <span className="name"> {`${cell} ${row.nom}`} </span>
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="d-flex justify-content-start align-items-center">
        <button className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".show-family-modal"
          />
        </button>
            <button className="action-btn edit-btn" title="Modification">
              <IconlyPack.Edit
                set="light"
                primaryColor="#C8C8C8"
                className="action-btn-icon action-edit-icon"
                data-toggle="modal"
                data-target=".edit-family-modal"
              />
            </button>
            <button className="action-btn delete-btn" title="Archivage">
              <IconlyPack.Delete
                set="light"
                primaryColor="#C8C8C8"
                className="action-btn-icon action-delete-icon"
                data-toggle="modal"
                data-target=".delete-family-modal"
              />
            </button>
      </div>
    );
  };

//   const ageFormater = (cell, row) => {
//     return moment(row?.date_naissance).format("DD/MM/YYYY");
//     return moment(cell).locale("fr").fromNow(true);
//   };


  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <EpousesDetailsAdherentSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={epouses}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="prenom"
                    // dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Prénom &Nom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="age"
                    // dataFormat={(cell, row) => ageFormater(cell, row)}
                  >
                    Âge
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EpousesDetailsAdherent;
