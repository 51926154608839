import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

var EmptyHeader = (props) => {
  var history = useHistory();
  const onRedirectToHomepage = () => {
    history.push("/");
  };

  return (
    <div className="container-fluid empty-fluidy">
      <div
        className={
          "empty-header " + (props.isLandingPage ? "landing-page-header" : "")
        }
      >
        <div className="logo-item linkable" onClick={onRedirectToHomepage}>
          <span className="logo-firsty">EASY</span>&nbsp;
          <span className="logo-secondy">PM</span>
        </div>
        {props.isLandingPage && (
          <div className="auth-btn-container">
            <div className="login-btn-container">
              <NavLink
                to="/connexion"
                className="btn btn-default login-empty-header"
              >
                Connexion
              </NavLink>
            </div>
            {/* <div className="login-btn-container">
              <NavLink
                to="/inscription"
                className="btn btn-default ldpg-register-btn auth-btns"
              >
                Inscription
              </NavLink>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyHeader;
