import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
import "./NousContacter.css";
import ImgProfilMessage from "../../../../images/others/message-contact.png";
import { NavLink } from "react-router-dom";
import {
  MyButton,
  MyInput,
  MyTextareaInput,
  useCurrentUser,
  useFormData,
  useFormValidation,
} from "../../../../utils/utils";
import { useMutation } from "../../../../Http/http/mutation";
import http from "../../../../Http/http/http";
import { toast } from "react-toastify";

var NousContacter = () => {
  const user = useCurrentUser();
  const mutation = useMutation((data) => http.post("/contact/", data));
  const [data, onChange] = useFormData({
    email: "",
    objet: "",
    message: "",
  });
  const [errors, validate] = useFormValidation({}, (Yup) => ({
    objet: Yup.string().min(3).required().label("Objet"),
    message: Yup.string().min(10).required().label("Message"),
    // email: Yup.string().email().required().label("Email"),
  }));

  const onSubmit = React.useCallback(async () => {
    validate(data)
      .then(async () => {
        try {
          const sendData = {
            ...data,
            email: user.email,
          };
          await mutation.exec(sendData);
          toast.success(`message envoyé avec succès`);
          onChange({
            objet: "",
            message: "",
          });
        } catch (ex) {
          toast.error(`Error: ${ex.message}`);
        }
      })
      .catch((ex) => {
        console.log("message", ex);
      });
  }, [data, user]);

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Nous contacter</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="py-5">
                    <div className="row">
                      <div className="col-md-6">
                        <MyInput
                          name="objet"
                          error={errors.objet}
                          value={data.objet}
                          type="text"
                          onChange={(v) => onChange("objet", v)}
                          label="Objet"
                          placeholder="Objet"
                        />
                      </div>
                      <div className="col-md-6" />
                      <div className="col-md-6">
                        <MyTextareaInput
                          name="message"
                          error={errors.message}
                          value={data.message}
                          onChange={(v) => onChange("message", v)}
                          label="Message"
                          placeholder="Message"
                        />
                        <div className="col-md-6" />
                        <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                          <span />
                          <MyButton
                            title="Envoyer"
                            loading={mutation.loading}
                            onClick={onSubmit}
                            className="btn  crud-submit-btn-assurance"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NousContacter;
