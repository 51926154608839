import React, { useContext } from "react";
import { DataContext } from "../store/GlobalState";

const ShowTypePrestationModal = () => {
  const { state, dispatch } = useContext(DataContext);

  const { prestation_type } = state;

  // console.log("prestation type==>", prestation_type)

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Detail Type Prestation
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              dispatch({ type: "EDIT_PRESTATION_TYPE", payload: {} })
            }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="crud-form">
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Famille de soins: </strong>

                    <span className="crud-form-control">
                      {prestation_type?.familledesoin?.nom}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Tyype de soins: </strong>

                    <span className="crud-form-control">
                      {prestation_type?.typedesoin?.nom}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Libellé: </strong>

                    <span className="crud-form-control">
                      {prestation_type?.libelle}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="name">Taux Pris en charge: </strong>
                    <span className="crud-form-control">
                      {prestation_type?.taux_prise_en_charge}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="specialite">Montant: </strong>
                    <span className="crud-form-control">
                      {prestation_type?.montant_plafond}
                    </span>
                  </div>
                </div>

                <div
                  className="col-md-6 offset-md-6 crud-form-action-btn-container"
                  style={{ justifyContent: "flex-end" }}
                >
                  {/* <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button> */}

                  <button
                    className="btn btn-danger cancelleb-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      dispatch({ type: "EDIT_PRESTATION_TYPE", payload: {} })
                    }
                  >
                    Retour
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowTypePrestationModal;
