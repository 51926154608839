import React from "react";
import Header from "../../../Navs/Header";
import GestionException from "../../IPM/GestionException/GestionException";
import ChangePassword from "../../IPM/Parametres/ChangePassword";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import DisponibilitePrestataire from "./DisponibilitePrestataire";
import LogoPrestataire from "./LogoPrestataire";
import ProfileInformation from "./ProfileInformation";
import Services from "./Services";
import Visa from "./Visa";

var ParametresPrestataire = () => {
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={`prestataire`} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Paramétres</h1>
                  </div>
                </div>

                <div className="parent-table-container parent-tabs-container">
                  <div className="easypm-tabs-content">
                    <ul
                      className="nav nav-pills mb-3 easypm-tabs-nav-pills"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item easypm-tabs-nav-pills-item">
                        <a
                          className="nav-link active"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#pills-informations"
                          role="tab"
                          // onClick={(e) => handleCheck(e, 'informations')}
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Informations
                        </a>
                      </li>
                      <li className="nav-item easypm-tabs-nav-pills-item">
                        <a
                          className="nav-link"
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#pills-services"
                          role="tab"
                          aria-controls="pills-profile"
                          // onClick={(e) => handleCheck(e, 'services')}
                          aria-selected="false"
                        >
                          Services
                        </a>
                      </li>
                      <li className="nav-item easypm-tabs-nav-pills-item">
                        <a
                          className="nav-link"
                          id="pills-logo-tab"
                          data-toggle="pill"
                          href="#pills-logo"
                          role="tab"
                          // onClick={(e) => handleCheck(e, 'logo')}
                          aria-controls="pills-logo"
                          aria-selected="false"
                        >
                          Logo
                        </a>
                      </li>
                      <li className="nav-item easypm-tabs-nav-pills-item">
                        <a
                          className="nav-link"
                          id="pills-visa-tab"
                          data-toggle="pill"
                          href="#pills-visa"
                          role="tab"
                          // onClick={(e) => handleCheck(e, 'visa')}
                          aria-controls="pills-visa"
                          aria-selected="false"
                        >
                          Visa
                        </a>
                      </li>
                      <li className="nav-item easypm-tabs-nav-pills-item">
                        <a
                          className="nav-link"
                          id="pills-exception-tab"
                          data-toggle="pill"
                          href="#pills-exceptions"
                          role="tab"
                          // onClick={(e) => handleCheck(e, 'exceptions')}
                          aria-controls="pills-exception"
                          aria-selected="false"
                        >
                          Oppositions
                        </a>
                      </li>
                      <li className="nav-item easypm-tabs-nav-pills-item">
                        <a
                          className="nav-link"
                          id="pills-disponibilite-tab"
                          data-toggle="pill"
                          href="#pills-disponibilite"
                          role="tab"
                          aria-controls="pills-disponibilite"
                          aria-selected="false"
                        >
                          Mes disponibilités
                        </a>
                      </li>
                    </ul>
                    <div
                      className="tab-content easypm-tabs-content-body"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane easypm-tabs-pane fade show active"
                        id="pills-informations"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <ProfileInformation />
                      </div>
                      <div
                        className="tab-pane easypm-tabs-pane fade"
                        id="pills-services"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <Services />
                      </div>
                      <div
                        className="tab-pane easypm-tabs-pane fade"
                        id="pills-logo"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <LogoPrestataire />
                      </div>
                      <div
                        className="tab-pane easypm-tabs-pane fade"
                        id="pills-visa"
                        role="tabpanel"
                        aria-labelledby="pills-visa-tab"
                      >
                        <Visa />
                      </div>
                      <div
                        className="tab-pane easypm-tabs-pane fade"
                        id="pills-exceptions"
                        role="tabpanel"
                        aria-labelledby="pills-exception-tab"
                      >
                        <GestionException />
                      </div>
                      <div
                        className="tab-pane easypm-tabs-pane fade"
                        id="pills-disponibilite"
                        role="tabpanel"
                        aria-labelledby="pills-disponibilite-tab"
                      >
                        <DisponibilitePrestataire />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal add-entreprise-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  {/* <CRUDEntrepriseModal /> */}
                </div>
              </div>
            </div>
          </div>

          {/* {tab === 'informations' && (
            <div
              className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny"
              style={{ marginTop: '35px' }}
            >
              <div className="page-body-container">
                <div className="page-content-container">
                  <div className="page-first-row-container">
                    <div className="page-title-container">
                      <h1 className="page-title">
                        Changer votre mot de de passe
                      </h1>
                    </div>
                  </div>

                  <div className="parent-table-container parent-tabs-container">
                    <div className="easypm-tabs-content">
                      <div
                        className="tab-content easypm-tabs-content-body"
                        id="pills-tabContent"
                      >
                        <div
                          className="tab-pane easypm-tabs-pane fade show active"
                          id="pills-informations"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <ChangePassword />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ParametresPrestataire;
