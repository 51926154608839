import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
import Swal from "sweetalert2";
import DefaultAvatar from "../../../../images/others/default-profile-avatar.png";
import './ProfilUtilisateur.css'
var ProfilUtilisateurAssureur = () => {
    const editNom = () => {
        Swal.fire({
          title: "Modifer votre nom",
          input: "text",
          inputLabel: "nom",
          inputPlaceholder: "Ndiaye",
          inputAttributes: {
            autocapitalize: "off",
            autocorrect: "off",
          },
        });
      };
      const editPrenom = () => {
        Swal.fire({
          title: "Modifer votre prenom",
          input: "text",
          inputLabel: "prénom",
          inputPlaceholder: "Mary",
          inputAttributes: {
            autocapitalize: "off",
            autocorrect: "off",
          },
        });
      };
      const editDateNaissance = () => {
        Swal.fire({
          title: "Modifer votre date de naissance",
          input: "date",
          inputLabel: "date de naissance",
          inputPlaceholder: "23-11-1999",
          inputAttributes: {
            autocapitalize: "off",
            autocorrect: "off",
          },
        });
      };
      const editLieuNaissance = () => {
        Swal.fire({
          title: "Modifer votre lieu de naissance",
          input: "text",
          inputLabel: "lieu de naissance",
          inputPlaceholder: "Dakar",
          inputAttributes: {
            autocapitalize: "off",
            autocorrect: "off",
          },
        });
      };
      const editAdresse = () => {
        Swal.fire({
          title: "Modifer votre adresse",
          input: "text",
          inputLabel: "adresse",
          inputPlaceholder: "HLM Grand yoff",
          inputAttributes: {
            autocapitalize: "off",
            autocorrect: "off",
          },
        });
      };
      const editEmail = () => {
        Swal.fire({
          title: "Modifer votre email",
          input: "email",
          inputLabel: "email",
          inputPlaceholder: "mary@volkeno.sn",
          inputAttributes: {
            autocapitalize: "off",
            autocorrect: "off",
          },
        });
      };
      const editTelephone = () => {
        Swal.fire({
          title: "Modifer votre numéro de téléphone",
          input: "number",
          inputLabel: "téléphone",
          inputPlaceholder: "777777777",
          inputAttributes: {
            autocapitalize: "off",
            autocorrect: "off",
          },
        });
      };
      const editPassword = () => {
        Swal.fire({
          title: "Modifer votre mot de passe",
          html:
            '<div class="form-group"> <label>Ancien mot de passe</label><input id="swal-input1" class="swal2-input" placeholder="Ancien mot de passe"></div>' +
            '<div class="form-group"> <label>Nouveau mot de passe</label><input id="swal-input2" class="swal2-input" placeholder="Nouveau mot de passe"></div>' +
            '<div class="form-group"> <label>Confirmer mot de passe</label><input id="swal-input3" class="swal2-input" placeholder="Confirmer mot de passe"></div>',
          focusConfirm: false,
          preConfirm: () => {
            return [
              document.getElementById("swal-input1").value,
              document.getElementById("swal-input2").value,
              document.getElementById("swal-input3").value,
            ];
          },
        });
      };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2  />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                    Mon compte
                    </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                <div className="px-5">
              <form>
                <div className="d-flex align-items-center flex-column py-3">
                  <div className="py-3">
                    <img
                      src={DefaultAvatar}
                      alt="Image profil utilisateur"
                      className="img-profil-app"
                    />
                  </div>
                  <div>
                    <h2 className="nom-profil-user pb-3">Abdou Diop</h2>
                    <p className="text-profil-user">Admin</p>
                  </div>
                </div>
                <div className="py-3">
                  <p className="label-profil-user">Informations personnelles</p>
                </div>
                <div className="row d-flex align-items-center">
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group position-relative">
                      <label className="label-input pb-2">Nom</label>
                      <input
                        type="text"
                        className="bloc-div form-control"
                        placeholder="Nom"
                      />
                      <span className="icon-edit-profil">
                        <IconlyPack.Edit
                          set="light"
                          primaryColor="#AEAEAE"
                          onClick={() => editNom()}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group">
                      <label className="label-input pb-2">Prénom</label>
                      <input
                        type="text"
                        className="bloc-div form-control"
                        placeholder="Prénom"
                      />
                      <span className="icon-edit-profil">
                        <IconlyPack.Edit
                          set="light"
                          primaryColor="#AEAEAE"
                          onClick={() => editPrenom()}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group">
                      <label className="label-input pb-2">
                        Date de naissance
                      </label>
                      <input
                        type="text"
                        className="bloc-div form-control"
                        placeholder="Date de naissance"
                      />
                      <span className="icon-edit-profil">
                        <IconlyPack.Edit
                          set="light"
                          primaryColor="#AEAEAE"
                          onClick={() => editDateNaissance()}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group">
                      <label className="label-input pb-2">
                        Lieu de naissance
                      </label>
                      <input
                        type="text"
                        className="bloc-div form-control"
                        placeholder="Lieu de naissance"
                      />
                      <span className="icon-edit-profil">
                        <IconlyPack.Edit
                          set="light"
                          primaryColor="#AEAEAE"
                          onClick={() => editLieuNaissance()}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group">
                      <label className="label-input pb-2">Adresse</label>
                      <input
                        type="text"
                        className="bloc-div form-control"
                        placeholder="Adresse"
                      />
                      <span className="icon-edit-profil">
                        <IconlyPack.Edit
                          set="light"
                          primaryColor="#AEAEAE"
                          onClick={() => editAdresse()}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group">
                      <label className="label-input pb-2">Email</label>
                      <input
                        type="text"
                        className="bloc-div form-control"
                        placeholder="Email"
                      />
                      <span className="icon-edit-profil">
                        <IconlyPack.Edit
                          set="light"
                          primaryColor="#AEAEAE"
                          onClick={() => editEmail()}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group">
                      <label className="label-input pb-2">
                        Numéro de téléphone
                      </label>
                      <input
                        type="text"
                        className="bloc-div form-control"
                        placeholder="Numéro de téléphone"
                      />
                      <span className="icon-edit-profil">
                        <IconlyPack.Edit
                          set="light"
                          primaryColor="#AEAEAE"
                          onClick={() => editTelephone()}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group">
                      <label className="label-input pb-2">Mot de passe</label>
                      <input
                        type="text"
                        className="bloc-div form-control"
                        placeholder="Mot de passe"
                      />
                      <span className="icon-edit-profil">
                        <IconlyPack.Edit
                          set="light"
                          primaryColor="#AEAEAE"
                          onClick={() => editPassword()}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilUtilisateurAssureur;
