import React, { useContext, useEffect } from "react";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./GestionRendezVousPrestataire.css";
import CardItemRdv from "./CardItemRdv";
import CalendrierRdvPrestataire from "./CalendrierRdvPrestataire";
import { getName, useCurrentUser, useFormData } from "../../../../utils/utils";
import { useQuery } from "../../../../Http/http/query";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { useRdvPrestataire } from "../../navbarContent/PrestataireNavBarContent";
var GestionRendezVousPrestataire = () => {
  const [rdvs, loading] = useRdvPrestataire("accepte");
  const [names, setNames] = React.useState([]);
  const [formData, onChange] = useFormData({
    beneficiaire: "",
    adresse: "",
    date: moment().toDate(),
  });
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    setData(
      rdvs.filter(
        (f) => moment(f.date).format("L") === moment(formData.date).format("L")
      )
    );
  }, [rdvs, formData.date]);
  React.useEffect(() => {
    const finalData = {};
    for (let i of data) {
      if (i.beneficiaire) {
        const n = getName(i.beneficiaire);
        if (!finalData[n]) {
          finalData[n] = {
            label: n,
            value: i.beneficiaire.id,
          };
        }
      }
    }
    setNames(Object.keys(finalData).map((d) => finalData[d]));
  }, [data]);

  const filter = React.useCallback(() => {
    const filtred = [...data].reverse();
    const addr = formData.adresse.toLowerCase().trim();
    if (formData.beneficiaire || addr) {
      return filtred.filter((f) => {
        let ok1 = true;
        let ok2 = true;
        if (formData.beneficiaire) {
          ok1 = f.beneficiaire?.id == formData.beneficiaire;
        }
        if (addr) {
          ok2 = f.adresse.toLowerCase().includes(addr);
        }
        return ok1 && ok2;
      });
    }
    return filtred;
  }, [formData.beneficiaire, formData.adresse, data]);
  const results = filter();
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 userType={`prestataire`} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Mes rendez-vous</h1>
                  </div>
                </div>
                <form>
                  <div className="row align-items-center">
                    <div className="col-xl-1 col-lg-1 col-md-2">
                      <label className="label-filter-rdv">Filter</label>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 py-4">
                      <div class="form-group">
                        <select
                          value={formData.beneficiaire}
                          onChange={(e) =>
                            onChange("beneficiaire", e.currentTarget.value)
                          }
                          class="form-control select-filere-filter-module"
                        >
                          <option value={""}>Nom</option>
                          {names.map((n) => (
                            <option value={n.value}>{n.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 py-4">
                      <div class="form-group">
                        <input
                          value={formData.adresse}
                          onChange={(e) =>
                            onChange("adresse", e.currentTarget.value)
                          }
                          type="text"
                          class="form-control input-add-event pl-5"
                          id="inlineFormInputGroup"
                          placeholder="Entrer un lieu"
                          style={{ background: "#F7F7F7" }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="page-content-container mt-4">
                    <CalendrierRdvPrestataire
                      value={formData.date}
                      onChange={(value) => onChange("date", value)}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="py-4">
                    {loading && rdvs.length === 0 && (
                      <div className="row">
                        {[1, 2, 3, 4, 5, 6].map((i) => {
                          return (
                            <div key={i} className="col-md-4 pb-3">
                              <Skeleton height={250} />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <div className="row">
                      {results.map((item) => (
                        <div key={item.id} className="col-md-4 pb-3">
                          <CardItemRdv item={item} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionRendezVousPrestataire;
