import { exclusion } from "./Endpoints";
import axios from "axios";

export default {
  async getListExclusions(config) {
    const response = await axios.get(exclusion.onGetOrPostExclusion(), config);
    // console.log(response)
    return response.data;
  },
  async postCreateExclusion(data, config) {
    const response = await axios.post(
      exclusion.onGetOrPostExclusion(),
      data,
      config
    );
    return response.data;
  },
  async putExclusion(id, data, config) {
    const response = await axios.put(
      exclusion.onEditExclusion(id),
      data,
      config
    );
    return response.data;
  },
  async deleteExclusion(id, config) {
    const response = await axios.delete(
      exclusion.onDeleteExclusion(id),
      config
    );
    return response;
  },
};
