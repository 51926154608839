const RemboursementAssureurTableData = [
    {
        id: 1,
        assures: 'Badou Seck',
        date: '20/09/2021',
        type_soin: 'Spécialiste',
        part_assurance: '20 000',
        montant: '20 000',
        statut: 'valide'
    },
    {
        id: 2,
        assures: 'Badou Seck',
        date: '20/09/2021',
        type_soin: 'Spécialiste',
        part_assurance: '20 000',
        montant: '20 000',
        statut: 'valide'
    },
    {
        id: 3,
        assures: 'Badou Seck',
        date: '20/09/2021',
        type_soin: 'Spécialiste',
        part_assurance: '20 000',
        montant: '20 000',
        statut: 'valide'
    },
    {
        id: 4,
        assures: 'Badou Seck',
        date: '20/09/2021',
        type_soin: 'Spécialiste',
        part_assurance: '20 000',
        montant: '20 000',
        statut: 'valide'
    },
];

export default RemboursementAssureurTableData;
