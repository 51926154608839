import React, { useContext, useEffect } from "react";
import Header from "../../Navs/Header";
import NavBarVerticalV2 from "../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../store/GlobalState";
import { getData } from "../../utils/fetchData";
import ConditionContent from "./ConditionContent";

function Conditions() {

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Paramètres</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active tab-parametre-assurance"
                        id="cgu-tab"
                        data-toggle="tab"
                        href="#cgu"
                        role="tab"
                        aria-controls="cgu"
                        aria-selected="true"
                      >
                        Conditions générales d’utilisation{" "}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link tab-parametre-assurance"
                        id="pc-tab"
                        data-toggle="tab"
                        href="#pc"
                        role="tab"
                        aria-controls="pc"
                        aria-selected="false"
                      >
                        Politique de Protection des donneées personnelles
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="cgu"
                      role="tabpanel"
                      aria-labelledby="cgu-tab"
                    >
                      <ConditionContent type="CGU" />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pc"
                      role="tabpanel"
                      aria-labelledby="pc-tab"
                    >
                      <ConditionContent type="PPDP" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Conditions;
