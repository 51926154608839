import React, { useContext, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { DataContext } from "../store/GlobalState";
import { getDataByID } from "../utils/fetchData";
registerLocale("fr", fr);

const AddFicheModal = (props) => {
  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;
  var [startDate, setStartDate] = React.useState(new Date());
  var [ayantdroits, setAyantdroits] = React.useState("");
  var [isPharmacie, setIsPharmacie] = React.useState(false);
  var [selected, setSelected] = React.useState({});
  useEffect(() => {
    props.register("date");
    props.setValue("date", moment(startDate).format("YYYY-MM-DD"));
  }, [startDate]);

  React.useEffect(() => {
    if (props.formState.errors) {
      setTimeout(() => {
        Object.entries(props.formState.errors).map(([key]) => {
          return props.clearErrors(key);
        });
      }, 5000);
    }
  }, [props.formState.errors]);

  React.useEffect(() => {
    if (props?.isSuccess) {
      setAyantdroits([]);
      setIsPharmacie(false);
      props.register("date");
      props.setValue("date", moment(new Date()).format("YYYY-MM-DD"));
    }
  }, [props?.isSuccess]);

  const handleDate = (date) => {
    setStartDate(date);
    props.register("date");
    props.setValue("date", moment(date).format("YYYY-MM-DD"));
  };

  const handleChange = (e) => {
    let id = e.target.value;
    props.register("beneficiaire");
    props.setValue("beneficiaire", id);

    let benef = props.beneficiaires.filter((b) => b?.id === parseInt(id));
    setSelected(benef[0]);
    getDataByID("ayantdroitbybeneficiaire", parseInt(id), auth.token)
      .then((res) => {
        // console.log("res", );
        if (res?.length) {
          setAyantdroits([...benef, ...res]);
        } else {
          setAyantdroits(benef);
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  const handleChangeBenef = (e) => {
    let benef = props.beneficiaires.filter(
      (b) => b?.id === parseInt(e.target.value)
    );

    if (benef?.length && benef[0]?.prenom === selected?.prenom) {
      console.log("benef");
    } else {
      props.register("ayant_droit");
      props.setValue("ayant_droit", e.target.value);
    }
  };

  const handleChangePresta = (e) => {
    props.register("prestataire");
    props.setValue("prestataire", e.target.value);
    let testPharmacie = props.prestataires.filter(
      (p) =>
        p?.id === parseInt(e.target.value) &&
        p?.specialite?.id &&
        (p?.specialite?.nom?.toLowerCase() === "pharmacie" ||
          p?.specialite?.nom?.toLowerCase() === "pharmacien" ||
          p?.specialite?.nom?.toLowerCase() === "pharmacienne")
    );
    if (testPharmacie?.length) {
      setIsPharmacie(true);
      props.register("ordonnance_file");
      props.setValue("showOrdonnance", true);
      // console.log("testPharmacie", testPharmacie);
    } else {
      props.unregister("ordonnance_file");
      setIsPharmacie(false);
      props.setValue("showOrdonnance", false);
      // console.log(
      //   "non testPharmacie",
      //   testPharmacie,
      //   props.prestataires.filter((p) => p?.id === parseInt(e.target.value))
      // );
    }
  };

  const handleReset = () => {
    props.reset();
    setStartDate(new Date());
    setAyantdroits([]);
    setIsPharmacie(false);
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Demander une fiche de soins
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => handleReset()}
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={props.onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="edit_employeur">Participant</label>
                    <div className="filter-item-container">
                      <select
                        // {...props.register("beneficiaire")}
                        onChange={handleChange}
                        name="beneficiaire"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir
                        </option>
                        {props.beneficiaires &&
                          props.beneficiaires
                            .filter((b) => b?.is_active === true)
                            .map((item, index) => (
                              <option key={index} value={item.id}>
                                {" "}
                                {item.prenom + " " + item.nom}{" "}
                              </option>
                            ))}
                      </select>

                      {props.formState.errors &&
                        props.formState.errors.beneficiaire && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.beneficiaire?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="edit_employeur">Prestataires</label>
                    <div className="filter-item-container">
                      <select
                        // {...props.register("prestataire")}
                        name="prestataire"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                        onChange={handleChangePresta}
                      >
                        <option hidden value="">
                          Choisir
                        </option>
                        {props.prestataires &&
                          props.prestataires.map((item, index) => (
                            <option key={index} value={item.id}>
                              {" "}
                              {item.raison_sociale
                                ? item.raison_sociale
                                : item.prenom + " " + item.nom}
                            </option>
                          ))}
                      </select>

                      {props.formState.errors &&
                        props.formState.errors.prestataire && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.prestataire?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {/* {ayantdroits?.length ? ( */}
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="edit_employeur">Bénéficiaire</label>
                    <div className="filter-item-container">
                      <select
                        onChange={handleChangeBenef}
                        name="ayant_droit"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir
                        </option>
                        {ayantdroits?.length &&
                          ayantdroits
                            ?.filter((b) => b?.is_active === true)
                            .map((item, index) => (
                              <option key={index} value={item.id}>
                                {" "}
                                {item.prenom + " " + item.nom}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* ) : null} */}

                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="date_debut">Date</label>

                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      popperPlacement="top"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="date"
                      locale="fr"
                      onChange={(date) => handleDate(date)}
                    />
                    {props.formState.errors && props.formState.errors.date && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {props.formState.errors.date?.message}
                      </div>
                    )}
                  </div>
                </div>

                {isPharmacie && (
                  <div
                    className="col-md-6 crud-col-item"
                    style={{ marginBottom: "15px" }}
                  >
                    <div className="form-group crud-form-group custom-file required">
                      <label htmlFor="file">Ordonnance</label>
                      <input
                        type="file"
                        placeholder="Importer l'ordonnance"
                        name="ordonnance_file"
                        className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                        id="file"
                        // onChange={handleFile}
                        {...props.register("ordonnance_file")}
                      />

                      {props.formState.errors &&
                        props.formState.errors.ordonnance_file && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.ordonnance_file?.message}
                          </div>
                        )}
                    </div>
                  </div>
                )}

                <input
                  {...props.register("etat")}
                  type="hidden"
                  name="etat"
                  value={`new`}
                />
                {/* <input
                    {...props.register('user_type')}
                    type="hidden"
                    name="user_type"
                    value={`admin_ipm`}
                  />
                  <input
                    {...props.register('admin_type')}
                    type="hidden"
                    name="admin_type"
                    value={`ipm`}
                  /> */}

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    onClick={() => handleReset()}
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  {props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFicheModal;
