import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
import "./ParametreAssurance.css";
import ParametreInformation from "./ParametreInformation";
import CompteTable from "./CompteTable.js/CompteTable";
import OppositionTable from "./OppositionTable/OppositionTable";
import ParametreVisa from "./ParametreVisa";
import ParametreLogo from "./ParametreLogo";
import ParametreFacturation from "./ParametreFacturation";
import ExclusionTable from "./ExclusionTable/ExclusionTable";
import { DataContext } from "../../../store/GlobalState";
var ParametreAssurance = () => {
  const { state } = useContext(DataContext);
  const { auth } = state;
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Paramètres</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active tab-parametre-assurance"
                        id="information-tab"
                        data-toggle="tab"
                        href="#information"
                        role="tab"
                        aria-controls="information"
                        aria-selected="true"
                      >
                        Informations{" "}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link tab-parametre-assurance"
                        id="opposition-tab"
                        data-toggle="tab"
                        href="#opposition"
                        role="tab"
                        aria-controls="opposition"
                        aria-selected="false"
                      >
                        Oppositions
                      </a>
                    </li>
                    {!auth?.user?.admin_type && (
                      <>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link tab-parametre-assurance"
                            id="compte-tab"
                            data-toggle="tab"
                            href="#compte"
                            role="tab"
                            aria-controls="compte"
                            aria-selected="false"
                          >
                            Comptes
                          </a>
                        </li>

                        <li class="nav-item" role="presentation">
                          <a
                            class="nav-link tab-parametre-assurance"
                            id="visa-tab"
                            data-toggle="tab"
                            href="#visa"
                            role="tab"
                            aria-controls="visa"
                            aria-selected="true"
                          >
                            Visa
                          </a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a
                            class="nav-link tab-parametre-assurance"
                            id="logo-tab"
                            data-toggle="tab"
                            href="#logo"
                            role="tab"
                            aria-controls="logo"
                            aria-selected="false"
                          >
                            Logo
                          </a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a
                            class="nav-link tab-parametre-assurance"
                            id="facturation-tab"
                            data-toggle="tab"
                            href="#facturation"
                            role="tab"
                            aria-controls="facturation"
                            aria-selected="false"
                          >
                            {" "}
                            Facturations{" "}
                          </a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a
                            class="nav-link tab-parametre-assurance"
                            id="exclusion-tab"
                            data-toggle="tab"
                            href="#exclusion"
                            role="tab"
                            aria-controls="exclusion"
                            aria-selected="true"
                          >
                            Exclusions
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="information"
                      role="tabpanel"
                      aria-labelledby="information-tab"
                    >
                      <ParametreInformation />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="compte"
                      role="tabpanel"
                      aria-labelledby="compte-tab"
                    >
                      <div className="easypm-tabs-item">
                        <div className="row adding-row">
                          <div className="col-md-2 offset-md-10 adding-col-offseting">
                            <div
                              className="adding-item-container linkable"
                              data-toggle="modal"
                              data-target=".add-admin-modal"
                            >
                              <span className="icon-container">
                                <IconlyPack.Plus
                                  set="light"
                                  primaryColor="gray"
                                />
                              </span>
                              <span className="adding-item-label">
                                Ajouter un Admin
                              </span>
                            </div>
                          </div>
                        </div>
                        <CompteTable />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="opposition"
                      role="tabpanel"
                      aria-labelledby="opposition-tab"
                    >
                      <div className="row adding-row">
                        <div className="col-md-2 offset-md-10 adding-col-offseting">
                          <div
                            className="adding-item-container linkable"
                            data-toggle="modal"
                            data-target=".add-opposition-modal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="gray"
                              />
                            </span>
                            <span className="adding-item-label">
                              Ajouter une opposition
                            </span>
                          </div>
                        </div>
                      </div>
                      <OppositionTable />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="visa"
                      role="tabpanel"
                      aria-labelledby="visa-tab"
                    >
                      <ParametreVisa />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="logo"
                      role="tabpanel"
                      aria-labelledby="logo-tab"
                    >
                      <ParametreLogo />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="facturation"
                      role="tabpanel"
                      aria-labelledby="facturation-tab"
                    >
                      <ParametreFacturation />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="exclusion"
                      role="tabpanel"
                      aria-labelledby="exclusion-tab"
                    >
                      <div className="row adding-row">
                        <div className="col-md-2 offset-md-10 adding-col-offseting">
                          <div
                            className="adding-item-container linkable"
                            data-toggle="modal"
                            data-target=".ajout-exclusion-modal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="gray"
                              />
                            </span>
                            <span className="adding-item-label">
                              Ajouter une exclusion
                            </span>
                          </div>
                        </div>
                      </div>
                      <ExclusionTable />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParametreAssurance;
