import React, { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";
import Facture from "../../../Services/Facture";
import prestataire from "../../../Services/Prestataire";
import { getDataByID } from "../../../utils/fetchData";
import moment from "moment";

const UseCreateFatureForm = () => {
  const { state, dispatch } = useContext(DataContext);
  let { auth } = state;

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [facture, setFacture] = useState();
  const [isFormRequestError, setIsFormRequestError] = useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        date_debut: yup
          .string()
          .required("La date de début est requise")
          .default(moment(new Date()).format("YYYY-MM-DD")),
        date_fin: yup
          .string()
          .required("La date de fin est requise")
          .default(moment(new Date()).format("YYYY-MM-DD")),
        ipm: yup.string().required("L'ipm est requise"),
        // type_prestation: yup.string().required('Le type de prestation est requis'),
        prestataire: yup.string(),
        // montant: yup.string().required('Le Montant est requis'),
      }),
    []
  );

  const { register, handleSubmit, setValue, formState, clearErrors, reset } =
    useForm({
      resolver: yupResolver(validationSchema),
    });

  React.useEffect(() => {
    register("prestataire");
    setValue("prestataire", auth?.user?.id);
  }, [setValue, auth, register]);

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    console.log("data", formValues);
    formValues.prestataire = auth.user.id;
    var prestationRequest = Facture.postCreateFacture(
      formValues,
      requestConfig
    );
    await prestationRequest
      .then((response) => {
        e.target.reset();
        console.log("response", response);
        setIsSuccess(true);
        getDataByID("facturebyprestataire", auth?.user?.id, auth?.token).then(
          (res) => {
            dispatch({
              type: "LIST_FACTURE_BY_PRESTATAIRE",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );
        if (response?.id && response?.prestations?.length) {
          dispatch({
            type: "LIST_PRESTATION_BY_FACTURE",
            payload: response?.prestations.sort((a, b) => b?.id - a?.id),
          });
          dispatch({ type: "EDIT_FACTURE", payload: response });
          toast.success("Facture générée avec succès.");
        } else {
          toast.warning(response?.message);
          window.$(".add-facture-modal").modal("hide");
          setIsFormRequestError(true);
          setTimeout(() => {
            setIsFormRequestError(false);
          }, 2000);
        }
        // if (response?.id) {
        //   toast.success("Facture générée avec succès.");

        // } else {
        //   toast.warning(response?.message);
        // }
        setIsSuccessfullySubmitted(false);

        reset();
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message) {
          toast.error(error?.response.data.message);
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };

  return {
    register,
    setValue,
    formState,
    facture,
    setFacture,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    reset,
    onSubmit: handleSubmit(onSubmitCreateForm),
    isSuccess,
  };
};

export default UseCreateFatureForm;
