/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useReducer, useEffect } from "react";
import { fetchData, getData, getDataByID, postData } from "../utils/fetchData";
import reducers from "./Reducers";
import jwtDecode from "jwt-decode";
import moment from "moment";
import _ from "lodash";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const initialState = {
    auth: {},
    ipms: [],
    ipm: {},
    prestataires: [],
    prestation_types: [],
    prestataire: {},
    beneficiaires: [],
    entreprises: [],
    entreprises_by_ipm: [],
    entreprise: {},
    beneficiaire: {},
    prestation_type: {},
    ayantDroits: [],
    ayantDroit: {},
    ficheDeSoinsbyIpm: [],
    ficheDeSoin: {},
    prestations: [],
    beneficiairesByEntreprise: [],
    //single item prestation soin
    prestation: {},
    admin_ipms: [],
    admin_ipm: {},
    prestationbyassurance: [],
    prestatairebyassurance: [],
    prestationbyprestataire: [],
    beneficiairebyemploye: [],
    stats: {},
    fichevalidbyprestataire: [],
    facturebyprestataire: [],
    facturebyipm: [],
    facturebyentreprise: [],
    facture: {},
    exceptions_by_ipm: [],
    exception: {},
    services: [],
    notifications: [],
    devisbyprestataire: [],
    devisbyipm: [],
    devisbyentreprise: [],
    devis: {},
    rapport: {},
    parent: {},
    prestations_facture: [],
    list_ayantdroit: [],
    list_famille_soins: [],
    famille_soins: {},
    list_exclusions: [],
    exclusion: {},
    list_specialites: [],
    specialite: {},
    assureur_entreprises: {
      isLoading: true,
      data: [],
    },
    assureur_prestataires: {
      isLoading: true,
      data: [],
    },
    assureur_beneficiaires: {
      isLoading: true,
      data: [],
    },
    assureur_prise_en_charges: {
      isLoading: true,
      data: [],
    },
    assureur_admins: {
      isLoading: true,
      data: [],
    },
    assureur_ficheSoins: {
      isLoading: true,
      data: [],
    },
    assureur_remboursements: {
      isLoading: true,
      data: [],
    },
    assureur_factures: {
      isLoading: true,
      data: [],
    },
    assureur_rapports: {
      isLoading: true,
      data: {},
    },
    assureur_devis: {
      isLoading: true,
      data: [],
    },
    assureur_oppositions: {
      isLoading: true,
      data: [],
    },
    assureur_exclusions: {
      isLoading: true,
      data: [],
    },
    beneficiaire_ficheSoins: {
      isLoading: true,
      data: [],
    },
    conditions: {
      isLoading: true,
      data: [],
    },
    cgu: {
      isLoading: true,
      data: [],
    },
    pc: {
      isLoading: true,
      data: [],
    },
  };
  const [state, dispatch] = useReducer(reducers, initialState);
  const { auth, beneficiaire, entreprise, parent } = state;

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");

    if (token) {
      let { exp } = jwtDecode(token);
      console.log("exp", exp);
      if (exp < moment().unix()) {
        console.log("expired");
        // postData("auth/refresh-token/", { token: token }, token).then(
        //   (response) => {
        //     // console.log('new token', response)
        //     window.sessionStorage.setItem("authToken", response?.token);
        //     dispatch({
        //       type: "AUTH",
        //       payload: { token: response?.token, user: auth?.user },
        //     });
        //   }
        // );
      } else {
        getData(`me/`, token)
          .then((res) => {
            sessionStorage.setItem("userID", res?.data?.id);

            if (res.data?.admin_type === "entreprise") {
              getDataByID("entreprise", res.data?.parent, token).then((prt) => {
                console.log("parent", prt);
                dispatch({
                  type: "PARENT",
                  payload: prt,
                });
              });
            }
            dispatch({
              type: "AUTH",
              payload: {
                token: token,
                user: res?.data,
              },
            });
          })
          .catch((e) => {
            return sessionStorage.removeItem("authToken");
          });
      }

      // getData(`assurance_maladies/${user?.user_id}/`, token).then((res) => {
      //   if (res.err) return sessionStorage.removeItem('authToken')
      //   // console.log(res)

      // })
    }
  }, []);

  useEffect(() => {
    if (auth.token) {
      if (
        auth.user?.user_type === "ipm" ||
        auth?.user?.user_type === "assurance_sante"
      ) {
        getData("prestataire", auth.token)
          .then((res) => {
            // console.log('data', data)
            dispatch({
              type: "ADD_PRESTATAIRE",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getData("familledesoin", auth?.token)
          .then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_FAMILLE_SOINS",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
        getDataByID("exclusionbyipm", auth?.user?.id, auth?.token).then(
          (res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_EXCLUSION",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );

        getDataByID("exceptionbyipm", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "LIST_EXCEPTION_IPM",
              payload: res.data.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("prestatairebyassurance", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "PRESTATAIRE_BY_IPM",
              payload: res.results.reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("typeprestationbyassurance", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_PRESTATION_TYPE",
              payload: res?.sort((a, b) => b.id - a.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("fichedesoinbyipm", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_FICHE_DE_SOIN_BY_IPM",
              payload: res?.sort((a, b) => a.id - b.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("statbyassurance", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({ type: "STATS", payload: res.data });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("beneficiairebyassurance", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_BENEFICIAIRE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("entreprisebyassurance", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_ENTREPRISE_BY_IPM",
              payload: res?.sort((a, b) => b.id - a.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getData("entreprise", auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_ENTREPRISE",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("remboursementbyipm", auth?.user?.id, auth?.token)
          .then((res) => {
            dispatch({
              type: "GET_FICHES_DE_SOIN_FOR_IPM",
              payload: res?.sort((a, b) => a.id - b.id).reverse() || [],
            });
          })
          .catch((err) => console.log("erreur=>", err));

        getDataByID("admin_by_ipm", auth?.user?.id, auth.token)
          .then((res) => {
            //console.log('res', res)
            dispatch({
              type: "LIST_ADMIN_IPM",
              payload: res?.sort((a, b) => b.id - a.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("facturebyipm", auth?.user?.id, auth?.token)
          .then((res) => {
            dispatch({
              type: "LIST_FACTURE_BY_IPM",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
        getDataByID("notificationbyipm", auth?.user?.id, auth?.token)
          .then((res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((error) => console.log("error", error?.response));

        getDataByID("devisbyipm", auth?.user?.id, auth.token)
          .then((res) => {
            // console.log(res)
            dispatch({
              type: "DEVIS_BY_IPM",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("rapportbyipm", auth?.user?.id, auth.token)
          .then((res) => {
            // console.log('rapport', res)
            dispatch({
              type: "RAPPORT",
              payload: res,
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
      }

      if (auth.user?.user_type === "assureur") {
        getData("prestataire", auth.token)
          .then((res) => {
            // console.log('data', data)
            dispatch({
              type: "ADD_PRESTATAIRE",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getData("familledesoin", auth?.token).then((res) => {
          // console.log("notif", res);
          dispatch({
            type: "LIST_FAMILLE_SOINS",
            payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
          });
        });

        getDataByID("exclusionbyipm", auth?.user?.id, auth?.token)
          .then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "EXCLUSION_ASSUREUR",
              payload: {
                isLoading: false,
                data: res.results.sort((a, b) => b?.id - a?.id),
              },
            });
          })
          .catch((error) => console.log("error", error?.response));

        getDataByID("exceptionbyipm", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "OPPOSITION_ASSUREUR",
              payload: {
                isLoading: false,
                data: res.data.sort((a, b) => b?.id - a?.id),
              },
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("prestatairebyassurance", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "PRESTATAIRE_ASSUREUR",
              payload: {
                isLoading: false,
                data: res.results?.sort((a, b) => b.id - a.id),
              },
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("typeprestationbyassurance", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "PRISE_EN_CHARGE_ASSUREUR",
              payload: {
                isLoading: false,
                data: res?.sort((a, b) => b.id - a.id),
              },
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("fichedesoinbyipm", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "FICHESOINS_ASSUREUR",
              payload: {
                isLoading: false,
                data: res?.sort((a, b) => b.id - a.id),
              },
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("statbyassurance", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({ type: "STATS", payload: res.data });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("beneficiairebyassurance", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "BENEFICIAIRE_ASSUREUR",
              payload: {
                isLoading: false,
                data: res?.sort((a, b) => b.id - a.id),
              },
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("entreprisebyassurance", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "ENTREPRISE_ASSUREUR",
              payload: {
                isLoading: false,
                data: res?.sort((a, b) => b.id - a.id),
              },
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getData("entreprise", auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_ENTREPRISE",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("remboursementbyipm", auth?.user?.id, auth?.token)
          .then((res) => {
            // console.log("resp remb", res)
            dispatch({
              type: "REMBOURSEMENTS_ASSUREUR",
              payload: {
                isLoading: false,
                data: res?.sort((a, b) => b.id - a.id),
              },
            });
          })
          .catch((err) => console.log("erreur=>", err));

        getDataByID("admin_by_assureur", auth?.user?.id, auth.token)
          .then((res) => {
            console.log("res addmin", res);
            dispatch({
              type: "ADMIN_ASSUREUR",
              payload: {
                isLoading: false,
                data: res?.sort((a, b) => b.id - a.id),
              },
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("facturebyipm", auth?.user?.id, auth?.token)
          .then((res) => {
            dispatch({
              type: "FACTURES_ASSUREUR",
              payload: {
                isLoading: false,
                data: res?.sort((a, b) => b?.id - a?.id),
              },
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("notificationbyipm", auth?.user?.id, auth?.token)
          .then((res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((error) => console.log("error", error?.response));

        getDataByID("devisbyipm", auth?.user?.id, auth.token)
          .then((res) => {
            // console.log(res)
            dispatch({
              type: "DEVIS_ASSUREUR",
              payload: {
                isLoading: false,
                data: res?.sort((a, b) => b?.id - a?.id),
              },
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("rapportbyipm", auth?.user?.id, auth.token).then((res) => {
          // console.log('rapport', res)
          dispatch({
            type: "RAPPORT_ASSUREUR",
            payload: {
              isLoading: false,
              data: res,
            },
          });
        });
      }

      if (auth.user?.user_type === "entreprise" && !auth?.user?.admin_type) {
        getDataByID("beneficiairebyemploye", auth?.user?.id, auth.token)
          .then((res) => {
            // console.log('res benef', res)
            dispatch({
              type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
              payload: res?.sort((a, b) => b.id - a.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
        // getData("prestataire_agree", auth.token)
        //   .then((res) => {
        //     let data = [];
        //     //const found = arr1.some(r=> arr2.indexOf(r) >= 0)
        //     res.results.forEach((element) => {
        //       if (
        //         element.assurance_maladie?.length &&
        //         element?.assurance_maladie.some(
        //           (r) => auth?.user?.ipm_from.indexOf(r) >= 0
        //         )
        //       ) {
        //         data = [...data, element];
        //       }
        //     });
        //     dispatch({
        //       type: "ADD_PRESTATAIRE",
        //       payload: data.sort((a, b) => a?.id - b?.id).reverse(),
        //     });
        //   })
        //   .catch((e) => {
        //     console.log("e", e);
        //   });
        getDataByID("devisbyentreprise", auth?.user?.id, auth.token)
          .then((res) => {
            // console.log(res)
            dispatch({
              type: "DEVIS_BY_ENTREPRISE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
        getDataByID("fichedesoinbyentreprise", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_FICHE_DE_SOIN_BY_IPM",
              payload: res?.sort((a, b) => a.id - b.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("exceptionbyentreprise", auth?.user?.id, auth.token)
          .then((res) => {
            console.log("data", res);
            dispatch({
              type: "LIST_EXCEPTION_IPM",
              payload: res.data.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getData("assurance_maladies", auth.token)
          .then((res) => {
            let data = [];
            // const found = arr1.some(r=> arr2.indexOf(r) >= 0)
            res.results.forEach((element) => {
              if (
                auth?.user?.ipm_from?.length &&
                auth?.user?.ipm_from.includes(element?.id)
              ) {
                data = [...data, element];
              }
            });
            // console.log('data', data)
            dispatch({
              type: "ADD_IPM",
              payload: data.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        // getData("typeprestation", auth.token)
        //   .then((res) => {
        //     dispatch({
        //       type: "ADD_PRESTATION_TYPE",
        //       payload: res.results.reverse(),
        //     });
        //   })
        //   .catch((e) => {
        //     console.log("e", e);
        //   });

        getDataByID("prestatairebyentreprise", auth?.user?.id, auth.token)
          .then((res) => {
            // console.log('res pres', res)

            dispatch({
              type: "ADD_PRESTATAIRE",
              payload: _.uniqBy(res, function (e) {
                return e.id;
              })?.sort((a, b) => b?.id - a?.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("admin_by_entreprise", auth?.user?.id, auth.token)
          .then((res) => {
            //console.log('res', res)
            dispatch({
              type: "LIST_ADMIN_IPM",
              payload: res?.sort((a, b) => b.id - a.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("facturebyentreprise", auth?.user?.id, auth?.token).then(
          (res) => {
            // console.log(res)
            dispatch({
              type: "LIST_FACTURE_BY_ENTREPRISE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );

        getDataByID("statbyentreprise", auth?.user?.id, auth.token)
          .then((res) => {
            // console.log('stat', res)
            dispatch({ type: "STATS", payload: res.data });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("notificationbyentreprise", auth?.user?.id, auth?.token)
          .then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
        getDataByID("remboursementbyentreprise", auth?.user?.id, auth?.token)
          .then((res) => {
            // console.log(res, 'eee')
            dispatch({
              type: "GET_FICHES_DE_SOIN",
              payload: res?.sort((a, b) => a.id - b.id).reverse() || [],
            });
          })
          .catch((err) => console.log("erreur=>", err));

        getDataByID("rapportbyentreprise", auth?.user?.id, auth.token).then(
          (res) => {
            // console.log('rapport', res)
            dispatch({
              type: "RAPPORT",
              payload: res,
            });
          }
        );

        // getData('entreprise', auth.token)
        //   .then((res) => {
        //     dispatch({ type: 'ADD_ENTREPRISE', payload: res.results.reverse() })
        //   })
        //   .catch((e) => {
        //     console.log('e', e)
        //   })
        // getData("prestation", auth.token).then((res) => {
        //   dispatch({ type: "GET_FICHES_DE_SOIN", payload: res.results || [] });
        // });
      }

      if (auth.user?.user_type === "prestataire") {
        getDataByID("prestataire", auth?.user?.id, auth?.token)
          .then((res) => {
            if (res?.id) {
              getData("assurance_maladies", auth.token)
                .then((resp) => {
                  let data = [];
                  // const found = arr1.some(r=> arr2.indexOf(r) >= 0)
                  resp.results.forEach((element) => {
                    if (
                      res?.assurance_maladie?.length &&
                      res?.assurance_maladie.includes(element?.id)
                    ) {
                      data = [...data, element];
                    }
                  });
                  dispatch({
                    type: "ADD_IPM",
                    payload: data.sort((a, b) => a?.id - b?.id).reverse(),
                  });
                })
                .catch((e) => {
                  console.log("e", e);
                });
              let data = [];
              res.assurance_maladie.forEach(async (element) => {
                // console.log(element, "typeprestationbyassurance");

                getDataByID("typeprestationbyassurance", element, auth.token)
                  .then((res) => {
                    // let datas = [...data, ...res];
                    data = [...data, ...res];
                    dispatch({
                      type: "ADD_PRESTATION_TYPE",
                      payload: data.sort((a, b) => a?.id - b?.id).reverse(),
                    });
                    // console.log(data, "typeprestationbyassurance");
                  })
                  .catch((e) => {
                    console.log("e", e);
                  });
              });
            }
            dispatch({
              type: "EDIT_PRESTATAIRE",
              payload: res,
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
        getDataByID("beneficiairebyprestataire", auth?.user?.id, auth?.token)
          .then((res) => {
            dispatch({
              type: "ADD_BENEFICIAIRE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("exceptionbyprestataire", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "LIST_EXCEPTION_IPM",
              payload: res.data.sort((a, b) => b?.id - a?.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("prestationbyprestataire", auth?.user?.id, auth?.token)
          .then((res) => {
            dispatch({
              type: "LISTE_PRESTATATION_BY_PRESTATAIRE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("facturebyprestataire", auth?.user?.id, auth?.token)
          .then((res) => {
            dispatch({
              type: "LIST_FACTURE_BY_PRESTATAIRE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID(
          "fichedesoinvalidatebyprestataire",
          auth?.user?.id,
          auth?.token
        )
          .then((res) => {
            dispatch({
              type: "LISTE_FICHE_VALID_BY_PRESTATAIRE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("statbyprestataire", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({ type: "STATS", payload: res.data });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("servicebyprestataire", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "LIST_SERVICES",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("devisbyprestataire", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "DEVIS_BY_PRESTATAIRE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("notificationbyprestataire", auth?.user?.id, auth?.token)
          .then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
      }

      if (auth?.user?.is_superuser || auth.user?.user_type === "super_ipm") {
        getData("assurance_maladies", auth.token)
          .then((res) => {
            // console.log('data', res.results)
            dispatch({
              type: "ADD_IPM",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getData("prestataire", auth.token)
          .then((res) => {
            // console.log('data', res)
            dispatch({
              type: "ADD_PRESTATAIRE",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getData("super_admin", auth.token)
          .then((res) => {
            dispatch({
              type: "LIST_ADMIN_IPM",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("notificationbysuperadmin", auth?.user?.id, auth?.token)
          .then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getData("familledesoin", auth?.token)
          .then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_FAMILLE_SOINS",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
        getData("exclusion", auth?.token)
          .then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_EXCLUSION",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getData("conditions", auth?.token)
          .then((res) => {
            console.log("conditions", res);
            dispatch({
              type: "CONDITIONS",
              payload: {
                data: res,
                isLoading: false,
              },
            });
          })
          .catch((e) => {
            console.log("e", e);
            dispatch({
              type: "CONDITIONS",
              payload: { data: [], isLoading: false },
            });
          });
      }

      if (auth?.user?.user_type === "beneficiaire") {
        getDataByID("ayantdroitbybeneficiaire", auth?.user?.id, auth?.token)
          .then((response) => {
            // console.log("by benf", response);
            dispatch({
              type: "FICHESOINS_BENEFICIAIRE",
              payload: {
                data: response?.sort((a, b) => b?.id - a?.id),
                isLoading: false,
              },
            });
          })
          .catch((error) => {
            console.log("error", error?.response);
          });
      }

      if (beneficiaire?.id !== undefined) {
        getDataByID("ayantdroitbybeneficiaire", beneficiaire?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_AYANTDROIT_BY_BENEFICIAIRE",
              payload: res?.sort((a, b) => b.id - a.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
      }

      getData("ayantdroit", auth.token)
        .then((res) => {
          // console.log('data ayant droit', res)
          dispatch({
            type: "LIST_AYANTDROIT",
            payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
          });
        })
        .catch((e) => {
          console.log("e", e);
        });

      getData("familledesoin", auth?.token)
        .then((res) => {
          // console.log("famille de soins", res);
          dispatch({
            type: "LIST_FAMILLE_SOINS",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((e) => {
          console.log("e", e);
        });
      getData("specialite", auth?.token)
        .then((response) => {
          // console.log("specialites", response);
          dispatch({
            type: "LIST_SPECIALITES",
            payload: response.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((e) => {
          console.log("e", e);
        });
      // console.log('state', state.prestataire, state.prestataires)
    } else {
      dispatch({ type: "ADD_PRESTATAIRE", payload: [] });
      dispatch({ type: "PRESTATAIRE_BY_IPM", payload: [] });
      dispatch({ type: "ADD_PRESTATION_TYPE", payload: [] });
      dispatch({ type: "STATS", payload: [] });
      dispatch({ type: "GET_FICHES_DE_SOIN", payload: [] });
      dispatch({ type: "GET_FICHES_DE_SOIN_FOR_IPM", payload: [] });
      dispatch({ type: "LIST_ADMIN_IPM", payload: [] });
      dispatch({ type: "ADD_FICHE_DE_SOIN_BY_IPM", payload: [] });
      dispatch({ type: "ADD_BENEFICIAIRE", payload: [] });
      dispatch({ type: "ADD_ENTREPRISE", payload: [] });
      dispatch({ type: "LIST_BENEFICIAIRE_BY_ENTREPRISE", payload: [] });
      dispatch({ type: "ADD_AYANTDROIT_BY_BENEFICIAIRE", payload: [] });
    }
    
  }, [auth, beneficiaire?.id, entreprise?.id]);

  useEffect(() => {
    if (auth.token) {
      if (auth.user?.user_type === "admin_ipm") {
        getData("prestataire", auth.token)
          .then((res) => {
            // console.log('data', data)
            dispatch({
              type: "ADD_PRESTATAIRE",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("exclusionbyipm", auth?.user?.parent, auth?.token).then(
          (res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_EXCLUSION",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );

        getDataByID("exceptionbyipm", auth?.user?.parent, auth.token)
          .then((res) => {
            dispatch({
              type: "LIST_EXCEPTION_IPM",
              payload: res.data.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("prestatairebyassurance", auth?.user?.parent, auth.token)
          .then((res) => {
            dispatch({
              type: "PRESTATAIRE_BY_IPM",
              payload: res.results.reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("typeprestationbyassurance", auth?.user?.parent, auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_PRESTATION_TYPE",
              payload: res?.sort((a, b) => b.id - a.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("fichedesoinbyipm", auth?.user?.parent, auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_FICHE_DE_SOIN_BY_IPM",
              payload: res?.sort((a, b) => a.id - b.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("statbyassurance", auth?.user?.parent, auth.token)
          .then((res) => {
            dispatch({ type: "STATS", payload: res.data });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("beneficiairebyassurance", auth?.user?.parent, auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_BENEFICIAIRE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("entreprisebyassurance", auth?.user?.parent, auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_ENTREPRISE_BY_IPM",
              payload: res?.sort((a, b) => b.id - a.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getData("entreprise", auth.token)
          .then((res) => {
            dispatch({
              type: "ADD_ENTREPRISE",
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("remboursementbyipm", auth?.user?.parent, auth?.token)
          .then((res) => {
            dispatch({
              type: "GET_FICHES_DE_SOIN_FOR_IPM",
              payload: res?.sort((a, b) => a.id - b.id).reverse() || [],
            });
          })
          .catch((err) => console.log("erreur=>", err));

        getDataByID("admin_by_ipm", auth?.user?.parent, auth.token)
          .then((res) => {
            //console.log('res', res)
            dispatch({
              type: "LIST_ADMIN_IPM",
              payload: res?.sort((a, b) => b.id - a.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("facturebyipm", auth?.user?.parent, auth?.token).then(
          (res) => {
            dispatch({
              type: "LIST_FACTURE_BY_IPM",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );

        getDataByID("notificationbyipm", auth?.user?.parent, auth?.token).then(
          (res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );

        getDataByID("devisbyipm", auth?.user?.parent, auth.token)
          .then((res) => {
            // console.log(res)
            dispatch({
              type: "DEVIS_BY_IPM",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("rapportbyipm", auth?.user?.parent, auth.token).then(
          (res) => {
            // console.log('rapport', res)
            dispatch({
              type: "RAPPORT",
              payload: res,
            });
          }
        );
      }

      if (auth.user?.user_type === "entreprise" && auth?.user?.admin_type) {
        // getData("prestataire_agree", auth.token)
        //   .then((res) => {
        //     let data = [];
        //     //const found = arr1.some(r=> arr2.indexOf(r) >= 0)
        //     res.results.forEach((element) => {
        //       if (
        //         element.assurance_maladie?.length &&
        //         element?.assurance_maladie.some(
        //           (r) => auth?.user?.ipm_from.indexOf(r) >= 0
        //         )
        //       ) {
        //         data = [...data, element];
        //       }
        //     });
        //     dispatch({
        //       type: "ADD_PRESTATAIRE",
        //       payload: data.sort((a, b) => a?.id - b?.id).reverse(),
        //     });
        //   })
        //   .catch((e) => {
        //     console.log("e", e);
        //   });

        getDataByID("devisbyentreprise", auth?.user?.parent, auth.token)
          .then((res) => {
            // console.log(res)
            dispatch({
              type: "DEVIS_BY_ENTREPRISE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("exceptionbyentreprise", auth?.user?.parent, auth.token)
          .then((res) => {
            // console.log('data', res)
            dispatch({
              type: "LIST_EXCEPTION_IPM",
              payload: res.data.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getData("assurance_maladies", auth.token)
          .then((res) => {
            let data = [];
            // const found = arr1.some(r=> arr2.indexOf(r) >= 0)
            res.results.forEach((element) => {
              if (
                parent?.ipm_from?.length &&
                parent?.ipm_from.includes(element?.id)
              ) {
                data = [...data, element];
              }
            });
            console.log("data", data);
            dispatch({
              type: "ADD_IPM",
              payload: data.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        // getData("typeprestation", auth.token)
        //   .then((res) => {
        //     dispatch({
        //       type: "ADD_PRESTATION_TYPE",
        //       payload: res.results.reverse(),
        //     });
        //   })
        //   .catch((e) => {
        //     console.log("e", e);
        //   });

        getDataByID("beneficiairebyemploye", auth?.user?.parent, auth.token)
          .then((res) => {
            // console.log('res benef', res)
            dispatch({
              type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
              payload: res?.sort((a, b) => b.id - a.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("prestatairebyentreprise", auth?.user?.parent, auth.token)
          .then((res) => {
            // console.log('res pres', res)
            dispatch({
              type: "ADD_PRESTATAIRE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("admin_by_entreprise", auth?.user?.parent, auth.token)
          .then((res) => {
            //console.log('res', res)
            dispatch({
              type: "LIST_ADMIN_IPM",
              payload: res?.sort((a, b) => b.id - a.id),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("facturebyentreprise", auth?.user?.parent, auth?.token)
          .then((res) => {
            // console.log(res)
            dispatch({
              type: "LIST_FACTURE_BY_ENTREPRISE",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
        getDataByID("statbyentreprise", auth?.user?.parent, auth.token)
          .then((res) => {
            // console.log('stat', res)
            dispatch({ type: "STATS", payload: res.data });
          })
          .catch((e) => {
            console.log("e", e);
          });

        getDataByID("notificationbyentreprise", auth?.user?.parent, auth?.token)
          .then((res) => {
            // console.log("notif", res);
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res?.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
        getDataByID(
          "remboursementbyentreprise",
          auth?.user?.parent,
          auth?.token
        )
          .then((res) => {
            // console.log(res, 'eee')
            dispatch({
              type: "GET_FICHES_DE_SOIN",
              payload: res?.sort((a, b) => a.id - b.id).reverse() || [],
            });
          })
          .catch((err) => console.log("erreur=>", err));

        getDataByID("rapportbyentreprise", auth?.user?.parent, auth.token).then(
          (res) => {
            // console.log('rapport', res)
            dispatch({
              type: "RAPPORT",
              payload: res,
            });
          }
        );

        // getData('entreprise', auth.token)
        //   .then((res) => {
        //     dispatch({ type: 'ADD_ENTREPRISE', payload: res.results.reverse() })
        //   })
        //   .catch((e) => {
        //     console.log('e', e)
        //   })
        // getData("prestation", auth.token).then((res) => {
        //   dispatch({ type: "GET_FICHES_DE_SOIN", payload: res.results || [] });
        // });
      }
    } else {
      dispatch({ type: "ADD_PRESTATAIRE", payload: [] });
      dispatch({ type: "PRESTATAIRE_BY_IPM", payload: [] });
      dispatch({ type: "ADD_PRESTATION_TYPE", payload: [] });
      dispatch({ type: "STATS", payload: [] });
      dispatch({ type: "GET_FICHES_DE_SOIN", payload: [] });
      dispatch({ type: "GET_FICHES_DE_SOIN_FOR_IPM", payload: [] });
      dispatch({ type: "LIST_ADMIN_IPM", payload: [] });
      dispatch({ type: "ADD_FICHE_DE_SOIN_BY_IPM", payload: [] });
      dispatch({ type: "ADD_BENEFICIAIRE", payload: [] });
      dispatch({ type: "ADD_ENTREPRISE", payload: [] });
      dispatch({ type: "LIST_BENEFICIAIRE_BY_ENTREPRISE", payload: [] });
      dispatch({ type: "ADD_AYANTDROIT_BY_BENEFICIAIRE", payload: [] });
    }
    fetchData("cgu")
      .then((res) => {
        console.log("cgu", res);
        dispatch({
          type: "CGU",
          payload: {
            data: res,
            isLoading: false,
          },
        });
      })
      .catch((e) => {
        console.log("e", e);
        dispatch({
          type: "CGU",
          payload: { data: [], isLoading: false },
        });
      });
    fetchData("ppdp")
      .then((res) => {
        console.log("ppdp", res);
        dispatch({
          type: "PC",
          payload: {
            data: res,
            isLoading: false,
          },
        });
      })
      .catch((e) => {
        console.log("e", e);
        dispatch({
          type: "PC",
          payload: { data: [], isLoading: false },
        });
      });
  }, [auth, parent]);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};
