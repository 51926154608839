import React, { useEffect, useContext } from 'react'
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from 'react-bootstrap-table'
import GestionPrestataireAssureurTableSkeleton from './GestionPrestataireAssureurTableSkeleton'
import * as IconlyPack from 'react-iconly'
import { Link, NavLink } from 'react-router-dom'
import GestionPrestataireAssureurTableData from './GestionPrestataireAssureurTableData'
import ModifierPrestataireModal from '../../../Backend/Assurance/GestionPrestataireAssureur/ModifierPrestataireModal'
import RenvoiPasswordPrestataireModal from './RenvoiPasswordPrestataireModal'
import { DataContext } from "../../../store/GlobalState";
import moment from "moment";

var GestionPrestataireAssureurTable = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { assureur_prestataires, auth } = state;
  const [soins, setSoins] = React.useState(GestionPrestataireAssureurTableData);

  var [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, [soins]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
    expandBy: "column",
  };

  const dateFormater = (cell, row) => {
    return moment(new Date(cell)).format("DD/MM/YYYY");
  };

  const nameFormater = (cell, row) => {
    if (auth?.user?.user_type !== "entreprise") {
      return (
        <div className="nameFormatter" onClick={() => getItem(row)}>
          <span className="name">
            {" "}
            {row?.raison_sociale
              ? row?.raison_sociale
              : row?.prenom + " " + row?.nom}{" "}
          </span>{" "}
          <br />
          {/* {!props.isEntrepriseList && (
          <span className="entreprise"> {row.employeur.raison_sociale} </span>
        )} */}
        </div>
      );
    } else {
      //   return (
      //     <NavLink
      //       to={`/entreprise/prestation-prestataire/${row.id}`}
      //       className="nameFormatter"
      //       onClick={() => getItem(row)}
      //     >
      //       <span className="name">
      //         {' '}
      //         {row?.raison_sociale
      //           ? row?.raison_sociale
      //           : row?.prenom + ' ' + row?.nom}{' '}
      //       </span>{' '}
      //       <br />
      //       {/* {!props.isEntrepriseList && (
      //       <span className="entreprise"> {row.employeur.raison_sociale} </span>
      //     )} */}
      //     </NavLink>
      //   )
      // }
      return row?.raison_sociale || row?.prenom + " " + row?.nom;
    }
  };

  const nameFilter = (cell, row) => {
    return row?.raison_sociale || row?.prenom + " " + row?.nom;
  };

  const specialiteFormat = (cell, row) => {
    return row?.specialite?.nom;
  };

  const agreementFormatter = (cell, row) => {
    if (auth?.user?.user_type !== "entreprise") {
      if (cell === true) {
        return (
          <span
            className="badge badge-success easypm-badge-success linkable"
            data-toggle="modal"
            data-target=".agreement-valid-modal"
            onClick={() => getItem(row)}
          >
            {" "}
            OUI{" "}
          </span>
        );
      } else {
        return (
          <span
            className="badge badge-danger easypm-badge-danger linkable"
            data-toggle="modal"
            data-target=".agreement-valid-modal"
            onClick={() => getItem(row)}
          >
            {" "}
            NON{" "}
          </span>
        );
      }
    } else {
      if (cell === true) {
        return (
          <span className="badge badge-success easypm-badge-success ">
            {" "}
            OUI{" "}
          </span>
        );
      } else {
        return (
          <span className="badge badge-danger easypm-badge-danger"> NON </span>
        );
      }
    }
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <Link
          className="action-btn show-btn mr-2"
          to={{
            pathname: `/assurance/prestation-prestataire/${cell}`,
            state: { prestataire: row },
          }}
          title="prestations effectuées"
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </Link>
        <button className="action-btn edit-btn" title="Modification">
          <IconlyPack.Edit
            onClick={() => getItem(row)}
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#ModifierPrestataireModal"
          />
        </button>
        <button className="action-btn edit-btn" title="Renvoi de mot de passe">
          <IconlyPack.Password
            onClick={() => getItem(row)}
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#RenvoiPasswordPrestataireModal"
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    dispatch({
      type: "EDIT_PRESTATAIRE",
      payload: item,
    });
  };

  const isExpandableRow = (row) => {
    if (row.id) return true;
    else return false;
  };

  const expandComponent = (row) => {
    return (
      <div className="easypm-collapse-body">
        <div className="card">
          <div className="card-body">
            <div className="add-family-form-container">
              {row && (
                <div className="crud-form">
                  <div className="row crud-row">
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="numero_contrat">N°.Contrat: </strong>

                        <span className="crud-form-control">
                          {row?.num_contrat}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="first_name">Agréement: </strong>
                        <span className="crud-form-control">
                          {row?.agreement ? "Oui" : "Non"}
                        </span>
                      </div>
                    </div>
                    {row?.raison_sociale && (
                      <>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="name">Nom: </strong>
                            <span className="crud-form-control">
                              {row?.raison_sociale}
                            </span>
                          </div>
                        </div>
                        {/* <div className="col-md-6"></div> */}
                      </>
                    )}

                    {row?.prenom && (
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group crud-form-group">
                          <strong htmlFor="name">Prénom: </strong>
                          <span className="crud-form-control">
                            {row?.prenom}
                          </span>
                        </div>
                      </div>
                    )}

                    {row?.nom && (
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group crud-form-group">
                          <strong htmlFor="name">Nom: </strong>
                          <span className="crud-form-control">{row?.nom}</span>
                        </div>
                      </div>
                    )}

                    <div
                      className={`${
                        row?.specialite?.nom?.length <= 30
                          ? "col-md-6"
                          : "col-md-12"
                      } crud-col-item`}
                    >
                      <div className="form-group crud-form-group">
                        <strong htmlFor="specialite">Spécialité: </strong>
                        <span className="crud-form-control">
                          {row?.specialite?.nom}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="ninea">NINEA: </strong>
                        <span className="crud-form-control">{row?.ninea}</span>
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="adresse">Adresse: </strong>
                        <span className="crud-form-control">
                          {row?.adresse}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="telephone">Téléphone: </strong>
                        <span className="crud-form-control">
                          {row?.telephone}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="first_name">Date de début: </strong>
                        <span className="crud-form-control">
                          {row?.date_debut ? row?.date_debut : ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="first_name">Date de fin: </strong>
                        <span className="crud-form-control">
                          {row?.date_fin ? row?.date_fin : ""}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="email">Email: </strong>
                        <span className="crud-form-control">{row?.email}</span>
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="website">Site web: </strong>
                        <span className="crud-form-control">
                          {row?.website}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const optionFilter = {
    true: "OUI",
    false: "NON",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {assureur_prestataires?.isLoading && (
              <GestionPrestataireAssureurTableSkeleton />
            )}
            {!assureur_prestataires?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={assureur_prestataires?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                  expandableRow={isExpandableRow}
                  expandComponent={expandComponent}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="nom"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="specialite"
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px" },
                    }}
                    filterValue={specialiteFormat}
                    dataFormat={(cell, row) => specialiteFormat(cell, row)}
                  >
                    Spécialité
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="date_fin"
                    dataFormat={dateFormater}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                  >
                    Date de fin
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="telephone"
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                  >
                    Téléphone
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="120"
                    filter={{ type: "SelectFilter", options: optionFilter }}
                    dataField="agreement"
                    dataFormat={(cell, row) => agreementFormatter(cell, row)}
                  >
                    Agréement
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="120"
                    dataField="id"
                    expandable={false}
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            class="modal fade"
            id="ModifierPrestataireModal"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="ModifierPrestataireModalLabel"
            aria-hidden="true"
          >
            <ModifierPrestataireModal />
          </div>
          <div
            class="modal fade"
            id="RenvoiPasswordPrestataireModal"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="RenvoiPasswordPrestataireModalLabel"
            aria-hidden="true"
          >
            <RenvoiPasswordPrestataireModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionPrestataireAssureurTable
