import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import { getDataByID } from "../../../../utils/fetchData";

function BarChart({ auth }) {
  const [date, setDate] = useState(new Date());
  const [count, setCount] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [countIpm, setCountIpm] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [countAdh, setCountAdh] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const [stats, setStats] = useState([]);
  const [labels, setLabels] = useState([]);
  const [label, setLabel] = useState("");
  useEffect(() => {
    setLabels(dateRange(moment(new Date()).format("YYYY-MM-DD")));
  }, [date]);

  useEffect(() => {
    if (!auth?.user?.admin_type) {
      getDataByID(`dashboardbyipm`, auth?.user?.id, auth?.token).then(
        (stat) => {
          setStats(stat?.data);
          setIsPageLoading(false);
        }
      );
    } else {
      getDataByID(`dashboardbyipm`, auth?.user?.id, auth?.token).then(
        (stat) => {
          setStats(stat?.data);
          setIsPageLoading(false);
        }
      );
    }
  }, [auth, date]);

  useEffect(() => {
    let part_total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let part_ipm = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let part_adh = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    if (date && stats?.length) {
      stats.forEach((element) => {
        part_total[moment(element?.month).format("M") - 1] =
          element?.cout_total;
        part_ipm[moment(element?.month).format("M") - 1] = element?.part_ipm;
        part_adh[moment(element?.month).format("M") - 1] =
          element?.part_beneficiaire;
      });
    }
    setCount(part_total);
    setCountIpm(part_ipm);
    setCountAdh(part_adh);
  }, [date, stats]);

  const dateRange = (endDate) => {
    var start = moment().startOf("year").format("YYYY-MM-DD").split("-");
    var end = moment(endDate).format("YYYY-MM-DD").split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];
    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      // console.log("mois", startYear, endYear);
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var mois = moment().month(j).locale("fr").format("MMM");
        dates.push([mois].join("-"));
      }
    }
    return dates;
  };
  const series = [
    {
      name: "Coût total",
      type: "column",
      data: count,
    },
    {
      name: "Prise en charge assurance",
      type: "column",
      data: countIpm,
    },
    {
      name: "Parts assurés",
      type: "column",
      data: countAdh,
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 4],
    },
    title: {
      text: "Répartition mensuelle des dépenses par poste",
      align: "left",
      offsetX: 1,
    },
    xaxis: {
      categories: labels,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#000000",
        },
        labels: {
          style: {
            colors: "#000000",
          },
        },
        title: {
          text: "",
          style: {
            color: "#000000",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };
  return (
    <div className="">
      <div id="chart">
        {isPageLoading ? (
          <div>
            <Skeleton width={`100%`} height={300} />
          </div>
        ) : (
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={350}
          />
        )}
      </div>
    </div>
  );
}

export default BarChart;
