import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import BaseDonneeListeSpecialitesTableSkeleton from "./BaseDonneeListeSpecialitesTableSkeleton";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import BaseDonneeListeSpecialitesTableData from "./BaseDonneeListeSpecialitesTableData";
import GestionSoinBeneficiaireModal from "../../../Backend/Beneficiaire/FicheDeSoinsBeneficiaire/GestionSoinBeneficiaire";
import ModifierSoinModal from "../../../Backend/Beneficiaire/FicheDeSoinsBeneficiaire/ModifierSoinModal";
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'
import Profil from "../../../../images/others/photo.png";
import { getAvatarOrDefault } from "../../../Backend/Beneficiaire/LivretBeneficiaire/AyantDroitBeneficiaireTable.js/AyantDroitBeneficiaireTable";
import { getName } from "../../../../utils/utils";
import AjouterRdv from "../../../Backend/Beneficiaire/GestionRendezVous/AjouterRdv";
var BaseDonneeListeSpecialitesTable = ({ data, loading }) => {
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-Beneficiaire">
        <span
          data-toggle="modal"
          data-target={`#AjouterRdv${row.id}`}
          className="action-btn edit-btn btn-prendre-rv"
        >
          <IconlyPack.Message
            set="light"
            primaryColor="#919191"
            className="action-btn-icon action-edit-icon"
          />
          <span className="pl-2">Demander un RV</span>
        </span>
        <div
          class="modal fade"
          id={`AjouterRdv${row.id}`}
          tabindex="-1"
          aria-labelledby="AjouterRdvLabel"
          aria-hidden="true"
        >
          <AjouterRdv
            onDone={() => {
              window.$(`#AjouterRdv${row.id}`).modal("hide");
            }}
            prestataire={row}
          />
        </div>
      </div>
    );
  };

  const photoFormatter = (cell, row) => {
    const avatar = getAvatarOrDefault(row?.avatar);
    return (
      <div>
        <img
          style={{ width: "50px", height: "50px", borderRadius: "100%" }}
          src={avatar}
          alt="Image"
        />
        {cell}
      </div>
    );
  };
  const opt = {};
  for (let i of data) {
    opt[i.specialite.nom] = i.specialite.nom;
  }
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {loading && <BaseDonneeListeSpecialitesTableSkeleton />}
            {!loading && (
              <div className="table-container">
                <BootstrapTable
                  data={data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="70"
                    dataField="photo"
                    dataFormat={(cell, row) => photoFormatter(cell, row)}
                  >
                    Photos
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="nom"
                    dataFormat={(_, r) => getName(r)}
                    filterValue={(_, r) => getName(r)}
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                  >
                    Nom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    filterValue={(_, r) => r.specialite.nom}
                    dataFormat={(_, r) => r.specialite.nom}
                    filter={{ type: "SelectFilter", options: opt }}
                    dataField="specialite"
                  >
                    Spécialités
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GenererSoinsBeneficiaire"
            tabIndex="-1"
            aria-labelledby="GenererSoinsBeneficiaireLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <GestionSoinBeneficiaireModal />
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionSoinEditBeneficiaireModal"
            tabIndex="-1"
            aria-labelledby="GestionSoinEditBeneficiaireModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <ModifierSoinModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseDonneeListeSpecialitesTable;
