import React, { useContext, useEffect } from 'react'
import { DataContext } from '../../../store/GlobalState'
import UseUpdateProfileInformationForm from './UseUpdateProfileInformationForm'
import { Toggle } from 'rsuite'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
// import AcceptGénérationModal from '../../../Modals/AcceptGénérationModal'

var ProfileInformation = () => {
  // const [userType, setUserType] = React.useState(window.sessionStorage.getItem('userType'))
  // var [isPageLoading, setIsPageLoading] = React.useState(true);
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setValue,
  } = UseUpdateProfileInformationForm()

  const { state } = useContext(DataContext)
  const { auth } = state

  const [phone, setPhone] = React.useState('')

  React.useEffect(() => {
    if (auth?.user?.id) {
      setPhone(auth?.user?.telephone)
    }
    console.log(auth?.user)
  }, [auth?.user?.id])

  const handleSetPhone = (val) => {
    setPhone(val)
    register('telephone', {
      required: {
        value: true,
        message: 'Ce champ est obligatoire.',
      },
      pattern: {
        value: new RegExp(/^((\+)221|0)[1-9](\d{2}){4}$/),
        message: 'Veuillez vérifier le format du numéro de téléphone',
      },
    })
    setValue('telephone', '+' + val)
  }

  useEffect(() => {
    if (isSuccessfullySubmitted) {
      setTimeout(() => {
        setIsSuccessfullySubmitted(false)
      }, 5000)
    }
  }, [isSuccessfullySubmitted, formState, setIsSuccessfullySubmitted])

  const handleChange = (e) => {
    console.log('val', e)
    setValue('auto_generate_fiche', e)
    if (
      auth?.user?.user_type === 'ipm' ||
      auth?.user?.user_type === 'assurance_sante'
    ) {
      onSubmit('valid')
    }

    if (e === true) {
      window.$('.accept-generation-modal').modal('show')
    }
  }
  return (
    <div className="easypm-tabs-item-content-container">
      <div className="easypm-tabs-item">
        <div className="add-family-form-container">
          <form className="crud-form" onSubmit={onSubmit}>
            <div className="row crud-row">
              {auth?.user?.user_type !== "admin_ipm" &&
              !auth?.user?.admin_type ? (
                <>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="raison_sociale">Raison Sociale</label>
                      <input
                        {...register("raison_sociale")}
                        defaultValue={auth?.user?.raison_sociale}
                        type="text"
                        placeholder="Raison Sociale"
                        name="raison_sociale"
                        className="form-control crud-form-control"
                        id="raison_sociale"
                      />
                      {formState?.errors &&
                        formState?.errors?.raison_sociale && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.raison_sociale?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="ninea">NINEA</label>
                      <input
                        {...register("ninea")}
                        defaultValue={auth?.user?.ninea}
                        type="text"
                        placeholder="NINEA"
                        name="ninea"
                        className="form-control crud-form-control"
                        id="ninea"
                      />
                      {formState?.errors && formState?.errors?.ninea && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState?.errors?.ninea?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="prenom">Prénom</label>
                      <input
                        {...register("prenom")}
                        defaultValue={auth?.user?.prenom}
                        type="text"
                        placeholder="Prénom"
                        name="prenom"
                        className="form-control crud-form-control"
                        id="prenom"
                      />
                      {formState?.errors && formState?.errors?.prenom && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState?.errors?.prenom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="nom">Nom</label>
                      <input
                        {...register("nom")}
                        defaultValue={auth?.user?.nom}
                        type="text"
                        placeholder="Nom"
                        name="nom"
                        className="form-control crud-form-control"
                        id="nom"
                      />
                      {formState?.errors && formState?.errors?.nom && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState?.errors?.nom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-6 crud-col-item">
                <div className="form-group crud-form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    {...register("email")}
                    defaultValue={auth?.user?.email}
                    type="email"
                    placeholder="Email"
                    name="email"
                    className="form-control crud-form-control"
                    id="email"
                  />
                  {formState?.errors && formState?.errors?.email && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.email?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 crud-col-item">
                <div className="form-group crud-form-group">
                  <label htmlFor="adresse">Adresse</label>
                  <input
                    {...register("adresse")}
                    defaultValue={auth?.user?.adresse}
                    type="text"
                    placeholder="Adresse"
                    name="adresse"
                    className="form-control crud-form-control"
                    id="adresse"
                  />
                  {formState?.errors && formState?.errors?.adresse && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.adresse?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 crud-col-item">
                <div className="form-group crud-form-group">
                  <label htmlFor="telephone">Téléphone</label>
                  {/* <input {...register('telephone')}  defaultValue={auth?.user?.telephone} type="text" placeholder="+221780158171" name="telephone" className="form-control crud-form-control" id="telephone" /> */}
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    inputClass="form-control crud-form-control"
                    country={"sn"}
                    onlyCountries={["sn"]}
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    disableDropdown={true}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    countryCodeEditable={false}
                    onChange={(phone) => handleSetPhone(phone)}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                    }}
                  />
                  {formState?.errors && formState?.errors?.telephone && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.telephone?.message}
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="col-md-6 crud-col-item">
                <div className="form-group crud-form-group">
                  <label htmlFor="telephone">Toogle</label>
                  
                </div>
              </div> */}
              {auth?.user?.user_type !== "entreprise" &&
                auth?.user?.user_type !== "admin_ipm" && (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="cle">
                        Génération automatique de fiche de soins
                      </label>
                      <div className="filter-item-container">
                        {auth?.user?.id && (
                          <Toggle
                            size="lg"
                            defaultChecked={auth?.user?.auto_generate_fiche}
                            onChange={handleChange}
                            checkedChildren="Accepter"
                            unCheckedChildren="Refuser"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}

              <input
                {...register("id")}
                type="hidden"
                name="id"
                value={auth?.user?.id || ""}
              />
              <div className="col-md-3 offset-md-9 crud-form-action-btn-container">
                {formState.isSubmitted && isSuccessfullySubmitted && (
                  <button className="btn btn-primary crud-submit-btn" disabled>
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {!isSuccessfullySubmitted && (
                  <button className="btn btn-primary crud-submit-btn">
                    Modifier
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProfileInformation
