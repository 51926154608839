const SoinsData = [
    {
        id: 1,
        type: 'Soin',
        beneficiaire: 'Sadio Sangharé',
        numero_contrat: '123456',
        cout: 12000,
        montant_rembourse: 9000,
        montant_retenu: 3000,
        date: '23-11-2020',
        fournisseur: 'Ceerno Lô',
        entreprise: 'Volkeno'
    },
    {
        id: 2,
        type: 'Soin',
        beneficiaire: 'Sadio Sangharé',
        numero_contrat: '123456',
        cout: 12000,
        montant_rembourse: 9000,
        montant_retenu: 3000,
        date: '23-11-2020',
        fournisseur: 'Ceerno Lô',
        entreprise: 'Volkeno'
    },
    {
        id: 3,
        type: 'Soin',
        beneficiaire: 'Sadio Sangharé',
        numero_contrat: '123456',
        cout: 12000,
        montant_rembourse: 9000,
        montant_retenu: 3000,
        date: '23-11-2020',
        fournisseur: 'Ceerno Lô',
        entreprise: 'Volkeno'
    },
    {
        id: 4,
        type: 'Soin',
        beneficiaire: 'Sadio Sangharé',
        numero_contrat: '123456',
        cout: 12000,
        montant_rembourse: 9000,
        montant_retenu: 3000,
        date: '23-11-2020',
        fournisseur: 'Ceerno Lô',
        entreprise: 'Volkeno'
    },
];

export default SoinsData;
