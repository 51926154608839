import { facture } from './Endpoints'
import axios from 'axios'

export default {
    async getListFactures(config) {
        const response = await axios.get(facture.onGetOrPostFacture(), config);
        // console.log(response)
        return response.data;
    },
    async postCreateFacture(data, config) {
        const response = await axios.post(facture.onGetOrPostFacture(), data, config);
        return response.data;
    },
    async putFacture(id, data, config) {
        const response = await axios.put(facture.onEditFacture(id), data, config);
        return response.data;
    },
    async deleteFacture(id, config) {
        const response = await axios.delete(facture.onDeleteFacture(id), config);
        return response;
    },
    async createFacture(data, config) {
        const response =  await axios.post(facture.postFacture(), data, config);
        return response.data;
    },
    async onPutFacture(id, data, config) {
        const response = await axios.put(facture.editFacture(id), data, config);
        return response.data;
    },
    async onEditFacture(id, data, config) {
        const response = await axios.put(facture.onPutFacture(id), data, config);
        return response.data;
    },

}