import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { DataContext } from "../../../../store/GlobalState";
import Exclusion from "../../../../Services/Exclusion";
import { getDataByID } from "../../../../utils/fetchData";

function UseAddExclusion() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        designation: yup.string().required("La désignation est requise"),
        familledesoin: yup.string().required("La famille de soins est requise"),
        typedesoin: yup.string().required("Le type de soins est requis."),
        // ipm: yup.string().required("L'ipm de fin est requise."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    formValues["ipm"] = auth?.user?.id;
    console.log("data", formValues);
    var exclusionRequest = Exclusion.postCreateExclusion(
      formValues,
      requestConfig
    );
    await exclusionRequest
      .then((response) => {
        var exceptionInfo = response;
        console.log(response);
        if (auth.user?.admin_type === "assureur") {
          getDataByID("exclusionbyipm", auth?.user?.parent, auth?.token)
            .then((res) => {
              // console.log("notif", res);
              dispatch({
                type: "EXCLUSION_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res.results.sort((a, b) => b?.id - a?.id),
                },
              });
            })
            .catch((error) => console.log("error", error?.response));
        } else {
          getDataByID("exclusionbyipm", auth?.user?.id, auth?.token)
            .then((res) => {
              // console.log("notif", res);
              dispatch({
                type: "EXCLUSION_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res.results.sort((a, b) => b?.id - a?.id),
                },
              });
            })
            .catch((error) => console.log("error", error?.response));
        }

        e.target.reset();
        reset();
        window.$(".ajout-exclusion-modal").modal("hide");
        toast.success("Exclusion ajoutée avec succès.");
        setIsSuccessfullySubmitted(false);
        setIsSuccess(true);
        console.log(isSuccessfullySubmitted);
      })
      .catch((error) => {
        console.log(error);
        setIsSuccessfullySubmitted(false);
        setIsFormRequestError(true);
        if (
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          toast.error(
            "Oupsss! L'adresse email est déjà associée à un compte. Veuillez utiliser une autre adresse email.",
            {
              position: "top-right",
              autoClose: 10000,
            }
          );
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };

  return {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitCreateForm),
    setValue,
    reset,
    getValues,
    isSuccess,
  };
}

export default UseAddExclusion;
