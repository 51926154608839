import moment from "moment";
import React, { useState } from "react";
// import * as IconlyPack from 'react-iconly'
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import { CountryDropdown } from "react-country-region-selector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

var CRUDBeneficiaireModal = (props) => {
  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());
  var [date, setDate] = React.useState(new Date());
  const [isValid, setIsValid] = React.useState(true);
  var beneficiaire = JSON.parse(sessionStorage.getItem("benefInfos"));
  var [country, setCountry] = useState();
  var [choice, setChoice] = useState("");
  var userType = sessionStorage.getItem("userType");
  var userID = sessionStorage.getItem("userID");
  const [phone, setPhone] = React.useState("");

  const handleSetPhone = (val) => {
    // console.log('phone', val)
    setPhone(val);
    props.register("telephone");
    props.setValue("telephone", "+" + val);
  };

  React.useEffect(() => {
    if (props.userActionType === "edit" && beneficiaire?.id) {
      props.register("email");
      props.setValue("email", beneficiaire?.email);
      props.register("assurance");
      props.setValue("assurance", beneficiaire?.assurance?.id);
      props.register("maticule");
      props.setValue("maticule", beneficiaire?.matricule);
      setCountry(beneficiaire?.nationnalite);
      setDate(beneficiaire?.date_naissance);
      if (beneficiaire?.date_debut !== null) {
        setStartDate(new Date(beneficiaire?.date_debut));
      }

      if (beneficiaire?.date_fin !== null) {
        setEndDate(new Date(beneficiaire?.date_fin));
      }

      if (beneficiaire?.date_de_naissance !== null) {
        setDate(new Date(beneficiaire?.date_de_naissance));
      }

      if (beneficiaire?.telephone && beneficiaire?.telephone.includes("+221")) {
        setPhone(beneficiaire?.telephone);
      } else {
        setPhone("+221" + beneficiaire?.telephone);
      }
    }
    if (endDate && startDate && startDate > endDate) {
      setIsValid(false);
    } else {
      props.register("date_debut");
      props.setValue("date_debut", moment(startDate).format("YYYY-MM-DD"));

      props.register("date_fin");
      props.setValue("date_fin", moment(endDate).format("YYYY-MM-DD"));
    }

    if (props?.entrepriseId !== undefined) {
      props.register("employeur");
      props.setValue("employeur", props?.entrepriseId);
    }
  }, [beneficiaire?.id, props.userActionType, props?.entrepriseId]);

  React.useEffect(() => {
    if (props.isSuccess) {
      props.register("date_de_naissance");
      props.setValue(
        "date_de_naissance",
        moment(new Date()).format("YYYY-MM-DD")
      );
      setPhone("+221");
      props.register("telephone");
      props.setValue("telephone", "");
      props.register("nationnalite");
      props.setValue("nationnalite", "");
      setCountry("");
      props.register("date_debut");
      props.setValue("date_debut", moment(new Date()).format("YYYY-MM-DD"));

      props.register("date_fin");
      props.setValue("date_fin", moment(new Date()).format("YYYY-MM-DD"));
      setDate(new Date());
      setStartDate(new Date());
      setEndDate(new Date());
    }
  }, [props.isSuccess]);

  const handleDate = (date) => {
    setDate(date);
    props.setValue("date_de_naissance", moment(date).format("YYYY-MM-DD"));
  };

  const handleDateDebut = (date) => {
    setStartDate(date);
    props.register("date_debut");
    props.setValue("date_debut", moment(date).format("YYYY-MM-DD"));
    if (endDate && date > endDate) {
      setIsValid(false);
    }
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      props.register("date_fin");
      props.setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };

  const handleChoice = (e) => {
    props.register("situation_matrimoniale");
    props.setValue("situation_matrimoniale", e.target.value);
    setChoice(e.target.value);
    console.log(e.target.value);
  };

  const handleReset = async () => {
    if (props.userActionType === "edit") {
      window.sessionStorage.removeItem("benefInfos");
    }
    props.reset();
  };

  const selectCountry = async (val) => {
    // await setCountry()
    await props.setValue("nationnalite", val);
    await setCountry(val);
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            {props.userActionType === "edit" ? "Modifier" : "Ajouter"} un
            participant
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {props.userActionType !== "edit" && (
              <form className="crud-form" onSubmit={props.onSubmit}>
                <div className="row crud-row">
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="prenom">Prénom</label>
                      <input
                        {...props.register("prenom")}
                        type="text"
                        placeholder="Prénom"
                        name="prenom"
                        className="form-control crud-form-control"
                        id="prenom"
                      />
                      {props.formState.errors &&
                        props.formState.errors.prenom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.prenom?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="nom">Nom</label>
                      <input
                        {...props.register("nom")}
                        type="text"
                        placeholder="Nom"
                        name="nom"
                        className="form-control crud-form-control"
                        id="nom"
                      />
                      {props.formState.errors && props.formState.errors.nom && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.nom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="sexe">Genre</label>
                      <div className="filter-item-container">
                        <select
                          {...props.register("sexe")}
                          name="sexe"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                        >
                          <option hidden value="">
                            Choisir un genre
                          </option>
                          <option value="femme">FEMME</option>
                          <option value="homme">HOMME</option>
                        </select>
                        {props.formState.errors &&
                          props.formState.errors.sexe && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {props.formState.errors.sexe?.message}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="situation_matrimoniale">
                        Situation matrimoniale
                      </label>
                      <div className="filter-item-container">
                        <select
                          onChange={(e) => handleChoice(e)}
                          name="situation_matrimoniale"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form select-country-input"
                          aria-label="Default select example"
                        >
                          <option hidden value="">
                            Choisir
                          </option>
                          <option value="marié(e)">MARIÉ(E)</option>
                          <option value="veuf(ve)">VEUF(VE)</option>
                          <option value="célibataire">CÉLIBATAIRE</option>
                          {/* <option value="divorcé">DIVORCÉ</option> */}
                        </select>
                        {props.formState.errors &&
                          props.formState.errors.situation_matrimoniale && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {
                                props.formState.errors.situation_matrimoniale
                                  ?.message
                              }
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="groupe_sanguin">Groupe sanguin</label>
                      <div className="filter-item-container">
                        <select
                          {...props.register("groupe_sanguin")}
                          name="groupe_sanguin"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                        >
                          <option hidden value="">
                            Choisir un groupe sanguin
                          </option>
                          <option value="A+">A+</option>
                          <option value="A-">A-</option>
                          <option value="B+">B+</option>
                          <option value="B-">B-</option>
                          <option value="AB+">AB+</option>
                          <option value="AB-">AB-</option>
                          <option value="O+">O+</option>
                          <option value="O-">O-</option>
                        </select>
                        {props.formState.errors &&
                          props.formState.errors.groupe_sanguin && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {props.formState.errors.groupe_sanguin?.message}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="nationnalite">Nationalité</label>
                      <CountryDropdown
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form select-country-input"
                        aria-label="Default select example"
                        defaultOptionLabel="Choisir une nationalité"
                        value={country}
                        priorityOptions={["SN"]}
                        name="nationnalite"
                        onChange={(val) => selectCountry(val)}
                      />

                      {props.formState.errors &&
                        props.formState.errors.nationnalite && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.nationnalite?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="num_contrat">Num. Contrat</label>
                      <input
                        {...props.register("num_contrat")}
                        type="text"
                        placeholder="Numéro contrat"
                        name="num_contrat"
                        className="form-control crud-form-control"
                        id="numero_contrat"
                      />
                      {props.formState.errors &&
                        props.formState.errors.num_contrat && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.num_contrat?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  {userType !== "entreprise" &&
                    props?.entrepriseId === undefined && (
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group crud-form-group required">
                          <label htmlFor="employeur">Adhérent</label>
                          <div className="filter-item-container">
                            <select
                              {...props.register("employeur")}
                              name="employeur"
                              className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                              aria-label="Default select example"
                            >
                              <option hidden value="">
                                Choisir
                              </option>
                              {props.listEntreprises &&
                                props.listEntreprises.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {" "}
                                    {item.raison_sociale}{" "}
                                  </option>
                                ))}
                            </select>
                            {props.formState.errors &&
                              props.formState.errors.employeur && (
                                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                  {props.formState.errors.employeur?.message}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}

                  {userType === "entreprise" && (
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <label htmlFor="assurance">IPM</label>
                        <div className="filter-item-container">
                          <select
                            {...props.register("assurance")}
                            name="assurance"
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                          >
                            <option hidden value="">
                              Choisir
                            </option>
                            {props.assurances &&
                              props.assurances.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {" "}
                                  {item.raison_sociale}{" "}
                                </option>
                              ))}
                          </select>
                          {props.formState.errors &&
                            props.formState.errors.assurance && (
                              <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                {props.formState.errors.assurance?.message}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="date_de_naissance">
                        Date de naissance
                      </label>
                      {/* <input
                        {...props.register('date_de_naissance')}
                        type="date"
                        name="date_de_naissance"
                        className="form-control crud-form-control"
                        id="date_de_naissance"
                      /> */}
                      <DatePicker
                        className="form-control crud-form-control"
                        selected={date}
                        placeholderText="Sélectionner une date"
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale="fr"
                        onChange={(date) => handleDate(date)}
                      />
                      {props.formState.errors &&
                        props.formState.errors.date_de_naissance && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.date_de_naissance?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        {...props.register("email")}
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="form-control crud-form-control"
                        id="email"
                      />
                      {props.formState.errors &&
                        props.formState.errors.email && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.email?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="adresse">Adresse</label>
                      <input
                        {...props.register("adresse")}
                        type="text"
                        placeholder="Adresse"
                        name="adresse"
                        className="form-control crud-form-control"
                        id="adresse"
                      />
                      {props.formState.errors &&
                        props.formState.errors.adresse && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.adresse?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="telephone">Téléphone</label>

                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control crud-form-control"
                        country={"sn"}
                        onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      {props.formState.errors &&
                        props.formState.errors.telephone && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.telephone?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="date_debut">Date de début</label>

                      <DatePicker
                        className="form-control crud-form-control"
                        selected={startDate}
                        placeholderText="Sélectionner une date"
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale="fr"
                        onChange={(date) => handleDateDebut(date)}
                      />
                      {props.formState.errors &&
                        props.formState.errors.date_debut && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.date_debut?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="date_fin">Date de fin</label>
                      <DatePicker
                        className="form-control crud-form-control"
                        selected={endDate}
                        placeholderText="Sélectionner une date"
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale="fr"
                        onChange={(date) => handleDateFin(date)}
                      />
                      {props.formState.errors &&
                        props.formState.errors.date_fin && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.date_fin?.message}
                          </div>
                        )}

                      {!isValid && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          La date de fin ne doit pas être inférieure à la date
                          de début.
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="matricule">Matricule</label>
                      <input
                        {...props.register("matricule")}
                        type="text"
                        placeholder="Matricule"
                        name="matricule"
                        className="form-control crud-form-control"
                        id="matricule"
                      />
                      {props.formState.errors &&
                        props.formState.errors.matricule && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.matricule?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div
                      className="form-group crud-form-group custom-file"
                      style={{ marginBottom: "13%" }}
                    >
                      <label htmlFor="document">
                        Carte nationale d'identité
                      </label>
                      <input
                        type="file"
                        placeholder="Importer votre CNI"
                        name="cni"
                        // onChange={handleFile}
                        className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                        id="document"
                        {...props.register("cni")}
                      />
                      {props.formState.errors && props.formState.errors.cni && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.cni.message}
                        </div>
                      )}
                    </div>
                  </div>

                  {choice === "marié(e)" ? (
                    <div className="col-md-6 crud-col-item">
                      <div
                        className="form-group crud-form-group custom-file"
                        style={{ marginBottom: "13%" }}
                      >
                        <label htmlFor="certificat_marriage">
                          Certificat de mariage
                        </label>
                        <input
                          type="file"
                          placeholder="Importer votre certifcat de mariage"
                          name="certificat_marriage"
                          // onChange={handleFile}
                          className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                          id="certificat_marriage"
                          {...props.register("certificat_marriage")}
                        />
                        {props.formState.errors &&
                          props.formState.errors.certificat_marriage && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {
                                props.formState.errors.certificat_marriage
                                  .message
                              }
                            </div>
                          )}
                      </div>
                    </div>
                  ) : null}

                  {/* <input {...props.register('ipm_from')} type="hidden" name="ipm_from" value={2} /> */}
                  <input
                    {...props.register("user_type")}
                    type="hidden"
                    name="user_type"
                    value={`beneficiaire`}
                  />

                  {userType !== "entreprise" && (
                    <input
                      {...props.register("assurance")}
                      type="hidden"
                      name="assurance"
                      value={userID}
                      // className="display-none"
                    />
                  )}

                  {userType === "entreprise" && (
                    <input
                      {...props.register("employeur")}
                      type="hidden"
                      name="employeur"
                      value={userID}
                      // className="display-none"
                    />
                  )}
                  <input
                    {...props.register("status")}
                    type="hidden"
                    name="status"
                    value={
                      userType !== "entreprise" ||
                      props?.entrepriseId === undefined
                        ? false
                        : true
                    }
                    // className="display-none"
                  />

                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                      onClick={() => handleReset()}
                    >
                      Retour
                    </button>
                    {props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                    {!props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        Enregistrer
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}

            {props.userActionType === "edit" && (
              <form className="crud-form" onSubmit={props.onSubmit}>
                <div className="row crud-row">
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_prenom">Prénom</label>
                      <input
                        defaultValue={
                          beneficiaire?.prenom ? beneficiaire?.prenom : ""
                        }
                        {...props.register("prenom")}
                        type="text"
                        placeholder="Prénom"
                        name="prenom"
                        className="form-control crud-form-control"
                        id="edit_prenom"
                      />
                      {props.formState.errors &&
                        props.formState.errors.prenom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.prenom?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_nom">Nom</label>
                      <input
                        defaultValue={beneficiaire?.nom}
                        {...props.register("nom")}
                        type="text"
                        placeholder="Nom"
                        name="nom"
                        className="form-control crud-form-control"
                        id="edit_nom"
                      />
                      {props.formState.errors && props.formState.errors.nom && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.nom?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_sexe">Genre</label>
                      <div className="filter-item-container">
                        {beneficiaire?.sexe && (
                          <select
                            defaultValue={beneficiaire?.sexe || ""}
                            {...props.register("sexe")}
                            name="sexe"
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                          >
                            <option hidden value="">
                              Choisir un genre
                            </option>
                            <option value="femme">FEMME</option>
                            <option value="homme">HOMME</option>
                          </select>
                        )}

                        {props.formState.errors &&
                          props.formState.errors.sexe && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {props.formState.errors.sexe?.message}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_situation_matrimoniale">
                        Situation matrimoniale
                      </label>
                      <div className="filter-item-container">
                        {beneficiaire?.situation_matrimoniale && (
                          <select
                            defaultValue={
                              beneficiaire?.situation_matrimoniale || ""
                            }
                            {...props.register("situation_matrimoniale")}
                            name="situation_matrimoniale"
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                          >
                            <option hidden value="">
                              Choisir
                            </option>
                            <option value="marié(e)">MARIÉ(E)</option>
                            <option value="veuf(ve)">VEUF(VE)</option>
                            <option value="célibataire">CÉLIBATAIRE</option>
                          </select>
                        )}

                        {props.formState.errors &&
                          props.formState.errors.situation_matrimoniale && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {
                                props.formState.errors.situation_matrimoniale
                                  ?.message
                              }
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="groupe_sanguin">Groupe sanguin</label>
                      {beneficiaire?.groupe_sanguin && (
                        <div className="filter-item-container">
                          <select
                            {...props.register("groupe_sanguin")}
                            defaultValue={
                              beneficiaire?.groupe_sanguin
                                ? beneficiaire?.groupe_sanguin
                                : ""
                            }
                            name="groupe_sanguin"
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                          >
                            <option hidden value="">
                              Choisir un groupe sanguin
                            </option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_nationnalite">Nationalité</label>
                      {/* <input
                        defaultValue={
                          beneficiaire?.nationnalite
                            ? beneficiaire?.nationnalite
                            : ''
                        }
                        {...props.register('nationnalite')}
                        type="text"
                        placeholder="Nationalité"
                        name="nationnalite"
                        className="form-control crud-form-control"
                        id="nationnalite"
                      /> */}
                      <CountryDropdown
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form select-country-input"
                        aria-label="Default select example"
                        defaultOptionLabel="Choisir une nationalité"
                        value={country}
                        priorityOptions={["SN"]}
                        name="nationnalite"
                        onChange={(val) => selectCountry(val)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_num_contrat">Num. Contrat</label>
                      <input
                        defaultValue={
                          beneficiaire?.num_contrat
                            ? beneficiaire?.num_contrat
                            : ""
                        }
                        {...props.register("num_contrat")}
                        type="text"
                        placeholder="Numéro contrat"
                        name="num_contrat"
                        className="form-control crud-form-control"
                        id="edit_num_contrat"
                      />
                    </div>
                  </div>
                  {userType !== "entreprise" &&
                    props?.entrepriseId === undefined && (
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group crud-form-group">
                          <label htmlFor="edit_employeur">Adhérent</label>
                          <div className="filter-item-container">
                            {beneficiaire?.employeur?.id !== undefined && (
                              <select
                                defaultValue={
                                  beneficiaire?.employeur?.id
                                    ? beneficiaire?.employeur?.id
                                    : ""
                                }
                                {...props.register("employeur")}
                                name="employeur"
                                className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                                aria-label="Default select example"
                              >
                                <option hidden value="">
                                  Choisir
                                </option>
                                {props.listEntreprises &&
                                  props.listEntreprises.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {" "}
                                      {item.raison_sociale}{" "}
                                    </option>
                                  ))}
                              </select>
                            )}

                            {props.formState.errors &&
                              props.formState.errors.employeur && (
                                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                  {props.formState.errors.employeur?.message}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}

                  {userType === "entreprise" && (
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <label htmlFor="edit_assurance">IPM</label>
                        <div className="filter-item-container">
                          {beneficiaire?.id && (
                            <select
                              defaultValue={
                                parseInt(beneficiaire?.assurance) || ""
                              }
                              {...props.register("assurance")}
                              name="assurance"
                              className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                              aria-label="Default select example"
                            >
                              <option hidden value="">
                                Choisir
                              </option>
                              {props.assurances &&
                                props.assurances.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {" "}
                                    {item.raison_sociale}{" "}
                                  </option>
                                ))}
                            </select>
                          )}

                          {props.formState.errors &&
                            props.formState.errors.assurance && (
                              <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                {props.formState.errors.assurance?.message}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_date_de_naissance">
                        Date de naissance
                      </label>
                      {beneficiaire?.id && (
                        <input
                          type="date"
                          placeholder="Date de naissance"
                          name="date_de_naissance"
                          className="form-control crud-form-control"
                          id="date_de_naissance"
                          {...props.register("date_de_naissance")}
                          defaultValue={beneficiaire?.date_de_naissance}
                        />
                      )}

                      {/* <DatePicker
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              {"<"}
                            </button>
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              {">"}
                            </button>
                          </div>
                        )}
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                      />
                    ); */}
                      {props.formState.errors &&
                        props.formState.errors.date_de_naissance && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.date_de_naissance?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_email">Email</label>
                      <input
                        defaultValue={beneficiaire?.email}
                        {...props.register("email")}
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="form-control crud-form-control"
                        id="edit_email"
                      />
                      {props.formState.errors &&
                        props.formState.errors.email && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.email?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_adresse">Adresse</label>
                      <input
                        defaultValue={beneficiaire?.adresse}
                        {...props.register("adresse")}
                        type="text"
                        placeholder="Adresse"
                        name="adresse"
                        className="form-control crud-form-control"
                        id="edit_adresse"
                      />
                      {props.formState.errors &&
                        props.formState.errors.adresse && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.adresse?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="edit_telephone">Téléphone</label>
                      {/* <input
                        defaultValue={beneficiaire?.telephone}
                        {...props.register('telephone')}
                        type="text"
                        placeholder="+221780158171"
                        name="telephone"
                        className="form-control crud-form-control"
                        id="edit_telephone"
                      />
                      <div className="input-infos-container">
                        <IconlyPack.Danger
                          set="light"
                          primaryColor="gray"
                          className="alerty-icon"
                        />
                        &nbsp;
                        <span className="input-infos">
                          Le numéro de téléphone doit être au format
                          +221780158171
                        </span>
                      </div> */}
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control crud-form-control"
                        country={"sn"}
                        onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      {props.formState.errors &&
                        props.formState.errors.telephone && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.telephone?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="date_debut">Date de début</label>

                      <DatePicker
                        className="form-control crud-form-control"
                        selected={startDate}
                        placeholderText="Sélectionner une date"
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale="fr"
                        onChange={(date) => handleDateDebut(date)}
                      />
                      {props.formState.errors &&
                        props.formState.errors.date_debut && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.date_debut?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="date_fin">Date de fin</label>
                      <DatePicker
                        className="form-control crud-form-control"
                        selected={endDate}
                        placeholderText="Sélectionner une date"
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale="fr"
                        onChange={(date) => handleDateFin(date)}
                      />
                      {props.formState.errors &&
                        props.formState.errors.date_fin && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.date_fin?.message}
                          </div>
                        )}

                      {!isValid && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          La date de fin ne peut pas être avant la date de
                          début.
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="matricule">Matricule</label>
                      <input
                        {...props.register("matricule")}
                        defaultValue={beneficiaire?.matricule || ""}
                        type="text"
                        placeholder="Matricule"
                        name="matricule"
                        className="form-control crud-form-control"
                        id="matricule"
                      />
                      {props.formState.errors &&
                        props.formState.errors.matricule && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.matricule?.message}
                          </div>
                        )}
                    </div>
                  </div>

                  <input
                    {...props.register("user_type")}
                    type="hidden"
                    name="user_type"
                    value={beneficiaire?.user_type || ""}
                  />
                  {/* {userType !== 'entreprise' && (
                    <input
                      {...props.register('assurance')}
                      type="hidden"
                      name="assurance"
                      value={userID}
                      // className="display-none"
                    />
                  )} */}
                  {userType === "entreprise" && (
                    <input
                      {...props.register("employeur")}
                      type="hidden"
                      name="employeur"
                      value={beneficiaire?.employeur?.id || ""}
                    />
                  )}
                  <input
                    {...props.register("id")}
                    type="hidden"
                    name="id"
                    value={beneficiaire?.id || ""}
                  />

                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                      onClick={() => handleReset()}
                    >
                      Retour
                    </button>
                    {props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                    {!props.isSuccessfullySubmitted && (
                      <button className="btn btn-primary crud-submit-btn">
                        Modifier
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRUDBeneficiaireModal;
