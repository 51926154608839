import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { DataContext } from "../store/GlobalState";
import Beneficiaire from "../Services/Beneficiaire";
import { toast } from "react-toastify";
import { getDataByID } from "../utils/fetchData";

const ImportCsvBeneficiaire = () => {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const userType = window.sessionStorage.getItem("userType");

  const { auth, entreprises_by_ipm, ipms, assureur_entreprises } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const { register, handleSubmit, formState, reset } = useForm();

  const onSubmit = async (data, e) => {
    console.log("data", data);
    var formData = new FormData();
    formData.append("file", data.file[0]);
    if (userType !== "entreprise") {
      formData.append("employeur", parseInt(data.employeur));
      formData.append("assurance", auth?.user?.id);
    } else {
      formData.append("assurance", parseInt(data.assurance));
      formData.append("employeur", auth?.user?.id);
    }

    setIsLoading(true);
    var beneRequest = Beneficiaire.postImportCsv(formData, requestConfig);
    await beneRequest
      .then((response) => {
        // var beneficiaireInfos = response
        console.log("resp", response);
        dispatch({ type: "SHOW_BENEFICIAIRE", payload: {} });
        if (userType === "entreprise") {
          getDataByID("beneficiairebyemploye", auth?.user?.id, auth.token)
            .then((res) => {
              // console.log('res benef', res)
              dispatch({
                type: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
                payload: res.reverse(),
              });
              toast.success("Adhérent ajouté avec succès.");
            })
            .catch((e) => {
              console.log("error", e);
            });
        }

        if (userType === "ipm" || userType === "assurance_sante") {
          getDataByID("beneficiairebyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_BENEFICIAIRE",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
              toast.success("Adhérent ajouté avec succès.");
            })
            .catch((e) => {
              console.log("e", e);
            });
        }

        if (userType === "assureur") {
          getDataByID("beneficiairebyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "BENEFICIAIRE_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res?.sort((a, b) => b.id - a.id),
                },
              });
              toast.success("Assuré ajouté avec succès.");
              window.$("#CsvModalItem").modal("hide");
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
        e.target.reset();
        toast.success("Participant ajouté avec succès.");
        window.$(".csv-beneficiaire-modal").modal("hide");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de l'ajout.");
      });
  };

  const handleReset = () => {
    reset();
  };
  //console.log('errors', errors)
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Importer un fichier csv
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item mb-3">
                  <div className="form-group crud-form-group custom-file">
                    <label htmlFor="file">Fichier</label>
                    <input
                      type="file"
                      placeholder="Importer un fichier csv"
                      name="file"
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      id="file"
                      {...register("file", {
                        required: "Ce champ est obligatoire",
                        validate: (value) => {
                          // console.log(value)
                          return (
                            (value &&
                              (value[0].type === "text/csv" ||
                                value[0]?.name?.split(".")?.pop() === "csv")) ||
                            "Veuillez importer un fichier .csv !"
                          );
                        },
                      })}
                    />
                    {formState.errors && formState.errors.file && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.file?.message}
                      </div>
                    )}
                  </div>
                </div>

                {userType !== "entreprise" && userType !== "assureur" && (
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="employeur">Adhérent</label>
                      <div className="filter-item-container">
                        <select
                          {...register("employeur", {
                            required: "Ce champ est obligatoire",
                          })}
                          name="employeur"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                          id="employeur"
                        >
                          <option hidden value="">
                            Choisir un adhérent
                          </option>
                          {entreprises_by_ipm.map((entreprise) => (
                            <option key={entreprise?.id} value={entreprise?.id}>
                              {entreprise?.raison_sociale}
                            </option>
                          ))}
                        </select>
                        {formState.errors && formState.errors.employeur && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.employeur?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {userType !== "entreprise" && userType === "assureur" && (
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="employeur">Entreprise</label>
                      <div className="filter-item-container">
                        <select
                          {...register("employeur", {
                            required: "Ce champ est obligatoire",
                          })}
                          name="employeur"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                          id="employeur"
                        >
                          <option hidden value="">
                            Choisir une entreprise
                          </option>
                          {assureur_entreprises?.data.map((entreprise) => (
                            <option key={entreprise?.id} value={entreprise?.id}>
                              {entreprise?.raison_sociale}
                            </option>
                          ))}
                        </select>
                        {formState.errors && formState.errors.employeur && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.employeur?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {userType === "entreprise" && (
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="assurance">Assurance</label>
                      <div className="filter-item-container">
                        <select
                          {...register("assurance", {
                            required: "Ce champ est obligatoire",
                          })}
                          name="assurance"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                          id="assurance"
                        >
                          <option hidden value="">
                            Choisir une assurance
                          </option>
                          {ipms.map((entreprise) => (
                            <option key={entreprise?.id} value={entreprise?.id}>
                              {entreprise?.raison_sociale}
                            </option>
                          ))}
                        </select>
                        {formState.errors && formState.errors.assurance && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.assurance?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {!isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}

                  {isLoading && (
                    <button
                      className="btn btn-primary crud-submit-btn"
                      disabled
                    >
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportCsvBeneficiaire;
