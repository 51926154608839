const TopAssuresTableData = [
    {
        id: 1,
        numero: '123',
        prenom: 'ABDOU',
        nom: 'FALL',
        montant: '245 520'
    },
    {
        id: 2,
        numero: '123',
        prenom: 'ABDOU',
        nom: 'FALL',
        montant: '245 520'
    },
    {
        id: 3,
        numero: '123',
        prenom: 'ABDOU',
        nom: 'FALL',
        montant: '245 520'
    },
    {
        id: 4,
        numero: '123',
        prenom: 'ABDOU',
        nom: 'FALL',
        montant: '245 520'
    },
];

export default TopAssuresTableData;
