import React, { useState, useEffect, useContext, useRef } from "react";
import * as IconlyPack from "react-iconly";
import SignaturePad from "react-signature-canvas";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import Signature from "../../../../images/others/signature.png";
import { toast } from "react-toastify";
import AssuranceMaladie from "../../../Services/AssuranceMaladie";
import baseUrl from "../../../../Http/backend-base-url";
import { useForm } from "react-hook-form";
import { DataContext } from "../../../store/GlobalState";
const ParametreVisa = () => {
  const { state, dispatch } = useContext(DataContext);
  const { prestataire, auth } = state;
  const [trimmedDataURL, setTrimmedDataURL] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  let sigPad = useRef({});

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  React.useEffect(() => {
    if (!auth?.user?.signature) {
      setOpen(true);
    } else {
      setTrimmedDataURL(baseUrl + auth?.user?.signature);
      setOpen(false);
    }
    register("signature");
  }, [auth?.user]);
  // console.log(prestataire?.url, trimmedDataURL)
  const clear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
    setOpen(!open);
  };
  const trim = (e) => {
    e.preventDefault();

    setTrimmedDataURL(sigPad?.current.toDataURL("image/png"));
    const blob = dataURLtoBlob(sigPad?.current.toDataURL("image/png"));
    // console.log(blob);
    const fd = new window.FormData();
    fd.append("signature", blob, "signature.png");

    handleSubmit(onSubmit(fd));
  };

  const onOpenSignature = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    var userRequest = AssuranceMaladie.editAssuranceMaladie(
      auth?.user?.id,
      data,
      requestConfig
    );

    await userRequest
      .then((response) => {
        var userInfos = response;
        console.log("user", userInfos);
        setIsLoading(false);
        dispatch({
          type: "AUTH",
          payload: { token: auth.token, user: userInfos },
        });
        setOpen(false);
        toast.success("Signature ajouté avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setOpen(true);
        toast.error(
          "Oupss! Une erreur est survenue lors de l'ajout de la signature."
        );
      });
  };
  return (
    <div className="add-family-form-container pt-5">
      <form className="crud-form">
        <div className="row crud-row" style={{ justifyContent: "center" }}>
          {trimmedDataURL ? (
            <div
              className="crud-col-item"
              style={{
                backgroundColor: "#fff",
                width: "77%",
                minHeight: "200px",
                marginBottom: "10px",
                textAlign: "center",
                border: "1px solid grey",
              }}
            >
              <img src={trimmedDataURL} style={{ marginTop: "10px" }} />
            </div>
          ) : null}
          {open && (
            <>
              <div
                className="crud-col-item"
                style={{
                  backgroundColor: "#fff",
                  width: "80%",
                  marginBottom: "10px",
                  textAlign: "center",
                }}
              >
                <SignaturePad
                  canvasProps={{
                    width: 700,
                    height: 250,
                    style: {
                      backgroundColor: "honeydew",
                      border: "1px solid grey",
                    },
                  }}
                  ref={sigPad}
                />
              </div>
              <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                <button
                  className="btn btn-default cancelleb-btn-assurance mr-2"
                  onClick={clear}
                  style={{ border: "1px solid red", borderRadius: "10px" }}
                >
                  Retour
                </button>
                {!isLoading ? (
                  <button
                    className="btn crud-submit-btn-assurance"
                    onClick={trim}
                  >
                    Enregistrer
                  </button>
                ) : (
                  <button className="btn crud-submit-btn-assurance">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </>
          )}

          {!open && (
            <div
              className="col-md-6 offset-md-11 crud-form-action-btn-container"
              style={{ marginLeft: "50%" }}
            >
              <button
                className="btn crud-submit-btn-assurance"
                onClick={onOpenSignature}
                style={{ marginLeft: "20%", width: "50%" }}
              >
                Modifier
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ParametreVisa;
