import React, { useEffect, useContext } from 'react'
import Header from '../../../Navs/Header'
import NavBarVerticalV2 from '../../navbarVerticalV2/navbarVerticalV2'
// import * as IconlyPack from 'react-iconly';
import { toast } from 'react-toastify'
import { DataContext } from '../../../store/GlobalState'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getDataByID, putData } from '../../../utils/fetchData'
import baseUrl from '../../../../Http/backend-base-url'
import Skeleton from 'react-loading-skeleton'

function DetailDevis() {
  const [userType, setUserType] = React.useState()
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext)

  const { auth, devisbyipm, devisbyprestataire } = state

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = React.useState(
    false,
  )
  var [isPageLoading, setIsPageLoading] = React.useState(true)
  const [devis, setDevis] = React.useState({})

  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  // console.log('props', location)
  useEffect(() => {
    setUserType(window.sessionStorage.getItem('userType'))

    if (params?.id && auth?.token) {
      //   getDataByID('prestataire', params?.id, auth?.token).then((res) => {
      //     console.log('res', res)
      //     setdevis(res)
      //     setIsPageLoading(false)
      //   })
      if (window.sessionStorage.getItem('userType') === 'prestataire') {
        setDevis(
          devisbyprestataire?.filter((f) => f?.id === parseInt(params?.id))[0],
        )
        setIsPageLoading(false)
        console.log(
          devisbyprestataire,
          devisbyprestataire?.filter((f) => f?.id === parseInt(params?.id)),
        )
        if (parseInt(window.sessionStorage.getItem('notifId'))) {
          handleValid()
        }
      }

      if (
        window.sessionStorage.getItem('userType') === 'ipm' ||
        window.sessionStorage.getItem('userType') === 'assurance_sante' ||
        window.sessionStorage.getItem('userType') === 'admin_ipm'
      ) {
        setDevis(devisbyipm?.filter((f) => f?.id === parseInt(params?.id))[0])
        setIsPageLoading(false)
        if (parseInt(window.sessionStorage.getItem('notifId'))) {
          handleValid()
        }
        console.log(devisbyipm?.filter((f) => f?.id === parseInt(params?.id)))
      }

      if (window.sessionStorage.getItem('userType') === 'entreprise') {
        let data = location?.state?.notification?.data
        getDataByID(
          `document`,
          location?.state?.notification?.data?.document,
          auth?.token,
        ).then((res) => {
          data['document'] = res
        })
        setDevis(data)
        setIsPageLoading(false)
      }
    }
  }, [params?.id, auth?.token, devisbyipm, devisbyprestataire])

  const handleValid = () => {
    putData(
      'notification/' +
        parseInt(window.sessionStorage.getItem('notifId')) +
        '/',
      { valid: true },
      auth?.token,
    ).then((res) => {
      console.log('res', res)
      if (userType !== 'prestataire') {
        if (auth?.user?.user_type === 'admin_ipm') {
          getDataByID(
            'notificationbyipm',
            auth?.user?.parent,
            auth?.token,
          ).then((resp) => {
            dispatch({
              type: 'LIST_NOTIFICATION',
              payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })
        } else {
          getDataByID('notificationbyipm', auth?.user?.id, auth?.token).then(
            (resp) => {
              // console.log('notif', resp)
              window.sessionStorage.removeItem('notifId')
              dispatch({
                type: 'LIST_NOTIFICATION',
                payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
              })
            },
          )
        }
        // history.push(`/ipm/profil-beneficiaire/${res.beneficiaire}`);
      } else {
        if (auth?.user?.admin_type) {
          getDataByID(
            'notificationbyentreprise',
            auth?.user?.parent,
            auth?.token,
          ).then((res) => {
            window.sessionStorage.removeItem('notifId')
            dispatch({
              type: 'LIST_NOTIFICATION',
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })
        } else {
          getDataByID(
            'notificationbyentreprise',
            auth?.user?.id,
            auth?.token,
          ).then((res) => {
            window.sessionStorage.removeItem('notifId')
            dispatch({
              type: 'LIST_NOTIFICATION',
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })
        }
        getDataByID(
          'notificationbyprestataire',
          auth?.user?.id,
          auth?.token,
        ).then((res) => {
          window.sessionStorage.removeItem('notifId')
          dispatch({
            type: 'LIST_NOTIFICATION',
            payload: res.sort((a, b) => a?.id - b?.id).reverse(),
          })
        })
        // history.push(`/entreprise/profil-employes/${res.beneficiaire}`);
      }
    })
  }
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Devis</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="add-family-form-container">
                    {isPageLoading && <Skeleton width={`100%`} height={500} />}
                    {!isPageLoading && (
                      <div
                        className="pdf-created-row"
                        style={{ margin: '0px', padding: '0px' }}
                      >
                        {/* <div style={{ marginBottom: '3em', marginTop: '3em' }}> */}
                        <div className="col-md-6 offset-md-3 pdf-created-link-container">
                          {/* <a style={style.download_pdf_} target="_blank" rel="noopener noreferrer" href={Url}> */}
                          <a
                            className="pdf-created-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            href={`${baseUrl}${devis?.devis}`}
                          >
                            Télécharger le devis
                          </a>
                        </div>
                        <div className="col-md-12 pdf-created-viewr-container">
                          {devis?.id && (
                            // eslint-disable-next-line jsx-a11y/iframe-has-title
                            <iframe
                              className="iframe_pdf"
                              frameBorder="0"
                              src={`${baseUrl}${devis?.document?.doc}`}
                              style={{ width: '100%', height: '700px' }}
                              height="700"
                              width="100%"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailDevis
