import React from "react";

import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import InfoUtilesTable from "../../../Tables/Beneficiaire/InfoUtilesTable/InfoUtilesTable";
import { useQuery } from "../../../../Http/http/query";
import _ from "lodash";
var InfoUtiles = () => {
  const query = useQuery("/article/");

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Infos utiles</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <InfoUtilesTable
                    data={query.data.reverse()}
                    loading={query.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoUtiles;
