import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { DataContext } from "../store/GlobalState";
import { toast } from "react-toastify";
import Prestataire from "../Services/Prestataire";
import { getDataByID } from "../utils/fetchData";

const AgreementValidModal = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useContext(DataContext);
  const { prestataire, auth } = state;
  const { register, handleSubmit, reset } = useForm();

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };
  const onSubmit = async (data, e) => {
    console.log("data", data);
    setIsLoading(true);

    var prestataireRequest = Prestataire.putPrestataire(
      prestataire.id,
      data,
      requestConfig
    );

    await prestataireRequest
      .then(async (response) => {
        if (auth?.user?.user_type === "admin_ipm") {
          getDataByID("prestatairebyassurance", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({
                type: "PRESTATAIRE_BY_IPM",
                payload: res.results.reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          getDataByID("prestatairebyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "PRESTATAIRE_BY_IPM",
                payload: res.results.reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
        setIsLoading(false);
        e.target.reset();
        window.$(".agreement-valid-modal").modal("hide");
        dispatch({ type: "EDIT_PRESTATAIRE", payload: {} });

        toast.success("agreement de ce prestataire modifié avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
      });
  };

  const onReset = () => {
    if (prestataire?.agreement !== undefined) {
      reset();
      dispatch({ type: "EDIT_PRESTATAIRE", payload: {} });
    }
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Gestion d'agréement
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {prestataire?.agreement !== undefined && (
              <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row crud-row">
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="agreement">Agréement</label>
                      <div className="filter-item-container">
                        <select
                          {...register("agreement")}
                          defaultValue={prestataire?.agreement}
                          name="agreement"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                        >
                          <option hidden value="">
                            Agréement
                          </option>
                          <option value="true">Oui</option>
                          <option value="false">Non</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                      onClick={() => onReset()}
                    >
                      Retour
                    </button>

                    {!isLoading && (
                      <button className="btn btn-primary crud-submit-btn">
                        Enregistrer
                      </button>
                    )}

                    {isLoading && (
                      <button
                        disabled
                        className="btn btn-primary crud-submit-btn"
                      >
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgreementValidModal;
