const GestionFacturationAdminTableData = [
    {
        id: 1,
        raisonsocial: 'VOLKENO SARL',
        ninea:'005836880 2v2',
        ncontrat: '3443245',
        adresse: 'Dakar plateau',
        telephone: '33 000 0000',
        ninea: '005836880 2v2',
    },
    
    
];

export default GestionFacturationAdminTableData;
