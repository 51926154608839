import React, { useState, useContext, useEffect } from "react";
import Select from "react-select";
import { DataContext } from "../store/GlobalState";
import { useForm } from "react-hook-form";
import { getData, getDataByID } from "../utils/fetchData";
import Exclusion from "../Services/Exclusion";
import { toast } from "react-toastify";

const EditExclusionModal = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useContext(DataContext);
  const { auth, ipms, list_famille_soins, exclusion } = state;
  const [types, setTypes] = React.useState([]);
  const [clesFamily, setClesFamily] = React.useState([
    { value: "", label: "" },
  ]);
  const [clesType, setClesType] = React.useState([{ value: "", label: "" }]);
  const [cles, setCles] = React.useState([{ value: "", label: "" }]);
  const [val, setVal] = React.useState("");
  const [valFamily, setValFamily] = React.useState("");
  const [valType, setValType] = React.useState("");
  const { register, handleSubmit, reset, formState, setValue, unregister } =
    useForm();
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  React.useEffect(() => {
    if (auth?.token) {
      getData("typedesoin/", auth.token).then((response) => {
        setTypes(response.results.sort((a, b) => b?.id - a?.id));
      });
    }
    if (
      ipms?.length &&
      auth?.user?.user_type !== "ipm" &&
      auth?.user?.user_type !== "assurance_dante"
    ) {
      setCles(
        ipms.map((b) => {
          return {
            label: b.raison_sociale,
            value: b.id,
          };
        })
      );
    }

    if (list_famille_soins?.length) {
      setClesFamily(
        list_famille_soins.map((b) => {
          return {
            label: b.nom,
            value: b.id,
          };
        })
      );
    }
  }, [auth, ipms, list_famille_soins]);

  useEffect(() => {
    if (exclusion?.id) {
      register("designation");
      setValue("designation", exclusion?.designation);
      register("ipm");
      setValue("ipm", exclusion?.ipm?.id);
      setVal({
        label: exclusion?.ipm?.raison_sociale,
        value: exclusion?.ipm?.id,
      });
      register("typedesoin");
      setValue("typedesoin", exclusion?.typedesoin?.id);
      setValType({
        label: exclusion?.typedesoin?.nom,
        value: exclusion?.typedesoin?.id,
      });
      register("familledesoin");
      setValue("familledesoin", exclusion?.familledesoin?.id);
      setValFamily({
        label: exclusion?.familledesoin?.nom,
        value: exclusion?.familledesoin?.id,
      });

      if (
        types?.length &&
        types.filter(
          (t) => t?.familledesoin?.id === exclusion?.familledesoin?.id
        )?.length
      ) {
        setClesType(
          types
            .filter((t) => t?.familledesoin?.id === exclusion?.familledesoin.id)
            .map((b) => {
              return {
                label: b.nom,
                value: b.id,
              };
            })
        );
      }
    }
  }, [exclusion]);

  const onSelect = (e) => {
    console.log(e.value);
    setVal(e);
    register("ipm");
    setValue("ipm", e.value);
  };

  const onSelectType = (e) => {
    console.log(e.value);
    setValType(e);
    register("typedesoin");
    setValue("typedesoin", e.value);
  };

  const onSelectFamily = (e) => {
    console.log(e.value, types);
    setValFamily(e);
    if (
      types?.length &&
      types.filter((t) => t?.familledesoin?.id === e.value)?.length
    ) {
      setClesType(
        types
          .filter((t) => t?.familledesoin?.id === e.value)
          .map((b) => {
            return {
              label: b.nom,
              value: b.id,
            };
          })
      );
    }
    register("familledesoin");
    setValue("familledesoin", e.value);
  };

  const onSubmit = async (data) => {
    // console.log('data', data)
    setIsLoading(true);

    var exclusionRequest = Exclusion.putExclusion(
      exclusion.id,
      data,
      requestConfig
    );

    await exclusionRequest
      .then((response) => {
        if (
          auth.user?.user_type === "ipm" ||
          auth?.user?.user_type === "assurance_sante"
        ) {
          getDataByID("exclusionbyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_EXCLUSION",
                payload: res.results.sort((a, b) => b?.id - a?.id),
              });
            }
          );
        } else {
          getData("exclusion/", auth?.token).then((res) => {
            dispatch({
              type: "LIST_EXCLUSION",
              payload: res.results.sort((a, b) => b?.id - a?.id),
            });
          });
        }
        setIsLoading(false);

        window.$(".edit-exclusion-modal").modal("hide");
        dispatch({ type: "EDIT_EXCLUSION", payload: {} });
        toast.success("Exclusion modifiée avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
      });
  };

  const handleReset = () => {
    dispatch({ type: "EDIT_EXCLUSION", payload: {} });
    reset();
    setVal("");
    setValFamily("");
    setValType("");
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Modifier une exclusion
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => handleReset()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="libelle">Désignation</label>
                    <input
                      type="text"
                      placeholder="Désignation"
                      name="designation"
                      className="form-control crud-form-control"
                      id="designation"
                      {...register("designation")}
                    />
                    {formState.errors && formState.errors.designation && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.designation?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Famille de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner la famille de soins"
                        name="familledesoin"
                        value={valFamily}
                        onChange={(e) => onSelectFamily(e)}
                        options={clesFamily}
                      />
                      {formState.errors && formState.errors.familledesoin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.familledesoin?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Type de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner le type de soins"
                        name="typedesoin"
                        value={valType}
                        onChange={(e) => onSelectType(e)}
                        options={clesType}
                      />
                      {formState.errors && formState.errors.typedesoin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.typedesoin?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {auth.user?.user_type !== "ipm" &&
                auth?.user?.user_type !== "assurance_sante" &&
                auth?.user?.user_type !== "admin_ipm" ? (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="cle">Ipm</label>
                      <div className="filter-item-container">
                        <Select
                          placeholder="Selectionner l'ipm"
                          name="ipm"
                          value={val}
                          onChange={(e) => onSelect(e)}
                          options={cles}
                        />
                        {formState.errors && formState.errors.ipm && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.ipm?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditExclusionModal;
