import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../store/GlobalState";
import jsPDF from "jspdf";
import html2canvas from "jspdf-html2canvas";
import $ from "jquery";
import IMGEPM from "../../images/others/easypm.png";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import moment from "moment";
import { formatMoney, formatMontant } from "../../utils/utils";
import html2PDF from "jspdf-html2canvas";
import baseUrl from "../../Http/backend-base-url";

// html2PDF(node, options);

const FacturePdfModal = () => {
  const { state, dispatch } = useContext(DataContext);

  const { facture } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (facture?.id && facture.prestations?.length) {
      let total_mont = 0;
      facture.prestations.forEach((element) => {
        total_mont += element.quantite * parseInt(element.montant);
      });

      setTotal(total_mont);
    } else {
      setTotal(0);
    }
  }, [facture]);

  const generatePDF = () => {
    setIsLoading(true);

    const input = document.getElementById("contentFacture");
    html2PDF(input, {
      jsPDF: {
        unit: "px",
        format: "a4",
      },
      html2canvas: {
        imageTimeout: 15000,
        logging: true,
        useCORS: false,
        allowTaint: true,
      },
      imageType: "image/png",
      imageQuality: 1,
      margin: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5,
      },
      output: `facture-prestataire-${moment(new Date()).format(
        "DD-MM-YYYY"
      )}.pdf`,
      init: function () {},
      success: function (pdf) {
        pdf.save(this.output);
        window.$("#facturePdfModal").modal("hide");
        window.$("#FacturePdfModal").modal("hide");
        setIsLoading(false);
      },
    });
  };

  const handleReset = () => {
    setIsLoading(false);
    dispatch({ type: "EDIT_FACTURE", payload: {} });
  };
  return (
    <div class="modal-dialog modal-xl" id="modal-facture">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="facturePdfModalLabel">
            Facture pdf
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body px-4">
          <div className="row d-flex justify-content-center pb-4">
            <div className="col-md-6">
              {!isLoading && (
                <button
                  className="btn pdf-created-link"
                  //  id="generatePDF"
                  onClick={() => generatePDF()}
                  type="button"
                >
                  Télécharger la facture
                </button>
              )}
              {isLoading && (
                <button
                  className="btn pdf-created-link"
                  //  id="generatePDF"
                  //   onClick={() => generatePDF()}
                  type="button"
                >
                  Téléchargement en cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
          <div id="contentFacture">
            <div className="row d-flex align-items-center">
              <div className="col-md-6">
                <div className>
                  <img src={IMGEPM} className="img-facture" />
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <div className="blocright d-flex">
                  <div className="bgblue">
                    <p className="textfacturebloc">Numéro de facture</p>
                    <p className="textlibellebloc">{facture?.id}</p>
                  </div>
                  <div className="bggreen">
                    <p className="textfacturebloc">Référence client</p>
                    <p className="textlibellebloc">
                      {facture?.entreprise
                        ? facture?.entreprise?.id
                        : facture?.ipm?.id}
                    </p>
                  </div>
                  <div className="bgblue">
                    <p className="textfacturebloc">Facture du</p>
                    <p className="textlibellebloc">
                      {moment(facture?.created_at).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row py-3">
              {/* {facture?.prestataire && facture?.prestataire?.logo && (
                <div className="col-md-12">
                  <img src={baseUrl + facture?.prestataire?.logo} width="100" />
                </div>
              )} */}
              <div className="col-md-6">
                <p className="p-text text-dark">
                  {facture?.prestataire
                    ? facture?.prestataire?.prenom
                      ? facture?.prestataire?.prenom +
                        " " +
                        facture?.prestataire?.nom
                      : facture?.prestataire?.raison_sociale
                    : facture?.ipm?.raison_sociale}
                </p>
                <p className="p-text">
                  {" "}
                  {facture?.prestataire
                    ? facture?.prestataire?.adresse
                    : facture?.ipm?.adresse}
                </p>
                <p className="p-text">
                  {facture?.prestataire
                    ? facture?.prestataire?.telephone
                    : facture?.ipm?.telephone}
                </p>
                <p className="p-text">
                  {facture?.prestataire
                    ? facture?.prestataire?.email
                    : facture?.ipm?.email}
                </p>
              </div>

              <div className="col-md-6 d-flex justify-content-center">
                <ul className="">
                  <li className="list">
                    Nom du client :{" "}
                    <span className="spanli">
                      {facture?.entreprise
                        ? facture?.entreprise?.raison_sociale
                        : facture?.ipm?.raison_sociale}
                    </span>
                  </li>
                  <li className="list">
                    Adresse du client :{" "}
                    <span className="spanli">
                      {facture?.entreprise
                        ? facture?.entreprise?.adresse
                        : facture?.ipm?.adresse}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bloctrois">
              <div className="textbloctrois py-2 pl-2">
                Règlements des sommes dues:{" "}
                {moment(facture?.date_debut).format("DD/MM/YYYY")} au{" "}
                {moment(facture?.date_fin).format("DD/MM/YYYY")}1
              </div>
            </div>
            {/* <div className="py-3">
              <p className="text"> Mesdames, Messieurs</p>
              <p className="text">
                Veuillez trouver ci-dessous le montant des sommes à régler
              </p>
            </div> */}

            <div>
              {/* <div className="bloctrois mt-3">
                <div className="textbloctrois py-2 pl-2">
                  Etat Récapitulatif des Prestations
                </div>
              </div> */}
              <div className="pt-3">
                <table class="table" id="page-2">
                  <thead>
                    <tr>
                      {/* <th scope="col" className="text-center">
                        Entreprise
                      </th> */}
                      {/* <th scope="col" className="text-center">
                        Prénom et Nom bénéf
                      </th>
                      <th scope="col" className="text-center">
                        N°.Adhérent
                      </th> */}
                      <th scope="col">Date</th>
                      <th scope="col">Désignation</th>
                      <th scope="col" className="text-right">
                        P.Unitaire (F CFA)
                      </th>
                      <th scope="col" className="text-right">
                        Qté
                      </th>
                      <th scope="col" className="text-right">
                        Montant (F CFA)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {facture?.prestations?.length ? (
                      facture?.prestations
                        .sort((a, b) => new Date(a?.id) - new Date(b?.id))
                        .map((prestation) => (
                          <tr key={prestation?.id}>
                            <td className="fact-cls">
                              {moment(prestation?.date).format("DD/MM/YYYY")}
                            </td>
                            <td className="fact-cls">
                              <span className="name">
                                {prestation?.designation}
                              </span>
                              <br />
                              <span>
                                Famille de soins&nbsp; (
                                {prestation?.familledesoin?.nom})
                                &nbsp;/&nbsp;Type de soins&nbsp; (
                                {prestation?.typedesoin?.nom})
                              </span>

                              <br />
                              <span>
                                Adhérent &nbsp; (
                                {prestation?.beneficiaire?.prenom +
                                  " " +
                                  prestation?.beneficiaire?.nom}
                                ) &nbsp;/&nbsp; Bénéficiaire&nbsp; (
                                {prestation?.ayant_droit
                                  ? prestation?.ayant_droit?.prenom +
                                    " " +
                                    prestation?.ayant_droit?.nom
                                  : prestation?.beneficiaire?.prenom +
                                    " " +
                                    prestation?.beneficiaire?.nom}
                                )
                              </span>
                              <br />
                              <span>
                                Entreprise&nbsp; (
                                {
                                  prestation?.beneficiaire?.employeur
                                    ?.raison_sociale
                                }
                                )
                              </span>
                            </td>
                            <td className="fact-cls text-right">
                              {formatMontant(prestation?.montant)}
                            </td>
                            <td className="fact-cls text-right">
                              {prestation?.quantite}{" "}
                            </td>
                            <td className="fact-cls text-right">
                              {formatMontant(
                                parseInt(prestation?.montant) *
                                  parseInt(prestation?.quantite)
                              )}{" "}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr
                        style={{
                          textAlign: "center",
                          backgroundColor: "transparent",
                          fontSize: "20px",
                        }}
                      >
                        <td className="totalText col-td" colSpan="6">
                          <span>Aucune prestation disponible</span>
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                      <td colSpan="5">Montant Total des Actes</td>
                      <td>{formatMoney(facture?.total)}</td>
                    </tr> */}
                  </tbody>
                </table>
                <div className="py-3">
                  <ul class="list-group">
                    <li class="list-group-item listgray d-flex justify-content-between align-items-center">
                      Règlement effectué
                      <span className="spanlist">{formatMoney(0)}</span>
                    </li>
                    <li class="list-group-item listgray d-flex justify-content-between align-items-center">
                      Restant dû
                      <span className="spanlist">
                        {formatMoney(facture?.total)}
                      </span>
                    </li>
                    <li class="list-group-item listred d-flex justify-content-between align-items-center">
                      Total Facture
                      <span className="listtotal">
                        {formatMoney(facture?.total)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default FacturePdfModal;
