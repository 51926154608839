import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import assuranceMaladie from "../../../../Services/AssuranceMaladie";
import { DataContext } from "../../../../store/GlobalState";
import { toast } from "react-toastify";
import { updateItem } from "../../../../store/Actions";
import { getDataByID } from "../../../../utils/fetchData";

var UseEditAssuranceAdminForm = () => {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const { state, dispatch } = useContext(DataContext);

  const { auth, admin_ipms, admin_ipm } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email("L'adresse email n'est pas valide"),
        nom: yup.string(),
        prenom: yup.string(),
        telephone: yup
          .string()
          .matches(
            /^((\+)221|0)[1-9](\d{2}){4}$/,
            "Veuillez vérifier le format du numéro de téléphone"
          ),
        adresse: yup.string(),
      }),
    []
  );

  const { register, handleSubmit, formState, clearErrors, setValue, reset } =
    useForm({
      resolver: yupResolver(validationSchema),
    });

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    if (!formValues?.email) {
      formValues.email = admin_ipm?.email;
    }

    if (!formValues?.nom) {
      formValues.nom = admin_ipm?.nom;
    }

    if (!formValues?.prenom) {
      formValues.prenom = admin_ipm?.prenom;
    }

    if (!formValues?.adresse) {
      formValues.adresse = admin_ipm?.adresse;
    }

    if (!formValues?.telephone) {
      formValues.telephone = admin_ipm?.telephone;
    }

    if (!formValues?.id) {
      formValues.id = admin_ipm?.id;
    }
    if (!formValues?.parent) {
      formValues.parent = admin_ipm?.parent;
    }

    if (!formValues?.parent) {
      formValues.parent = admin_ipm?.parent;
    }

    if (!formValues?.admin_type) {
      formValues.admin_type = admin_ipm?.admin_type;
    }

    if (!formValues?.user_type) {
      formValues.user_type = admin_ipm?.user_type;
    }
    var adminRequest = assuranceMaladie.editAdminAssuranceMaladie(
      admin_ipm?.id,
      formValues,
      requestConfig
    );
    await adminRequest
      .then(async (response) => {
        var adminInfos = response;
        console.log("admin", adminInfos);
        //dispatch({ type: 'LIST_ADMIN_IPM', payload: [...admin_ipms, adminInfos]})
        await dispatch(
          updateItem(admin_ipms, admin_ipm.id, adminInfos, "ADD_ADMIN_IPM")
        );
        await dispatch({ type: "EDIT_ADMIN_IPM", payload: {} });
        if (
          auth?.user?.user_type === "ipm" ||
          auth?.user?.user_type === "assurance_sante"
        ) {
          getDataByID("admin_by_ipm", auth?.user?.id, auth.token)
            .then((res) => {
              //console.log('res', res)
              dispatch({
                type: "LIST_ADMIN_IPM",
                payload: res.sort((a, b) => b.id - a.id),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }

        if (auth?.user?.user_type === "admin_ipm") {
          getDataByID("admin_by_ipm", auth?.user?.parent, auth.token)
            .then((res) => {
              //console.log('res', res)
              dispatch({
                type: "LIST_ADMIN_IPM",
                payload: res.sort((a, b) => b.id - a.id),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }

        if (auth?.user?.user_type === "entreprise") {
          getDataByID("admin_by_entreprise", auth?.user?.id, auth.token)
            .then((res) => {
              //console.log('res', res)
              dispatch({
                type: "LIST_ADMIN_IPM",
                payload: res.sort((a, b) => b.id - a.id),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
        e.target.reset();
        toast.success("Admin modifié avec succès.");
        console.log(isSuccessfullySubmitted);
      })
      .catch((error) => {
        console.log(error);
        setIsFormRequestError(true);
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          if (
            error?.response?.data?.email &&
            error?.response?.data?.email[0] ===
              "user with this email already exists."
          ) {
            toast.error(
              "Oupsss! L'adresse email est déjà associée à un compte. Veuillez utiliser une autre adresse email.",
              {
                position: "top-right",
                autoClose: 10000,
              }
            );
          } else if (error?.response?.status === 500) {
            toast.error(
              "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
            );
          } else if (
            error?.response?.status === 400 &&
            !error?.response?.data?.email
          ) {
            toast.error(
              "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
            );
          }
        }
      });
  };

  return {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitCreateForm),
    setValue,
    reset,
  };
};

export default UseEditAssuranceAdminForm;
