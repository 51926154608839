import React, { useState, useEffect, useContext } from "react";
import QrReader from "react-qr-reader";
import { Redirect } from "react-router";
import { DataContext } from "../store/GlobalState";
import { getData, getDataByID } from "../utils/fetchData";
import { toast } from "react-toastify";

const QrCodeModal = () => {
  const { state, dispatch } = useContext(DataContext);
  const [valid, setValid] = useState(true);
  const [empty, setEmpty] = useState(false);
  const { auth, prestataire, fichevalidbyprestataire } = state;
  const [beneficiaires, setbeneficiaires] = useState([]);
  const [result, setResult] = useState();

  const handleScan = (data) => {
    if (data) {
      console.log(data);
      if (typeof data !== "object" && typeof data !== "number") {
        // console.log(typeof JSON.parse(data),typeof data)
        try {
          getData(`carnetdecodeqr/?code=${data}`, auth?.token)
            .then(async (res) => {
              if (
                res?.id &&
                beneficiaires.filter((b) => b.id === res.id)?.length
              ) {
                // console.log();
                setResult(res.id);
                // if (
                //   fichevalidbyprestataire.filter(
                //     (f) => f?.beneficiaire?.id === res.id
                //   )?.length
                // ) {
                //   setResult(res.id);
                // } else {
                //   setValid(false);
                //   return false;
                // }
              } else {
                setEmpty(true);
                return false;
              }
            })
            .catch((e) => {
              console.log("error", e);
              setEmpty(true);
              return false;
            });
        } catch (error) {
          console.log("error", error);
        }
      } else {
        toast.error("Votre qrcode n'est pas valide.");
        window.$(".qr-code-modal").modal("hide");
        dispatch({
          type: "AUTH",
          payload: {
            token: auth?.token,
            user: auth?.user,
          },
        });
      }
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  useEffect(() => {
    if (auth?.user) {
      if (prestataire?.assurance_maladie?.length) {
        prestataire.assurance_maladie.forEach(async (element) => {
          // console.log(element);
          await getDataByID(
            "beneficiairebyassurance",
            element,
            auth?.token
          ).then((res) => {
            let datas = [...beneficiaires, ...res];
            // console.log(datas)
            setbeneficiaires(datas);
          });
        });
      }
    }
    // eslint-disable-next-line
  }, [auth?.user, prestataire?.assurance_maladie?.length]);

  useEffect(() => {
    if (result) {
      window.$(".qr-code-modal").modal("hide");
      dispatch({
        type: "AUTH",
        payload: {
          token: auth?.token,
          user: auth?.user,
        },
      });
    }

    if (!valid) {
      window.$(".qr-code-modal").modal("hide");
      dispatch({
        type: "AUTH",
        payload: {
          token: auth?.token,
          user: auth?.user,
        },
      });
      toast.error("Ce beneficiaire n'a pas encore de fiche de soins valide.");
      setValid(true);
    }

    if (empty) {
      window.$(".qr-code-modal").modal("hide");
      dispatch({
        type: "AUTH",
        payload: {
          token: auth?.token,
          user: auth?.user,
        },
      });
      toast.error(
        "Ce beneficiaire n'est dans aucun de nos ipms veiller vous rapprocher de votre employeur."
      );
      setEmpty(false);
    }
  }, [result, valid, empty]);

  if (!result) {
    return (
      <div className="modal-dialog easypm-modal-dialog modal-lg">
        <div className="modal-content easypm-modal-content">
          <div className="modal-header easypm-modal-header">
            <h5
              className="modal-title easypm-modal-title"
              id="SendMessageModal"
            >
              Scan Qrcode
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() =>
                dispatch({
                  type: "AUTH",
                  payload: {
                    token: auth?.token,
                    user: auth?.user,
                  },
                })
              }
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body easypm-modal-body">
            <div className="add-family-form-container">
              <div className="crud-form">
                <div className="row crud-row" style={{ paddingBottom: "1rem" }}>
                  <div className="col-md-12 crud-col-item">
                    {!result && (
                      <QrReader
                        delay={500}
                        onError={handleError}
                        onScan={handleScan}
                        style={{
                          width: "50%",
                          marginLeft: "12rem",
                          marginRight: "5rem",
                        }}
                      />
                    )}
                  </div>

                  <div
                    className="col-md-6 offset-md-6 crud-form-action-btn-container"
                    style={{ justifyContent: "flex-end", marginTop: "1rem" }}
                  >
                    <button
                      className="btn btn-danger cancelleb-btn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() =>
                        dispatch({
                          type: "AUTH",
                          payload: {
                            token: auth?.token,
                            user: auth?.user,
                          },
                        })
                      }
                    >
                      Retour
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to={`/prestataire/profil-beneficiaire/${result}`} />;
  }
};

export default QrCodeModal;
