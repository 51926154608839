import React, { useEffect, useContext } from 'react'
import {
    BootstrapTable,
    TableHeaderColumn,
    ClearSearchButton,
} from 'react-bootstrap-table'
import GestionEntrepriseAdminTableSkeleton from './GestionEntrepriseAdminTableSkeleton'
import * as IconlyPack from 'react-iconly'
import { NavLink } from 'react-router-dom'
import GestionEntrepriseAdminTableData from './GestionEntrepriseAdminTableData'
import RenvoiPasswordEntrepriseAdmin from './RenvoiPasswordEntrepriseAdmin'
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'

var GestionEntrepriseAdminTable = (props) => {


    const [soins, setSoins] = React.useState(GestionEntrepriseAdminTableData)

    var [isPageLoading, setIsPageLoading] = React.useState(true)

    useEffect(() => {
        setTimeout(() => {
            setIsPageLoading(false)
        }, 3000)
    }, [soins])

    var handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event')
        onClick()
    }

    var createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText="Effacer"
                btnContextual="btn-warning"
                className="my-custom-class"
                onClick={(e) => handleClearButtonClick(onClick)}
            />
        )
    }

    const options = {
        clearSearch: true,
        clearSearchBtn: createCustomClearButton,
        noDataText: 'Aucune donnée disponible',
    }
    const actionFormatter = (cell, row) => {
        return (
            <div className="table-actions-btn-container-assurance">
                <button className="action-btn edit-btn" title="Traitement">
                    <IconlyPack.Show
                        set="light"
                        primaryColor="#C8C8C8"
                        className="action-btn-icon action-edit-icon"
                        data-toggle="modal"
                        data-target=".show-fiche-modal"
                    />
                </button>
                <button className="action-btn edit-btn" title="Details">
                    <IconlyPack.Edit
                        set="light"
                        primaryColor="#C8C8C8"
                        className="action-btn-icon action-edit-icon"
                        data-toggle="modal"
                        data-target=".show-soin-modal"
                    />
                </button>
                <button className="action-btn edit-btn" title="Details">
                    <IconlyPack.Message
                        set="light"
                        primaryColor="#C8C8C8"
                        className="action-btn-icon action-edit-icon"
                        data-toggle="modal"
                        data-target=".show-soin-modal"
                    />
                </button>
                <button className="action-btn edit-btn" title="Details">
                    <IconlyPack.Password
                        set="light"
                        primaryColor="#C8C8C8"
                        className="action-btn-icon action-edit-icon"
                        data-toggle="modal"
                        data-target="#RenvoiPasswordEntrepriseAdmin"
                    />
                </button>
            </div>
        );
    };



    return (
        <div className="child-table-container">
            <div className="table-body-container">
                <div className="row easypm-table-row m-t-50 m-b-50">
                    <div className="col-md-12 easypm-table-col">
                        {isPageLoading && <GestionEntrepriseAdminTableSkeleton />}
                        {!isPageLoading && (
                            <div className="table-container">
                                <BootstrapTable
                                    data={soins}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    pagination
                                    search={false}
                                    version="4"
                                    bordered={false}
                                >
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        dataField="id"
                                        isKey={true}
                                        hidden={true}
                                    >
                                        ID
                  </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="150"
                                        dataField="raisonsocial"
                                    >
                                        Raison Sociale
                  </TableHeaderColumn>

                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="150"
                                        dataField="ninea"
                                    >
                                        NINEA
                  </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="140"
                                        dataField="email"
                                    >
                                        Email
                  </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="140"
                                        dataField="telephone"
                                    >
                                        Téléphone
                  </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="140"
                                        dataField="tierspayantintegral"
                                    >
                                        Tiers Payant Intégral
                  </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: '#1F497D',
                                        }}
                                        tdStyle={{
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: 'rgba(0, 0, 0, 0.7)',
                                        }}
                                        width="150"
                                        dataField="id"
                                        dataFormat={(cell, row) => actionFormatter(cell, row)}
                                    >
                                        Action
                  </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        )}
                    </div>
                    <div
                        className="modal fade easypm-modal add-soin-modal"
                        id="RenvoiPasswordEntrepriseAdmin"
                        tabIndex="-1"
                        aria-labelledby="RenvoiPasswordEntrepriseAdmin"
                        data-backdrop="static"
                        aria-hidden="true"
                    >
                        <RenvoiPasswordEntrepriseAdmin />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GestionEntrepriseAdminTable
