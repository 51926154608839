import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
// import EnfantsSkeleton from "./EnfantsSkeleton";
// import { DataContext } from "../../store/GlobalState";
import moment from "moment";
import { DataContext } from "../../../store/GlobalState";
import BeneficiairesSkeleton from "../../../Tables/Beneficiaires/BeneficiairesSkeleton";
import { formatMoney, formatMontant } from "../../../../utils/utils";

const TopBeneficiaire = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [userType, setUserType] = React.useState(
    sessionStorage.getItem("userType")
  );
  var [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    if (auth?.token && auth?.user?.id && props?.beneficiaires) {
      // setTimeout(() => {
      setIsPageLoading(false);
      // }, 5000);
    } else {
      setIsPageLoading(true);
    }
  }, [auth, props]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    if (
      userType === "ipm" ||
      userType === "assurance_sante" ||
      userType === "admin_ipm"
    ) {
      return (
        <NavLink
          to={`/ipm/profil-beneficiaire/${row?.item?.id}`}
          className="nameFormatter"
          onClick={() => onGetBenefInfos(row, "show")}
        >
          <span className="name"> {row?.item?.prenom} </span> <br />
          {!props.isEntrepriseList && (
            <span className="entreprise">
              {" "}
              {row?.item?.employeur.raison_sociale}{" "}
            </span>
          )}
        </NavLink>
      );
    }

    if (userType === "entreprise") {
      return (
        <NavLink
          to={`/entreprise/profil-employes/${row?.item?.id}`}
          onClick={() => onGetBenefInfos(row?.item, "show")}
          className="nameFormatter"
        >
          <span className="name"> {row?.item?.prenom} </span>
        </NavLink>
      );
    }
  };

  const nomFormater = (cell, row) => {
    if (
      userType === "ipm" ||
      userType === "assurance_sante" ||
      userType === "admin_ipm"
    ) {
      return (
        <NavLink
          to={`/ipm/profil-beneficiaire/${row?.item?.id}`}
          className="nameFormatter"
          onClick={() => onGetBenefInfos(row?.item, "show")}
        >
          <span className="name"> {row?.item?.nom} </span> <br />
        </NavLink>
      );
    }

    if (userType === "prestataire") {
      return (
        <NavLink
          // to={`/prestataire/profil-beneficiaire/${row.id}`}
          to="#"
          className="nameFormatter"
          onClick={() => onGetBenefInfos(row?.item, "show")}
        >
          <span className="name"> {row?.item?.nom} </span> <br />
        </NavLink>
      );
    }

    if (userType === "entreprise") {
      return (
        <NavLink
          to={`/entreprise/profil-employes/${row?.item.id}`}
          onClick={() => onGetBenefInfos(row?.item, "show")}
          className="nameFormatter"
        >
          <span className="name"> {row?.item?.nom} </span>
        </NavLink>
      );
    }
  };

  const onGetBenefInfos = async (item, action) => {
    await window.sessionStorage.removeItem("benefInfos");
    await window.sessionStorage.setItem("benefInfos", JSON.stringify(item));
    // setUserActionType(action)
    // console.log('item', item)
    await dispatch({ type: "SHOW_BENEFICIAIRE", payload: item });
  };

  const onGetItem = (item) => {
    dispatch({
      type: "SHOW_BENEFICIAIRE",
      payload: item,
    });
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <BeneficiairesSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={props?.beneficiaires}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="110"
                    dataField="id"
                    dataFormat={(cell, row) => row?.item?.id}
                    isKey={true}
                    hidden={false}
                    dataAlign="right"
                  >
                    Participant
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // dataAlign="center"
                    // width="150"
                    dataField="prenom"
                    // filter={{ type: 'TextFilter' }}
                    // filterValue={nameFilter}
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Prénom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="120"
                    dataField="nom"
                    // filter={{ type: 'TextFilter' }}
                    // filterValue={nameFilter}
                    dataFormat={(cell, row) => nomFormater(cell, row)}
                  >
                    Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="right"
                    dataField="montant"
                    dataFormat={(cell, row) => formatMontant(cell)}
                  >
                    Montant (F CFA)
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBeneficiaire;
