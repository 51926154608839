import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import GestionSoinAssureurModal from "../../../Backend/Assurance/GestionSoinsAssureur/GestionSoinAssureur";
import GestionRemboursementTableSkeleton from "./GestionRemboursementTableSkeleton";
import GestionFicheDeSoin from "./GestionFicheDeSoin";
import { DataContext } from "../../../store/GlobalState";
import moment from "moment";
import { formatMoney } from "../../../../utils/utils";
import ModifierRemboursement from "./ModifierRemboursement";
import DetailsRemboursement from "./DetailsRemboursement";
import ActionRemboursement from "./ActionRemboursement";

var GestionRemboursementTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const { assureur_remboursements } = state;

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const beneficaireFormatter = (cell, row) => {
    // let =cell.nom
    return (
      <NavLink
        to={{
          pathname: `/assurance/details-assures/${row?.prestation?.beneficiaire?.id}`,
          state: { assure: row?.prestation?.beneficiaire },
        }}
        className="nameFormatter "
      >
        <span className="name">
          {row?.prestation?.beneficiaire?.prenom || ""}
          {row?.prestation?.beneficiaire?.nom || ""}
        </span>{" "}
        <br />
        <strong className="entreprise">
          {" "}
          {row?.prestation?.beneficiaire?.employeur.raison_sociale}{" "}
        </strong>{" "}
        <br />
        <span className="entreprise">
          {" "}
          {row?.prestation?.beneficiaire?.employeur.num_contrat}{" "}
        </span>
      </NavLink>
    );
  };

  const montantFormatter = (cell, row) => {
    // return xofNumberFormatter.format(row?.prestation?.montant_rembourse);
    return formatMoney(row?.prestation?.montant_rembourse);
  };

  const currencyFormatter = (cell, row) => {
    // const num = 1234567890.1234;
    return formatMoney(row?.prestation?.montant);
  };

  const nameFilter = (cell, row) => {
    return (
      row?.prestation?.beneficiaire?.prenom +
      " " +
      row?.prestation?.beneficiaire?.nom +
      " " +
      row?.prestation?.beneficiaire?.employeur?.raison_sociale
    );
  };

  const typeFormatter = (cell, row) => {
    return row?.prestation?.typedesoin?.nom;
  };

  const etatFormatter = (cell, row) => {
    if (cell === "rejete") {
      return (
        <span className="badge badge-danger easypm-badge-danger ">Rejeté</span>
      );
    }

    if (cell === "archive") {
      return (
        <span className="badge badge-primary easypm-badge-primary ">
          Archivé
        </span>
      );
    }

    if (cell === "envoye") {
      return (
        <span className="badge badge-info easypm-badge-info ">Envoyé</span>
      );
    }

    if (cell === "en_attente") {
      return (
        <span className="badge badge-warning easypm-badge-warning ">
          En attente
        </span>
      );
    }

    if (cell === "valide") {
      return (
        <span className="badge badge-default easypm-badge-default ">
          Validé
        </span>
      );
    }

    if (cell === "regle") {
      return (
        <span className="badge badge-success easypm-badge-success ">Reglé</span>
      );
    }
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn edit-btn" title="Traitement">
          <IconlyPack.Paper
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#ActionRemboursement"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#DetailsRemboursement"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn edit-btn" title="Modification">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#ModifierRemboursement"
            onClick={() => getItem(row)}
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    // console.log("item==>", item);
    dispatch({
      type: "EDIT_PRESTATION",
      payload: item,
    });
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {assureur_remboursements?.isLoading && (
              <GestionRemboursementTableSkeleton />
            )}
            {!assureur_remboursements?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={assureur_remboursements?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="250"
                    dataField="beneficiaire"
                    filter={{
                      type: "TextFilter",
                      placeholder: "Chercher un Adhérent ou une entreprise",
                    }}
                    // dataAlign="center"
                    filterValue={nameFilter}
                    dataFormat={(cell, row) => beneficaireFormatter(cell, row)}
                  >
                    Assurés
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="center"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataField="date"
                    dataFormat={(cell) => {
                      return moment(cell).format("DD/MM/YYYY");
                    }}
                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    // dataAlign="center"
                    // width="300"
                    dataField="type_soins"
                    dataFormat={(row, cell) => typeFormatter(row, cell)}
                  >
                    Type de soins
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataAlign="right"
                    // width="300"
                    dataField="montant_rembourse"
                    dataFormat={(cell, row) => montantFormatter(cell, row)}
                  >
                    Part Assurance
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    dataAlign="right"
                    // width="300"
                    dataField="montant"
                    dataFormat={(cell, row) => currencyFormatter(cell, row)}
                  >
                    Montant
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataAlign="center"
                    // width="200"
                    dataField="etat_ipm"
                    dataFormat={(cell, row) => etatFormatter(cell, row)}
                  >
                    Statut
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionSoinModal"
            tabIndex="-1"
            aria-labelledby="GestionSoinModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <GestionSoinAssureurModal />
          </div>
          <div
            className="modal fade easypm-modal"
            id="DetailsRemboursement"
            tabIndex="-1"
            aria-labelledby="#DetailsRemboursementLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <DetailsRemboursement />
          </div>
          <div
            className="modal fade easypm-modal"
            id="ModifierRemboursement"
            tabIndex="-1"
            aria-labelledby="ModifierRemboursementLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <ModifierRemboursement />
          </div>
          <div
            className="modal fade easypm-modal"
            id="ActionRemboursement"
            tabIndex="-1"
            aria-labelledby="ActionRemboursementLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <ActionRemboursement />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionRemboursementTable;
