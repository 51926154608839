import React, { useEffect, useContext } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import * as IconlyPack from 'react-iconly';
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getDataByID, putData } from "../../../utils/fetchData";
import DetailPrestataireSkeleton from "./DetailPrestataireSkeleton";
import EntrepriseSkeleton from "./EntrepriseSkeleton";

function DetailEntrepriseNotification() {
  const [userType, setUserType] = React.useState();
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [entreprise, setEntreprise] = React.useState({});

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  // console.log('props', location)
  useEffect(() => {
    setUserType(window.sessionStorage.getItem("userType"));

    if (params?.id && auth?.token) {
      getDataByID("entreprise", params?.id, auth?.token).then((res) => {
        // console.log('res', res)
        setEntreprise(res);
        setIsPageLoading(false);
      });
    }
  }, [params?.id, auth?.token]);

  const handleValid = (item) => {
    let id = parseInt(window.sessionStorage.getItem("notifId"));
    putData("notification/" + id + "/", { valid: true }, auth?.token).then(
      (res) => {
        console.log("res", res);
        if (userType !== "super_ipm") {
          if (auth?.user?.user_type === "admin_ipm") {
            getDataByID(
              "notificationbyipm",
              auth?.user?.parent,
              auth?.token
            ).then((resp) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
              });
            });
          } else {
            getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
              (resp) => {
                // console.log('notif', resp)
                window.sessionStorage.removeItem("notifId");
                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
                });
              }
            );
          }
          // history.push(`/ipm/profil-beneficiaire/${res.beneficiaire}`);
        } else {
          getDataByID(
            "notificationbysuperadmin",
            auth?.user?.id,
            auth?.token
          ).then((res) => {
            window.sessionStorage.removeItem("notifId");
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
          // history.push(`/entreprise/profil-employes/${res.beneficiaire}`);
        }
        if (item === "rejeter" && userType !== "super_ipm") {
          history.push(`/ipm/gestion-des-prestataires`);
        }

        if (item === "rejeter" && userType === "super_ipm") {
          history.push(`/admin/gestion-des-etablissements-et-pharmacies`);
        }
      }
    );
  };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      {userType === "super_ipm"
                        ? "Détail Entreprise"
                        : "Demande d'agréement"}
                    </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="add-family-form-container">
                    {isPageLoading && <EntrepriseSkeleton />}
                    {!isPageLoading && (
                      <div className="crud-form">
                        <div className="row crud-row">
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="numero_contrat">
                                Num. Contrat:{" "}
                              </strong>

                              <strong className="crud-form-control">
                                {entreprise?.num_contrat}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="name">Raison sociale: </strong>
                              <strong className="crud-form-control">
                                {entreprise?.raison_sociale}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="ninea">NINEA: </strong>
                              <strong className="crud-form-control">
                                {entreprise?.ninea}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="email">Email: </strong>
                              <strong className="crud-form-control">
                                {entreprise?.email}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="adresse">Adresse: </strong>
                              <strong className="crud-form-control">
                                {entreprise?.adresse}
                              </strong>
                            </div>
                          </div>
                          <div className="col-md-6 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="telephone">Téléphone: </strong>
                              <strong className="crud-form-control">
                                {entreprise?.telephone}
                              </strong>
                            </div>
                          </div>

                          <div className="col-md-12 crud-col-item">
                            <div className="form-group crud-form-group">
                              <strong htmlFor="website">Site web: </strong>
                              <strong className="crud-form-control">
                                {entreprise?.website}
                              </strong>
                            </div>
                          </div>

                          <div
                            className="col-md-6 offset-md-6 crud-form-action-btn-container"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <button
                              className="btn btn-danger cancelleb-btn"
                              onClick={() => handleValid("rejeter")}
                            >
                              Retour
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailEntrepriseNotification;
