import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import Message from '../Services/Message'
import { DataContext } from '../store/GlobalState'
import { toast } from 'react-toastify'

const SendMessageModal = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { state, dispatch } = useContext(DataContext)
  const [isLoading, setIsLoading] = useState(false)

  const { auth, entreprise, ipm } = state

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  }

  const onSubmit = async (data, e) => {
    setIsLoading(true)
    console.log(data, entreprise)
    var messageRequest = Message.postMessage(data, requestConfig)
    await messageRequest
      .then((response) => {
        // var entrepriseInfos = response
        // console.log('res', response)
        window.$('.send-message-modal').modal('hide')
        onReset()
        toast.success('Message envoyé avec succès.')
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
        toast.error("Oupss! Une erreur est survenue lors de l'ajout.")
      })
  }

  const onReset = () => {
    dispatch({
      type: 'EDIT_ENTREPRISE',
      payload: {},
    })
    reset()
  }

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="SendMessageModal">
            Envoyer un message
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="first_name">Objet</label>

                    <input
                      type="text"
                      className="form-control crud-form-control"
                      name="objet"
                      id="objet"
                      placeholder="Objet du message"
                      {...register('objet', {
                        required: 'Ce champ est obligatoire !',
                      })}
                    />

                    {errors.objet && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {' '}
                        {errors.objet.message}{' '}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="first_name">Message</label>

                    <textarea
                      className="form-control crud-form-control"
                      name="message"
                      id="message"
                      placeholder="Saisissez votre message"
                      {...register('message', {
                        required:
                          "Veuiller saisir votre message avant d'envoyer !",
                      })}
                      cols="10"
                      rows="5"
                    ></textarea>

                    {errors.message && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {' '}
                        {errors.message.message}{' '}
                      </div>
                    )}
                  </div>
                </div>
                {entreprise?.id !== undefined && (
                  <input
                    type="hidden"
                    name="destinataire"
                    value={parseInt(entreprise?.id)}
                    {...register('destinataire')}
                  />
                )}

                {ipm?.id !== undefined && (
                  <input
                    type="hidden"
                    name="destinataire"
                    value={parseInt(ipm?.id)}
                    {...register('destinataire')}
                  />
                )}
                {auth?.user?.id !== undefined && (
                  <input
                    type="hidden"
                    name="expediteur"
                    value={auth?.user?.user_type === 'admin_ipm' ? parseInt(auth?.user?.parent) : parseInt(auth?.user?.id)}
                    {...register('expediteur')}
                  />
                )}
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => onReset()}
                  >
                    Retour
                  </button>

                  {isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isLoading && (
                    <button
                      type="submit"
                      className="btn btn-primary crud-submit-btn"
                    >
                      Envoyer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendMessageModal
