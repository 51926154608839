import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import contactMessage from '../../Services/Contact'

export default function UseSendContactForm() {
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false)
    const [isFormRequestError, setIsFormRequestError] = useState(false)
    const requestConfig = {
        headers: { Authorization: `Bearer ${window.sessionStorage.getItem('authToken')}` }
    }

    const validationSchema = useMemo(
        () =>
        yup.object().shape({
            email: yup.string().required('Votre email est requis.').email('Le format de votre email n\'est pas valide.'),
            objet: yup.string().required('L\'objet est requis.'),
            message: yup.string().required('Le message est requis.'),
        }),
        [],
    )

    const { register, handleSubmit, formState, clearErrors } = useForm({
        resolver: yupResolver(validationSchema),
    })

    const onSubmitPostForm = async (formValues) => {
        console.log(formValues)
        var messageRequest = contactMessage.onPostContactMessage(
        formValues)
        await messageRequest
        .then((response) => {
            setIsSuccessfullySubmitted(true)
            var message = response
            console.log(message)
        })
        .catch((error) => {
            console.log(error)
            setIsFormRequestError(true)
        })
    }

    return {
        register,
        formState,
        isSuccessfullySubmitted,
        setIsSuccessfullySubmitted,
        setIsFormRequestError,
        isFormRequestError,
        clearErrors,
        onSubmit: handleSubmit(onSubmitPostForm),
    }
}
