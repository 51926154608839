import React, { useEffect, useContext } from 'react'
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from 'react-bootstrap-table'
import GestionSoinsAdminTableSkeleton from './GestionSoinsAdminTableSkeleton'
import * as IconlyPack from 'react-iconly'
import { NavLink } from 'react-router-dom'
import GestionSoinsAdminTableData from './GestionSoinsAdminTableData'
import GestionSoinAdminModal from '../../../Backend/Assurance/AdminAssureur/ActiviteSoinAssureur/GestionSoinAdmin'
import ModifierSoinAdminModal from '../../../Backend/Assurance/AdminAssureur/ActiviteSoinAssureur/ModifierSoinAdminModal'
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'

var GestionSoinsAdminTable = (props) => {

 
  const [soins, setSoins] = React.useState(GestionSoinsAdminTableData)

  var [isPageLoading, setIsPageLoading] = React.useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false)
    }, 3000)
  }, [soins])

  var handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event')
    onClick()
  }

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    )
  }

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: 'Aucune donnée disponible',
  }
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
          <button className="action-btn edit-btn" title="Traitement">
          <IconlyPack.Delete
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#Modal"
          />
        </button>
        <button className="action-btn edit-btn" title="Traitement">
          <IconlyPack.Paper
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#GestionSoinAdminModal"
          />
        </button>
        {/* <button className="action-btn edit-btn" title="Traitement">
          <IconlyPack.Edit
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#ModifierSoinAdminModal"
          />
        </button> */}
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Show
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".show-soin-modal"
          />
        </button>
      </div>
    );
  };
  
  const etatFormatter = (cell, row) => {
      if (cell === "rejete") {
        return (
          <span className="badge badge-danger easypm-badge-danger ">
            Rejeté
          </span>
        );
      }

      if (cell === "archive") {
        return (
          <span className="badge badge-primary easypm-badge-primary ">
            Archivé
          </span>
        );
      }

      if (cell === "envoye") {
        return (
          <span className="badge badge-info easypm-badge-info ">Envoyé</span>
        );
      }

      if (cell === "en_attente") {
        return (
          <span className="badge badge-warning easypm-badge-warning ">
            En attente
          </span>
        );
      }

      if (cell === "valide") {
        return (
          <span className="badge badge-default easypm-badge-default ">
            Validé
          </span>
        );
      }

      if (cell === "regle") {
        return (
          <span className="badge badge-success easypm-badge-success ">
            Reglé
          </span>
        );
      }
  };
  const beneficaireFormatter = (cell, row) => {
    //  let beneficiaire = cell.beneficiaire
      return (
        <div className="nameFormatter" >
          <span className="name">
            {row?.beneficiaire}
          </span>{" "}
          <br />
          <strong className="entreprise">
            {" "}
            {row?.entreprise}{" "}
          </strong>{" "}
          
        </div>
      );
   
  };
  const prestataires = {
    Hoggy: "Hoggy",
  };
  const date = {
    date: "11/09/2021",
  };
  const statut = {
    valide: "Validé",
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <GestionSoinsAdminTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={soins}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="150"
                    dataField="beneficiaire"
                    dataFormat={(cell, row) => beneficaireFormatter(cell, row)}
                    filter={{
                        type: "TextFilter",
                        defaultValue:"",
                      }}
                  >
                    Bénéficiaires
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="150"
                    dataField="prestataire"
                    filter={{ type: "SelectFilter", options: prestataires }}
                  >
                    Prestataires
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="date"
                    filter={{ type: "SelectFilter", options: date }}

                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="140"
                    dataField="statut"
                    dataFormat={(cell, row) => etatFormatter(cell, row)}
                    filter={{ type: "SelectFilter", options: statut }}

                  >
                    Statut
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#1F497D',
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.7)',
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionSoinAdminModal"
            tabIndex="-1"
            aria-labelledby="GestionSoinAdminModalLabel"
            data-backdrop="static"
            aria-hidden="true"
        >
            <GestionSoinAdminModal />
        </div>
        <div
            className="modal fade easypm-modal add-soin-modal"
            id="ModifierSoinAdminModal"
            tabIndex="-1"
            aria-labelledby="ModifierSoinAdminModalLabel"
            data-backdrop="static"
            aria-hidden="true"
        >
            <ModifierSoinAdminModal />
        </div>
        </div>
      </div>
    </div>
  )
}

export default GestionSoinsAdminTable
