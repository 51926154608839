import React from "react";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";

const AdminMobileNavBarContent = () => {
  return (
    <>
      {/* <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/prestataire/dashboard"
          activeClassName="actived"
        >
          <IconlyPack.Chart
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Tableau de bord</span>
        </NavLink>
      </li> */}
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/admin/gestion-des-ipm"
          activeClassName="actived"
        >
          <IconlyPack.Document
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">IPMs</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/admin/gestion-des-etablissements-et-pharmacies"
          activeClassName="actived"
        >
          <IconlyPack.Work
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">
            Établissements & <br /> Pharmacies
          </span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/admin/gestion-des-familles-soins"
          activeClassName="actived"
        >
          <IconlyPack.Category
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Famille de soins</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/admin/gestion-des-exclusions"
          activeClassName="actived"
        >
          <IconlyPack.PaperFail
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Exclusions</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/admin/parametres"
          activeClassName="actived"
        >
          <IconlyPack.Setting
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Paramétres</span>
        </NavLink>
      </li>
    </>
  );
};

export default AdminMobileNavBarContent;
