import React, { useContext } from 'react'
import Header from '../../../Navs/Header'
import NavBarVerticalV2 from '../../navbarVerticalV2/navbarVerticalV2'
import * as IconlyPack from 'react-iconly'
import AdminPrestatairesTable from '../../../Tables/AdminPrestataire/AdminPrestatairesTable'
import CRUDAdminPrestataireModal from '../../../Modals/CRUDAdminPrestataireModal'
import { DataContext } from '../../../store/GlobalState'
import UseCreatePrestataireForm from '../../IPM/GestionPrestataires/UseCreatePrestataireForm'
import EditPrestataireModal from '../../../Modals/EditPrestataireModal'
import ShowPrestataireModal from '../../../Modals/ShowPrestataireModal'
import DeleteModalPrestataire from '../../../Modals/DeleteModalPrestataire'

var AdminGestionPrestataires = () => {
  const {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    setValue,
    reset,
  } = UseCreatePrestataireForm()
  const { state, dispatch } = useContext(DataContext)

  const { auth, prestataires } = state
  React.useEffect(() => {
    console.log(auth.token)
    if (isSuccessfullySubmitted) {
      setTimeout(() => {
        // setPrestataireData(prestataireData)
        setIsSuccessfullySubmitted(false)
      }, 3000)
    }

    if (isFormRequestError) {
      setIsSuccessfullySubmitted(false)
      setIsFormRequestError(true)
      setTimeout(() => {
        setIsFormRequestError(false)
      }, 5000)
    }

    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key)
        })
      }, 3000)
    }
  }, [
    errors,
    isSuccessfullySubmitted,
    formState,
    isFormRequestError,
    clearErrors,
    setIsFormRequestError,
    setIsSuccessfullySubmitted,
  ])

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Établissements et pharmacies</h1>
                  </div>
                  <div
                    className="adding-item-container linkable admin"
                    data-toggle="modal"
                    data-target=".add-prestataire-modal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">Ajouter</span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <AdminPrestatairesTable prestataires={prestataires} />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal add-prestataire-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                  data-backdrop="static"
                >
                  <CRUDAdminPrestataireModal
                    register={register}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    setValue={setValue}
                    reset={reset}
                  />
                </div>
                {/* Modal Edit */}
                <div
                  className="modal fade easypm-modal edit-prestataire-modal"
                  id="EditModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="EditModalItemLabel"
                  aria-hidden="true"
                >
                  <EditPrestataireModal />
                </div>

                {/* Modal Show */}
                <div
                  className="modal fade easypm-modal show-prestataire-modal"
                  id="ShowModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="ShowModalItemLabel"
                  aria-hidden="true"
                >
                  <ShowPrestataireModal />
                </div>

                {/* Modal Show */}
                <div
                  className="modal fade easypm-modal delete-prestataire-modal"
                  id="DeleteModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="DeleteModalItemLabel"
                  aria-hidden="true"
                >
                  <DeleteModalPrestataire />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminGestionPrestataires
