import React from 'react'
import { NavLink } from 'react-router-dom';
import * as IconlyPack from 'react-iconly';

var EntrepriseMobileNavBarContent = () => {
    return(
        <>
            <li>
                <NavLink className="mobile nav-link nav-link-vertival-custom" to="/entreprise/dashboard" activeClassName="actived">
                    <IconlyPack.Chart set="bold" primaryColor="white" className="icon-navbar-vertical"/>
                    <span className="mobile-navlink-label">Tableau de bord</span>
                </NavLink>
            </li> 
            <li>
                <NavLink className="mobile nav-link nav-link-vertival-custom" to="/entreprise/gestion-des-employes" activeClassName="actived">
                    <IconlyPack.People set="bold" primaryColor="white" className="icon-navbar-vertical"/>
                    <span className="mobile-navlink-label">Employés</span>
                </NavLink>
            </li> 
            <li>
                <NavLink className="mobile nav-link nav-link-vertival-custom" to="/entreprise/liste-des-prestataires" activeClassName="actived">
                    <IconlyPack.Swap set="bold" primaryColor="white" className="icon-navbar-vertical"/>
                    <span className="mobile-navlink-label">Prestataires</span>
                </NavLink>
            </li> 
            <li>
                <NavLink className="mobile nav-link nav-link-vertival-custom" to="/entreprise/parametres" activeClassName="actived">
                    <IconlyPack.Setting set="bold" primaryColor="white" className="icon-navbar-vertical"/>
                    <span className="mobile-navlink-label">Paramétres</span>
                </NavLink>
            </li> 
        </>
    )
}

export default EntrepriseMobileNavBarContent;
