import React from "react";
import Skeleton from "react-loading-skeleton";

const EntreprisesSkeleton = () => {
    return (
      <div className="col-md-12 as-col-table-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" style={{ color: "#1F497D" }}>
                Raison Sociale
              </th>
              <th scope="col" style={{ color: "#1F497D" }}>
                NINEA
              </th>
              <th scope="col" style={{ color: "#1F497D" }}>
                Email
              </th>
              <th scope="col" style={{ color: "#1F497D" }}>
                Telephone
              </th>
              <th scope="col" style={{ color: "#1F497D" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
}

export default EntreprisesSkeleton;