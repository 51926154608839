const AutresAdminsTableData = [
    {
        id: 1,
        nom: 'Sadio Sangharé',
        email: 'sadio.sanghare@volkeno.sn',
        telephone: '780158171'
    },
    {
        id: 2,
        nom: 'Sadio Sangharé',
        email: 'sadio.sanghare@volkeno.sn',
        telephone: '780158171'
    },
    {
        id: 3,
        nom: 'Sadio Sangharé',
        email: 'sadio.sanghare@volkeno.sn',
        telephone: '780158171'
    },
    {
        id: 4,
        nom: 'Sadio Sangharé',
        email: 'sadio.sanghare@volkeno.sn',
        telephone: '780158171'
    },
];

export default AutresAdminsTableData;
