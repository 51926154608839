import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { DataContext } from "../../store/GlobalState";
import Condition from "../../Services/Condition";
import { toast } from "react-toastify";

function UseCrudConditionForm(type, item) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFormRequestError, setIsFormRequestError] = useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        text: yup.string().required("Le contenu est requis").nullable(),
        type: yup.string().nullable(),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    reset,
    getValues,
    errors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [isLoading, setIsLoading] = useState(false);

  const [contenu, setContenu] = useState("");

  useEffect(() => {
    if (item?.id) {
      const fields = ["slug", "text", "type"];
      for (let field of fields) {
        register(field);
        setValue(field, item[field]);
      }
      setContenu(item?.text);
    } else {
      setContenu("");
    }
  }, [item]);

  useEffect(() => {
    if (errors) {
      setTimeout(
        () => Object.entries(errors).map(([key]) => clearErrors(key)),
        3000
      );
    }
  }, [errors]);

  const handleChange = (text) => {
    setContenu(text);
    setValue("text", text);
  };

  const onSubmitCreateForm = async (formValues, e) => {
    setIsLoading(true);
    formValues["type"] = type;

    console.log("data", formValues);
    let req = null;
    if (formValues?.slug) {
      req = Condition.putCondition(formValues?.slug, formValues, requestConfig);
    } else {
      req = Condition.postCreateCondition(formValues, requestConfig);
    }
    await req
      .then((response) => {
        console.log(response);

        toast.success("Le contenu a été mis à jour avec succès.");
        setIsLoading(false);
        setIsSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsFormRequestError(true);
        if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };

  return {
    register,
    formState,
    isLoading,
    clearErrors,
    onSubmit: handleSubmit(onSubmitCreateForm),
    setValue,
    reset,
    getValues,
    isSuccess,
    handleChange,
    contenu,
  };
}

export default UseCrudConditionForm;
