import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import FacturePdfDataModal from "../../../../Modals/FacturePdfDataModal";
import Header from "../../../../Navs/Header";
import FacturationClientTable from "../../../../Tables/Assurance/FacturationClientTable/FacturationClientTable";
import NavbarVerticalV2 from "../../../navbarVerticalV2/navbarVerticalV2";
import AjouterFactureModal from "./AjouterFactureModal";
import GenererFactureModal from "./GenererFactureClient";
var FacturationClient = () => {
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Facturation des clients</h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target="#AjouterFactureClientModal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Générer une facture
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <FacturationClientTable />
                </div>
                <div
                  class="modal fade"
                  id="GenererFactureClientModal"
                  tabindex="-1"
                  data-backdrop="static"
                  aria-labelledby="GenererFactureClientModalLabel"
                  aria-hidden="true"
                >
                  <GenererFactureModal />
                </div>
                <div
                  class="modal fade"
                  id="AjouterFactureClientModal"
                  tabindex="-1"
                  data-backdrop="static"
                  aria-labelledby="AjouterFactureClientModalLabel"
                  aria-hidden="true"
                >
                  <AjouterFactureModal />
                </div>
                <div
                  class="modal fade"
                  id="FacturePdfDataModal"
                  tabindex="-1"
                  data-backdrop="static"
                  aria-labelledby="FacturePdfDataModalLabel"
                  aria-hidden="true"
                >
                  <FacturePdfDataModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacturationClient;
