import React, { useContext, useState } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { DataContext } from "../store/GlobalState";
import TypePrestation from "../Services/TypePrestation";
import { toast } from "react-toastify";
import { updateItem } from "../store/Actions";
import { getDataByID, getData } from "../utils/fetchData";

const EditTypePrestationModal = () => {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, prestation_type, list_famille_soins } = state;

  const [types, setTypes] = React.useState([]);
  const [clesFamily, setClesFamily] = React.useState([
    { value: "", label: "" },
  ]);
  const [clesType, setClesType] = React.useState([{ value: "", label: "" }]);
  const [valFamily, setValFamily] = React.useState("");
  const [valType, setValType] = React.useState("");

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  React.useEffect(() => {
    if (errors) {
      setTimeout(() => {
        clearErrors();
      }, 5000);
    }
  }, [errors]);

  React.useEffect(() => {
    if (prestation_type?.id) {
      register("libelle");
      setValue("libelle", prestation_type?.libelle);
      register("taux_prise_en_charge");
      setValue("taux_prise_en_charge", prestation_type?.taux_prise_en_charge);

      register("montant_plafond");
      setValue("montant_plafond", prestation_type?.montant_plafond);

      register("typedesoin");
      setValue("typedesoin", prestation_type?.typedesoin?.id);
      if (prestation_type?.typedesoin?.id) {
        setValType({
          label: prestation_type?.typedesoin?.nom,
          value: prestation_type?.typedesoin?.id,
        });
      }

      register("familledesoin", {
        required: "La famille de soins est requise.",
      });
      setValue("familledesoin", prestation_type?.familledesoin?.id);
      if (prestation_type?.familledesoin?.id) {
        setValFamily({
          label: prestation_type?.familledesoin?.nom,
          value: prestation_type?.familledesoin?.id,
        });
      }

      if (
        types?.length &&
        types.filter(
          (t) => t?.familledesoin?.id === prestation_type?.familledesoin?.id
        )?.length
      ) {
        setClesType(
          types
            .filter(
              (t) => t?.familledesoin?.id === prestation_type?.familledesoin.id
            )
            .map((b) => {
              return {
                label: b.nom,
                value: b.id,
              };
            })
        );
      }
    }
  }, [prestation_type]);

  React.useEffect(() => {
    if (auth?.token) {
      getData("typedesoin/", auth.token).then((response) => {
        setTypes(response.results.sort((a, b) => b?.id - a?.id));
      });
    }

    if (list_famille_soins?.length) {
      setClesFamily(
        list_famille_soins.map((b) => {
          return {
            label: b.nom,
            value: b.id,
          };
        })
      );
    }
  }, [auth, list_famille_soins]);

  const onSubmit = async (data) => {
    // console.log('data', data)
    setIsLoading(true);
    if (!data.libelle) {
      data.libelle = prestation_type.libelle;
    }
    if (!data.montant_plafond) {
      data.montant_plafond = prestation_type.montant_plafond;
    }

    if (!data.taux_prise_en_charge) {
      data.taux_prise_en_charge = prestation_type.taux_prise_en_charge;
    }

    var prestation_typeRequest = TypePrestation.putTypePrestation(
      prestation_type.id,
      data,
      requestConfig
    );

    await prestation_typeRequest
      .then((response) => {
        var prestation_typeInfos = response;
        console.log("prestation_type", prestation_typeInfos);
        // dispatch(
        //   updateItem(
        //     prestation_types,
        //     prestation_type.id,
        //     prestation_typeInfos,
        //     "ADD_PRESTATION_TYPE"
        //   )
        // );
        if (auth?.user?.user_type === "admin_ipm") {
          getDataByID(
            "typeprestationbyassurance",
            auth?.user?.parent,
            auth.token
          )
            .then((res) => {
              dispatch({
                type: "ADD_PRESTATION_TYPE",
                payload: res.reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          getDataByID("typeprestationbyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_PRESTATION_TYPE",
                payload: res.reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
        setIsLoading(false);
        window.$(".edit-type-prestation-modal").modal("hide");
        dispatch({ type: "EDIT_PRESTATION_TYPE", payload: {} });
        toast.success("Type de prestation modifié avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
      });
  };

  const onSelectType = (e) => {
    console.log(e.value);
    setValType(e);
    register("typedesoin");
    setValue("typedesoin", e.value);
  };

  const onSelectFamily = (e) => {
    console.log(e.value, types);
    setValFamily(e);
    if (
      types?.length &&
      types.filter((t) => t?.familledesoin?.id === e.value)?.length
    ) {
      setClesType(
        types
          .filter((t) => t?.familledesoin?.id === e.value)
          .map((b) => {
            return {
              label: b.nom,
              value: b.id,
            };
          })
      );
    }
    register("familledesoin");
    setValue("familledesoin", e.value);
  };

  const handleReset = () => {
    dispatch({ type: "EDIT_PRESTATION_TYPE", payload: {} });
    setValFamily("");
    setValType("");
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Modifier un type de prestation
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Famille de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner la famille de soins"
                        name="familledesoin"
                        value={valFamily || ""}
                        onChange={(e) => onSelectFamily(e)}
                        options={clesFamily}
                      />
                      {errors && errors.familledesoin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {errors.familledesoin?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group ">
                    <label htmlFor="cle">Type de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner le type de soins"
                        name="typedesoin"
                        value={valType}
                        onChange={(e) => onSelectType(e)}
                        options={clesType}
                      />
                      {errors && errors.typedesoin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {errors.typedesoin?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="libelle">Libellé</label>
                    <input
                      type="text"
                      placeholder="Libellé"
                      name="libelle"
                      className="form-control crud-form-control"
                      id="libelle"
                      {...register("libelle")}
                      defaultValue={prestation_type?.libelle}
                    />
                    {errors.libelle && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {errors.libelle.message}{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="taux_prise_en_charge">
                      Taux de prise en charge
                    </label>
                    <input
                      type="number"
                      placeholder="Taux de prise en charge"
                      name="taux_prise_en_charge"
                      className="form-control crud-form-control"
                      id="taux_prise_en_charge"
                      {...register("taux_prise_en_charge", {
                        required: "Le taux de prise en charge est requis.",
                      })}
                      defaultValue={prestation_type?.taux_prise_en_charge}
                    />
                    {errors.taux_prise_en_charge && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {errors.taux_prise_en_charge.message}{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="montant_plafond">Montant plafond</label>
                    <input
                      type="number"
                      placeholder="Montant plafond"
                      name="montant_plafond"
                      className="form-control crud-form-control"
                      id="montant_plafond"
                      {...register("montant_plafond", {
                        required: "Le montant plafond est requis.",
                      })}
                      defaultValue={prestation_type?.montant_plafond}
                    />
                    {errors.montant_plafond && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {errors.montant_plafond.message}{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>

                  {!isLoading ? (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  ) : (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTypePrestationModal;
