import { remboursement } from "./Endpoints";
import axios from "axios";

export default {
  async getListRemboursements(config) {
    const response = await axios.get(
      remboursement.onGetOrPostRemboursement(),
      config
    );
    // console.log(response)
    return response.data;
  },
  async postCreateRemboursement(
    prestataireId,
    beneficiaireId,
    ayant_droitId,
    type_prestationId,
    typedesoinId,
    familledesoinId,
    designation,
    data,
    config
  ) {
    const response = await axios.post(
      remboursement.onGetOrPostRemboursement(
        prestataireId,
        beneficiaireId,
        ayant_droitId,
        type_prestationId,
        typedesoinId,
        familledesoinId,
        designation
      ),
      data,
      config
    );
    return response.data;
  },
  async putRemboursement(id, data, config) {
    const response = await axios.put(
      remboursement.onEditRemboursement(id),
      data,
      config
    );
    return response.data;
  },
  async deleteRemboursement(id, config) {
    const response = await axios.delete(
      remboursement.onDeleteRemboursement(id),
      config
    );
    return response;
  },
};
