/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import baseUrl from "../../Http/backend-base-url";
import { DataContext } from "../store/GlobalState";

function ShowDevisFileModal() {
  const { state, dispatch } = React.useContext(DataContext);

  const { devis, auth } = state;
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Devis {auth?.user?.user_type === "entreprise" && "Décompte"}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "SHOW_DEVIS", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            className="pdf-created-row"
            style={{ margin: "0px", padding: "0px" }}
          >
            {/* <div style={{ marginBottom: '3em', marginTop: '3em' }}> */}
            <div className="col-md-6 offset-md-3 pdf-created-link-container">
              {/* <a style={style.download_pdf_} target="_blank" rel="noopener noreferrer" href={Url}> */}
              <a
                className="pdf-created-link"
                target="_blank"
                rel="noopener noreferrer"
                download
                href={`${baseUrl}${
                  auth?.user?.user_type === "entreprise"
                    ? devis?.decompte_file
                    : devis?.document?.doc
                }`}
                // data-dismiss="modal"
                onClick={() => {
                  setTimeout(() => {
                    window.$(".show-devis-file-modal").modal("hide");
                  }, 1000);
                }}
              >
                Télécharger le devis
              </a>
            </div>
            <div className="col-md-12 pdf-created-viewr-container">
              {devis?.id && devis?.document?.id ? (
                <iframe
                  className="iframe_pdf"
                  frameBorder="0"
                  src={`${baseUrl}${
                    auth?.user?.user_type === "entreprise"
                      ? devis?.decompte_file
                      : devis?.document?.doc
                  }`}
                  style={{ width: "100%", height: "700px" }}
                  height="700"
                  width="100%"
                />
              ) : (
                <div
                  className="gfa-rowy"
                  style={{ marginTop: "18%", height: "300px" }}
                >
                  <div className="alert alert-warning gfa-warning">
                    Fichier non disponible
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowDevisFileModal;
