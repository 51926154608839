import React from "react";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import { useCurrentUser } from "../../../utils/utils";
import { useQuery } from "../../../Http/http/query";

export function useRdvPrestataire(etat = "new") {
  const user = useCurrentUser();
  const query = useQuery(null);
  const [data, setData] = React.useState([]);
  const reload = (userId) => {
    query.getData(`/rdvbyprestataire/${userId}/`);
  };
  React.useEffect(() => {
    if (user?.id) {
      reload(user.id);
    }
  }, [user?.id]);
  React.useEffect(() => {
    setData(query.data.filter((f) => f.etat === etat));
  }, [query.data]);

  return [data, query.loading, reload];
}

var PrestataireNavBarContent = () => {
  const [data] = useRdvPrestataire();
  return (
    <>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/prestataire/dashboard"
          activeClassName="actived"
        >
          <IconlyPack.Chart
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Tableau de bord</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/prestataire/gestion-des-factures"
          activeClassName="actived"
        >
          <IconlyPack.Wallet
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Facturation</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/prestataire/gestion-des-rdv"
          activeClassName="actived"
        >
          <IconlyPack.Calendar
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Mes rendez-vous</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom position-relative">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/prestataire/gestion-des-reservations"
          activeClassName="actived"
        >
          <IconlyPack.Wallet
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Réservations</span>
          <div className="notif-reservetion">{data.length}</div>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/prestataire/fiche-de-soin-valide"
          activeClassName="actived"
        >
          {/* <IconlyPack.Scan set="bold" primaryColor="white" className="icon-navbar-vertical" /> */}
          <i
            className="fa fa-qrcode fa-lg icon-navbar-vertical"
            aria-hidden="true"
            style={{ color: "#ffffff" }}
          ></i>
          <span className="hiddenable-navlink-label">Soins et Médicaments</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/prestataire/gestion-des-devis"
          activeClassName="actived"
        >
          <IconlyPack.Activity
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Devis</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/prestataire/parametres"
          activeClassName="actived"
        >
          <IconlyPack.Setting
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Paramétres</span>
        </NavLink>
      </li>
    </>
  );
};

export default PrestataireNavBarContent;
