import React from "react";
import { NavLink } from "react-router-dom";
import EasyPMInMacBook from "../../../images/landingpage/easypm-in-macbook.svg";
import * as IconlyPack from "react-iconly";
import './FirstLandingPage.css'
import ContactForm from "../../LandingPage/ContactForm/ContactForm";
import EmptyHeader from "../../Auth/EmptyHeader";
import FooterFirstLandingpage from "./FooterFirstLandingpage";
var FirstLandingPage = () => {
  const [showContactForm, setShowContactForm] = React.useState(false);

  const onShowAndHideContactForm = () => {
    if (showContactForm) {
      setShowContactForm(false);
    } else {
      setShowContactForm(true);
    }
  };
  return (
    <div className="landing-page-component">
      <EmptyHeader isLandingPage={true} />
      <div className="page-content position-relative bg-white">
        <section className="page-garde-container-first-landingpage">
           <div className="container container-padding-bottom-first-landingpage">
           <div className="row">
                <div className="col-md-12">
                <h1 className="introduction-first-landingpage">
                EasyPM, une révolution pour vos démarches de santé
            <br />
          </h1>
          <div className="introduction-slogan-first-landingpage">
            <p>
            Une nouvelle façon de gérer le parcours de soins: une solution digitale qui facilite vos démarches et améliore vos services
            </p>
          </div>
            <div className="introduction-action-btn-container i-am mt-5 i-am-padding-bottom">
                <div className="d-flex align-items-center row">
                    <div className="col-xl-2 col-lg-2 col-md-2 py-2 d-flex justify-content-center">
                        <span className="text-i-am">Je suis</span>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-5 py-2">
                        <NavLink className="btn ldpg-login-btn p-2" to="/easypm">UNE INSTITUTION DE PRÉVOYANCE MALADIE</NavLink>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-5 py-2">
                        <NavLink className="btn ldpg-login-btn-assurance p-2" to="/easypm-assurance">UNE ASSURANCE SANTÉ</NavLink>
                    </div>
                </div>
            </div>
                </div>
            </div>
          
           </div>
        </section>
        <section className="easypm-in-macbook-image-container-first-landingpage">
            <div className="easypm-in-macbook-image-content-first-landingpage">
                <img
                title="EasyPM"
                src={EasyPMInMacBook}
                alt="IPM Easy"
                className="easypm-in-macbook-image-first-landingpage"
                />
            </div>
        </section>
     
        <section className="contact-section-container margin-bottom-contact">
          <div className="contact-light-container-first-landingpage">
              <div className="container">
            <div className="row contact-light-row">
              <div className="col-xl-7 col-lg-7 col-md-6 contact-light-col textual-contact-col">
                <h3 className="helpy-title">
                  {" "}
                  Nous sommes là pour vous aider{" "}
                </h3>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6 pb-3 contact-light-col btn-contact-col">
                <button
                  onClick={() => onShowAndHideContactForm()}
                  className="btn btn-default contact-light-btn"
                >
                  Nous contacter
                </button>
              </div>
              {showContactForm && (
                <div className="col-xl-5 col-lg-5 col-md-6 pb-3 offset-md-6 contact-form-col">
                  <ContactForm />
                </div>
              )}
            </div>
            </div>
          </div>
        </section>

        <FooterFirstLandingpage />
      </div>
    </div>
  );
};

export default FirstLandingPage;
