import React, { useContext } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../../store/GlobalState";
import AddExclusionModal from "../../../Modals/AddExclusionModal";
import UseCreateExclusionForm from "./UseCreateExclusionForm";
import Exclusions from "../../../Tables/Exclusions/Exclusions";

const GestionDesExclusions = () => {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    isSuccess,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    getValues,
  } = UseCreateExclusionForm();
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      Gestion des exclusions &nbsp;
                      {/* <span className="badge badge-info easypm-badge-info">
                      </span> */}
                    </h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target=".add-exclusion-modal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Ajouter une exclusion
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <Exclusions />
                </div>
                {/* Modal */}
                <div
                  className="modal fade easypm-modal add-exclusion-modal"
                  id="AddExclusionModalItem2"
                  tabIndex="-1"
                  aria-labelledby="AddExclusionModalItem2Label"
                  aria-hidden="true"
                  data-backdrop="static"
                >
                  <AddExclusionModal
                    register={register}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    reset={reset}
                    setValue={setValue}
                    isSuccess={isSuccess}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionDesExclusions;
