import React, { useContext, useState } from "react";
import { DataContext } from "../../../store/GlobalState";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Prestataire from "../../../Services/Prestataire";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

var ProfileInformation = () => {
  const { state, dispatch } = useContext(DataContext);
  const { prestataire, auth } = state;

  const [isLoading, setIsLoading] = useState(false);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [phone, setPhone] = React.useState("");

  React.useEffect(() => {
    if (prestataire?.id) {
      // setPhone(prestataire?.telephone)
      if (prestataire.telephone.includes("+221")) {
        setPhone(prestataire.telephone);
      } else {
        setPhone("+221" + prestataire.telephone);
      }
    }
    console.log(prestataire);
  }, [prestataire?.id]);

  const handleSetPhone = (val) => {
    setPhone(val);
    register("telephone", {
      required: {
        value: true,
        message: "Ce champ est obligatoire.",
      },
      pattern: {
        value: new RegExp(/^((\+)221|0)[1-9](\d{2}){4}$/),
        message: "Veuillez vérifier le format du numéro de téléphone",
      },
    });
    setValue("telephone", "+" + val);
  };

  const onSubmit = async (data, e) => {
    console.log(data);
    setIsLoading(true);
    if (!data?.email) {
      data.email = prestataire.email;
    }

    if (!data?.raison_sociale) {
      data.raison_sociale = prestataire.raison_sociale;
    }

    if (!data?.ninea) {
      data.ninea = prestataire.ninea;
    }

    if (!data?.prenom) {
      data.prenom = prestataire.prenom;
    }

    if (!data?.nom) {
      data.nom = prestataire.nom;
    }

    if (!data?.telephone) {
      data.telephone = prestataire.telephone;
    }

    if (!data?.adresse) {
      data.adresse = prestataire.adresse;
    }

    var prestataireRequest = Prestataire.putPrestataire(
      prestataire.id,
      data,
      requestConfig
    );

    await prestataireRequest
      .then((response) => {
        var prestataireInfos = response;
        console.log("prestataire", prestataireInfos);
        // dispatch(
        //   updateItem(
        //     prestataires,
        //     prestataire.id,
        //     prestataireInfos,
        //     'ADD_PRESTATAIRE',
        //   ),
        // )
        setIsLoading(false);
        dispatch({ type: "EDIT_PRESTATAIRE", payload: prestataireInfos });
        dispatch({
          type: "AUTH",
          payload: { token: auth.token, user: prestataireInfos },
        });

        toast.success("Profil modifié avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
      });
  };

  return (
    <div className="easypm-tabs-item-content-container">
      <div className="easypm-tabs-item">
        <div className="add-family-form-container">
          <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row crud-row">
              {prestataire?.raison_sociale && (
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="name">Raison Sociale</label>
                    <input
                      type="text"
                      defaultValue={prestataire?.raison_sociale}
                      {...register("raison_sociale")}
                      placeholder="Nom"
                      name="raison_sociale"
                      className="form-control crud-form-control"
                      id="name"
                    />
                  </div>
                </div>
              )}

              {prestataire?.prenom && (
                <>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="prenom">Prénom</label>
                      <input
                        type="text"
                        defaultValue={prestataire?.prenom}
                        {...register("prenom")}
                        placeholder="Nom"
                        name="prenom"
                        className="form-control crud-form-control"
                        id="prenom"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="nom">Nom</label>
                      <input
                        type="text"
                        defaultValue={prestataire?.nom}
                        {...register("nom")}
                        placeholder="Nom"
                        name="nom"
                        className="form-control crud-form-control"
                        id="nom"
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-6 crud-col-item">
                <div className="form-group crud-form-group">
                  <label htmlFor="ninea">NINEA</label>
                  <input
                    type="text"
                    defaultValue={prestataire?.ninea}
                    {...register("ninea")}
                    placeholder="NINEA"
                    name="ninea"
                    className="form-control crud-form-control"
                    id="ninea"
                  />
                </div>
              </div>
              <div className="col-md-6 crud-col-item">
                <div className="form-group crud-form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    defaultValue={prestataire?.email}
                    {...register("email", {
                      pattern: {
                        value: new RegExp(
                          // /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                          /^(?<user>[a-zA-Z0-9._%+'-]+)@(?<domain>[a-zA-Z0-9.'-]+\.[a-zA-Z]{2,4})$/
                        ),
                        message: "Votre email n'est pas valide.",
                      },
                    })}
                    placeholder="Email"
                    name="email"
                    className="form-control crud-form-control"
                    id="email"
                  />
                  {errors.email && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {errors.email.message}{" "}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 crud-col-item">
                <div className="form-group crud-form-group">
                  <label htmlFor="adresse">Adresse</label>
                  <input
                    type="text"
                    defaultValue={prestataire?.adresse}
                    {...register("adresse")}
                    placeholder="Adresse"
                    name="adresse"
                    className="form-control crud-form-control"
                    id="adresse"
                  />
                </div>
              </div>
              <div className="col-md-6 crud-col-item">
                <div className="form-group crud-form-group">
                  <label htmlFor="telephone">Téléphone</label>
                  {/* <input
                    type="text"
                    defaultValue={prestataire?.telephone}
                    {...register('telephone')}
                    placeholder="+221780158171"
                    name="telephone"
                    className="form-control crud-form-control"
                    id="telephone"
                  /> */}
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    inputClass="form-control crud-form-control"
                    country={"sn"}
                    onlyCountries={["sn"]}
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    disableDropdown={true}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    countryCodeEditable={false}
                    onChange={(phone) => handleSetPhone(phone)}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                    }}
                  />
                  {errors && errors?.telephone && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {errors?.telephone?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3 offset-md-9 crud-form-action-btn-container">
                {/* <button className="btn btn-default cancelleb-btn" data-dismiss="modal">Retour</button> */}
                {!isLoading ? (
                  <button className="btn btn-primary crud-submit-btn">
                    Modifier
                  </button>
                ) : (
                  <button className="btn btn-primary crud-submit-btn">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileInformation;
