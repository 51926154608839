import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation } from "../../../../../Http/http/mutation";
import {
  add_ayant_droit,
  edit_ayant_droit,
} from "../../../../../Http/http/api";
import {
  MyButton,
  MyDatePicker,
  MyInput,
  Select,
  useFormData,
  useFormValidation,
} from "../../../../../utils/utils";
const congig = [
  {
    name: "prenom",
    label: "Prénom",
  },
  {
    name: "nom",
    label: "Nom",
  },
  {
    name: "sexe",
    label: "Genre",
    type: "picker",
    items: [
      { value: "homme", label: "Homme" },
      { value: "femme", label: "Femme" },
    ],
  },
  {
    name: "statut",
    label: "Statut",
    type: "picker",
    items: [
      { value: "enfant", label: "Enfant" },
      { value: "époux(se)", label: "époux(se)" },
    ],
  },
  {
    name: "certificat",
    label: (v) =>
      v
        ? v === "époux(se)"
          ? "Certificat de mariage"
          : "Certificat de naissance"
        : "Certificat de mariage ou de naissance",
    type: "file",
  },
  {
    name: "avatar",
    label: "Avatar",
    type: "file",
  },
  {
    name: "date_naissance",
    label: "Date de naissance",
    format: "DD/MM/YYYY",
    type: "date",
  },
];
const keys = congig.map((c) => c.name);
const AddAyantDroitModal = ({ user, item, onAdd }) => {
  const mutation = useMutation(add_ayant_droit);
  const mutation2 = useMutation((data) => edit_ayant_droit(data.Id, data));
  const obj = {};
  for (let k of keys) {
    obj[k] = "";
  }
  const [data, onChange] = useFormData(obj);
  const [errors, validate] = useFormValidation({}, (Yup) => ({
    nom: Yup.string().required().label("Nom"),
    prenom: Yup.string().required().label("Prénom"),
    sexe: Yup.string().required().label("Genre"),
    statut: Yup.string().required().label("Statut"),
    certificat: Yup.string().required().label("Certificat"),
    date_naissance: Yup.string().required().label("Date de naissance"),
  }));
  React.useEffect(() => {
    if (item) {
      onChange({
        nom: item.nom,
        prenom: item.prenom,
        sexe: item.sexe,
        statut: item.statut,
        certificat: item.certificat,
        date_naissance: moment(item.date_naissance).toDate(),
        avatar: item.avatar,
      });
    }
  }, [item]);

  const onSubmit = React.useCallback(async () => {
    validate(data)
      .then(async () => {
        const { date_naissance, avatar, certificat, ...rest } = data;
        const sendData = {
          ...rest,
          beneficiaire: user.id,
        };
        if (avatar && avatar !== item?.avatar) {
          sendData.avatar = avatar;
        }
        if (certificat && certificat !== item?.certificat) {
          sendData.certificat = certificat;
        }
        sendData.date_naissance = moment(date_naissance).format("YYYY-MM-DD");
        console.log("sendData", sendData);
        try {
          if (!item) {
            await mutation.exec(sendData);
            toast.success(
              "L'ajout des données est en cours de Validation. \n Vous serez notifié lorsque la Validation sera un succes. \n Une fois notifié les données se Mettront Automatiquement à jour"
            );
          } else {
            sendData.Id = item.id;
            await mutation2.exec(sendData);
            toast.success(
              "La modifications des données est en cours de Validation. \n Vous serez notifié lorsque la Validation sera un succes. \n Une fois notifié les données se Mettront Automatiquement à jour"
            );
          }
          reset();
          onAdd();
          window.$("#DemandeFicheSoinsModal").modal("hide");
        } catch (err) {
          toast.error(`Error: ${err?.message}`);
          console.log("error", JSON.stringify(err.response));
        }
      })
      .catch((ex) => {
        console.log("error", ex);
      });
  }, [data, item]);
  const reset = () => {
    console.log("reser", obj);
    onChange({
      ...obj,
    });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="DemandeFicheSoinsModal"
          >
            {item ? "Modifier" : "Ajouter"} un ayantdroit
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => {
              onAdd(false);
              reset();
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row crud-row">
                {congig.map(({ type, name, items, label, ...rest }, i) => {
                  if (name === "certificat") {
                    label = label(data.statut);
                  }
                  if (type === "picker") {
                    return (
                      <div className="col-md-6" key={i}>
                        <Select
                          name={name}
                          error={errors[name]}
                          value={data[name]}
                          onChange={(value) => {
                            if (name === "statut") {
                              onChange({
                                [name]: value,
                                date_naissance: "",
                              });
                            } else {
                              onChange(name, value);
                            }
                          }}
                          label={label}
                          placeholder={label}
                          items={items}
                          {...rest}
                        />
                      </div>
                    );
                  }
                  if (type === "date") {
                    return (
                      <div className="col-md-12" key={i}>
                        <MyDatePicker
                          name={name}
                          error={errors[name]}
                          value={data[name]}
                          onChange={(v) => onChange(name, v)}
                          label={label}
                          placeholder={label}
                          minDate={
                            data.statut !== "époux(se)"
                              ? moment(user?.date_de_naissance).toDate()
                              : undefined
                          }
                          maxDate={new Date()}
                          {...rest}
                        />
                      </div>
                    );
                  }
                  return (
                    <div className="col-md-6" key={i}>
                      <MyInput
                        error={errors[name]}
                        {...(type !== "file" ? { value: data[name] } : {})}
                        onChange={(v) => onChange(name, v)}
                        label={label}
                        placeholder={label}
                        name={name}
                        {...rest}
                        type={type === "file" ? "file" : "text"}
                      />
                    </div>
                  );
                })}
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    onClick={() => {
                      onAdd(false);
                      reset();
                    }}
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  <MyButton
                    title="Enregistrer"
                    loading={mutation.loading || mutation2.loading}
                    onClick={onSubmit}
                    className="btn  crud-submit-btn-assurance"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAyantDroitModal;
