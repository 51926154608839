import { ACTIONS } from "./Actions";

const reducers = (state, action) => {
  switch (action.type) {
    case ACTIONS.AUTH:
      return {
        ...state,
        auth: action.payload,
      };
    case ACTIONS.ADD_PRESTATAIRE:
      return {
        ...state,
        prestataires: action.payload,
      };
    case ACTIONS.ADD_IPM:
      return {
        ...state,
        ipms: action.payload,
      };
    case ACTIONS.EDIT_IPM:
      return {
        ...state,
        ipm: action.payload,
      };
    case ACTIONS.ADD_PRESTATION_TYPE:
      return {
        ...state,
        prestation_types: action.payload,
      };
    case ACTIONS.EDIT_PRESTATION_TYPE:
      return {
        ...state,
        prestation_type: action.payload,
      };
    case ACTIONS.EDIT_PRESTATAIRE:
      return {
        ...state,
        prestataire: action.payload,
      };
    case ACTIONS.ADD_BENEFICIAIRE:
      return {
        ...state,
        beneficiaires: action.payload,
      };
    case ACTIONS.ADD_ENTREPRISE:
      return {
        ...state,
        entreprises: action.payload,
      };
    case ACTIONS.ADD_ENTREPRISE_BY_IPM:
      return {
        ...state,
        entreprises_by_ipm: action.payload,
      };
    case ACTIONS.EDIT_ENTREPRISE:
      return {
        ...state,
        entreprise: action.payload,
      };
    case ACTIONS.SHOW_BENEFICIAIRE:
      return {
        ...state,
        beneficiaire: action.payload,
      };
    case ACTIONS.LIST_BENEFICIAIRE_BY_ENTREPRISE:
      return {
        ...state,
        beneficiairesByEntreprise: action.payload,
      };
    case ACTIONS.ADD_AYANTDROIT_BY_BENEFICIAIRE:
      return {
        ...state,
        ayantDroits: action.payload,
      };
    case ACTIONS.SHOW_AYANTDROIT_BY_BENEFICIAIRE:
      return {
        ...state,
        ayantDroit: action.payload,
      };
    case ACTIONS.ADD_FICHE_DE_SOIN_BY_IPM:
      return {
        ...state,
        ficheDeSoinsbyIpm: action.payload,
      };
    case ACTIONS.EDIT_FICHE_DE_SOIN_BY_IPM:
      return {
        ...state,
        ficheDeSoin: action.payload,
      };
    case ACTIONS.STATS:
      return {
        ...state,
        stats: action.payload,
      };
    case ACTIONS.LIST_ADMIN_IPM:
      return {
        ...state,
        admin_ipms: action.payload,
      };
    case ACTIONS.EDIT_ADMIN_IPM:
      return {
        ...state,
        admin_ipm: action.payload,
      };
    case ACTIONS.GET_FICHES_DE_SOIN:
      return {
        ...state,
        prestations: action.payload,
      };

    case ACTIONS.ADD_PRESTATION_FICHE_DE_SOIN:
      return {
        ...state,
        prestations: action.payload,
      };

    case ACTIONS.EDIT_PRESTATION:
      return {
        ...state,
        prestation: action.payload,
      };
    case ACTIONS.GET_FICHES_DE_SOIN_FOR_IPM:
      return {
        ...state,
        prestationbyassurance: action.payload,
      };
    case ACTIONS.PRESTATAIRE_BY_IPM:
      return {
        ...state,
        prestatairebyassurance: action.payload,
      };
    case ACTIONS.LISTE_PRESTATATION_BY_PRESTATAIRE:
      return {
        ...state,
        prestationbyprestataire: action.payload,
      };
    case ACTIONS.LISTE_FICHE_VALID_BY_PRESTATAIRE:
      return {
        ...state,
        fichevalidbyprestataire: action.payload,
      };
    case ACTIONS.LIST_FACTURE_BY_PRESTATAIRE:
      return {
        ...state,
        facturebyprestataire: action.payload,
      };
    case ACTIONS.LIST_FACTURE_BY_IPM:
      return {
        ...state,
        facturebyipm: action.payload,
      };
    case ACTIONS.LIST_FACTURE_BY_ENTREPRISE:
      return {
        ...state,
        facturebyentreprise: action.payload,
      };
    case ACTIONS.EDIT_FACTURE:
      return {
        ...state,
        facture: action.payload,
      };
    case ACTIONS.LIST_EMPLOYE_BY_ENTREPRISE_FROM_IPM:
      return {
        ...state,
        beneficiairebyemploye: action.payload,
      };
    case ACTIONS.LIST_EXCEPTION_IPM:
      return {
        ...state,
        exceptions_by_ipm: action.payload,
      };
    case ACTIONS.EDIT_EXCEPTION:
      return {
        ...state,
        exception: action.payload,
      };
    case ACTIONS.LIST_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case ACTIONS.LIST_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload,
      };
    case ACTIONS.DEVIS_BY_PRESTATAIRE:
      return {
        ...state,
        devisbyprestataire: action.payload,
      };
    case ACTIONS.DEVIS_BY_IPM:
      return {
        ...state,
        devisbyipm: action.payload,
      };
    case ACTIONS.DEVIS_BY_ENTREPRISE:
      return {
        ...state,
        devisbyentreprise: action.payload,
      };
    case ACTIONS.SHOW_DEVIS:
      return {
        ...state,
        devis: action.payload,
      };
    case ACTIONS.RAPPORT:
      return {
        ...state,
        rapport: action.payload,
      };
    case ACTIONS.LIST_PRESTATION_BY_FACTURE:
      return {
        ...state,
        prestations_facture: action.payload,
      };
    case ACTIONS.PARENT:
      return {
        ...state,
        parent: action.payload,
      };
    case ACTIONS.LIST_AYANTDROIT:
      return {
        ...state,
        list_ayantdroit: action.payload,
      };
    case ACTIONS.LIST_FAMILLE_SOINS:
      return {
        ...state,
        list_famille_soins: action.payload,
      };
    case ACTIONS.FAMILLE_SOINS:
      return {
        ...state,
        famille_soins: action.payload,
      };
    case ACTIONS.LIST_SPECIALITES:
      return {
        ...state,
        list_specialites: action.payload,
      };
    case ACTIONS.SPECIALITE:
      return {
        ...state,
        specialite: action.payload,
      };
    case ACTIONS.LIST_EXCLUSION:
      return {
        ...state,
        list_exclusions: action.payload,
      };
    case ACTIONS.EDIT_EXCLUSION:
      return {
        ...state,
        exclusion: action.payload,
      };
    case ACTIONS.ENTREPRISE_ASSUREUR:
      return {
        ...state,
        assureur_entreprises: action.payload,
      };
    case ACTIONS.PRESTATAIRE_ASSUREUR:
      return {
        ...state,
        assureur_prestataires: action.payload,
      };
    case ACTIONS.BENEFICIAIRE_ASSUREUR:
      return {
        ...state,
        assureur_beneficiaires: action.payload,
      };
    case ACTIONS.PRISE_EN_CHARGE_ASSUREUR:
      return {
        ...state,
        assureur_prise_en_charges: action.payload,
      };
    case ACTIONS.ADMIN_ASSUREUR:
      return {
        ...state,
        assureur_admins: action.payload,
      };
    case ACTIONS.FICHESOINS_ASSUREUR:
      return {
        ...state,
        assureur_ficheSoins: action.payload,
      };
    case ACTIONS.REMBOURSEMENTS_ASSUREUR:
      return {
        ...state,
        assureur_remboursements: action.payload,
      };
    case ACTIONS.FACTURES_ASSUREUR:
      return {
        ...state,
        assureur_factures: action.payload,
      };
    case ACTIONS.DEVIS_ASSUREUR:
      return {
        ...state,
        assureur_devis: action.payload,
      };
    case ACTIONS.RAPPORT_ASSUREUR:
      return {
        ...state,
        assureur_rapports: action.payload,
      };
    case ACTIONS.OPPOSITION_ASSUREUR:
      return {
        ...state,
        assureur_oppositions: action.payload,
      };
    case ACTIONS.EXCLUSION_ASSUREUR:
      return {
        ...state,
        assureur_exclusions: action.payload,
      };
    case ACTIONS.FICHESOINS_BENEFICIAIRE:
      return {
        ...state,
        beneficiaire_ficheSoins: action.payload,
      };
    case ACTIONS.CONDITIONS:
      return {
        ...state,
        conditions: action.payload,
      };
    case ACTIONS.CGU:
      return {
        ...state,
        cgu: action.payload,
      };
    case ACTIONS.PC:
      return {
        ...state,
        pc: action.payload,
      };
    default:
      return state;
  }
};

export default reducers;
