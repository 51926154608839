import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import Prestataire from "../../../Services/Prestataire";
import { getData, getDataByID } from "../../../utils/fetchData";
import { DataContext } from "../../../store/GlobalState";

function UseEditPrestataire() {
  const { state, dispatch } = useContext(DataContext);
  let { auth, prestataire } = state;

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [prestataireData, setPrestataireData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const validationSchema = useMemo(() =>
    yup.object().shape(
      {
        email: yup
          .string()
          .email("Votre adresse email n'est pas valide")
          .required("L'email est requis"),
        specialite: yup.string().required("La Spécialité est requise"),
        telephone: yup
          .string()
          .required("Le numéro de téléphone est requis")
          .matches(
            /^((\+)221|0)[1-9](\d{2}){4}$/,
            "Veuillez vérifier le format du numéro de téléphone"
          ),
        adresse: yup.string().required("L'adresse est requise"),
        agreement: yup.string().required("L'agréement est requis."),
        specialite: yup.string().required("La spécialité est requise"),
        ninea: yup.string().when("prenom", {
          is: (val) => !val || val.length === 0,
          then: yup
            .string()
            .matches(
              new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/),
              "Le NINEA doit être de format alpha-numérique."
            )
            .length(12, "Le ninea doit comporter 12 caractères")
            .required("Le NINEA est requis"),
          otherwise: yup.string(),
        }),
        date_debut: yup.string().required("La Date d'arrivée est requise"),
        date_fin: yup.string().required("La date de début est requise"),
        // assurance_maladie: yup.string().required("L'assureur est requis"),
        website: yup.string(),
        prenom: yup.string().when("raison_sociale", {
          is: (val) => !val || val.length === 0,
          then: yup.string().required("Le prénom est requis"),
          otherwise: yup.string(),
        }),
        nom: yup.string().when("raison_sociale", {
          is: (val) => !val || val.length === 0,
          then: yup.string().required("Le nom est requis"),
          otherwise: yup.string(),
        }),
        raison_sociale: yup.string().when("prenom", {
          is: (prenom) => !prenom || prenom.length === 0,
          then: yup.string().required("Le nom est requis."),
          otherwise: yup.string(),
        }),
      },
      ["prenom", "raison_sociale"]
    )
  );

  const validSchema = useMemo(() =>
    yup.object().shape(
      {
        assurance_maladie: yup.string().required("L'ipm est requis"),
      },
      []
    )
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    setValue,
    reset,
    unregister,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    // console.log(auth?.user?.id)
    // if (auth?.user?.user_type !== "entreprise") {
    //   if (!prestataire?.id && !auth?.user?.is_superuser) {
    //     if (auth?.user?.user_type === "admin_ipm") {
    //       register("assurance_maladie");
    //       setValue("assurance_maladie", auth?.user?.parent);
    //     } else {
    //       register("assurance_maladie");
    //       setValue("assurance_maladie", auth?.user?.id);
    //     }
    //   }
    // } else {
    //   register("agreement");
    //   setValue("agreement", false);
    //   register("enterprise");
    //   if (!auth?.user?.admin_type) {
    //     setValue("enterprise", auth?.user?.id);
    //   } else {
    //     setValue("enterprise", auth?.user?.parent);
    //   }
    // }
  }, [register, setValue, auth, prestataire?.id]);

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);

    console.log("data", formValues);

    var prestataireRequest = Prestataire.putPrestataire(
      formValues?.id,
      formValues,
      requestConfig
    );

    await prestataireRequest
      .then((response) => {
        var prestataireInfos = response;
        // console.log('prestataire', prestataireInfos)
        setPrestataireData(prestataireInfos);
        console.log(isSuccessfullySubmitted);

        e.target.reset();

        if (auth?.user?.user_type === "assureur") {
          getDataByID("prestatairebyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "PRESTATAIRE_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res.results?.sort((a, b) => b.id - a.id),
                },
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }

        toast.success("Prestataire modifié avec succès.");

        window.$("#ModifierPrestataireModal").modal("hide");
        dispatch({ type: "EDIT_PRESTATAIRE", payload: {} });
        setIsSuccess(true);
        setIsSuccessfullySubmitted(false);
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error?.response);
        setIsFormRequestError(true);
         if (error?.response?.data?.message) {
           toast.error(error.response.data.message);
         } else if (
           error?.response?.data?.email &&
           error?.response?.data?.email[0] ===
             "user with this email already exists."
         ) {
           toast.error(
             "Oupsss! L'adresse email est déjà associée à un compte. Veuillez utiliser une autre adresse email.",
             {
               position: "top-right",
               autoClose: 10000,
             }
           );
         } else if (error?.response?.status === 500) {
           toast.error(
             "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
           );
         } else if (
           error?.response?.status === 400 &&
           !error?.response?.data?.email
         ) {
           toast.error(
             "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
           );
         }
        setIsSuccessfullySubmitted(false);
      });
  };

  return {
    register,
    errors,
    formState,
    prestataireData,
    setPrestataireData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitCreateForm),
    setValue,
    reset,
    unregister,
    isSuccess,
  };
}

export default UseEditPrestataire;
