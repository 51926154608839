const GestionEntrepriseAdminTableData = [
    {
        id: 1,
        raisonsocial: 'VOLKENO SARL',
        ninea: '005836880 2v2',
        email: 'tlo@volkeno.sn',
        telephone: '33 800 0000',
        tierspayantintegral: 'OUI'
    },
    {
        id: 2,
        raisonsocial: 'VOLKENO SARL',
        ninea: '005836880 2v2',
        email: 'tlo@volkeno.sn',
        telephone: '33 800 0000',
        tierspayantintegral: 'OUI'
    },


];

export default GestionEntrepriseAdminTableData;
