import React from "react";
import * as IconlyPack from "react-iconly";
import AddExclusionModal from "../../../Modals/AddExclusionModal";
import Exclusions from "../../../Tables/Exclusions/Exclusions";
import UseCreateExclusionForm from "../../SUPERADMIN/GestionDesExclusions/UseCreateExclusionForm";

const ExclusionsIpm = () => {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    isSuccess,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    getValues,
  } = UseCreateExclusionForm();
  return (
    <div className="easypm-tabs-item-content-container">
      <div className="easypm-tabs-item">
        <div className="row adding-row">
          <div className="col-md-2 offset-md-10 adding-col-offseting">
            <div
              className="adding-item-container linkable"
              data-toggle="modal"
              data-target=".add-exclusion-modal"
            >
              <span className="icon-container">
                <IconlyPack.Plus set="light" primaryColor="gray" />
              </span>
              <span className="adding-item-label">Ajouter une exclusion</span>
            </div>
          </div>
        </div>
        <Exclusions />
      </div>
      {/* Modal */}
      <div
        className="modal fade easypm-modal add-exclusion-modal"
        id="AddExclusionModalItem2"
        tabIndex="-1"
        aria-labelledby="AddExclusionModalItem2Label"
        aria-hidden="true"
        data-backdrop="static"
      >
        <AddExclusionModal
          register={register}
          onSubmit={onSubmit}
          formState={formState}
          isSuccessfullySubmitted={isSuccessfullySubmitted}
          setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
          reset={reset}
          setValue={setValue}
          isSuccess={isSuccess}
        />
      </div>
    </div>
  );
};

export default ExclusionsIpm;
