import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Facture from "../../../../Services/Facture";
import { DataContext } from "../../../../store/GlobalState";
import { getDataByID } from "../../../../utils/fetchData";

const GenererFactureModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useContext(DataContext);
  const { facture, auth } = state;
  const { register, handleSubmit, reset, setValue, formState, clearErrors } = useForm();
  const [etat, setEtat] = useState("");

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  React.useEffect(() => {
    if (facture?.id) {
      setEtat(facture?.statut_ipm);
      register("statut_ipm");
      setValue("statut_ipm", facture?.statut_ipm);
    }
  }, [facture, auth]);

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  const handleChange = (e) => {
    setEtat(e.target.value);
    setValue("statut_ipm", e.target.value);
  };
  const onSubmit = async (data, e) => {
    if (
      (data?.statut_ipm === "regle" || data?.statut_ipm === "rejete") &&
      facture?.statut_prestataire
    ) {
      data["statut_prestataire"] = data?.statut_ipm;
    }

    if (
      (data?.statut_ipm === "regle" || data?.statut_ipm === "rejete") &&
      facture?.statut_entreprise
    ) {
      data["statut_entreprise"] = data?.statut_ipm;
    }

    setIsLoading(true);
    console.log("data", data);
    var factureRequest = Facture.putFacture(facture.id, data, requestConfig);

    await factureRequest
      .then(async (response) => {
        if (auth?.user?.admin_type !== "assureur") {
          getDataByID("facturebyipm", auth?.user?.id, auth?.token)
            .then((res) => {
              dispatch({
                type: "FACTURES_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res?.sort((a, b) => b?.id - a?.id),
                },
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          getDataByID("facturebyipm", auth?.user?.parent, auth?.token)
            .then((res) => {
              dispatch({
                type: "FACTURES_ASSUREUR",
                payload: {
                  isLoading: false,
                  data: res?.sort((a, b) => b?.id - a?.id),
                },
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
        setIsLoading(false);
        e.target.reset();
        window.$("#GenererFactureClientModal").modal("hide");
        dispatch({ type: "EDIT_FACTURE", payload: {} });

        toast.success("Statut de la facture modifié avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
      });
  };

  const onReset = () => {
    if (facture?.statut !== undefined) {
      reset();
      dispatch({ type: "EDIT_FACTURE", payload: {} });
    }
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="GenererFactureClientModal"
          >
            Gestion de la facture
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Action</label>
                    <div className="filter-item-container">
                      {facture?.statut_ipm !== undefined && (
                        <select
                          onChange={handleChange}
                          value={facture?.statut_ipm}
                          name="statut_ipm"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                        >
                          <option hidden value="">
                            Choisir une action
                          </option>
                          {/* <option value="nouveau">Nouveau</option> */}
                          <option value="envoye" hidden>
                            Envoyer
                          </option>
                          <option value="reçu" hidden>
                            Recevoir
                          </option>

                          <option value="regle">Régler</option>
                          <option value="rejete">Rejeter</option>
                        </select>
                      )}
                    </div>
                  </div>
                </div>
                {etat && etat === "regle" && (
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="moyen_paiement">
                        Référence de paiement
                      </label>
                      <input
                        type="text"
                        className="form-control crud-form-control"
                        name="references_paiement"
                        style={{
                          width: "100%",
                          border: "1px solid #ced4da",
                          backgroundColor: "#ffffff",
                        }}
                        {...register("references_paiement", {
                          required:
                            "Veuiller saisir les références du chèque ou du virement avant d'envoyer !",
                        })}
                        defaultValue={facture?.references_paiement || ""}
                        placeholder="Références du chèque ou du virement"
                        id="moyen_paiement"
                      />

                      {formState.errors &&
                        formState.errors?.references_paiement && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {" "}
                            {
                              formState.errors?.references_paiement?.message
                            }{" "}
                          </div>
                        )}
                    </div>
                  </div>
                )}

                {etat === "rejete" && (
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="motif">Motif</label>
                      {facture?.id && (
                        <textarea
                          className="form-control crud-form-control"
                          name="motif"
                          id="motif"
                          placeholder="Saisissez le motif"
                          {...register("motif", {
                            required:
                              "Veuiller saisir le motif avant d'envoyer !",
                          })}
                          defaultValue={facture?.motif || ""}
                          cols="10"
                          rows="5"
                        ></textarea>
                      )}

                      {formState.errors && formState.errors?.motif && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {formState.errors?.motif?.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    data-dismiss="modal"
                    onClick={() => onReset()}
                  >
                    Retour
                  </button>

                  {!isLoading && (
                    <button className="btn btn-primary crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                  )}

                  {isLoading && (
                    <button
                      disabled
                      className="btn btn-primary crud-submit-btn-assurance"
                    >
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenererFactureModal;
