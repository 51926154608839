import React, { useEffect, useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import { DataContext } from "../../../../store/GlobalState";
import UseEditAdminForm from "./UseEditAdminForm";

const ModiferAdmin = () => {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    isSuccess,
  } = UseEditAdminForm();
  const { state } = useContext(DataContext);
  const { auth, admin_ipm } = state;

  const [phone, setPhone] = useState("");
  let userType = window.sessionStorage.getItem("userType");

  useEffect(() => {
    if (admin_ipm?.id) {
      register("prenom");
      setValue("prenom", admin_ipm?.prenom);
      register("nom");
      setValue("nom", admin_ipm?.nom);
      register("telephone");
      setValue("telephone", admin_ipm?.telephone);
      setPhone(admin_ipm?.telephone);
      register("email");
      setValue("email", admin_ipm?.email);
      register("id");
      setValue("id", admin_ipm?.id);
    }
  }, [admin_ipm]);

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  useEffect(() => {
    if (isSuccess) {
      handleReset();
    }
  }, [isSuccess]);

  const handleSetPhone = (val) => {
    // console.log('phone', val)
    setPhone(val);
    register("telephone");
    setValue("telephone", "+" + val);
  };

  const handleReset = () => {
    reset();
    setPhone("+221");
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="EdittingFamilyModal"
          >
            Modifier un admin
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => handleReset()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="prenom">Prénom</label>
                    <input
                      {...register("prenom")}
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      className="form-control crud-form-control"
                      id="first_name"
                    />
                    {formState?.errors && formState?.errors?.prenom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="nom">Nom</label>
                    <input
                      {...register("nom")}
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      className="form-control crud-form-control"
                      id="last_name"
                    />
                    {formState?.errors && formState?.errors?.nom && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      {...register("email")}
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="form-control crud-form-control"
                      id="email"
                    />
                    {formState?.errors && formState?.errors?.email && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group required crud-form-group">
                    <label htmlFor="telephone">Téléphone</label>

                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      inputClass="form-control crud-form-control"
                      country={"sn"}
                      onlyCountries={["sn"]}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    {/* <div className="input-infos-container">
                        <IconlyPack.Danger
                          set="light"
                          primaryColor="gray"
                          className="alerty-icon"
                        />
                        &nbsp;
                        <span className="input-infos">
                          Le numéro de téléphone doit être au format
                          +221780158171
                        </span>
                      </div> */}
                    {formState?.errors && formState?.errors?.telephone && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.telephone?.message}
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className="col-md-6 crud-col-item">
                    <div className="form-group required crud-form-group">
                      <label htmlFor="adresse">Adresse</label>
                      <input
                        {...register("adresse")}
                        type="text"
                        placeholder="Adresse"
                        name="adresse"
                        className="form-control crud-form-control"
                        id="adresse"
                      />
                      {formState?.errors &&
                        formState?.errors?.adresse && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.adresse?.message}
                          </div>
                        )}
                    </div>
                  </div> */}

                {/* <input
                    {...register('parent')}
                    type="hidden"
                    name="parent"
                    value={auth?.user?.user_type === 'admin_ipm' ? auth?.user?.parent : fromIpmID}
                  /> */}
                {/* <input
                    {...register('user_type')}
                    type="hidden"
                    name="user_type"
                    value={`admin_ipm`}
                  />
                  <input
                    {...register('admin_type')}
                    type="hidden"
                    name="admin_type"
                    value={`ipm`}
                  /> */}

                <input
                  {...register("user_type")}
                  type="hidden"
                  name="user_type"
                  value={`assureur`}
                />

                <input
                  {...register("admin_type")}
                  type="hidden"
                  name="admin_type"
                  value={`assureur`}
                />

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn-assurance">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModiferAdmin;
