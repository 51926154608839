import React from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import AyantDroitBeneficiaireTableSkeleton from "./AyantDroitBeneficiaireTableSkeleton";
import DefaultAvatar from "../../../../../images/others/default-profile-avatar.png";

import http, { ApiUrl } from "../../../../../Http/http/http";
import { getAge, getName, StatusActive } from "../../../../../utils/utils";
import AddAyantDroitModal from "./AddAyantDroitModal";
import { toast } from "react-toastify";
// import GestionFicheDeSoin from './GestionFicheDeSoin'
export function getAvatarOrDefault(avatar) {
  return avatar?.includes("default.png") || !avatar
    ? DefaultAvatar
    : ApiUrl + avatar;
}
var AyantDroitBeneficiaireTable = ({ items, loading, user, reload }) => {
  const [deleting, setDeleting] = React.useState(null);
  const [item, setItem] = React.useState(null);
  React.useEffect(() => {
    if (deleting) {
      http
        .delete(`/ayantdroit/${deleting}`)
        .then(() => {
          reload();
          toast.success("Données supprimées avec succès");
          setDeleting(null);
        })
        .catch((ex) => {
          setDeleting(null);
        });
    }
  }, [deleting]);
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-Beneficiaire">
        {!!row.certificat && (
          <a
            href={ApiUrl + row.certificat}
            className="action-btn edit-btn"
            title="Certificat"
          >
            <IconlyPack.Paper
              set="bold"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-edit-icon"
              data-toggle="modal"
            />
          </a>
        )}
        <button className="action-btn edit-btn" title="Modifier">
          <IconlyPack.Edit
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            onClick={() => {
              setItem(row);
            }}
            data-target="#DemandeFicheSoinsModal"
          />
        </button>
        <button className="action-btn edit-btn" title="Supprimer">
          {deleting !== row.id && (
            <IconlyPack.Delete
              set="bold"
              primaryColor="#f00"
              onClick={() => {
                const ok = window.confirm(
                  "êtes-vous sûr de supprimer cet ayant droit"
                );
                if (ok) {
                  setDeleting(row.id);
                }
              }}
              className="action-btn-icon action-edit-icon"
            />
          )}
          {deleting === row.id && <i className="fas fa-spin fa-spinner"></i>}
        </button>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="page-first-row-container">
        <div className="page-title-container">
          <h1 className="page-title"></h1>
        </div>
        <div
          className="adding-item-container linkable"
          data-toggle="modal"
          data-target="#DemandeFicheSoinsModal"
        >
          <span className="icon-container">
            <IconlyPack.Plus set="light" primaryColor="gray" />
          </span>
          <span className="adding-item-label">Ajouter un Ayantdroit</span>
        </div>
      </div>
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {loading && <AyantDroitBeneficiaireTableSkeleton />}
            {!loading && (
              <div className="table-container">
                <BootstrapTable
                  data={items}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    dataFormat={(_, user) => {
                      const avatar = getAvatarOrDefault(user?.avatar);
                      return (
                        <img
                          src={avatar}
                          alt="Image profil utilisateur"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "100%",
                          }}
                        />
                      );
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="id"
                  >
                    Photos
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataFormat={(_, item) => getName(item)}
                    dataField="nom"
                  >
                    Prenom & nom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="statut"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                  >
                    Statut
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="age"
                    dataFormat={(_, item) =>
                      `${getAge(item.date_naissance)} Ans`
                    }
                  >
                    Âge
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="age"
                    dataFormat={(_, item) => (
                      <StatusActive etat={item.is_active} />
                    )}
                  >
                    État
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#0C0B0B",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade easypm-modal add-soin-modal"
        id="DemandeFicheSoinsModal"
        tabIndex="-1"
        aria-labelledby="DemandeFicheSoinsModalLabel"
        data-backdrop="static"
        aria-hidden="true"
      >
        <AddAyantDroitModal
          item={item}
          onAdd={(flag) => {
            setItem(null);
            if (flag !== false) {
              reload();
            }
          }}
          user={user || {}}
        />
      </div>
    </div>
  );
};

export default AyantDroitBeneficiaireTable;
