import React, { useContext } from "react";
import Header from "../../../Navs/Header";
import * as IconlyPack from "react-iconly";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import DemandeFicheDeSoinTable from "../../../Tables/FicheDeSoin/DemandeFicheDeSoinTable";
import FicheDeSoinValidationModal from "../../../Modals/FicheDeSoinValidationModal";
import AddFicheModal from "../../../Modals/AddFicheModal";
import UseFicheCreateForm from "./UseFicheCreateForm";
import { DataContext } from "../../../store/GlobalState";
import ValidFicheDeSoinModal from "../../../Modals/ValidFicheDeSoinModal";
import { getDataByID } from "../../../utils/fetchData";

var GestionDemandeFicheDeSoin = () => {
  const {
    unregister,
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    watch,
    reset,
    setValue,
    isSuccess,
    clearErrors,
  } = UseFicheCreateForm();
  const { state, dispatch } = useContext(DataContext);

  const { beneficiaires, prestatairebyassurance, auth } = state;

  React.useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (
        auth.user?.user_type === "ipm" ||
        auth?.user?.user_type === "assurance_sante" ||
        auth?.user?.user_type === "admin_ipm"
      ) {
        if (!auth?.user?.admin_type) {
          getDataByID("fichedesoinbyipm", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_FICHE_DE_SOIN_BY_IPM",
                payload: res.sort((a, b) => a.id - b.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID("notificationbyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_NOTIFICATION",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        } else {
          getDataByID("fichedesoinbyipm", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({
                type: "ADD_FICHE_DE_SOIN_BY_IPM",
                payload: res.sort((a, b) => a.id - b.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });

          getDataByID(
            "notificationbyipm",
            auth?.user?.parent,
            auth?.token
          ).then((res) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
        }
      }
    }
  }, [auth]);
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType="ipm" />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 entry">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Activité de Soins</h1>
                  </div>
                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target=".add-fiche-modal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Génèrer une fiche de soins
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <DemandeFicheDeSoinTable />
                </div>
                <div
                  className="modal fade easypm-modal show-fiche-modal"
                  id="ShowModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <FicheDeSoinValidationModal />
                </div>

                <div
                  className="modal fade easypm-modal show-valid-modal"
                  id="ShowModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <ValidFicheDeSoinModal />
                </div>

                <div
                  className="modal fade easypm-modal add-fiche-modal"
                  id="ShowModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <AddFicheModal
                    beneficiaires={beneficiaires}
                    prestataires={prestatairebyassurance}
                    register={register}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    reset={reset}
                    watch={watch}
                    setValue={setValue}
                    isSuccess={isSuccess}
                    unregister={unregister}
                    clearErrors={clearErrors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionDemandeFicheDeSoin;
