import ExceptionData from "./ExceptionData";
import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import ExceptionSkeleton from "./ExceptionSkeleton";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../store/GlobalState";
import ShowExceptionModal from "../../Modals/ShowExceptionModal";
import EditExceptionModal from "../../Modals/EditExceptionModal";
import { getDataByID } from "../../utils/fetchData";
import moment from "moment";

const ExceptionsTable = (props) => {
  const { state, dispatch } = useContext(DataContext);

  const { auth, beneficiaires } = state;
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  var [exceptions, setExceptions] = React.useState(ExceptionData);
  const [cles, setCles] = React.useState([]);

  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      setIsPageLoading(false);
    } else {
      setIsPageLoading(true);
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.user?.id) {
      getDataByID("benef_and_prestatairebyipm", auth?.user?.id, auth.token)
        .then((res) => {
          setCles(res.sort((a, b) => a?.id - b?.id).reverse());
        })
        .catch((e) => {
          console.log("e", e);
        });
    }
  }, [auth]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    if (cell?.user_type === "beneficiaire") {
      return (
        <div className="nameFormatter">
          <span className="name">
            {" "}
            {cell?.prenom} {cell?.nom}{" "}
          </span>{" "}
          <br />
          <span className="entreprise">
            {auth?.user?.user_type === "entreprise" ? "Employé" : "Participant"}
          </span>
        </div>
      );
    }

    if (cell?.user_type === "prestataire") {
      return (
        <div className="nameFormatter">
          <span className="name">
            {" "}
            {cell?.raison_sociale
              ? cell?.raison_sociale
              : cell?.prenom + " " + cell?.nom}
          </span>{" "}
          <br />
          <span className="entreprise">Prestataire</span>
        </div>
      );
    }

    if (cell?.user_type === "entreprise") {
      return (
        <div className="nameFormatter">
          <span className="name"> {cell?.raison_sociale}</span> <br />
          <span className="entreprise">Adhérent</span>
        </div>
      );
    }

    if (cell?.user_type === "ipm" || cell?.user_type === "assurance_sante") {
      return (
        <div className="nameFormatter">
          <span className="name"> {cell?.raison_sociale}</span> <br />
          <span className="entreprise">IPM</span>
        </div>
      );
    }
  };

  const dateFormater = (cell, row) => {
    return moment(cell).format("DD/MM/YYYY");
    // return cell
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button
          onClick={() => onGetItem(row)}
          className="action-btn show-btn"
          data-toggle="modal"
          data-target=".show-exception-modal"
          title="Details"
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
          />
        </button>
        <button
          className="action-btn edit-btn"
          onClick={() => onGetItem(row)}
          data-toggle="modal"
          data-target=".edit-exception-modal"
          title="Modification"
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
        {/* <button className="action-btn delete-btn">
              <IconlyPack.Delete set="light" primaryColor="#C8C8C8" className="action-btn-icon action-delete-icon"/>
          </button> */}
      </div>
    );
  };

  const onGetItem = (item) => {
    dispatch({ type: "EDIT_EXCEPTION", payload: item });
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <ExceptionSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={props?.exceptions}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={true}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="120"
                    dataField="type"
                    dataFormat={(cell, row) => {
                      if (cell === "entreprise") {
                        return "adhérent";
                      } else if (cell === "beneficiaire") {
                        return "participant";
                      } else {
                        return cell;
                      }
                    }}
                  >
                    Type
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="libelle"
                  >
                    Libelle
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // dataAlign="center"
                    // width="140"
                    dataField="cle"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Objet de l’opposition
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // dataAlign="center"
                    width="150"
                    dataField="date_debut"
                    dataFormat={(cell, row) => dateFormater(cell, row)}
                  >
                    Date de validité
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // dataAlign="center"
                    width="140"
                    dataField="date_fin"
                    dataFormat={(cell, row) => dateFormater(cell, row)}
                  >
                    Date de fin
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      padding: 15,
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal show-exception-modal"
            id="ShowModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="AddModalItemLabel"
            aria-hidden="true"
          >
            <ShowExceptionModal />
          </div>

          <div
            className="modal fade easypm-modal edit-exception-modal"
            id="ShowModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="AddModalItemLabel"
            aria-hidden="true"
          >
            <EditExceptionModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExceptionsTable;
