import React from "react";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import FeatherIcon from "feather-icons-react";
import BusinessIcon from "@material-ui/icons/Business";

var AdminNavBarContent = () => {
  //   React.useEffect(() => {
  //       sessionStorage.setItem('userType', 'admin')
  //   }, [])
  return (
    <>
      {/* <li className="nav-item nav-item-vertical-custom">
                <NavLink className="nav-link nav-link-vertival-custom" to="/admin/dashboard" activeClassName="actived">
                    <IconlyPack.Chart set="bold" primaryColor="white" className="icon-navbar-vertical"/>
                    <span className="hiddenable-navlink-label">Tableau de bord</span>
                </NavLink>
            </li>  */}

      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/admin/gestion-des-ipm"
          activeClassName="actived"
        >
          <IconlyPack.Document
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">IPMS / ASSUREURS</span>
        </NavLink>
      </li>
      {/* <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/activite-soin-assurance"
          activeClassName="actived"
        >
          <i
            className="fa fa-qrcode fa-lg icon-navbar-vertical"
            aria-hidden="true"
            style={{ color: "#ffffff", marginTop: "0.3rem" }}
          ></i>
          <span className="hiddenable-navlink-label">Assureurs</span>
        </NavLink>
      </li> */}
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/admin/gestion-des-etablissements-et-pharmacies"
          activeClassName="actived"
        >
          <IconlyPack.Work
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">
            Établissements & <br /> Pharmacies
          </span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/admin/gestion-des-familles-soins"
          activeClassName="actived"
        >
          <IconlyPack.Category
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Famille de soins</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/admin/gestion-des-specialites"
          activeClassName="actived"
        >
          <IconlyPack.MoreSquare
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Spécialités</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/admin/gestion-des-exclusions"
          activeClassName="actived"
        >
          <IconlyPack.PaperFail
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Exclusions</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/admin/conditions"
          activeClassName="actived"
        >
          <IconlyPack.Paper
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Conditions</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/admin/parametres"
          activeClassName="actived"
        >
          <IconlyPack.Setting
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Paramétres</span>
        </NavLink>
      </li>
      {/* <li className="nav-item nav-item-vertical-custom">
                <NavLink
                    className="nav-link nav-link-vertival-custom"
                    to="/activite-soin-assurance"
                    activeClassName="actived"
                >
                    <i
                        className="fa fa-qrcode fa-lg icon-navbar-vertical"
                        aria-hidden="true"
                        style={{ color: "#ffffff", marginTop: "0.3rem" }}
                    ></i>
                    <span className="hiddenable-navlink-label">Soins</span>
                </NavLink>
            </li>
           <li className="nav-item nav-item-vertical-custom">
                <NavLink
                    className="nav-link nav-link-vertival-custom"
                    to="/rapports-assurance"
                    activeClassName="actived"
                >
                    <FeatherIcon
                        strokeWidth="3"
                        className="icon-navbar-vertical"
                        icon="pie-chart"
                        style={{ color: "#ffffff" }}
                    />
                    <span className="hiddenable-navlink-label">Rapports</span>
                </NavLink>
            </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                    className="nav-link nav-link-vertival-custom"
                    to="/remboursement-assurance"
                    activeClassName="actived"
                >
                    <i
                        className="fa fa-credit-card fa-lg icon-navbar-vertical"
                        style={{ color: "#ffffff", marginTop: "0.3rem" }}
                        aria-hidden="true"
                    ></i>
                    <span className="hiddenable-navlink-label">Remboursements</span>
                </NavLink>
            </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                    className="nav-link nav-link-vertival-custom"
                    to="/adherent-assurance"
                    activeClassName="actived"
                >
                    <IconlyPack.People
                        set="bold"
                        primaryColor="white"
                        className="icon-navbar-vertical"
                    />
                    <span className="hiddenable-navlink-label">Adhérents</span>
                </NavLink>
            </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                    className="nav-link nav-link-vertival-custom"
                    to="/prise-en-charge-assurance"
                    activeClassName="actived"
                >
                    <IconlyPack.Category
                        set="bold"
                        primaryColor="white"
                        className="icon-navbar-vertical"
                    />
                    <span className="hiddenable-navlink-label">Prises en charge</span>
                </NavLink>
            </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                    className="nav-link nav-link-vertival-custom"
                    to="/prestataire-assurance"
                    activeClassName="actived"
                >
                    <IconlyPack.AddUser
                        set="bold"
                        primaryColor="white"
                        className="icon-navbar-vertical"
                    />
                    <span className="hiddenable-navlink-label">Prestataires</span>
                </NavLink>
            </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                    className="nav-link nav-link-vertival-custom"
                    to="/facturation-assurance"
                    activeClassName="actived"
                >
                    <IconlyPack.Wallet
                        set="bold"
                        primaryColor="white"
                        className="icon-navbar-vertical"
                    />
                    <span className="hiddenable-navlink-label">Facturation</span>
                </NavLink>
            </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                    className="nav-link nav-link-vertival-custom"
                    to="/entreprise-assurance"
                    activeClassName="actived"
                >
                    <BusinessIcon
                        className="icon-navbar-vertical"
                        style={{ color: "#ffffff" }}
                    />
                    <span className="hiddenable-navlink-label">Entreprises</span>
                </NavLink>
            </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink
                    className="nav-link nav-link-vertival-custom"
                    to="/devis-assurance"
                    activeClassName="actived"
                >
                    <IconlyPack.Activity
                        set="bold"
                        primaryColor="white"
                        className="icon-navbar-vertical"
                    />
                    <span className="hiddenable-navlink-label">Devis</span>
                </NavLink>
            </li> */}
    </>
  );
};

export default AdminNavBarContent;
