import React from "react";
import { toast } from "react-toastify";
import http, { ApiUrl } from "../../../Http/http/http";
import ImgProfil from "../../../images/others/pp.png";
import { getName, MyButton } from "../../../utils/utils";

var ReaffecterReservationModal = ({ item, data, getData, userId }) => {
  const [filtred, setFiltred] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const onSubmit = async (value) => {
    try {
      await http.put(`/rdv/${item.id}/`, { prestataire: value });
      getData();
      // toast.success('')
      window.$(`#ReaffecterReservationModal`).modal("hide");
    } catch (ex) {
      toast.error(ex.message);
    }
    setValue(null);
  };
  React.useEffect(() => {
    if (value) {
      onSubmit(value);
    }
  }, [value]);

  React.useEffect(() => {
    if (item) {
      setFiltred(
        data.filter(
          (f) =>
            f.id !== userId &&
            f.disponibilite &&
            JSON.parse(f.disponibilite)
              .map((d) => parseInt(d))
              .includes(parseInt(item.heure))
        )
      );
    }
  }, [item, data, userId]);
  return (
    <>
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Liste des prestataires disponibles à cette date
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {filtred.length === 0 && <span>Pas de prestataire disponible</span>}
            <div>
              <ul>
                {filtred.map((i) => (
                  <li key={i.id} className="list-reaffecter-modal">
                    <div className="d-flex align-items-center">
                      <div>
                        <img src={ApiUrl + i.avatar} alt="Image profil" />
                      </div>
                      <div className="pl-2">
                        <p className="titre-list-modal">{getName(i)}</p>
                        <span>{i.specialite?.nom}</span>
                      </div>
                      <div className="ml-auto">
                        <MyButton
                          loading={value === i.id}
                          title="Réaffecter"
                          onClick={() => setValue(i.id)}
                          className="btn crud-submit-btn-beneficiaire"
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReaffecterReservationModal;
