/* eslint-disable no-useless-concat */
import moment from "moment";
import React from "react";
import { getData, getDataByID } from "../utils/fetchData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import Select from "react-select";
import { DataContext } from "../store/GlobalState";
import { formatMontant } from "../../utils/utils";
import { useHistory } from "react-router-dom";

registerLocale("fr", fr);

const calcul = (cout_montant, taux, montant_plafond) => {
  let a_rembourser = (parseFloat(cout_montant) * taux) / 100;

  if (a_rembourser > montant_plafond) {
    a_rembourser = montant_plafond;
  }
  let a_retenir = parseFloat(cout_montant) - a_rembourser;
  return {
    retenu: a_retenir,
    rembourser: a_rembourser,
  };
};

const AddSoinModal = (props) => {
  const { state, dispatch } = React.useContext(DataContext);
  const { auth, list_famille_soins } = state;
  const history = useHistory();
  const [types, setTypes] = React.useState([]);
  const [clesFamily, setClesFamily] = React.useState([
    { value: "", label: "" },
  ]);
  const [clesType, setClesType] = React.useState([{ value: "", label: "" }]);
  const [valFamily, setValFamily] = React.useState("");
  const [valType, setValType] = React.useState("");

  const [token, setToken] = React.useState();
  const [patients, setPatients] = React.useState([]);
  var [startDate, setStartDate] = React.useState(new Date());
  const [prestation_types, setPrestation_types] = React.useState([
    { value: "", label: "" },
  ]);
  // eslint-disable-next-line
  const [prestation_type, setPrestation_type] = React.useState("");
  const [montant, setMontant] = React.useState(0);
  const [retenu, setRetenu] = React.useState(0);
  const [rembourser, setRembouser] = React.useState(0);
  const [type, setType] = React.useState("");
  const [adherent, setAdherent] = React.useState("");
  const [item_type, setItem_type] = React.useState({
    taux: "",
    montant: "",
  });
  var [selected, setSelected] = React.useState({});
  let userType = sessionStorage.getItem("userType");
  // const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (auth?.token) {
      getData("typedesoin/", auth.token)
        .then((response) => {
          setTypes(response.results.sort((a, b) => b?.id - a?.id));
        })
        .catch((error) => console.log("error", error?.response));
    }

    if (list_famille_soins?.length) {
      setClesFamily(
        list_famille_soins.map((b) => {
          return {
            label: b.nom,
            value: b.id,
          };
        })
      );
    }
  }, [auth, list_famille_soins]);

  React.useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    setToken(token);
    props.register("beneficiaire");
    props.register("date");
    props.setValue("date", moment(startDate).format("YYYY-MM-DD"));
  }, [token, props, startDate]);

  React.useEffect(() => {
    if (props?.adherent?.id) {
      // console.log("teste");
      setAdherent(props?.adherent?.id);
      let params = props?.adherent?.id;
      props.setValue("beneficiaire", params);
      setAdherent(params);
      // console.log("value", props?.adherent?.id);
      let benef = props.beneficiaires.filter((b) => b?.id === parseInt(params));
      if (params !== "null") {
        getDataByID("ayantdroitbybeneficiaire", params, token)
          .then((res) => {
            if (res?.length) {
              setPatients([...benef, ...res]);
            } else {
              setPatients(benef);
            }
            setSelected(benef[0]);
            console.log("patients=>", patients);
          })
          .catch((error) => console.log("error=>", error));
      } else {
        setPatients([]);
      }
    }
  }, [props?.adherent?.id, props.beneficiaires]);

  React.useEffect(() => {
    if (props?.isSuccess) {
      setPrestation_type("");
      setValFamily("");
      setValType("");
      setPrestation_types([]);
      setPatients([]);
      setRembouser(0);
      setRetenu(0);
      setItem_type({
        taux: "",
        montant: "",
      });
    }
  }, [props?.isSuccess]);

  const onSelect = (e) => {
    let params = e.target.value;
    props.setValue("beneficiaire", params);
    console.log("value", e.target.value);
    setAdherent(e.target.value);
    let benef = props.beneficiaires.filter((b) => b?.id === parseInt(params));
    if (params !== "null") {
      getDataByID("ayantdroitbybeneficiaire", params, token)
        .then((res) => {
          if (res?.length) {
            setPatients([...benef, ...res]);
          } else {
            setPatients(benef);
          }
          setSelected(benef[0]);
          console.log("patients=>", patients);
        })
        .catch((error) => console.log("error=>", error));
    } else {
      setPatients([]);
    }
  };

  const onChangeMontant = (e) => {
    props.setValue("montant", e.currentTarget.value);
    setMontant(e.currentTarget.value);
    if (type) {
      let montant_plafond = props.prestation_types.filter(
        (f) => f.id == type
      )[0].montant_plafond;
      // eslint-disable-next-line
      let taux_prise_en_charge = props.prestation_types.filter(
        (f) => f.id == type
      )[0].taux_prise_en_charge;
      setItem_type({
        montant: montant_plafond,
        taux: taux_prise_en_charge,
      });
      if (montant_plafond !== 0) {
        if (props.getValues("montant") !== "") {
          let montant = props.getValues("montant");
          console.log("calcul=>", montant);
          const _getCalcul = calcul(
            montant,
            taux_prise_en_charge,
            montant_plafond
          );
          setRembouser(Math.abs(_getCalcul.rembourser));
          setRetenu(Math.abs(_getCalcul.retenu));
          props.setValue("montant_rembourse", Math.abs(_getCalcul.rembourser));
          props.setValue("montant_retenu", Math.abs(_getCalcul.retenu));
        }
      }
    }
  };

  const onSelectAyant = (e) => {
    let current = e.target.value;
    // console.log('current=>', current)
    let benef = props.beneficiaires.filter((b) => b?.id === parseInt(current));

    if (benef?.length && benef[0]?.prenom === selected?.prenom) {
      console.log("benef");
    } else {
      props.register("ayant_droit");
      props.setValue("ayant_droit", current);
    }
    // props.setValue("ayant_droit", current);
  };

  const onSelectType = (e) => {
    let params = e.value;
    setPrestation_type(e);
    props.setValue("type_prestation", params);
    props.setValue("show_type", true);
    console.log("params", params, e);
    if (params && params !== undefined && params !== null) {
      setType(params);
      // eslint-disable-next-line
      let montant_plafond = props.prestation_types.filter(
        (f) => f.id === params
      )[0]?.montant_plafond;
      // eslint-disable-next-line
      let taux_prise_en_charge = props.prestation_types.filter(
        (f) => f.id === params
      )[0]?.taux_prise_en_charge;
      setItem_type({
        montant: montant_plafond,
        taux: taux_prise_en_charge,
      });
      if (montant_plafond !== 0 && montant_plafond !== null) {
        if (props.getValues("montant") !== "") {
          let montant = props.getValues("montant");

          if (montant) {
            const _getCalcul = calcul(
              montant,
              taux_prise_en_charge,
              montant_plafond
            );
            setRembouser(Math.abs(_getCalcul.rembourser));
            setRetenu(Math.abs(_getCalcul.retenu));
            props.setValue(
              "montant_rembourse",
              Math.abs(_getCalcul.rembourser)
            );
            props.setValue("montant_retenu", Math.abs(_getCalcul.retenu));
          }
          // console.log("calcul=>", calcul(montant, item_type.taux))
        }
      }
    } else {
      setItem_type({ montant: 0, taux: 0 });
      setRembouser(0);
      setRetenu(0);
    }
  };

  const handleDate = (date) => {
    setStartDate(date);
    props.register("date");
    props.setValue("date", moment(date).format("YYYY-MM-DD"));
  };

  const handleFile = (e) => {
    console.log(e.target.files[0]);
    props.register("documents_justif");
    props.setValue("documents_justif", e.target.files[0]);
  };

  const handleSelectType = (e) => {
    console.log(e.value);
    setValType(e);
    // setPrestation_types(
    //   props?.prestation_types?.filter((t) => t?.typedesoin?.id === e.value)
    // );
    let types = props?.prestation_types?.filter(
      (t) => t?.typedesoin?.id === e.value
    );

    console.log("types", types);

    if (types?.length) {
      if (types?.length > 1) {
        setPrestation_types(
          types.map((p) => {
            return {
              label:
                p?.libelle + " " + "(" + p?.taux_prise_en_charge + "%" + ")",
              value: p?.id,
            };
          })
        );
        props.setValue("show_type", true);
      } else {
        props.setValue("type_prestation", types[0]?.id);
        handleMont(types[0]?.id);
        props.setValue("show_type", false);
      }
    } else {
      handleMont(types[0]?.id);
      setPrestation_types([]);
      // props.setValue("type_prestation", "");
      props.setValue("show_type", false);
    }
    // let type = props?.prestation_types?.filter(
    //   (t) => t?.typedesoin?.id === e.value
    // );
    // if (type?.length) {
    //   onSelectType(type[0]?.id);
    // } else {
    //   onSelectType();
    // }

    props.register("typedesoin");
    props.setValue("typedesoin", e.value);
  };

  const handleMont = (id) => {
    if (id && id !== undefined && id !== null) {
      setType(id);
      // eslint-disable-next-line
      let montant_plafond = props.prestation_types.filter((f) => f.id === id)[0]
        ?.montant_plafond;
      // eslint-disable-next-line
      let taux_prise_en_charge = props.prestation_types.filter(
        (f) => f.id === id
      )[0]?.taux_prise_en_charge;
      setItem_type({
        montant: montant_plafond,
        taux: taux_prise_en_charge,
      });
      if (montant_plafond !== 0 && montant_plafond !== null) {
        if (props.getValues("montant") !== "") {
          let montant = props.getValues("montant");

          if (montant) {
            const _getCalcul = calcul(
              montant,
              taux_prise_en_charge,
              montant_plafond
            );
            setRembouser(Math.abs(_getCalcul.rembourser));
            setRetenu(Math.abs(_getCalcul.retenu));
            props.setValue(
              "montant_rembourse",
              Math.abs(_getCalcul.rembourser)
            );
            props.setValue("montant_retenu", Math.abs(_getCalcul.retenu));
          }
          // console.log("calcul=>", calcul(montant, item_type.taux))
        }
      }
    } else {
      setItem_type({ montant: 0, taux: 0 });
      setRembouser(0);
      setRetenu(0);
    }
  };

  const onSelectFamily = (e) => {
    // console.log(e.value, types);
    setValFamily(e);

    getDataByID("typedesoinbyfamille", e?.value, auth?.token)
      .then((response) => {
        console.log(response.results, "test");
        if (response.results?.length) {
          setClesType(
            response.results.map((b) => {
              return {
                label: b.nom,
                value: b.id,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
    props.register("familledesoin");
    props.setValue("familledesoin", e.value);
  };

  const handleReset = async () => {
    props.reset();
    setAdherent("");
    setValFamily("");
    setValType("");
    setPrestation_type("");
    setPrestation_types([]);
    setPatients([]);
    setRembouser(0);
    setRetenu(0);
    setItem_type({
      taux: "",
      montant: "",
    });
    history.push("/ipm/gestion-des-remboursements");
  };

  const onSelectIpm = (e) => {
    console.log(e.target.value);
    props.register("assurance");
    props.setValue("assurance", e.target.value);
    getDataByID("prestatairebyassurance", e.target.value, token)
      .then((res) => {
        dispatch({
          type: "PRESTATAIRE_BY_IPM",
          payload: res.results.reverse(),
        });
      })
      .catch((e) => {
        console.log("e", e);
      });

    getDataByID("typeprestationbyassurance", e.target.value, token)
      .then((res) => {
        dispatch({
          type: "ADD_PRESTATION_TYPE",
          payload: res.reverse(),
        });
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Remboursement
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={props.onSubmit}>
              <div className="row crud-row">
                {userType === "entreprise" ? (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="first_name">Assurance</label>
                      <div className="filter-item-container">
                        <select
                          onChange={(e) => onSelectIpm(e)}
                          // {...props.register('beneficiaire')}
                          name="assurance"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                        >
                          <option hidden value="">
                            Assurance
                          </option>
                          {props.ipms.map((ipm) => (
                            <option key={ipm.id} value={ipm.id}>
                              {`${ipm.raison_sociale}`}
                            </option>
                          ))}
                        </select>
                        {props.formState.errors &&
                          props.formState.errors.assurance && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {props.formState.errors.assurance?.message}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <input
                    type="hidden"
                    name="assurance"
                    {...props.register("assurance")}
                    value={props?.assurance_maladie}
                  />
                )}

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Participant</label>
                    <div className="filter-item-container">
                      <select
                        onChange={(e) => onSelect(e)}
                        // {...props.register('beneficiaire')}
                        value={adherent}
                        name="beneficiaire"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Participant
                        </option>
                        {props.beneficiaires
                          .filter((b) => b?.is_active === true)
                          .map((benef) => (
                            <option key={benef.id} value={benef.id}>
                              {`${benef.prenom} ${benef.nom}`}
                            </option>
                          ))}
                      </select>
                      {props.formState.errors &&
                        props.formState.errors.beneficiaire && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.beneficiaire?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Bénéficiaire</label>
                    <div className="filter-item-container">
                      <select
                        onChange={(e) => {
                          // props.register('ayant_droit')
                          onSelectAyant(e);
                        }}
                        name="ayant_droit"
                        // name="prestataires"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir un Bénéficiaire
                        </option>
                        {patients
                          .filter((b) => b?.is_active === true)
                          .map((p) => {
                            let name = `${p.prenom} ${p.nom}`;
                            return (
                              <option key={p.id} value={p.id}>
                                {name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Famille de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner la famille de soins"
                        name="familledesoin"
                        value={valFamily}
                        onChange={(e) => onSelectFamily(e)}
                        options={clesFamily}
                      />
                      {props.formState.errors &&
                        props.formState.errors.familledesoin && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.familledesoin?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="cle">Type de soins</label>
                    <div className="filter-item-container">
                      <Select
                        placeholder="Selectionner le type de soins"
                        name="typedesoin"
                        value={valType}
                        onChange={(e) => handleSelectType(e)}
                        options={clesType}
                      />
                      {props.formState.errors &&
                        props.formState.errors.typedesoin && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.typedesoin?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {/* Type de prestation */}
                {prestation_types?.length && prestation_types?.length > 1 ? (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="cle">Type de prestation</label>
                      <div className="filter-item-container">
                        <Select
                          placeholder="Selectionner le type de prestation"
                          name="type_prestation"
                          value={prestation_type}
                          onChange={(e) => onSelectType(e)}
                          options={prestation_types}
                        />
                        {props.formState.errors &&
                          props.formState.errors.type_prestation && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {props.formState.errors.type_prestation?.message}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* END */}
                <div className="col-md-6 crud-col-item">
                  <div className="crud-form-interm">
                    <div className="form-group crud-form-group required crud-interm">
                      <label htmlFor="date">Date</label>
                      <DatePicker
                        className="form-control crud-form-control"
                        selected={startDate}
                        placeholderText="Sélectionner une date"
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="top"
                        peekNextMonth
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        name="date"
                        locale="fr"
                        onChange={(date) => handleDate(date)}
                      />
                      {props.formState.errors &&
                        props.formState.errors.date && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-3">
                            {props.formState.errors.date?.message}
                          </div>
                        )}
                    </div>
                    <div className="form-group crud-form-group required crud-interm">
                      <label htmlFor="cout">Montant de la dépense</label>
                      <input
                        type="number"
                        placeholder="Montant de la dépense en FCFA"
                        min="1"
                        name="montant"
                        className="form-control crud-form-control"
                        id="cout"
                        onChange={(e) => onChangeMontant(e)}
                        // {...props.register('montant', {
                        //   required: true,
                        //   maxLength: 30,
                        // })}
                      />
                      {/* <span className="customy-label">FCFA</span> */}
                      {props.formState.errors &&
                        props.formState.errors.montant && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-3">
                            {props.formState.errors.montant?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="crud-form-interm">
                    <div className="form-group crud-form-group crud-interm">
                      <label htmlFor="a_rembourser">Part IPM</label>
                      <input
                        type="text"
                        readOnly
                        placeholder="15000"
                        value={formatMontant(rembourser)}
                        name="montant_rembourse"
                        className="form-control crud-form-control"
                        id="a_rembourser"
                      />
                      <span className="customy-label">FCFA</span>
                    </div>
                    <div className="form-group crud-form-group crud-interm">
                      <label htmlFor="cout">Part Participant</label>
                      <input
                        type="text"
                        readOnly
                        value={formatMontant(retenu)}
                        placeholder="5000"
                        name="montant_retenu"
                        className="form-control crud-form-control"
                        id="a_retenir"
                      />
                      <span className="customy-label">FCFA</span>
                      {props.formState.errors &&
                        props.formState.errors.a_retenir && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-3">
                            {props.formState.errors.a_retenir?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="fournisseur">Fournisseur</label>
                    <div className="filter-item-container">
                      <select
                        {...props.register("prestataire")}
                        name="prestataire"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                      >
                        <option hidden value="">
                          Choisir un fournisseur
                        </option>
                        {props.prestataires.map((prest) => {
                          return (
                            <option key={prest.id} value={prest.id}>
                              {prest.raison_sociale ||
                                prest.prenom + " " + prest.nom}
                            </option>
                          );
                        })}
                      </select>
                      {props.formState.errors &&
                        props.formState.errors.prestataire && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {props.formState.errors.prestataire?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-6 crud-col-item"
                  style={{ marginBottom: "15px" }}
                >
                  <div className="form-group crud-form-group custom-file required">
                    <label htmlFor="file">Pièce justificatif</label>
                    <input
                      type="file"
                      placeholder="Importer un document"
                      name="documents_justif"
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      id="file"
                      onChange={handleFile}
                      // {...props.register("documents_justif")}
                    />

                    {props.formState.errors &&
                      props.formState.errors.documents_justif && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {props.formState.errors.documents_justif?.message}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item pricing-cruded">
                  <div className="taux-ipm-container">
                    <div className="taux-ipm-label">Taux IPM : </div>
                    <div className="taux-ipm-value">{item_type.taux}%</div>
                  </div>
                  <div className="plafond-ipm-container">
                    <div className="plafond-ipm-label">Montant Plafond : </div>
                    <div className="plafond-ipm-value">
                      {formatMontant(item_type.montant)} FCFA
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!props.isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSoinModal;
