import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import Specialite from "../../../Services/Specialite";
import { DataContext } from "../../../store/GlobalState";
import { getData } from "../../../utils/fetchData";

function UseEditSpecialiteForm() {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [ipmData, setIpmData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        nom: yup.string().required("Le nom est requis."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    console.log("data", formValues);
    var dataRequest = Specialite.putSpecialite(
      formValues?.id,
      formValues,
      requestConfig
    );
    await dataRequest
      .then((response) => {
        var ipmInfos = response;
        console.log("ipm", ipmInfos);
        getData("specialite/", auth.token).then((response) => {
          dispatch({
            type: "LIST_SPECIALITES",
            payload: response.results.sort((a, b) => b?.id - a?.id),
          });
        });
        e.target.reset();
        setIsSuccessfullySubmitted(false);
        window.$(".edit-specialite-modal").modal("hide");
        dispatch({
          type: "SPECIALITE",
          payload: {},
        });
        toast.success("Specialite modifiée avec succès.");
        reset();
      })
      .catch((error) => {
        console.log(error?.response);
        setIsFormRequestError(true);
        setIsSuccessfullySubmitted(false);
        if (
          error?.response?.data?.nom &&
          error?.response?.data?.nom[0] ===
            "specialite with this nom already exists."
        ) {
          toast.error("Oupsss! Cette spécialité existe déja.", {
            position: "top-right",
            autoClose: 10000,
          });
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        } else {
          toast.error(
            "Une erreur est survenue, veuillez vérifier votre connexion internet !"
          );
        }
      });
  };
  return {
    register,
    errors,
    formState,
    ipmData,
    setIpmData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitCreateForm),
    reset,
    setValue,
  };
}

export default UseEditSpecialiteForm;
