import { condition } from "./Endpoints";
import axios from "axios";

export default {
  async getListConditions(config) {
    const response = await axios.get(condition.onGetOrPostCondition(), config);
    // console.log(response)
    return response.data;
  },
  async postCreateCondition(data, config) {
    const response = await axios.post(
      condition.onGetOrPostCondition(),
      data,
      config
    );
    return response.data;
  },
  async putCondition(id, data, config) {
    const response = await axios.put(
      condition.onPutCondition(id),
      data,
      config
    );
    return response.data;
  },
  async deleteCondition(id, config) {
    const response = await axios.delete(
      condition.onDeleteCondition(id),
      config
    );
    return response;
  },
  async getCGU() {
    const response = await axios.get(condition.onGetCgu());
    // console.log(response)
    return response.data;
  },
  async getPC() {
    const response = await axios.get(condition.onGetPc());
    // console.log(response)
    return response.data;
  },
};
