import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import EmptyHeader from "./EmptyHeader";
import { userRegister } from "../Services/Endpoints";
import { useHistory } from "react-router";
import { DataContext } from "../store/GlobalState";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Inscription() {
  const { register, handleSubmit, formState, watch, setValue, clearErrors } =
    useForm();

  const history = useHistory();

  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;

  const [phone, setPhone] = useState("");

  const [registerInProgress, setRegisterInProgress] = useState(false);
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);

  useEffect(() => {
    register("password", {
      required: {
        value: true,
        message: "Ce champ est obligatoire.",
      },
      pattern: {
        value: new RegExp(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
          // "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
        message:
          "Votre mot de passe doit contenir au moins 8 caracteres, un majuscule, un minuscule et un caractère spécial.",
      },
    });
    register("password");
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, clearErrors, register]);

  useEffect(() => {
    if (
      (auth.token || window.sessionStorage.getItem("authToken")) &&
      (window.sessionStorage.getItem("userType") === "assurance_sante" ||
        window.sessionStorage.getItem("userType") === "ipm" ||
        window.sessionStorage.getItem("userType") === "admin_ipm")
    ) {
      history.push("/ipm/dashboard");
    }

    if (
      (auth.token || window.sessionStorage.getItem("authToken")) &&
      window.sessionStorage.getItem("userType") === "entreprise"
    ) {
      history.push("/entreprise/dashboard");
    }

    if (
      (auth.token || window.sessionStorage.getItem("authToken")) &&
      window.sessionStorage.getItem("userType") === "prestataire"
    ) {
      history.push("/prestataire/dashboard");
    }
  }, [auth.token]);

  const onSubmit = async (data) => {
    setRegisterInProgress(true);

    data["user_type"] = data.specialite;

    await userRegister(data).then(() => {
      setRegisterInProgress(false);
    });
  };

  const countUppercase = (str) => {
    if (str.replace(/[^A-Z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str) => {
    if (str.replace(/[^a-z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str) => {
    if (str.replace(/[^0-9]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str) => {
    if (str.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str) => {
    const punct = "!,;.-?@";
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e) => {
    var password = e.target.value;
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("password", e.target.value);
  };

  const handleSetPhone = (val) => {
    console.log("phone", val);
    setPhone(val);
    register("telephone", {
      required: {
        value: true,
        message: "Ce champ est obligatoire.",
      },
      pattern: {
        value: new RegExp(/^((\+)221|0)[1-9](\d{2}){4}$/),
        message: "Veuillez vérifier le format du numéro de téléphone",
      },
    });
    setValue("telephone", "+" + val);
  };

  return (
    <>
      <EmptyHeader />
      <div className="container login-page-container">
        <div className="row login-page-row">
          <div className="col-md-6 left-side-col"></div>

          <div className="col-md-6 right-side-col">
            <div className="login-form-container">
              <div className="login-form-introduction">
                <h1 className="login-title-label">Nous rejoindre</h1>
              </div>
              <form
                autoComplete="off"
                id="login-form"
                className="register-real-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="input-group my-2">
                  <label htmlFor="nom_structure">Nom de la structure</label>
                  <input
                    name="raison_sociale"
                    id="nom_structure"
                    {...register("raison_sociale", {
                      required: {
                        value: true,
                        message: "Ce champ est obligatoire.",
                      },
                    })}
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Nom de la structure"
                  />
                  {formState.errors.raison_sociale && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.raison_sociale.message}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <label htmlFor="adresse">Adresse</label>
                  <input
                    name="adresse"
                    id="asdresse"
                    {...register("adresse", {
                      required: {
                        value: true,
                        message: "Ce champ est obligatoire.",
                      },
                    })}
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Adresse de la structure"
                  />
                  {formState.errors.adresse && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.adresse.message}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <label htmlFor="phone">Téléphone</label>

                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    inputClass="form-control input-with-icon"
                    country={"sn"}
                    onlyCountries={["sn"]}
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    disableDropdown={true}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    countryCodeEditable={false}
                    onChange={(phone) => handleSetPhone(phone)}
                    // inputStyle={{
                    //   width: "100%",
                    //   paddingBottom: "22px",
                    //   borderRadius: "10px",
                    // }}
                    buttonClass="drop-flag"
                  />
                  {formState.errors.telephone && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.telephone.message}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <label htmlFor="email">Adresse email</label>
                  <input
                    name="email"
                    id="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Ce champ est obligatoire.",
                      },
                      pattern: {
                        value: new RegExp(
                          // /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                          /^(?<user>[a-zA-Z0-9._%+'-]+)@(?<domain>[a-zA-Z0-9.'-]+\.[a-zA-Z]{2,4})$/
                        ),
                        message: "Votre email n'est pas valide",
                      },
                    })}
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Ajouter votre adresse email"
                  />
                  {formState.errors.email && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.email.message}{" "}
                    </div>
                  )}
                </div>
                <div className="filter-item-container input-group my-2">
                  <label htmlFor="specialite">Spécialité</label>
                  <select
                    name="specialite"
                    {...register("specialite", {
                      required: {
                        value: true,
                        message: "Ce champ est obligatoire.",
                      },
                    })}
                    className="form-select form-select-line-chart filter-select-item"
                    aria-label="Default select example"
                  >
                    <option hidden value="">
                      Choisir une spécialité
                    </option>
                    <option value="ipm">IPM</option>
                    <option value="assureur">ASSURANCE SANTÉ</option>
                  </select>
                  {formState.errors.specialite && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.specialite.message}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <label htmlFor="ninea">NINEA</label>
                  <input
                    name="ninea"
                    id="ninea"
                    maxlength={12}
                    {...register("ninea", {
                      required: {
                        value: true,
                        message: "Ce champ est obligatoire.",
                      },
                      pattern: {
                        value: new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/),
                        message:
                          "Le NINEA doit être de format alpha-numérique.",
                      },
                      length: {
                        value: 12,
                        message: "Le ninea doit avoir au maximum 12 caractère",
                      },
                    })}
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Ajouter votre NINEA"
                  />
                  {formState.errors.ninea && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.ninea.message}{" "}
                    </div>
                  )}
                </div>

                <div className="input-group my-2">
                  <label htmlFor="password">Mot de passe</label>
                  <input
                    // autoComplete="new-password"
                    autoComplete="chrome-off"
                    name="password"
                    id="password"
                    onChange={(e) => passwordHandleChange(e)}
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Ajouter votre mot de passe"
                  />
                  {formState.errors.password && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.password.message}{" "}
                    </div>
                  )}
                </div>

                <div className="input-group my-2 password-validation-terms-row">
                  <div className="password-validation-terms-container">
                    <h4 className="password-validation-terms-title">
                      Votre mot de passe doit contenir :{" "}
                    </h4>
                    <ul className="password-validation-terms-ul">
                      <li
                        className={
                          "min-character-count pvt-li " +
                          (haveMinCharacter && "min-character-count-ok")
                        }
                      >
                        Au minimum 8 caractères
                      </li>
                      <li
                        className={
                          "min-character-uppercase pvt-li " +
                          (haveMinUppercase && "min-character-uppercase-ok")
                        }
                      >
                        Au minimum 1 caractère en majuscule
                      </li>
                      <li
                        className={
                          "min-character-lowercase pvt-li " +
                          (haveMinLowercase && "min-character-lowercase-ok")
                        }
                      >
                        Au minimum 1 caractère en minuscule
                      </li>
                      <li
                        className={
                          "min-character-number pvt-li " +
                          (haveMinNumber && "min-character-number-ok")
                        }
                      >
                        Au minimum 1 nombre
                      </li>
                      <li
                        className={
                          "min-character-special pvt-li " +
                          (haveMinSpecialCharacter &&
                            "min-character-special-ok")
                        }
                      >
                        Au minimum 1 caractère spéciale
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="input-group my-2">
                  <label htmlFor="password_repeat">
                    Confirmation du mot de passe
                  </label>
                  <input
                    name="password_repeat"
                    id="password_repeat"
                    {...register("password_repeat", {
                      required: {
                        value: true,
                        message: "Ce champ est obligatoire.",
                      },
                      validate: (value) => {
                        return (
                          value === watch("password") ||
                          "Veuillez entrer deux mots de passe identiques."
                        );
                      },
                    })}
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Confirmer votre mot de passe"
                  />
                  {formState.errors.password_repeat && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.password_repeat.message}{" "}
                    </div>
                  )}
                </div>

                <div className="row submit-btn-row">
                  <div className="col-md-8 offset-md-4 submit-btn-col m-t-50">
                    {!registerInProgress ? (
                      <button type="submit" className="btn-connexion">
                        INSCRIPTION
                      </button>
                    ) : (
                      <button className="btn-connexion in-progress-btn">
                        INSCRIPTION EN COURS &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
