import React, { useEffect, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import GestionSoinAssureurModal from "../../../Backend/Assurance/GestionSoinsAssureur/GestionSoinAssureur";
import GestionEntrepriseTableData from "./GestionEntrepriseTableData";
import GestionEntrepriseTableSkeleton from "./GestionEntrepriseTableSkeleton";
// import FactureModalAssureur from './FactureModalAssureur'
// import UseEditEntrepriseForm from '../../Backend/IPM/GestionEntreprises/UseEditEntrepriseForm'
// import CRUDEntrepriseModal from './../../Modals/CRUDEntrepriseModal'
import FeatherIcon from "feather-icons-react";
import RenvoiPasswordEntreprise from "./RenvoiPasswordEntreprise";
import { DataContext } from "../../../store/GlobalState";
import EntrepriseEdit from "./EntrepriseEdit";
import EntrepriseDetails from "./EntrepriseDetails";
// import GestionFicheDeSoin from './GestionFicheDeSoin'

var GestionEntrepriseTable = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { assureur_entreprises } = state;
  const [soins, setSoins] = React.useState(GestionEntrepriseTableData);

  var [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, [soins]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const TiersFormatter = (cell, row) => {
    if (cell === true) {
      return (
        <span className="badge badge-success easypm-badge-success "> OUI </span>
      );
    } else {
      return (
        <span className="badge badge-danger easypm-badge-danger"> NON </span>
      );
    }
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <button className="action-btn edit-btn" title="Détails">
          <IconlyPack.Show
            onClick={() => getItem(row)}
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#DetailsEntreprise"
          />
        </button>
        <button className="action-btn edit-btn" title="Modifier">
          <IconlyPack.Edit
            onClick={() => getItem(row)}
            set="bold"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#ModifierEntreprise"
          />
        </button>
        {/* <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Message
            onClick={() => getItem(row)}
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#factureAssureur"
          />
        </button> */}
        <button className="action-btn edit-btn" title="Renvoyer mot de passe">
          <IconlyPack.Password
            onClick={() => getItem(row)}
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#RenvoiPasswordEntreprise"
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    console.log("item", item);
    dispatch({
      type: "EDIT_ENTREPRISE",
      payload: item,
    });
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {assureur_entreprises?.isLoading && (
              <GestionEntrepriseTableSkeleton />
            )}
            {!assureur_entreprises?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={assureur_entreprises?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="170"
                    dataField="raison_sociale"
                    filter={{
                      type: "TextFilter",
                      defaultValue: "",
                    }}
                  >
                    Raison sociale
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="ninea"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    Ninea
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="email"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    Email
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="telephone"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    Téléphone
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="140"
                    dataField="tiers_payant"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataAlign="center"
                    dataFormat={TiersFormatter}
                  >
                    Tiers Payant Intégral
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="GestionSoinModal"
            tabIndex="-1"
            aria-labelledby="GestionSoinModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <GestionSoinAssureurModal />
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="RenvoiPasswordEntreprise"
            tabIndex="-1"
            aria-labelledby="RenvoiPasswordEntrepriseLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <RenvoiPasswordEntreprise />
          </div>

          <div
            className="modal fade easypm-modal add-soin-modal"
            id="ModifierEntreprise"
            tabIndex="-1"
            aria-labelledby="ModifierEntrepriseLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <EntrepriseEdit />
          </div>
          <div
            className="modal fade easypm-modal add-soin-modal"
            id="DetailsEntreprise"
            tabIndex="-1"
            aria-labelledby="DetailsEntrepriseLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <EntrepriseDetails />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionEntrepriseTable;
