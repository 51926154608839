import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
// import AjouterUneBaseDeDonnee from "../../../Tables/Assurance/BaseDeDonneeTable/AjouterUneBaseDeDonnee";
import Img1 from '../../../../images/others/doctor.png'
import Img2 from '../../../../images/others/pharmacy.png'
import Img3 from '../../../../images/others/hospital.png'
import Img4 from '../../../../images/others/monitor.png'
import  './BaseDeDonnee.css'
import { NavLink } from "react-router-dom";

var BaseDeDonneeBeneficiaire = () => {
 

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2  />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                    Base de données
                    </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                 <div className="row">
                     <div className="col-xl-6 col-lg-6 col-md-6 pb-3"> 
                        <NavLink to="/liste-des-specialites-beneficiaire" className="no-link">
                            <div className="bloc-base-de-donnee">
                                <img src={Img1} alt="Image base de donnee" />
                            </div>
                        </NavLink>
                        
                     </div>
                     <div className="col-xl-6 col-lg-6 col-md-6 pb-3"> 
                        <NavLink to="/liste-des-pharmacies-beneficiaire" className="no-link">
                            <div className="bloc-base-de-donnee">
                                <img src={Img2} alt="Image base de donnee" />
                            </div>
                        </NavLink>
                     </div>
                     <div className="col-xl-6 col-lg-6 col-md-6 pb-3"> 
                        <NavLink to="/liste-des-etablissement-sante-beneficiaire" className="no-link">
                            <div className="bloc-base-de-donnee">
                                <img src={Img3} alt="Image base de donnee" />
                            </div>
                        </NavLink>
                     </div>
                     <div className="col-xl-6 col-lg-6 col-md-6 pb-3"> 
                        <NavLink to="/info-utile-beneficiaire" className="no-link">
                            <div className="bloc-base-de-donnee">
                                <img src={Img4} alt="Image base de donnee" />
                            </div>
                        </NavLink>
                     </div>
                
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseDeDonneeBeneficiaire;
