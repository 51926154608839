/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import { formatMoney, formatMontant } from "../../../utils/utils";
import { getDataByID } from "../../utils/fetchData";

function TopIpmsTable({ auth }) {
  const [topIpms, setTopIpms] = useState([]);
  var [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    if (auth?.user?.id && auth?.user?.user_type === "prestataire") {
      getDataByID(`top10ipmbyprestataire`, auth?.user?.id, auth?.token).then(
        (response) => {
          console.log("response", response);
          setTopIpms(response);
          setIsPageLoading(false);
        }
      );
    }
  }, [auth]);

  const adhFormat = (cell, row) => {
    return row?.client?.prenom + " " + row?.client?.nom;
  };

  const adhIDFormat = (cell, row) => {
    return cell?.id;
  };

  const ipmFormat = (cell, row) => {
    return cell?.raison_sociale;
  };

  const montantFormat = (cell, row) => {
    return formatMontant(cell);
  };

  const options = {
    clearSearch: true,
    noDataText: "Aucune donnée disponible",
    expandBy: "column",
  };

  return (
    <div className="chart-item-container">
      <div className="row mb-2">
        <div className="col-xl-12 col-lg-12">
          <h2 className="chart-item-title">
            Top 10 des montants facturés aux ipms{" "}
          </h2>
        </div>
      </div>
      <div className="table-responsive">
        {/* {isPageLoading && <TopTableSkeleton />} */}
        {!isPageLoading && (
          <BootstrapTable
            data={topIpms}
            striped={true}
            hover={true}
            condensed={true}
            multiColumnSort={2}
            options={options}
            pagination
            search={false}
            version="4"
            bordered={false}
          >
            <TableHeaderColumn
              thStyle={{
                fontWeight: 600,
                fontSize: 16,
                color: "#1F497D",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
              }}
              dataField="client"
              isKey={true}
              hidden={false}
              expandable={false}
              width="140"
              dataFormat={adhIDFormat}
            >
              N° Adhérent
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{
                fontWeight: 600,
                fontSize: 16,
                color: "#1F497D",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
              }}
              dataFormat={adhFormat}
              dataField="montant_adherent"
            >
              Adhérents
            </TableHeaderColumn>

            <TableHeaderColumn
              thStyle={{
                fontWeight: 600,
                fontSize: 16,
                color: "#1F497D",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
              }}
              dataFormat={ipmFormat}
              dataField="ipm"
            >
              Ipm
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{
                fontWeight: 600,
                fontSize: 16,
                color: "#1F497D",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
              }}
              width="200"
              dataAlign="right"
              dataFormat={montantFormat}
              dataField="montant"
            >
              Montant (F CFA)
            </TableHeaderColumn>
          </BootstrapTable>
        )}
      </div>
    </div>
  );
}

export default TopIpmsTable;
