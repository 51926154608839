import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getName,
  isPharmacien,
  Select,
  useFormData,
  MyDatePicker,
  useFormValidation,
  MyInput,
  MyButton,
} from "../../../../utils/utils";
import { useMutation } from "../../../../Http/http/mutation";
import {
  fichedesoin,
  editfichedesoin,
  create_prestationBeneficiaire,
} from "../../../../Http/http/api";

const AddRemboursementModal = ({
  prestataires,
  ayant_droits,
  familledesoins,
  user,
  onAdd,
  item,
}) => {
  const [data, onChange] = useFormData({
    prestataire: "",
    beneficiaire: "",
    libelle: "",
    familledesoin: "",
    typedesoin: "",
    date: "",
    montant: "",
    file: "",
  });

  const [errors, validate] = useFormValidation({}, (Yup) => ({
    prestataire: Yup.string().required().label("Prestataire"),
    montant: Yup.string().required().label("Montant de la dépense"),
    libelle: Yup.string().label("Libellé"),
    familledesoin: Yup.string().required().label("Famille de soins"),
    typedesoin: Yup.string().required().label("Type de soins"),
    file: Yup.string().required().label("Piece"),
    date: Yup.string().required().label("Date"),
    beneficiaire: Yup.string()
      .label("Beneficiaire")
      .test(
        "beneficiaire",
        "Beneficiaire est un champ obligatoire",
        function (value) {
          return value !== "";
        }
      ),
  }));

  const [loading, setLoading] = React.useState(false);
  const [typedesoins, setTypedesoins] = useState([]);
  React.useEffect(() => {
    if (data.familledesoin) {
      const obj = familledesoins.find((i) => i.id == data.familledesoin);
      if (obj) {
        setTypedesoins(
          obj.typedesoin.map((i) => ({
            value: i.id,
            label: i.nom,
          }))
        );
      }
    }
  }, [data.familledesoin, familledesoins]);

  const onSubmit = React.useCallback(async () => {
    validate(data)
      .then(async () => {
        setLoading(true);
        try {
          const status = await window.confirm("Voulez-vous vraiment continuer");
          if (status) {
            const url = `/remboursementbybeneficiaire/?prestataire=${
              data.prestataire
            }&beneficiaire=${user.id}&ayant_droit=${
              data.beneficiaire !== "0" ? data.beneficiaire : ""
            }&familledesoin=${data.familledesoin}&typedesoin=${
              data.typedesoin
            }`;
            const remboursement = {
              date: moment(data.date).format("YYYY-MM-DD"),
              document: data.file,
              montant: data.montant,
              familledesoin: data.familledesoin,
              typedesoin: data.typedesoin,
              libelle: data.libelle,
            };
            await create_prestationBeneficiaire(url, remboursement);
            toast.success(
              "Votre demande a été prise en compte. Vous allez recevoir la confirmation par email"
            );
            window.$("#DemandeRemboursementBeneficiaire").modal("hide");
            reset();
            onAdd();
          }
        } catch (err) {
          toast.error(`Error: ${err?.message}`);
          console.log("err response", err.response);
          console.log("err", err);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((ex) => {});
  });
  const reset = () => {
    onChange({
      prestataire: "",
      beneficiaire: "",
      libelle: "",
      familledesoin: "",
      typedesoin: "",
      date: "",
      montant: "",
      file: "",
    });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="DemandeFicheSoinsModal"
          >
            {item ? "Modifier" : "Demander"} une fiche de soins
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => {
              onAdd(false);
              reset();
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <Select
                    name="beneficiaire"
                    error={errors.beneficiaire}
                    value={data.beneficiaire}
                    onChange={(v) => onChange("beneficiaire", v)}
                    label="Bénéficiaires"
                    placeholder="Choisir"
                    items={[
                      {
                        label: `${getName(user)} (Vous)`,
                        value: "0",
                      },
                      ...ayant_droits
                        .filter((i) => i.is_active === true)
                        .map((i) => ({
                          label: getName(i),
                          value: String(i.id),
                        })),
                    ]}
                  />
                </div>
                <div className="col-md-6 crud-col-item">
                  <Select
                    name="prestataire"
                    value={data.prestataire}
                    error={errors.prestataire}
                    onChange={(v) => onChange("prestataire", v)}
                    label="Prestataires"
                    placeholder="Choisir un prestataire"
                    items={prestataires
                      .filter((i) => i.agreement)
                      .map((i) => ({
                        label: getName(i),
                        value: String(i.id),
                      }))}
                  />
                </div>
                <div className="col-md-6 crud-col-item">
                  <Select
                    name="familledesoin"
                    value={data.familledesoin}
                    error={errors.familledesoin}
                    onChange={(value) => {
                      onChange({
                        familledesoin: value,
                        typedesoin: "",
                        type_prestation: "",
                      });
                    }}
                    label="Famille de soins"
                    placeholder="Famille de soins"
                    items={familledesoins.map((i) => ({
                      label: i.nom,
                      value: String(i.id),
                    }))}
                  />
                </div>
                <div className="col-md-6 crud-col-item">
                  <Select
                    name="typedesoin"
                    value={data.typedesoin}
                    error={errors.typedesoin}
                    onChange={(value) => {
                      onChange({
                        typedesoin: value,
                        type_prestation: "",
                      });
                    }}
                    label="Type de soins"
                    placeholder="Type de soins"
                    items={typedesoins}
                  />
                </div>

                <div className="col-md-6 crud-col-item">
                  <MyInput
                    name="montant"
                    error={errors.montant}
                    value={data.montant}
                    type="number"
                    onChange={(v) => onChange("montant", v)}
                    label="Montant de la dépense"
                    placeholder="Renseigner le montant de la dépense"
                  />
                </div>

                <div className="col-md-6 crud-col-item">
                  <MyDatePicker
                    value={data.date}
                    name="date"
                    onChange={(v) => onChange("date", v)}
                    label="Date"
                    placeholder="Choisir une date"
                    error={errors.date}
                  />
                </div>
                <div className="col-md-6 crud-col-item">
                  <MyInput
                    name="file"
                    error={errors.file}
                    type="file"
                    onChange={(v) => onChange("file", v)}
                    label="Piece justificative"
                    placeholder="Piece justificative"
                  />
                </div>
                <div className="col-md-6 crud-col-item">
                  <MyInput
                    name="libelle"
                    error={errors.libelle}
                    value={data.libelle}
                    type="text"
                    onChange={(v) => onChange("libelle", v)}
                    label="Libellé"
                    placeholder="Libellé"
                  />
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn-assurance"
                    onClick={() => {
                      onAdd(false);
                      reset();
                    }}
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  <MyButton
                    title="Enregistrer"
                    loading={loading}
                    onClick={onSubmit}
                    className="btn  crud-submit-btn-assurance"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRemboursementModal;
