import React from "react";

import http from "./http";

export function useQuery(url = null) {
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const getData = React.useCallback(
    async (uri = null) => {
      if (uri) {
        url = uri;
      }
      if (url) {
        setLoading(true);
        try {
          let data;
          if (typeof url === "string") {
            data = await http.get(url);
          }
          if (data.results) {
            setData(data.results);
          } else if (Array.isArray(data)) {
            setData(data);
          } else if (data.data) {
            setData(data.data);
          } else {
            setData(data);
          }
          setError(null);
        } catch (ex) {
          setError(ex);
        }
      }

      setLoading(false);
    },
    [loading]
  );
  React.useEffect(() => {
    getData();
  }, []);
  React.useEffect(() => {
    if (error) {
      const to = setTimeout(() => {
        setError((e) => {
          if (e) {
            console.log("reload from error");
            getData();
          }
          return e;
        });
      }, 100000);
      return () => clearTimeout(to);
    }
  }, [error]);
  return {
    data,
    loading,
    error,
    getData,
  };
}
export function useQueryCb(cb) {
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const getData = React.useCallback(async (params) => {
    setLoading(true);
    try {
      const data = await cb(params);
      if (data.results) {
        setData(data.results);
      } else if (Array.isArray(data)) {
        setData(data);
      } else if (data.data) {
        setData(data.data);
      } else {
        setData(data);
      }
      setError(null);
    } catch (ex) {
      setError(ex);
    }
    setLoading(false);
  }, []);

  return {
    data,
    loading,
    error,
    getData,
  };
}
export function useQueries(urls, reload = true) {
  const [data, setData] = React.useState(urls.map(() => []));
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const getData = React.useCallback(
    async (paramsUrls) => {
      urls = paramsUrls || urls;
      console.log("urls", urls);
      setError(null);
      if (reload) {
        setLoading(true);
      }
      try {
        const finalData = [];
        const promises = urls.map(async (url) => {
          if (typeof url === "string") {
            return http.get(url);
          }
        });
        const results = await Promise.all(promises);
        for (let r of results) {
          if (r.results) {
            finalData.push(r.results);
          } else if (Array.isArray(r)) {
            finalData.push(r);
          } else if (r.data) {
            finalData.push(r.data);
          } else {
            finalData.push(r);
          }
        }
        setData(finalData);
      } catch (ex) {
        setError(ex);
      }
      setTimeout(() => {
        setLoading(false);
      }, 100);
    },
    [reload]
  );
  React.useEffect(() => {
    getData();
  }, []);
  React.useEffect(() => {
    if (error) {
      const to = setTimeout(() => {
        setError((e) => {
          if (e) {
            console.log("reload from error");
            getData();
          }
          return e;
        });
      }, 100000);
      return () => clearTimeout(to);
    }
  }, [error]);
  return {
    data,
    loading,
    error,
    getData,
  };
}
