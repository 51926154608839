import React, { useEffect, useState } from "react";
import { Bar, Pie, Doughnut } from "react-chartjs-2";
import moment from "moment";
import "moment/locale/fr";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { getDataByID } from "../../utils/fetchData";
import Skeleton from "react-loading-skeleton";
// import { formatMoney, formatMontant } from "../../../utils/utils";

registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});
function CumulChart({ auth }) {
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const [date, setDate] = useState(new Date());
  const [cumulTotal, setCumulTotal] = useState(0);
  const [count, setCount] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [labels, setLabels] = useState([]);
  // const [plugins, setPlugins] = useState([]);
  useEffect(() => {
    setLabels(dateRange(moment(date).format("YYYY-MM-DD")));
  }, [date]);

  useEffect(() => {
    if (auth?.user?.id && date && auth?.user?.user_type === "prestataire") {
      getDataByID(
        `culmulmontantbyprestataire/${auth?.user?.id}`,
        moment(date).format("DD-MM-YYYY"),
        auth?.token
      ).then((stat) => {
        getStat(stat?.data);
        setIsPageLoading(false);
        // console.log("testeee", stat?.data);
      });
    }
  }, [auth, date]);

  // useEffect(() => {

  // }, [stats]);

  const getStat = (array) => {
    let part_total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let part_ipm = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let part_adh = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let total_ipm = 0;
    let total_adh = 0;
    let total = 0;
    let cumul = [0, 0];
    if (array?.length) {
      // console.log("cout_total", array);
      array.forEach((element) => {
        total += parseInt(element?.cout_total);
        total_adh += parseInt(element?.part_beneficiaire);
        total_ipm += parseInt(element?.part_ipm);
        part_total[moment(element?.month).format("M") - 1] =
          element?.cout_total;
        part_ipm[moment(element?.month).format("M") - 1] = element?.part_ipm;
        part_adh[moment(element?.month).format("M") - 1] =
          element?.part_beneficiaire;
      });
    }
    cumul[0] = total_ipm;
    cumul[1] = total_adh;
    // console.log("test", total);
    // setCumul(cumul);
    setCumulTotal(total);
    setCount(part_total);
    // setCountIpm(part_ipm);
    // setCountAdh(part_adh);

    // setPlugins([
    //   {
    //     beforeDraw: function (chart) {
    //       var width = chart.width,
    //         height = chart.height,
    //         ctx = chart.ctx;
    //       ctx.restore();
    //       var fontSize = (height / 160).toFixed(2);
    //       ctx.font = 1 + "em sans-serif";
    //       ctx.textBaseline = "top";
    //       var text = formatMontant(total) + " (" + 100 + "%)",
    //         textX = Math.round((width - ctx.measureText(text).width) / 2),
    //         textY = height / 2;
    //       ctx.fillText(text, textX, textY);
    //       ctx.save();
    //     },
    //   },
    // ]);
  };

  const dateRange = (endDate) => {
    var start = moment().startOf("year").format("YYYY-MM-DD").split("-");
    var end = moment(endDate).format("YYYY-MM-DD").split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];
    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      // console.log("mois", startYear, endYear);
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var mois = moment().month(j).locale("fr").format("MMM");
        dates.push([mois].join("-"));
      }
    }
    return dates;
  };

  const data = {
    labels: labels,
    text: cumulTotal,
    datasets: [
      {
        label: "Coût total",
        data: count,
        // backgroundColor: "#f2cb67",
        backgroundColor: [
          "#f2cb67",
          "#047eb1",
          "#002060",
          "#02a69c",
          "#fd8a65",
          "rgb(255, 153, 153, 1)",
          "rgb(128, 191, 255, 1)",
          "rgb(255, 255, 128, 1)",
          //   "rgb(255, 128, 255, 1)",
          "rgb(128, 255, 255, 1)",
          "rgba(255, 99, 132, 1)",
          //   "rgba(54, 162, 235, 1)",
          //   "rgba(255, 206, 86, 1)",
          //   "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgb(2, 166, 156, 1)",
        ],
      },
    ],
  };

  const options = {
    showAllTooltips: true,
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          var total = meta.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1)
          );
          return currentValue + " (" + percentage + "%)";
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
  };

  let plugins = [
    {
      datalabels: {
        display: true,
      },
    },
  ];

  const handleChangeDate = (date) => {
    // setCount([]);
    // setCountAdh([])
    setLabels([]);
    setDate(date);
    var start = moment().startOf("year").format("YYYY-MM-DD").split("-");
    var end = moment(date).format("YYYY-MM-DD").split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];
    console.log("year", startYear, endYear);
    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      // console.log("mois", startYear, endYear);
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var mois = moment().month(j).locale("fr").format("MMM");
        dates.push([mois].join("-"));
      }
    }
    setLabels(dates);
  };
  return (
    <div className="chart-item-container">
      <div className="row mb-2">
        <div className="col-xl-5 col-lg-4 pb-2">
          <h2 className="chart-item-title">
            Cumul sur l'année civile des montants par mois{" "}
          </h2>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6 mb-2 dis-flex">
          <DatePicker
            dateFormat={"MMMM/yyyy"}
            showMonthYearPicker={true}
            maxDate={new Date()}
            className="form-select form-select-line-chart ml-md-auto"
            selected={date}
            onChange={(date) => handleChangeDate(date)}
            locale="fr"
          />
        </div>
      </div>
      {isPageLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Skeleton circle={true} height={250} width={250} />
        </div>
      ) : (
        <Pie data={data} options={options} plugins={plugins} />
      )}
    </div>
  );
}

export default CumulChart;
