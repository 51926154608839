import React, { useContext } from "react";
import EmptyHeader from "../Auth/EmptyHeader";
import FooterFirstLandingpage from "../Backend/FirstLandingPage/FooterFirstLandingpage";
import ContentPageCondition from "./ContentPageCondition";
import { DataContext } from "../store/GlobalState";

function CGU() {
  const { state } = useContext(DataContext);
  const { cgu } = state;
  return (
    <div className="landing-page-component">
      <EmptyHeader isLandingPage={true} />
      <div className="page-content position-relative ">
        <div className="landing-banner-page-component">
          <div className="row row-banner-landingpage">
            <div className="col-md-12 col-left-banner-landingpage mb-3 d-flex">
              <div className="content-col-left-banner-landingpage custom-content-col-left-banner-landingpage w-100">
                {/* CGU Content */}
                <div className="dashboard-praticien-table-recent-patient-container bg-white py-3 px-5 my-4">
                  <div className="titre-mentions-legales mb-5">
                    Conditions générales d'utilisation
                  </div>

                  <ContentPageCondition content={cgu} />
                </div>
                {/* END */}
              </div>
            </div>
          </div>
        </div>

        <FooterFirstLandingpage />
      </div>
    </div>
  );
}

export default CGU;
