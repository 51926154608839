import axios from "axios";
import baseUrl from "../backend-base-url";
export const ApiUrl = baseUrl;

const apiEndPoint = ApiUrl + "/api";

export function getUrl(url) {
  return apiEndPoint + url;
}

export async function get(url) {
  const options = await getOptions();
  return axios.get(getUrl(url), options).then((res) => res.data);
}
function getAuth(url, options = {}) {
  return axios.get(getUrl(url), options).then((res) => res.data);
}

export async function post(url, data, options) {
  const opt = await getOptions(options);
  return axios.post(getUrl(url), data, opt).then((res) => res.data);
}
export async function patch(url, data) {
  const options = await getOptions();
  return axios.patch(getUrl(url), data, options).then((res) => res.data);
}

export async function postPassword(url, data) {
  return axios.post(getUrl(url), data).then((res) => res.data);
}
async function deleteItem(url, options = {}) {
  const opt = await getOptions(options);
  return axios.delete(getUrl(url), opt).then((res) => res.data);
}

async function put(url, data, options = {}) {
  const opt = await getOption(options);
  return axios.put(getUrl(url), data, opt).then((res) => res.data);
}
async function getOptions(options = {}) {
  if (!options) options = {};
  options = Object.assign(options, {
    headers: {
      ...options.headers,
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  });
  return options;
}

async function getOption(options) {
  options = {
    ...options,
    ...(await getOptions(options)),
  };
  return options;
}
function upload(data, route, onUploadProgress, method = "post") {
  const config = {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onUploadProgress(percentCompleted);
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  if (method === "post") return post(route, data, config);

  return put(route, data, config);
}

export async function uploadDataImage(path, data) {
  const options = await getOptions(path);
  options.headers["Content-Type"] = "multipart/form-data";
  return axios.post(getUrl(path), data, options).then((res) => res.data);
}

export function makeid(length) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default {
  get,
  put,
  post,
  upload,
  getAuth,
  postPassword,
  patch,
  delete: deleteItem,
  axios,
};
