import React, { useContext, useState, useEffect } from "react";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import { DataContext } from "../../../store/GlobalState";
import { useForm } from "react-hook-form";
import RequestPassword from "../../../Services/RequestPassword";
import { toast } from "react-toastify";
import Header from "../../../Navs/Header";
import { putData } from "../../../utils/fetchData";

const ChangePassword = () => {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [isInProgress, setIsInProgress] = useState(false);
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = useState(null);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    clearErrors,
    reset,
  } = useForm();

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  useEffect(() => {
    if (auth?.token && auth?.user?.first_connexion) {
      let url = null;
      if (auth?.user?.user_type === "prestataire") {
        url = "prestataire/" + auth?.user?.id + "/";
      }

      if (auth?.user?.user_type === "entreprise" && !auth?.user?.admin_type) {
        url = "entreprise/" + auth?.user?.id + "/";
      }

      if (auth?.user?.user_type === "entreprise" && auth?.user?.admin_type) {
        url = "admin_user/" + auth?.user?.id + "/";
      }

      if (auth?.user?.user_type === "admin_ipm") {
        url = "admin_user/" + auth?.user?.id + "/";
      }
    }
  }, [auth]);

  useEffect(() => {
    register("new_password", {
      required: {
        value: true,
        message: "Le nouveau mot de passe est requis.",
      },
    });
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, formState?.errors, clearErrors, register]);

  const countUppercase = (str) => {
    if (str.replace(/[^A-Z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str) => {
    if (str.replace(/[^a-z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str) => {
    if (str.replace(/[^0-9]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str) => {
    if (str.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str) => {
    const punct = "!,;.-?@";
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e) => {
    var password = e.target.value;
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }
    // console.log("paass", e.target.value);
    register("new_password", {
      required: {
        value: true,
        message: "Le nouveau mot de passe est requis.",
      },
      pattern: {
        value: new RegExp(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
          // "Must Contain 8 ‚Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
        message:
          "Votre mot de passe doit contenir au moins 8 caracteres, un majuscule, un minuscule et un caractère spécial.",
      },
    });
    setValue("new_password", e.target.value);
  };

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    let url = null;
    if (auth?.user?.first_connexion) {
      if (auth?.user?.user_type === "prestataire") {
        url = "prestataire/" + auth?.user?.id + "/";
      }

      if (auth?.user?.user_type === "entreprise" && !auth?.user?.admin_type) {
        url = "entreprise/" + auth?.user?.id + "/";
      }

      if (auth?.user?.user_type === "entreprise" && auth?.user?.admin_type) {
        url = "admin_user/" + auth?.user?.id + "/";
      }

      if (auth?.user?.user_type === "admin_ipm") {
        url = "admin_user/" + auth?.user?.id + "/";
      }
    }

    delete data?.confirm_password;
    console.log("data", data);
    const request = RequestPassword.changePassword(data, requestConfig);
    await request
      .then((res) => {
        console.log("res", res);
        if (res.status === "success") {
          setIsLoading(false);
          toast.success("Mot de passe modifié avec succés.");
          if (url) {
            putData(url, { first_connexion: false }, auth?.token).then(
              (response) => {
                console.log("user", response);
                dispatch({ token: auth?.token, user: response });
              }
            );
          }

          e.target.reset();
          reset();
          setHaveMinUppercase(false);
          setHaveMinLowercase(false);
          setHaveMinNumber(false);
          setHaveMinCharacter(false);
          setHaveMinSpecialCharacter(false);
          setIsRevealPwd(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error?.response?.data?.old_password &&
          error?.response?.data?.old_password[0] === "Wrong password."
        ) {
          setError("Mot de passe incorrect");
          //  setIsLoading(false)
          setValue("old_password", "");

          setTimeout(() => {
            setError(null);
          }, 5000);
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (
          error?.response?.status === 400 &&
          !error?.response?.data?.old_password
        ) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };

  const handleToggle = (e) => {
    e.preventDefault();
    setIsRevealPwd((prevState) => !prevState);
  };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Desktop  */}
          <div
            className={`nav-container ${
              auth?.user?.user_type === "assureur" && "nav-assurance-color"
            }  `}
          >
            <NavBarVerticalV2 />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Changer votre mot de passe</h1>
                  </div>
                </div>
                <div className="parent-table-container parent-tabs-container">
                  <div className="easypm-tabs-content">
                    <div className="easypm-tabs-item-content-container">
                      <div className="easypm-tabs-item">
                        <div className="add-family-form-container">
                          <form
                            className="crud-form"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="row row-form">
                              <div className="col-md-4 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <label htmlFor="old_password">
                                    Ancien mot de passe
                                  </label>
                                  <input
                                    {...register("old_password", {
                                      required:
                                        "L'ancien mot de passe est requis.",
                                    })}
                                    type="password"
                                    placeholder="Ancien mot de passe"
                                    name="old_password"
                                    className="form-control crud-form-control"
                                    id="old_password"
                                  />
                                  {formState?.errors &&
                                    formState?.errors?.old_password && (
                                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                        {
                                          formState?.errors?.old_password
                                            ?.message
                                        }
                                      </div>
                                    )}
                                  {error && (
                                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                      {error}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <label htmlFor="new_password">
                                    Nouveau mot de passe
                                  </label>
                                  <input
                                    // {...register('new_password', {
                                    //   required: 'Le nouveau mot de passe est requis.',

                                    // })}
                                    onChange={(e) => passwordHandleChange(e)}
                                    // type="password"
                                    type={isRevealPwd ? "text" : "password"}
                                    placeholder="Nouveau mot de passe"
                                    name="new_password"
                                    className="form-control crud-form-control"
                                    id="new_password"
                                  />
                                  <span
                                    toggle="#password-field"
                                    className={
                                      isRevealPwd
                                        ? "fa fa-eye fa-eye-slash field-icon toggle-password"
                                        : "fa fa-fw fa-eye field-icon toggle-password"
                                    }
                                    title={
                                      isRevealPwd
                                        ? "Cacher le mot de passe"
                                        : "Voir le mot de passe"
                                    }
                                    onClick={(e) => handleToggle(e)}
                                  ></span>
                                  {formState?.errors &&
                                    formState?.errors?.new_password && (
                                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                        {
                                          formState?.errors?.new_password
                                            ?.message
                                        }
                                      </div>
                                    )}
                                </div>
                              </div>

                              <div className="col-md-4 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <label htmlFor="confirm_password">
                                    Confirmer le nouveau mot de passe
                                  </label>
                                  <input
                                    {...register("confirm_password", {
                                      required:
                                        "La confirmation du mot de passe est requis.",
                                      validate: (value) => {
                                        return (
                                          value === watch("new_password") ||
                                          "Veuillez entrer deux mots de passe identiques."
                                        );
                                      },
                                    })}
                                    type="password"
                                    placeholder="Confirmer le nouveau mot de passe"
                                    name="confirm_password"
                                    className="form-control crud-form-control"
                                    id="confirm_password"
                                  />
                                  {formState?.errors &&
                                    formState?.errors?.confirm_password && (
                                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                        {
                                          formState?.errors?.confirm_password
                                            ?.message
                                        }
                                      </div>
                                    )}
                                </div>
                              </div>

                              <div className="crud-col-item">
                                <div className="input-group my-2 password-validation-terms-row">
                                  <div className="password-validation-terms-container">
                                    <h4 className="password-validation-terms-title">
                                      Votre nouveau mot de passe doit contenir :{" "}
                                    </h4>
                                    <ul className="password-validation-terms-ul">
                                      <li
                                        className={
                                          "min-character-count pvt-li " +
                                          (haveMinCharacter &&
                                            "min-character-count-ok")
                                        }
                                      >
                                        Au minimum 8 caractères
                                      </li>
                                      <li
                                        className={
                                          "min-character-uppercase pvt-li " +
                                          (haveMinUppercase &&
                                            "min-character-uppercase-ok")
                                        }
                                      >
                                        Au minimum 1 caractère en majuscule
                                      </li>
                                      <li
                                        className={
                                          "min-character-lowercase pvt-li " +
                                          (haveMinLowercase &&
                                            "min-character-lowercase-ok")
                                        }
                                      >
                                        Au minimum 1 caractère en minuscule
                                      </li>
                                      <li
                                        className={
                                          "min-character-number pvt-li " +
                                          (haveMinNumber &&
                                            "min-character-number-ok")
                                        }
                                      >
                                        Au minimum 1 nombre
                                      </li>
                                      <li
                                        className={
                                          "min-character-special pvt-li " +
                                          (haveMinSpecialCharacter &&
                                            "min-character-special-ok")
                                        }
                                      >
                                        Au minimum 1 caractère spéciale
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 offset-md-9 crud-form-action-btn-container">
                                {isLoading && (
                                  <button
                                    className="btn btn-primary crud-submit-btn"
                                    disabled
                                  >
                                    En cours &nbsp;
                                    <i className="fas fa-spin fa-spinner"></i>
                                  </button>
                                )}
                                {!isLoading && (
                                  <button className="btn btn-primary crud-submit-btn">
                                    Modifier
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
