import React, { useContext, useRef } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../../store/GlobalState";
import Service from "../../../Services/Service";
import { getDataByID } from "../../../utils/fetchData";

var Services = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { state, dispatch } = useContext(DataContext);
  const { services, auth, prestataire } = state;
  const { register, handleSubmit, formState, setValue } = useForm();
  let sigPad = useRef({});

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };
  var [isOpenAddServiceForm, setIsOpenAddServiceForm] = React.useState(false);

  const onServiceFormState = () => {
    if (isOpenAddServiceForm === false) {
      setIsOpenAddServiceForm(true);
    } else {
      setIsOpenAddServiceForm(false);
    }
  };

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    console.log(data);
    var serviceRequest = Service.postCreateService(data, requestConfig);

    await serviceRequest
      .then((res) => {
        console.log("response", res);
        getDataByID("servicebyprestataire", auth?.user?.id, auth.token)
          .then((res) => {
            dispatch({
              type: "LIST_SERVICES",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          })
          .catch((e) => {
            console.log("e", e);
          });
        setIsLoading(false);
        toast.success("Service ajouté avec succès.");
        e.target.reset();
      })
      .catch((e) => {
        console.log("error", e);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de l'ajout.");
      });
  };

  return (
    <div className="easypm-tabs-item-content-container">
      <div className="easypm-tabs-item">
        <div className="row add-service-row">
          <div className="col-md-4 offset-md-8 add-service-col">
            <div
              className="adding-item-container-icon linkable"
              onClick={() => onServiceFormState()}
            >
              <span className="icon-container">
                <IconlyPack.Plus set="light" primaryColor="gray" />
              </span>
              &nbsp;
              <span className="adding-item-label">Ajouter un service</span>
            </div>
          </div>
        </div>
        {isOpenAddServiceForm && (
          <div className="hiddenable-crud-system">
            <div className="row hiddenable-crud-system-row">
              <div className="col-md-6 offset-md-6 hiddenable-crud-system-col">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="hiddenable-crud-container inputify-container">
                    <div className="form-group crud-form-group">
                      <input
                        type="text"
                        placeholder="Nom du service"
                        name="nom"
                        className="form-control crud-form-control"
                        id="nom"
                        {...register("nom", {
                          required: "Le nom est requis.",
                        })}
                      />
                      {formState.errors.nom && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {formState.errors.nom.message}{" "}
                        </div>
                      )}
                    </div>
                    <input
                      type="hidden"
                      value={prestataire?.id || ""}
                      name="prestataire"
                      {...register("prestataire")}
                    />
                    <div className="form-group crud-form-group buttonify-container">
                      {!isLoading ? (
                        <button className="btn btn-primary submit-button">
                          Ajouter
                        </button>
                      ) : (
                        <button className="btn btn-primary submit-button">
                          En cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        <div className="row services-row">
          {services?.length &&
            services.map((service) => (
              <div className="col-md-6 services-col" key={service?.id}>
                <div className="service-item-container">
                  <span className="service-name-label"> Nom du service : </span>
                  &nbsp;
                  <span className="service-item-value"> {service?.nom} </span>
                </div>
              </div>
            ))}

          {/* <div className="col-md-6 services-col">
            <div className="service-item-container">
              <span className="service-name-label"> Nom du service : </span>
              &nbsp;
              <span className="service-item-value"> service 1 </span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Services;
