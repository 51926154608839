import React, { useState, useEffect, useContext } from "react";
import * as IconlyPack from "react-iconly";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UseCreatePrestataire from "./UseCreatePrestataire";
import { DataContext } from "../../../store/GlobalState";

const AjouterPrestataireModal = (props) => {
  const {
    register,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    onSubmit,
    setValue,
    reset,
    unregister,
    isSuccess,
  } = UseCreatePrestataire();
  const { state, dispatch } = useContext(DataContext);

  const { auth, prestataires, prestataire, list_specialites, ipms } = state;
  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());
  const [isValid, setIsValid] = React.useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [personne, setPersonne] = useState("personne_morale");
  var [website, setWebsite] = useState("");

  const [phone, setPhone] = React.useState("");

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  useEffect(() => {
    if (isSuccess) {
      handleReset()
    }
  }, [isSuccess]);

  const handleSetPhone = (val) => {
    setPhone(val);
    register("telephone");
    setValue("telephone", "+" + val);
  };
  const handleChangeWebsite = (e) => {
    // eslint-disable-next-line
    const urlPattern = new RegExp(
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    );
    let string = e.target.value;

    if (urlPattern.test(string)) {
      ///clear http && https from string
      string = string.replace("https://", "").replace("http://", "");
      //add https to string
      string = `https://${string}`;
    }
    setWebsite(string);
    register("website");
    setValue("website", string);
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);

    if (event.target.value && !prestataire?.id) {
      if (personne === "personne_morale") {
        register("raison_sociale");
        setValue("raison_sociale", event.target.value);
      }

      if (personne === "personne_physique") {
        register("prenom");
        setValue("prenom", event.target.value);
      }
    } else {
      setSearchResults([]);
      unregister("raison_sociale");
      unregister("prenom");
      setValue("prenom", "");
    }
  };

  const handleDateDebut = (date) => {
    setStartDate(date);
    register("date_debut");
    setValue("date_debut", moment(date).format("YYYY-MM-DD"));
    if (endDate && date > endDate) {
      setIsValid(false);
    }
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      register("date_fin");
      setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    if (!prestataire?.id && searchTerm) {
      const results = prestataires.filter((person) => {
        //console.log('search term', searchTerm)
        if (person.raison_sociale) {
          return person.raison_sociale
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        }

        if (!person.raison_sociale) {
          // console.log('search term', searchTerm)
          let name = person.prenom + " " + person.nom;
          return (
            person.prenom.toLowerCase().includes(searchTerm.toLowerCase()) ||
            person.nom.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
      });
      setSearchResults(results);
    }

    if (prestataire?.id) {
      setPhone(prestataire?.telephone);
      // console.log('date', new Date(prestataire?.date_debut), prestataire?.date_debut)
      setStartDate(new Date(prestataire?.date_debut));
      setEndDate(new Date(prestataire?.date_fin));
    }
  }, [searchTerm, prestataire]);

  useEffect(() => {
    register("date_debut");
    setValue("date_debut", moment(startDate).format("YYYY-MM-DD"));

    register("date_fin");
    setValue("date_fin", moment(endDate).format("YYYY-MM-DD"));
  }, [startDate, endDate]);

  const handleClick = async (e, item) => {
    e.preventDefault();
    // console.log('item', item)
    await setSearchTerm("");
    await setSearchResults([]);
    if (item?.raison_sociale) {
      await setPersonne("personne_morale");
      // setSearchTerm(item?.raison_sociale)
    } else {
      await setPersonne("personne_physique");
      // setSearchTerm(item?.prenom)
    }

    await dispatch({
      type: "EDIT_PRESTATAIRE",
      payload: item,
    });
  };

  const handleReset = () => {
    // await reset()
    setSearchTerm("");
    setWebsite("");
    setPhone("+221");
    setStartDate(new Date());
    setEndDate(new Date());
    register("telephone");
    setValue("telephone", "");
    setSearchResults([]);
    if (personne === "personne_morale") {
      register("raison_sociale");
      setValue("raison_sociale", "");
    }

    if (personne === "personne_physique") {
      register("prenom");
      setValue("prenom", "");
    }

    dispatch({ type: "EDIT_PRESTATAIRE", payload: {} });
    reset();
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="AjouterPrestataireModal"
          >
            Ajouter un prestataire
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 type-presta-container">
                  <div className="type-presta-label-container col-md-4">
                    <span className="type-presta-label">
                      {" "}
                      Le prestataire est une{" "}
                    </span>
                  </div>
                  <div className="type-presta-label-container col-md-8 type-presta-label-col">
                    <div className="type-presta-input-radio-container">
                      <input
                        type="radio"
                        onChange={(e) => setPersonne(e.target.value)}
                        className="easypm-input-radio input-radio-form-control"
                        name="user_type"
                        value="personne_physique"
                        defaultChecked={personne === "personne_physique"}
                      />
                      <label className="input-radio-item-label">
                        une personne physique
                      </label>
                    </div>
                    <div className="type-presta-input-radio-container">
                      <input
                        type="radio"
                        onChange={(e) => setPersonne(e.target.value)}
                        className="easypm-input-radio input-radio-form-control"
                        name="user_type"
                        value="personne_morale"
                        defaultChecked={personne === "personne_morale"}
                      />
                      <label className="input-radio-item-label">
                        une personne morale
                      </label>
                    </div>
                  </div>
                </div>

                {personne === "personne_morale" && (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="name">Nom</label>
                      <input
                        type="text"
                        placeholder="Nom"
                        name="raison_sociale"
                        className="form-control crud-form-control"
                        id="name"
                        // {...register('raison_sociale')}
                        value={
                          !prestataire?.id
                            ? searchTerm
                            : prestataire?.raison_sociale
                        }
                        onChange={handleChange}
                      />
                      {searchResults?.length ? (
                        <ul className="search-result-container">
                          {searchResults.map((item) => (
                            <li
                              className="search-result-item"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleClick(e, item)}
                              key={item?.id}
                            >
                              {item?.raison_sociale
                                ? item.raison_sociale
                                : item.prenom + " " + item.nom}
                            </li>
                          ))}
                        </ul>
                      ) : null}

                      {formState.errors && formState.errors.raison_sociale && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.raison_sociale?.message}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {personne === "personne_physique" && (
                  <>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <label htmlFor="prenom">Prénom</label>
                        <input
                          type="text"
                          placeholder="Prénom"
                          name="prenom"
                          className="form-control crud-form-control"
                          id="prenom"
                          disabled={prestataire?.id}
                          value={
                            !prestataire?.id ? searchTerm : prestataire?.prenom
                          }
                          onChange={handleChange}
                          // {...register('prenom')}
                        />
                        {searchTerm?.length ? (
                          <ul className="search-result-container">
                            {searchResults.map((item) => (
                              <li
                                className="search-result-item"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => handleClick(e, item)}
                                key={item?.id}
                              >
                                {item?.raison_sociale
                                  ? item.raison_sociale
                                  : item.prenom + " " + item.nom}
                              </li>
                            ))}
                          </ul>
                        ) : null}
                        {formState.errors && formState.errors.prenom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.prenom?.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <label htmlFor="nom">Nom</label>
                        <input
                          type="text"
                          placeholder="Nom"
                          name="nom"
                          className="form-control crud-form-control"
                          id="nom"
                          disabled={prestataire?.id}
                          defaultValue={
                            !prestataire?.id ? "" : prestataire?.nom
                          }
                          // onChange={handleChange}
                          {...register("nom")}
                        />
                        {formState.errors && formState.errors.nom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.nom?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="date_debut">Date d'arrivée</label>

                    <DatePicker
                      className="form-control crud-form-control"
                      selected={startDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                      onChange={(date) => handleDateDebut(date)}
                    />
                    {formState.errors && formState.errors.date_debut && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.date_debut?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="date_fin">Date de fin</label>
                    <DatePicker
                      className="form-control crud-form-control"
                      selected={endDate}
                      placeholderText="Sélectionner une date"
                      dateFormat="dd/MM/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="fr"
                      onChange={(date) => handleDateFin(date)}
                    />
                    {formState.errors && formState.errors.date_fin && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.date_fin?.message}
                      </div>
                    )}

                    {!isValid && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        La date de fin ne doit pas être inférieure à la date de
                        début.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Spécialité</label>
                    <div className="filter-item-container">
                      <select
                        name="specialite"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                        {...register("specialite")}
                      >
                        <option hidden value="">
                          Choisir une spécialité
                        </option>
                        {list_specialites?.length &&
                          list_specialites?.map((sp) => (
                            <option key={sp?.id} value={sp?.id}>
                              {sp?.nom}
                            </option>
                          ))}
                      </select>

                      {formState.errors && formState.errors.specialite && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.specialite?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {personne === "personne_morale" && (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="ninea">NINEA</label>
                      <input
                        type="text"
                        placeholder="NINEA"
                        name="ninea"
                        className="form-control crud-form-control"
                        id="ninea"
                        maxlength={12}
                        disabled={prestataire?.id}
                        defaultValue={
                          !prestataire?.id ? "" : prestataire?.ninea
                        }
                        {...register("ninea")}
                      />
                      {formState.errors && formState.errors.ninea && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.ninea?.message}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      placeholder="exemple@exemple.com"
                      name="email"
                      className="form-control crud-form-control"
                      id="email"
                      disabled={prestataire?.id}
                      defaultValue={!prestataire?.id ? "" : prestataire?.email}
                      {...register("email")}
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      type="text"
                      placeholder="HLM Grand Yoff, Villa 241 - Dakar"
                      name="adresse"
                      className="form-control crud-form-control"
                      id="adresse"
                      disabled={prestataire?.id}
                      defaultValue={
                        !prestataire?.id ? "" : prestataire?.adresse
                      }
                      {...register("adresse")}
                    />
                    {formState.errors && formState.errors.adresse && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.adresse?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="telephone">Téléphone</label>

                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      inputClass="form-control crud-form-control"
                      country={"sn"}
                      onlyCountries={["sn"]}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    {formState.errors && formState.errors.telephone && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.telephone?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="numero_contrat">Num. Contrat</label>
                    <input
                      type="text"
                      placeholder="Numéro contrat"
                      name="num_contrat"
                      className="form-control crud-form-control"
                      id="numero_contrat"
                      disabled={prestataire?.id}
                      defaultValue={
                        !prestataire?.id ? "" : prestataire?.num_contrat
                      }
                      {...register("num_contrat")}
                    />
                  </div>
                </div>
                {auth?.user?.user_type !== "entreprise" ? (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="first_name">Agréement</label>
                      <div className="filter-item-container">
                        {prestataire?.id && (
                          <select
                            name="agreement"
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                            disabled={prestataire?.id}
                            value={prestataire?.agreement}
                            {...register("agreement")}
                          >
                            <option hidden value="">
                              Choisir
                            </option>
                            <option value={true}>OUI</option>
                            <option value={false}>NON</option>
                          </select>
                        )}

                        {!prestataire?.id && (
                          <select
                            name="agreement"
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                            {...register("agreement")}
                          >
                            <option hidden value="">
                              Choisir
                            </option>
                            <option value={true}>OUI</option>
                            <option value={false}>NON</option>
                          </select>
                        )}

                        {formState.errors && formState.errors.agreement && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState.errors.agreement?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}

                {auth?.user?.user_type === "entreprise" ? (
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="first_name">Ipm</label>
                      <div className="filter-item-container">
                        <select
                          name="assurance_maladie"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                          {...register("assurance_maladie")}
                        >
                          <option hidden value="">
                            Choisir
                          </option>
                          {ipms &&
                            ipms.map((item, index) => (
                              <option key={index} value={item.id}>
                                {" "}
                                {item.raison_sociale}{" "}
                              </option>
                            ))}
                        </select>

                        {formState.errors &&
                          formState.errors.assurance_maladie && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {formState.errors.assurance_maladie?.message}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="website">Site web</label>
                    <input
                      type="text"
                      placeholder="www.exemple.com"
                      name="website"
                      className="form-control crud-form-control"
                      id="website"
                      // value={website}
                      disabled={prestataire?.id}
                      defaultValue={
                        !prestataire?.id ? website : prestataire?.website
                      }
                      onChange={handleChangeWebsite}
                    />
                    <div className="input-infos-container">
                      <IconlyPack.Danger
                        set="light"
                        primaryColor="gray"
                        className="alerty-icon"
                      />
                      &nbsp;
                      <span className="input-infos">
                        Saisie au format www.exemple.com, l'adresse sera
                        autocomplétée avec le protocole http://
                      </span>
                    </div>
                    {formState.errors && formState.errors.website && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.website?.message}
                      </div>
                    )}
                  </div>
                </div>

                <input
                  {...register("user_type")}
                  type="hidden"
                  name="user_type"
                  value="prestataire"
                />

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  {!prestataire?.id && (
                    <button
                      className="btn btn-default cancelleb-btn-assurance"
                      onClick={() => handleReset()}
                      data-dismiss="modal"
                    >
                      Retour
                    </button>
                  )}
                  {prestataire?.id && (
                    <button
                      className="btn btn-default cancelleb-btn-assurance"
                      onClick={() => handleReset()}
                    >
                      Réinitialiser
                    </button>
                  )}

                  {isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn crud-submit-btn-assurance">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterPrestataireModal;
