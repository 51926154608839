import React, { useContext, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FeatherIcon from "feather-icons-react";
import ListeDesParticipantsTable from "../../../Tables/Beneficiaire/ListeDesParticipantsTable/ListeDesParticipantsTable";
import { useQuery } from "../../../../Http/http/query";
export function usePrestatairebyvisitor() {
  const query = useQuery("/prestatairebyvisitor/");
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    setData(query.data.filter((f) => !!f.specialite));
  }, [query.data]);
  return [data, query.loading];
}
var ListeDesParticipants = () => {
  const [data, loading] = usePrestatairebyvisitor();
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container nav-assurance-color">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Liste des praticiens</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <ListeDesParticipantsTable data={data} loading={loading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeDesParticipants;
