import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Exception from "../../../Services/Exception";
import { DataContext } from "../../../store/GlobalState";
import { toast } from "react-toastify";
import { getDataByID } from "../../../utils/fetchData";
import moment from "moment";

const UseCreateExceptionForm = () => {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const { state, dispatch } = useContext(DataContext);

  const { beneficiaires, auth } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        type: yup.string().required("Le type est requis"),
        libelle: yup.string().required("Le libellé est requis"),
        cle: yup.string().required("L'objet de l’opposition est requis."),
        date_debut: yup
          .string()
          .required("La date de début est requise.")
          .default(moment(new Date()).format("YYYY-MM-DD")),
        date_fin: yup
          .string()
          .required("La date de fin est requise.")
          .default(moment(new Date()).format("YYYY-MM-DD")),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    if (
      auth?.user?.user_type !== "entreprise" &&
      auth?.user?.user_type !== "prestataire"
    ) {
      if (auth?.user?.user_type !== "admin_ipm") {
        formValues["ipm"] = auth?.user?.id;
      } else {
        formValues["ipm"] = auth?.user?.parent;
      }
      formValues["entreprise"] = null;
      formValues["prestataire"] = null;
    } else if (auth?.user?.user_type === "entreprise") {
      formValues["entreprise"] = auth?.user?.id;
      formValues["ipm"] = null;
      formValues["prestataire"] = null;
    } else if (auth?.user?.user_type === "prestataire") {
      formValues["prestataire"] = auth?.user?.id;
      formValues["ipm"] = null;
      formValues["entreprise"] = null;
    }

    console.log("data", formValues);
    var exceptionRequest = Exception.postCreateException(
      formValues,
      requestConfig
    );
    await exceptionRequest
      .then((response) => {
        var exceptionInfo = response;
        console.log(response);
        if (
          auth?.user?.user_type !== "entreprise" &&
          auth?.user?.user_type !== "prestataire"
        ) {
          if (auth?.user?.user_type !== "admin_ipm") {
            getDataByID("exceptionbyipm", auth?.user?.id, auth.token)
              .then((res) => {
                // console.log('data', res)
                dispatch({
                  type: "LIST_EXCEPTION_IPM",
                  payload: res.data.sort((a, b) => a?.id - b?.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            getDataByID("exceptionbyipm", auth?.user?.parent, auth.token)
              .then((res) => {
                // console.log('data', res)
                dispatch({
                  type: "LIST_EXCEPTION_IPM",
                  payload: res.data.sort((a, b) => a?.id - b?.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          }
        } else if (auth?.user?.user_type === "entreprise") {
          getDataByID("exceptionbyentreprise", auth?.user?.id, auth.token)
            .then((res) => {
              console.log("data", res);
              dispatch({
                type: "LIST_EXCEPTION_IPM",
                payload: res.data.sort((a, b) => a?.id - b?.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        } else {
          getDataByID("exceptionbyprestataire", auth?.user?.id, auth.token)
            .then((res) => {
              console.log("data", res);
              dispatch({
                type: "LIST_EXCEPTION_IPM",
                payload: res.data.sort((a, b) => a?.id - b?.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
        setIsSuccess(true);
        e.target.reset();
        reset();
        window.$(".add-exception-modal").modal("hide");
        window.$(".accept-generation-modal").modal("hide");
        toast.success("Exception ajouté avec succès.");
        setIsSuccessfullySubmitted(false);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
        console.log(isSuccessfullySubmitted);
      })
      .catch((error) => {
        console.log(error);
        setIsSuccessfullySubmitted(false);
        setIsFormRequestError(true);
        if (
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          toast.error(
            "Oupsss! L'adresse email est déjà associée à un compte. Veuillez utiliser une autre adresse email.",
            {
              position: "top-right",
              autoClose: 10000,
            }
          );
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };

  return {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitCreateForm),
    setValue,
    reset,
    getValues,
    isSuccess,
  };
};

export default UseCreateExceptionForm;
