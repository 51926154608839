export const ACTIONS = {
  AUTH: "AUTH",
  STATS: "STATS",
  ADD_IPM: "ADD_IPM",
  EDIT_IPM: "EDIT_IPM",
  ADD_PRESTATAIRE: "ADD_PRESTATAIRE",
  PRESTATAIRE_BY_IPM: "PRESTATAIRE_BY_IPM",
  EDIT_PRESTATAIRE: "EDIT_PRESTATAIRE",
  ADD_PRESTATION_TYPE: "ADD_PRESTATION_TYPE",
  EDIT_PRESTATION_TYPE: "EDIT_PRESTATION_TYPE",
  ADD_FICHE_DE_SOIN_BY_IPM: "ADD_FICHE_DE_SOIN_BY_IPM",
  EDIT_FICHE_DE_SOIN_BY_IPM: "EDIT_FICHE_DE_SOIN_BY_IPM",
  ADD_BENEFICIAIRE: "ADD_BENEFICIAIRE",
  SHOW_BENEFICIAIRE: "SHOW_BENEFICIAIRE",
  ADD_ENTREPRISE_BY_IPM: "ADD_ENTREPRISE_BY_IPM",
  ADD_ENTREPRISE: "ADD_ENTREPRISE",
  EDIT_ENTREPRISE: "EDIT_ENTREPRISE",
  LIST_BENEFICIAIRE_BY_ENTREPRISE: "LIST_BENEFICIAIRE_BY_ENTREPRISE",
  ADD_AYANTDROIT_BY_BENEFICIAIRE: "ADD_AYANTDROIT_BY_BENEFICIAIRE",
  LIST_AYANTDROIT: "LIST_AYANTDROIT",
  SHOW_AYANTDROIT_BY_BENEFICIAIRE: "SHOW_AYANTDROIT_BY_BENEFICIAIRE",
  LIST_ADMIN_IPM: "LIST_ADMIN_IPM",
  EDIT_ADMIN_IPM: "EDIT_ADMIN_IPM",
  //actions soins
  GET_FICHES_DE_SOIN: "GET_FICHES_DE_SOIN",
  LISTE_PRESTATATION_BY_PRESTATAIRE: "LISTE_PRESTATATION_BY_PRESTATAIRE",
  LISTE_PRESTATATION_BY_FACTURE: "LISTE_PRESTATATION_BY_FACTURE",
  LISTE_FICHE_VALID_BY_PRESTATAIRE: "LISTE_FICHE_VALID_BY_PRESTATAIRE",
  EDIT_PRESTATION: "EDIT_PRESTATION",
  GET_FICHES_DE_SOIN_FOR_IPM: "GET_FICHES_DE_SOIN_FOR_IPM",
  //end actions soins

  LIST_EMPLOYE_BY_ENTREPRISE_FROM_IPM: "LIST_EMPLOYE_BY_ENTREPRISE_FROM_IPM",
  LIST_FACTURE_BY_PRESTATAIRE: "LIST_FACTURE_BY_PRESTATAIRE",
  LIST_FACTURE_BY_IPM: "LIST_FACTURE_BY_IPM",
  LIST_FACTURE_BY_ENTREPRISE: "LIST_FACTURE_BY_ENTREPRISE",
  EDIT_FACTURE: "EDIT_FACTURE",
  LIST_EXCEPTION_IPM: "LIST_EXCEPTION_IPM",
  EDIT_EXCEPTION: "EDIT_EXCEPTION",
  LIST_SERVICES: "LIST_SERVICES",
  LIST_NOTIFICATION: "LIST_NOTIFICATION",
  DEVIS_BY_PRESTATAIRE: "DEVIS_BY_PRESTATAIRE",
  DEVIS_BY_IPM: "DEVIS_BY_IPM",
  DEVIS_BY_ENTREPRISE: "DEVIS_BY_ENTREPRISE",
  SHOW_DEVIS: "SHOW_DEVIS",
  RAPPORT: "RAPPORT",
  PARENT: "PARENT",
  LIST_PRESTATION_BY_FACTURE: "LIST_PRESTATION_BY_FACTURE",
  LIST_FAMILLE_SOINS: "LIST_FAMILLE_SOINS",
  FAMILLE_SOINS: "FAMILLE_SOINS",
  LIST_EXCLUSION: "LIST_EXCLUSION",
  EDIT_EXCLUSION: "EDIT_EXCLUSION",
  LIST_SPECIALITES: "LIST_SPECIALITES",
  SPECIALITE: "SPECIALITE",

  // {/** Assurance */}
  ENTREPRISE_ASSUREUR: "ENTREPRISE_ASSUREUR",
  PRESTATAIRE_ASSUREUR: "PRESTATAIRE_ASSUREUR",
  BENEFICIAIRE_ASSUREUR: "BENEFICIAIRE_ASSUREUR",
  PRISE_EN_CHARGE_ASSUREUR: "PRISE_EN_CHARGE_ASSUREUR",
  ADMIN_ASSUREUR: "ADMIN_ASSUREUR",
  FICHESOINS_ASSUREUR: "FICHESOINS_ASSUREUR",
  REMBOURSEMENTS_ASSUREUR: "REMBOURSEMENTS_ASSUREUR",
  FACTURES_ASSUREUR: "FACTURES_ASSUREUR",
  DEVIS_ASSUREUR: "DEVIS_ASSUREUR",
  RAPPORT_ASSUREUR: "RAPPORT_ASSUREUR",
  OPPOSITION_ASSUREUR: "OPPOSITION_ASSUREUR",
  EXCLUSION_ASSUREUR: "EXCLUSION_ASSUREUR",
  CONDITIONS: "CONDITIONS",
  CGU: "CGU",
  PC: "PC",

  // Bénéficiaires
  FICHESOINS_BENEFICIAIRE: "FICHESOINS_BENEFICIAIRE",
};

export const updateItem = (data, id, post, type) => {
  const newData = data.map((item) => (item.id === id ? post : item));
  // console.log('new', post, id)
  return { type, payload: newData };
};

export const deleteItem = (data, id, type) => {
  const newData = data.filter((item) => item.id !== id);
  return { type, payload: newData };
};
