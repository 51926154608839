import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { DataContext } from "../../../../store/GlobalState";
import AssuranceMaladie from "../../../../Services/AssuranceMaladie";
import { getDataByID } from "../../../../utils/fetchData";

function UseEditAdminForm() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
  };

  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("L'adresse email n'est pas valide")
          .required("L'email est requis"),
        nom: yup.string().required("Le Nom est requis"),
        prenom: yup.string().required("Le prénom est requis"),
        telephone: yup
          .string()
          .required("Le numéro de téléphone est requis")
          .matches(
            /^((\+)221|0)[1-9](\d{2}){4}$/,
            "Veuillez vérifier le format du numéro de téléphone"
          ),
        // adresse: yup.string().required('L\'adresse est requise'),
      }),
    []
  );

  const { register, handleSubmit, formState, clearErrors, setValue, reset } =
    useForm({
      resolver: yupResolver(validationSchema),
    });

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    console.log("form", formValues);
    formValues["adresse"] = "HLM Grand Yoff";
    // formValues["parent"] = auth?.user?.id;

    var adminRequest = AssuranceMaladie.editAdminAssuranceMaladie(
      formValues?.id,
      formValues,
      requestConfig
    );
    await adminRequest
      .then((response) => {
        var adminInfos = response;
        console.log("res", adminInfos);
        // dispatch({ type: 'LIST_ADMIN_IPM', payload: [...admin_ipms, adminInfos]})

        if (auth?.user?.user_type === "assureur") {
          if (auth?.user?.admin_type === "assureur") {
            getDataByID("admin_by_assureur", auth?.user?.parent, auth.token)
              .then((res) => {
                //console.log('res', res)
                dispatch({
                  type: "ADMIN_ASSUREUR",
                  payload: {
                    isLoading: false,
                    data: res?.sort((a, b) => b.id - a.id),
                  },
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            getDataByID("admin_by_assureur", auth?.user?.id, auth.token)
              .then((res) => {
                //console.log('res', res)
                dispatch({
                  type: "ADMIN_ASSUREUR",
                  payload: {
                    isLoading: false,
                    data: res?.sort((a, b) => b.id - a.id),
                  },
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          }
        }

        e.target.reset();
        window.$(".edit-admin-modal").modal("hide");
        setIsSuccess(true);
        setIsSuccessfullySubmitted(false);
        toast.success("Admin modifié avec succès.");
        // console.log(isSuccessfullySubmitted);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error?.response);
        setIsSuccessfullySubmitted(false);
        setIsFormRequestError(true);
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          if (
            error?.response?.data?.email &&
            error?.response?.data?.email[0] ===
              "user with this email already exists."
          ) {
            toast.error(
              "Oupsss! L'adresse email est déjà associée à un compte. Veuillez utiliser une autre adresse email.",
              {
                position: "top-right",
                autoClose: 10000,
              }
            );
          } else if (error?.response?.status === 500) {
            toast.error(
              "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
            );
          } else if (
            error?.response?.status === 400 &&
            !error?.response?.data?.email
          ) {
            toast.error(
              "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
            );
          }
        }
      });
  };

  return {
    register,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitCreateForm),
    setValue,
    reset,
    isSuccess,
  };
}

export default UseEditAdminForm;
