import React, { useContext } from 'react'
import { DataContext } from '../store/GlobalState'
import moment from 'moment'
import DefaultAvatar from '../../images/others/default-profile-avatar.png'
import baseUrl from '../../Http/backend-base-url'

const ShowFamilyModal = () => {
  const { state, dispatch } = useContext(DataContext)

  const { ayantDroit } = state
  

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Detail {ayantDroit?.statut === "époux(se)" ? "époux(se)" : "enfant"}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              dispatch({ type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE", payload: {} })
            }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="crud-form">
              <div className="profile-avatar-container">
                <img
                  // src={DefaultAvatar}
                  src={
                    ayantDroit?.avatar === "/mediafiles/avatars/default.png"
                      ? DefaultAvatar
                      : baseUrl + ayantDroit?.avatar
                  }
                  className="profile-avatar"
                  alt="Profile"
                />
              </div>

              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="numero_contrat">Prénom: </strong>

                    <span className="crud-form-control">
                      {ayantDroit?.prenom}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="name">Nom: </strong>
                    <span className="crud-form-control">{ayantDroit?.nom}</span>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="specialite">Date de Naissance: </strong>
                    <span className="crud-form-control">
                      {moment(ayantDroit?.date_de_naissance).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <strong htmlFor="specialite">Genre: </strong>
                    <span className="crud-form-control">
                      {ayantDroit?.sexe}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="accordion" id="accordionExample">
                    {ayantDroit?.certificat ? (
                      <>
                        <div className="card">
                          <div
                            className="card-header"
                            id="headingOne"
                            style={{ textAlign: "left" }}
                          >
                            <h5 className="mb-0">
                              <button
                                className="bloc-text-profil"
                                style={{ background: "none" }}
                                type="button"
                                data-toggle="collapse"
                                data-target={`#collapse${1}`}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                Certificat de{" "}
                                {ayantDroit?.statut === "enfant"
                                  ? "naissance"
                                  : "mariage"}
                              </button>
                            </h5>
                          </div>

                          <div
                            id={`collapse${1}`}
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div
                              className="card-body"
                              style={{ height: "700px" }}
                            >
                              <iframe
                                className="responsive-iframe w-100"
                                src={baseUrl + ayantDroit?.certificat}
                                style={{ height: "100%" }}
                                title="Certificat"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                <div
                  className="col-md-6 offset-md-6 crud-form-action-btn-container"
                  style={{ justifyContent: "flex-end" }}
                >
                  {/* <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button> */}

                  <button
                    className="btn btn-danger cancelleb-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      dispatch({
                        type: "SHOW_AYANTDROIT_BY_BENEFICIAIRE",
                        payload: {},
                      })
                    }
                  >
                    Retour
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowFamilyModal
