import React, { useEffect, useContext } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import * as IconlyPack from 'react-iconly';
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getDataByID, postData, putData } from "../../../utils/fetchData";
import DetailPrestataireSkeleton from "./DetailPrestataireSkeleton";
import moment from "moment";
import { formatMoney } from "../../../../utils/utils";

const DetailsValidationExclusion = () => {
  const [userType, setUserType] = React.useState();
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);

  const { auth } = state;

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  var [isPageLoading, setIsPageLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [dataPres, setDataPres] = React.useState({});

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (location.state.notification?.data) {
      // console.log("props", );
      setData(location.state.notification?.data);
      setDataPres({
        date: location.state.notification?.data?.date,
        montant: location.state.notification?.data?.montant,
        quantite: location.state.notification?.data?.quantite,
        designation: location.state.notification?.data?.designation,
        typedesoin: location.state.notification?.data?.typedesoin?.id,
        familledesoin: location.state.notification?.data?.familledesoin?.id,
        fichedesoin: location.state.notification?.data?.fichedesoin?.id,
        prestataire:
          location.state.notification?.data?.fichedesoin?.prestataire?.id,
        beneficiaire:
          location.state.notification?.data?.fichedesoin?.beneficiaire?.id,
        ayant_droit: location.state.notification?.data?.fichedesoin?.ayant_droit
          ? location.state.notification?.data?.fichedesoin?.ayant_droit?.id
          : null,
      });
    }
  }, [location, auth?.token]);

  const handleValid = (e, item) => {
    e.preventDefault();
    let id = parseInt(window.sessionStorage.getItem("notifId"));
    if (item === "rejeter") {
      setIsPageLoading(true);
      putData(
        "notification/" + id + "/",
        { is_archived: true },
        auth?.token
      ).then(async (res) => {
        if (auth?.user?.user_type === "admin_ipm") {
          await getDataByID(
            "notificationbyipm",
            auth?.user?.parent,
            auth?.token
          ).then((resp) => {
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
          await toast.success("Demande rejetée.");
          await history.push("/ipm/dashboard");
        } else {
          await getDataByID(
            "notificationbyipm",
            auth?.user?.id,
            auth?.token
          ).then((resp) => {
            // console.log('notif', resp)
            window.sessionStorage.removeItem("notifId");
            dispatch({
              type: "LIST_NOTIFICATION",
              payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
            });
          });
          await toast.success("Demande rejetée.");
          await history.push("/ipm/dashboard");
        }
        await setIsPageLoading(false);
      });
    } else {
      setIsSuccessfullySubmitted(true);
      console.log("data", dataPres);
      postData("prestation/", dataPres, auth?.token).then((response) => {
        console.log("response", response);
        putData("notification/" + id + "/", { valid: true }, auth?.token).then(
          async (res) => {
            if (auth?.user?.user_type === "admin_ipm") {
              await getDataByID(
                "notificationbyipm",
                auth?.user?.parent,
                auth?.token
              ).then((resp) => {
                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
                });
              });
              await toast.success("Demande validée.");
              await history.push("/ipm/dashboard");
            } else {
              await getDataByID(
                "notificationbyipm",
                auth?.user?.id,
                auth?.token
              ).then((resp) => {
                // console.log('notif', resp)
                window.sessionStorage.removeItem("notifId");
                dispatch({
                  type: "LIST_NOTIFICATION",
                  payload: resp.sort((a, b) => a?.id - b?.id).reverse(),
                });
              });
              await toast.success("Demande validée.");
              await history.push("/ipm/dashboard");
            }
            await setIsSuccessfullySubmitted(false);
          }
        );
      });
    }
  };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={userType} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Demande d'autorisation</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="add-family-form-container">
                    {/* {isPageLoading && <DetailPrestataireSkeleton />} */}

                    <div className="crud-form">
                      <div className="row crud-row">
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="numero_contrat">
                              Prestataire
                            </strong>

                            <span className="crud-form-control">
                              {data?.fichedesoin?.prestataire?.raison_sociale
                                ? data?.fichedesoin?.prestataire?.raison_sociale
                                : data?.fichedesoin?.prestataire?.prenom +
                                  " " +
                                  data?.fichedesoin?.prestataire?.nom}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="numero_contrat">Patient: </strong>

                            <span className="crud-form-control">
                              {data?.fichedesoin?.ayant_droit
                                ? data?.fichedesoin?.ayant_droit?.prenom +
                                  " " +
                                  data?.fichedesoin?.ayant_droit?.nom
                                : data?.fichedesoin?.beneficiaire?.prenom +
                                  " " +
                                  data?.fichedesoin?.beneficiaire?.nom}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="numero_contrat">
                              Entreprise:{" "}
                            </strong>

                            <span className="crud-form-control">
                              {
                                data?.fichedesoin?.beneficiaire?.employeur
                                  ?.raison_sociale
                              }
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="numero_contrat">
                              Famille de soins:{" "}
                            </strong>

                            <span className="crud-form-control">
                              {data?.familledesoin?.nom}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="numero_contrat">
                              Type de soins:{" "}
                            </strong>

                            <span className="crud-form-control">
                              {data?.typedesoin?.nom}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="numero_contrat">
                              Désignation:{" "}
                            </strong>

                            <strong className="crud-form-control">
                              {data?.designation}
                            </strong>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="numero_contrat">Quantité: </strong>

                            <strong className="crud-form-control">
                              {data?.quantite}
                            </strong>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="numero_contrat">
                              Prix Unit:{" "}
                            </strong>

                            <strong className="crud-form-control">
                              {formatMoney(data?.montant)}
                            </strong>
                          </div>
                        </div>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="numero_contrat">
                              Date de la prestation:{" "}
                            </strong>

                            <strong className="crud-form-control">
                              {moment(data?.date).format("DD/MM/YYYY")}
                            </strong>
                          </div>
                        </div>
                        <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                          {isPageLoading && (
                            <button
                              className="btn btn-default cancelleb-btn"
                              onClick={(e) => handleValid(e, "rejeter")}
                            >
                              En cours &nbsp;
                              <i className="fas fa-spin fa-spinner"></i>
                            </button>
                          )}
                          {!isPageLoading && (
                            <button
                              className="btn btn-default cancelleb-btn"
                              onClick={(e) => handleValid(e, "rejeter")}
                            >
                              {userType === "super_ipm" ? "Retour" : "Rejeter"}
                            </button>
                          )}
                          {isSuccessfullySubmitted && (
                            <button className="btn btn-primary crud-submit-btn">
                              En cours &nbsp;
                              <i className="fas fa-spin fa-spinner"></i>
                            </button>
                          )}
                          {!isSuccessfullySubmitted && (
                            <button
                              className="btn btn-primary crud-submit-btn"
                              onClick={(e) => handleValid(e, "valid")}
                            >
                              Accepter
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsValidationExclusion;
