import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import moment from "moment";
import * as IconlyPack from "react-iconly";
import { NavLink, useLocation } from "react-router-dom";
import { DataContext } from "../../store/GlobalState";
import DeviSkeleton from "./DeviSkeleton";

const DevisTable = () => {
  var [isPageLoading, setIsPageLoading] = React.useState(true);

  const { state, dispatch } = useContext(DataContext);
  const { auth, devisbyprestataire, devisbyipm, devisbyentreprise } = state;
  const location = useLocation();

  useEffect(() => {
    // setTimeout(() => {
    //   setIsPageLoading(false)
    // }, 5000)
    if (auth?.token && auth?.user?.id) {
      setIsPageLoading(false);
    } else {
      setIsPageLoading(true);
    }
  }, [auth]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return row?.beneficiaire?.prenom + " " + row?.beneficiaire?.nom;
  };

  const adresseFormater = (cell, row) => {
    return row?.beneficiaire?.adresse;
  };

  const emailFormater = (cell, row) => {
    return row?.beneficiaire?.email;
  };

  const telephoneFormater = (cell, row) => {
    return row?.beneficiaire?.telephone;
  };

  const dateFormater = (cell, row) => {
    return moment(row?.created_at).format("DD/MM/YYYY");
  };

  const etatFormatter = (cell, row) => {
    if (auth?.user?.user_type === "prestataire") {
      if (cell === "refuse") {
        return (
          <span
            // data-toggle="modal"
            // data-target=".devis-validation-modal"
            className="badge badge-danger easypm-badge-danger"
            // onClick={() => onGetItem(row)}
          >
            Refusé
          </span>
        );
      }
      if (cell === "a_traite") {
        return (
          <span className="badge badge-warning easypm-badge-warning">Reçu</span>
        );
      }
      if (cell === "envoye") {
        return (
          <span className="badge badge-info easypm-badge-info linkable">
            envoyé
          </span>
        );
      }
      if (cell === "validee") {
        return (
          <span className="badge badge-success easypm-badge-success">
            Validé
          </span>
        );
      }
    } else {
      if (cell === "a_traite") {
        return (
          <span className="badge badge-secondary easypm-badge-secondary">
            Reçu
          </span>
        );
      }
      if (cell === "refuse") {
        return (
          <span
            // data-toggle="modal"
            // data-target=".devis-validation-modal"
            className="badge badge-danger easypm-badge-danger"
            // onClick={() => onGetItem(row)}
          >
            Refusé
          </span>
        );
      }
      if (cell === "en_cours") {
        return (
          <span className="badge badge-default easypm-badge-default">
            En cours
          </span>
        );
      }
      if (cell === "en_attente") {
        return (
          <span
            // data-toggle="modal"
            // data-target=".devis-validation-modal"
            className="badge badge-warning easypm-badge-warning"
          >
            En attente
          </span>
        );
      }
      if (cell === "envoye") {
        return (
          <span className="badge badge-info easypm-badge-info ">envoyée</span>
        );
      }
      if (cell === "validee") {
        return (
          <span
            // data-toggle="modal"
            // data-target=".devis-validation-modal"
            className="badge badge-success easypm-badge-success"
          >
            Validé
          </span>
        );
      }
    }
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {((auth?.user?.user_type === "prestataire" &&
          row?.etat_prestataire !== "refuse") ||
          (auth?.user?.user_type === "entreprise" &&
            row?.etat_entreprise !== "refuse") ||
          ((auth?.user?.user_type === "ipm" ||
            auth?.user?.user_type === "assurance_sante") &&
            row?.etat_ipm !== "refuse")) && (
          <button className="action-btn edit-btn" title="Traitement">
            <IconlyPack.Paper
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-edit-icon"
              data-toggle="modal"
              data-target=".devis-validation-modal"
              onClick={() => onGetItem(row)}
            />
          </button>
        )}

        <NavLink to={`#`} className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".show-devis-modal"
            onClick={() => onGetItem(row)}
          />
        </NavLink>
        <button className="action-btn edit-btn" title="Voir le fichier">
          <IconlyPack.Download
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".show-devis-file-modal"
            onClick={() => onGetItem(row)}
          />
        </button>
      </div>
    );
  };

  const prestataireFormater = (cell, row) => {
    return cell?.raison_sociale || cell?.prenom + " " + cell?.nom;
  };

  const onGetItem = (item) => {
    console.log(item);
    dispatch({ type: "SHOW_DEVIS", payload: item });
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <DeviSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={
                    auth?.user?.user_type === "prestataire"
                      ? devisbyprestataire
                      : auth?.user?.user_type === "entreprise"
                      ? devisbyentreprise
                      : devisbyipm
                  }
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                    filter={{
                      type: "TextFilter",
                      defaultValue: location?.state?.notification?.data?.id
                        ? String(location?.state?.notification?.data?.id)
                        : "",
                    }}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="200"
                    hidden={auth?.user?.user_type === "prestataire"}
                    dataFormat={prestataireFormater}
                    dataField="prestataire"
                  >
                    Prestataire
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="200"
                    dataFormat={nameFormater}
                    dataField="beneficiaire"
                  >
                    Participant
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="200"
                    dataAlign="center"
                    dataField="created_at"
                    dataFormat={(cell, row) => dateFormater(cell, row)}
                  >
                    Date
                  </TableHeaderColumn>

                  {/* <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="center"
                    width="200"
                    dataField="email"
                    dataFormat={(cell, row) => emailFormater(cell, row)}
                  >
                    Email
                  </TableHeaderColumn> */}

                  {/* <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="200"
                    dataField="adresse"
                    dataFormat={(cell, row) => adresseFormater(cell, row)}
                  >
                    Adresse
                  </TableHeaderColumn> */}
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="200"
                    dataField="telephone"
                    dataFormat={(cell, row) => telephoneFormater(cell, row)}
                  >
                    Téléphone
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="100"
                    dataAlign="center"
                    dataField={
                      auth?.user?.user_type === "prestataire"
                        ? "etat_prestataire"
                        : auth?.user?.user_type === "entreprise"
                        ? "etat_entreprise"
                        : "etat_ipm"
                    }
                    dataFormat={(cell, row) => etatFormatter(cell, row)}
                  >
                    Statut
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="200"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevisTable;
