import React, { useContext } from "react";
import { TimeItem } from "../../Beneficiaire/GestionRendezVous/AjouterRdv";
import http from "../../../../Http/http/http";
import { useCurrentUser } from "../../../../utils/utils";
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";

export function getAllHours() {
  return [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
}
const DisponibilitePrestataire = () => {
  const { state, dispatch } = useContext(DataContext);
  const { auth, prestataire } = state;
  const user = useCurrentUser();
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [matin] = React.useState(getAllHours().filter((f) => f < 14));
  const [soir] = React.useState(getAllHours().filter((f) => f >= 14));
  const onSubmit = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const disponibilite = JSON.stringify(data);
      await http.put("/prestataire/" + user?.id + "/", {
        disponibilite,
      });
      dispatch({
        type: "EDIT_PRESTATAIRE",
        payload: { ...user, disponibilite },
      });
      dispatch({
        type: "AUTH",
        payload: { token: auth.token, user: { ...user, disponibilite } },
      });
      toast.success("Disponibilités modifiés avec succès.");
    } catch (ex) {}
    setIsLoading(false);
  }, [user, data, auth]);
  React.useEffect(() => {
    setData(
      prestataire?.disponibilite ? JSON.parse(prestataire.disponibilite) : []
    );
  }, [prestataire]);

  return (
    <div className="easypm-tabs-item-content-container">
      <div className="easypm-tabs-item">
        <div className="add-family-form-container">
          <div className="row">
            <div className="col-md-12">
              <form className="crud-form">
                <div className="checkbox-add-event mt-4">
                  <label
                    class="label-form-add-event mb-2"
                    for="inlineFormInputGroup"
                  >
                    Matin
                  </label>

                  <div className="row">
                    {matin.map((m) => (
                      <TimeItem
                        colLess
                        onPress={() => {
                          if (data.includes(m)) {
                            setData((old) => old.filter((f) => f !== m));
                          } else {
                            setData((old) => [...old, m]);
                          }
                        }}
                        checked={data.includes(m)}
                        time={m}
                        key={m}
                      />
                    ))}
                  </div>
                  <div className="mt-4">
                    <label
                      class="label-form-add-event mb-2"
                      for="inlineFormInputGroup"
                    >
                      Soir
                    </label>
                    <div class="row">
                      {soir.map((m) => (
                        <TimeItem
                          colLess
                          onPress={() => {
                            if (data.includes(m)) {
                              setData((old) => old.filter((f) => f !== m));
                            } else {
                              setData((old) => [...old, m]);
                            }
                          }}
                          checked={data.includes(m)}
                          time={m}
                          key={m}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-12">
              <div className="col-md-3 offset-md-9 crud-form-action-btn-container">
                {/* <button className="btn btn-default cancelleb-btn" data-dismiss="modal">Retour</button> */}
                {!isLoading ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onSubmit();
                    }}
                    className="btn btn-primary crud-submit-btn"
                  >
                    Modifier
                  </button>
                ) : (
                  <button className="btn btn-primary crud-submit-btn">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisponibilitePrestataire;
