import React, { useEffect, useState } from "react";

import ImageLabel from "../../../../assets/images/image_label.png";
import DocsLabel from "../../../../assets/images/image_label.png";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import * as IconlyPack from "react-iconly";

import { getDroppedOrSelectedFiles } from "html5-file-selector";
import Header from "../../../Navs/Header";
import NavbarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import { NavLink } from "react-router-dom";
import EditeurTexte from "./EditeurDeTexte";

var AjouterInfoUtile = () => {


  const getUploadParams = ({ meta }) => {
    // console.log(typeof meta);
    return { url: "https://httpbin.org/post" };
  };
  const handleChangeStatus = ({ meta, file }, status) => {
    file.height = meta.height;
    // console.log(file);
  };
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };
  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    // console.log("files", files);
    const text = files.length === 0 && (
      // 'Ajouter encore des photos' :
      <div className="start-label">
        <div className="label-img-container">
          <img src={ImageLabel} className="label-image" alt="Label" />
        </div>
        <span className="first_label">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 2Mo.
        </span>
      </div>
    );
    let buttonStartStyle = {
      background: "rgb(255, 152, 0)",
      cursor: "pointer",
      padding: 15,
      borderRadius: 30,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "30%",
      marginLeft: "35%",
      color: "#fff",
      fontWeight: 700,
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="row upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-6 offset-md-3">
          {text}
          <input
            style={{ opacity: 0 }}
            className="file-input"
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="dashboard-item-container">
    <div className="container-fluid body-background-dashboard">
      <Header />

      <div className="row">
        {/* Navbar Desktop  */}
        <div className="nav-container nav-assurance-color">
          <NavbarVerticalV2  />
        </div>


          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container pd-page">
                  <div className="page-title-container">
                    <h1 className="page-title"> 
                    <NavLink to="/info-utile-beneficiaire">
                    <IconlyPack.CaretLeft set="bold" primaryColor="#000" className="pr-2"/>
                    </NavLink>
                         Ajouter une info</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="pt-4">
                    <form>
                      <div className="row d-flex align-items-center">
                        <div className="col-md-12 pb-3">
                          <div className="form-group">
                            <label className="label-input">
                            Titre de l’info
                            </label>
                            <input
                              name="name"
                              type="text"
                              className="bloc-div form-control"
                              placeholder="Nom du point d'interêt"
                            />
                          </div>
                        </div>
                       <div className="col-md-12 py-3 w-full py-3">
                          <div className="form-group">
                            <label className="label-input">Image du de l’info </label>
                            <div className="input-file">
                              <Dropzone
                                getUploadParams={getUploadParams}
                                onChangeStatus={handleChangeStatus}
                                // onSubmit={handleSubmit}
                                accept="image/*"
                                autoUpload
                                styles={{
                                  dropzoneReject: {
                                    borderColor: "#F19373",
                                    backgroundColor: "#F1BDAB",
                                  },
                                  inputLabel: (files, extra) =>
                                    extra.reject ? { color: "#A02800" } : {},
                                }}
                                maxFiles={1}
                                // inputWithFilesContent={(files) => {}}
                                InputComponent={InputChooseFile}
                                getFilesFromEvent={getFilesFromEvent}
                                classNames
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 py-5">
                            <div className="form-group">
                              <label className="label-input pb-2">
                              Ajoutez un contenu
                              </label>
                              <EditeurTexte />
                            </div>
                          </div>
                      </div>
                      <div className="row my-5 d-flex justify-content-end">
                        <div className="col-xl-2 col-lg-2 col-md-2">
                            <button className="btn-theme-plus">
                              ENREGISTRER
                            </button>
      
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AjouterInfoUtile;