import React, { useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AssuranceMaladie from '../../../Services/AssuranceMaladie'
import { toast } from 'react-toastify'
import { getData } from '../../../utils/fetchData'
import { DataContext } from '../../../store/GlobalState'

function UseCreateIpmForm() {
  const { state, dispatch } = useContext(DataContext)
  const { auth } = state
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = React.useState(
    false,
  )
  const [ipmData, setIpmData] = React.useState()
  const [isFormRequestError, setIsFormRequestError] = React.useState(false)
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem('authToken')}`,
    },
  }

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("L'adresse email n'est pas valide")
          .required("L'email est requis"),
        raison_sociale: yup.string().required('La raison sociale est requise'),
        telephone: yup
          .string()
          .required('Le numéro de téléphone est requis')
          .matches(
            /^((\+)221|0)[1-9](\d{2}){4}$/,
            'Veuillez vérifier le format du numéro de téléphone',
          ),
        adresse: yup.string().required("L'adresse est requise"),
        ninea: yup
        .string()
        .matches(new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/), 'Le NINEA doit être de format alpha-numérique.')
        .length(12, 'Le ninea doit avoir au maximum 12 caractères')
        .required('Le NINEA est requis'),
        specialite: yup.string().required('Le specialite est requis'),
      }),
    [],
  )

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true)
    // console.log('data', formValues)
    var typePrestationRequest = AssuranceMaladie.createIpm(
      formValues,
      requestConfig,
    )
    await typePrestationRequest
      .then((response) => {
        var ipmInfos = response
        console.log('ipm', ipmInfos)
        setIpmData(ipmInfos)
        // console.log(isSuccessfullySubmitted)

        // dispatch({
        //   type: 'ADD_PRESTATION_TYPE',
        //   payload: [...prestation_types, typePrestationInfos],
        // })
        e.target.reset()
        getData('assurance_maladies', auth.token)
          .then((res) => {
            // console.log('data', res.results)
            dispatch({
              type: 'ADD_IPM',
              payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
            })
          })
          .catch((e) => {
            console.log('e', e)
          })
          setIsSuccessfullySubmitted(false)
        window.$('.add-ipm-modal').modal('hide')
        toast.success('Ipm ajouté avec succès.')
      })
      .catch((error) => {
        console.log(error)
        setIsFormRequestError(true)
        toast.error("Oupss! Une erreur est survenue lors de l'ajout.")
      })
  }
  return {
    register,
    errors,
    formState,
    ipmData,
    setIpmData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitCreateForm),
    reset,
    setValue
  }
}

export default UseCreateIpmForm
